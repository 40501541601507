export enum ErrorMessage {
  _name = 'ERROR_MESSAGE',
  SESSION_EXPIRED = 'sessionExpired',
  UNKNOWN = 'unknown',
}

export enum HttpStatusError {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}

export enum HttpStatus {
  NO_DATA = 204,
  PENDING = 202,
  OK = 200,
}

export enum Country {
  CANADA = 'CAD',
  USA = 'USA',
}

export enum DefaultBrandColors {
  BRAND = '00884F', // primary main
  ACCENT = '003032',
}
