import React from 'react';
import { Controller } from 'react-hook-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useTranslation } from 'react-i18next';

import './styles.css';
import { FormPhoneInputProps } from 'types';

const FormPhoneInput = (props: FormPhoneInputProps): React.ReactElement => {
  const { t } = useTranslation('dashboard');
  const { name, label, required, errorobj, control, placeholder, testid } = props;
  let errorMessage = '';
  if (errorobj && errorobj[name]) {
    errorMessage = errorobj[name].message;
  }

  return (
    <Controller
      as={MuiPhoneNumber}
      variant="outlined"
      name={name}
      onFocus={(): void => null}
      control={control}
      InputLabelProps={{
        required: required || false,
      }}
      FormHelperTextProps={{
        className: 'error-text',
      }}
      error={!!errorMessage}
      helperText={t(errorMessage)}
      {...props}
      placeholder={t(placeholder)}
      label={t(label)}
      inputProps={{ ...props.inputProps, 'data-testid': testid }}
    />
  );
};

export default FormPhoneInput;
