import { ApiResponse, ConnectUrlResponse, IpWhitelistResponse } from '../../../types';
import { ErrorResponse } from '../../../types/interface/error.interface';

export interface ConnectAction {
  payload: any;
  type: string;
}

/**
 * Connect store state
 */
export interface ConnectState {
  loading: boolean;
  error: ErrorResponse;
  response: ApiResponse;
  ipWhitelists: IpWhitelistResponse[];
  connectUrl: ConnectUrlResponse;
}

/**
 * Name of the connect Store
 */
export const CONNECT_STORE_NAME = 'connect';

/**
 * connect initial state
 */
export const CONNECT_INITIAL_STATE: ConnectState = {
  response: null,
  ipWhitelists: [],
  connectUrl: null,
  loading: false,
  error: null,
};
