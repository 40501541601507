import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    view: {
      paddingTop: theme.spacing(4),
    },
    forgotPasswordButton: {
      display: 'block',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1.5),
      paddingLeft: 0,
      height: theme.spacing(5),
    },
  }),
);
