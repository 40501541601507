import { Box, Grid, Typography } from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import SandboxIcon from '@material-ui/icons/FlipToFront';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';

import { Link } from 'react-router-dom';

import { RailzButton } from '@railzai/railz-uikit-react';

import { getProfileState } from '../../store/features/account/profile/profile.selector';
import { getBusinessStats } from '../../store/features/business/business.selector';

import { isSandbox } from '../../store/features/auth';

import useQuerySearch from '../../hooks/use-query-search';

import useStyles from './style';

import ConnectionInsightsCard from './connection-insights/connection-insights';

import StatCard from './components/stat-card';

import { Header, ScrollView, View } from 'components';
import Loading from 'components/loading';
import { FREE_PLAN_CONNECTION_LIMIT, Role, ServiceType, Plan as PlanType } from 'types';

import { BusinessEventStat } from 'store/features/business/business.state';
import { resetTeamError } from 'store/features/account/team/team.action';
import { getTeamErrorMessage } from 'store/features/account/team/team.selector';
import SubscriptionSuccess from 'pages/plan/components/subscription-success/subscription-success';
import { fetchBusinessStats } from 'store/features/business/business.action';

const getRemainingBusinesses = (stats: BusinessEventStat): number => {
  const remainingBusinesses =
    FREE_PLAN_CONNECTION_LIMIT - (stats.businesses.active + stats.businesses.inactive);
  return remainingBusinesses > 0 ? remainingBusinesses : 0;
};

const HomePage: React.FC<{
  isAppInitialized?: boolean;
}> = ({ isAppInitialized = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const querySearch = useQuerySearch();
  const history = useHistory();

  const [isInitialized, setIsInitialized] = useState(isAppInitialized);
  const [businesses, setBusinesses] = useState(null);
  const [billingPeriod, setBillingPeriod] = useState(null);
  const [billingUsage, setBillingUsage] = useState<string>('0');

  const [connStatsOrder, setConnStatsOrder] = useState(['accounting', 'banking', 'commerce']);
  const [billingPlan, setBillingPlan] = useState<string | null>(null);
  const [subscriptionSuccessOpen, setSubscriptionSuccessOpen] = useState(false);

  const stats = useSelector(getBusinessStats);
  const user = useSelector(getProfileState);
  const teamErrorMessage = useSelector(getTeamErrorMessage);
  const isSandboxEnabled = useSelector(isSandbox);

  useEffect(() => {
    // If redirected from teams/members due to unauthorized credentials, reset the teams error so if
    // you log in as a user with access it won't display the errors
    if (!isEmpty(teamErrorMessage) && teamErrorMessage[0] === 'fetch') {
      dispatch(resetTeamError());
    }
  }, [teamErrorMessage]);

  useEffect(() => {
    if (!stats) {
      dispatch(fetchBusinessStats());
    }
  }, []);

  useEffect(() => {
    if (user?.billingPlan) {
      setBillingPlan(user.billingPlan);
    }
  }, [user?.billingPlan]);

  useEffect(() => {
    if (user && stats) {
      const newBusinesses = {
        remaining: getRemainingBusinesses(stats),
        ...stats.businesses,
      };

      setBusinesses(newBusinesses);
      if (isSandboxEnabled) {
        setBillingUsage(String(stats.connections.summary.active || 0));
      } else if (stats.billing?.period && user?.billingPlan !== PlanType.FREE) {
        setBillingPeriod({
          startDate: new Date(stats.billing.period.startDate),
          endDate: new Date(stats.billing.period.endDate),
        });
        setBillingUsage(String(stats.billing.usage || 0));
      } else {
        setBillingUsage(String(newBusinesses.allTimeActive || 0));
      }

      setIsInitialized(true);
      const initialStatsOrder = ['accounting', 'banking', 'commerce'];
      const connWithStats = initialStatsOrder.filter(
        (connName) => Object.keys(stats.connections[connName].connectionsByServiceName).length > 0,
      );
      const connWithoutStats = initialStatsOrder.filter(
        (connName) => !connWithStats.includes(connName),
      );
      setConnStatsOrder([...connWithStats, ...connWithoutStats]);
      if (user?.billingPlan) {
        setBillingPlan(user.billingPlan);
      }
    }
  }, [user, stats, isSandboxEnabled]);

  useEffect(() => {
    if (querySearch && querySearch.get('success')) {
      const success = querySearch.get('success') === 'true';
      if (success && user?.billingPlan === PlanType.PAY_AS_YOU_GO) {
        setSubscriptionSuccessOpen(true);
        querySearch.delete('success');
        history.replace('/');
      }
    }
  }, [querySearch, user]);

  const formatBillingPeriod = (billingPeriod: { startDate: Date; endDate: Date }): string => {
    const formatting = new Intl.DateTimeFormat('en', {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    });

    return `${formatting.format(billingPeriod.startDate)} - ${formatting.format(
      billingPeriod.endDate,
    )}`;
  };

  return (
    <>
      <Loading loading={!isInitialized} testId={'home-page-loader'} />
      <Header
        drawerMenu
        title={t('DASHBOARD_HOME_HEADER')}
        leftComponent={null}
        rightComponent={null}
        testId={'home-page-header'}
      />
      <View>
        <SubscriptionSuccess
          open={subscriptionSuccessOpen}
          onClose={(): void => setSubscriptionSuccessOpen(false)}
        />
        <ScrollView style={{ padding: 24 }}>
          <Box className={classes.headerBox}>
            <Typography variant="h6" className={classes.headerTitle}>
              {t('DASHBOARD_BUSINESS_HEADER')}
            </Typography>
            {(user.role != Role.DEVELOPER || isSandboxEnabled) && (
              <Box>
                <Link to={`/businesses?show=ALL`} className={classes.headerLink}>
                  <RailzButton
                    size="large"
                    type="text primary"
                    label={t('DASHBOARD_BUSINESS_HOMEPAGE_LINK')}
                    data-testid="business-link-button"
                  >
                    <span slot="suffix">
                      <ArrowForwardIcon fontSize="small" />
                    </span>
                  </RailzButton>
                </Link>
              </Box>
            )}
          </Box>

          <ul className={classes.businessStatsList}>
            <li>
              <StatCard
                title={t('DASHBOARD_BUSINESS_STATUS_ACTIVE')}
                value={businesses?.active}
                testId="home-page-businessActive"
                icon={<LibraryAddCheckIcon />}
                type="active"
                tooltipText={t('DASHBOARD_HOME_ACTIVE_TOOLTIP')}
                link={{
                  to: '/businesses?show=ACTIVE',
                  label: 'View Active Businesses',
                }}
              />
            </li>
            <li>
              <StatCard
                title={t('DASHBOARD_BUSINESS_STATUS_PENDING')}
                value={businesses?.new}
                testId="home-page-businessNew"
                icon={<SelectAllIcon />}
                type="pending"
                tooltipText={t('DASHBOARD_HOME_PENDING_TOOLTIP')}
                link={{
                  to: '/businesses?show=NEW',
                  label: 'View Pending Businesses',
                }}
              />
            </li>
            <li>
              <StatCard
                title={t('DASHBOARD_BUSINESS_STATUS_INACTIVE')}
                value={businesses?.inactive}
                icon={<RemoveCircleOutlineIcon />}
                testId={'home-page-businessInactive'}
                type="inactive"
                tooltipText={t('DASHBOARD_HOME_INACTIVE_TOOLTIP')}
                link={{
                  to: '/businesses?show=INACTIVE',
                  label: 'View Inactive Businesses',
                }}
              />
            </li>
          </ul>

          {isSandboxEnabled && (
            <Typography
              variant="h6"
              className={classes.headerTitle}
              style={{ marginBottom: 16, marginTop: 16 }}
            >
              {t('DASHBOARD_HOME_SERVICES_HEADER')}
            </Typography>
          )}
          {isSandboxEnabled ? (
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <StatCard
                  title={t('DASHBOARD_SANDBOX_CONNECTION_COUNT_TITLE')}
                  type="lifetime"
                  icon={<SandboxIcon />}
                  value={String(stats?.connections?.railzSandbox?.active || 0)}
                  testId="home-page-activeConnectionsCount"
                  valueDescription={'DASHBOARD_SANDBOX_CONNECTION_COUNT_DESCRIPTION'}
                  tooltipText={t('DASHBOARD_SANDBOX_CONNECTION_COUNT_TOOLTIP')}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <StatCard
                  title={t('DASHBOARD_INTEGRATION_SANDBOX_SERVICES_SUBTITLE')}
                  type="lifetime"
                  icon={<ExtensionOutlinedIcon />}
                  value={billingUsage}
                  testId="home-page-activeConnectionsCount"
                  valueDescription={'DASHBOARD_INTEGRATION_SANDBOX_SERVICES_DESCRIPTION'}
                  tooltipText={t('DASHBOARD_HOME_INTEGRATION_SANDBOX_SERVICES_TOOLTIP')}
                />
              </Grid>
            </Grid>
          ) : (
            <StatCard
              title={
                billingPlan === PlanType.FREE
                  ? t('DASHBOARD_LIFETIME_BUSINESS_USAGE')
                  : t('DASHBOARD_CURRENT_BILLING_USAGE')
              }
              type="lifetime"
              value={billingUsage}
              testId="home-page-activeConnectionsCount"
              description={
                billingPlan === PlanType.FREE
                  ? t('DASHBOARD_LIFETIME_BUSINESS_USAGE_SUBTITLE')
                  : t('DASHBOARD_TOTAL_CONNECTED')
              }
              dateRange={
                billingPlan !== PlanType.FREE &&
                billingPeriod && <div>{formatBillingPeriod(billingPeriod)}</div>
              }
              valueDescription={
                billingPlan === PlanType.FREE && 'DASHBOARD_FREE_SERVICES_COUNT_DESCRIPTION'
              }
              tooltipText={
                billingPlan === PlanType.FREE
                  ? t('DASHBOARD_LIFETIME_BUSINESS_TOOLTIP')
                  : t('DASHBOARD_HOME_BILLING_TOOLTIP')
              }
            />
          )}
          {!isSandboxEnabled && (
            <Typography variant="h6" className={classes.headerTitle} style={{ marginTop: 32 }}>
              {t('DASHBOARD_HOME_SERVICES_HEADER')}
            </Typography>
          )}
          <Grid container className={classes.statsContainer}>
            {connStatsOrder.map((kind) => (
              <Grid item key={kind} xs={12} md={3} className={classes.statsCard}>
                <ConnectionInsightsCard
                  kind={kind}
                  connectionsByServiceName={stats?.connections[kind].connectionsByServiceName}
                  logos={
                    kind === ServiceType.BANKING
                      ? (stats?.connections[kind].logos as Record<string, string>)
                      : {}
                  }
                  sandbox={isSandboxEnabled}
                  connectionGroupSummary={stats?.connections[kind]}
                />
              </Grid>
            ))}
          </Grid>
        </ScrollView>
      </View>
    </>
  );
};

export default HomePage;
