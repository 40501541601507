import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClick?: () => void;
}

const BackButton: React.FC<RouteComponentProps & IProps> = (props) => {
  const defaultGoBack = (): void => props.history.goBack();
  const onClick: any = props.onClick || defaultGoBack;
  const { t } = useTranslation();

  return (
    <IconButton
      color="inherit"
      id="backButton"
      onClick={onClick}
      data-testid="backButton"
      aria-label={t('DASHBOARD_NAV_GO_BACK')}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export default withRouter(BackButton);
