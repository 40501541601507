import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { SSOButton } from './button';

import microsoftLogo from 'assets/images/microsoft.svg';

import Config from 'config';

interface Props {
  onSuccess: (accessToken: string) => void;
  onFailure: () => void;
}

const MicrosoftLoginWrapper: React.FC<Props> = ({ onSuccess, onFailure }) => {
  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: Config.MICROSOFT_CLIENT_ID,
      redirectUri: Config.MICROSOFT_CLIENT_REDIRECT_URI,
    },
    cache: {
      cacheLocation: 'memoryStorage',
    },
  });

  return (
    <MsalProvider instance={publicClientApplication}>
      <MicrosoftLogin onSuccess={onSuccess} onFailure={onFailure} />
    </MsalProvider>
  );
};

const MicrosoftLogin: React.FC<Props> = ({ onSuccess, onFailure }) => {
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const acquireToken = async (): Promise<void> => {
    const { accessToken } = await instance.acquireTokenSilent({
      scopes: Config.MICROSOFT_SCOPES,
      account: accounts[0],
    });
    onSuccess(accessToken);
  };

  useEffect(() => {
    if (isAuthenticated && accounts?.length > 0) {
      acquireToken();
    }
  }, [accounts, isAuthenticated]);

  return (
    <SSOButton
      logo={microsoftLogo}
      label={t('DASHBOARD_LOGIN_SSO_MICROSOFT')}
      onClick={(): void => {
        instance
          .loginPopup({
            scopes: Config.MICROSOFT_SCOPES,
          })
          .catch(() => {
            onFailure();
          });
      }}
    >
      {t('DASHBOARD_LOGIN_SSO_MICROSOFT')}
    </SSOButton>
  );
};

export default MicrosoftLoginWrapper;
