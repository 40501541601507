import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import { AuthService } from '../../services';
import useQuerySearch from '../../hooks/use-query-search';

import Title from '../../components/title';
import Logo from '../../components/logo';

import useStyle from './style';

import Loading from 'components/loading';

const VerifyEmail: React.FC = () => {
  const classes = useStyle();
  const query = useQuerySearch();
  const { t } = useTranslation();
  const [verificationChecked, setVerificationChecked] = useState(false);
  const [emailIsVerified, setEmailIsVerified] = useState(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await AuthService.verifyEmail(query.get('token'));
        setEmailIsVerified(true);
      } catch (error) {
        setEmailIsVerified(false);
      } finally {
        setVerificationChecked(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!verificationChecked) {
    return (
      <Container role="main" className={classes.container}>
        <Logo />
        <Loading loading testId="loading-email-verification" isBackdrop={false} />
      </Container>
    );
  }
  return (
    <Container role="main" className={classes.container}>
      <Logo />

      <Title
        className={classes.title}
        color="textPrimary"
        variant="h1"
        title={
          emailIsVerified
            ? t('DASHBOARD_EMAIL_VERIFIED')
            : t('DASHBOARD_EMAIL_VERIFY_TOKEN_EXPIRED')
        }
      />

      {!emailIsVerified && (
        <Typography variant="body2" className={classes.body} color="textSecondary">
          {t('DASHBOARD_EMAIL_VERIFY_TOKEN_EXPIRED_SUPPORTING')}
        </Typography>
      )}

      <RailzButton
        size="large"
        href="/login"
        target="_self"
        className={classes.button}
        label={t('DASHBOARD_EMAIL_VERIFIED_CTA')}
      />
    </Container>
  );
};

export default VerifyEmail;
