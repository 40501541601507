import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ALL_FONTS } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(14),
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
      },
    },
    main: {
      display: 'flex',
      justifyContent: 'start',
      overflowX: 'hidden',
      paddingBottom: theme.spacing(5),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(58),
      },
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      paddingTop: theme.spacing(8.375),
      paddingBottom: theme.spacing(1),
    },
    confirmationBody: {
      lineHeight: '1.625rem',
    },
    logoIcon: {
      width: theme.spacing(11.25),
      height: theme.spacing(3.625),
    },
    logout: {
      display: 'flex',
      gap: theme.spacing(3),
      fontSize: '1rem',
      fontWeight: 500,
      paddingTop: theme.spacing(3.5),
      color: theme.palette.grey[800],
      cursor: 'pointer',
    },
    illustration: {
      position: 'fixed',
      height: 'clamp(280px, 35%, 447px)',
      width: 'clamp(280px, 70%, 500px)',
      top: 'clamp(-160px, -80%, -447px)',
      right: '-7%',
      zIndex: -1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        objectFit: 'contain',
        maxWidth: '100%',
        width: '100%',
      },
    },
    description: {
      color: theme.palette.grey[800],
      fontSize: '0.875rem',
    },
    card: {
      minHeight: theme.spacing(30),
      padding: theme.spacing(2),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    cardPillSection: {
      transform: 'translate(-50%, -50%)',
      position: 'relative',
      backgroundColor: '#F4FC83',
      left: '50%',
      border: `${theme.spacing(0.0625)}px solid #000000`,
      width: 'fit-content',
      borderRadius: theme.spacing(0.625),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      boxSizing: 'border-box',
    },
    cardTitle: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    cardSubTitle: {
      fontSize: '1.125rem',
      fontWeight: theme.typography.fontWeightBold as number,
      color: theme.palette.grey[900],
      marginBottom: theme.spacing(2),
    },
    cardButton: {
      display: 'flex',
      marginTop: 'auto',
      '& .label': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontSize: '1rem',
        fontWeight: 500,
      },
    },
    featureContainer: {
      marginTop: theme.spacing(3),
    },
    featureListContainer: {
      margin: 0,
      listStyleType: 'none',
      padding: 0,
      '& li': {
        marginBottom: theme.spacing(1),
      },
      marginBottom: theme.spacing(2),
    },
    featureText: {
      fontSize: '0.875rem',
      lineHeight: `${theme.spacing(3)}px`,
      fontFamily: ALL_FONTS,
      display: 'flex',
      color: theme.palette.grey[800],
      fontWeight: 400,
    },
    checkmark: {
      marginRight: theme.spacing(1.375),
      width: theme.spacing(2.3),
    },

    plansContainer: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'stretch',
      flexDirection: 'row',
      alignContent: 'center',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    gridItem: {
      border: `${theme.spacing(0.0625)}px solid`,
      borderRadius: theme.spacing(0.625),
      width: theme.spacing(48),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      display: 'flex',
    },
    planDescription: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: theme.spacing(0.625),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    divider: {
      marginTop: theme.spacing(3),
    },
    plusPlans: {
      backgroundColor: '#F3FFF8',
    },
    freePlanContainer: {
      backgroundColor: '#FFFFFF',
    },
    freePlan: {
      backgroundColor: '#F4FC83',
    },
    payAsGoPlan: {
      backgroundColor: '#B5F4FD',
    },
    enterPricePlan: {
      backgroundColor: '#25E896',
    },
  }),
);
