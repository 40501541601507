import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
      fontSize: '1.25rem',
      color: theme.palette.grey[900],
    },

    subtitle: {
      fontWeight: 400,
      fontSize: '1rem',
      color: theme.palette.grey[800],
    },
    container: {
      width: '80%',
      maxWidth: '80ch',
    },
    containerSmall: {
      backgroundColor: 'red',
    },
  }),
);
