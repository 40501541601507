import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from '../../providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    headerTitle: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '28px',
      color: '#000000',
    },
    cardContainer: {
      height: '100%',
      background: ACTIVE_TEXT_COLOR,
      borderRadius: '7px',
      margin: `${theme.spacing(2)}px 0`,
      display: 'flex',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'white',
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
      [theme.breakpoints.only('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    cardTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: +theme.typography.fontWeightMedium,
      fontSize: '16px',
      lineHeight: '20px',
    },
    cardTitle: {
      paddingLeft: theme.spacing(1.25),
    },
    tooltip: {
      paddingLeft: '9.33px',
    },
    cardValue: {
      fontWeight: 600,
      fontSize: '36px',
      lineHeight: '32px',
    },
    cardCurrentBillingContainer: {
      height: '100%',
      borderRadius: '7px',
      margin: `${theme.spacing(2)}px 0`,
      padding: `0 ${theme.spacing(2)}px`,
      background: 'white',
      color: '#424242',
      border: '1px solid #E0E0E0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cardCurrentBillingTitle: {
      display: 'flex',
      flexDirection: 'column',
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '16px',

      [theme.breakpoints.only('md')]: {
        paddingTop: theme.spacing(0.5),
      },
    },
    lifetimeConnectionsText: {
      display: 'flex',
      marginBottom: theme.spacing(),

      [theme.breakpoints.only('md')]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    cardCurrentBillingInformationContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    currentBillingInformationText: {
      marginBottom: theme.spacing(2.75),
    },
    cardCurrentBillingInformationInfo1: {
      fontWeight: +theme.typography.fontWeightMedium,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#424242',
    },
    cardCurrentBillingInformationInfo2: {
      fontWeight: +theme.typography.fontWeightMedium,
      fontSize: '10px',
      lineHeight: '14px',
      color: '#757575',
    },
    cardCurrentBillingInformationValue: {
      fontWeight: 600,
      fontSize: '36px',
      marginRight: theme.spacing(),
      justifyContent: 'flex-end',
    },
    countContainer: { padding: theme.spacing(2), height: 200 },
    countTextHeader: {
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
    },
    connectionStatsContainer: {
      height: '100%',
    },
    connectionsStats: {
      flexGrow: 1,
    },
    statsLabel: {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: theme.palette.grey[600],
      marginTop: theme.spacing(1),
    },
    borderRight: {
      paddingRight: theme.spacing(2.25),
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },

    statsContainer: {
      display: 'flex',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    statsCard: {
      maxWidth: '100%',
      flexGrow: 1,
    },
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerLink: {
      textDecoration: 'none',
    },

    iconHeaderLink: {
      display: 'inline-block',
      verticalAlign: 'top',
    },

    businessStatsList: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      listStyle: 'none',
      margin: `${theme.spacing(1)}px ${theme.spacing(-1)}px`,

      '& li': {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 0,
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
          width: '25%',
          minWidth: '320px',
        },
      },
    },
  }),
);
