import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import { Business } from '../../types/interfaces';
import { ActionTypes } from '../../types/enums';

import ClipboardInput from '../../input-clipboard';

import {
  generateBusinessUrlApi,
  resetBusinessError,
  resetGeneratedUrl,
} from '../../../../store/features/business/business.action';
import {
  getErrorMessage,
  getGeneratedUrl,
} from '../../../../store/features/business/business.selector';

import useStyle from './styles';

import { showSnackbar } from 'helpers/common.helper';
import AlertDialog from 'components/dialog';

const BusinessGenerateLink: React.FC<{
  currentBusiness: Business;
  currentActionType: ActionTypes;
  setCurrentActionType: (actionType: ActionTypes) => void;
}> = ({
  currentBusiness: parentCurrentBusiness,
  currentActionType: parentCurrentActionType,
  setCurrentActionType: parentSetCurrentActionType,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState<boolean>(false);
  const [generatedLink, setGeneratedLink] = useState<string>(null);

  const [currentBusiness, setCurrentBusiness] = useState<Business>(parentCurrentBusiness);
  const [currentActionType, setCurrentActionType] = useState(parentCurrentActionType);
  const generatedUrl = useSelector(getGeneratedUrl);
  const errorResponseMessage = useSelector(getErrorMessage);

  useEffect(() => {
    if (generatedUrl && currentActionType === ActionTypes.GENERATE_LINK) {
      dispatch(resetGeneratedUrl());
      setGeneratedLink(generatedUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedUrl]);

  useEffect(() => {
    if (errorResponseMessage && currentActionType === ActionTypes.GENERATE_LINK) {
      dispatch(resetBusinessError());
      if (errorResponseMessage.includes('generateUrl')) {
        showSnackbar({
          message: t('DASHBOARD_GENERATE_LINK_NOT_ABLE_TO_GENERATE'),
          type: 'error',
        });
        parentSetCurrentActionType(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponseMessage]);
  useEffect((): void => {
    setCurrentBusiness(parentCurrentBusiness);
    setCurrentActionType(parentCurrentActionType);
    if (
      parentCurrentBusiness !== null &&
      parentCurrentActionType !== null &&
      parentCurrentActionType === ActionTypes.GENERATE_LINK
    ) {
      (async (): Promise<void> => {
        dispatch(generateBusinessUrlApi({ businessUuid: parentCurrentBusiness?.businessUuid }));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentCurrentBusiness, parentCurrentActionType]);

  return (
    <AlertDialog
      testId="generate-link-dialog"
      showCloseButton
      isOpen={
        !!currentBusiness && currentActionType === ActionTypes.GENERATE_LINK && !!generatedLink
      }
      title={t('DASHBOARD_GENERATE_LINK_HEADER')}
      messageComponent={{
        message: (
          <Trans
            i18nKey="DASHBOARD_GENERATE_LINK_DESCRIPTION"
            shouldUnescape={true}
            tOptions={{ interpolation: { escapeValue: true } }}
            values={{ businessName: currentBusiness?.businessName }}
          />
        ),
        color: '#4F4F4F',
      }}
      classContent={classes.container}
      confirm={{
        label: t('DASHBOARD_GENERATE_LINK_DONE'),
        onClick: (): void => {
          parentSetCurrentActionType(null);
          setCopied(false);
        },
      }}
      onClose={(): void => {
        parentSetCurrentActionType(null);
        setCopied(false);
      }}
    >
      {generatedLink ? (
        <ClipboardInput
          label={t('DASHBOARD_GENERATE_LINK_LINK')}
          ariaLabel={t('DASHBOARD_GENERATE_LINK_INPUT_ARIA_LABEL')}
          generatedContent={generatedLink}
          setCopied={setCopied}
        />
      ) : (
        <CircularProgress />
      )}
      {copied && <span className={classes.copiedText}>{t('DASHBOARD_GENERATE_LINK_COPIED')}</span>}
    </AlertDialog>
  );
};

export default BusinessGenerateLink;
