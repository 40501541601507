import {
  AspType,
  BankAccountsReportSummary,
  FinancialRatioReportSummary,
  ReportQuery,
  ReportSummaryType,
  ReportType,
  SelectableReportType,
  ServiceType,
} from '../../../types';
import { ReportApiParam } from '../../../helpers/report-helpers/types';
import { DropDownMap } from '../../../helpers/common.helper';
import { acceptedAspList } from '../../../helpers/report-helpers/constants';

export const REPORT_STORE_NAME = 'report';

const initialSummaryState = {
  loading: false,
  statusCode: null,
  summary: null,
};
export const REPORT_INITIAL_STATE: ReportState = {
  report: {
    statusCode: null,
    loading: false,
    data: null,
  },
  aspDataTypes: {
    reportTypes: acceptedAspList as FormattedDataTypes,
    loading: false,
  },
  reportExport: {
    loading: false,
  },
  bankAccountsSummary: initialSummaryState,
  financialRatiosSummary: initialSummaryState,
  filter: null,
  businessHistory: {
    loading: false,
    error: '',
    success: false,
    history: null,
  },
};

export interface ReportData {
  data?: any;
  statusCode?: number;
  requestParams?: ReportParam;
  loading: boolean;
}

export type ReportFetchActionData<T> = Omit<T, 'loading'>;

export interface ReportDataType {
  name: ReportType;
  action: string[];
  reportType: SelectableReportType;
}

export interface DataTypesResponse {
  serviceName: AspType;
  serviceType: ServiceType;
  isBeta: boolean;
  dataTypes: ReportDataType[];
}

export type FormattedDataTypes = {
  [key in AspType]: DropDownMap[];
};

export type FormattedDataTypesByCategory = {
  [key in AspType]: DropDownMapByCategory[];
};

export type DropDownMapByCategory = {
  name: string;
  value: string;
  serviceType?: string;
};

export interface DataTypesState {
  reportTypes: FormattedDataTypes;
  loading: boolean;
}

export interface ReportParam {
  type: ReportType | ReportSummaryType;
  params: ReportApiParam;
}

export interface ReportAction {
  payload?: ReportParam;
  type: string;
}

export interface SyncStatusAction {
  payload?: BusinessHistoryParam;
  type: string;
}

export interface ReportSummaryState {
  bankAccountsSummary: BankAccountsReportSummary;
  financialRatiosSummary: FinancialRatioReportSummary;
}

export class ConnectionHistoryMetadata {
  serviceName?: string;
  oldName?: string;
  newName?: string;
  reason?: string;
}

export interface BusinessHistoryData {
  event: string;
  timestamp: string;
  metadata: ConnectionHistoryMetadata;
}

export interface BusinessHistoryDataFormatted {
  event: string;
  service: string;
  timeStamp: string;
}

export interface BusinessHistoryResponse {
  lastSyncDate: string;
  businessConnectionHistory: BusinessHistoryData[];
}

export interface ReportState extends ReportSummaryState {
  report: ReportData;
  aspDataTypes: DataTypesState;
  filter: ReportQuery;
  businessHistory: {
    loading?: boolean;
    error?: string;
    success?: boolean;
    history?: BusinessHistoryResponse;
  };
  reportExport: {
    loading: boolean;
    controller?: AbortController;
  };
}

export interface BusinessHistoryParam {
  params: BusinessHistoryParamApiParams;
}

export interface BusinessHistoryParamApiParams {
  uuid: string;
}

export interface SyncStatusMetaResponse {
  connectionUuid: string;
  syncTime: number;
}

export class BusinessHistoryResponseDto {
  meta?: SyncStatusMetaResponse;
  lastSyncDate: string;
  businessConnectionHistory: BusinessHistoryData[];
  message?: string;
}
