import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    lastColumn: {
      fontSize: '0.75rem',
      textAlign: 'left',
      width: theme.spacing(18),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      lineHeight: `${theme.spacing(1.5)}px`,
      '& span': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    tableHeaderCell: {
      background: 'red',
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      backgroundColor: 'white',
      color: theme.palette.grey[800],
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      letterSpacing: '0.06em',
      lineHeight: `${theme.spacing(1.875)}px`,
      '& span:focus-visible': {
        borderRadius: '2px',
        outline: `1px solid ${theme.palette.primary.main}`,
        height: theme.spacing(2),
        outlineOffset: '4px',
      },
    },
    labelColumnContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.spacing(1),
    },
    selectAll: {
      alignItems: 'center',
    },
    selectAllCell: {
      padding: 0,
      paddingRight: theme.spacing(2),
    },
    checkColumn: {},
    checksBody: {
      paddingRight: theme.spacing(4.4),
    },
    dataTypeLabel: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: `${theme.spacing(2.75)}px`,
    },
    dataTypeUrl: {
      color: theme.palette.grey[600],
      fontSize: '0.875rem',
      lineHeight: `${theme.spacing(2.75)}px`,
      fontWeight: 400,
    },
    checkTableContainer: {
      textAlign: 'left',

      '& label': {
        marginRight: '0 !important',
      },
    },

    containerCheck: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      whiteSpace: 'normal',
      textAlign: 'right',
      lineHeight: 1.4,
      '& .title': {
        textAlign: 'left',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100px',
      },
      '& .title.min-content-width': {
        width: 'min-content',
      },

      '& .MuiIconButton-root': {
        padding: '0px',
      },
    },

    checkbox: {},

    headerTable: {
      padding: theme.spacing(1),
    },
    confirmationDescription: {
      color: theme.palette.grey[800],
      margin: 0,
      marginTop: theme.spacing(-1),
    },
  }),
);
