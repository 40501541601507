import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../../../store';
import { getCustomerState } from '../../../../store/features/account/customer/customer.selector';

import { CustomerState } from '../../../../store/features/account/customer/customer.state';

import useStyle from './style';

import { FormInput } from 'components/form';
import { showSnackbar, formChange } from 'helpers/common.helper';

const initialValues: IFormValue = {
  billContactName: ' ',
  billContactEmail: ' ',
};

interface IFormValue {
  billContactName: string;
  billContactEmail: string;
}

interface Props {
  onSubmit: (values) => void;
  isDisabled?: boolean;
}

const validationSchema = yup.object().shape({
  billContactName: yup
    .string()
    .required('DASHBOARD_COMPANY_BILLING_CONTACT_NAME_REQUIRED')
    .nullable(),
  billContactEmail: yup
    .string()
    .required('DASHBOARD_BILLING_CONTACT_EMAIL_REQUIRED')
    .email('DASHBOARD_BILLING_CONTACT_EMAIL_INVALID')
    .nullable(),
});

export default function PlanForm({ onSubmit, isDisabled }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const store = storeService.getStore();
  const customer = useSelector<ReturnType<typeof store.getState>, CustomerState>(getCustomerState);

  const { handleSubmit, errors, control, formState, reset } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const { isSubmitting } = formState;

  React.useEffect(() => {
    (async (): Promise<void> => {
      if (customer && !customer.loading) {
        reset({
          billContactEmail: customer?.billContactEmail,
          billContactName: customer?.billContactName,
        });
      }
    })();
    // eslint-disable-next-line
  }, [customer]);

  const onSubmitCall = async (values): Promise<void> => {
    if (formChange(values, customer)) {
      onSubmit && onSubmit(values);
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitCall)}
      aria-labelledby="form-label"
      tabIndex={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            id="form-label"
            variant="h4"
            color="textSecondary"
            className={classes.labelDescription}
          >
            {t('DASHBOARD_BILLING_CONTACT_HEADER')}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInput
            defaultValue=""
            placeholder="DASHBOARD_BILLING_CONTACT_NAME_PLACEHOLDER"
            label="DASHBOARD_BILLING_CONTACT_NAME_LABEL"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            name="billContactName"
            errorobj={errors}
            control={control}
            disabled={isDisabled}
            testid="name-input"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInput
            defaultValue=""
            placeholder="DASHBOARD_BILLING_CONTACT_EMAIL_PLACEHOLDER"
            label="DASHBOARD_BILLING_CONTACT_EMAIL_LABEL"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            name="billContactEmail"
            errorobj={errors}
            control={control}
            disabled={isDisabled}
            testid="email-input"
          />
        </Grid>
      </Grid>
      <div className={classes.button}>
        <RailzButton
          data-testid="submit"
          size="large"
          buttonType="submit"
          isDisabled={isSubmitting || isDisabled}
          label={t('DASHBOARD_BILLING_CONTACT_CTA')}
        >
          {isSubmitting ? (
            <span slot="prefix">
              <CircularProgress size={20} color="inherit" />
            </span>
          ) : null}
        </RailzButton>
      </div>
    </form>
  );
}
