import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ConnectUrlResponse, IpWhitelistResponse } from '../../../types';

import {
  CREATE_IP_WHITELIST_API,
  createIpWhitelistFailureApi,
  createIpWhitelistSuccessApi,
  DELETE_IP_WHITELIST_API,
  deleteIpWhitelistFailureApi,
  deleteIpWhitelistSuccessApi,
  GET_CONNECT_URL_API,
  GET_IP_WHITELIST_API,
  getConnectUrlFailureApi,
  getConnectUrlSuccessApi,
  getIpWhitelistFailureApi,
  getIpWhitelistSuccessApi,
  UPDATE_IP_WHITELIST_API,
  updateIpWhitelistFailureApi,
  updateIpWhitelistSuccessApi,
} from './connect.action';
import { default as ConnectService } from './connect.service';
import { ConnectAction } from './connect.state';

// Worker Saga: will be fired on UPDATE_IP_WHITELIST_API actions
function* updateIpWhitelistApiCall(action: ConnectAction): Generator<any> {
  try {
    yield call(ConnectService.updateIpWhitelist, action.payload);
    yield put(updateIpWhitelistSuccessApi({ success: true, message: 'update' }));
  } catch (error) {
    yield put(updateIpWhitelistFailureApi({ ...error, message: ['update'] }));
  }
}

/*
  Does not allow concurrent fetches of user. If "UPDATE_IP_WHITELIST_API" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* updateIpWhitelistSaga(): Generator<any> {
  yield takeEvery(UPDATE_IP_WHITELIST_API, updateIpWhitelistApiCall);
}

function* deleteIpWhitelistApiCall(action: ConnectAction): Generator<any> {
  try {
    yield call(ConnectService.deleteIpWhitelist, action.payload);
    yield put(deleteIpWhitelistSuccessApi({ success: true, message: 'delete' }));
  } catch (error) {
    yield put(deleteIpWhitelistFailureApi({ ...error, message: ['delete'] }));
  }
}

function* deleteIpWhitelistSaga(): Generator<any> {
  yield takeEvery(DELETE_IP_WHITELIST_API, deleteIpWhitelistApiCall);
}

function* createIpWhitelistApiCall(action: ConnectAction): Generator<any> {
  try {
    yield call(ConnectService.createIpWhitelist, action.payload);
    yield put(createIpWhitelistSuccessApi({ success: true, message: 'create' }));
  } catch (error) {
    yield put(createIpWhitelistFailureApi({ ...error, message: ['create'] }));
  }
}

function* createIpWhitelistSaga(): Generator<any> {
  yield takeEvery(CREATE_IP_WHITELIST_API, createIpWhitelistApiCall);
}

function* getIpWhitelistApiCall(): Generator<any> {
  try {
    const response = (yield call(
      ConnectService.getIpWhitelists,
    ) as unknown) as IpWhitelistResponse[];
    yield put(getIpWhitelistSuccessApi(response));
  } catch (error) {
    yield put(getIpWhitelistFailureApi(error));
  }
}

function* getIpWhitelistSaga(): Generator<any> {
  yield takeLatest(GET_IP_WHITELIST_API, getIpWhitelistApiCall);
}

function* getConnectUrlApiCall(): Generator<any> {
  try {
    const response = (yield call(ConnectService.getConnectUrl) as unknown) as ConnectUrlResponse;
    yield put(getConnectUrlSuccessApi(response));
  } catch (error) {
    yield put(getConnectUrlFailureApi(error));
  }
}

function* getConnectUrlSaga(): Generator<any> {
  yield takeLatest(GET_CONNECT_URL_API, getConnectUrlApiCall);
}

export {
  updateIpWhitelistApiCall,
  createIpWhitelistApiCall,
  deleteIpWhitelistApiCall,
  getIpWhitelistApiCall,
  getConnectUrlApiCall,
};

export const connectSaga = [
  updateIpWhitelistSaga(),
  createIpWhitelistSaga(),
  deleteIpWhitelistSaga(),
  getIpWhitelistSaga(),
  getConnectUrlSaga(),
];
