export enum Role {
  _name = 'ROLE',
  SUPER_ADMINISTRATOR = 'superAdmin',
  ADMINISTRATOR = 'administrator',
  ANALYST = 'analyst',
  BILLING = 'billing',
  DEVELOPER = 'developer',
}

export enum TeamRole {
  superAdmin = 'Super Administrator',
  administrator = 'Administrator',
  analyst = 'Analyst',
  billing = 'Billing',
  developer = 'Developer',
}

export enum Plan {
  _name = 'PLAN',
  FREE = 'free',
  PAY_AS_YOU_GO = 'payAsYouGo',
  CUSTOM = 'custom',
}

export enum ContactType {
  _name = 'CONTACT_TYPE',
  BILLING = 'billing',
  TECHNICAL = 'technical',
}

export enum ApiPurpose {
  _name = 'API_PURPOSE',
  AR_AP_AUTOMATION = 'aRApAutomation',
  BANKING = 'banking',
  FINANCIAL_MANAGEMENT = 'financialManagement',
  INSURANCE = 'insurance',
  LENDING = 'lending',
  PAYMENTS = 'payments',
  OTHER = 'other',
}

export enum BusinessRole {
  _name = 'BUSINESS_ROLE',
  ANALYST = 'analyst',
  CONSULTANT_CONTRACTOR = 'consultantContractor',
  EXECUTIVE = 'executive',
  OPERATIONS = 'operations',
  PRODUCT = 'product',
  TECHNOLOGY = 'technology',
  OTHER = 'other',
}

export enum AuthType {
  GOOGLE = 'google',
}

export enum UserState {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  UNVERIFIED = 'unverified',
}
