import React, { useEffect, useState } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { disableFrequency } from '../../../helpers/report-helpers/utils';
import { ReportFrequency } from '../../../helpers/report-helpers/types';
import { reportFrequencyValues } from '../../../helpers/report-helpers/constants';

interface Props {
  selectedReportFrequency: string;
  reportType?: string;
  handleSelected: (string) => void;
  forcedHideFrequency?: boolean;
}

export default function Frequencies({
  selectedReportFrequency: parentSelectedReportFrequency,
  reportType,
  handleSelected,
  forcedHideFrequency,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedReportFrequency, setSelectedReportFrequency] = useState(null);

  useEffect(() => {
    setSelectedReportFrequency(parentSelectedReportFrequency);
  }, [parentSelectedReportFrequency]);

  const onChange = (event): void => {
    setSelectedReportFrequency(event.target.value as ReportFrequency);
    handleSelected && handleSelected(event.target.value as ReportFrequency);
  };

  return (
    <TextField
      fullWidth
      label={t('DASHBOARD_REPORT_FREQUENCY_LABEL')}
      placeholder={t('DASHBOARD_REPORT_FREQUENCY_PLACEHOLDER')}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      select
      disabled={forcedHideFrequency || disableFrequency(reportType)}
      value={selectedReportFrequency || ''}
      onChange={onChange}
      size="small"
      inputProps={{ 'data-testid': 'frequency-input-access' }}
      data-testid="frequency-input"
    >
      {reportFrequencyValues.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
