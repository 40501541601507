import moment, { Moment } from 'moment-timezone';

import { calculateBusinessDefaultConnection } from './financial-summary-helper';

import { Service } from 'helpers/report-helpers/types';
import {
  disableAccountingMethod,
  disableAccountingMethodAsp,
  disableDate,
  getReportUniqueIdentifier,
  noFrequency,
  showDateRangeFilterIndicator,
  showFinancialStatementType,
  showFrequency,
  showStatus,
  useDefaultBusinessConnection,
} from 'helpers/report-helpers/utils';
import { ConnectionStatus } from 'pages/business/types/enums';
import { Business } from 'pages/business/types/interfaces';
import { FormattedDataTypes } from 'store/features/report/report.state';
import {
  ASP_SERVICE_TYPE,
  AllReportFrequency,
  ReportAccountingMethod,
  ReportFrequency,
  ReportQuery,
  ReportStatus,
  ReportType,
} from 'types';
import { DropDownMap } from 'helpers/common.helper';

export type DataFromFilter = {
  startDate: Moment;
  endDate: Moment;
  reportFrequency: ReportFrequency;
  dateView: AllReportFrequency | ReportFrequency;
  selectedConnection: Service;
  reportType: ReportType;
  status: ReportStatus;
  accountingMethod: ReportAccountingMethod;
  possibleServices: Service[];
  meta: {
    disabledDates: boolean;
    frequencyShown: boolean;
    statusShown: boolean;
    noFrequency: boolean;
    allDateView: boolean;
    financialStatementTypeShown: boolean;
    dateRangeIndicatorShown: boolean;
    accountingMethodShown: boolean;
  };
};

export const BusinessReportDefaultFilterFlags: DataFromFilter['meta'] = {
  disabledDates: false,
  frequencyShown: true,
  statusShown: false,
  noFrequency: false,
  allDateView: true,
  financialStatementTypeShown: false,
  dateRangeIndicatorShown: false,
  accountingMethodShown: false,
};

export function getPossibleServices(
  aspList: FormattedDataTypes,
  business: Business,
  selectedReportType: ReportType,
): Service[] {
  if (useDefaultBusinessConnection(selectedReportType)) {
    const connection = calculateBusinessDefaultConnection(business);
    if (!connection) return [];
    return [{ businessId: String(business.businessId), ...connection } as Service];
  }

  return (business?.connections || [])
    .filter((conn) => conn.status !== ConnectionStatus.PENDING)
    .map((connection) => ({
      ...connection,
      reportTypes: aspList[connection.serviceName] || [],
    }))
    .filter((connection) =>
      (connection?.reportTypes as Array<DropDownMap<ReportType>>).some(
        (reportType) =>
          getReportUniqueIdentifier(reportType, ASP_SERVICE_TYPE[connection.serviceName]) ===
          selectedReportType,
      ),
    )
    .map((connection) => ({
      businessId: String(business.businessId),
      connectionId: connection.connectionId,
      createdAt: moment(connection.createdAt).format('YYYY-MM-DD').toString(),
      firstRecordDate: moment(connection.firstRecordDate).format('YYYY-MM-DD').toString(),
      serviceName: connection.serviceName,
      status: connection.status,
      isMockData: connection.isMockData,
    }));
}

export function buildDataFromFilterUpdate(
  filter: ReportQuery,
  selectedBusiness: Business,
  aspList: FormattedDataTypes,
): DataFromFilter {
  const meta: DataFromFilter['meta'] = {
    disabledDates: disableDate(filter.reportType),
    frequencyShown: showFrequency(filter.reportType),
    statusShown: showStatus(filter.reportType),
    noFrequency: disableDate(filter.reportType) || showStatus(filter.reportType),
    allDateView: noFrequency(filter.reportType),
    financialStatementTypeShown: showFinancialStatementType(filter.reportType),
    dateRangeIndicatorShown: showDateRangeFilterIndicator(filter.reportType),
    accountingMethodShown:
      !disableAccountingMethodAsp(filter.serviceName) &&
      !disableAccountingMethod(filter.reportType),
  };

  const freq = meta.noFrequency ? null : filter.reportFrequency || ReportFrequency.MONTH;
  const possibleServices = getPossibleServices(aspList, selectedBusiness, filter.reportType);

  return {
    startDate: filter.startDate ? moment(filter.startDate) : moment(),
    endDate: filter.endDate ? moment(filter.endDate) : moment().add(1, freq as any),
    reportFrequency: freq,
    dateView: meta.allDateView ? AllReportFrequency.ALL : freq || AllReportFrequency.MONTH,
    reportType: filter.reportType,
    status: filter.status,
    accountingMethod: filter.accountingMethod,
    meta,
    possibleServices: possibleServices,
    selectedConnection: possibleServices[0],
  };
}
