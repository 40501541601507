import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '80%',
      maxWidth: 560,
      margin: 'auto',
      padding: theme.spacing(5),
      border: '1px solid #D8D8D8',
      borderRadius: 10,
      textAlign: 'center',

      '& img': {
        margin: 0,
      },
    },

    title: {
      fontSize: '1.125rem',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },

    body: {
      width: '70%',
      margin: 'auto',
      marginBottom: theme.spacing(1),
    },

    button: {
      marginTop: theme.spacing(3),
      display: 'inline-flex',
    },
  }),
);
