import React from 'react';
import { Controller } from 'react-hook-form';
import { SelectProps } from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { FormInputProps } from 'types';

import './styles.css';

export type SelectOptions = Array<{
  name: string;
  value: number | string | boolean;
  disabled?: boolean;
}>;

const MuiSelect = React.forwardRef(
  (
    props: FormInputProps & { SelectProps?: Partial<SelectProps> },
    ref: React.RefObject<HTMLDivElement>,
  ): React.ReactElement => {
    const { label, name, options, variant, errorobj, placeholder, testid, escape } = props;
    const { t } = useTranslation();
    let errorMessage = '';

    if (errorobj && get(errorobj, name, '')) {
      errorMessage = get(errorobj, name).message;
    }

    return (
      <TextField
        id={`${testid}-textfield`}
        select
        variant={variant || 'outlined'}
        FormHelperTextProps={{
          className: 'error-text',
        }}
        ref={ref}
        error={!!errorMessage}
        helperText={t(errorMessage)}
        {...props}
        placeholder={t(placeholder)}
        label={t(label)}
        inputProps={{
          id: testid,
          'data-testid': testid,
        }}
        SelectProps={{
          ...props?.SelectProps,
          MenuProps: {
            anchorEl: () => ref.current,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            ...props?.SelectProps?.MenuProps,
            PopoverClasses: {
              paper: 'menu',
            },
          },
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            id={`${testid}-${item.value}`}
            data-testid={`${testid}-option-${item.value}`}
            disabled={item.disabled}
            className="menuItem"
          >
            {escape ? item.name : t(item.name)}
          </MenuItem>
        ))}
      </TextField>
    );
  },
);

const FormSelect = (
  props: Omit<FormInputProps, 'options'> & {
    options: SelectOptions;
    SelectProps?: Partial<SelectProps>;
  },
): React.ReactElement => {
  const parentProps = props;
  return (
    <Controller
      defaultValue=""
      {...props}
      render={({ ...props }): JSX.Element => <MuiSelect {...props} {...parentProps} />}
    />
  );
};

export default FormSelect;
