import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ALL_FONTS } from '../../providers/theme-provider';

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      paddingBottom: '1.5rem',
    },
    icon: {
      color: theme.palette.primary.main,
      paddingRight: '1rem',
    },
    termsContainer: {
      fontSize: '0.75rem',
      height: '336px',
      marginTop: theme.spacing(3),
      overflowY: 'scroll',
      overflowX: 'clip',
      border: `1px solid #BDBDBD`,
      padding: theme.spacing(2),
    },
    subTitleStyle: {
      marginTop: theme.spacing(),
      fontSize: '0.875rem',
      fontFamily: ALL_FONTS,
    },
    continueButton: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    checkboxLabel: { flexWrap: 'wrap', fontSize: '0.875rem' },
    form: {
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
  });
});
