import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minHeight: theme.spacing(4),
      fontWeight: 600,
      fontSize: theme.spacing(3),
      lineHeight: '32px',
      letterSpacing: '-0.02em',
      color: '#000000',
      height: '100%',
      marginBottom: theme.spacing(),
    },
    description: {
      fontWeight: 400,
      fontSize: theme.spacing(2),
      lineHeight: '26px',
      color: '#424242',
    },
  }),
);
