import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyles from '../style';

import {
  doMfaRecoveryApi,
  getErrorResponse,
  getMessage,
  getUserResponse,
  resetAuthError,
  resetAuthResponse,
} from '../../../store/features/auth';

import storeService from '../../../store';

import { ErrorResponse } from '../../../types/interface/error.interface';

import { LoginMfa } from '../../../types';

import AlertDialog from 'components/dialog';
import { FormInput } from 'components/form';
import { EventService } from 'services';

import Title from 'components/title';

import Logo from 'components/logo';

interface IProps {
  isOpen: boolean;
  confirm: {
    label: string;
    onClick?: () => void;
  };
  close: () => void;
}

interface IFormValue {
  recoveryCode: string;
}

const initialValues: IFormValue = {
  recoveryCode: '',
};

const validationSchema = yup.object().shape({
  recoveryCode: yup.string().required('DASHBOARD_LOGIN_MFA_RECOVERY_REQUIRED').nullable(),
});

const RecoveryDialog: React.FC<IProps> = ({ isOpen, close }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const user = useSelector<ReturnType<typeof store.getState>, LoginMfa>(getUserResponse);
  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse | any>(
    getErrorResponse,
  );
  const message = useSelector<ReturnType<typeof store.getState>, string>(getMessage);

  useEffect(() => {
    if (message) {
      dispatch(resetAuthResponse());
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: t('DASHBOARD_LOGIN_MFA_RECOVERY_SUCCESS'),
      });
      history.replace('/login'); // direct them back to login
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (error) {
      dispatch(resetAuthError());
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'warning',
        message: t('DASHBOARD_LOGIN_MFA_INVALID'),
      });
    }
    // eslint-disable-next-line
  }, [error]);

  const { handleSubmit, errors, formState, control } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ recoveryCode }): Promise<void> => {
    if (recoveryCode) {
      dispatch(doMfaRecoveryApi({ userUuid: user.uuid, recoveryCode }));
    }
  };

  const { isSubmitting } = formState;

  return (
    <AlertDialog isOpen={isOpen} onClose={(): void => null}>
      <div className={classes.mfaContainer}>
        <Logo />
        <Title title="DASHBOARD_SIGNIN_MFA_FORGOT" className={classes.title} />
        <Typography
          color="textSecondary"
          variant="subtitle1"
          align="center"
          className={classes.mfaRecoveryDescription}
        >
          {t('DASHBOARD_SIGNIN_MFA_FORGOT_DESCRIPTION')}
        </Typography>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <FormInput
            className={classes.mfaRecoveryInput}
            name="recoveryCode"
            type="text"
            placeholder="DASHBOARD_SIGNIN_MFA_FORGOT_PLACEHOLDER"
            label="DASHBOARD_SIGNIN_MFA_FORGOT_LABEL"
            fullWidth
            errorobj={errors}
            control={control}
          />
          <RailzButton
            buttonType="submit"
            size="large"
            grow
            isDisabled={Boolean(errors.recoveryCode || isSubmitting)}
            label={t('DASHBOARD_SIGNIN_MFA_CTA')}
          />
        </form>
        <div className={classes.mfaRecoveryBack}>
          <RailzButton
            type="text primary"
            label={t('DASHBOARD_SIGNIN_MFA_FORGOT_BACK_CTA')}
            onButtonClick={close}
          >
            <span slot="prefix">
              <ArrowBack fontSize="small" />
            </span>
          </RailzButton>
        </div>
      </div>
    </AlertDialog>
  );
};
export default RecoveryDialog;
