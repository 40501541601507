import axios from 'axios';

import Config from 'config';
import {
  ConnectUrlResponse,
  IpWhitelistDeleteResponse,
  IpWhitelistPostResponse,
  IpWhitelistResponse,
  IpWhitelistUpdateParameter,
} from 'types';

class ConnectService {
  async getConnectUrl(): Promise<ConnectUrlResponse> {
    return await axios.get(`${Config.SERVER_URL}/widget/url`);
  }

  async getIpWhitelists(): Promise<Array<IpWhitelistResponse>> {
    return await axios.get(`${Config.SERVER_URL}/widget/ipWhitelist`);
  }

  async createIpWhitelist(payload: IpWhitelistResponse): Promise<IpWhitelistPostResponse> {
    return await axios.post(`${Config.SERVER_URL}/widget/ipWhitelist`, payload);
  }

  async updateIpWhitelist({
    payload,
    uuid,
  }: IpWhitelistUpdateParameter): Promise<IpWhitelistPostResponse> {
    return await axios.put(`${Config.SERVER_URL}/widget/ipWhitelist/${uuid}`, payload);
  }

  async deleteIpWhitelist(uuid: string): Promise<IpWhitelistDeleteResponse> {
    return await axios.delete(`${Config.SERVER_URL}/widget/ipWhitelist/${uuid}`);
  }
}

export default new ConnectService();
