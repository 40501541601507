import React from 'react';
import { Typography } from '@material-ui/core';

import ButtonPreview from '../button-preview';

import useStyle from './style';

interface Props {
  brandColor: string;
  accentColor?: string;
  title: string;
  body: string;
}

const OnboardingContent: React.FC<Props> = ({ brandColor, title, body }) => {
  const classes = useStyle();

  const charLimit = {
    title: 50,
    body: 140,
  };

  return (
    <div className={classes.visible} data-testid="onboard-preview">
      <Typography className={classes.heading} variant="h4">
        {title?.substring(0, charLimit.title)}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {body?.substring(0, charLimit.body)}
      </Typography>
      <div className={classes.inputBox}>
        <span>Business Name</span>
      </div>

      <ButtonPreview brandColor={brandColor} />
    </div>
  );
};

export default OnboardingContent;
