import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FormControlLabel, Switch, Typography } from '@material-ui/core';

import { getConnectUrl, getConnectUrlApi } from '../../../store/features/connect';

import {
  CONNECT_NPM_INSTALL_INFO,
  CONNECT_NPM_SCRIPT_INFO,
  CONNECT_SCRIPT_INFO,
  CONNECT_WIDGET_ID_INFO,
} from '../../../types/connect.constants';

import useStyle from './style';

import ConnectCode, { ConnectCodeProps } from './connect.code';

import { Header, ScrollView, View } from 'components';
import { useEventContext } from 'providers/events-provider';
import Loading from 'components/loading';
import PageDescription from 'components/page-description/page-description';
import { openNewTab } from 'helpers/open-new-tab';
import {
  getRailzConnectMetadata,
  isCustomerLoading,
} from 'store/features/account/customer/customer.selector';
import { updateCustomerApi } from 'store/features/account/customer/customer.action';
import { getUserRole } from 'store/features/account/profile/profile.selector';
import { Role } from 'types';
import { FormattedTranslation } from 'translations/helper';
import { showSnackbar } from 'helpers/common.helper';

interface Props {
  testid?: string;
}

const Widget: React.FC<Props> = ({ testid = 'test-header-widget-page' }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const { initialized, setInitialized } = useEventContext();
  const [widgetId, setWidgetId] = useState('');
  const [copied, setCopied] = useState('');
  const dispatch = useDispatch();
  const urlResult = useSelector(getConnectUrl);
  const railzConnectMetadata = useSelector(getRailzConnectMetadata);
  const isLoading = useSelector(isCustomerLoading);
  const role = useSelector(getUserRole);

  useEffect(() => {
    getConnectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlResult) {
      const { widgetId } = urlResult;
      setWidgetId(widgetId);
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlResult]);

  const getConnectInfo = (): void => {
    try {
      setInitialized(false);
      dispatch(getConnectUrlApi());
    } catch (err) {}
  };

  const scripts: ConnectCodeProps[] = [
    {
      code: CONNECT_WIDGET_ID_INFO(widgetId),
      header3: 'DASHBOARD_RAILZ_CONNECT_WIDGET_TITLE',
      description: 'DASHBOARD_RAILZ_CONNECT_WIDGET_DESCRIPTION',
      identifier: 'widgetIdInfo',
      language: 'shell',
    },
    {
      code: CONNECT_NPM_INSTALL_INFO,
      header3: 'DASHBOARD_RAILZ_CONNECT_SDK_TITLE',
      description: 'DASHBOARD_RAILZ_CONNECT_SDK_SUBTITLE',
      header4: 'DASHBOARD_RAILZ_CONNECT_NPM_TITLE',
      identifier: 'npmInfo',
      language: 'jsx',
    },
    {
      code: CONNECT_NPM_SCRIPT_INFO(widgetId),
      header4: 'DASHBOARD_RAILZ_CONNECT_NPM_CODE_TITLE',
      identifier: 'npmScriptInfo',
      language: 'javascript',
    },
    {
      code: CONNECT_SCRIPT_INFO(widgetId),
      header3: 'DASHBOARD_RAILZ_CONNECT_SCRIPT_TITLE',
      identifier: 'cdnInfo',
      language: 'html',
    },
  ];
  const canSeeBusinessCreationBlock =
    [Role.ADMINISTRATOR, Role.SUPER_ADMINISTRATOR].includes(role) && railzConnectMetadata;
  return (
    <>
      <Loading loading={!initialized} testId={'widget-page-loader'} />
      <Header
        drawerMenu
        title={t('DASHBOARD_RAILZ_CONNECT_HEADER')}
        titleAriaLabel={t('DASHBOARD_RAILZ_CONNECT_HEADER_ARIA_LABEL')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />
      <View>
        <ScrollView style={{ padding: 24 }}>
          <PageDescription className={classes.titleContainer} title="DASHBOARD_RAILZ_CONNECT_TITLE">
            <Trans
              i18nKey="DASHBOARD_RAILZ_CONNECT_SUBTITLE"
              components={{
                span: <code className={classes.bodyTag}></code>,
                a: (
                  <a
                    href="https://docs.railz.ai/docs/railz-connect-configure-and-setup"
                    onClick={(e): void =>
                      openNewTab(e, 'https://docs.railz.ai/docs/railz-connect-configure-and-setup')
                    }
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    {t('DASHBOARD_LEARN_MORE_CTA')}
                  </a>
                ),
              }}
            />
          </PageDescription>
          {canSeeBusinessCreationBlock && (
            <div className={classes.toggleContainer}>
              <Typography variant="h3" className={classes.subHeader}>
                {t('DASHBOARD_RAILZ_CONNECT_BLOCK_BUSINESS_TITLE')}
              </Typography>
              <Typography paragraph={true} className={classes.paragraph}>
                {FormattedTranslation({
                  i18nKey: 'DASHBOARD_RAILZ_CONNECT_BLOCK_BUSINESS_DESCRIPTION',
                })}
              </Typography>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      id="blockBusinessCreation"
                      checked={!railzConnectMetadata?.blockBusinessCreation}
                      inputProps={{
                        'aria-label': t(
                          `DASHBOARD_TOGGLE_${
                            !railzConnectMetadata?.blockBusinessCreation ? 'DISABLE' : 'ENABLE'
                          }`,
                        ),
                      }}
                      disabled={!!isLoading}
                      onChange={(_, allowCheckValue): void => {
                        const blockCheckValue = !allowCheckValue;
                        const data = new FormData();
                        for (const key in railzConnectMetadata) {
                          data.append(key, railzConnectMetadata[key]);
                        }
                        data.set('blockBusinessCreation', blockCheckValue.toString());
                        dispatch(updateCustomerApi(data));
                        showSnackbar({
                          message: t('DASHBOARD_SECURITY_UPDATE_SUCCESS'),
                          type: 'success',
                        });
                      }}
                      color="primary"
                    />
                  }
                  label={t('DASHBOARD_RAILZ_CONNECT_BLOCK_BUSINESS_TOGGLE')}
                  labelPlacement="start"
                />
              </div>
            </div>
          )}
          {scripts.map((script) => (
            <div className={classes.section} key={script.identifier}>
              <ConnectCode {...script} setCopied={setCopied} />
              {copied && copied === script.identifier && (
                <span className={classes.copiedText}>{t('DASHBOARD_CONNECT_COPIED')}</span>
              )}
            </div>
          ))}
        </ScrollView>
      </View>
    </>
  );
};

export default Widget;
