import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Button } from '@material-ui/core';

import { ReportInfoModalCtaButton, ReportInfoModal } from '../report-info-modal';

import ReportTable from './report-table';
import useStyles from './styles';

import BusinessReportFilter from './../business-report-filter';

import {
  getTableSortKey,
  getDefaultTableSortOrder,
  REPORT_PAGINATION_LIMIT,
  formatReportData,
  buildReportServiceParams,
  checkBusinessHasAccountingAndBanking,
} from 'helpers/report-helpers';
import { fetchReportsApi } from 'store/features/report/report.action';
import { getBusinessSummaryFilter, getReport } from 'store/features/report/report.selector';
import { getSelectedBusiness } from 'store/features/business/business.selector';
import { ReportQuery, ReportType } from 'types';
import { Business } from 'pages/business/types/interfaces';
import { ReportData } from 'store/features/report/report.state';

const SelectedReport = ({ goBack }: { goBack: () => void }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [count, setCount] = useState<number>(1);
  const [reportData, setReportData] = useState<any[]>([]);
  const [reportInformationModalData, setInformationModalData] = useState<Partial<ReportData>>();

  const [order, setOrder] = useState<string>('asc');
  const [sortBy, setSortBy] = useState<string>('');
  const [sortByValue, setSortByValue] = useState<string[]>([]);

  const [stateSelectedBusiness, setStateSelectedBusiness] = useState<Business>();
  const [hasBankingAndAsp, setHasBankingQndAsp] = useState(false);

  const dispatch = useDispatch();
  const { statusCode, loading, data, requestParams } = useSelector(getReport);
  const selectedBusiness = useSelector(getSelectedBusiness);
  const filter = useSelector(getBusinessSummaryFilter);

  useEffect(() => {
    if (
      stateSelectedBusiness &&
      stateSelectedBusiness?.businessName !== selectedBusiness?.businessName
    ) {
      goBack();
    }
    setStateSelectedBusiness(selectedBusiness);
    setHasBankingQndAsp(checkBusinessHasAccountingAndBanking(selectedBusiness));

    const businessChange = filter?.businessName !== selectedBusiness?.businessName;
    businessChange && updateReports();
  }, [selectedBusiness]);

  useEffect(() => {
    if (initialLoad) {
      const differentType = filter.reportType !== requestParams?.type;
      const differentUuid = filter.connectionUuid !== requestParams?.params?.connectionUuid;
      if (differentType || differentUuid) return;
    }
    if (data && statusCode === 200) {
      const formattedReportData = formatReportData({
        data: cloneDeep(data),
        selectedReportType: filter.reportType,
        serviceName: filter.serviceName,
      });

      if (typeof formattedReportData !== 'undefined') {
        setReportData(formattedReportData.filter(Boolean));
        setCount(Math.ceil(data.pagination?.count / REPORT_PAGINATION_LIMIT) || 1);
      }
    } else if (statusCode === 204 || !data) {
      setReportData([]);
      setCount(0);
    }
  }, [data]);

  const updateReports = ({
    localOrder = order,
    localSortBy = sortBy,
    localOffset = offset,
    query = filter,
  }: {
    localOrder?: string;
    localSortBy?: string;
    query?: ReportQuery;
    localOffset?: number;
  } = {}): void => {
    if (
      [ReportType.BANK_TRANSACTIONS, ReportType.BANK_ACCOUNTS, ReportType.BANK_ASSET].includes(
        query.reportType,
      )
    )
      query = { ...query, connectionUuid: query.bankConnectionUuid };
    const reportParams = buildReportServiceParams({ localOrder, localSortBy, localOffset, query });
    if (!reportParams) return;
    dispatch(fetchReportsApi(reportParams));
  };

  const handleOrderChange = useCallback(
    ({ key: newSortBy, order: newOrder }: { key: string; order: 'asc' | 'desc' }): void => {
      if (newOrder === order && newSortBy === sortBy) return;
      setOrder(newOrder);
      setSortBy(newSortBy);
      updateReports({ localSortBy: newSortBy, localOrder: newOrder });
    },
    [sortBy, order, filter, offset],
  );

  const generateReport = async (query): Promise<void> => {
    if (!selectedBusiness?.businessName || !query.serviceName || !query.reportType) {
      return goBack();
    }

    if (initialLoad) {
      const localSortBy = getTableSortKey(query.reportType);
      const localOrder = getDefaultTableSortOrder(localSortBy);
      setOrder(localOrder);
      setSortBy(localSortBy);
      setSortByValue([localSortBy]);
      setInitialLoad(false);
      updateReports({ localOrder, localSortBy, query });
    } else {
      updateReports({ localOrder: order, localSortBy: sortBy, query });
    }
  };

  const hasData = Array.isArray(reportData) && reportData.filter(Boolean).length > 0;

  return (
    <>
      <div className={classes.top}>
        <Button className={classes.backButton} tabIndex={0} onClick={(): void => goBack()}>
          <ArrowRightAltIcon className={classes.backIcon} />
          <span role="button" className={classes.backLink} title={t('DASHBOARD_REPORT_CTA_BACK')}>
            {t('DASHBOARD_REPORT_CTA_BACK')}
          </span>
        </Button>
      </div>
      <BusinessReportFilter
        applyFilter={generateReport}
        reportData={reportData}
        reportParamsButtonChild={
          <ReportInfoModalCtaButton
            handleOpen={(): void => setInformationModalData({ data, requestParams })}
          />
        }
      />

      <ReportTable
        reportType={filter.reportType}
        data={reportData}
        loading={loading}
        statusCode={statusCode}
        defaultSort={sortBy}
        defaultSortByValue={sortByValue}
        defaultOrder={order}
        setUserSortBy={handleOrderChange}
        setUserSortByValue={setSortByValue}
        validBankReconciliationBusiness={hasBankingAndAsp}
      />
      {hasData && (
        <div className={classes.pagination}>
          <Pagination
            data-testid="pagination"
            count={count}
            page={currentPage}
            defaultPage={1}
            onChange={(_, pagerNumber): void => {
              setCurrentPage(pagerNumber);
              const newOffset = (pagerNumber - 1) * REPORT_PAGINATION_LIMIT;
              setOffset(newOffset);
              updateReports({ localOffset: newOffset });
            }}
          />
        </div>
      )}

      {reportInformationModalData && (
        <ReportInfoModal
          reportName={filter.reportName}
          data={reportInformationModalData}
          handleClose={(): void => setInformationModalData(null)}
        />
      )}
    </>
  );
};

export default SelectedReport;
