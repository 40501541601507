import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    colorContainer: {
      overflow: 'hidden',
      width: '36px',
      height: '26px',
      borderRadius: '4px',
      boxShadow: '1px 1px 3px 0px grey',
      margin: '4px 0',
    },
    colorInput: {
      padding: '0',
      width: '150%',
      height: '150%',
      margin: '-25%',
    },
  }),
);
