import React, { useEffect, useState } from 'react';
import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@material-ui/icons/Refresh';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from '../style';

import ColorPicker from '../color-picker';

import { DefaultBrandColors } from 'types';
import { getHexValue } from 'pages/sites/utils';

const tooltipStyle = makeStyles(() => ({
  arrow: {
    color: '#003032',
    opacity: '0.9',
  },
  tooltip: {
    backgroundColor: '#003032',
    opacity: '0.9',
    fontSize: '14px',
  },
}));

interface Props {
  brandColor: string;
  setBrandColor: (brandColor: string) => void;
  accentColor: string;
  setAccentColor: (accentColor: string) => void;
  brandColorInvalid: boolean;
  accentColorInvalid: boolean;
  userIsOnFreePlan: boolean;
}

export default function BrandSection({
  brandColor,
  setBrandColor,
  accentColor,
  setAccentColor,
  brandColorInvalid,
  accentColorInvalid,
  userIsOnFreePlan,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const tooltipClasses = tooltipStyle();
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    setIsDefault(
      getHexValue(brandColor)?.toUpperCase() === DefaultBrandColors.BRAND.toUpperCase() &&
        getHexValue(accentColor)?.toUpperCase() === DefaultBrandColors.ACCENT.toUpperCase(),
    );
  }, [brandColor, accentColor]);

  return (
    <div className={userIsOnFreePlan ? classes.brandDisabled : null}>
      <Typography className={classes.description}>{t('DASHBOARD_SITES_BRAND_BODY')}</Typography>
      <Typography variant="h4">{t('DASHBOARD_BRAND_COLOURS_HEADING')}</Typography>

      <div className={classes.colorSelectors}>
        <div className={classes.colorSelector}>
          <div className={classes.labelContainer}>
            <span className={classes.label}>{t('DASHBOARD_BRAND_BRAND_COLOR')}</span>
            <Tooltip
              arrow
              title={t('DASHBOARD_BRAND_TOOLTIP_BRAND')}
              placement="right"
              classes={tooltipClasses}
            >
              <HelpIcon style={{ fontSize: '14px', color: '#424242' }} />
            </Tooltip>
          </div>
          <div>
            {brandColor ? (
              <ColorPicker
                ariaLabel={t('DASHBOARD_BRAND_ARIA')}
                color={brandColor}
                setColor={setBrandColor}
                testid="brand-color-picker"
                disabled={userIsOnFreePlan}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <span className={classes.error}>
            {brandColorInvalid && t('DASHBOARD_BRAND_HEX_INVALID')}
          </span>
        </div>
        <div className={classes.colorSelector}>
          <div className={classes.labelContainer}>
            <span className={classes.label}>{t('DASHBOARD_BRAND_ACCENT_COLOR')}</span>
            <Tooltip
              arrow
              title={t('DASHBOARD_BRAND_TOOLTIP_ACCENT')}
              placement="right"
              classes={tooltipClasses}
            >
              <HelpIcon style={{ fontSize: '14px', color: '#424242' }} />
            </Tooltip>
          </div>
          <div>
            {accentColor ? (
              <ColorPicker
                ariaLabel={t('DASHBOARD_ACCENT_ARIA')}
                color={accentColor}
                setColor={setAccentColor}
                testid="accent-color-picker"
                disabled={userIsOnFreePlan}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <span className={classes.error}>
            {accentColorInvalid && t('DASHBOARD_BRAND_HEX_INVALID')}
          </span>
        </div>
      </div>
      {!isDefault && (
        <div className={classes.brandResetContainer}>
          <RailzButton
            data-testid="reset-colors"
            type="text warning"
            buttonType="button"
            onClick={(): void => {
              setBrandColor(DefaultBrandColors.BRAND);
              setAccentColor(DefaultBrandColors.ACCENT);
            }}
            label={t('DASHBOARD_SETTINGS_SITES_BRAND_RESET')}
          >
            <span slot="prefix">
              <RefreshIcon fontSize="small" />
            </span>
          </RailzButton>
        </div>
      )}
    </div>
  );
}
