/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@material-ui/icons/Refresh';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from '../style';

import { FormInput } from 'components/form';

interface Props {
  disabled: boolean;
  defaultContent: any;
  form: any;
}

export default function OnboardLandingPageSection({
  disabled,
  defaultContent,
  form: { errors, control, setValue, clearErrors, getValues, watch },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isDefault, setIsDefault] = useState(false);

  const watchAllFields = watch();
  useEffect(() => {
    const { onboardLandingPageTitle, onboardLandingPageBody } = getValues();
    setIsDefault(
      onboardLandingPageTitle === defaultContent.onboardLandingPageTitle &&
        onboardLandingPageBody === defaultContent.onboardLandingPageBody,
    );
  }, [watchAllFields]);

  return (
    <Grid container className={disabled ? classes.uploadDisabled : null}>
      <Typography className={classes.description}>
        {t('DASHBOARD_SETTINGS_SITES_LANDING_PAGE_DESCRIPTION')}
      </Typography>
      <Grid container>
        <div className={classes.inputContainer}>
          <FormInput
            defaultValue=""
            label={t('DASHBOARD_SETTINGS_SITES_LANDING_PAGE_TEXT_FIELD_TITLE')}
            placeholder={t('DASHBOARD_SITES_ONBOARDING_HEADING')}
            id="onboardLandingPageTitle"
            name="onboardLandingPageTitle"
            margin="dense"
            testid="onboarding-title-input"
            fullWidth
            errorobj={errors}
            control={control}
            className={classes.contentInputItem}
          />
          <FormInput
            defaultValue=""
            label={t('DASHBOARD_SETTINGS_SITES_LANDING_PAGE_TEXT_FIELD_BODY')}
            placeholder={t('DASHBOARD_SITES_ONBOARDING_BODY')}
            id="onboardLandingPageBody"
            name="onboardLandingPageBody"
            margin="dense"
            testid="onboarding-body-input"
            fullWidth
            errorobj={errors}
            control={control}
            className={classes.contentInputItem}
            {...{ multiline: true, minRows: 4 }}
          />

          {!isDefault && (
            <RailzButton
              data-testid="reset-onboard-landing"
              type="text warning"
              buttonType="button"
              onClick={(): void => {
                setValue('onboardLandingPageTitle', defaultContent.onboardLandingPageTitle);
                setValue('onboardLandingPageBody', defaultContent.onboardLandingPageBody);
                clearErrors();
              }}
              label={t('DASHBOARD_SETTINGS_SITES_RESET_CONTENT')}
            >
              <span slot="prefix">
                <RefreshIcon fontSize="small" />
              </span>
            </RailzButton>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
