import React from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import Search from '../form/search';

interface Props {
  handleSearch: (value) => void;
  i18nKey?: string;
  style?: any;
  className?: string;
}

export default function SearchItems({
  handleSearch,
  i18nKey = 'DASHBOARD_SEARCH_ITEM',
  style,
  className,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const debounceHandleSearch = debounce((event) => {
    handleSearch(event.target.value);
  }, 100);

  return (
    <Search
      className={className}
      style={style}
      handleSearch={debounceHandleSearch}
      inputProps={{
        'aria-label': t(i18nKey),
      }}
      data-testid="search-input"
      placeholder={t(i18nKey)}
      type="button"
    />
  );
}
