import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    labelDescription: {
      fontSize: '1rem',
      fontWeight: 600,
      marginTop: theme.spacing() * 2,
    },
    button: {
      marginTop: theme.spacing() * 2,
    },
  }),
);
