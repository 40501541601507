/* eslint-disable max-len */
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing, typography, palette }: Theme) => {
  return createStyles({
    formGrid: {
      '& .MuiGrid-item:last-child': {
        paddingBottom: 0,
      },
    },
    subheader: { marginBottom: spacing() * 2 },
    url: { marginBottom: spacing() },
    kindHeader: {
      marginBottom: spacing(),
      fontWeight: typography.fontWeightBold as number,
      lineHeight: '17px',
      fontSize: '0.875rem',
    },
    kinds: {
      marginTop: spacing(1),
      '& .MuiTypography-root': {
        lineHeight: '17px',
        fontSize: '0.875rem',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: spacing(1),
      },
    },
    kind: {
      margin: `${spacing(0.5)}px ${spacing(1)}px`,
      marginLeft: spacing(1.5),
      padding: 2,
    },
    dataPerTypeEvent: {
      margin: '0px',

      '&>.MuiAccordion-root': {
        // base accordion
        margin: `${spacing(0)}px ${spacing(0)}px 0px`,
        '&>.MuiButtonBase-root': {
          // accordion header
          justifyContent: 'flex-start',
          '&>.MuiAccordionSummary-content': {
            flexGrow: 0,
          },
        },

        '&>.MuiCollapse-root': {
          // accordion body
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: '22vh',
        },
      },

      // this affects both the main and sub accordions
      '& .MuiAccordion-root': {
        padding: 0,
        border: 0,
        boxShadow: 'none',
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    },
    requestInputAccordion: {
      // each sub-accordion
      margin: `0`,
      '&:not(:last-child)': {
        borderBottom: '1px solid #E0E0E0',
      },

      '& .MuiButtonBase-root.MuiAccordionSummary-root': {
        // accordion header
        paddingLeft: spacing(2),
        borderRadius: 0,
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        '& .MuiAccordionSummary-content': {
          fontSize: '0.625rem',
          color: palette.grey[800],
          textTransform: 'uppercase',
          lineHeight: `${spacing(2)}px`,
          fontWeight: 600,
          letterSpacing: '0.6px',
        },
      },
      '& .MuiCollapse-root': {
        // accordion body
        borderTop: '1px solid #E0E0E0',
        paddingLeft: spacing(1),
        '& .MuiFormControlLabel-root': {
          marginLeft: spacing(-1),
        },
      },
    },
    confirmationAlert: {
      '&.MuiGrid-root': {
        margin: `${2}px ${spacing(1)}px 0px`,
        padding: 0,
      },
      '& .MuiAlert-root': {
        padding: `${spacing(2)}px ${spacing(2)}px ${spacing(2)}px`,
        backgroundColor: '#FDF7E7',
      },
      '& .MuiAlert-icon': {
        color: '#F2A74C',
        marginRight: spacing(2),
        padding: '3px 0px 0px',
      },
      '& .MuiAlert-message': {
        color: palette.grey[800],
        fontSize: '1rem',
        lineHeight: '1.5rem',
        padding: 0,
      },
    },
    eventError: {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: -3,
    },
  });
});
