import React, { useEffect, useState } from 'react';
import { DatePickerView, KeyboardDatePicker } from '@material-ui/pickers';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { Moment } from 'moment-timezone';

import CustomToolbar from './toolbar';
import './styles.css';
import { popOverStyles } from './style';

import QuarterSelection from './quarter-selection';

import { AllReportFrequency, ReportFrequency } from 'types';

interface Props {
  label: string;
  placeholder: string;
  value: Moment;
  onChange: (date: Moment) => void;
  ariaLabel: string;
  inputVariant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  autoOk?: boolean;
  variant?: 'dialog' | 'inline' | 'static';
  format?: string;
  view?: string;
  goForward?: (date: Moment) => void;
  goBack?: (date: Moment) => void;
  disableFuture?: boolean;
  minDate?: Date; // min/maxDate require a native date obj
  maxDate?: Date;
  minDateErrorMessage?: string;
  maxDateErrorMessage?: string;
  invalidDateMessage?: string;
  disabled?: boolean;
}

const defaultView: DatePickerView[] = ['date'];

export default function DatePicker({
  label,
  placeholder,
  value,
  onChange,
  inputVariant = 'outlined',
  size = 'small',
  autoOk = true,
  variant = 'inline',
  format = 'MM/DD/YYYY',
  view,
  disableFuture = false,
  minDate,
  maxDate,
  minDateErrorMessage,
  maxDateErrorMessage,
  disabled,
  ariaLabel,
  invalidDateMessage,
}: Props): JSX.Element {
  const popOverClass = popOverStyles();
  const [views, setViews] = useState(defaultView);
  const [isStartDate, setIsStartDate] = useState(false);

  useEffect(() => {
    switch (view) {
      case AllReportFrequency.ALL:
        setViews(defaultView);
        break;
      case AllReportFrequency.YEAR:
        setViews([ReportFrequency.YEAR]);
        break;
      case AllReportFrequency.MONTH:
        setViews([AllReportFrequency.MONTH]);
        break;
      case AllReportFrequency.QUARTER:
        setIsStartDate(label.toLowerCase() === 'start date');
        break;
      default:
        setViews([AllReportFrequency.MONTH]);
    }
  }, [view]);

  return view === AllReportFrequency.QUARTER ? (
    <QuarterSelection
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      isStartDate={isStartDate}
      size={size}
      format={format}
      view={view}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
    />
  ) : (
    <KeyboardDatePicker
      className={popOverClass.root}
      ToolbarComponent={(props): any => (
        <CustomToolbar
          date={value}
          {...props}
          goForward={(date): void => onChange(date)}
          goBack={(date): void => onChange(date)}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
      invalidDateMessage={invalidDateMessage}
      disabled={disabled}
      disableToolbar={views === defaultView}
      fullWidth
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(date): void => onChange(date)}
      inputVariant={inputVariant}
      size={size}
      autoOk={autoOk}
      variant={variant}
      format={format}
      views={views}
      disableFuture={disableFuture}
      minDate={minDate}
      maxDate={maxDate}
      minDateMessage={minDateErrorMessage}
      maxDateMessage={maxDateErrorMessage}
      InputLabelProps={{
        shrink: true,
      }}
      KeyboardButtonProps={{
        'aria-label': ariaLabel,
      }}
      keyboardIcon={<CalendarTodayOutlinedIcon />}
      margin="normal"
      orientation="portrait"
      inputProps={{
        'data-testid': 'date-picker',
      }}
      PopoverProps={{
        classes: view === ReportFrequency.YEAR ? popOverClass : undefined,
      }}
    />
  );
}
