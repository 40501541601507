import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing() * 2,
    },
    labelDescription: {
      fontWeight: 600,
      fontSize: 16,
    },
    labelTopDescription: {
      fontWeight: 600,
      fontSize: 16,
      marginTop: theme.spacing() * 2,
    },
    section: {
      marginBottom: theme.spacing(),
    },
  }),
);
