import { createSelector } from 'reselect';

import { AllState } from '../../../state';

import { DEVELOPER_STORE_NAME } from '../developer.state';

import { WEBHOOK_STORE_NAME, WebhookState } from './webhook.state';

/** Select Webhook State */
export const getWebhookState = (state: AllState): WebhookState =>
  state[DEVELOPER_STORE_NAME][WEBHOOK_STORE_NAME];

export const isWebhookLoading = createSelector(
  getWebhookState,
  (webhook: WebhookState) => webhook && webhook.loading,
);

export const getWebhooks = createSelector(
  getWebhookState,
  (webhook: WebhookState) => webhook && !webhook.loading && webhook.webhooks,
);

export const getWebhookErrorMessage = createSelector(
  getWebhookState,
  (webhook: WebhookState) => webhook && webhook.error && webhook.error.message,
);

export const getWebhookApiResponse = createSelector(
  getWebhookState,
  (webhook: WebhookState) => webhook && webhook.response,
);

export const getWebhookHistory = createSelector(getWebhookState, (webhook: WebhookState) => {
  return {
    loading: webhook && webhook.webhookHistoryLoading,
    webhookHistory: webhook && webhook.webhookHistory,
  };
});
