import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles(({ spacing, palette, typography }: Theme) =>
  createStyles({
    tableFontSize: {
      fontSize: '0.75rem',
      color: palette.grey[800],
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '0.72px',
      paddingBottom: spacing(1.5),
    },

    listElementWrapper: {
      verticalAlign: 'middle',
      borderBottom: `1px solid #D8D8D8`,
      '&:last-child': {
        borderBottom: '1px solid transparent',
      },
      '& .MuiTypography-body1': {
        paddingTop: 5,
      },
    },
    listElement: {
      padding: `${spacing(1.5)}px 0px`,
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      lineHeight: '1.75rem',

      '& .MuiTypography-subtitle2': {
        lineHeight: '1.75rem',
        fontSize: '1rem',
        fontWeight: typography.fontWeightRegular as number,
      },
    },

    checkBox: {
      padding: `${spacing(2)}px ${spacing(1)}px ${spacing(1.5)}px ${spacing(1.5)}px`,
    },
    listDescription: {
      marginBottom: spacing(2),
      color: palette.grey[800],
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },

    bottomError: {
      margin: 0,
    },
    mainGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
      margin: `${spacing(0)}px ${spacing(0)}px`,
      '& > .MuiGrid-root': {
        margin: `${spacing(-0.5)}px ${spacing(0)}px`,
      },
    },

    confirmationAlert: {
      '&.MuiGrid-root': {
        margin: `${2}px ${spacing(1)}px 0px`,
        padding: 0,
      },
      '& .MuiAlert-root': {
        padding: `${spacing(2)}px ${spacing(2)}px ${spacing(3)}px`,
        backgroundColor: '#FDF7E7',
      },
      '& .MuiAlert-icon': {
        color: '#F2A74C',
        marginRight: '16px',
        padding: '3px 0px 0px',
      },
      '& .MuiAlert-message': {
        color: palette.grey[800],
        fontSize: '1rem',
        lineHeight: '1.5rem',
        padding: 0,
      },
    },

    copiedToClipboard: { marginTop: spacing(0.5), fontSize: '0.75rem' },
    generateApiKeyText: {
      width: '100%',
    },
    successDescriptionContainer: {
      '& *': {
        display: 'inline',
      },
      '& svg': {
        verticalAlign: 'middle',
        marginRight: spacing(2),
      },
      '& p': {
        verticalAlign: 'middle',
      },
      marginBottom: spacing(4),
      color: palette.grey[800],
    },
    newKeyDescriptionBold: {
      fontWeight: 'bold',
    },
    clipboardInput: {
      '& input:disabled': {
        color: palette.grey[800],
      },
      fontVariant: 'none',
    },
  }),
);
