import {
  ApiResponse,
  CustomerResponse,
  Plan,
  RailzConnectMetadata,
  RailzSitesMetadata,
} from '../../../../types';
import { Team } from '../team/team.state';
import { ErrorResponse } from '../../../../types/interface/error.interface';

export const CUSTOMER_INITIAL_STATE: CustomerState = {
  companyName: '',
  legalEntityName: '',
  address: '',
  country: '',
  city: '',
  province: '',
  postalCode: '',
  phoneNumber: '',
  website: '',
  taxId: '',
  apiPurpose: '',
  businessRole: '',
  tocDate: '',
  tocIPAddress: '',
  tocUserAgent: '',
  techContactName: '',
  techContactEmail: '',
  billContactName: '',
  billContactEmail: '',
  billingPlan: null,
  beta: null,
  state: '',
  createdAt: '',
  uuid: '',
  railzConnectMetadata: null,
  railzSitesMetadata: null,
  sessionURL: '',
  baseLoading: true,
  loading: true,
  error: null,
  response: null,
  teams: [],
  emailSuggestions: [],
  emailSuggestionsInputIndex: 0,
};

export interface StripePortalParam {
  railzRedirectUrl: string;
}

export interface StripePortalResponse {
  customerPortalUrl: string;
}

export interface EnableDisableSSORequest {
  ssoEnabled: boolean;
}

export interface TransferOwnershipRequest {
  userUuid: string;
  currentPassword: string;
}

export interface TransferOwnershipAction {
  payload: TransferOwnershipRequest;
  type: string;
}

export const CUSTOMER_STORE_NAME = 'customer';

export interface CustomerAction {
  payload: any;
  type: string;
}

export interface CustomerContact {
  contactType: string;
  contactName: string;
  contactEmail: string;
}

export interface CustomerSendMail {
  message: string;
}

export interface CompanyResponse {
  response: ApiResponse;
  customer: CustomerResponse;
}

export interface EmailSearch {
  search: string;
  index: number;
}

export interface EmailSearchResponse {
  emails: [];
  index: number;
}

export interface CustomerState {
  companyName: string;
  legalEntityName: string;
  address: string;
  country: string;
  city: string;
  province: string;
  postalCode: string;
  phoneNumber: string;
  website: string;
  taxId: string;
  apiPurpose: string;
  businessRole: string;
  tocDate: string;
  tocIPAddress: string;
  tocUserAgent: string;
  techContactName: string;
  techContactEmail: string;
  billContactName: string;
  billContactEmail: string;
  billingPlan?: Plan;
  beta: boolean;
  state: string;
  createdAt: string;
  uuid: string;
  railzConnectMetadata: RailzConnectMetadata;
  ssoEnabled?: boolean;
  railzSitesMetadata: RailzSitesMetadata;
  sessionURL: string;
  baseLoading: boolean;
  loading: boolean;
  error: ErrorResponse | any;
  response: ApiResponse;
  teams: Team[];
  emailSuggestions: string[];
  emailSuggestionsInputIndex: number;
}
