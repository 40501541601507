import React from 'react';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment-timezone';

import useStyle from './style';

interface Props {
  label?: string;
  date: Date | string | Moment;
  format?: string;
  testId?: string;
}

export default function DateDisplay({
  label,
  date,
  format = 'DD MMM YYYY',
  testId,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <>
      {label && (
        <Typography className={classes.title} data-testid={`${testId}-label`}>
          {t(label)}
        </Typography>
      )}

      {date && (
        <Typography className={classes.date} data-testid={`${testId}-date`}>
          {moment(date).format(format)}
        </Typography>
      )}
    </>
  );
}
