import React, { useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { deleteBusinessApi } from '../../../store/features/business/business.action';

import { Business } from '../types/interfaces';

import {
  getResponseMessage,
  isSuccess,
  getResponse,
  getErrorMessage,
} from '../../../store/features/business/business.selector';

import { useBusinessContext } from '../provider/business-event';

import useStyle from './style';

import AlertDialog from 'components/dialog';

import { showSnackbar } from 'helpers/common.helper';

interface Props {
  currentBusiness: Business;
  updateBusiness: () => void;
}

const BusinessDelete: React.FC<Props> = ({ currentBusiness, updateBusiness }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { openDeleteBusinessModal, setOpenDeleteBusinessModal } = useBusinessContext();
  const response: any = useSelector(getResponse);
  const responseMessage = useSelector(getResponseMessage);
  const success = useSelector(isSuccess);
  const errorMessage = useSelector(getErrorMessage);

  const handleConfirm = (): void => {
    dispatch(deleteBusinessApi(encodeURIComponent(currentBusiness?.businessName)));
    setOpenDeleteBusinessModal(false);
  };

  useEffect(() => {
    if (responseMessage) {
      if (success && responseMessage === 'deleteBusiness') {
        showSnackbar({
          message: (
            <div>
              <Trans
                i18nKey={'DASHBOARD_DELETE_BUSINESS_SUCCESS_TOAST'}
                shouldUnescape={true}
                tOptions={{ interpolation: { escapeValue: true } }}
                values={{ name: currentBusiness?.businessName }}
                components={{ bold: <strong /> }}
              />
            </div>
          ),
          type: 'success',
        });
      }
      updateBusiness();
    }
  }, [responseMessage]);

  useEffect(() => {
    if ((errorMessage && errorMessage.includes('deleteBusiness')) || response?.error) {
      showSnackbar({
        message: (
          <div>
            <Trans
              i18nKey={'DASHBOARD_DELETE_BUSINESS_FAIL_TOAST'}
              shouldUnescape={true}
              tOptions={{ interpolation: { escapeValue: true } }}
              values={{ name: currentBusiness?.businessName }}
              components={{ bold: <strong /> }}
            />
          </div>
        ),
        type: 'error',
      });
    }
  }, [errorMessage, response?.error]);

  return (
    <AlertDialog
      showCloseButton
      isOpen={openDeleteBusinessModal}
      title={t('DASHBOARD_DELETE_BUSINESS_HEADER')}
      onClose={(): void => setOpenDeleteBusinessModal(false)}
      cancel={{
        label: t('DASHBOARD_DELETE_BUSINESS_CANCEL'),
        type: 'gray',
        onClick: (): void => setOpenDeleteBusinessModal(false),
      }}
      confirm={{
        label: t('DASHBOARD_DELETE_BUSINESS_CONFIRM'),
        onClick: handleConfirm,
        type: 'error',
      }}
    >
      <>
        <div className={classes.container}>
          <div className={classes.text}>
            <Trans
              i18nKey="DASHBOARD_DELETE_BUSINESS_TEXT"
              shouldUnescape={true}
              tOptions={{ interpolation: { escapeValue: true } }}
              values={{
                businessName: currentBusiness?.businessName,
              }}
              components={{ bold: <strong /> }}
            />
          </div>
        </div>
      </>
    </AlertDialog>
  );
};

export default BusinessDelete;
