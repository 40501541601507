import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Switch, TextField, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import moment from 'moment';

import { RailzButton } from '@railzai/railz-uikit-react';

import RefreshIcon from '@material-ui/icons/Refresh';

import useStyle from '../style';

import { showSnackbar, setClipboard } from 'helpers/common.helper';

import customerService from 'services/customer.service';
import AlertDialog from 'components/dialog';
import { openNewTab } from 'helpers/open-new-tab';

interface Props {
  saveBusinessUrlEnabled: boolean;
  setSaveBusinessUrlEnabled: (saveBusinessUrlEnabled: boolean) => void;
  setSaveUrl: (saveUrl: string) => void;
}

export default function UrlSection({
  saveBusinessUrlEnabled,
  setSaveBusinessUrlEnabled,
  setSaveUrl,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const [customUrl, setCustomUrl] = useState('');
  const [showCopyDone, setShowCopyDone] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [urlRefreshed, setUrlRefreshed] = useState(false);

  const copyUrl = (): void => {
    setClipboard(customUrl).catch(console.error);
    setShowCopyDone(true);
  };

  const handleReset = (): void => {
    setShowModal(true);
    setShowCopyDone(false);
    setUrlRefreshed(false);
  };

  const refreshUrl = async (): Promise<void> => {
    setResetLoading(true);

    await getUrl(true);
  };

  const getUrl = async (refresh = false): Promise<void> => {
    return await customerService
      .getUrlTeam({ refresh })
      .then((responseGetUrl) => {
        const { url, createdAt } = responseGetUrl;

        setCustomUrl(url);
        setSaveUrl(url);
        setCreatedAt(moment(createdAt).format('MMM DD, YYYY, HH:mma'));

        if (refresh) {
          setUrlRefreshed(true);
          showSnackbar({
            message: t('DASHBOARD_SITES_LINK_SUCCESS_RESET'),
            type: 'success',
          });
        }
      })

      .catch((error) => {
        if (error) {
          showSnackbar({
            message: t('DASHBOARD_SITES_LINK_FAIL_RESET'),
            type: 'error',
          });
          setResetLoading(false);
        }
      });
  };

  useEffect(() => {
    getUrl().catch((error) => {
      console.error;
      if (error) {
        showSnackbar({
          message: t('DASHBOARD_SITES_LINK_FAIL_RESET'),
          type: 'error',
        });
      }
    });
  }, []);

  return (
    <Grid container data-testid="url-section">
      <Typography className={classes.description}>
        <Trans
          i18nKey="DASHBOARD_SETTINGS_SITES_LINK_SUBTITLE"
          components={{
            a: (
              <a
                onClick={(e): void =>
                  openNewTab(e, 'https://docs.railz.ai/docs/railz-sites-generate-link')
                }
                rel="noopener noreferrer"
                className={classes.linkLearnMore}
                href="https://docs.railz.ai/docs/railz-sites-generate-link"
              >
                {t('DASHBOARD_LEARN_MORE_CTA')}
              </a>
            ),
          }}
        />
      </Typography>
      <TextField
        value={customUrl}
        label={t('DASHBOARD_SETTINGS_URL_LABEL')}
        variant="outlined"
        fullWidth
        InputProps={
          customUrl
            ? {
                endAdornment: (
                  <IconButton className={classes.iconInputUrl} onClick={copyUrl}>
                    <FileCopyIcon />
                  </IconButton>
                ),
              }
            : null
        }
      />
      {showCopyDone && (
        <Grid container>
          <Typography className={classes.clipBoardText}>
            {t('DASHBOARD_GENERATE_LINK_COPIED')}
          </Typography>
        </Grid>
      )}
      <Grid container className={classes.containerRefreshLink}>
        <RailzButton
          data-testid="refresh-cta"
          type="text warning"
          buttonType="button"
          onClick={handleReset}
          label={t('DASHBOARD_RESET_LINK_CTA')}
        >
          <span slot="prefix" className={resetLoading ? 'resetLoading' : ''}>
            <RefreshIcon fontSize="small" />
          </span>
        </RailzButton>
        {createdAt && (
          <Typography className={classes.createdAt}>
            {t('DASHBOARD_SETTINGS_SITES_LINK_UPDATED')}: {createdAt}
          </Typography>
        )}
      </Grid>
      <Grid container className={classes.saveSitesUrlContainer}>
        <Typography className={classes.subHeader}>
          {t('DASHBOARD_SETTINGS_SITES_SAVE_URL_HEADER')}
        </Typography>
        <Typography className={classes.description}>
          {t('DASHBOARD_SETTINGS_SITES_SAVE_URL_DESCRIPTION')}
        </Typography>
        <div className={classes.inputContainer}>
          <div className={classes.toggleContainer}>
            <Typography className={classes.toggleText}>
              {t('DASHBOARD_SETTINGS_SITES_SAVE_URL_TOGGLE')}
            </Typography>
            <Switch
              checked={saveBusinessUrlEnabled}
              data-testid="saveBusinessUrlEnabled-switch"
              inputProps={{
                'aria-label': `${t(
                  saveBusinessUrlEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )}`,
              }}
              onChange={(e): void => setSaveBusinessUrlEnabled(e.target.checked)}
              color="primary"
            />
          </div>
        </div>
      </Grid>
      <AlertDialog
        cancel={
          !urlRefreshed
            ? {
                label: t('DASHBOARD_CANCEL_CTA'),
                type: 'gray',
                onClick: (): void => {
                  setShowModal(false);
                  setResetLoading(false);
                },
                'data-testid': 'alert-cancel-button',
              }
            : null
        }
        confirm={
          urlRefreshed
            ? {
                label: 'Done',
                onClick: (): void => {
                  setShowModal(false);
                  setResetLoading(false);
                  setUrlRefreshed(false);
                },
              }
            : {
                label: t('DASHBOARD_RESET_LINK_CTA'),
                onClick: refreshUrl,
                type: 'error',
              }
        }
        isOpen={showModal}
        title={
          urlRefreshed
            ? 'New Onboarding Link'
            : t('DASHBOARD_SETTINGS_SITES_RESET_LINK_MODAL_HEADER')
        }
        onClose={(): void => {
          setShowModal(false);
          setResetLoading(false);
          setUrlRefreshed(false);
        }}
        showCloseButton
      >
        {urlRefreshed ? (
          <>
            <TextField
              value={customUrl}
              label={t('DASHBOARD_SETTINGS_URL_LABEL')}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton className={classes.iconInputUrl} onClick={copyUrl}>
                    <FileCopyIcon />
                  </IconButton>
                ),
              }}
            />
            {showCopyDone && (
              <Grid container>
                <Typography className={classes.clipBoardText}>
                  {t('DASHBOARD_GENERATE_LINK_COPIED')}
                </Typography>
              </Grid>
            )}
          </>
        ) : (
          <Typography>{t('DASHBOARD_SETTINGS_SITES_RESET_LINK_MODAL_BODY')}</Typography>
        )}
      </AlertDialog>
    </Grid>
  );
}
