export const getHexValue = (color: string): string => {
  if (!color) {
    return null;
  }
  return color.indexOf('#') < 0 ? color : color.substring(1);
};

export function getPossibleFileObjectURL(possibleFile: File | string | null): string | null {
  if (!possibleFile) return null;
  return typeof possibleFile === 'object' ? URL.createObjectURL(possibleFile) : possibleFile;
}

export const isHexColor = (color: string): boolean => {
  if (!color) return false;
  const hex = getHexValue(color);
  return /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(hex);
};
