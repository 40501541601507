import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(1),

      '& .MuiFormControl-marginDense': {
        marginTop: 0,
        marginBottom: theme.spacing(0.25),
      },
    },
    deleteButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    deleteButton: {
      display: 'block',
    },
    selectTeam: {
      padding: '0 !important',
      marginBottom: theme.spacing(2),

      '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 12px) scale(1) !important',
      },
    },
  }),
);
