import React from 'react';

import { PaginationData } from '../types';

import { getAllReportsPagesOfData } from './export-large-report';

import { ReportDownloadProgress } from './report-download-progress';

import { showSnackbar } from 'helpers/common.helper';
import { ReportQuery } from 'types';

/**
 * Full Large Report Download logic entry point.
 * It opens the snackbar with the progress bar and the cancel button.
 * It's the one that executes (and returns the results of) the download.
 */
export default async function ReportDownload(
  initialPaginationData: PaginationData,
  filter: ReportQuery,
  controller: AbortController,
): Promise<any[]> {
  showSnackbar({
    topic: 'report-export',
    message: <ReportDownloadProgress />,
    styles: {
      backgroundColor: '#EAF4FC',
      color: '#000',
      height: '88px',
      flexWrap: 'nowrap',
    },
    action: {
      title: 'Cancel',
      onClick: (): void => controller.abort('Canceled by the user.'),
    },
    type: 'raw',
    isPinned: true,
    hideCloseButton: true,
  });

  return getAllReportsPagesOfData(initialPaginationData, filter, controller.signal);
}
