import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';

import useStyle from './style';

import { showSnackbar } from 'helpers/common.helper';
import { getPossibleFileObjectURL } from 'pages/sites/utils';

interface Props {
  id: string;
  onChange: (uploadImage: string) => void;
  value?: string | File | null;
  maxBlockSize?: number;
  fileType?: 'image' | 'imageWithSvg' | 'favicon';
}

const mimeTypes = {
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  ico: 'image/vnd.microsoft.icon',
  ico2: 'image/x-icon',
};

const acceptedFormats = {
  image: [mimeTypes.jpeg, mimeTypes.png],
  imageWithSvg: [mimeTypes.jpeg, mimeTypes.png, mimeTypes.svg],
  favicon: [mimeTypes.ico, mimeTypes.ico2, mimeTypes.png, mimeTypes.jpeg, mimeTypes.svg],
};

export default function Dropzone({
  id = '',
  onChange,
  value,
  maxBlockSize = 512000,
  fileType = 'image',
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();

  const shownValue = useMemo(() => getPossibleFileObjectURL(value), [value]);

  const changeUploadImage = (newValue): void => {
    const file = newValue ? newValue[0] : null;
    if (file) {
      if (maxBlockSize < file.size) {
        showSnackbar({ message: t('DASHBOARD_BRAND_UPLOAD_MAX_SIZE'), type: 'error' });
        return;
      }
    }
    onChange?.(file);
  };

  const onDrop = useCallback(changeUploadImage, [onChange, t]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFormats[fileType],
  });

  return (
    <div
      {...getRootProps()}
      className={classes.container}
      data-testid={`dropzone${id ? '-' + id : ''}`}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className={classes.upload}>
          <PublishIcon />
          <span>{t('DASHBOARD_BRAND_UPLOAD_DROP_FILE_HERE')}</span>
        </div>
      ) : (
        <>
          {shownValue && (
            <IconButton
              aria-label="delete"
              className={classes.closeButton}
              onClick={(event): void => {
                event.stopPropagation();
                changeUploadImage(null);
              }}
              data-testid="delete-button"
            >
              <DeleteOutlineIcon className={classes.trashCta} />
            </IconButton>
          )}
          <div className={classes.upload} data-testid={`dropzone-preview${id ? '-' + id : ''}`}>
            {shownValue ? (
              <div className={classes.preview}>
                <img
                  className={classes.imagePreview + ' ' + fileType}
                  src={shownValue}
                  alt={t('DASHBOARD_BRAND_UPLOAD_ALT_TEXT')}
                  data-testid="image"
                />
              </div>
            ) : (
              <>
                <PublishIcon data-testid="publish-icon" />
                <span>{t('DASHBOARD_BRAND_UPLOAD_TEXT')}</span>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
