import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import { Typography } from '@material-ui/core';

import { RailzButton } from '@railzai/railz-uikit-react';

import { FormCheckBox } from '../form';

import useStyles from './styles';

import AlertDialog from 'components/dialog';
// import RailzButton from 'components/buttons';

interface Props {
  open: boolean;
  close: (accepted?: boolean) => void;
  loading?: boolean;
}

const initialValues: { term: boolean } = {
  term: false,
};

const validationSchema = yup.object().shape({
  term: yup.bool().oneOf([true], 'DASHBOARD_BILLING_TERMS_REQUIRED').nullable(),
});
export default function TermsConditionsModal({ open, close, loading }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleContinue = (): void => close(true);
  const handleClose = (): void => close();
  const [html, setHtml] = useState<string>();

  const { handleSubmit, errors, control } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    fetch(t('DASHBOARD_BILLING_TERMS_SAAS_CONTENT'))
      .then((res) => res.text())
      .then((finalHtml) => {
        setHtml(finalHtml);
      });
  }, []);

  return (
    <AlertDialog
      isOpen={open}
      title={t('DASHBOARD_BILLING_TERMS_HEADER')}
      showCloseButton
      onClose={handleClose}
      headerStyle={{ marginBottom: 0 }}
    >
      <p className={classes.subTitleStyle}>{t('DASHBOARD_BILLING_TERMS_DESCRIPTION')}</p>
      {html && (
        <div className={classes.termsContainer} dangerouslySetInnerHTML={{ __html: html }} />
      )}
      <form
        data-testid="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleContinue)}
        className={classes.form}
      >
        <FormCheckBox
          name="term"
          color="primary"
          control={control}
          errorobj={errors}
          testid="terms-billing-input"
          label={
            <Typography className={classes.checkboxLabel}>
              {t('DASHBOARD_BILLING_TERMS_CHECKBOX')}
            </Typography>
          }
        />
        <div>
          <RailzButton
            label={!loading ? t('DASHBOARD_BILLING_TERMS_CONTINUE_CTA') : ''}
            onClick={handleSubmit(handleContinue)}
            size="large"
            loading={loading}
            data-testid={'terms-conditions'}
          />
        </div>
      </form>
    </AlertDialog>
  );
}
