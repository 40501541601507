import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox } from '@material-ui/core';

import { Control } from 'react-hook-form';

import { ItemDataType } from '../types/data-sync';

import style from './style';

import { ToggleDataSyncWarningModal } from './toggle-data-sync-warning-modal';

import { FormCheckBox } from 'components/form';
import BasicTable from 'components/table';
import { ItemDataTypeConfigStatus } from 'store/features/integration/integration.state';
import { TooltipIcon } from 'components/tooltip-icon';
import { DATA_TYPES_TOOLTIPS, DATA_TYPES_WITH_WARNINGS_ON_SYNC_ENABLE } from 'types';

interface Props {
  rows: ItemDataType[];
  isLoading?: boolean;
  control: Control;
  getValues: (keys?: string | string[]) => Record<string, unknown>;
  setValue: (name: string, value: boolean) => void;
  trigger: (name?: string | string[]) => Promise<boolean>;
}

type ModalWarningState = {
  title: string;
  message: string;
  callback: (confirm: boolean) => void;
};

export default function DataSyncTable({
  rows,
  control,
  getValues,
  setValue,
  trigger,
}: Props): JSX.Element {
  const [modalWarning, setWarning] = React.useState<ModalWarningState>(undefined);
  const classes = style();
  const formValues = getValues() as Record<string, { configStatus: ItemDataTypeConfigStatus }>;
  const allValues = Object.entries(formValues) as Array<
    [string, { configStatus: ItemDataTypeConfigStatus }]
  >;
  const { t } = useTranslation();

  /**
   * Should the user be warned about the consequence of changing the value of all checkboxes?
   *
   * It also triggers the modal as side effect
   * @returns boolean if the user should be interrupted or not.
   */
  function interruptCheckAllEvent(rows: ItemDataType[], checked: boolean): boolean {
    if (!checked) return;
    const interruptionsToShow = Object.entries(DATA_TYPES_WITH_WARNINGS_ON_SYNC_ENABLE).filter(
      ([name]) => {
        if (!formValues[name]) return false;
        const originalItem = rows.find((item) => item.key === name);
        if (!originalItem) return false;
        return !originalItem.configStatus.sync;
      },
    );

    if (interruptionsToShow.length > 1) {
      setWarning({
        title: 'DASHBOARD_DATA_SYNC_WARNING_ALL_TYPES_TITLE',
        message: 'DASHBOARD_DATA_SYNC_WARNING_ALL_TYPES',
        callback: (confirm) => {
          confirm && toggleAllSync(true);
          setWarning(undefined);
        },
      });
    } else if (interruptionsToShow.length) {
      setWarning({
        ...interruptionsToShow[0][1],
        callback: (confirm) => {
          confirm && toggleAllSync(true);
          setWarning(undefined);
        },
      });
    }
    return !!interruptionsToShow.length;
  }

  const toggleAllSync = (newValue: boolean): void => {
    const formKeys = Object.keys(getValues());
    formKeys.forEach((key) => setValue(`${key}.configStatus.sync`, newValue));
    trigger();
  };

  const columns = [
    {
      key: 'name',
      name: t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TABLE_HEADER_1'),
      className: classes.tableHeaderCell,
      render: ({ name, url, key }): React.ReactElement => {
        return (
          <div className={classes.labelColumnContent}>
            <span>
              <Typography className={classes.dataTypeLabel}>{name}</Typography>
              <Typography className={classes.dataTypeUrl}>{url}</Typography>
            </span>
            {DATA_TYPES_TOOLTIPS[key] && (
              <TooltipIcon
                bigIcon={true}
                title={t(DATA_TYPES_TOOLTIPS[key])}
                id={'sync-tooltip-' + key}
              />
            )}
          </div>
        );
      },
    },
    {
      key: 'sync',
      name: t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TABLE_HEADER_SYNC'),
      className: classes.lastColumn,
      isSelectColumn: true,
      renderColumn: (): React.ReactElement => {
        const totalCount = allValues.length;
        const selectedCount = allValues.filter(([, value]) => value.configStatus.sync).length;

        return (
          <Box className={classes.containerCheck}>
            <Checkbox
              indeterminate={selectedCount > 0 && selectedCount < totalCount}
              checked={selectedCount === totalCount}
              color="primary"
              onClick={({ target }): void => {
                const newValue = Boolean(target['checked']);
                if (!interruptCheckAllEvent(rows, newValue)) toggleAllSync(newValue);
              }}
              className={classes.checkbox}
              data-testid="check-toggle-all-sync"
            ></Checkbox>
            <span className="title min-content-width">
              {t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TABLE_HEADER_SYNC')}
            </span>
            <TooltipIcon
              bigIcon={true}
              title={t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TABLE_HEADER_SYNC_TOOLTIP')}
              id="sync-tooltip"
            />
          </Box>
        );
      },
      render: ({ key, configStatus }): React.ReactElement => {
        const configKey = 'sync';
        const name = `${key}.configStatus.${configKey}`;
        return (
          <div className={classes.checkTableContainer}>
            <FormCheckBox
              key={`input-${key}-sync`}
              testid={`data-sync-form-${key}-sync`}
              control={control}
              name={name}
              className={classes.checkColumn}
              defaultValue={configStatus.sync}
              onChange={(_event, checked): void => {
                if (!DATA_TYPES_WITH_WARNINGS_ON_SYNC_ENABLE[key]) {
                  setValue(`${key}.configStatus.sync`, checked);
                  trigger();
                  return;
                }

                if (!checked) {
                  trigger();
                  return;
                }

                // Manage modal interception
                const originalItem = rows.find((item) => item.key === key);
                const originalWasAlreadyTrue = !!originalItem?.configStatus[configKey] || false;
                if (originalWasAlreadyTrue) return;

                // Form input is already set in this case
                setValue(`${key}.configStatus.sync`, false);
                trigger();

                setWarning({
                  ...DATA_TYPES_WITH_WARNINGS_ON_SYNC_ENABLE[key],
                  callback: (confirm) => {
                    if (confirm) {
                      setValue(`${key}.configStatus.sync`, true);
                      trigger();
                    }
                    setWarning(undefined);
                  },
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: 'syncOnFirstRun',
      name: t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TABLE_HEADER_2'),
      className: classes.lastColumn,
      isSelectColumn: true,
      renderColumn: (): React.ReactElement => {
        const totalCount = allValues.length;
        const selectedCount = allValues.filter(
          ([, value]) => value.configStatus.syncOnFirstRun,
        ).length;

        return (
          <Box className={classes.containerCheck}>
            <Checkbox
              indeterminate={selectedCount > 0 && selectedCount < totalCount}
              checked={selectedCount === totalCount}
              color="primary"
              onClick={({ target }): void => {
                const newValue = Boolean(target['checked']);
                allValues.forEach(([key]) =>
                  setValue(`${key}.configStatus.syncOnFirstRun`, newValue),
                );
                trigger();
              }}
              className={classes.checkbox}
              data-testid="check-toggle-sync-on-first-run"
            />
            <span className="title">{t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TABLE_HEADER_2')} </span>
            <TooltipIcon
              bigIcon={true}
              title={t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TABLE_HEADER_SYNC_ON_FIRST_RUN_TOOLTIP')}
              id="sync-on-first-run-tooltip"
            />
          </Box>
        );
      },
      render: ({ key, configStatus: { syncOnFirstRun } }): React.ReactElement => {
        return (
          <div className={classes.checkTableContainer}>
            <FormCheckBox
              key={`input-${key}`}
              testid={`data-sync-form-${key}-syncOnFirstRun`}
              control={control}
              name={`${key}.configStatus.syncOnFirstRun`}
              className={classes.checkColumn}
              onChange={(): void => {
                trigger();
              }}
              defaultValue={syncOnFirstRun}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {modalWarning && <ToggleDataSyncWarningModal {...modalWarning} />}
      <BasicTable
        stickyHeader
        isLoading={!rows}
        columns={columns}
        rows={rows}
        size="medium"
        isPaperComponent={false}
      />
    </>
  );
}
