import { call, put, takeLatest } from 'redux-saga/effects';

import { WebhookConfig } from '../../../../types';

import {
  UPDATE_WEBHOOK_CONFIG_API,
  updateWebhookConfigSuccessApi,
  updateWebhookConfigFailureApi,
  deleteWebhookConfigSuccessApi,
  deleteWebhookConfigFailureApi,
  DELETE_WEBHOOK_CONFIG_API,
} from './webhook-config.action';

import { default as WebhookConfigService } from './webhook-config.service';
import { WebhookConfigAction } from './webhook-config.state';

import { ErrorResponse } from 'types/interface/error.interface';

export const buildErrorMessage = (
  error: ErrorResponse,
  context: 'create' | 'update' | 'fetch' | 'delete',
): ErrorResponse => {
  return {
    ...error,
    message: ['Webhook Config', context].concat(error.message),
  };
};

function* updateWebhookConfigApiCall(action: WebhookConfigAction<WebhookConfig>): Generator<any> {
  try {
    const resultWebhook = yield call(WebhookConfigService.update, action.payload);
    yield put(updateWebhookConfigSuccessApi(resultWebhook as WebhookConfig));
  } catch (error) {
    yield put(updateWebhookConfigFailureApi(buildErrorMessage(error, 'update')));
  }
}

function* updateWebhookConfigSaga(): Generator<any> {
  yield takeLatest(UPDATE_WEBHOOK_CONFIG_API, updateWebhookConfigApiCall);
}

function* deleteWebhookConfigApiCall(): Generator<any> {
  try {
    yield call(WebhookConfigService.delete);
    yield put(deleteWebhookConfigSuccessApi());
  } catch (error) {
    yield put(deleteWebhookConfigFailureApi(buildErrorMessage(error, 'delete')));
  }
}

function* deleteWebhookConfigSaga(): Generator<any> {
  yield takeLatest(DELETE_WEBHOOK_CONFIG_API, deleteWebhookConfigApiCall);
}

export { updateWebhookConfigApiCall, deleteWebhookConfigApiCall };

export const webhookConfigSaga = [updateWebhookConfigSaga(), deleteWebhookConfigSaga()];
