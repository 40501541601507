import {
  ItemDataType,
  DataTypeByName,
  AllDataTypesByCategory,
  AllDataTypes,
} from 'pages/data-sync/types/data-sync';
import { PulledDataTypes } from 'store/features/integration/integration.state';
import { FormattedDataTypes } from 'store/features/report/report.state';
import { AspType, ReportTypeDataTypeMapping } from 'types';

/**
 * Builds the complete list of report types,
 * with the current configuration of the team, with the name, url, status, etc.
 * @param aspList List of report types (from the backend), of each Service provider
 * @param syncConfigs Current configuration of the team
 */
export const expandDataTypes = (aspList: FormattedDataTypes): AllDataTypes => {
  if (!aspList || (aspList && !(aspList[AspType.QUICKBOOKS] as any)[0]?.serviceType)) {
    return;
  }
  const ReportNames = Object.values(aspList).reduce((acc, dataTypes) => {
    dataTypes.forEach((dt: any) => (acc[dt.value] = dt.name));
    return acc;
  }, {}) as DataTypeByName;
  const DataSyncConfigs = Object.entries(ReportTypeDataTypeMapping).reduce(
    (acum, [key, { value, serviceType, url, name = '' }]) => {
      acum[key] = {
        key,
        value,
        serviceType,
        url,
        // some analytics will not have a value in ReportNames
        name: ReportNames[value] || name,
      };

      return acum;
    },
    {},
  ) as AllDataTypes;

  return DataSyncConfigs;
};

/**
 * Groups the list of dataTypes by serviceType, and sorts them alphabetically.
 */
export const groupAndSortDataTypesByServiceType = (
  dataTypes: AllDataTypes,
): AllDataTypesByCategory => {
  const groupedDataTypes = Object.values(dataTypes).reduce((acc, dataTypes) => {
    if (acc[dataTypes.serviceType]) {
      acc[dataTypes.serviceType] = [...acc[dataTypes.serviceType], dataTypes];
    } else {
      acc[dataTypes.serviceType] = [dataTypes];
    }
    return acc;
  }, {}) as AllDataTypesByCategory;

  Object.keys(groupedDataTypes).forEach((key) => {
    groupedDataTypes[key] = groupedDataTypes[key].sort<ItemDataType>((a, b) =>
      String(a.name).localeCompare(String(b.name)),
    ) as ItemDataType[];
  });

  return groupedDataTypes;
};

const addSyncConfigStatusToDataTypes = (
  dataTypes: AllDataTypes,
  syncConfigs: PulledDataTypes,
): AllDataTypes => {
  if (!Object.keys(syncConfigs)?.length || !dataTypes) return;

  return Object.entries(dataTypes).reduce((acum, [key, dataType]) => {
    acum[key] = {
      ...dataType,
      configStatus: {
        syncOnFirstRun: !!syncConfigs[key]?.syncOnFirstRun,
        sync: !!syncConfigs[key]?.sync,
      },
    };

    return acum;
  }, {});
};

const filterOutDataTypesNotPresentInOriginalSyncConfigs = (
  dataTypes: AllDataTypes,
  syncConfigs: PulledDataTypes,
): AllDataTypes => {
  if (!Object.keys(syncConfigs)?.length || !dataTypes) return;

  return Object.entries(dataTypes).reduce((acum, [key, dataType]) => {
    if (!syncConfigs[key]) return acum;
    acum[key] = dataType;
    return acum;
  }, {});
};

/**
 * Merge the list of data types with the current configuration of the team, almost ready for the UI
 * @param allDataTypes expanded list of data types
 * @param syncConfigs current configuration of the team
 */
export const getDataTypesServiceTypeGrouper = (
  allDataTypes: AllDataTypes,
  syncConfigs: PulledDataTypes,
): AllDataTypesByCategory => {
  const dataTypesWithSyncConfig = addSyncConfigStatusToDataTypes(allDataTypes, syncConfigs);
  if (!dataTypesWithSyncConfig) return;
  const filteredDataTypes = filterOutDataTypesNotPresentInOriginalSyncConfigs(
    dataTypesWithSyncConfig,
    syncConfigs,
  );
  if (!filteredDataTypes) return;

  return groupAndSortDataTypesByServiceType(filteredDataTypes);
};
