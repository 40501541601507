import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export const calloutStyle = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '5px',

    '& .MuiAlertTitle-root': {
      margin: '0 !important',
      filter: 'brightness(50%)',
    },

    '& h2': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: `${theme.spacing(0.5)}px 0`,
    },
  },
  message: {
    fontSize: '14px',
  },
}));

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    headDescription: {
      padding: theme.spacing(3),
    },
    labelDescription: {
      fontWeight: 600,
      marginTop: theme.spacing(2),
    },
    createWebhookContainer: {
      paddingBottom: theme.spacing(4),
      maxWidth: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    wrapperFilter: {
      marginBottom: 5,
    },
    iconCta: {
      cursor: 'pointer',
      marginRight: theme.spacing(2),
    },
    addCta: {
      margin: theme.spacing(1),
    },
    form: {
      marginBottom: theme.spacing(2),
    },
    tableActions: {
      textAlign: 'right',
    },
    link: {
      textDecoration: 'underline',
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
    },
    scrollView: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(10)}px`,
    },
  }),
);
