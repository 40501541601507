import React, { useState, useEffect } from 'react';
import { Chip, Box } from '@material-ui/core';
import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { RailzBankReconciliation } from '@railzai/railz-visualizations-react';

import { RVReportTypes, RVFilterBankReconciliation } from '@railzai/railz-visualizations';

import { fetchReportsApi } from '../../../../../store/features/report/report.action';
import {
  AspType,
  HttpStatus,
  ReportQuery,
  ReportType,
  BankAccount,
  BankAccounts,
} from '../../../../../types';
import { getReport } from '../../../../../store/features/report/report.selector';

import Config from '../../../../../config';

import { chartOptions } from '../summary-charts-options';

import BusinessReportFilter from './business-report-filter';

import ReportTable from './selected-report/report-table';
import useStyle from './style';

import { createThemeObject } from 'providers/theme-provider';
import SearchTimestamp from 'components/search-timestamp';
import {
  ReconciledBankReports,
  ReportApiParam,
  TransactionsInformation,
  REPORT_PAGINATION_LIMIT,
  checkBusinessHasAccountingAndBanking,
} from 'helpers/report-helpers';
import ErrorComponent from 'components/error/error-component';
import { descendingComparatorByArray, getConnectionServiceName } from 'helpers/common.helper';
import DoubleArrow from 'assets/icons/double-arrow';
import reportService from 'store/features/report/report.service';
import { getSelectedBusiness } from 'store/features/business/business.selector';
import { getAccessToken } from 'store/features/auth';
import { formatFilter } from 'helpers/business-helpers/financial-summary-helper';
import { Business } from 'pages/business/types/interfaces';

interface ReportBuilderProps {
  newFilterData: Partial<ReportQuery>;
  localOrder: string;
  localSortBy: string;
  localOffset: number;
}

/**
 * Builds the parameters for the bank reconciliation report
 */
const buildBankReconciliationReportParams = (props: ReportBuilderProps): ReportApiParam => {
  const { newFilterData, localOrder, localSortBy, localOffset } = props;
  if (!newFilterData.connectionUuid) return;

  const reportParams = {
    connectionUuid: newFilterData.connectionUuid,
    orderBy: 'asc',
    reportFrequency: undefined,
    startDate: moment(newFilterData.startDate).format('YYYY-MM-DD'),
    endDate: moment(newFilterData.endDate).format('YYYY-MM-DD'),
    offset: localOffset.toString(),
    limit: REPORT_PAGINATION_LIMIT,
  };

  if (!isEmpty(localOrder) && !isEmpty(localSortBy)) {
    reportParams.orderBy = (localOrder === 'asc' ? '' : '-') + localSortBy;
  }

  return reportParams;
};

/**
 * Deep comparer of two report params
 * @returns true or false
 */
const paramsChanged = (
  lastReportParams: ReportApiParam,
  newReportParams: ReportApiParam,
): boolean => {
  if (!lastReportParams && !newReportParams) return false;
  const oldData = lastReportParams || {};
  const newData = newReportParams || {};

  for (const field of Object.keys({ ...oldData, ...newData })) {
    if (oldData[field] !== newData[field]) return true;
  }
  return false;
};

const BankReconciliation = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyle();
  const muiTheme = createThemeObject();
  const dispatch = useDispatch();

  const [filterInfo, setFilterInfo] = useState<ReportQuery | null>(null);
  const [lastReconciliationParams, setLastReconciliationParams] = useState<ReportApiParam>(null);
  const [lastAccountsParams, setLastAccountsParams] = useState<ReportApiParam>(null);
  const [bankAccounts, setBankAccounts] = useState<BankAccount>(undefined);

  const { data, loading, statusCode } = useSelector(getReport);
  const selectedBusiness = useSelector(getSelectedBusiness);
  const token = useSelector(getAccessToken);

  const [order, setOrder] = useState<string>('desc');
  const [sortBy, setSortBy] = useState<string>('date');
  const [sortByValue, setSortByValue] = useState<string[]>(['date']);
  const [reportData, setReportData] = useState([]);
  const [count, setCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [configuration, setConfiguration] = useState(null);

  useEffect(() => {
    if (token) {
      setConfiguration({
        token,
        endpoint: Config.FEEDER_SERVER,
        debug: Config.DEBUG,
      });
    }
  }, [token]);

  useEffect(() => {
    // effect to react to business changes, and the initial loading of bank accounts
    const businessChange = filterInfo?.businessName !== selectedBusiness?.businessName;
    if (filterInfo && businessChange)
      updateReconciliationReport({
        newFilterData: { ...filterInfo, businessName: selectedBusiness?.businessName },
      });
    // the bank accounts only need to be fetched once per business
    if (businessChange) updateBankReport(selectedBusiness);
  }, [selectedBusiness]);

  const formatReconciliatedData = (
    data: ReconciledBankReports,
    serviceName?: AspType,
  ): TransactionsInformation[] => {
    const toReturn = [];
    if (data?.reports) {
      const { connections } = selectedBusiness;
      const connection = connections.find((connection) => connection.serviceName === serviceName);
      const reportItemServiceName = getConnectionServiceName(connection) || serviceName;

      for (const reportItem of data.reports) {
        for (const dataItem of reportItem.data) {
          if (dataItem['unreconciledBankTransactions']) {
            for (const transaction of dataItem['unreconciledBankTransactions']) {
              const cleanData = { ...dataItem, unreconciledBankTransactions: undefined };
              const accountId = transaction['accountId'];
              const bankName =
                bankAccounts && bankAccounts[accountId]
                  ? bankAccounts[accountId]['institutionName']
                  : undefined;
              const accountType =
                bankAccounts && bankAccounts[accountId]
                  ? bankAccounts[accountId]['accountSubType']
                  : undefined;
              toReturn.push({
                ...transaction,
                ...cleanData,
                ...reportItem?.meta,
                accountingTransactionsAmount: 'N/A',
                serviceName: reportItemServiceName,
                accountingTransactionType: transaction.accountType,
                accountType: accountType,
                matched: <Chip size="small" color="default" label="Unmatched" />,
                bankIcon: <DoubleArrow color={'#00884F'} />,
                institutionName: bankName ? bankName : null,
              });
            }
          }

          if (dataItem['reconciledBankTransactions']) {
            for (const transaction of dataItem['reconciledBankTransactions']) {
              const cleanData = { ...dataItem, reconciledBankTransactions: undefined };
              const accountId = transaction['accountId'];
              const bankName =
                bankAccounts && bankAccounts[accountId]
                  ? bankAccounts[accountId]['institutionName']
                  : undefined;
              const accountType =
                bankAccounts && bankAccounts[accountId]
                  ? bankAccounts[accountId]['accountSubType']
                  : undefined;
              toReturn.push({
                ...transaction,
                ...cleanData,
                ...reportItem?.meta,
                serviceName: reportItemServiceName,
                accountingTransactionType: transaction.accountType,
                accountType: accountType,
                accountingTransactionsAmount: transaction.amount,
                matched: (
                  <Chip
                    className={classes.matchedChipFont}
                    size="small"
                    color="secondary"
                    label="Matched"
                  />
                ),
                bankIcon: <DoubleArrow color={'#00884F'} />,
                institutionName: bankName ? bankName : null,
              });
            }
          }
        }
      }
    }
    return toReturn;
  };

  /**
   * Updates the filters and fetches the reconciliation report
   */
  const updateReconciliationReport = ({
    newFilterData = {},
    localOrder = order,
    localSortBy = sortBy,
    localOffset = offset,
  }: Partial<ReportBuilderProps> = {}): void => {
    if (!checkBusinessHasAccountingAndBanking(selectedBusiness)) return;
    const filterData = { ...filterInfo, ...newFilterData };
    const newReportParams = buildBankReconciliationReportParams({
      newFilterData: filterData,
      localOrder,
      localSortBy,
      localOffset,
    });

    // If the filter is not complete, don't fetch
    if (!newReportParams) return;
    if (!paramsChanged(lastReconciliationParams, newReportParams)) return;

    setReportData([]);
    setOrder(localOrder);
    setSortBy(localSortBy);
    setFilterInfo(filterData);
    setLastReconciliationParams(newReportParams);

    dispatch(
      fetchReportsApi({
        type: ReportType.BANK_RECONCILIATION,
        params: newReportParams,
      }),
    );
  };

  /**
   * Fetches the bank accounts for the selected business
   */
  const updateBankReport = async (selectedBusiness: Business): Promise<void> => {
    if (!checkBusinessHasAccountingAndBanking(selectedBusiness)) return;

    const { connections } = selectedBusiness;
    const connection = connections.find(({ serviceName }) => serviceName === AspType.PLAID);
    const params = { connectionUuid: connection?.connectionId, orderBy: 'asc', offset: '0' };
    if (!paramsChanged(lastAccountsParams, params)) return;
    // Avoid fetching the bank accounts multiple times per business selection

    setLastAccountsParams(params);
    setBankAccounts(undefined);

    try {
      const response = await reportService.getReport({ type: ReportType.BANK_ACCOUNTS, params });
      if (!response || !Array.isArray(response.data))
        throw new Error(`No Bank Accounts in response for connection ${params.connectionUuid}`);

      setBankAccounts(
        (response.data as BankAccounts[]).reduce(
          (acc, { accountId, institutionName, accountSubType }) => {
            acc[accountId] = { institutionName, accountSubType };
            return acc;
          },
          {},
        ),
      );
    } catch (e: unknown) {
      console.error('getBankAccount', e);
    }
  };

  useEffect(() => {
    if (data && statusCode === 200) {
      const formattedReportData = formatReconciliatedData(cloneDeep(data), filterInfo?.serviceName);
      formattedReportData.sort((a, b) =>
        order === 'desc'
          ? descendingComparatorByArray(a, b, sortByValue)
          : -descendingComparatorByArray(a, b, sortByValue),
      );
      if (typeof formattedReportData !== 'undefined') {
        setReportData(formattedReportData);
        setCount(Math.ceil(data?.pagination?.count / REPORT_PAGINATION_LIMIT) || 1);
      }
    }
  }, [data, bankAccounts]);

  const handleFilterChange = (filter): void =>
    updateReconciliationReport({ newFilterData: filter });

  const handleOrderChange = ({
    key: localSortBy,
    order: localOrder,
  }: {
    key: string;
    order: 'asc' | 'desc';
  }): void => updateReconciliationReport({ localSortBy, localOrder });

  const showPagination =
    Array.isArray(reportData) && reportData.filter(Boolean).length > 0 && data?.pagination;

  return (
    <Box className={classes.contentContainer}>
      <BusinessReportFilter
        reportName={t('DASHBOARD_BUSINESS_BANK_RECONCILIATION')}
        forcedReportType={ReportType.BANK_RECONCILIATION}
        applyFilter={handleFilterChange}
        forcedHideFrequency={true}
      />
      {statusCode === 404 || !checkBusinessHasAccountingAndBanking(selectedBusiness) ? (
        <Box className={classes.errorContainer}>
          <ErrorComponent
            statusCode={HttpStatus.NO_DATA}
            imageStyle={{ paddingBottom: muiTheme.spacing(2) }}
            title={''}
            showTitle={false}
            showSubTitle={true}
            subTitle={t('DASHBOARD_BUSINESS_BANK_RECONCILIATION_SERVICE_CRITERIA_MISSING')}
          />
        </Box>
      ) : (
        <>
          {!loading && reportData?.length ? (
            <>
              <Box className={classes.timestampContainer}>
                <SearchTimestamp />
              </Box>
              <RailzBankReconciliation
                configuration={configuration}
                filter={
                  formatFilter({
                    ...(filterInfo as any),
                    reportType: RVReportTypes.BANK_RECONCILIATION,
                  }) as RVFilterBankReconciliation
                }
                options={chartOptions}
              />
            </>
          ) : null}
          <ReportTable
            loading={loading}
            data={reportData}
            reportType={ReportType.BUSINESS_BANK_RECONCILIATION}
            statusCode={statusCode}
            defaultSort={sortBy}
            defaultSortByValue={sortByValue}
            defaultOrder={order}
            setUserSortBy={handleOrderChange}
            setUserSortByValue={setSortByValue}
          />

          {showPagination && (
            <div className={classes.pagination}>
              <Pagination
                data-testid="pagination-bank-reconciliation"
                count={count}
                page={currentPage}
                defaultPage={1}
                onChange={(_, pagerNumber): void => {
                  setCurrentPage(pagerNumber);
                  const newOffset = (pagerNumber - 1) * REPORT_PAGINATION_LIMIT;
                  setOffset(newOffset);
                  updateReconciliationReport({ localOffset: newOffset });
                }}
              />
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default BankReconciliation;
