import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    cancelCta: {
      marginRight: theme.spacing(),
      '& span': {
        color: theme.palette.grey[800],
        fontSize: '1rem',
      },
    },
    confirmCta: {
      '& span': {
        fontSize: '1rem',
      },
    },
    description: {
      fontSize: '1rem',
      fontWeight: 400,
      color: theme.palette.grey[800],
      lineHeight: `${theme.spacing(3)}px`,
    },
  }),
);
