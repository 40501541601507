import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ServiceType, ServiceWithIntegrations } from '../../../types';

import {
  getIntegrations,
  isIntegrationLoading,
} from '../../../store/features/integration/integration.selector';

import { getServices } from '../../../store/features/business/business.selector';

import { getCustomerState } from '../../../store/features/account/customer/customer.selector';

import { isSandbox } from '../../../store/features/auth';

import useStyle from './style';

import { ServiceCollapsableItem } from './service-list-item';

import { prepareServicesAndRelatedIntegrations } from './helper';

import i18n from 'translations';

import { Header, ScrollView, View } from 'components';
import PageDescription from 'components/page-description/page-description';
import { fetchServices } from 'store/features/business/business.action';
import AlertIntegrationKeys from 'components/alert/alert-integration-keys/alert-integration-keys';
import { openNewTab } from 'helpers/open-new-tab';

function getPageDescription(sandbox, type, component): string {
  if (sandbox) return `DASHBOARD_INTEGRATION_SANDBOX_${component.toUpperCase()}`;
  return `DASHBOARD_INTEGRATION_${type.toUpperCase()}_${component.toUpperCase()}`;
}

const IntegrationList: React.FC<{ type: ServiceType }> = ({ type = ServiceType.ACCOUNTING }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [listData, setListData] = useState<ServiceWithIntegrations[]>([]);
  const [expandedServiceName, setExpandedServiceName] = useState<string>('');

  const user = useSelector(getCustomerState);
  const integrations = useSelector(getIntegrations);
  const services = useSelector(getServices);
  const isLoading = useSelector(isIntegrationLoading) || !services?.length;
  const sandbox = useSelector(isSandbox);
  const title = i18n.exists(`DASHBOARD_INTEGRATION_${type.toUpperCase()}_HEADER`)
    ? t(`DASHBOARD_INTEGRATION_${type.toUpperCase()}_HEADER`)
    : '';

  useEffect(() => {
    if (!services?.length) dispatch(fetchServices());
  }, []);

  useEffect(() => {
    if (!services?.length || !user) return;
    const list = prepareServicesAndRelatedIntegrations({
      services,
      integrations,
      user,
      type,
      sandbox,
    });
    setListData(list);
  }, [services, integrations]);

  return (
    <>
      <Header
        title={title}
        drawerMenu={true}
        leftComponent={null}
        rightComponent={null}
        testId="testheader"
      />
      <AlertIntegrationKeys sandbox={sandbox} />
      <View>
        <ScrollView classNames={classes.scrollView}>
          <PageDescription
            title={getPageDescription(sandbox, type, 'title')}
            subtitle={getPageDescription(sandbox, type, 'subtitle')}
            className={classes.container}
            subtitleComponents={{
              b: <b />,
              a: (
                <a
                  href="https://docs.railz.ai/docs/railz-sandbox"
                  onClick={(e): void => openNewTab(e, 'https://docs.railz.ai/docs/railz-sandbox')}
                  rel="noopener noreferrer"
                />
              ),
            }}
          />

          {listData.map((service, index) => (
            <ServiceCollapsableItem
              expandedServiceName={expandedServiceName}
              toggleExpandedServiceName={(): void =>
                setExpandedServiceName(expandedServiceName === service.name ? '' : service.name)
              }
              key={`${service.name}-${index}`}
              service={service}
              user={user}
              isLoading={isLoading}
              classes={classes}
            />
          ))}
        </ScrollView>
      </View>
    </>
  );
};
export default IntegrationList;
