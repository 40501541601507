import { call, put, select, takeLatest } from 'redux-saga/effects';

import { default as IntegrationService } from './integration.service';

import {
  FETCH_INTEGRATIONS_API,
  fetchIntegrationsFailureApi,
  fetchIntegrationsSuccessApi,
  fetchSyncConfigsSuccessApi,
  fetchSyncConfigsFailureApi,
  FETCH_SYNC_CONFIGS_API,
  UPDATE_SYNC_CONFIGS_API,
  updateSyncConfigsSuccessApi,
  updateSyncConfigsFailureApi,
  CREATE_INTEGRATION_API,
  createIntegrationSuccessApi,
  createIntegrationFailureApi,
  updateIntegrationSuccessApi,
  updateIntegrationFailureApi,
  UPDATE_INTEGRATION_API,
  UPDATE_INTEGRATIONS_STATE_API,
  updateIntegrationsStateSuccessApi,
  updateIntegrationsStateFailureApi,
  UPDATE_INTEGRATIONS_DEFAULT_API,
  updateIntegrationsDefaultSuccessApi,
  updateIntegrationsDefaultFailureApi,
  DELETE_INTEGRATION_API,
  deleteIntegrationSuccessApi,
  deleteIntegrationFailureApi,
} from './integration.action';
import { IntegrationAction, SyncDataState } from './integration.state';

import { getIntegrations } from './integration.selector';

import {
  HttpStatusError,
  type AspType,
  type Integration,
  type IntegrationCreationData,
  IntegrationStatus,
  type IntegrationUpdateData,
} from 'types';
import { getLocalizedService, showSnackbar } from 'helpers/common.helper';
import i18n from 'translations';
import { FormattedTranslation } from 'translations/helper';

function* fetchIntegrationsApiCall(): Generator<any> {
  try {
    const integrations = (yield call(IntegrationService.getIntegration)) as Integration[];
    yield put(fetchIntegrationsSuccessApi(integrations));
  } catch (error) {
    yield put(fetchIntegrationsFailureApi(error));
  }
}

function* fetchIntegrationSaga(): Generator<any> {
  yield takeLatest(FETCH_INTEGRATIONS_API, fetchIntegrationsApiCall);
}

function* fetchSyncConfigsCall(): Generator<any> {
  try {
    const getSyncConfigs = (yield call(IntegrationService.getSyncConfigs)) as SyncDataState;
    yield put(fetchSyncConfigsSuccessApi(getSyncConfigs));
  } catch (error) {
    yield put(fetchSyncConfigsFailureApi(error));
  }
}

function* fetchSyncConfigsSaga(): Generator<any> {
  yield takeLatest(FETCH_SYNC_CONFIGS_API, fetchSyncConfigsCall);
}

function* updateSyncConfigsCall(action: IntegrationAction): Generator<any> {
  try {
    const getSyncConfigs = (yield call(
      IntegrationService.putSyncConfigs,
      action.payload,
    )) as SyncDataState;
    yield put(updateSyncConfigsSuccessApi(getSyncConfigs));
  } catch (error) {
    yield put(updateSyncConfigsFailureApi(error));
  }
}

function* updateSyncConfigsSaga(): Generator<any> {
  yield takeLatest(UPDATE_SYNC_CONFIGS_API, updateSyncConfigsCall);
}

function* createIntegrationCall(
  action: IntegrationAction<IntegrationCreationData>,
): Generator<any> {
  const previousIntegrations = (yield select(getIntegrations)) as Integration[];
  const isFirstIntegrationOfService = !previousIntegrations.some(
    ({ deletedAt, service }) => !deletedAt && service === action.payload.service,
  );
  const name = isFirstIntegrationOfService
    ? getLocalizedService(action.payload.service)
    : action.payload.name;

  try {
    const createdIntegration = (yield call(
      IntegrationService.createIntegration,
      action.payload,
    )) as Integration;
    yield put(createIntegrationSuccessApi(createdIntegration));

    showSnackbar({
      message: FormattedTranslation({
        i18nKey: isFirstIntegrationOfService
          ? 'DASHBOARD_INTEGRATION_KEYS_MODAL_CTA_TOAST_SUCCESS'
          : 'DASHBOARD_INTEGRATION_KEYS_MODAL_CTA_TOAST_SUCCESS_ADDITIONAL_KEY',
        values: { name },
      }),
      type: 'success',
    });
  } catch (error) {
    yield put(createIntegrationFailureApi(error));

    showSnackbar({
      message: FormattedTranslation({
        i18nKey: isFirstIntegrationOfService
          ? 'DASHBOARD_INTEGRATION_KEYS_MODAL_CTA_TOAST_ERROR'
          : 'DASHBOARD_INTEGRATION_KEYS_MODAL_CTA_TOAST_ERROR_ADDITIONAL_KEY',
        values: { name },
      }),
      type: 'error',
    });
  }
}

function* createIntegrationSaga(): Generator<any> {
  yield takeLatest(CREATE_INTEGRATION_API, createIntegrationCall);
}

function* updateIntegrationCall(action: IntegrationAction<IntegrationUpdateData>): Generator<any> {
  let name = action.payload.name;
  if (!name) {
    const integrations = (yield select(getIntegrations)) as Integration[];
    name = integrations.find(({ uuid }) => uuid === action.payload.uuid).name;
  }

  try {
    const updatedIntegration = (yield call(
      IntegrationService.updateIntegration,
      action.payload,
    )) as Integration;
    yield put(updateIntegrationSuccessApi(updatedIntegration));
    showSnackbar({
      message: FormattedTranslation({
        i18nKey: 'DASHBOARD_INTEGRATON_MANAGE_UPDATE_SUCCESS',
        values: { name },
      }),
      type: 'success',
    });
  } catch (error) {
    showSnackbar({
      message: FormattedTranslation({
        i18nKey: 'DASHBOARD_INTEGRATON_MANAGE_INVALID_KEYS',
        values: { name },
      }),
      type: 'error',
    });
    yield put(updateIntegrationFailureApi(error));
  }
}

function* updateIntegrationSaga(): Generator<any> {
  yield takeLatest(UPDATE_INTEGRATION_API, updateIntegrationCall);
}

function* deleteIntegrationsCall(action: IntegrationAction<Integration>): Generator<any> {
  try {
    const deletedIntegration = (yield call(
      IntegrationService.deleteIntegration,
      action.payload.uuid,
    )) as Pick<Integration, 'service' | 'uuid' | 'deletedAt'>;

    yield put(deleteIntegrationSuccessApi(deletedIntegration));
    showSnackbar({
      message: FormattedTranslation({
        i18nKey: 'DASHBOARD_INTEGRATION_KEYS_MODAL_DELETE_SUCCESS',
        values: { name: action.payload.name },
      }),
      type: 'success',
    });
  } catch (error) {
    yield put(deleteIntegrationFailureApi(error));
    showSnackbar({
      message: FormattedTranslation({
        i18nKey: 'DASHBOARD_INTEGRATION_KEYS_MODAL_DELETE_ERROR',
        values: { name: action.payload.name },
      }),
      type: 'error',
    });
  }
}
function* deleteIntegrationSaga(): Generator<any> {
  yield takeLatest(DELETE_INTEGRATION_API, deleteIntegrationsCall);
}

function* updateIntegrationsServiceStateCall(
  action: IntegrationAction<{
    state: IntegrationStatus;
    service: AspType;
  }>,
): Generator<any> {
  try {
    const { uuid: createdUuid } = (yield call(
      IntegrationService.updateIntegrationStatus,
      action.payload,
    )) as { uuid?: string };
    if (createdUuid) {
      yield put(
        updateIntegrationsStateSuccessApi({
          uuid: createdUuid,
          service: action.payload.service,
          state: action.payload.state,
          name: action.payload.service + '-' + createdUuid.slice(4, 12),
        }),
      );
    } else {
      yield put(updateIntegrationsStateSuccessApi(action.payload));
    }

    showSnackbar({
      message: FormattedTranslation({
        i18nKey: 'DASHBOARD_INTEGRATON_MANAGE_UPDATE_SUCCESS',
        values: { name: getLocalizedService(action.payload.service) },
      }),
      type: 'success',
    });
  } catch (error) {
    if (error.statusCode === HttpStatusError.CONFLICT) {
      showSnackbar({
        type: 'error',
        message: i18n.t('DASHBOARD_INTEGRATON_MANAGE_INVALID_API_KEYS'),
      });
    }
    yield put(updateIntegrationsStateFailureApi(error));
  }
}

function* updateIntegrationsServiceStateSaga(): Generator<any> {
  yield takeLatest(UPDATE_INTEGRATIONS_STATE_API, updateIntegrationsServiceStateCall);
}

function* updateIntegrationServiceDefaultCall(
  action: IntegrationAction<Integration>,
): Generator<any> {
  try {
    yield call(IntegrationService.updateIntegrationActiveIntegration, action.payload.uuid);
    yield put(updateIntegrationsDefaultSuccessApi(action.payload));
    showSnackbar({
      message: FormattedTranslation({
        i18nKey: 'DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_SUCCESS',
        values: { name: action.payload.name },
      }),
      type: 'success',
    });
  } catch (error) {
    showSnackbar({
      message: FormattedTranslation({
        i18nKey: 'DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_ERROR',
        values: { name: action.payload.name },
      }),
      type: 'error',
    });
    yield put(updateIntegrationsDefaultFailureApi(error));
  }
}

function* updateIntegrationsDefaultSaga(): Generator<any> {
  yield takeLatest(UPDATE_INTEGRATIONS_DEFAULT_API, updateIntegrationServiceDefaultCall);
}

export {
  fetchIntegrationsApiCall,
  fetchSyncConfigsCall,
  updateSyncConfigsCall,
  createIntegrationCall,
  updateIntegrationCall,
  updateIntegrationsServiceStateCall,
  updateIntegrationServiceDefaultCall,
};
export const integrationSaga = [
  fetchIntegrationSaga(),
  fetchSyncConfigsSaga(),
  updateSyncConfigsSaga(),
  createIntegrationSaga(),
  updateIntegrationSaga(),
  updateIntegrationsDefaultSaga(),
  updateIntegrationsServiceStateSaga(),
  deleteIntegrationSaga(),
];
