import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AuthService, EventService } from '../../services';
import { handleError } from '../../helpers/common.helper';

import ForgotPasswordForm from './forgot-password-form';
import useStyle from './style';

import LayoutImage from 'pages/layout-image/layout-image.page';

const ForgotPassword: React.FC = () => {
  const classes = useStyle();
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async (values): Promise<void> => {
    try {
      await AuthService.forgotPassword(values.email);

      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: t('DASHBOARD_RESET_PASSWORD_EMAIL_CONFIRM'),
      });
      history.replace('/login');
    } catch (error) {
      if (error.statusCode === 406) {
        handleError(t('DASHBOARD_RESET_PASSWORD_SSO_ENABLED'));
        return;
      }

      handleError(error.message?.[0]);
    }
  };

  return (
    <LayoutImage>
      <Typography className={classes.header}>{t('DASHBOARD_RESET_PASSWORD_HEADER')}</Typography>
      <Typography className={classes.description}>
        {t('DASHBOARD_RESET_PASSWORD_DESCRIPTION')}
      </Typography>
      <ForgotPasswordForm onSubmit={onSubmit} />
    </LayoutImage>
  );
};

export default ForgotPassword;
