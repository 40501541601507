import { createSelector } from 'reselect';

import { AllState } from '../../../state';

import { DEVELOPER_STORE_NAME } from '../developer.state';

import { WEBHOOK_CONFIG_STORE_NAME, WebhookConfigState } from './webhook-config.state';

/** Select Webhook State */
export const getWebhookConfigState = (state: AllState): WebhookConfigState =>
  state[DEVELOPER_STORE_NAME][WEBHOOK_CONFIG_STORE_NAME];

export const isWebhookConfigLoading = createSelector(
  getWebhookConfigState,
  (webhookConfig) => webhookConfig.loading,
);

export const getWebhookConfig = createSelector(
  getWebhookConfigState,
  (webhookConfig) => webhookConfig.webhookConfig,
);

export const getWebhookConfigError = createSelector(
  getWebhookConfigState,
  (webhookConfig) => webhookConfig.error,
);

export const getWebhookConfigErrorMessage = createSelector(
  getWebhookConfigState,
  (webhookConfig) => webhookConfig.error && webhookConfig.error.message,
);

export const getWebhookConfigApiResponse = createSelector(
  getWebhookConfigState,
  (webhookConfig) => webhookConfig && webhookConfig.response,
);

export const getWebhookConfigSuccess = createSelector(
  getWebhookConfigState,
  (webhookConfig: WebhookConfigState) =>
    webhookConfig && webhookConfig.response && webhookConfig.response.success,
);
