import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCaptcha from 'react-google-recaptcha';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Link } from 'react-router-dom';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from '../style';

import { View } from 'components';
import { EMAIL_TEAM_INVALID_DOMAINS_REGEX } from 'helpers/regex.helper';
import { FormInput } from 'components/form';
import i18n from 'translations';
import config from 'config';
import { ENVIRONMENT_TYPES } from 'types';

interface Data {
  email: string;
  password: string;
  captchaToken: string;
}

const ENV_LIST = [ENVIRONMENT_TYPES.QA2] as string[];

interface Props {
  login: (data: Omit<Data, 'captchaToken'>) => any;
}

const initialValues: Data = {
  email: '',
  password: '',
  captchaToken: '',
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('DASHBOARD_LOGIN_EMAIL_REQUIRED')
    .required('DASHBOARD_LOGIN_EMAIL_REQUIRED')
    .nullable()
    .matches(EMAIL_TEAM_INVALID_DOMAINS_REGEX, 'DASHBOARD_EMAIL_NOT_WORK_EMAIL'),
  password: yup.string().required('DASHBOARD_LOGIN_PASSWORD_INVALID').nullable(),
  captchaToken: yup.lazy((value) =>
    value === undefined
      ? yup.string().nullable()
      : yup.string().required(i18n.t('DASHBOARD_VERIFY_HUMAN')).nullable(),
  ),
});

export default function LoginForm({ login }: Props): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();
  const { handleSubmit, errors, formState, control, register, setValue, trigger } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const [showCaptchaInput, setShowCaptchaInput] = useState(false);

  const onSubmit = async (data: Omit<Data, 'captchaToken'>): Promise<void> => {
    login({ email: data.email, password: data.password });
  };

  useEffect(() => {
    if (!ENV_LIST.includes(config.SENTRY_ENVIRONMENT)) {
      setShowCaptchaInput(true);
      register({ name: 'captchaToken' });
    }
  }, []);

  const onVerifyCaptcha = (token): void => {
    setValue('captchaToken', token);
    trigger('captchaToken');
  };

  const { isSubmitting } = formState;
  return (
    <div style={{ paddingTop: '32px' }}>
      <form data-testid="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <FormInput
              name="email"
              type="email"
              placeholder="DASHBOARD_SIGNIN_EMAIL_PLACEHOLDER"
              label="DASHBOARD_SIGNIN_EMAIL_LABEL"
              testid="email-input"
              fullWidth
              errorobj={errors}
              control={control}
            />
          </Grid>
          <Grid xs={12} item>
            <FormInput
              type="password"
              name="password"
              placeholder="DASHBOARD_SIGNIN_PASSWORD_PLACEHOLDER"
              label="DASHBOARD_SIGNIN_PASSWORD_LABEL"
              testid="password-input"
              fullWidth
              errorobj={errors}
              control={control}
            />
          </Grid>
        </Grid>
        <View alignItems="end" className={classes.forgotPasswordButton}>
          <Link to="/forgot-password" className={classes.link}>
            {t('DASHBOARD_SIGNIN_CTA_RESET_PASSWORD')}
          </Link>
        </View>
        {/* To be hidden on QA2 */}
        {showCaptchaInput && (
          <div className={classes.captchaContainer}>
            <ReCaptcha
              data-testid="captcha-input"
              sitekey={config.RECAPTCHA_KEY}
              onChange={onVerifyCaptcha}
            />
            {errors.captchaToken && (
              <p className={classes.captchaError}>{errors.captchaToken.message}</p>
            )}
          </div>
        )}
        <RailzButton
          size="large"
          buttonType="submit"
          isDisabled={isSubmitting}
          data-testid="submit"
          label={t('DASHBOARD_SIGNIN_CTA')}
          className={classes.loginButton}
        />
      </form>
    </div>
  );
}
