import { PaginationProps } from 'types';

export const handlePagination = (params?: PaginationProps): PaginationProps => {
  let localOffset = Number((params.currentPage - 1) * params.paginationLimit);
  if (params?.offset !== undefined) localOffset = params?.offset;
  return {
    ...params,
    limit: params?.limit || params.paginationLimit,
    offset: localOffset,
  };
};
