import withWidth from '@material-ui/core/withWidth';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import Loading from '../loading';

import DrawerComponent from './drawer';

import dashboardStyles from './style';

import IdleTimer from 'components/idle-timer';

const MainLayout: React.FC<{
  children?: React.ReactChildren;
  mainRef?: () => void;
  width?: Breakpoint;
  handleLogout: () => void;
}> = (props): React.ReactElement => {
  const { mainRef = (): void => null, width, children, handleLogout } = props;
  const classes = dashboardStyles(props);

  return (
    <div className={classes.root}>
      <DrawerComponent width={width} handleLogout={handleLogout} />
      <main ref={mainRef} className={classes.content} id="main-content">
        {children}
      </main>
      <IdleTimer onIdle={handleLogout} />
      <Loading />
    </div>
  );
};

export default withWidth()(withRouter<any, any>(MainLayout));
