import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
    },
    table: {
      marginTop: theme.spacing(2),
      overflow: 'auto',
    },
    sortIcon: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default styles;
