import { call, put, takeLatest } from 'redux-saga/effects';

import { WebhookConfig, WebhookSecret, WebhookSecretRequest } from '../../../../types';

import { fetchWebhookConfigSuccessApi } from '../webhook-config/webhook-config.action';

import {
  CREATE_WEBHOOK_SECRET_API,
  UPDATE_WEBHOOK_SECRET_API,
  FETCH_WEBHOOK_SECRET_API,
  fetchWebhookSecretFailureApi,
  fetchWebhookSecretSuccessApi,
  createWebhookSecretFailureApi,
  updateWebhookSecretSuccessApi,
  createWebhookSecretSuccessApi,
  updateWebhookSecretFailureApi,
  deleteWebhookSecretSuccessApi,
  deleteWebhookSecretFailureApi,
  DELETE_WEBHOOK_SECRET_API,
} from './webhook-secret.action';

import { default as WebhookSecretService } from './webhook-secret.service';
import { WebhookSecretAction } from './webhook-secret.state';

import { ErrorResponse } from 'types/interface/error.interface';

export const buildErrorMessage = (
  error: ErrorResponse,
  context: 'create' | 'update' | 'fetch' | 'delete',
): ErrorResponse => {
  return {
    ...error,
    message: ['Webhook Secret', context].concat(error.message),
  };
};

function* fetchWebhookSecretApiCall(): Generator<any> {
  try {
    const resultWebhook = yield call(WebhookSecretService.get);
    // extract the webhoookConfig & call the action fetchWebhook
    const { requestIdParam, payloadType, ...webhooksSecrets } = (resultWebhook ||
      {}) as WebhookSecret & WebhookConfig;
    yield put(fetchWebhookConfigSuccessApi({ requestIdParam, payloadType } as WebhookConfig));
    yield put(
      fetchWebhookSecretSuccessApi(
        !webhooksSecrets.method ? null : (webhooksSecrets as WebhookSecret),
      ),
    );
  } catch (error) {
    yield put(fetchWebhookSecretFailureApi(buildErrorMessage(error, 'fetch')));
  }
}

function* fetchWebhookSecretSaga(): Generator<any> {
  yield takeLatest(FETCH_WEBHOOK_SECRET_API, fetchWebhookSecretApiCall);
}

function* createWebhookSecretApiCall(
  action: WebhookSecretAction<WebhookSecretRequest>,
): Generator<any> {
  try {
    const resultWebhook = yield call(WebhookSecretService.create, action.payload);
    yield put(createWebhookSecretSuccessApi(resultWebhook as WebhookSecret));
  } catch (error) {
    yield put(createWebhookSecretFailureApi(buildErrorMessage(error, 'create')));
  }
}

function* createWebhookSecretSaga(): Generator<any> {
  yield takeLatest(CREATE_WEBHOOK_SECRET_API, createWebhookSecretApiCall);
}

function* updateWebhookSecretApiCall(
  action: WebhookSecretAction<WebhookSecretRequest>,
): Generator<any> {
  try {
    const resultWebhook = yield call(WebhookSecretService.update, action.payload);
    yield put(updateWebhookSecretSuccessApi(resultWebhook as WebhookSecret));
  } catch (error) {
    yield put(updateWebhookSecretFailureApi(buildErrorMessage(error, 'update')));
  }
}

function* updateWebhookSecretSaga(): Generator<any> {
  yield takeLatest(UPDATE_WEBHOOK_SECRET_API, updateWebhookSecretApiCall);
}

function* deleteWebhookSecretApiCall(): Generator<any> {
  try {
    yield call(WebhookSecretService.delete);
    yield put(deleteWebhookSecretSuccessApi());
  } catch (error) {
    yield put(deleteWebhookSecretFailureApi(buildErrorMessage(error, 'delete')));
  }
}

function* deleteWebhookSecretSaga(): Generator<any> {
  yield takeLatest(DELETE_WEBHOOK_SECRET_API, deleteWebhookSecretApiCall);
}

export {
  createWebhookSecretApiCall,
  fetchWebhookSecretApiCall,
  updateWebhookSecretApiCall,
  deleteWebhookSecretApiCall,
};

export const webhookSecretSaga = [
  createWebhookSecretSaga(),
  fetchWebhookSecretSaga(),
  updateWebhookSecretSaga(),
  deleteWebhookSecretSaga(),
];
