import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    previewContainerWrapper: {
      width: '100%',
      minWidth: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },

    previewContainer: {
      width: '100%',
      minWidth: 0,
      display: 'flex',
      borderRadius: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      aspectRatio: '5 / 4',
      background: 'white',
      border: '1px solid',
      borderColor: theme.palette.grey[300],
      boxShadow: '0 4px 6px -4px rgba(0,0,0, 0.25)',
      maxHeight: '75vh',
      '&.expanded-seo': {
        borderTopLeftRadius: 0,
      },

      '& *': {
        boxSizing: 'border-box',
      },
    },

    zoomContainer: {
      flexGrow: 1,
      height: '100%',
      minWidth: 0,
      minHeight: 0,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '10px',
      transform: 'scale(1)',
      maxHeight: '75vh',

      '&.expanded-seo': {
        borderTopLeftRadius: 0,
      },
    },

    preview: {
      display: 'flex',
      minHeight: '200%',
      width: '200%',
      flexShrink: 0,
      transition: 'all .2s linear',
      transform: 'scale(1)',
      transformOrigin: '50% 50%',
      fontSize: '1.25rem',

      ['@media screen and (min-width: 1260px)']: {
        fontSize: '1.5rem',
      },

      ['@media screen and (min-width: 1440px)']: {
        fontSize: '2rem',
      },

      '&.focus-default, &.focus-brand, &.focus-saveBusinessUrlToggle': {
        transform: 'scale(.5)',
      },

      // eslint-disable-next-line max-len
      '&.focus-onboard, &.focus-overview, &.focus-integrations, &.focus-help-link, &.focus-terms-and-conditions':
        {
          transform: 'scale(.6)',
          transformOrigin: '35% 50%',
        },

      '&.focus-left-side-bar': {
        transform: 'scale(.5)',
        transformOrigin: '62% 50%',
      },

      '&.focus-logo': {
        transform: 'scale(.7)',
        transformOrigin: '82% 60%',
      },

      '&.focus-seo': {
        transform: 'scale(.5)',
        transformOrigin: '50% 50%',
      },
    },

    headerContainer: {
      padding: 'clamp(1em, 4vw, 4em)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'black',
      color: 'white',
      width: '40%',
      minWidth: '10em',
      transition: 'all .2s linear',
      position: 'relative',
      overflowWrap: 'anywhere',

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-25%',
        right: 0,
        bottom: '-25%',
        width: '150%',
        background: 'inherit',
        zIndex: -1,
      },

      '& > div': {
        transition: 'all .2s ease-in-out',
      },

      '.focus-left-side-bar &': {
        width: '60%',
        fontSize: '1.25em',
      },

      // eslint-disable-next-line max-len
      '.focus-onboard & > div, .focus-overview & > div, .focus-integrations & > div, .focus-help-link & > div, .focus-terms-and-conditions & > div':
        {
          filter: 'blur(8px)',
        },
    },

    contentContainer: {
      width: '100%',
      flexGrow: 1,
      padding: 'clamp(2em, 4vw, 4em) 0',
      backgroundColor: 'white',
      textAlign: 'center',
      alignItems: 'center',
      display: 'grid',
      gridTemplate: '1fr / 1fr',
      placeItems: 'center',
      overflowWrap: 'anywhere',

      '& > div': {
        transition: 'all .2s ease-in-out',
        flexGrow: 1,
        width: '100%',
        gridColumn: '1 / 1',
        gridRow: '1 / 1',
      },

      '& [data-testid="overview-preview"]': {
        width: '100%',
      },

      '.focus-logo & > div, .focus-left-side-bar & > div': {
        filter: 'blur(8px)',
      },
    },
  }),
);
