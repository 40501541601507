import { ErrorResponse } from '../../../../types/interface/error.interface';
import { ProfileState } from '../profile/profile.state';

import {
  ApiResponse,
  Role,
  SuccessResponse,
  TeamMembersResponse,
  TeamsResponse,
  TeamUserResponse,
  TeamV2Response,
} from 'types';

export enum Purpose {
  USE_CASES = 'useCases',
  DIFF_PRODUCTS = 'products',
  PROD_ENVIRONMENT = 'environment',
  SEPARATE_APPLICATIONS = 'separateApplication',
  SEPARATE_TEAMS = 'separateTeams',
  OTHER = 'other',
}

export const TEAM_INITIAL_STATE: TeamState = {
  loading: false,
  query: { limit: 100, count: 1, page: 1 },
  users: [],
  response: null,
  error: null,
  teams: { meta: { offset: 1, limit: 1, currentPage: 1, totalPages: 1, count: 1 }, teams: [] },
  team: null,
  teamMembers: {
    meta: { offset: 1, limit: 1, currentPage: 1, totalPages: 1, count: 1 },
    users: [],
  },
};
export const TEAM_STORE_NAME = 'team';

interface query {
  limit: number;
  page: number;
  count: number;
}

export interface UpdateTeamMember {
  uuid: string;
  role: Role;
}
export interface UpdateMemberRole {
  memberUuid: string;
  memberTeams: UpdateTeamMemberships[];
}
export interface UpdateTeamMemberships {
  role: Role;
  teamUuid: string;
}

export interface AddTeamMember {
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
}

export interface AddOrUpdateTeamMemberV2 {
  members: { email: string; role: Role }[];
  teamUuid: string;
}

export interface AddOrUpdateTeamMemberResponse {
  users: TeamUserResponse[];
}

export interface AddOrUpdateTeamMemberSuccessPayload
  extends SuccessResponse,
    AddOrUpdateTeamMemberResponse {}

export interface InviteTeamErrorResponse extends ErrorResponse {
  badEmails?: string[];
}
export interface AddTeamV2 {
  teamName: string;
  description?: string;
  purpose: Purpose[];
}

export type UpdateTeamV2 = AddTeamV2;

export interface TeamAction {
  payload: any;
  type: string;
}

export interface TeamState {
  users: Array<ProfileState>;
  query: query;
  loading: boolean;
  response: ApiResponse;
  error: InviteTeamErrorResponse;
  teams: TeamsResponse;
  team: TeamV2Response;
  teamMembers: TeamMembersResponse;
}
export interface Team {
  teamName: string;
  uuid: string;
}
export interface ResendTeamMemberInvitation {
  email: string;
}

export interface TeamsFilter {
  limit?: number;
  offset?: number;
  userId?: number;
}
