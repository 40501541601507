import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { getCustomerState } from '../../store/features/account/customer/customer.selector';

import useStyle from './style';
import SitesForm from './sites-form';
import DynamicPreview from './dynamic-preview';

import { Plan, RailzSitesMetadata } from 'types';
import { Header } from 'components';
import { WEBSITE_WITH_OR_WITHOUT_PROTOCOL_REGEX } from 'helpers/regex.helper';

const DEFAULT_META_TITLE = 'Railz Sites™ | No code access to financial data with Railz Sites™';
const DEFAULT_META_DESCRIPTION =
  // eslint-disable-next-line max-len
  "Railz Sites™ is a no code, white-labelled front-end interface powered by Railz. Railz is the Accounting Data as a Service solution that makes sense of your business customers' financial data.";

const BrandPage: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  const customer = useSelector(getCustomerState);

  const [brandColor, setBrandColor] = useState<string>();
  const [accentColor, setAccentColor] = useState<string>();
  const [pageCompanyLogo, setPageUploadCompanyLogo] = useState<string | File>();
  const [pageMetaFavicon, setPageMetaFavicon] = useState<string | File>();
  const [pageMetaImage, setPageMetaImage] = useState<string | File>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  //New site settings
  const [expanded, setExpanded] = useState('saveBusinessUrlToggle');

  const [saveBusinessUrlEnabled, setSaveBusinessUrlEnabled] = useState(false);

  const [overviewEnabled, setOverviewEnabled] = useState(true);
  const [summaryEnabled, setSummaryEnabled] = useState(true);

  const [accountingRequired, setAccountingRequired] = useState(false);
  const [bankingRequired, setBankingRequired] = useState(false);
  const [commerceRequired, setCommerceRequired] = useState(false);

  const [railzWatermarkEnabled, setRailzWatermarkEnabled] = useState(true);
  const [helpLinkEnabled, setHelpLinkEnabled] = useState(true);
  const [termsAndConditionsEnabled, setTermsAndConditionsEnabled] = useState(true);
  const [saveUrl, setSaveUrl] = useState('');

  const defaultValues = {
    leftSideBarTitle: customer?.companyName,
    leftSideBarBody: t('DASHBOARD_SETTINGS_SITES_LEFT_PAGE_BODY'),
    metaTitle: DEFAULT_META_TITLE,
    metaDescription: DEFAULT_META_DESCRIPTION,
    onboardLandingPageTitle: t('DASHBOARD_SITES_ONBOARDING_HEADING'),
    onboardLandingPageBody: t('DASHBOARD_SITES_ONBOARDING_BODY'),
    helpLinkTitle: t('DASHBOARD_SITES_HELP_LINK_URL'),
    helpLinkBody: t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_DIPLAY'),
    termsAndConditionsTitle: t('DASHBOARD_SETTINGS_SITES_TERMS_LINK_PLACEHOLDER'),
    termsAndConditionsBody: t('DASHBOARD_SETTINGS_SITES_TERMS_PAGE_TEXT_FIELD_DIPLAY'),
    overviewEnabled: true,
    summaryEnabled: true,
    railzWatermarkEnabled: true,
    helpLinkEnabled: true,
    termsAndConditionsEnabled: true,
    accountingRequired: false,
    bankingRequired: false,
    commerceRequired: false,
    saveBusinessUrlEnabled: false,
  };

  const onFreePlan = customer?.billingPlan === Plan.FREE;

  useEffect(() => {
    if (isLoading) return null;
    const getCustomerFormValues = (): RailzSitesMetadata => {
      if (customer?.billingPlan !== Plan.CUSTOM) return { ...defaultValues };
      if (!customer?.railzSitesMetadata) return { ...defaultValues };
      return customer.railzSitesMetadata;
    };

    const formValuesToSet = getCustomerFormValues();
    reset(formValuesToSet);
    setPageUploadCompanyLogo(customer?.railzConnectMetadata?.companyLogo);
    setPageMetaImage(formValuesToSet.metaImageUrl);
    setPageMetaFavicon(formValuesToSet.faviconUrl);
  }, [customer]);

  const validationSchema = yup.object().shape({
    onboardLandingPageTitle: yup.string().max(50, t('DASHBOARD_SITES_CONTENT_CRUD_MAX')).nullable(),
    onboardLandingPageBody: yup
      .string()
      .max(140, t('DASHBOARD_SITES_CONTENT_BODY_CRUD_MAX'))
      .nullable(),
    leftSideBarTitle: yup.string().max(50, t('DASHBOARD_SITES_CONTENT_CRUD_MAX')).nullable(),
    leftSideBarBody: yup.string().max(140, t('DASHBOARD_SITES_CONTENT_BODY_CRUD_MAX')).nullable(),
    metaTitle: yup
      .string()
      .max(70, t('Title must be less than or equal 70 characters.'))
      .nullable(),
    metaDescription: yup
      .string()
      .max(200, t('Description must be less than or equal 200 characters.'))
      .nullable(),
    helpLinkTitle: yup
      .string()
      .matches(WEBSITE_WITH_OR_WITHOUT_PROTOCOL_REGEX, 'DASHBOARD_COMPANY_WEBSITE_INVALID')
      .min(2, t('DASHBOARD_COMPANY_WEBSITE_INVALID'))
      .max(2048, t('DASHBOARD_COMPANY_WEBSITE_INVALID'))
      .nullable(),
    helpLinkBody: yup
      .string()
      .min(2, 'DASHBOARD_SITES_CONTENT_BODY_CRUD_MIN')
      .max(50, t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_CRUD_MAX'))
      .nullable(),
    termsAndConditionsTitle: yup
      .string()
      .matches(WEBSITE_WITH_OR_WITHOUT_PROTOCOL_REGEX, 'DASHBOARD_COMPANY_WEBSITE_INVALID')
      .min(2, t('DASHBOARD_COMPANY_WEBSITE_INVALID'))
      .max(2048, t('DASHBOARD_COMPANY_WEBSITE_INVALID'))
      .nullable(),
    termsAndConditionsBody: yup
      .string()
      .min(2, 'DASHBOARD_SETTINGS_SITES_LINK_PAGE_CRUD_MIN')
      .max(50, t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_CRUD_MAX'))
      .nullable(),
    saveBusinessUrlEnabled: yup.bool().nullable(),
    overviewEnabled: yup.bool().nullable(),
    summaryEnabled: yup.bool().nullable(),
    railzWatermarkEnabled: yup.bool().nullable(),
    helpLinkEnabled: yup.bool().nullable(),
    termsAndConditionsEnabled: yup.bool().nullable(),
    accountingRequired: yup.bool().nullable(),
    bankingRequired: yup.bool().nullable(),
    commerceRequired: yup.bool().nullable(),
  });

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <Header
        drawerMenu={true}
        title={t('DASHBOARD_TOP_NAV_RAILZ_SITES')}
        leftComponent={null}
        rightComponent={null}
        testId="test-brand-page"
      />
      <form className={classes.view}>
        <div className={classes.sideBar}>
          <SitesForm
            expanded={expanded}
            setExpanded={setExpanded}
            saveBusinessUrlEnabled={saveBusinessUrlEnabled}
            setSaveBusinessUrlEnabled={setSaveBusinessUrlEnabled}
            overviewEnabled={overviewEnabled}
            setSummaryEnabled={setSummaryEnabled}
            accountingRequired={accountingRequired}
            setAccountingRequired={setAccountingRequired}
            bankingRequired={bankingRequired}
            setBankingRequired={setBankingRequired}
            commerceRequired={commerceRequired}
            setCommerceRequired={setCommerceRequired}
            summaryEnabled={summaryEnabled}
            railzWatermarkEnabled={railzWatermarkEnabled}
            setRailzWatermarkEnabled={setRailzWatermarkEnabled}
            setOverviewEnabled={setOverviewEnabled}
            helpLinkEnabled={helpLinkEnabled}
            setHelpLinkEnabled={setHelpLinkEnabled}
            termsAndConditionsEnabled={termsAndConditionsEnabled}
            setTermsAndConditionsEnabled={setTermsAndConditionsEnabled}
            setBrandColor={setBrandColor}
            setAccentColor={setAccentColor}
            setPageUploadCompanyLogo={setPageUploadCompanyLogo}
            pageCompanyLogo={pageCompanyLogo}
            pageMetaFavicon={pageMetaFavicon}
            setPageMetaFavicon={setPageMetaFavicon}
            pageMetaImage={pageMetaImage}
            setPageMetaImage={setPageMetaImage}
            userIsOnFreePlan={onFreePlan}
            setSaveUrl={setSaveUrl}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            defaultContent={defaultValues}
            sitesForm={{
              handleSubmit,
              control,
              getValues,
              errors,
              setValue,
              clearErrors,
              watch,
            }}
          />
        </div>
        <div className={classes.previewCanvas}>
          <DynamicPreview
            expandedSection={expanded}
            brandColor={brandColor}
            accentColor={accentColor}
            pageCompanyLogo={pageCompanyLogo}
            helpLinkEnabled={helpLinkEnabled}
            termsAndConditionsEnabled={termsAndConditionsEnabled}
            railzWatermarkEnabled={railzWatermarkEnabled}
            overviewEnabled={overviewEnabled}
            summaryEnabled={summaryEnabled}
            accountingRequired={accountingRequired}
            bankingRequired={bankingRequired}
            commerceRequired={commerceRequired}
            sitesForm={{ watch }}
            saveUrl={saveUrl}
            isLoading={isLoading}
            saveUrlEnabled={saveBusinessUrlEnabled}
            pageMetaFavicon={pageMetaFavicon}
          />
        </div>
      </form>
    </>
  );
};

export default BrandPage;
