import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
} from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment-timezone';

import { popOverStyles } from './style';

import { isOutsideDateRange } from 'helpers/report-helpers';

interface Props {
  label: string;
  placeholder: string;
  value: Moment;
  onChange: (date: Moment) => void;
  isStartDate: boolean;
  inputVariant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  format?: string;
  view?: string;
  minDate?: Date; // min/maxDate require a native date obj
  maxDate?: Date;
  disabled?: boolean;
}

enum QUARTER {
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
}

const START_DATES = {
  q1: '01/01/',
  q2: '04/01/',
  q3: '07/01/',
  q4: '10/01/',
};

const END_DATES = {
  q1: '03/31/',
  q2: '06/30/',
  q3: '09/30/',
  q4: '12/31/',
};

const quarters = [
  {
    value: QUARTER.Q1,
    primaryText: 'Q1',
    secondaryText: 'Jan, Feb, Mar',
  },
  {
    value: QUARTER.Q2,
    primaryText: 'Q2',
    secondaryText: 'Apr, May, Jun',
  },
  {
    value: QUARTER.Q3,
    primaryText: 'Q3',
    secondaryText: 'Jul, Aug, Sep',
  },
  {
    value: QUARTER.Q4,
    primaryText: 'Q4',
    secondaryText: 'Oct, Nov, Dec',
  },
];

export default function QuarterSelection({
  label,
  placeholder,
  value,
  onChange,
  isStartDate = false,
  size = 'small',
  format = 'MM/DD/YYYY',
  minDate,
  maxDate,
  disabled,
}: Props): JSX.Element {
  const popOverClass = popOverStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dateMonthVal, setDateMonthVal] = useState('');
  const [yearVal, setYearVal] = useState(value.year());
  const [fomattedDateVal, setFormattedDateVal] = useState('');
  const targetRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const openQuarterSelection = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeQuarterSelection = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isOutOfDateRange = (quarter): boolean => {
    const dateString = (isStartDate ? START_DATES[quarter] : END_DATES[quarter]) + yearVal;

    return isOutsideDateRange(isStartDate, dateString, minDate, maxDate);
  };

  const handleYearChangeBy = (val: number): void => {
    const newYearVal = yearVal + val;
    setYearVal(newYearVal);
    const newDateCandidate = moment(dateMonthVal + newYearVal, format);
    if (newDateCandidate.toString() !== 'Invalid date') {
      setSelectedDate(dateMonthVal + newYearVal);
    }
  };

  const chooseQuarter = (quarter: QUARTER, index): void => {
    const newMonthVal = isStartDate ? START_DATES[quarter] : END_DATES[quarter];
    setSelectedIndex(index);
    setDateMonthVal(newMonthVal);
    setSelectedDate(newMonthVal + yearVal);
    closeQuarterSelection();
  };

  const setSelectedDate = (dateString): void => {
    setFormattedDateVal(dateString);
    const momentDateVal = moment(dateString, format);
    onChange(momentDateVal);
  };

  useEffect(() => {
    const stringDate = value.format(format);
    setFormattedDateVal(stringDate);
    setYearVal(value.year());
    setDateMonthVal(stringDate.slice(0, 6));
  }, [value]);

  return (
    <>
      <TextField
        id="quarterInput"
        data-tesid="quarterInput"
        ref={targetRef}
        className={popOverClass.root + ' ' + popOverClass.quarterInputField}
        label={label}
        placeholder={placeholder}
        value={fomattedDateVal}
        variant="outlined"
        disabled={disabled}
        fullWidth
        size={size}
        onClick={openQuarterSelection}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={openQuarterSelection}>
                <CalendarTodayOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={open ? 'simple-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={closeQuarterSelection}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <div className={popOverClass.yearList}>
              <IconButton
                data-testid="yearDecButton"
                disabled={isOutOfDateRange(QUARTER.Q1) || yearVal === minDate.getFullYear()}
                onClick={(): void => handleYearChangeBy(-1)}
              >
                <ArrowBackIos fontSize="small" />
              </IconButton>
              <ListItemText
                data-testid="yearDisplay"
                primary={yearVal}
                className={popOverClass.yearDisplay}
              />
              <IconButton
                data-testid="yearIncButton"
                disabled={isOutOfDateRange(QUARTER.Q4) || yearVal === new Date().getFullYear()}
                onClick={(): void => handleYearChangeBy(1)}
              >
                <ArrowForwardIos fontSize="small" />
              </IconButton>
            </div>
          </ListItem>
          {quarters.map((quarter, index) => (
            <ListItem
              key={index}
              data-testid={'quarterSel-' + index}
              selected={selectedIndex === index}
              disabled={isOutOfDateRange(quarter.value)}
              button
              className={selectedIndex === index ? popOverClass.selectedOption : ''}
              onClick={(): void => chooseQuarter(quarter.value, index)}
            >
              <ListItemText
                data-testid={'quarterText-' + index}
                className={popOverClass.quarterListText}
                primary={quarter.primaryText}
                secondary={quarter.secondaryText}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
