import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { RailzButton } from '@railzai/railz-uikit-react';

import Add from '@material-ui/icons/Add';

import useStyle from './style';

import { FormInput } from 'components/form';

interface Props {
  paramType: string;
  control: any;
  errorobj: any;
  append: any;
  remove: any;
  fields: any;
}

const WebookOptionalParam = ({
  paramType,
  control,
  errorobj: errors,
  append,
  remove,
  fields,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyle();
  const paramTypeUpperCase = paramType.toUpperCase();

  const addFormField = (): void => {
    if (fields?.length < 5) {
      append({ name: '', value: '' });
    }
  };

  const removeFormField = (index): void => {
    remove(index);
  };

  return (
    <>
      <Divider className={classes.divider} />
      {fields.map((item, i) => (
        <Grid container spacing={1} key={item.id} md={12} className={classes.mainGrid}>
          <Grid item md={6} xs={12}>
            <FormInput
              label={t(`DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_${paramTypeUpperCase}_NAME`)}
              placeholder={t(
                `DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_${paramTypeUpperCase}_NAME`,
              )}
              name={`${paramType}[${i}].name`}
              margin="dense"
              defaultValue={item.name}
              testid={`webhook-optional-param-${paramType}Name${i}-input`}
              fullWidth
              errorobj={errors}
              control={control}
              type="string"
            />
            {errors && errors[paramType] && (
              <span className="errorText">{t(errors[paramType].message)}</span>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <FormInput
              label={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_HEADER_VALUE')}
              placeholder={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_HEADER_VALUE')}
              name={`${paramType}[${i}].value`}
              margin="dense"
              defaultValue={item.value}
              testid={`webhook-optional-param-${paramType}value${i}-input`}
              fullWidth
              errorobj={errors}
              control={control}
              type="string"
            />
          </Grid>

          <Grid item md={1} xs={12}>
            <RailzButton
              type="text warning"
              aria-label="DASHBOARD_BUSINESS_MENU_DELETE"
              onClick={(): void => removeFormField(i)}
              label={t('DASHBOARD_BUSINESS_MENU_DELETE')}
              className={classes.deleteButton}
            >
              <span slot="prefix">
                <DeleteOutlinedIcon fontSize="small" />
              </span>
            </RailzButton>
          </Grid>
        </Grid>
      ))}

      {fields?.length < 5 && (
        <RailzButton
          type="text primary"
          className={classes.addHeaderButton}
          onButtonClick={(): void => addFormField()}
          label={t(`DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_${paramTypeUpperCase}_ENUM`)}
        >
          <span slot="prefix">
            <Add fontSize="small" />
          </span>
        </RailzButton>
      )}
    </>
  );
};

export default WebookOptionalParam;
