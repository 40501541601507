import {
  AspType,
  IntegrationStatus,
  Integration,
  ServiceEntityState,
  ServiceType,
  Services,
  ServiceWithIntegrations,
} from '../../../types';

import { isActive, isBeta, isManageable } from './service-list-item-components/helper';

import { CustomerState } from 'store/features/account/customer/customer.state';

const MAX_INTEGRATIONS_PER_SERVICE = 3;

const TooltipTextByService = {
  [AspType.RAILZ_SANDBOX]: 'DASHBOARD_INTEGRATION_SANDBOX_TOOLTIP',
};

function filterServices(services: Services[], type: ServiceType, sandbox: boolean): Services[] {
  if (sandbox)
    return services.filter(
      ({ kind, name, hasOwnSandbox }) => kind === type && name !== AspType.MYOB && hasOwnSandbox,
    );

  return services.filter(
    ({ status, kind, name }) =>
      status !== 'sandbox' &&
      kind === type &&
      ![AspType.MYOB, AspType.RAILZ_SANDBOX].includes(name),
  );
}

function sortServices(filteredServices: Services[]): Services[] {
  return filteredServices.sort((a, b) => {
    if (a.name === AspType.RAILZ_SANDBOX) return -1;

    if (a.status === ServiceEntityState.ACTIVE && b.status !== ServiceEntityState.ACTIVE) return -1;
    if (a.status !== ServiceEntityState.ACTIVE && b.status === ServiceEntityState.ACTIVE) return 1;
    if (!!a.beta && !b.beta) return 1;
    if (!a.beta && !!b.beta) return -1;
    else return a.name > b.name ? 1 : -1;
  });
}

function addIntegrationInfoToServiceList(
  baseListOfServices: Services[],
  integrations: Integration[],
): Array<Omit<ServiceWithIntegrations, 'isConfigurable' | 'totalConnections'>> {
  return baseListOfServices.map((item) => {
    const relatedIntegrations = integrations
      .filter(
        ({ service, isMockData, deletedAt }) =>
          (service === AspType.RAILZ_SANDBOX || !isMockData) && item.name === service && !deletedAt,
      )
      .map((integration) => {
        return { ...integration, connectionCount: integration.connectionCount || 0 };
      })
      .sort((a, b) => {
        if (a.isActive || b.isActive) return Number(!!b.isActive) - Number(!!a.isActive);
        return String(a.name).localeCompare(b.name);
      });

    const enabled =
      !!relatedIntegrations.length &&
      relatedIntegrations.every(({ state }) => state === IntegrationStatus.ENABLED);

    return {
      ...item,
      enabled,
      canAddMoreIntegrations: relatedIntegrations.length < MAX_INTEGRATIONS_PER_SERVICE,
      relatedIntegrations,
      tooltipText: TooltipTextByService[item.name],
    };
  });
}

function isConfigurableService(account: Services, user: CustomerState, sandbox: boolean): boolean {
  if (sandbox && !account.hasOwnSandbox) return false;
  if (isBeta(account) && !isBeta(user)) return false;
  if (!isActive(account) || !isManageable(account)) return false;
  return true;
}

function calculateTotalConnectionNumberOfService(integrations: Integration[]): number {
  return integrations.reduce(
    (acc, { connectionCount }) => (connectionCount ? acc + connectionCount : acc),
    0,
  );
}

export function prepareServicesAndRelatedIntegrations({
  services,
  integrations,
  user,
  type,
  sandbox,
}: {
  services: Services[];
  integrations: Integration[];
  user: CustomerState;
  type: ServiceType;
  sandbox: boolean;
}): Array<ServiceWithIntegrations> {
  const baseList = filterServices(services, type, sandbox);
  const sortedList = sortServices(baseList);
  const listWithIntegrations = addIntegrationInfoToServiceList(sortedList, integrations);
  return listWithIntegrations.map((service) => ({
    ...service,
    isConfigurable: isConfigurableService(service, user, sandbox),
    totalConnections: calculateTotalConnectionNumberOfService(service.relatedIntegrations),
  }));
}
