import * as Sentry from '@sentry/react';

export const SENTRY_CONTEXTS = {
  USER: 'user',
  TEAM: 'team',
  CUSTOMER: 'customer',
  SANDBOX: 'sandbox',
} as const;

type SentryContexts = typeof SENTRY_CONTEXTS[keyof typeof SENTRY_CONTEXTS];

export function updateSentryContext(
  context: SentryContexts,
  data: Record<string, string | boolean | number> | null = null,
): void {
  if (context === 'user') {
    Sentry.setUser(data);
  } else {
    Sentry.setContext(context, data);
  }
}

export function resetAllSentryContexts(): void {
  for (const context in SENTRY_CONTEXTS) {
    updateSentryContext(context as SentryContexts, null);
  }
}
