import React from 'react';

import { ConnectionStatus } from '../../pages/business/types/enums';

interface CircleProps {
  type: string;
}

export default function CircleIndicator(props: CircleProps): React.ReactElement {
  const { type } = props;
  const activeColor = '#38C076';
  const inactiveColor = '#E10A0A';

  let color: string;

  switch (type) {
    case ConnectionStatus.ACTIVE:
    case ConnectionStatus.PENDING:
      color = activeColor;
      break;
    default:
      color = inactiveColor;
      break;
  }

  const circleStyle = {
    marginLeft: 10,
    display: 'inline-block',
    backgroundColor: color,
    borderRadius: '50%',
    width: 6,
    height: 6,
    left: 0,
    top: 0,
  };

  return <div style={circleStyle} />;
}
