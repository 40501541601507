import React from 'react';

export default function SuccessIcon(): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#F5F5F5" />
      <circle cx="7.98442" cy="7.98442" r="7.98442" fill="#38C076" />
      <path
        // eslint-disable-next-line max-len
        d="M7.03195 11.3267C6.8151 11.3267 6.60838 11.2321 6.46646 11.0667L4.67926 8.98159C4.41163 8.66928 4.44781 8.19924 4.76012 7.93161C5.07229 7.66369 5.54231 7.69987 5.81025 8.01234L6.97862 9.37537L10.6713 4.92255C11.2429 4.12189 11.8412 4.56526 11.267 5.36935L7.63782 11.0148C7.50468 11.2011 7.29333 11.3158 7.06473 11.3259C7.05369 11.3264 7.04283 11.3267 7.03195 11.3267Z"
        fill="white"
      />
    </svg>
  );
}
