import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    createAccountButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      padding: theme.spacing(1.5),
    },
    row: {
      display: 'flex',
      gap: `0 ${theme.spacing(2)}px`,
      flexWrap: 'wrap',
      flexGrow: 1,

      '& > div': {
        width: '40%',
        minWidth: '200px',
        flexGrow: 1,
      },
    },
  }),
);
