import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes appear': {
      from: {
        transform: 'scale(.8)',
        opacity: 0,
        filter: 'blur(5px)',
      },
    },
    title: {
      fontWeight: 600,
      fontSize: '1.125em',
      lineHeight: '1.375em',
      textAlign: 'center',
      paddingBottom: theme.spacing(2),
    },
    description: {
      fontWeight: 400,
      fontSize: '0.875em',
      lineHeight: '1.25em',
      textAlign: 'center',
      color: theme.palette.grey[800],
      paddingBottom: theme.spacing(4),
    },
    inputBox: {
      background: 'white',
      border: '2px solid',
      borderColor: theme.palette.grey[300],
      borderRadius: '.5em',
      padding: '.5em 1em',
      fontSize: '.875em',
      textAlign: 'left',
      justifySelf: 'center',
      textOverflow: 'ellipsis',
      display: 'flex',
    },
    inputText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
    },
    inputIcon: {
      fontSize: '1.19em',
      marginLeft: '1em',
    },
    visible: {
      width: '80% !important',
      opacity: 1,
      visibility: 'visible',
      animation: `$appear .2s ease-in-out both`,
    },
    cta: {
      marginTop: '1.25em',
    },
    cardPillSection: {
      transform: 'translate(-50%, 48%)',
      position: 'relative',
      left: '7%',
      width: 'fit-content',
      backgroundColor: 'white',
    },
    inputTitle: {
      fontWeight: 400,
      fontSize: '0.625em',
      lineHeight: '0.75em',
      color: theme.palette.grey[800],
      marginRight: '0.3em',
      marginLeft: '0.3em',
    },
  }),
);
