import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { useIntercom } from 'react-use-intercom';

import { useSelector } from 'react-redux';

import storeService from '../store';

import { isSandbox, isUserLoggedIn } from '../store/features/auth';

import PrivateRoute from './private-routing.component';

// Pages
import ProfilePage from './profile';
import SignupPage from './signup';
import QuestionnairePage from './signup/questionnaire';
import PlanPage from './plan';
import LoginPage from './login';
import HomePage from './home';
import ForgotPasswordPage from './forgot-password';
import ResetPasswordPage from './reset-password';
import ApiKeysPage from './developers/api-keys';
import ConnectPage from './connect/setup';
import IpWhitelistPage from './connect/ip-whitelist';
import SitesPage from './sites';
import BusinessPage from './business';
import CompanyPage from './company';
import SecurityPage from './security';
import VerifyEmail from './verify-email';
import TeamsPage from './teams';
import TeamPageV2 from './teams/team';
import MembersPageV2 from './members';
import WebhooksPage from './developers/webhooks';
import WebhookLogsPage from './developers/webhook-logs';
import VerifyEmailPage from './email-verification';
import FinancialSummaryPage from './financial-summary';
import MfaLogin from './login/mfa';
import {
  AccountingIntegrationAccounting,
  AccountingIntegrationBanking,
  AccountingIntegrationCommerce,
} from './integration';
import Settings from './settings';

import SelectPlan from './signup/select-plan';

import DataSync from './data-sync';

import { Role } from 'types';

const Root: React.FC = () => {
  const { boot } = useIntercom();

  const store = storeService.getStore();
  const isLoggedIn = useSelector<ReturnType<typeof store.getState>, boolean>(isUserLoggedIn);
  const sandbox = useSelector<ReturnType<typeof store.getState>, boolean>(isSandbox);

  useEffect(() => {
    // boot intercom for unauthenticated users
    if (!isLoggedIn) boot();
    // for authenticated users we boot directly in the private route

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={HomePage} />
        {/* account */}
        <PrivateRoute exact path="/account/profile" component={ProfilePage} />
        <PrivateRoute
          exact
          path="/account/company"
          component={CompanyPage}
          roles={[Role.SUPER_ADMINISTRATOR, Role.BILLING]}
        />
        <PrivateRoute exact path="/account/teams" component={TeamsPage} />
        <PrivateRoute exact path="/account/teams/team" component={TeamPageV2} />
        <PrivateRoute
          exact
          path="/account/members"
          component={MembersPageV2}
          roles={[Role.SUPER_ADMINISTRATOR]}
        />
        <PrivateRoute exact path="/account/security" component={SecurityPage} />
        <PrivateRoute exact path="/account/billing" component={PlanPage} />
        <PrivateRoute
          exact
          path="/integration/accounting"
          component={AccountingIntegrationAccounting}
        />
        <PrivateRoute exact path="/integration/banking" component={AccountingIntegrationBanking} />
        <PrivateRoute
          exact
          path="/integration/commerce"
          component={AccountingIntegrationCommerce}
        />
        <PrivateRoute
          exact
          path="/integration/data-sync"
          component={DataSync}
          roles={[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR]}
        />
        {/* developers */}
        <PrivateRoute
          exact
          path="/developers/api-keys"
          component={ApiKeysPage}
          roles={[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER]}
        />
        <PrivateRoute
          exact
          path="/developers/webhooks"
          component={WebhooksPage}
          roles={[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER]}
        />
        <PrivateRoute
          exact
          path="/developers/webhook-logs"
          component={WebhookLogsPage}
          roles={[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER]}
        />
        <PrivateRoute
          exact
          path="/connect/setup"
          component={ConnectPage}
          roles={[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER]}
        />
        <PrivateRoute
          exact
          path="/connect/ip-whitelist"
          component={IpWhitelistPage}
          roles={[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR]}
        />
        <PrivateRoute
          exact
          path="/sites"
          component={SitesPage}
          roles={[
            Role.SUPER_ADMINISTRATOR,
            Role.ADMINISTRATOR,
            sandbox ? Role.DEVELOPER : Role.BILLING,
          ]}
        />
        <PrivateRoute
          exact
          path="/businesses"
          component={BusinessPage}
          roles={[
            Role.SUPER_ADMINISTRATOR,
            Role.ADMINISTRATOR,
            Role.ANALYST,
            sandbox ? Role.DEVELOPER : Role.BILLING,
          ]}
        />
        <PrivateRoute path="/businesses/overview" component={FinancialSummaryPage} />
        <PrivateRoute
          exact
          path={'/settings'}
          component={Settings}
          roles={[Role.SUPER_ADMINISTRATOR]}
        />
        <PrivateRoute exact path="/questionnaire" component={QuestionnairePage} />
        <PrivateRoute exact path="/select-plan" component={SelectPlan} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/verification" component={VerifyEmailPage} />
        <Route exact path="/verifyEmail" component={VerifyEmail} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/login/mfa" component={MfaLogin} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path={['/resetPassword', '/verifyUser']} component={ResetPasswordPage} />
        {isLoggedIn && <Redirect to="/" />}
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
};

export default Root;
