import { Box, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonRounded';

import useStyles from './style';

import { TeamUserResponse } from 'types';

interface Props {
  user: TeamUserResponse;
}

const UserDetails: React.FC<Props> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderAvatar = ({ firstName, lastName, profileImageUrl }): React.ReactElement => {
    const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;

    return (
      <div
        className={classes.avatar}
        style={{
          backgroundImage: `url(${profileImageUrl}`,
        }}
        key={`${initials}`}
        data-testid={`avatar-${initials}`}
      >
        {!profileImageUrl && initials && <div className={classes.initials}>{initials}</div>}
        {!profileImageUrl && !initials && (
          <div className={classes.profileIcon}>
            <PersonOutlineOutlinedIcon style={{ color: 'white' }} className={classes.icon} />
          </div>
        )}
      </div>
    );
  };

  return (
    <Box>
      <Grid container spacing={1} className={classes.containerDetails}>
        <Grid item xs={2}>
          {renderAvatar(user)}
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.labelContainer}>
            <span className={classes.field}>{t('DASHBOARD_MEMBER_EDIT_NAME_LABEL')}:</span>
            {user?.firstName ? `${user?.firstName} ${user?.lastName}` : '-'}
          </Typography>
          <Typography className={classes.labelContainer}>
            <span className={classes.field}>{t('DASHBOARD_MEMBER_EDIT_EMAIL_LABEL')}:</span>
            {user?.email}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
    </Box>
  );
};

export default UserDetails;
