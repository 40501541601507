import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    cardContainer: {
      height: 248,
      flex: 1,
      borderRadius: theme.spacing(1),
      boxShadow: 'none',
      border: '1px solid #D8D8D8',
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.grey[900],
      fontFeatureSettings: `'ss02' on, 'dnom' on, 'ss01' on, 'salt' on`,
      transition: 'opacity 5s ease-in-out',
      '&:hover $tooltipIcon': {
        opacity: '1 !important',
        transform: 'scale(1) !important',
        pointerEvents: 'auto',
      },
    },
    emptyCard: {
      '&.accounting': {
        color: theme.palette.grey[300],
        background: theme.palette.common.white,
        borderColor: `#E0E0E0`,
      },
      '&.banking': {
        background: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
      },
      '&.commerce': {
        background: `#F9F9F9`,
        borderColor: `#F9F9F9`,
      },
    },
    cardTitleContainer: {
      padding: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(2),
      color: theme.palette.grey[900],
      fontSize: `1.125rem`,
      height: theme.spacing(11),
      background: `#F9F9F9`,
      alignItems: 'unset',
      justifyContent: 'space-between',
      '&.empty': {
        background: 'transparent',
      },
      '& svg, h6': {
        lineHeight: '24px',
      },
      '&.empty.accounting': {
        color: `#006037`,
      },
    },
    cardSubtitle: {
      borderTop: '1px solid #E0E0E0',
      borderBottom: '1px solid #E0E0E0',
      padding: `${theme.spacing()}px 19px ${theme.spacing()}px ${theme.spacing(2)}px `,
      marginBottom: 0,
      justifyContent: 'space-between',
    },
    cardBody: {
      padding: theme.spacing(2),
      height: '100%',
      '&.empty': {
        alignContent: 'space-between',
        background: 'transparent',
        color: theme.palette.grey[900],
      },
      '&.list': {
        marginTop: `-1px`,
        fontFeatureSettings: `'clig' off, 'liga' off`,
        paddingTop: 0,
        overflowY: 'auto',
        maxHeight: '70%',
        fontSize: `0.875rem`,
        paddingBottom: 0,
      },
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      '& h6': {
        fontSize: `1.125rem`,
        lineHeight: '24px',
      },
    },
    cardTitleCount: {
      height: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      '& .count': {
        paddingRight: theme.spacing(2),
        fontSize: `1.625rem`,
        fontWeight: 600,
        fontFeatureSettings: `'clig' off, 'liga' off`,
        color: theme.palette.common.black,
        textAlign: 'right',
        lineHeight: '26px',
      },
      '& .description': {
        paddingRight: theme.spacing(2),
        fontSize: `0.625rem`,
        fontWeight: 500,
        fontFeatureSettings: `'clig' off, 'liga' off`,
        color: theme.palette.grey[600],
        textAlign: 'right',
        lineHeight: '12px',
      },
    },
    tooltipContainer: {
      height: theme.spacing(2),
      '& .MuiTooltip-tooltip, & .MuiTooltip-arrow::before': {
        background: `var(--primary-dark-green, #003032)`,
        fontSize: '0.875rem',
      },
      '& .MuiTooltip-tooltip': {
        fontFeatureSettings: `'clig' off, 'liga' off`,
        padding: theme.spacing(2),
      },
    },

    tooltipIcon: {
      marginLeft: theme.spacing(1),
      fontSize: '1rem',
      color: 'var(--greyscale-grey-800, #424242)',
      transition: 'all 0.2s ease-in-out',
      pointerEvents: 'auto',
      transformOrigin: 'top right',
      willChange: 'transform, opacity',
      opacity: 0.5,
      transform: 'scale(.7)',
    },
    statsColumnLabel: {
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0.54px',
      color: theme.palette.grey[600],
      fontSize: `0.5625rem`,
      textTransform: 'uppercase',
      verticalAlign: 'sub',
      textAlign: 'center',
    },
    connectionCountCol: {
      display: 'flex',
      justifyContent: 'end',
      color: theme.palette.grey[800],
    },
    serviceKind: {
      fontWeight: 600,
      alignItems: 'center',
    },

    providerRow: {
      padding: `${theme.spacing(1.5)}px 0`,
      '&:not(:first-child)': {
        borderTop: '1px solid #E0E0E0',
      },
    },
    providerNameAndLogo: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      color: theme.palette.grey[800],
    },
    imgContainer: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,

      '& railz-logo': {
        width: '100%',
        flexShrink: 0,
      },

      '& img': {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
    rowItem: {
      display: 'flex',
      alignItems: 'center',
      '&.connectionCount': {
        paddingRight: theme.spacing(6),
        ['@media screen and (min-width: 960px) and (max-width: 1100px)']: {
          paddingRight: theme.spacing(4),
        },
      },
    },
    learnMoreCtx: {
      border: '2px solid',
      fontWeight: 500,
    },
  });
});
