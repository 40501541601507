import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import ReCaptcha from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../../store';

import { doSignupApi, getErrorMessageResponse, resetAuthError } from '../../../store/features/auth';

import { EventService } from '../../../services';

import useStyle from './style';

import Config from 'config';
import { EMAIL_INVALID_DOMAINS_REGEX, PASSWORD_REGEX } from 'helpers/regex.helper';
import { CustomerRequest } from 'types';

import { View } from 'components';
import { FormCheckBox, FormInput } from 'components/form';
import { openNewTab } from 'helpers/open-new-tab';
import i18n from 'translations';

const initialValues: CustomerRequest = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  company: '',
  term: false,
  captchaToken: '',
  apiPurpose: '',
  businessRole: '',
  country: '',
};

const getMessagekey = (message): string => {
  switch (message) {
    case 'Company already exist!':
      return 'DASHBOARD_SIGNUP_EMAIL_MULTIPLE_USERS_DOMAIN';
    case 'Email already in use':
      return 'DASHBOARD_SIGNUP_EMAIL_DUPLICATE';
    case 'Cannot register multiple users in this domain':
      return 'DASHBOARD_SIGNUP_EMAIL_MULTIPLE_USERS_DOMAIN';
    case 'ACCOUNT NOT APPROVED':
      return 'DASHBOARD_SIGNUP_EMAIL_NOT_VALID';
    case 'DISPOSABLE_EMAIL':
      return 'DASHBOARD_SIGNUP_EMAIL_DISPOSABLE_EMAIL';
    default:
      return 'DASHBOARD_ENUM_ERROR_MESSAGE_UNKNOWN';
  }
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required('DASHBOARD_SIGNUP_FIRST_NAME_REQUIRED')
    .min(2, 'DASHBOARD_SIGNUP_FIRST_NAME_MIN_LENGTH')
    .max(40, 'DASHBOARD_SIGNUP_FIRST_NAME_MAX_LENGTH')
    .nullable(),
  lastName: yup
    .string()
    .trim()
    .required('DASHBOARD_SIGNUP_LAST_NAME_REQUIRED')
    .min(2, 'DASHBOARD_SIGNUP_LAST_NAME_MIN_LENGTH')
    .max(40, 'DASHBOARD_SIGNUP_LAST_NAME_MAX_LENGTH')
    .nullable(),
  email: yup
    .string()
    .trim()
    .required('DASHBOARD_SIGNUP_EMAIL_REQUIRED')
    .min(2, 'DASHBOARD_SIGNUP_EMAIL_MIN_LENGTH')
    .email('DASHBOARD_SIGNUP_EMAIL_REQUIRED')
    .max(50, 'DASHBOARD_SIGNUP_EMAIL_MAX_LENGTH')
    .matches(EMAIL_INVALID_DOMAINS_REGEX, 'DASHBOARD_EMAIL_NOT_WORK_EMAIL')
    .nullable(),
  password: yup
    .string()
    .required('DASHBOARD_SIGNUP_PASSWORD_REQUIRED')
    .matches(PASSWORD_REGEX, 'DASHBOARD_SIGNUP_PASSWORD_INVALID')
    .nullable(),
  term: yup.bool().oneOf([true], 'DASHBOARD_SIGNUP_TERMS_REQUIRED').nullable(),
  captchaToken: yup.string().required(i18n.t('DASHBOARD_VERIFY_HUMAN')).nullable(),
});

export default function SignupForm(): JSX.Element {
  const classes = useStyle();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const errorMessage = useSelector<ReturnType<typeof store.getState>, string[]>(
    getErrorMessageResponse,
  );

  const { handleSubmit, errors, register, setError, formState, control, setValue, trigger } =
    useForm({
      mode: 'onChange',
      defaultValues: initialValues,
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (errorMessage) {
      dispatch(resetAuthError());
      const message = getMessagekey(errorMessage[0]);
      if (message === 'DASHBOARD_SIGNUP_COMPANY_DUPLICATE') {
        setError('company', { message: 'DASHBOARD_SIGNUP_COMPANY_DUPLICATE' });
      } else if (
        [
          'DASHBOARD_SIGNUP_EMAIL_DUPLICATE',
          'DASHBOARD_SIGNUP_EMAIL_MULTIPLE_USERS_DOMAIN',
          'DASHBOARD_SIGNUP_EMAIL_DISPOSABLE_EMAIL',
        ].includes(message)
      ) {
        setError('email', { message });
      }

      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: message
          ? t(message)
          : i18n.exists(errorMessage[0])
          ? t(errorMessage[0])
          : t('DASHBOARD_SIGNUP_GENERIC_ERROR'),
      });
    }
    // eslint-disable-next-line
  }, [errorMessage]);

  useEffect(() => {
    register({ name: 'captchaToken' });
  }, []);

  const onVerifyCaptcha = (token): void => {
    setValue('captchaToken', token);
    trigger('captchaToken');
  };

  const onSubmit = async (data: CustomerRequest): Promise<void> => {
    delete data.captchaToken;
    delete data.term;
    dispatch(doSignupApi(data));
  };

  const { isSubmitting } = formState;
  return (
    <form
      className={classes.formContainer}
      data-testid="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classes.row}>
        <FormInput
          name="firstName"
          label="DASHBOARD_SIGNUP_FIRST_NAME_LABEL"
          placeholder="DASHBOARD_SIGNUP_FIRST_NAME_PLACEHOLDER"
          fullWidth
          errorobj={errors}
          control={control}
          testid="first-name-input"
        />
        <FormInput
          name="lastName"
          label="DASHBOARD_SIGNUP_LAST_NAME_LABEL"
          placeholder="DASHBOARD_SIGNUP_LAST_NAME_PLACEHOLDER"
          fullWidth
          errorobj={errors}
          control={control}
          testid="last-name-input"
        />
      </div>

      <FormInput
        name="email"
        type="email"
        label="DASHBOARD_SIGNUP_EMAIL_LABEL"
        placeholder="DASHBOARD_SIGNUP_EMAIL_PLACEHOLDER"
        fullWidth
        errorobj={errors}
        control={control}
        testid="email-input"
      />

      <FormInput
        name="password"
        type="password"
        label="DASHBOARD_SIGNUP_PASSWORD_LABEL"
        placeholder="DASHBOARD_SIGNUP_PASSWORD_PLACEHOLDER"
        fullWidth
        errorobj={errors}
        control={control}
        testid="password-input"
      />

      <FormCheckBox
        name="term"
        color="primary"
        control={control}
        errorobj={errors}
        testid="terms-input"
        label={
          <View flexDirection="row" alignItems="center" style={{ flexWrap: 'wrap' }}>
            <Typography>
              {t('DASHBOARD_SIGNUP_AGREE_TEXT')}
              <Trans
                i18nKey="DASHBOARD_SIGNUP_TERMS_TEXT"
                components={{
                  a: (
                    <a
                      href="https://www.railz.ai/terms.html"
                      onClick={(e): void => openNewTab(e, 'https://www.railz.ai/terms.html')}
                      className={classes.termsLink}
                      rel="noopener noreferrer"
                      color="secondary"
                    >
                      {t('DASHBOARD_SIGNUP_TERMS_LINK')}
                    </a>
                  ),
                }}
              />
              <Trans
                i18nKey="DASHBOARD_SIGNUP_PRIVACY_TEXT"
                components={{
                  a: (
                    <a
                      href="https://www.railz.ai/privacy.html"
                      onClick={(e): void => openNewTab(e, 'https://www.railz.ai/privacy.html')}
                      className={classes.termsLink}
                      rel="noopener noreferrer"
                      color="secondary"
                    >
                      {t('DASHBOARD_SIGNUP_PRIVACY_LINK')}
                    </a>
                  ),
                }}
              />
            </Typography>
          </View>
        }
      />
      <div className={classes.captchaContainer}>
        <ReCaptcha
          data-testid="captcha-input"
          sitekey={Config.RECAPTCHA_KEY}
          onChange={onVerifyCaptcha}
        />
        {errors.captchaToken && (
          <p className={classes.captchaError}>{errors.captchaToken.message}</p>
        )}
      </div>

      <div className={classes.footer}>
        <RailzButton
          size="large"
          buttonType="submit"
          isDisabled={isSubmitting}
          data-testid="signup-submit"
          label={t('DASHBOARD_SIGNUP_CTA')}
        />
        <Typography>
          {t('DASHBOARD_SIGNUP_CTA_LOGIN')}
          <Link to="/login" className={classes.signupLabel}>
            {t('DASHBOARD_SIGNUP_CTA_LOGIN_LABEL_LINK')}
          </Link>
        </Typography>
      </div>
    </form>
  );
}
