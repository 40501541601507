/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import AddIcon from '@material-ui/icons/Add';

import Alert from '@material-ui/lab/Alert';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';

import { useDispatch, useSelector } from 'react-redux';

import { AlertTitle } from '@material-ui/lab';

import { RailzButton } from '@railzai/railz-uikit-react';

import { isEmpty } from 'lodash';

import {
  createIpWhitelistApi,
  deleteIpWhitelistApi,
  getConnectApiResponse,
  getConnectError,
  getIps,
  getIpWhitelistApi,
  isLoading,
  resetConnectApiResponse,
  updateIpWhitelistApi,
} from '../../../store/features/connect';

import useStyles from './style';

import Form from './form';

import { Kind } from 'components/table-actions/table-actions';

import { Header, ScrollView, View } from 'components';
import { calloutStyle } from 'assets/styles/style';
import AlertDialog from 'components/dialog';
import BasicTable from 'components/table';
import { EventKind, IpWhitelistResponse } from 'types';
import { generateDropdown, showSnackbar } from 'helpers/common.helper';
import PageDescription from 'components/page-description/page-description';
import useBrandStyle from 'pages/sites/style';
import { openNewTab } from 'helpers/open-new-tab';

const eventDropdownValues = generateDropdown(EventKind);

interface Props {
  testid: string;
}

const IpWhitelist: React.FC<Props> = ({ testid = 'test-header-ip-whitelist-page' }) => {
  const [selectedIp, setIp] = useState(null);
  const [ips, setIps] = useState(null);
  const [errors] = useState([]);
  const [isShowForm, setShowForm] = useState(false);
  const [isOpenAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const ipWhitelists = useSelector(getIps);
  const apiMessage = useSelector(getConnectApiResponse);
  const errorResponse = useSelector(getConnectError);
  const loading = useSelector(isLoading);

  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();
  const calloutClasses = calloutStyle();
  const classesBrand = useBrandStyle();
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    getIpWhitelists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ipWhitelists) {
      setIps(ipWhitelists);
    }
  }, [ipWhitelists]);

  useEffect(() => {
    if (apiMessage) {
      if (apiMessage === 'no changes') {
        showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
      }
      if (apiMessage === 'update') {
        handleSuccess(true);
      } else if (apiMessage === 'delete') {
        getIpWhitelists();
        showSnackbar({
          message: t('DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_DELETE_SUCCESS'),
          type: 'success',
        });
      } else {
        handleSuccess(false);
      }
    }
    dispatch(resetConnectApiResponse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiMessage]);

  useEffect(() => {
    if (errorResponse) {
      const errorCode = errorResponse?.code;
      if (errorCode === 409) {
        showSnackbar({ message: t('DASHBOARD_RAILZ_CONNECT_IP_DUPLICATE'), type: 'error' });
      } else if (errorResponse.message.includes('delete')) {
        showSnackbar({
          message: t('DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_UPDATE_FAILURE'),
          type: 'error',
        });
      } else if (errorResponse.message.includes('create')) {
        showSnackbar({
          message: t('DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_CREATE_FAILURE'),
          type: 'error',
        });
      } else {
        showSnackbar({
          message: t('DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_UPDATE_FAILURE'),
          type: 'error',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponse]);

  const getIpWhitelists = (): void => {
    dispatch(getIpWhitelistApi());
  };

  const onAddClick = (): void => {
    setShowForm(true);
    setIp({});
  };

  const handleSuccess = (update: boolean): void => {
    setIp({});
    setShowForm(false);
    getIpWhitelists();
    const msg = update
      ? 'DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_UPDATE_SUCCESS'
      : 'DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_CREATE_SUCCESS';
    showSnackbar({ message: t(msg), type: 'success' });
  };

  const onEditOrAddIpWhitelist = (oldIp, ip): void => {
    if (oldIp?.uuid) {
      const newIp = {};
      if (oldIp.ip !== ip.ip) newIp['ip'] = ip.ip;
      if (oldIp.name !== ip.name) newIp['name'] = ip.name;
      if (Object.keys(newIp).length) {
        dispatch(updateIpWhitelistApi({ payload: newIp, uuid: oldIp.uuid }));
      } else {
        showSnackbar({
          message: t('DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_UPDATE_FAILURE_NO_UPDATE'),
          type: 'error',
        });
      }
    } else {
      dispatch(createIpWhitelistApi(ip));
    }
    setShowForm(false);
  };

  const onUpdateIpWhitelist = (ip): void => {
    setIp(ip);
    setShowForm(true);
  };

  const onConfirmDelete = async (): Promise<void> => {
    dispatch(deleteIpWhitelistApi(selectedIp.uuid));
    setOpenAlert(false);
  };

  const overflowOptions = [
    {
      key: Kind.EDIT,
      id: Kind.EDIT,
      icon: <EditIcon />,
      label: t('DASHBOARD_TEAM_EDIT_CTA'),
      ariaLabel: (row) => t('DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_EDIT_ARIA', { name: row.name }),
      action: (row: IpWhitelistResponse): void => {
        onUpdateIpWhitelist(row);
      },
      testid: 'ip-whitelist-edit',
    },
    {
      key: Kind.DELETE,
      id: Kind.DELETE,
      icon: <DeleteOutlinedIcon />,
      label: t('DASHBOARD_TEAM_DELETE_CTA'),
      color: theme.palette.error.main,
      ariaLabel: (row) => t('DASHBOARD_RAILZ_CONNECT_IP_WHITELIST_DELETE_ARIA', { name: row.name }),
      action: (row: IpWhitelistResponse): void => {
        setIp(row);
        setOpenAlert(true);
      },
      testid: 'ip-whitelist-delete',
    },
  ];

  const columns = [
    {
      key: 'ip',
      name: t('DASHBOARD_RAILZ_CONNECT_IP'),
    },
    {
      key: 'name',
      name: t('DASHBOARD_RAILZ_CONNECT_IP_LABEL'),
    },
    {
      key: 'cta',
      name: t('DASHBOARD_RAILZ_CONNECT_IP_CTA'),
      render: (row: IpWhitelistResponse): React.ReactNode => {
        return (
          <>
            <IconButton
              aria-owns={anchorEl?.[row.ip] ? `${row.ip}-overflow-menu` : undefined}
              aria-haspopup="true"
              onClick={(event): void => setAnchorEl({ ...anchorEl, [row.ip]: event.currentTarget })}
              data-testid={`${row.ip}-overflow-menu-button`}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`${row.ip}-overflow-menu`}
              anchorEl={anchorEl?.[row.ip]}
              open={Boolean(anchorEl?.[row.ip])}
              onClose={(): void => setAnchorEl({ ...anchorEl, [row.ip]: null })}
              data-testid={`${row.ip}-overflow-menu`}
            >
              {overflowOptions.map(({ key, action, color, icon, label, testid }) => (
                <MenuItem
                  key={key}
                  data-testid={testid}
                  aria-label={label}
                  onClick={(): void => {
                    setAnchorEl({ ...anchorEl, [row.ip]: null });
                    action(row);
                  }}
                  style={{ color: color }}
                >
                  {icon}
                  <span style={{ marginLeft: '12px' }}>{label}</span>
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Header
        drawerMenu
        leftComponent={null}
        rightComponent={null}
        title={t('DASHBOARD_NAV_RAILZ_CONNECT_MANAGE_IP')}
        testId={testid}
      />
      <View className={classes.view}>
        <div className={classes.viewHeader}>
          {ips?.length < 1 && !loading && (
            <Alert
              severity="warning"
              classes={calloutClasses}
              iconMapping={{ warning: <WarningRoundedIcon /> }}
            >
              <AlertTitle>
                <Typography variant="h2">
                  {t('DASHBOARD_RAILZ_CONNECT_IP_NO_RESULTS_ALERT')}
                </Typography>
              </AlertTitle>
            </Alert>
          )}
          <PageDescription title="DASHBOARD_IP_WHITELIST_TITLE">
            <Trans
              i18nKey="DASHBOARD_IP_WHITELIST_DESCRIPTION"
              components={{
                a: (
                  <a
                    href="https://docs.railz.ai/docs/railz-connect-configure-and-setup#ip-whitelisting-optional"
                    onClick={(e): void =>
                      openNewTab(
                        e,
                        'https://docs.railz.ai/docs/railz-connect-configure-and-setup#ip-whitelisting-optional',
                      )
                    }
                    rel="noopener noreferrer"
                    className={classesBrand.link}
                  >
                    {t('DASHBOARD_LEARN_MORE_CTA')}
                  </a>
                ),
              }}
            />
          </PageDescription>
          <RailzButton
            size="large"
            label={t('DASHBOARD_IP_WHITELIST_ADD_IP_ADDRESS')}
            onClick={onAddClick}
            className={classes.primaryCta}
            data-testid="add-ip-address"
          >
            <span slot="prefix">
              <AddIcon />
            </span>
          </RailzButton>
        </div>

        <ScrollView classNames={classes.viewContent}>
          <BasicTable
            stickyHeader
            columns={columns}
            rows={ips}
            isLoading={loading}
            isPaperComponent={false}
            noData={t('DASHBOARD_RAILZ_CONNECT_IP_NO_RESULTS')}
          />
        </ScrollView>
      </View>

      <AlertDialog
        isOpen={isShowForm}
        title={t(
          isEmpty(selectedIp)
            ? 'DASHBOARD_IP_WHITELIST_ADD_IP_ADDRESS'
            : 'DASHBOARD_IP_WHITELIST_EDIT_IP_ADDRESS',
        )}
        onClose={(): void => {
          setShowForm(false);
        }}
        showCloseButton
      >
        <Form
          ip={selectedIp}
          errorsMessage={errors}
          ipWhitelist={ips}
          onSubmit={(newIp): void => onEditOrAddIpWhitelist(selectedIp, newIp)}
          options={eventDropdownValues}
          disabled={Boolean(selectedIp)}
          onCancel={(): void => {
            setShowForm(false);
          }}
        />
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenAlert}
        message={t('DASHBOARD_RAILZ_CONNECT_IP_DELETE_MODAL_DESCRIPTION')}
        cancel={{
          label: t('DASHBOARD_RAILZ_CONNECT_IP_DELETE_MODAL_CANCEL'),
          onClick: () => setOpenAlert(false),
          type: 'gray',
        }}
        title={t('DASHBOARD_RAILZ_CONNECT_IP_DELETE_MODAL_TITLE')}
        confirm={{
          label: t('DASHBOARD_RAILZ_CONNECT_IP_DELETE_MODAL_CONFIRM'),
          onClick: onConfirmDelete,
          type: 'error',
        }}
        onClose={(): void => setOpenAlert(false)}
      />
    </>
  );
};
export default IpWhitelist;
