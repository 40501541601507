/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import ProgressBarPreview from '../progress-bar-preview';

import ButtonPreview from '../button-preview';

import useStyle from './style';

import RoundLogo from 'assets/images/RoundLogo.svg';

interface Props {
  brandColor: string;
  accentColor: string;
  overviewEnabled?: boolean;
  summaryEnabled?: boolean;
  helpLinkEnabled?: boolean;
  helpLinkBody?: string;
  termsAndConditionsEnabled?: boolean;
  termsAndConditionsBody?: string;
  expandedContentSection?: string;
}

const IntegrationsContent: React.FC<Props> = ({
  brandColor,
  accentColor,
  overviewEnabled,
  summaryEnabled,
  helpLinkEnabled,
  helpLinkBody,
  termsAndConditionsEnabled,
  termsAndConditionsBody,
  expandedContentSection,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const ServiceCardPreview = ({ selected }): any => (
    <div className={classes.serviceCard} style={selected ? { borderColor: `#${accentColor}` } : {}}>
      {selected}
      <img src={RoundLogo} alt={t('DASHBOARD_BRAND_SERVICE_CARD')} />
      <Typography>{t('DASHBOARD_BRAND_SERVICE_CARD')}</Typography>
    </div>
  );

  return (
    <div className={classes.visible} data-testid="onboard-preview">
      <ProgressBarPreview
        summaryEnabled={summaryEnabled}
        overviewEnabled={overviewEnabled}
        accentColor={accentColor}
      />

      <Typography className={classes.heading} variant="h4">
        Accounting Services
      </Typography>

      <span style={{ color: `#${accentColor}` }} className={classes.accentLinkText}>
        Accent colour example
      </span>

      <ul className={classes.cardList}>
        <li>
          <ServiceCardPreview selected={true} />
        </li>
      </ul>

      <ButtonPreview brandColor={brandColor} />

      {helpLinkEnabled && expandedContentSection === 'help-link' ? (
        <span className={classes.helpLink}>
          {helpLinkBody !== undefined
            ? helpLinkBody.substring(0, 50)
            : t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_DIPLAY')}
        </span>
      ) : null}
      {termsAndConditionsEnabled && expandedContentSection === 'terms-and-conditions' ? (
        <span className={classes.helpLink}>
          {termsAndConditionsBody !== undefined
            ? termsAndConditionsBody.substring(0, 50)
            : t('DASHBOARD_SIGNUP_PRIVACY_LINK')}
        </span>
      ) : null}
    </div>
  );
};

export default IntegrationsContent;
