import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      width: '100%',
      flexWrap: 'wrap',
      height: 72,
    },
    checkboxContainer: {
      marginRight: theme.spacing() * 2,
      fontWeight: +theme.typography.fontWeightMedium,
    },
    accordionRoot: {
      padding: 0,
      '& .MuiAccordionDetails-root': {
        padding: `0px ${theme.spacing(3)}px ${theme.spacing(5)}px`,
      },
    },
    accordion: {
      padding: 0,
      margin: 0,
      marginBottom: 10,
      '&:has([data-is-configurable="false"]) .MuiAccordionSummary-root': {
        cursor: 'default',
      },

      '& .MuiAccordionSummary-root': {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1),
      },
    },
    serviceAccordionSummary: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      minHeight: 72,
      maxHeight: 72,
      '& .serviceNameWrapper': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },

      '& .serviceNameWrapper .serviceName': {
        fontSize: '1rem',
      },

      '& .serviceNameWrapper .serviceConnectionCount': {
        fontSize: '0.75rem',
        marginLeft: theme.spacing(1),
      },

      '& p.MuiTypography-root.MuiTypography-body1': {
        // toggle button text
        fontWeight: +theme.typography.fontWeightMedium,
      },
      ['@media screen and (max-width: 768px)']: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        maxHeight: 'revert',
        '& .serviceNameWrapper': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        '& .serviceNameWrapper .serviceName': {
          fontSize: '0.875rem',
        },
        '& .serviceNameWrapper .serviceConnectionCount': {
          display: 'block',
          marginLeft: 0,
        },
      },
    },
    accordionSummaryConfigureText: {
      textDecoration: 'none',
      minWidth: '70px',
      fontSize: '0.875rem',
      fontWeight: +theme.typography.fontWeightMedium,
      '&[data-is-configurable="false"]': {
        minWidth: '98px', // 120px + 28px of the collapsable arrow
      },
    },
    accordionContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      gap: theme.spacing(3),
      '& .MuiTableContainer-root': {
        minHeight: 'revert',
        scrollPaddingTop: 'revert',
      },
    },

    topRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      paddingTop: `calc(${theme.spacing(3)}px - 1px)`,
      '& .titleAndDescription': {
        display: 'flex',
        flexDirection: 'column',
        ['@media screen and (min-width: 768px)']: {
          maxWidth: 'calc(100% - 140px)',
        },
      },
      ['@media screen and (max-width: 768px)']: {
        flexDirection: 'column-reverse',
        '& railz-button, & railz-button button.sc-railz-button': {
          width: '100%',
          maxWidth: '100%',
        },
        '& railz-button': {
          marginBottom: theme.spacing(3),
        },
      },
    },

    tableTitle: {
      fontSize: '1rem',
      fontWeight: +theme.typography.fontWeightBold,
      marginBottom: theme.spacing(1),
    },
    tableSubtitle: {
      fontSize: '0.875rem',
      fontWeight: +theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(0),
    },
    table: {
      padding: 0,
      width: '100%',
      height: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      '& .MuiTableCell-sizeSmall:not(:last-child)': {
        textAlign: 'left',
      },
      '& .MuiTableCell-sizeSmall:first-child': {
        width: theme.spacing(9),
        paddingLeft: 0,
      },
      '& .MuiTableCell-sizeSmall:nth-child(2)': {
        minWidth: theme.spacing(8),
      },
      '& .MuiTableCell-sizeSmall:last-child': {
        maxWidth: theme.spacing(6),
        paddingRight: 0,
      },
    },
    tableCell: {
      maxWidth: 200,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '0.875rem',
    },

    link: {
      fontSize: '0.875rem',
      fontWeight: 600,
      textDecoration: 'underline',
      marginRight: theme.spacing(3),
    },
    chip: {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[800],
      fontSize: '0.875rem',
    },
    container: {
      marginBottom: theme.spacing(3),
      '& a': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
      },
    },
    scrollView: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(10)}px`,
    },
    menuItem: {
      fontSize: '0.875rem',
      '&>svg.MuiSvgIcon-root': {
        fontSize: '1rem',
        marginRight: theme.spacing(1),
      },
    },
  }),
);
