/**
 * Webhook payload
 */
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ApiResponse, Webhook, WebhookHistoryResponse } from '../../../../types';
import { ErrorResponse } from '../../../../types/interface/error.interface';

import { WebhookDeleteResponse } from './webhook.service';

export type ReducerBuilder<T> = (builder: ActionReducerMapBuilder<T>) => ActionReducerMapBuilder<T>;

export type WebhookActionCreator<T = undefined> = (payload?: T) => WebhookAction<T>;

export interface WebhookAction<T> {
  payload?: T;
  type: string;
}

export interface WebhookApiResult {
  success: boolean;
  message: string[];
  data: Required<Webhook> | WebhookDeleteResponse;
}

/**
 * Webhook store state
 */
export interface WebhookState {
  loading: boolean;
  error: ErrorResponse;
  response: ApiResponse;
  webhooks: Webhook[];
  webhookHistoryLoading: boolean;
  webhookHistory: WebhookHistoryResponse;
}

/**
 * Name of the webhook Store
 */
export const WEBHOOK_STORE_NAME = 'webhook';

/**
 * webhook initial state
 */
export const WEBHOOK_INITIAL_STATE: WebhookState = {
  webhooks: [],
  response: null,
  loading: false,
  error: null,
  webhookHistoryLoading: false,
  webhookHistory: {
    meta: {},
    webhookHistory: [],
  },
};
