import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import { RailzButton } from '@railzai/railz-uikit-react';

import SyntaxHighlighter from 'react-syntax-highlighter';

import { foundation } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import useStyle from './style';

import { setClipboard, showSnackbar } from 'helpers/common.helper';

export type CodeBlock = {
  id: string;
  code: string;
  title: string;
  numberedLines?: boolean;
  language: string;
};

export const CodeBlock = ({
  id,
  code,
  title,
  numberedLines = false,
  language = 'plaintext',
}: CodeBlock): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Box className={classes.codeBlock} id={`code-block-${id}`} data-testid={`code-block-${id}`}>
      <div className={classes.codeBlockHeader}>
        <Typography variant="button">{t(title)}</Typography>
        <RailzButton
          aria-label={t('DASHBOARD_CODE_BLOCK_COPY_CTA')}
          size="large"
          type="text primary"
          onClick={(): void => {
            setClipboard(code);
            showSnackbar({ message: t('DASHBOARD_CODE_BLOCK_COPIED_FEEDBACK'), type: 'success' });
          }}
          data-testid={id + '-submit'}
          label={t('DASHBOARD_CODE_BLOCK_COPY_CTA')}
        />
      </div>
      <SyntaxHighlighter
        language={language}
        className={classes.code}
        style={foundation}
        wrapLines
        wrapLongLines
        showLineNumbers={numberedLines}
        lineNumberStyle={{ minWidth: 'unset', padding: 'unset' }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
};
