import { createStyles, makeStyles, Theme } from '@material-ui/core';

import {
  ACTIVE_TEXT_COLOR,
  DEFAULT_ERROR_COLOR,
  DEFAULT_SECONDARY_COLOR,
} from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    rowText: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
      color: theme.palette.grey[800],
      fontSize: `${theme.spacing(1.75)}px`,
    },

    rowTextWrap: {
      textWrap: 'wrap',
    },

    view: {
      padding: theme.spacing(3),
    },

    mainGrid: {
      display: 'flex',
      overflow: 'hidden',
    },

    leftSideContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: `${theme.spacing(2)}px`,
      width: '100%',
      '&.selected': {
        width: '50%',
        ['@media screen and (max-width: 768px)']: {
          display: 'none',
        },
      },
    },

    rightSideContainer: {
      width: '50%',
      overflowY: 'auto',
      overflowX: 'hidden',
      boxShadow: `0px -1px ${theme.spacing(1.5)}px 0px rgba(0, 0, 0, 0.05)`,
      borderRadius: `${theme.spacing(1.25)}px`,
      padding: `${theme.spacing(2)}px`,
      marginTop: `${theme.spacing(1)}px`,
      ['@media screen and (max-width: 768px)']: {
        width: '100%',
        padding: `${theme.spacing(2)}px 0px 0px 0px`,
        marginTop: '0px',
        boxShadow: 'none',
      },
    },

    scrollContainer: {},
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      '& .Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
      paddingBottom: '0px',
    },

    statusWrapper: {
      marginTop: '-4px',
    },

    tableRoot: {
      scrollBehavior: 'smooth',
      scrollPaddingTop: 'calc(80px + 20vh)',
    },

    tableHead: {
      color: '#4F4F4F',
    },

    statusColumn: {
      width: `${theme.spacing(5)}px`,
    },

    eventColumn: {
      width: `${theme.spacing(2.25)}%`,
    },

    connUuidColumn: {
      width: '25%',
    },

    serviceNameColumn: {
      width: `${theme.spacing(1.5)}%`,
    },

    timestampColumn: {
      width: `${theme.spacing(1.25)}%`,
    },

    chip: {
      fontSize: '0.75rem',
      fontWeight: +theme.typography.fontWeightMedium,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
    tableChipActive: {
      marginLeft: theme.spacing(-2),
    },
    tableChipInactive: {
      marginLeft: theme.spacing(-0.25),
    },
    chipInactive: {
      color: DEFAULT_ERROR_COLOR['main'],
      backgroundColor: '#FCECE7',
    },

    chipActive: {
      color: ACTIVE_TEXT_COLOR,
      backgroundColor: DEFAULT_SECONDARY_COLOR['main'],
    },

    emptyStateContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    noMarginBottom: {
      marginBottom: 0,
    },

    smallPaddingLeft: {
      paddingLeft: theme.spacing(1),
    },

    webhookHistoryDetail: {
      height: '100%',
    },

    detailHeader: {
      display: 'flex',
      alignItems: 'center',
      ['@media screen and (max-width: 768px)']: {
        flexWrap: 'wrap',
      },
    },

    headerText: {
      fontWeight: 600,
    },

    longText: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },

    vDivider: {
      marginTop: `${theme.spacing(1)}px`,
      marginBottom: `${theme.spacing(1)}px`,
      background: '#757575',
    },

    hDivider: {
      marginTop: `${theme.spacing(2)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },

    dataField: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
      ['@media screen and (max-width: 768px)']: {
        display: 'block',
      },
    },

    dataKey: {
      color: theme.palette.grey[600],
      fontWeight: 600,
      fontSize: `${theme.spacing(1.5)}px`,
      letterSpacing: '0.72px',
    },

    dataValue: {
      color: '#424242',
      fontWeight: Number(theme.typography.fontWeightRegular),
      fontSize: '14px',
      wordBreak: 'break-all',
      textAlign: 'end',
      ['@media screen and (max-width: 768px)']: {
        textAlign: 'start',
        marginTop: `${theme.spacing(1)}px`,
        marginBottom: `${theme.spacing(3)}px`,
      },
    },

    codeWrapper: {
      paddingLeft: theme.spacing(2),
      '& h3': {
        fontSize: `${theme.spacing(2.25)}px`,
        fontWeight: theme.typography.fontWeightRegular,
      },
      '& pre': {
        maxHeight: '300px',
        height: 'auto',
        '& code': {
          overflow: 'auto',
          fontFeatureSettings: "'clig' off, 'liga' off",
        },
      },
    },

    copiedText: {
      fontSize: '0.75rem',
      marginTop: theme.spacing(2),
    },

    pageDesc: {
      paddingBottom: theme.spacing(2),
      '&.selected': {
        ['@media screen and (max-width: 768px)']: {
          display: 'none',
        },
      },
    },

    filterContainer: {
      '&.selected': {
        ['@media screen and (max-width: 768px)']: {
          display: 'none',
        },
      },
    },

    wrapperFilter: {
      marginBottom: theme.spacing(2),
    },

    iconButton: {
      padding: 4,
    },

    ctaButton: {
      '& button': {
        width: '100% !important',
        minWidth: '60px !important',
      },
    },
  }),
);
