/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import { useDispatch } from 'react-redux';

import useStyle from './style';

import {
  EventKind,
  PAGINATION_LIMIT,
  WebhookDateRange,
  WebhookLogDateRange,
  WebhookLogState,
} from 'types';
import { generateDropdown } from 'helpers/common.helper';
import { fetchWebhookHistoryApi } from 'store/features/developer/webhook/webhook.action';
import { getDateRange } from 'helpers/format.helper';

interface Props {
  handleSelected?: (value) => void;
  componentClasses?: string;
}

export default function WebhookLogsFilters({
  handleSelected,
  componentClasses,
}: Props): JSX.Element {
  const classes = useStyle();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [connectionUuid, setConnectionUuid] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(WebhookLogState.ALL);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(WebhookLogDateRange.LAST_SEVEN_DAYS);

  const statuses = generateDropdown(WebhookLogState);
  const eventsDropDown = generateDropdown(EventKind);
  const dateRangeDropdown = generateDropdown(WebhookLogDateRange);

  const getFormattedDateRange = (dateRange: WebhookLogDateRange): WebhookDateRange => {
    let formattedDate;

    switch (dateRange) {
      case WebhookLogDateRange.TODAY:
        formattedDate = getDateRange(0, 0, 0, true);
        break;
      case WebhookLogDateRange.LAST_HOUR:
        formattedDate = getDateRange(0, 0, 1);
        break;
      case WebhookLogDateRange.LAST_SEVEN_DAYS:
        formattedDate = getDateRange(0, 7, 0);
        break;
      case WebhookLogDateRange.LAST_THIRTY_DAYS:
        formattedDate = getDateRange(0, 30, 0);
        break;
      case WebhookLogDateRange.LAST_NINETY_DAYS:
        formattedDate = getDateRange(0, 90, 0);
        break;
      case WebhookLogDateRange.LAST_SIX_MONTHS:
        formattedDate = getDateRange(6, 0, 0);
        break;
      default:
        break;
    }
    return formattedDate;
  };

  const handleApplyFilter = (): void => {
    const payload = {
      limit: PAGINATION_LIMIT,
      status: selectedStatus === WebhookLogState.ALL ? null : selectedStatus,
      webhookEvent: selectedEvent || null,
      connectionUuid: connectionUuid || null,
      startDate: null,
      endDate: null,
    };
    if (selectedDateRange) {
      const { startDate, endDate } = getFormattedDateRange(selectedDateRange);
      payload.startDate = startDate;
      payload.endDate = endDate;
    }
    handleSelected(payload);
    dispatch(fetchWebhookHistoryApi(payload));
  };

  const handleClearFilter = (): void => {
    setConnectionUuid('');
    setSelectedStatus(WebhookLogState.ALL);
    setSelectedEvent('');
    setSelectedDateRange(WebhookLogDateRange.LAST_SEVEN_DAYS);
    const { startDate, endDate } = getFormattedDateRange(WebhookLogDateRange.LAST_SEVEN_DAYS);
    const payload = {
      limit: PAGINATION_LIMIT,
      startDate,
      endDate,
    };
    handleSelected(payload);
    dispatch(fetchWebhookHistoryApi(payload));
  };

  const onStatusChange = (event): void => {
    setSelectedStatus(event.target.value);
  };

  const onEventChange = (event): void => {
    setSelectedEvent(event.target.value);
  };

  const onDateRangeChange = (event): void => {
    setSelectedDateRange(event.target.value);
  };

  const onConnectionUuidChange = (event): void => {
    setConnectionUuid(event.target.value);
  };

  return (
    <>
      <div data-testid="webhook-logs-filter" className={`${componentClasses}`}>
        <Grid container spacing={2} className={classes.wrapperFilter} alignItems="center">
          <Grid item lg={4} md={4} sm={12} xs={12} className={classes.statusWrapper}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <IconButton className={classes.iconButton} aria-label="search">
                    <SearchIcon style={{ color: '#424242' }} />
                  </IconButton>
                ),
              }}
              placeholder={t('DASHBOARD_ENUM_WEBHOOK_LOG_CTA_SEARCH')}
              value={connectionUuid}
              onChange={onConnectionUuidChange}
              data-testid="search-input"
            />
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12} className={classes.statusWrapper}>
            <TextField
              fullWidth
              label={t('DASHBOARD_ENUM_WEBHOOK_LOG_CTA_DATE')}
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              select
              value={selectedDateRange || ''}
              onChange={onDateRangeChange}
              data-testid="status-input"
            >
              {dateRangeDropdown.map((dateRange, index) => (
                <MenuItem key={index} value={dateRange.value}>
                  {dateRange?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={1} md={1} sm={12} xs={12} className={classes.statusWrapper}>
            <TextField
              fullWidth
              label={t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_STATUS')}
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              select
              value={selectedStatus || ''}
              onChange={onStatusChange}
              data-testid="status-input"
            >
              {statuses.map((status, index) => (
                <MenuItem key={index} value={status.value}>
                  {status?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className={classes.statusWrapper}>
            <TextField
              fullWidth
              label={t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_EVENT')}
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              select
              value={selectedEvent || ''}
              onChange={onEventChange}
              data-testid="status-input"
            >
              {eventsDropDown.map((event, index) => (
                <MenuItem key={index} value={event.value}>
                  {event?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={1} md={1} sm={12} xs={12}>
            <RailzButton
              size="medium"
              label={t('DASHBOARD_ENUM_WEBHOOK_LOG_CTA_APPLY')}
              className={classes.ctaButton}
              onClick={handleApplyFilter}
              data-testid="webhook-filter-apply"
            />
          </Grid>
          <Grid item lg={1} md={1} sm={12} xs={12}>
            <RailzButton
              size="medium"
              type="reset"
              label={t('DASHBOARD_ENUM_WEBHOOK_LOG_CTA_RESET')}
              className={classes.ctaButton}
              onClick={handleClearFilter}
              data-testid="webhook-filter-clear"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
