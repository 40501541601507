import { ApiResponse, ApiKey, ApiKeyGenerateResponse } from '../../../../types';
import { ErrorResponse } from '../../../../types/interface/error.interface';

/**
 * API Key payload
 */
export interface ApiKeyAction {
  payload: Partial<ApiKey>;
  type: string;
}

/**
 * API Key store state
 */
export interface ApiKeyState {
  loading: boolean;
  error: ErrorResponse;
  response: ApiResponse & {
    name?: string;
    apiKey?: ApiKeyGenerateResponse;
  };
  apiKeys: ApiKey[];
}

/**
 * Name of the API key Store
 */
export const API_KEY_STORE_NAME = 'apiKey';

/**
 * API key initial state
 */
export const API_KEY_INITIAL_STATE: ApiKeyState = {
  apiKeys: [],
  response: null,
  loading: false,
  error: null,
};
