import { IconButton, Theme } from '@material-ui/core';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { EventService } from '../../services';

const styles = (theme: Theme): any => ({
  root: {
    marginLeft: -12,
    marginRight: 16,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

interface IProps {
  onClick?: () => void;
  testId: string;
}

const DrawerMenuButton: React.FC<{ classes: any } & IProps> = (props) => {
  const defaultGoBack = (): void => EventService.emit(EventService.EVENT.TOGGLE_DRAWER);
  const { t } = useTranslation();
  const onClick: any = props.onClick || defaultGoBack;

  return (
    <IconButton
      color="inherit"
      onClick={onClick}
      className={props.classes.root}
      aria-label={t('DASHBOARD_NAV_MENU_OPEN')}
      data-testid={props.testId}
    >
      <MenuOutlinedIcon />
    </IconButton>
  );
};

export default withStyles(styles)(DrawerMenuButton);
