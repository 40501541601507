import React from 'react';

interface Props {
  color?: string;
}

const ChevronCircleIcon: React.FC<Props> = ({ color = '#38C076' }): React.ReactElement => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill={color} />
      <path
        d="M7 11L10 8L7 5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronCircleIcon;
