import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { SyntheticEvent } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { INotification } from '../../types';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';
import RailzButton from 'components/buttons';

const useStyles = makeStyles((theme: Theme) => ({
  snackbarContainer: {
    position: 'absolute',
    '& .MuiSnackbarContent-message': {
      padding: 0,
    },
  },
  success: {
    backgroundColor: 'rgb(227, 252, 239)',
    borderColor: 'rgb(54, 179, 126)',
  },
  error: {
    backgroundColor: 'rgb(255, 235, 230)',
    borderColor: 'rgb(255, 86, 48)',
  },
  info: {
    backgroundColor: '#EAF4FC',
    borderColor: '#EAF4FC',
  },
  warning: {
    backgroundColor: 'rgb(255, 250, 230)',
    borderColor: 'rgb(255, 171, 0)',
  },
  successMessage: {
    color: 'rgb(0, 102, 68)',
    display: 'flex',
    whiteSpace: 'pre-wrap',
    alignItems: 'center',
  },
  errorMessage: {
    color: 'rgb(191, 38, 0)',
    display: 'flex',
    whiteSpace: 'pre-wrap',
    alignItems: 'center',
  },
  warningMessage: {
    color: '#603E11',
    display: 'flex',
    whiteSpace: 'pre-wrap',
    alignItems: 'center',
  },
  infoMessage: {
    color: '#1C3B5E',
  },
  rawMessage: {
    color: theme.palette.common.black,
  },
  successDismiss: {
    color: ACTIVE_TEXT_COLOR,
    fontSize: 20,
  },
  errorDismiss: {
    color: 'rgb(191, 38, 0)',
    fontSize: 20,
  },
  warningDismiss: {
    color: '#603E11',
    fontSize: 20,
  },
  infoDismiss: {
    color: '#1C3B5E',
    fontSize: 20,
  },
  rawDismiss: {
    color: '#1C3B5E',
    fontSize: 20,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error.main,
    padding: `${theme.spacing(1)}px 0px`,
  },
  notificationActionButton: {
    margin: `0px ${theme.spacing(1)}px`,
  },
  successIcon: { paddingRight: '1rem', color: theme.palette.primary.main },
  infoIcon: { paddingRight: '1rem', color: '#599EEE' },
  warningIcon: { paddingRight: '1rem', color: '#F2A74C' },
}));

interface IProps {
  notification: INotification;
  open: boolean;
  onClose: () => void;
}

export default function CustomizedSnackbars(props: IProps): React.ReactElement {
  const classes = useStyles(props);
  const { open, notification } = props;

  const handleClose = (event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }

    props.onClose();
  };

  const handleActionClick = (): void => {
    props.onClose();
    notification.action.onClick && notification.action.onClick();
  };

  const classMessage = classes[`${notification.type}Message`];
  const classDissmiss = classes[`${notification.type}Dismiss`];

  const message =
    notification.type === 'raw' ? (
      notification.message
    ) : (
      <div className={classes.iconContainer}>
        {notification.type === 'error' && <ErrorIcon style={{ paddingRight: '1rem' }} />}
        {notification.type === 'success' && <CheckCircleIcon className={classes.successIcon} />}
        {notification.type === 'info' && <InfoIcon className={classes.infoIcon} />}
        {notification.type === 'warning' && <WarningIcon className={classes.warningIcon} />}

        <span className={classMessage}>{notification.message}</span>
      </div>
    );

  return (
    <Snackbar
      className={classes.snackbarContainer}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={notification.isPinned ? null : 3000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[notification.type]}
        style={notification.styles || {}}
        message={message}
        action={[
          Boolean(notification.action) && (
            <RailzButton
              color="inherit"
              key="undo"
              type="small"
              variant="outlined"
              onClick={handleActionClick}
              className={classes.notificationActionButton}
              buttonText={notification.action.title}
            />
          ),
          ...(notification.hideCloseButton
            ? []
            : [
                <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon className={classDissmiss} />
                </IconButton>,
              ]),
        ]}
      />
    </Snackbar>
  );
}
