import { createReducer } from '@reduxjs/toolkit';

import {
  GENERATE_API_KEY,
  GENERATE_API_KEY_SUCCESS,
  GENERATE_API_KEY_FAILURE,
  FETCH_API_KEYS,
  FETCH_API_KEYS_SUCCESS,
  FETCH_API_KEYS_FAILURE,
  DELETE_API_KEY,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_FAILURE,
  RESET_API_KEY_ERROR,
  RESET_API_KEY_RESPONSE,
  RESET_STORAGE_API_KEY,
} from './api-key.action';
import { API_KEY_STORE_NAME, API_KEY_INITIAL_STATE } from './api-key.state';

/**
 * API Key Reducers
 */
export const apiKeyReducer = createReducer(API_KEY_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_API_KEY, () => API_KEY_INITIAL_STATE)
    .addCase(RESET_API_KEY_ERROR, (state) => ({ ...state, error: null }))
    .addCase(RESET_API_KEY_RESPONSE, (state) => ({ ...state, response: null }))
    .addCase(FETCH_API_KEYS, (state) => ({
      ...state,
      apiKeys: [],
      error: null,
      loading: true,
    }))
    .addCase(FETCH_API_KEYS_SUCCESS, (state, action: any) => {
      return {
        ...state,
        apiKeys: action.payload,
        loading: false,
      };
    })
    .addCase(FETCH_API_KEYS_FAILURE, (state, action: any) => ({
      ...state,
      apiKeys: [],
      error: action.payload,
      loading: false,
    }))
    .addCase(GENERATE_API_KEY, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(GENERATE_API_KEY_SUCCESS, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(GENERATE_API_KEY_FAILURE, (state, action: any) => ({
      ...state,
      response: null,
      error: action.payload,
      loading: false,
    }))
    .addCase(DELETE_API_KEY, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_API_KEY_SUCCESS, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(DELETE_API_KEY_FAILURE, (state, action: any) => ({
      ...state,
      response: null,
      error: action.payload,
      loading: false,
    }));
});

export const apiKeyReducerConfig = {
  [API_KEY_STORE_NAME]: apiKeyReducer,
};
