import { combineReducers } from '@reduxjs/toolkit';

import { ACCOUNT_STORE_NAME } from './account.state';

import { customerReducer } from './customer/customer.reducer';
import { profileReducer } from './profile/profile.reducer';
import { teamReducer } from './team/team.reducer';

export const accountReducer = combineReducers({
  customer: customerReducer,
  profile: profileReducer,
  team: teamReducer,
});

export const accountReducerConfig = {
  [ACCOUNT_STORE_NAME]: accountReducer,
};
