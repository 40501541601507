import React from 'react';

import { useHistory } from 'react-router-dom';
import { TableCell, TableCellProps } from '@material-ui/core';

import useStyles from './style';

import { openNewTab } from 'helpers/open-new-tab';

interface LinkTableCellProps extends TableCellProps {
  link: { pathname: string; search?: string };
  linkLabel?: string | React.ReactNode;
  key?: React.Key;
}

const LinkTableCell: React.FC<LinkTableCellProps> = (props) => {
  const { link, linkLabel, className, children, ...cellProps } = props;
  const history = useHistory();
  const classes = useStyles();
  const cellRef = React.useRef<HTMLTableCellElement>(null);

  function passthroughEvent(event: React.MouseEvent | React.KeyboardEvent): void {
    const hasActionableButtons = cellRef.current?.querySelector('button:not(:disabled)');
    if (hasActionableButtons) return;
    if ((event as React.MouseEvent).button === 1 || event.ctrlKey || event.metaKey) {
      openNewTab(event, link.pathname + (link.search || ''));
    } else {
      history.push(link);
    }
  }

  return (
    <TableCell
      {...cellProps}
      role="button"
      tabIndex={0}
      className={[classes.linkCell, className || ''].join(' ')}
      onClick={passthroughEvent}
      onAuxClick={passthroughEvent}
      onKeyPress={passthroughEvent}
      ref={cellRef}
    >
      {linkLabel && (
        <div className={classes.rowLink}>
          <span className={classes.linkLabel}>{linkLabel}</span>
        </div>
      )}
      {children}
    </TableCell>
  );
};

interface RailzTableCellProps extends TableCellProps {
  link?: { pathname: string; search?: string } | false;
  key?: React.Key;
  linkLabel?: string | React.ReactNode;
}

export function RailzTableCell(props: RailzTableCellProps): React.ReactElement {
  if (props && 'link' in props && props.link) {
    return <LinkTableCell {...(props as LinkTableCellProps)} />;
  }
  return <TableCell {...props} />;
}
