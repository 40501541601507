import { Webhook, WebhookHistoryParams, WebhookHistoryResponse } from '../../../../types';

import { WebhookActionCreator } from './webhook.state';

import { ErrorResponse } from 'types/interface/error.interface';

export const CREATE_WEBHOOK_API = 'CREATE_WEBHOOK_API';
export const UPDATE_WEBHOOK_API = 'UPDATE_WEBHOOK_API';
export const FETCH_WEBHOOK_API = 'FETCH_WEBHOOK_API';
export const DELETE_WEBHOOK_API = 'DELETE_WEBHOOK_API';
export const FETCH_WEBHOOK_HISTORY_API = 'FETCH_WEBHOOK_HISTORY_API';

export const RESET_WEBHOOK_ERROR = 'RESET_WEBHOOK_ERROR';
export const RESET_WEBHOOK_API_RESPONSE = 'RESET_WEBHOOK_API_RESPONSE';

export const CREATE_WEBHOOK_SUCCESS_API = 'CREATE_WEBHOOK_SUCCESS_API';
export const CREATE_WEBHOOK_FAILURE_API = 'CREATE_WEBHOOK_FAILURE_API';

export const UPDATE_WEBHOOK_SUCCESS_API = 'UPDATE_WEBHOOK_SUCCESS_API';
export const UPDATE_WEBHOOK_FAILURE_API = 'UPDATE_WEBHOOK_FAILURE_API';

export const FETCH_WEBHOOK_SUCCESS_API = 'FETCH_WEBHOOK_SUCCESS_API';
export const FETCH_WEBHOOK_FAILURE_API = 'FETCH_WEBHOOK_FAILURE_API';

export const DELETE_WEBHOOK_SUCCESS_API = 'DELETE_WEBHOOK_SUCCESS_API';
export const DELETE_WEBHOOK_FAILURE_API = 'DELETE_WEBHOOK_FAILURE_API';

export const FETCH_WEBHOOK_HISTORY_SUCCESS = 'FETCH_WEBHOOK_HISTORY_SUCCESS';
export const FETCH_WEBHOOK_HISTORY_FAILURE = 'FETCH_WEBHOOK_HISTORY_FAILURE';

export const RESET_STORAGE_WEBHOOK = 'RESET_STORAGE_WEBHOOK';

export const resetWebhookStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_WEBHOOK,
  };
};

export const resetWebhookError: WebhookActionCreator = () => ({
  type: RESET_WEBHOOK_ERROR,
});

export const resetWebhookApiResponse: WebhookActionCreator = () => ({
  type: RESET_WEBHOOK_API_RESPONSE,
});

export const fetchWebhookApi: WebhookActionCreator = () => ({
  type: FETCH_WEBHOOK_API,
});

export const fetchWebhookSuccessApi: WebhookActionCreator<Required<Webhook>[]> = (payload) => ({
  type: FETCH_WEBHOOK_SUCCESS_API,
  payload,
});

export const fetchWebhookFailureApi: WebhookActionCreator<ErrorResponse> = (payload) => ({
  type: FETCH_WEBHOOK_FAILURE_API,
  payload,
});

export const createWebhookApi: WebhookActionCreator<Webhook> = (payload) => ({
  type: CREATE_WEBHOOK_API,
  payload,
});

export const createWebhookSuccessApi: WebhookActionCreator<Required<Webhook>> = (payload) => ({
  type: CREATE_WEBHOOK_SUCCESS_API,
  payload,
});

export const createWebhookFailureApi: WebhookActionCreator<ErrorResponse> = (payload) => ({
  type: CREATE_WEBHOOK_FAILURE_API,
  payload,
});

export const updateWebhookApi: WebhookActionCreator<Webhook> = (payload) => ({
  type: UPDATE_WEBHOOK_API,
  payload,
});

export const updateWebhookSuccessApi: WebhookActionCreator<Required<Webhook>> = (payload) => ({
  type: UPDATE_WEBHOOK_SUCCESS_API,
  payload,
});

export const updateWebhookFailureApi: WebhookActionCreator<ErrorResponse> = (payload) => ({
  type: UPDATE_WEBHOOK_FAILURE_API,
  payload,
});

export const deleteWebhookApi: WebhookActionCreator<string> = (payload) => ({
  type: DELETE_WEBHOOK_API,
  payload,
});

export const deleteWebhookSuccessApi: WebhookActionCreator<string> = (payload) => ({
  type: DELETE_WEBHOOK_SUCCESS_API,
  payload,
});

export const deleteWebhookFailureApi: WebhookActionCreator<ErrorResponse> = (payload) => ({
  type: DELETE_WEBHOOK_FAILURE_API,
  payload,
});

export const fetchWebhookHistoryApi = (
  payload?: WebhookHistoryParams | string,
): { type: string; payload: WebhookHistoryParams | string } => {
  return {
    type: FETCH_WEBHOOK_HISTORY_API,
    payload,
  };
};

export const fetchWebhookHistorySuccessApi = (
  payload: WebhookHistoryResponse,
): { payload: WebhookHistoryResponse; type: string } => ({
  type: FETCH_WEBHOOK_HISTORY_SUCCESS,
  payload,
});

export const fetchWebhookHistoryFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => ({
  type: FETCH_WEBHOOK_HISTORY_FAILURE,
  payload,
});
