import React from 'react';

import { Chip, Tooltip, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import HelpIcon from '@material-ui/icons/Help';

import useStyles from './styles';

import { isBeta } from './helper';

import { View } from 'components';

import { getLocalizedService } from 'helpers/common.helper';
import { ServiceWithIntegrations } from 'types';

interface Props {
  service: ServiceWithIntegrations;
  testid: string;
}

export const ServiceName: React.FC<Props> = ({ service, testid }): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <View flexGrow flexDirection="row">
      <Typography component={'div'} data-testid={testid} className="serviceNameWrapper">
        <span className="serviceName">{getLocalizedService(service.name)}</span>
        {service.totalConnections > 0 && (
          <span className="serviceConnectionCount">
            ({service.totalConnections}{' '}
            {service.totalConnections > 1 ? 'connections' : 'connection'})
          </span>
        )}
        {service.tooltipText && (
          <span className={classes.tooltipContainer}>
            <Tooltip
              title={t(service.tooltipText)}
              placement="right"
              arrow
              PopperProps={{ disablePortal: true }}
            >
              <HelpIcon className={classes.tooltipIcon} />
            </Tooltip>
          </span>
        )}
      </Typography>
      {isBeta(service) && (
        <Chip
          label={t('DASHBOARD_INTEGRATION_BETA')}
          color="primary"
          size="small"
          style={{ marginLeft: 20 }}
          data-testid={`chip-${testid}`}
        />
      )}
    </View>
  );
};
