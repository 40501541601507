import { makeStyles } from '@material-ui/core';

const dashboardStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
}));

export default dashboardStyles;
