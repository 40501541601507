import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    view: {
      height: '100%',
    },

    viewHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
      gap: theme.spacing(2),
    },

    primaryCta: {
      alignSelf: 'flex-end',
    },

    viewContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      minHeight: '0',
      padding: `0 ${theme.spacing(4)}px`,
      position: 'relative',

      '& table': {
        paddingBottom: `${theme.spacing(10)}px`,
      },

      '& td:has($url)': {
        width: '50px',
      },
    },
  });
});
