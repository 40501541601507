import { Role } from '../../types';
import { Business, BusinessWithQuery } from '../../pages/business/types/interfaces';

import { calculateFilter, setQuerySearch } from './financial-summary-helper';

export const getColumnHeaders = (
  isSandboxEnabled: boolean,
  role: Role,
): { key: string; name: string; showSort: boolean; role?: Role[] }[] => [
  {
    key: 'businessName',
    name: 'DASHBOARD_BUSINESS_NAME',
    showSort: true,
  },
  {
    key: 'status',
    name: 'DASHBOARD_BUSINESS_STATUS',
    showSort: true,
  },
  {
    key: 'createdAt',
    name: 'DASHBOARD_BUSINESS_CREATED',
    showSort: true,
  },
  {
    key: 'updatedAt',
    name: 'DASHBOARD_BUSINESS_UPDATED',
    showSort: true,
  },
  {
    key: 'serviceList',
    name: 'DASHBOARD_BUSINESS_SERVICES',
    showSort: false,
  },
  {
    key: 'menu',
    name: 'DASHBOARD_MANAGE_SERVICES_ACTION',
    showSort: false,
    role: [
      Role.SUPER_ADMINISTRATOR,
      Role.ADMINISTRATOR,
      isSandboxEnabled && role === Role.DEVELOPER ? Role.DEVELOPER : null,
    ],
  },
];

export const expandBusinessInfo = (businesses: Business[]): BusinessWithQuery[] =>
  businesses.map((business) => {
    const {
      filter: { businessName, connectionUuid, serviceName, reportFrequency },
    } = calculateFilter(business);
    const query = setQuerySearch(null, null, {
      businessName,
      serviceName,
      connectionUuid,
      reportFrequency,
    }).toString();

    return {
      ...business,
      query: query,
    };
  });
