/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';

import { contrastColor } from 'contrast-color';

import useStyle from './style';

import HeaderContent from './header-content';

import OnboardingContent from './onboarding-content';
import OverviewContent from './overview-content';
import IntegrationsContent from './integrations-content';
import SaveUrlPreview from './save-url';
import MetaSeoPreview from './meta-seo-preview';

interface Props {
  brandColor?: string;
  accentColor?: string;
  pageCompanyLogo?: string | File;
  pageMetaFavicon?: string | File;
  expandedSection?: string;
  helpLinkEnabled?: boolean;
  termsAndConditionsEnabled?: boolean;
  railzWatermarkEnabled?: boolean;
  overviewEnabled?: boolean;
  summaryEnabled?: boolean;
  accountingRequired?: boolean;
  bankingRequired?: boolean;
  commerceRequired?: boolean;
  saveUrlEnabled?: boolean;
  saveUrl?: string;
  isLoading?: boolean; // TODO: Maybe remove
  sitesForm?: {
    watch: Function;
  };
}

const showOverviewContent = (contentBeingEdited: string): boolean =>
  ['overview', 'help-link', 'integrations', 'terms-and-conditions'].includes(contentBeingEdited);

const DynamicPreview: React.FC<Props> = ({
  brandColor,
  accentColor,
  pageCompanyLogo,
  pageMetaFavicon,
  expandedSection,
  helpLinkEnabled,
  termsAndConditionsEnabled,
  railzWatermarkEnabled,
  overviewEnabled,
  summaryEnabled,
  accountingRequired,
  bankingRequired,
  commerceRequired,
  saveUrl,
  saveUrlEnabled,
  sitesForm: { watch },
}) => {
  const classes = useStyle();
  const [color, setColor] = useState<string>('#D8D8D8');
  const [expandedContentSection, setExpandedContentSection] = useState<string>('onboard');

  useEffect(() => {
    setColor(brandColor ? contrastColor({ bgColor: `#${brandColor}` }) : '#D8D8D8');
  }, [brandColor]);

  useEffect(() => {
    if (
      expandedSection === 'saveBusinessUrlToggle' ||
      expandedSection === 'onboard' ||
      expandedSection === 'overview' ||
      expandedSection === 'brand' ||
      expandedSection === 'seo' ||
      expandedSection === 'help-link' ||
      expandedSection === 'terms-and-conditions' ||
      expandedSection === 'integrations'
    ) {
      setExpandedContentSection(expandedSection);
    }
  }, [expandedSection]);

  const watchFields = watch([
    'onboardLandingPageTitle',
    'onboardLandingPageBody',
    'leftSideBarTitle',
    'leftSideBarBody',
    'helpLinkTitle',
    'helpLinkBody',
    'termsAndConditionsTitle',
    'termsAndConditionsBody',
    'metaTitle',
  ]);

  return (
    <div className={classes.previewContainerWrapper} data-testid="preview">
      <MetaSeoPreview
        pageMetaFavicon={pageMetaFavicon}
        metaTitle={watchFields.metaTitle}
        visible={expandedContentSection === 'seo'}
      />
      <div className={classes.previewContainer + ` expanded-${expandedContentSection}`}>
        <div className={classes.zoomContainer + ` expanded-${expandedContentSection}`}>
          <div
            className={`${classes.preview} focus-${expandedSection ? expandedSection : 'default'}`}
          >
            <div
              className={classes.headerContainer}
              style={{
                color: `${color}`,
                backgroundColor: `#${brandColor}`,
              }}
            >
              <HeaderContent
                title={watchFields.leftSideBarTitle}
                body={watchFields.leftSideBarBody}
                uploadImage={pageCompanyLogo}
                railzWatermarkEnabled={railzWatermarkEnabled}
              />
            </div>
            <div className={classes.contentContainer}>
              {((): React.ReactElement => {
                switch (true) {
                  case expandedContentSection === 'saveBusinessUrlToggle' && saveUrlEnabled:
                    return <SaveUrlPreview url={saveUrl} brandColor={brandColor} />;
                  case showOverviewContent(expandedContentSection):
                    return (
                      <OverviewContent
                        brandColor={brandColor}
                        accentColor={accentColor}
                        overviewEnabled={overviewEnabled}
                        summaryEnabled={summaryEnabled}
                        helpLinkEnabled={helpLinkEnabled}
                        helpLinkBody={watchFields.helpLinkBody}
                        termsAndConditionsEnabled={termsAndConditionsEnabled}
                        termsAndConditionsBody={watchFields.termsAndConditionsBody}
                        expandedContentSection={expandedContentSection}
                        accountingRequired={accountingRequired}
                        bankingRequired={bankingRequired}
                        commerceRequired={commerceRequired}
                      />
                    );
                  case expandedContentSection === 'brand':
                    return (
                      <IntegrationsContent
                        brandColor={brandColor}
                        accentColor={accentColor}
                        overviewEnabled={overviewEnabled}
                        summaryEnabled={summaryEnabled}
                        helpLinkEnabled={helpLinkEnabled}
                      />
                    );
                  default:
                    return (
                      <OnboardingContent
                        title={watchFields.onboardLandingPageTitle}
                        body={watchFields.onboardLandingPageBody}
                        brandColor={brandColor}
                      />
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicPreview;
