import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) => {
  return createStyles({
    headerBar: {
      '&>.MuiToolbar-root': {
        height: theme.spacing(9),
      },
    },
    teamSwitch: {
      marginRight: theme.spacing(2),
      borderRight: `0.5px solid`,
      borderRightColor: theme.palette.common.white,
      paddingRight: theme.spacing(2),
    },
    teamSwitchButton: {
      maxHeight: 43,
      padding: 0,
      '& .MuiButton-label': {
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: theme.spacing(1),
      },
    },

    teamSwitchMenuItemTexts: {
      textAlign: 'left',
    },
    teamSwitchButtonTexts: {
      textAlign: 'right',
      color: theme.palette.common.white,
    },
    teamSwitchTeamNameText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1rem',
      width: theme.spacing(26),
    },
    teamSwitchTeamRoleText: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    teamSwitchMenu: {
      maxHeight: '300px',
      overflowY: 'auto',
      paddingTop: 0,
      paddingBottom: 0,
    },
    teamSwitchMenuHeader: {
      display: 'block',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px `,
      fontWeight: 600,
      fontSize: `0.75rem`,
      lineHeight: '16px',
      letterSpacing: '0.06em',
      textTransform: 'uppercase',
    },
    teamSwitchMenuItem: {
      color: theme.palette.grey[800],

      '&.MuiButtonBase-root': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },

      '&.current': {
        color: ACTIVE_TEXT_COLOR,
      },
    },

    title: {
      fontSize: '1.125rem !important',
      fontWeight: 400,
      lineHeight: 1.2,

      [theme.breakpoints.up('sm')]: {
        fontSize: `${theme.spacing(3)}px !important`,
      },
    },

    sandboxBanner: {
      padding: theme.spacing(),
      backgroundColor: theme.palette.grey[200],
    },

    limitBannerWarning: {
      padding: theme.spacing(),
      backgroundColor: '#FDF7E7',
      color: '#603E11',
    },

    limitBannerDanger: {
      padding: theme.spacing(),
      backgroundColor: '#FCECE7',
      color: theme.palette.error.main,
    },

    limitTitle: {
      fontSize: `0.75rem !important`,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightRegular as number,
    },

    upgradeWarningLink: {
      color: '#603E11',
    },

    upgradeDangerLink: {
      color: theme.palette.error.main,
    },

    upgradeLink: {
      textDecoration: 'underline !important',
      marginLeft: theme.spacing(1),
    },

    sandboxTitle: {
      fontSize: '0.75rem !important',
      textAlign: 'center',
    },

    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },

    userName: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginLeft: theme.spacing(1.5),
        color: 'white',
        textDecoration: 'none',
        fontSize: 14,
      },
    },

    chipContainer: {
      textDecoration: 'none',
      display: 'none',

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },

    chipStyle: {
      '& span': {
        fontWeight: theme.typography.fontWeightMedium as number,
      },
    },

    upgradeNowButton: {
      '& .button': {
        minHeight: theme.spacing(4),
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
      },
    },
  });
});
