import * as yup from 'yup';

import { SPECIAL_CHARS } from 'types/constants';

// Add custom Yup methods
yup.addMethod(yup.string, 'noSpecialChars', function (message) {
  return this.test('noSpecialChars', message, function (value) {
    return !SPECIAL_CHARS.some((char) => value.includes(char));
  });
});
