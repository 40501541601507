/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { applyMiddleware, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';

import { persistStore } from 'redux-persist';

import { persistedReducer } from './persist';
import { saga } from './saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
const store = createStore(persistedReducer, applyMiddleware(...middlewares));
sagaMiddleware.run(saga);

export const persistor = persistStore(store);
// export store singleton instance
export default store;
