import { SuccessResponse } from '../../../../types';
import { ErrorResponse } from '../../../../types/interface/error.interface';
import { ProfileState } from '../profile/profile.state';

import {
  CompanyResponse,
  CustomerContact,
  CustomerSendMail,
  CustomerState,
  EmailSearch,
  EmailSearchResponse,
  EnableDisableSSORequest,
  StripePortalParam,
  StripePortalResponse,
  TransferOwnershipRequest,
} from './customer.state';

export const RESET_CUSTOMER_ERROR = 'RESET_CUSTOMER_ERROR';
export const RESET_CUSTOMER_RESPONSE = 'RESET_CUSTOMER_RESPONSE';

export const FETCH_CUSTOMER_API = 'FETCH_CUSTOMER_API';
export const FETCH_CUSTOMER_SUCCESS_API = 'FETCH_CUSTOMER_SUCCESS_API';
export const FETCH_CUSTOMER_FAILURE_API = 'FETCH_CUSTOMER_FAILURE_API';

export const FETCH_CUSTOMER_EMAIL_API = 'FETCH_CUSTOMER_EMAIL_API';
export const FETCH_CUSTOMER_EMAIL_SUCCESS_API = 'FETCH_CUSTOMER_EMAIL_SUCCESS_API';
export const FETCH_CUSTOMER_EMAIL_FAILURE_API = 'FETCH_CUSTOMER_EMAIL_FAILURE_API';

export const RESET_CUSTOMER_EMAIL_SUGGESTIONS_API = 'RESET_CUSTOMER_EMAIL_SUGGESTIONS_API';

export const UPDATE_CUSTOMER_API = 'UPDATE_CUSTOMER_API';
export const UPDATE_CUSTOMER_SUCCESS_API = 'UPDATE_CUSTOMER_SUCCESS_API';
export const UPDATE_CUSTOMER_FAILURE_API = 'UPDATE_CUSTOMER_FAILURE_API';

export const UPDATE_COMPANY_API = 'UPDATE_COMPANY_API';
export const UPDATE_COMPANY_SUCCESS_API = 'UPDATE_COMPANY_SUCCESS_API';
export const UPDATE_COMPANY_FAILURE_API = 'UPDATE_COMPANY_FAILURE_API';

export const DELETE_COMPANY_LOGO_API = 'DELETE_COMPANY_LOGO_API';
export const DELETE_COMPANY_LOGO_SUCCESS_API = 'DELETE_COMPANY_LOGO_SUCCESS_API';
export const DELETE_COMPANY_LOGO_FAILURE_API = 'DELETE_COMPANY_LOGO_FAILURE_API';

export const SEND_EMAIL_API = 'SEND_EMAIL_API';
export const SEND_EMAIL_SUCCESS_API = 'SEND_EMAIL_SUCCESS_API';
export const SEND_EMAIL_FAILURE_API = 'SEND_EMAIL_FAILURE_API';

export const RESET_SEND_EMAIL_API = 'RESET_SEND_EMAIL_API';

export const UPDATE_CUSTOMER_CONTACT_API = 'UPDATE_CUSTOMER_CONTACT_API';
export const UPDATE_CUSTOMER_CONTACT_SUCCESS_API = 'UPDATE_CUSTOMER_CONTACT_SUCCESS_API';
export const UPDATE_CUSTOMER_CONTACT_FAILURE_API = 'UPDATE_CUSTOMER_CONTACT_FAILURE_API';

export const FETCH_STRIPE_PORTAL_API = 'FETCH_STRIPE_PORTAL_API';
export const FETCH_STRIPE_PORTAL_SUCCESS_API = 'FETCH_STRIPE_PORTAL_SUCCESS_API';
export const FETCH_STRIPE_PORTAL_FAILURE_API = 'FETCH_STRIPE_PORTAL_FAILURE_API';

export const CREATE_STRIPE_PAYMENT_FAILURE_API = 'CREATE_STRIPE_PAYMENT_FAILURE_API';

export const CREATE_STRIPE_CHECKOUT_API = 'CREATE_STRIPE_CHECKOUT_API';
export const CREATE_STRIPE_CHECKOUT_SUCCESS_API = 'CREATE_STRIPE_CHECKOUT_SUCCESS_API';
export const CREATE_STRIPE_CHECKOUT_FAILURE_API = 'CREATE_STRIPE_CHECKOUT_FAILURE_API';

export const CREATE_STRIPE_SIGNUP_CHECKOUT_API = 'CREATE_STRIPE_SIGNUP_CHECKOUT_API';
export const CREATE_STRIPE_SIGNUP_CHECKOUT_SUCCESS_API =
  'CREATE_STRIPE_SIGNUP_CHECKOUT_SUCCESS_API';
export const CREATE_STRIPE_SIGNUP_CHECKOUT_FAILURE_API =
  'CREATE_STRIPE_SIGNUP_CHECKOUT_FAILURE_API';

export const RESET_CUSTOMER_INFORMATION = 'RESET_CUSTOMER_INFORMATION';

export const ENABLE_DISABLE_SSO_API = 'ENABLE_DISABLE_SSO_API';
export const ENABLE_DISABLE_SSO_SUCCESS_API = 'ENABLE_DISABLE_SSO_SUCCESS_API';
export const ENABLE_DISABLE_SSO_FAILURE_API = 'ENABLE_DISABLE_SSO_FAILURE_API';

export const TRANSFER_OWNERSHIP_API = 'TRANSFER_OWNERSHIP_API';
export const TRANSFER_OWNERSHIP_SUCCESS_API = 'TRANSFER_OWNERSHIP_SUCCESS_API';
export const TRANSFER_OWNERSHIP_FAILURE_API = 'TRANSFER_OWNERSHIP_FAILURE_API';

export const NO_BRANDING_CHANGE_SAVE = 'NO_BRANDING_CHANGE_SAVE';

export const RESET_STORAGE_CUSTOMER = 'RESET_STORAGE_CUSTOMER';

export const resetCustomerStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_CUSTOMER,
  };
};

export const resetCustomerInformation = (): { type: string } => {
  return {
    type: RESET_CUSTOMER_INFORMATION,
  };
};

export const resetCustomerError = (): { type: string } => {
  return {
    type: RESET_CUSTOMER_ERROR,
  };
};
export const resetCustomerResponse = (): { type: string } => {
  return {
    type: RESET_CUSTOMER_RESPONSE,
  };
};

export const resetSendEmailResponse = (): { type: string } => {
  return {
    type: RESET_SEND_EMAIL_API,
  };
};

export const fetchCustomerApi = (payload?: boolean): { type: string; payload: boolean } => {
  return {
    type: FETCH_CUSTOMER_API,
    payload,
  };
};

export const fetchCustomerSuccessApi = (
  payload: CustomerState,
): { payload: CustomerState; type: string } => {
  return {
    type: FETCH_CUSTOMER_SUCCESS_API,
    payload,
  };
};

export const fetchCustomerFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: FETCH_CUSTOMER_FAILURE_API,
    payload,
  };
};

export const fetchCustomerEmailApi = (
  payload: EmailSearch,
): {
  type: string;
  payload: EmailSearch;
} => {
  return {
    type: FETCH_CUSTOMER_EMAIL_API,
    payload,
  };
};

export const fetchCustomerEmailSuccessApi = (
  payload: EmailSearchResponse,
): {
  payload: EmailSearchResponse;
  type: string;
} => {
  return {
    type: FETCH_CUSTOMER_EMAIL_SUCCESS_API,
    payload,
  };
};

export const fetchCustomerEmailFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: FETCH_CUSTOMER_EMAIL_FAILURE_API,
    payload,
  };
};

export const resetCustomerEmailSuggestionsApi = (): { type: string } => {
  return {
    type: RESET_CUSTOMER_EMAIL_SUGGESTIONS_API,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateCompanyApi = (payload: any): { payload: any; type: string } => {
  return {
    type: UPDATE_COMPANY_API,
    payload,
  };
};

export const updateCompanySuccessApi = (
  payload: CompanyResponse,
): { type: string; payload: CompanyResponse } => {
  return {
    type: UPDATE_COMPANY_SUCCESS_API,
    payload,
  };
};

export const updateCompanyFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: UPDATE_COMPANY_FAILURE_API,
    payload,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateCustomerApi = (payload: any): { payload: any; type: string } => {
  return {
    type: UPDATE_CUSTOMER_API,
    payload,
  };
};

export const updateCustomerSuccessApi = (payload: {
  customer: CustomerState;
  response?: SuccessResponse;
}): { type: string; payload: { customer: CustomerState; response?: SuccessResponse } } => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS_API,
    payload,
  };
};

export const updateCustomerFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: UPDATE_CUSTOMER_FAILURE_API,
    payload,
  };
};

export const deleteCompanyLogoApi = (): { type: string } => {
  return {
    type: DELETE_COMPANY_LOGO_API,
  };
};

export const deleteCompanyLogoSuccessApi = (): { type: string } => {
  return {
    type: DELETE_COMPANY_LOGO_SUCCESS_API,
  };
};

export const deleteCompanyLogoFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DELETE_COMPANY_LOGO_FAILURE_API,
    payload,
  };
};

export const sendEmailApi = (
  payload: CustomerSendMail,
): { type: string; payload: CustomerSendMail } => {
  return {
    type: SEND_EMAIL_API,
    payload,
  };
};

export const sendEmailSuccessApi = (): { type: string } => {
  return {
    type: SEND_EMAIL_SUCCESS_API,
  };
};

export const sendEmailFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: SEND_EMAIL_FAILURE_API,
    payload,
  };
};

export const updateCustomerContactApi = (
  payload: CustomerContact,
): { payload: CustomerContact; type: string } => {
  return {
    type: UPDATE_CUSTOMER_CONTACT_API,
    payload,
  };
};

export const updateCustomerContactSuccessApi = (
  payload: CustomerState,
): { type: string; payload: CustomerState } => {
  return {
    type: UPDATE_CUSTOMER_CONTACT_SUCCESS_API,
    payload,
  };
};

export const updateCustomerContactFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: UPDATE_CUSTOMER_CONTACT_FAILURE_API,
    payload,
  };
};

export const fetchStripePortalApi = (
  payload: StripePortalParam,
): { payload: StripePortalParam; type: string } => {
  return {
    type: FETCH_STRIPE_PORTAL_API,
    payload,
  };
};

export const fetchStripePortalSuccessApi = (
  payload: StripePortalResponse,
): { type: string; payload: StripePortalResponse } => {
  return {
    type: FETCH_STRIPE_PORTAL_SUCCESS_API,
    payload,
  };
};

export const fetchStripePortalFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: FETCH_STRIPE_PORTAL_FAILURE_API,
    payload,
  };
};

export const createStripePaymentFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: CREATE_STRIPE_PAYMENT_FAILURE_API,
    payload,
  };
};

export const createStripeCheckoutApi = (): { type: string } => {
  return { type: CREATE_STRIPE_CHECKOUT_API };
};

export const createStripeCheckoutSuccessApi = (payload: string): { type: string; payload: any } => {
  return { type: CREATE_STRIPE_CHECKOUT_SUCCESS_API, payload };
};

export const createStripeCheckoutFailureApi = (
  payload: ErrorResponse,
): { type: string; payload: any } => {
  return { type: CREATE_STRIPE_CHECKOUT_FAILURE_API, payload };
};

export const createStripeSignupCheckoutApi = (): { type: string } => {
  return { type: CREATE_STRIPE_SIGNUP_CHECKOUT_API };
};

export const createStripeSignupCheckoutSuccessApi = (
  payload: string,
): { type: string; payload: any } => {
  return { type: CREATE_STRIPE_SIGNUP_CHECKOUT_SUCCESS_API, payload };
};

export const createStripeSignupCheckoutFailureApi = (
  payload: ErrorResponse,
): { type: string; payload: any } => {
  return { type: CREATE_STRIPE_SIGNUP_CHECKOUT_FAILURE_API, payload };
};

export const enableDisableSSOApi = (
  payload: EnableDisableSSORequest,
): { type: string; payload: any } => {
  return {
    type: ENABLE_DISABLE_SSO_API,
    payload,
  };
};

export const enableDisableSSOSuccessApi = (
  payload: ProfileState,
): { type: string; payload: ProfileState } => {
  return {
    type: ENABLE_DISABLE_SSO_SUCCESS_API,
    payload,
  };
};

export const enableDisableSSOFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse } => {
  return {
    type: ENABLE_DISABLE_SSO_FAILURE_API,
    payload,
  };
};

export const noChangeSave = (): { type: string } => {
  return { type: NO_BRANDING_CHANGE_SAVE };
};

export const transferOwnershipApi = (
  payload: TransferOwnershipRequest,
): { type: string; payload: any } => {
  return {
    type: TRANSFER_OWNERSHIP_API,
    payload,
  };
};

export const transferOwnershipSuccessApi = (
  payload: Partial<ProfileState>,
): { type: string; payload: any } => {
  return {
    type: TRANSFER_OWNERSHIP_SUCCESS_API,
    payload,
  };
};

export const transferOwnershipFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse } => {
  return {
    type: TRANSFER_OWNERSHIP_FAILURE_API,
    payload,
  };
};
