/* eslint-disable  */
import React, { useEffect } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { useDispatch, useSelector } from 'react-redux';

import useStyles from './style';

import { FormInput } from 'components/form';
import { showSnackbar } from 'helpers/common.helper';
import { TeamListElement } from 'types';
import AlertDialog from 'components/dialog';
import {
  resetTeamError,
  resetTeamResponse,
  deleteTeamApi,
} from 'store/features/account/team/team.action';
import {
  getTeamApiResponse,
  getTeamError,
  isTeamLoading,
} from 'store/features/account/team/team.selector';
import { Alert } from '@material-ui/lab';

const createTranslationString =
  (values: Record<string, string>) =>
  ({ i18nKey }: { i18nKey: string }): React.ReactElement =>
    (
      <Trans
        shouldUnescape={true}
        tOptions={{ interpolation: { escapeValue: true } }}
        i18nKey={i18nKey}
        components={{ bold: <strong /> }}
        values={values}
      />
    );

const DeleteTeamModal = ({
  onClose,
  team,
}: {
  onClose: () => void;
  team: TeamListElement;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { t } = useTranslation();
  const TranslatedString = createTranslationString({ Name: team.teamName });

  const { handleSubmit, errors, control } = useForm({
    mode: 'onTouched',
    defaultValues: { confirmation: '' },
    resolver: yupResolver(
      yup.object().shape({
        confirmation: yup
          .string()
          .oneOf([team.teamName], 'DASHBOARD_TEAMS_TEAM_DELETE_MODAL_WARNING_ERROR'),
      }),
    ),
  });

  const teamErrorMessage = useSelector(getTeamError);
  const teamResponse = useSelector(getTeamApiResponse);
  const isLoading = useSelector(isTeamLoading);

  const onSubmitData = async (): Promise<void> => {
    await dispatch(deleteTeamApi(team.uuid));
  };

  useEffect(() => {
    if (teamErrorMessage) {
      dispatch(resetTeamResponse());
      dispatch(resetTeamError());
      showSnackbar({
        message: <TranslatedString i18nKey="DASHBOARD_TEAMS_TEAM_DELETE_ERROR" />,
        type: 'error',
      });
    }
    if (teamResponse) {
      dispatch(resetTeamResponse());
      showSnackbar({
        message: <TranslatedString i18nKey="DASHBOARD_TEAMS_TEAM_DELETE_SUCCESS" />,
        type: 'success',
      });
      onClose();
    }
  }, [teamErrorMessage, teamResponse]);

  if (isLoading)
    return (
      <AlertDialog isOpen={true}>
        <Grid container className={classes.loading} direction="column">
          <Grid item>
            <Typography
              className={classes.confirmationLabel}
              paragraph={true}
              data-testid="loading-text"
            >
              <TranslatedString i18nKey="DASHBOARD_TEAMS_TEAM_DELETE_MODAL_WAIT_MESSAGE" />
            </Typography>
          </Grid>

          <Grid item>
            <CircularProgress size="3rem" />
          </Grid>
        </Grid>
      </AlertDialog>
    );

  return (
    <AlertDialog
      isOpen={true}
      title={t('DASHBOARD_TEAMS_TEAM_DELETE_MODAL')}
      cancel={{
        label: t('DASHBOARD_TEAM_ADD_CTA_CANCEL'),
        onClick: onClose,
        'data-testid': 'cancel-button',
        type: 'gray',
      }}
      confirm={{
        type: 'error',
        label: t('DASHBOARD_TEAMS_TEAM_DELETE_CTA'),
        onClick: handleSubmit(onSubmitData),
        'data-testid': 'cta-button',
      }}
      onClose={onClose}
      showCloseButton
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid container>
          <Typography className={classes.confirmationLabel} paragraph={true}>
            <TranslatedString i18nKey="DASHBOARD_TEAMS_TEAM_DELETE_MODAL_BODY" />
          </Typography>
          <Alert
            severity="warning"
            className={classes.confirmationAlert}
            iconMapping={{ warning: <WarningRoundedIcon fontSize="inherit" /> }}
          >
            <TranslatedString i18nKey="DASHBOARD_TEAMS_TEAM_DELETE_MODAL_BODY_WARNING" />
          </Alert>

          <Typography className={classes.confirmationLabel} paragraph={true}>
            <TranslatedString i18nKey="DASHBOARD_TEAMS_TEAM_DELETE_MODAL_BODY_WARNING_LABEL" />
          </Typography>
          <Grid item xs={12} md={12}>
            <FormInput
              className={classes.confirmationInput}
              defaultValue=""
              id="confirmation"
              name="confirmation"
              margin="dense"
              testid="confirmation-input"
              fullWidth
              errorobj={errors}
              control={control}
              type="text"
            />
          </Grid>
        </Grid>
      </form>
    </AlertDialog>
  );
};

export default DeleteTeamModal;
