import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      margin: `${theme.spacing() * 2}px 0px`,
      borderRadius: 3,
    },
    accordion: {
      padding: theme.spacing(3),

      '& .MuiAccordionSummary-root': {
        padding: 0,
        minHeight: theme.spacing(3),
        outlined: 'none',
        borderRadius: 3,
      },
    },
    hideOutline: {
      boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
    },
    outline: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    },
    accordionSummary: {
      '& .MuiAccordionSummary-content': {
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .MuiAccordionSummary-expandIcon': {
        height: theme.spacing(3),
        width: theme.spacing(3),
        padding: 0,
        marginRight: theme.spacing(0.5),
      },
    },
    textPlanUpdate: {
      color: ACTIVE_TEXT_COLOR,
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    iconPlanUpdate: {
      marginRight: '0.5rem',
    },
    linkPlanUpdate: {
      textDecoration: 'none',
      marginRight: '0.5rem',
    },
    iconInfo: {
      marginRight: 10,
    },
    accordionDetails: {
      padding: `${theme.spacing(2)}px 0 0`,
      flexDirection: 'column',
      textAlign: 'left',
      '& ul': {
        marginTop: 0,
      },
      '& li': {
        fontSize: '0.875rem',
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily,
        lineHeight: theme.typography.subtitle2.lineHeight,
      },
    },
  });
});
