/* eslint-disable react-hooks/exhaustive-deps */
import { Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';

import { RailzButton } from '@railzai/railz-uikit-react';

import { isSandbox } from '../../../store/features/auth';

import {
  getWebhookApiResponse,
  getWebhookState,
  getWebhooks,
} from '../../../store/features/developer/webhook/webhook.selector';

import {
  deleteWebhookApi,
  fetchWebhookApi,
  resetWebhookApiResponse,
} from '../../../store/features/developer/webhook/webhook.action';

import useStyles from './style';

import { WebhookForm } from './form';

import WebhookRowMenu from './webhook-row-menu';
import WebhookSecret from './webhook-secret/index';

import { getWebhookRequestTypesCountLabel, webhookCreationWouldGoOverLimit } from './webhook.utils';

import PageDescription from 'components/page-description/page-description';
import { EventKind, Webhook } from 'types';
import SecretRevealBtn from 'components/secret-reveal-btn';
import AlertDialog from 'components/dialog';
import BasicTable from 'components/table';
import { Header, View } from 'components';
import { showSnackbar } from 'helpers/common.helper';
import { openNewTab } from 'helpers/open-new-tab';
import { fetchDataTypesApi } from 'store/features/report/report.action';
import { getExpandedDataTypes } from 'store/features/report/report.selector';

interface Props {
  testid: string;
}

const snackOptions: any = {
  create: {
    success: ['DASHBOARD_DEVELOPERS_WEBHOOK_SUCCESS', 'success'],
    error: ['DASHBOARD_DEVELOPERS_WEBHOOK_ERROR', 'error'],
  },
  update: {
    success: ['DASHBOARD_DEVELOPERS_WEBHOOK_UPDATE_SUCCESS', 'success'],
    error: ['DASHBOARD_DEVELOPERS_WEBHOOK_UPDATE_FAILURE', 'error'],
  },
  delete: {
    success: ['DASHBOARD_DEVELOPERS_WEBHOOK_DELETE_SUCCESS', 'success'],
    error: ['DASHBOARD_DEVELOPERS_WEBHOOK_DELETE_UNSUCCESSFUL', 'error'],
  },
};

const Webhooks: React.FC<Props> = ({ testid = 'test-header-webhooks-page' }) => {
  const [webhook, setWebhook] = useState<Webhook>(null);
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [isShowForm, setShowForm] = useState(false);
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [otherWebhooks, setOtherWebhooks] = useState<Webhook[]>([]);

  const apiResponse = useSelector(getWebhookApiResponse);
  const webhooksLoading = useSelector(getWebhookState).loading;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const webhookResult = useSelector(getWebhooks);
  const dataTypes = useSelector(getExpandedDataTypes);
  const sandbox = useSelector(isSandbox);
  const classes = useStyles();

  useEffect(() => {
    if (webhookResult) {
      setWebhooks(webhookResult);
    }
  }, [webhookResult]);

  useEffect(() => {
    if (['update', 'create', 'delete'].includes(apiResponse?.message)) {
      const lastAction = apiResponse?.message;
      const result = apiResponse?.success ? 'success' : 'error';
      const [message, type] = snackOptions[lastAction][result];
      dispatch(resetWebhookApiResponse());
      showSnackbar({ message: t(message), type });
    }
  }, [apiResponse]);

  useEffect(() => {
    dispatch(fetchWebhookApi());
    dispatch(fetchDataTypesApi());
  }, []);

  const names = {
    url: t('DASHBOARD_DEVELOPERS_WEBHOOKS_URL'),
    kind: t('DASHBOARD_DEVELOPERS_WEBHOOKS_EVENT_TYPE'),
    secret: t('DASHBOARD_DEVELOPERS_WEBHOOKS_SECRET'),
    cta: t('DASHBOARD_DEVELOPERS_WEBHOOKS_CTA'),
  };

  const formattedWebhookKind = (kinds: string[], requestTypes: Webhook['requestTypes']): string =>
    kinds
      .map(
        (kind) =>
          t(`DASHBOARD_ENUM_${EventKind._name}_${kind.toUpperCase()}`) +
          (kind === EventKind.DATAPERTYPE
            ? getWebhookRequestTypesCountLabel(requestTypes.length, dataTypes)
            : ''),
      )
      .sort((a, b) => String(a).localeCompare(b))
      .join(', ');

  const columns = [
    {
      key: 'url',
      name: names.url,
      render: ({ url }): React.ReactElement => <span className={classes.url}>{url}</span>,
    },

    {
      key: 'secret',
      name: names.secret,
      render: ({ secret }): React.ReactElement => (
        <SecretRevealBtn label="DASHBOARD_DEVELOPERS_WEBHOOK_SECRET_CTA" secret={secret} />
      ),
    },
    {
      key: 'event',
      name: names.kind,
      render: ({ event, requestTypes }): string => formattedWebhookKind(event, requestTypes),
    },
    {
      key: 'cta',
      name: names.cta,
      render: (webhook): React.ReactElement => (
        <WebhookRowMenu
          webhook={webhook}
          onUpdateWebhook={openWebhookForm}
          setWebhook={setWebhook}
          setOpenAlert={setOpenAlert}
          formattedWebhookKind={formattedWebhookKind}
        />
      ),
    },
  ];

  const openWebhookForm = useCallback(
    (webhook?: Webhook): void => {
      const isCreateForm = !webhook;
      const others = !isCreateForm ? webhooks.filter((w) => w.uuid !== webhook.uuid) : webhooks;

      if (isCreateForm && webhookCreationWouldGoOverLimit(others, sandbox)) {
        showSnackbar({ message: t('DASHBOARD_DEVELOPERS_WEBHOOK_ERROR_MAX'), type: 'error' });
        return;
      }
      setOtherWebhooks(others);
      setWebhook(webhook);
      setShowForm(true);
    },
    [webhooks],
  );

  const onCreateWebhook = useCallback(() => openWebhookForm(), [webhooks]);

  const onConfirmDelete = async (): Promise<void> => {
    dispatch(deleteWebhookApi(webhook.uuid));
    closeAll();
  };

  const closeAll = (): void => {
    setWebhook(null);
    setShowForm(false);
    setOpenAlert(false);
  };

  return (
    <>
      <Header
        drawerMenu
        leftComponent={null}
        rightComponent={null}
        title={t('DASHBOARD_DEVELOPERS_WEBHOOKS_HEADER')}
        testId={testid}
      />

      <View className={classes.view}>
        <div>
          <div className={classes.viewHeader}>
            <PageDescription
              title="DASHBOARD_DEVELOPERS_WEBHOOKS_TITLE"
              subtitle={
                sandbox
                  ? 'DASHBOARD_DEVELOPERS_WEBHOOKS_DESCRIPTION_SANDBOX'
                  : 'DASHBOARD_DEVELOPERS_WEBHOOKS_DESCRIPTION'
              }
              subtitleComponents={{
                a: (
                  <Link
                    href="https://docs.railz.ai/reference/webhooks"
                    onClick={(e): void => openNewTab(e, 'https://docs.railz.ai/reference/webhooks')}
                    className={classes.link}
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
            <Typography variant="h2" className={classes.title}>
              {t('DASHBOARD_DEVELOPERS_WEBHOOKS_SETTINGS_HEADER')}
            </Typography>
            <WebhookSecret />
            <RailzButton
              label={t('DASHBOARD_DEVELOPERS_WEBHOOK_ADD_ARIA')}
              onClick={onCreateWebhook}
              size="large"
              className={classes.primaryCta}
              data-testid="cta-webhook-page"
            >
              <span slot="prefix">
                <AddIcon />
              </span>
            </RailzButton>
          </div>

          <View className={classes.viewContent}>
            <BasicTable
              columns={columns}
              rows={webhooks}
              stickyHeader
              isLoading={webhooksLoading}
              noData={t('DASHBOARD_DEVELOPERS_WEBHOOKS_NO_RESULTS')}
              isPaperComponent={false}
            />
          </View>
        </div>
      </View>

      {isShowForm && (
        <WebhookForm
          webhook={webhook}
          onClose={closeAll}
          sandbox={sandbox}
          otherWebhooks={otherWebhooks}
          dataTypes={dataTypes}
        />
      )}

      <AlertDialog
        isOpen={isOpenAlert}
        message={t('DASHBOARD_DEVELOPERS_WEBHOOK_DELETE_MODAL_DESCRIPTION')}
        cancel={{
          label: t('DASHBOARD_BUSINESS_CANCEL'),
          onClick: closeAll,
          type: 'gray',
        }}
        title={t('DASHBOARD_DEVELOPERS_WEBHOOK_DELETE_MODAL_TITLE')}
        confirm={{
          label: t('DASHBOARD_DEVELOPERS_WEBHOOK_DELETE_MODAL_CONFIRM'),
          onClick: onConfirmDelete,
          type: 'error',
          'data-testid': 'confirm-cta-delete',
        }}
        showCloseButton={true}
        onClose={(): void => setOpenAlert(false)}
      />
    </>
  );
};
export default Webhooks;
