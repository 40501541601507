import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import ErrorIcon from '../error-icon';

import { HttpStatus, HttpStatusError } from 'types';

interface TextProps {
  title?: string;
  subTitle?: string;
  showTitle?: boolean;
  showSubTitle?: boolean;
}

interface Props extends TextProps {
  statusCode: number;
  imageStyle?: any;
  testId?: string;
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flex: 1,
    },
    title: {
      lineHeight: '1.21',
      fontWeight: 500,
      color: theme.palette.grey[800],
    },
    image500: {
      height: 74,
    },
    image: {
      height: 57,
    },
    description: {
      maxWidth: '100%',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.palette.grey[800],
    },
  }),
);
const image500 = {
  height: 74,
};

const image = {
  height: 57,
};

function ErrorComponentText({
  title,
  subTitle,
  showTitle = true,
  showSubTitle = true,
}: TextProps): JSX.Element {
  const classes = styles();
  return (
    <>
      {showTitle && (
        <Typography className={classes.title} color="textSecondary">
          {title}
        </Typography>
      )}
      {showSubTitle && (
        <Typography color="textSecondary" className={classes.description}>
          {subTitle}
        </Typography>
      )}
    </>
  );
}

export default function ErrorComponent({
  statusCode,
  title,
  subTitle,
  imageStyle = null,
  showTitle = true,
  showSubTitle = true,
  testId,
}: Props): JSX.Element {
  const classes = styles();
  const { t } = useTranslation();
  switch (statusCode) {
    case HttpStatusError.INTERNAL_SERVER_ERROR:
    case HttpStatusError.BAD_REQUEST:
      return (
        <Box className={classes.errorContainer} data-testid={`${testId}-${statusCode}`}>
          <ErrorIcon style={imageStyle || image500} statusCode={500} />
          <ErrorComponentText
            showTitle={showTitle}
            showSubTitle={showSubTitle}
            title={t(title || 'DASHBOARD_ERROR_500_TITLE')}
            subTitle={t(subTitle || 'DASHBOARD_ERROR_500_SUBTITLE')}
          />
        </Box>
      );
    case HttpStatusError.NOT_FOUND:
      return (
        <Box className={classes.errorContainer} data-testid={`${testId}-${statusCode}`}>
          <ErrorIcon style={imageStyle || image} statusCode={404} />
          <ErrorComponentText
            showTitle={showTitle}
            showSubTitle={showSubTitle}
            title={t(title || 'DASHBOARD_ERROR_404_TITLE')}
            subTitle={t(subTitle || 'DASHBOARD_ERROR_404_SUBTITLE')}
          />
        </Box>
      );
    case HttpStatus.NO_DATA:
      return (
        <Box className={classes.errorContainer} data-testid={`${testId}-${statusCode}`}>
          <ErrorIcon statusCode={204} style={imageStyle || image} />
          <ErrorComponentText
            showTitle={showTitle}
            showSubTitle={showSubTitle}
            title={t(title || 'DASHBOARD_ERROR_204_TITLE')}
            subTitle={t(subTitle || 'DASHBOARD_ERROR_204_SUBTITLE')}
          />
        </Box>
      );
    case HttpStatus.PENDING:
      return (
        <Box className={classes.errorContainer} data-testid={`${testId}-${statusCode}`}>
          <ErrorIcon style={imageStyle || image} statusCode={202} />
          <ErrorComponentText
            showTitle={showTitle}
            showSubTitle={showSubTitle}
            title={t(title || 'DASHBOARD_ERROR_202_TITLE')}
            subTitle={t(subTitle || 'DASHBOARD_ERROR_202_SUBTITLE')}
          />
        </Box>
      );
    default:
      return null;
  }
}
