import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, InputAdornment, Typography } from '@material-ui/core';

import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import { getCustomerState } from '../../../store/features/account/customer/customer.selector';
import storeService from '../../../store';

import { resetCustomerError } from '../../../store/features/account/customer/customer.action';
import { CustomerState } from '../../../store/features/account/customer/customer.state';

import useStyle from './style';

import { getCountryList, getRegionList } from 'data/countries';
import { PHONE_REGEX, WEBSITE_REGEX, ZIP_REGEXES } from 'helpers/regex.helper';
import { FormInput, FormPhoneInput, FormSelectAutoComplete } from 'components/form';
import { showSnackbar, formChange } from 'helpers/common.helper';

export interface IFormValue {
  companyName: string;
  legalEntityName: string;
  address: string;
  country: any | string;
  city: string;
  province: any | string;
  postalCode: string;
  website: string;
  phoneNumber: string;
  techContactEmail: string;
  techContactName: string;
}

const initialValues: IFormValue = {
  companyName: '',
  legalEntityName: '',
  country: '',
  address: '',
  city: '',
  province: '',
  postalCode: '',
  website: '',
  phoneNumber: '',
  techContactEmail: '',
  techContactName: '',
};

const validationSchema: SchemaOf<IFormValue> = yup.object().shape(
  {
    companyName: yup
      .string()
      .trim()
      .required('DASHBOARD_SIGNUP_COMPANY_NAME_REQUIRED')
      .min(2, 'DASHBOARD_SIGNUP_COMPANY_NAME_CRUD_MIN')
      .max(50, 'DASHBOARD_SIGNUP_COMPANY_NAME_CRUD_MAX'),
    legalEntityName: yup
      .string()
      .required('DASHBOARD_COMPANY_LEGAL_ENTITY_NAME_REQUIRED')
      .nullable(),
    country: yup.object().required('DASHBOARD_COMPANY_COUNTRY_REQUIRED').nullable(),
    address: yup.string().required('DASHBOARD_COMPANY_ADDRESS_REQUIRED').nullable(),
    city: yup.string().required('DASHBOARD_COMPANY_CITY_REQUIRED').nullable(),
    province: yup.object().required('DASHBOARD_COMPANY_STATE_REQUIRED').nullable(),
    postalCode: yup
      .string()
      .required('DASHBOARD_COMPANY_ZIP_REQUIRED')
      .transform((value) => value?.toUpperCase())
      .nullable()
      .when('country', (country, schema) => {
        if (yup.object().required().nullable().isValid(country)) {
          return schema.matches(
            ZIP_REGEXES[country?.countryShortCode],
            'DASHBOARD_COMPANY_ZIP_INVALID',
          );
        } else {
          return schema;
        }
      }),
    website: yup.string().matches(WEBSITE_REGEX, 'DASHBOARD_COMPANY_WEBSITE_INVALID').nullable(),
    phoneNumber: yup
      .string()
      .required('DASHBOARD_COMPANY_PHONE_REQUIRED')
      .nullable()
      .matches(PHONE_REGEX, 'DASHBOARD_COMPANY_PHONE_INVALID'),
    techContactEmail: yup
      .string()
      .email('DASHBOARD_COMPANY_TECH_CONTACT_EMAIL_INVALID')
      .nullable()
      .when('techContactName', {
        is: (val) => val,
        then: yup.string().required('DASHBOARD_COMPANY_TECH_CONTACT_EMAIL_REQUIRED').nullable(),
      }),
    techContactName: yup
      .string()
      .nullable()
      .when('techContactEmail', {
        is: (val) => val,
        then: yup.string().required('DASHBOARD_COMPANY_TECH_CONTACT_NAME_REQUIRED').nullable(),
      }),
  },
  [['techContactEmail', 'techContactName']],
);

interface Data {
  body: any;
  country: any;
  province: any;
}

interface Props {
  companySubmit: (data: Data) => void;
  customerError?: any;
}

export default function CompanyForm({ companySubmit, customerError = null }: Props): JSX.Element {
  const store = storeService.getStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyle();
  const [optionStates, setOptionStates] = useState([]);
  const [saveFormState, setSaveFormState] = useState(false);
  const countries = getCountryList();

  const customer = useSelector<ReturnType<typeof store.getState>, CustomerState>(getCustomerState);

  const { handleSubmit, errors, control, formState, watch, setValue, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
  });
  const { isSubmitting } = formState;
  const onSubmit = async (values): Promise<void> => {
    const data: any = getValues(['country', 'province']);
    const country = data?.country?.countryShortCode || '';
    const province = data?.province?.name || '';

    if (!values.website || values.website?.trim() === '') {
      delete values.website;
    } else {
      values.website = `https://${values.website.trim()}`;
    }

    if (values.phoneNumber?.trim() === '') {
      delete values.phoneNumber;
    } else {
      // TODO - consider moving this into Dto or entity
      values.phoneNumber = values.phoneNumber.replace(/\D/g, '');
    }
    if (values.legalEntityName?.trim() === '') delete values.legalEntityName;

    if (formChange({ ...values, country, province }, customer)) {
      companySubmit({ ...values, country, province });
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }
  };

  const country: any = watch('country');

  useEffect(() => {
    if (customerError) {
      setSaveFormState(true);
    }
  }, [customerError]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (customer && !customer.loading && !customerError && !saveFormState) {
        const defaultCountry = countries.find(
          (itm) =>
            itm.countryShortCode ===
            ('country' in customer && customer?.country ? customer?.country : ''),
        );
        const defaultState =
          'province' in customer && customer?.province ? { name: customer?.province } : '';

        const defaultValue = (prop): string =>
          (customer && customer[prop]) || initialValues[prop] || '';

        reset({
          companyName: defaultValue('companyName'),
          legalEntityName: defaultValue('legalEntityName'),
          address: defaultValue('address'),
          country: defaultCountry,
          city: defaultValue('city'),
          province: defaultState,
          postalCode: defaultValue('postalCode'),
          website: defaultValue('website').replace('https://', ''),
          phoneNumber: defaultValue('phoneNumber'),
          techContactEmail: defaultValue('techContactEmail'),
          techContactName: defaultValue('techContactName'),
        });
      }
    })(); // eslint-disable-next-line
  }, [customer]);

  useEffect(() => {
    if (customerError) {
      dispatch(resetCustomerError());
    }
  }, [saveFormState]);

  useEffect(() => {
    if (!country) {
      setValue('country', '');
      return;
    }

    const { countryShortCode } = country;
    setOptionStates(getRegionList(countryShortCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid
          container
          spacing={2}
          role={'region'}
          className={classes.section}
          aria-labelledby={'companyInformationSection'}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              color="textSecondary"
              className={classes.labelDescription}
              tabIndex={0}
              id={'companyInformationSection'}
            >
              {t('DASHBOARD_COMPANY_INFORMATION_HEADER')}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_NAME_PLACEHOLDER"
              label="DASHBOARD_COMPANY_NAME_LABEL"
              fullWidth
              name="companyName"
              id="companyName"
              errorobj={errors}
              control={control}
              testid="company-name-input"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_LEGAL_NAME_PLACEHOLDER"
              label="DASHBOARD_COMPANY_LEGAL_NAME_LABEL"
              variant="outlined"
              fullWidth
              name="legalEntityName"
              id="legalEntityName"
              errorobj={errors}
              control={control}
              testid="legal-entity-name-input"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_WEBSITE_PLACEHOLDER"
              label="DASHBOARD_COMPANY_WEBSITE_LABEL"
              fullWidth
              name="website"
              id="companyWebsite"
              errorobj={errors}
              control={control}
              InputProps={{
                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
              }}
              testid="website-input"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormPhoneInput
              placeholder="DASHBOARD_COMPANY_PHONE_PLACEHOLDER"
              label="DASHBOARD_COMPANY_PHONE_LABEL"
              variant="outlined"
              fullWidth
              name="phoneNumber"
              id="phoneNumber"
              errorobj={errors}
              control={control}
              // TODO - sync this with the country drop-down and enable all supported countries
              onlyCountries={['us']}
              defaultCountry="us"
              disableDropdown={true}
              disableCountryCode={true}
              testid="phone-number-input"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          role={'region'}
          className={classes.section}
          aria-labelledby={'companyAddressSection'}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              color="textSecondary"
              className={classes.labelTopDescription}
              tabIndex={0}
              id={'companyAddressSection'}
            >
              {t('DASHBOARD_COMPANY_ADDRESS_HEADER')}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            {countries && (
              <FormSelectAutoComplete
                placeholder="DASHBOARD_COMPANY_COUNTRY_PLACEHOLDER"
                label="DASHBOARD_COMPANY_COUNTRY_LABEL"
                fullWidth
                name="country"
                id="companyCountry"
                errorobj={errors}
                control={control}
                options={countries}
                getOptionLabel={(option: any): string => option.countryName || ''}
                renderOption={(option): React.ReactElement => (
                  <React.Fragment>{option.countryName}</React.Fragment>
                )}
                valuekey="countryShortCode"
                testid="country-input"
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_ADDRESS_PLACEHOLDER"
              label="DASHBOARD_COMPANY_ADDRESS_LABEL"
              fullWidth
              name="address"
              id="companyAddress"
              errorobj={errors}
              control={control}
              testid="address-input"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_CITY_PLACEHOLDER"
              label="DASHBOARD_COMPANY_CITY_LABEL"
              fullWidth
              name="city"
              id="companyCity"
              errorobj={errors}
              control={control}
              testid="city-input"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {optionStates && (
              <FormSelectAutoComplete
                options={optionStates}
                placeholder="DASHBOARD_COMPANY_STATE_PLACEHOLDER"
                label="DASHBOARD_COMPANY_STATE_LABEL"
                fullWidth
                name="province"
                id="companyProvince"
                errorobj={errors}
                control={control}
                getOptionLabel={(option: any): string => option.name || ''}
                renderOption={(option): React.ReactElement => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                valuekey="name"
                testid="province-input"
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_ZIP_PLACEHOLDER"
              label="DASHBOARD_COMPANY_ZIP_LABEL"
              fullWidth
              name="postalCode"
              id="companyPostalCode"
              errorobj={errors}
              control={control}
              testid="postal-code-input"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} role={'region'} aria-labelledby={'companyTechContactSection'}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              color="textSecondary"
              className={classes.labelTopDescription}
              tabIndex={0}
              id={'companyTechContactSection'}
            >
              {t('DASHBOARD_COMPANY_TECH_CONTACT_HEADER')}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_TECH_CONTACT_NAME_PLACEHOLDER"
              label="DASHBOARD_COMPANY_TECH_CONTACT_NAME_LABEL"
              fullWidth
              name="techContactName"
              id="techContactName"
              errorobj={errors}
              control={control}
              testid="tech-contact-name-input"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              placeholder="DASHBOARD_COMPANY_TECH_CONTACT_EMAIL_PLACEHOLDER"
              label="DASHBOARD_COMPANY_TECH_CONTACT_EMAIL_LABEL"
              fullWidth
              name="techContactEmail"
              id="techContactEmail"
              errorobj={errors}
              control={control}
              testid="tech-contact-email-input"
            />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.button}>
        <RailzButton
          size="large"
          isDisabled={isSubmitting}
          data-testid="submit"
          buttonType="submit"
          label={t('DASHBOARD_COMPANY_CTA')}
        >
          {isSubmitting ? (
            <span slot="prefix">
              <CircularProgress size={20} color="inherit" />
            </span>
          ) : null}
        </RailzButton>
      </div>
    </form>
  );
}
