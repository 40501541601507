import React from 'react';

import { createTheme, Theme } from '@material-ui/core';
import { PaletteColorOptions } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProviderProps } from '@material-ui/styles/ThemeProvider/ThemeProvider';
import { grey } from '@material-ui/core/colors';

import { DefaultBrandColors } from 'types';

export const DEFAULT_PRIMARY_COLOR: PaletteColorOptions = {
  main: '#' + DefaultBrandColors.BRAND,
  dark: '#' + DefaultBrandColors.ACCENT,
};
export const DEFAULT_SECONDARY_COLOR: PaletteColorOptions = {
  main: '#F3FFF8',
};
export const DEFAULT_ERROR_COLOR: PaletteColorOptions = {
  main: '#B30000',
};
export const ACTIVE_TEXT_COLOR = '#006037';
const DEFAULT_FONT = 'Inter';
export const ALL_FONTS = [
  DEFAULT_FONT,
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const createThemeObject = (): Theme => {
  let theme = createTheme({
    palette: {
      primary: DEFAULT_PRIMARY_COLOR,
      secondary: DEFAULT_SECONDARY_COLOR,
      error: DEFAULT_ERROR_COLOR,
      contrastThreshold: 3,
    },
    typography: {
      fontFamily: ALL_FONTS,
    },
    overrides: {
      MuiInputBase: {
        root: {
          '&$disabled *': {
            color: grey[600],
            borderColor: grey[300],
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&$disabled': {
            color: grey[600],
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'unset',
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 'bold',
        },
        sizeSmall: {
          padding: '3px 24px 3px 16px',

          '&:last-child:not(:only-child)': {
            textAlign: 'right',
            width: '120px',

            '& > *': {
              display: 'inline-flex',
            },
          },
        },
      },
    },
  });
  theme = createTheme(theme, {
    overrides: {
      MuiFormLabel: {
        root: {
          color: theme.palette.grey[800],
        },
      },
      MuiOutlinedInput: {
        root: {
          color: theme.palette.grey[800],
          '&:hover $notchedOutline': {
            borderColor: theme.palette.grey[800],
          },
        },
        notchedOutline: {
          borderColor: theme.palette.grey[400],
        },
      },
    },
  });
  return theme;
};

const getTheme = (): Theme => {
  return createThemeObject();
};

interface IState {
  theme: Theme;
}

class Provider extends React.Component {
  state: IState = {
    theme: getTheme(),
  };

  render(): React.ReactElement<ThemeProviderProps<Theme>> {
    return <ThemeProvider theme={this.state.theme}>{this.props.children}</ThemeProvider>;
  }
}

export default Provider;
