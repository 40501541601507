import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) => {
  return createStyles({
    view: {
      display: 'flex',
      overflow: 'auto',
    },

    viewHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
      gap: theme.spacing(2),
    },

    primaryCta: {
      marginTop: `${theme.spacing(1)}px`,
      alignSelf: 'flex-end',
    },

    link: {
      textDecoration: 'underline',
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
    },

    viewContent: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      flexGrow: 1,
      minHeight: '0',
      padding: `0 ${theme.spacing(4)}px`,
      position: 'relative',

      '& table': {
        paddingBottom: `${theme.spacing(10)}px`,
      },
    },

    url: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    title: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
  });
});
