import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  const activeFocus = {
    zIndex: 9999,
    opacity: 1,
    transform: 'translateY(0%)',
  };
  return createStyles({
    skipToContentButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      transform: 'translateY(-50%)',
      transition: 'transform 0.3s',
      margin: theme.spacing(2),
      boxShadow: '0 8px 16px rgba(0,0,0, .2)',

      '& button:active': {
        ...activeFocus,
      },
      '&:focus-within': {
        ...activeFocus,
      },
    },
  });
});
