import React from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  TableProps,
} from '@material-ui/core';

import useStyles from './style';

import { RailzTableCell } from './link-cell';

import { OnRowClick } from 'types';

interface ITableColumns {
  key: string;
  render?: (any, number) => React.ReactNode;
  width?: string;
  name?: string;
  className?: string;
  showSort?: boolean;
  orderBy?: string;
  order?: 'asc' | 'desc';
  handleSort?: (key: string) => (event: React.MouseEvent<unknown>) => void;
  renderColumn?: () => React.ReactNode;
}

interface IBasicTableProps extends TableProps {
  columns: ITableColumns[];
  rows: any[];
  isPaperComponent?: boolean;
  noData?: React.ReactNode | string;
  isLoading?: boolean;
  isHoverable?: boolean;
  onRowClick?: OnRowClick;
  errorLoading?: React.ReactNode;
  selectionKey?: string;
  selectionId?: string;
}

const BasicTable = ({
  isLoading,
  isHoverable = false,
  isPaperComponent = true,
  rows,
  columns,
  noData,
  onRowClick = null,
  size = 'small',
  errorLoading = null,
  selectionKey = null,
  selectionId = null,
  ...props
}: IBasicTableProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <TableContainer
      component={isPaperComponent ? Paper : undefined}
      classes={{ root: classes.tableRoot }}
    >
      <Table {...props} size={size}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {Array.isArray(columns) &&
              columns.map(
                (
                  { name, className, renderColumn, showSort, orderBy, key, order, handleSort },
                  index,
                ) => {
                  return (
                    <TableCell
                      tabIndex={0}
                      key={index}
                      data-testid={`column-${index}`}
                      className={`${classes.tableHeadCell} ${className}`}
                      colSpan={1}
                    >
                      {showSort ? (
                        <TableSortLabel
                          active={orderBy === key}
                          direction={orderBy === key ? order : 'asc'}
                          onClick={handleSort(key)}
                          data-testid={`${key}-label`}
                        >
                          <span>{name}</span>
                        </TableSortLabel>
                      ) : renderColumn ? (
                        renderColumn()
                      ) : (
                        <span>{name}</span>
                      )}
                    </TableCell>
                  );
                },
              )}
          </TableRow>
        </TableHead>
        <TableBody data-testid="table-body">
          {!isLoading &&
            Array.isArray(rows) &&
            rows.map((item, keyRow) => {
              let rowLinkData;
              if (onRowClick?.pathName) {
                const { searchParam, linkLabel } = onRowClick.formatLinkParams(item);
                rowLinkData = {
                  linkLabel,
                  link: {
                    pathname: onRowClick?.pathName,
                    search: searchParam,
                  },
                };
              }
              return (
                <TableRow
                  key={keyRow}
                  data-testid={`row-${keyRow}`}
                  className={`${isHoverable ? classes.tableRowHover : ''} ${classes.tableRow}`}
                  onClick={(): void => {
                    onRowClick?.actionFunction && onRowClick?.actionFunction(item);
                  }}
                  selected={
                    selectionKey && selectionId ? item[selectionKey] === selectionId : false
                  }
                >
                  {columns.map(({ key, width, render }, index) => (
                    <RailzTableCell key={index} width={width} {...rowLinkData}>
                      {render
                        ? render(
                            {
                              ...item,
                              tabIndex: 0,
                            },
                            0,
                          )
                        : item[key]}
                    </RailzTableCell>
                  ))}
                </TableRow>
              );
            })}
          {!rows?.length && !isLoading && noData && !errorLoading && (
            <TableRow>
              <TableCell colSpan={columns.length} align="center" className={classes.noData}>
                <Typography>{noData}</Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading && errorLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center" className={classes.noData}>
                {errorLoading}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {isLoading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}
    </TableContainer>
  );
};
export default BasicTable;
