import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import useStyle from './style';

import AlertDialog from 'components/dialog';

export const ToggleDataSyncWarningModal: React.FC<{
  title: string;
  message: string;
  callback: (confirm: boolean) => void;
}> = ({ title, message, callback }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <AlertDialog
      showCloseButton
      isOpen={true}
      title={t(title)}
      onClose={(): void => callback(false)}
      cancel={{
        label: t('DASHBOARD_DATA_SYNC_WARNING_MODAL_CANCEL'),
        type: 'gray',
        onClick: (): void => callback(false),
      }}
      confirm={{
        label: t('DASHBOARD_DATA_SYNC_WARNING_MODAL_CONFIRM'),
        onClick: () => callback(true),
      }}
    >
      <Typography className={classes.confirmationDescription} paragraph={true}>
        <Trans i18nKey={message} components={{ b: <strong /> }} />
      </Typography>
    </AlertDialog>
  );
};
