import { WebhookSecret, WebhookSecretRequest } from '../../../../types';

import { WebhookSecretActionCreator } from './webhook-secret.state';

import { ErrorResponse } from 'types/interface/error.interface';

/** StateWebhookSecretActions */

export const FETCH_WEBHOOK_SECRET_API = 'FETCH_WEBHOOK_SECRET_API';
export const FETCH_WEBHOOK_SECRET_SUCCESS_API = 'FETCH_WEBHOOK_SECRET_SUCCESS_API';
export const FETCH_WEBHOOK_SECRET_FAILURE_API = 'FETCH_WEBHOOK_SECRET_FAILURE_API';

export const CREATE_WEBHOOK_SECRET_API = 'CREATE_WEBHOOK_SECRET_API';
export const CREATE_WEBHOOK_SECRET_SUCCESS_API = 'CREATE_WEBHOOK_SECRET_SUCCESS_API';
export const CREATE_WEBHOOK_SECRET_FAILURE_API = 'CREATE_WEBHOOK_SECRET_FAILURE_API';

export const UPDATE_WEBHOOK_SECRET_API = 'UPDATE_WEBHOOK_SECRET_API';
export const UPDATE_WEBHOOK_SECRET_SUCCESS_API = 'UPDATE_WEBHOOK_SECRET_SUCCESS_API';
export const UPDATE_WEBHOOK_SECRET_FAILURE_API = 'UPDATE_WEBHOOK_SECRET_FAILURE_API';

export const DELETE_WEBHOOK_SECRET_API = 'DELETE_WEBHOOK_SECRET_API';
export const DELETE_WEBHOOK_SECRET_SUCCESS_API = 'DELETE_WEBHOOK_SECRET_SUCCESS_API';
export const DELETE_WEBHOOK_SECRET_FAILURE_API = 'DELETE_WEBHOOK_SECRET_FAILURE_API';
/** WebhookSecretAction Creators */
export const RESET_STORAGE_WEBHOOK_SECRET = 'RESET_STORAGE_WEBHOOK_SECRET';

export const resetWebhookSecretStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_WEBHOOK_SECRET,
  };
};

// FETCH
export const fetchWebhookSecretApi: WebhookSecretActionCreator = () => ({
  type: FETCH_WEBHOOK_SECRET_API,
});

export const fetchWebhookSecretSuccessApi: WebhookSecretActionCreator<WebhookSecret> = (
  payload,
) => ({
  type: FETCH_WEBHOOK_SECRET_SUCCESS_API,
  payload,
});

export const fetchWebhookSecretFailureApi: WebhookSecretActionCreator<ErrorResponse> = (
  payload,
) => ({
  type: FETCH_WEBHOOK_SECRET_FAILURE_API,
  payload,
});

// CREATE
export const crateWebhookSecretApi: WebhookSecretActionCreator<WebhookSecretRequest> = (
  payload,
) => ({
  type: CREATE_WEBHOOK_SECRET_API,
  payload,
});

export const createWebhookSecretSuccessApi: WebhookSecretActionCreator<
  WebhookSecret | undefined
> = (payload) => ({
  type: CREATE_WEBHOOK_SECRET_SUCCESS_API,
  payload,
});

export const createWebhookSecretFailureApi: WebhookSecretActionCreator<ErrorResponse> = (
  payload,
) => ({
  type: CREATE_WEBHOOK_SECRET_FAILURE_API,
  payload,
});

// UPDATE
export const updateWebhookSecretApi: WebhookSecretActionCreator<Partial<WebhookSecret>> = (
  payload,
) => ({
  type: UPDATE_WEBHOOK_SECRET_API,
  payload,
});

export const updateWebhookSecretSuccessApi: WebhookSecretActionCreator<WebhookSecret> = (
  payload,
) => ({
  type: UPDATE_WEBHOOK_SECRET_SUCCESS_API,
  payload,
});

export const updateWebhookSecretFailureApi: WebhookSecretActionCreator<ErrorResponse> = (
  payload,
) => ({
  type: UPDATE_WEBHOOK_SECRET_FAILURE_API,
  payload,
});

// DELETE
export const deleteWebhookSecretApi: WebhookSecretActionCreator = () => ({
  type: DELETE_WEBHOOK_SECRET_API,
});

export const deleteWebhookSecretSuccessApi: WebhookSecretActionCreator<WebhookSecret> = () => ({
  type: DELETE_WEBHOOK_SECRET_SUCCESS_API,
});

export const deleteWebhookSecretFailureApi: WebhookSecretActionCreator<ErrorResponse> = (
  payload,
) => ({
  type: DELETE_WEBHOOK_SECRET_FAILURE_API,
  payload,
});
