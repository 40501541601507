import {
  ApiResponse,
  AspType,
  BusinessCrudResponse,
  ServiceEntityState,
  ServiceType,
} from '../../../types';

import { BusinessStatus } from '../../../pages/business/types/enums';
import { Business } from '../../../pages/business/types/interfaces';
import { ReportQuery } from '../../../types/interface/report-summary.interface';

import { ErrorResponse } from 'types/interface/error.interface';

export const BUSINESS_STORE_NAME = 'business';

export const BUSINESS_INITIAL_STATE: BusinessState = {
  businesses: [],
  eventItems: [],
  loading: false,
  businessLoading: false,
  businessStats: null,
  selectedBusiness: null,
  services: [],
  error: null,
  response: null,
  meta: null,
  generatedUrl: null,
  businessSuggestions: [],
};

export interface BusinessEvent {
  apiRequestCount: number;
  date: string;
}

interface ConnectionStats {
  all: number;
  active: number;
  pending: number;
  inactive: number;
  disconnected: number;
  expired: number;
  invalid: number;
  connectionsByServiceName?: object;
  logos?: object;
}
export interface BusinessEventStat {
  businesses: {
    all: number;
    active: number;
    inactive: number;
    new: number;
    allTimeActive: number;
  };
  connections: {
    summary: ConnectionStats;
    accounting: ConnectionStats;
    banking: ConnectionStats;
    commerce: ConnectionStats;
    railzSandbox: Pick<ConnectionStats, 'active'>;
  };
  billing: {
    period: {
      startDate: Date;
      endDate: Date;
    } | null;
    usage: number;
  };
}

export interface GenerateUrlPayload {
  businessUuid: string;
}

export interface GenerateUrlResponse {
  url: string;
  expiryDate: Date;
}

export interface MetaPageData {
  offset: number;
  limit: number;
  currentPage: number;
  totalPages: number;
  count: number;
}

export interface BusinessResponseApi {
  meta: MetaPageData;
  data: Business[];
}

export interface BusinessResponse {
  meta: MetaPageData;
  businesses: Business[];
  type?: 'single' | 'all';
}

export interface SelectedBusiness extends Business {
  filter: ReportQuery;
}

export interface BusinessFilter {
  status?: BusinessStatus | BusinessStatus[];
  limit?: number;
  offset?: number;
  orderBy?: string | number | symbol;
  businessName?: string;
  businessNameOrUuid?: string;
  integrationUuids?: string[];
  type?: 'single' | 'all';
}

export interface Services {
  website: string;
  name: AspType;
  kind: ServiceType;
  beta: boolean;
  requiresCredential: boolean;
  status: ServiceEntityState;
  hasOwnSandbox?: boolean;
}

export interface DeleteBusinessParam {
  businessName: string;
}

export interface CreateBusinessParam {
  businessName: string;
}

export interface RenameBusinessParam extends CreateBusinessParam {
  uuid: string;
}

export interface BusinessesMetaState {
  businesses: Business[];
  meta: any;
  loading: boolean;
}

export interface BusinessState {
  businesses: Business[];
  selectedBusiness: Business;
  eventItems: any[];
  error: ErrorResponse;
  response: ApiResponse & Partial<BusinessCrudResponse>;
  meta: any;
  loading: boolean;
  businessLoading: boolean;
  businessStats: BusinessEventStat;
  services: Services[];
  generatedUrl: string;
  businessSuggestions: Business[];
}

export interface BusinessAction {
  payload: any;
  type: string;
}
