import { createReducer } from '@reduxjs/toolkit';

import { EXPIRES_IN } from '../../../types';

import {
  DO_CHANGE_PASSWORD_API,
  DO_CHANGE_PASSWORD_FAILURE_API,
  DO_CHANGE_PASSWORD_SUCCESS_API,
  DO_LOGIN_API,
  DO_LOGIN_FAILURE_API,
  DO_LOGIN_MICROSOFT_API,
  DO_LOGIN_MFA_API,
  DO_LOGIN_MFA_FAILURE_API,
  DO_LOGIN_MFA_SUCCESS_API,
  DO_LOGIN_SUCCESS_API,
  DO_MFA_RECOVERY_API,
  DO_MFA_RECOVERY_FAILURE_API,
  DO_MFA_RECOVERY_SUCCESS_API,
  DO_REFRESH_TOKEN_API,
  DO_REFRESH_TOKEN_FAILURE_API,
  DO_REFRESH_TOKEN_SUCCESS_API,
  DO_RESEND_EMAIL_API,
  DO_RESEND_EMAIL_FAILURE_API,
  DO_RESEND_EMAIL_SUCCESS_API,
  DO_SIGNUP_API,
  DO_SIGNUP_FAILURE_API,
  DO_SIGNUP_SUCCESS_API,
  RESET_AUTH_INFORMATION,
  RESET_AUTH_ERROR,
  RESET_EXPIRED_ON,
  RESET_AUTH_RESPONSE,
  SET_AUTH_INFORMATION,
  DO_LOGIN_GOOGLE_API,
} from './auth.action';
import { AUTH_INITIAL_STATE } from './auth.state';

/**
 * auth Reducers
 */
export const authReducer = createReducer(AUTH_INITIAL_STATE, (builder) => {
  builder
    .addCase(SET_AUTH_INFORMATION, (state, action: any) => ({ ...state, ...action.payload }))
    .addCase(RESET_AUTH_INFORMATION, () => AUTH_INITIAL_STATE)
    .addCase(RESET_AUTH_ERROR, (state) => ({
      ...state,
      error: null,
    }))
    .addCase(RESET_AUTH_RESPONSE, (state) => ({
      ...state,
      response: null,
    }))
    .addCase(RESET_EXPIRED_ON, (state) => ({
      ...state,
      expiredOn: Date.now() + EXPIRES_IN,
    }))
    .addCase(DO_LOGIN_API, (state, action: any) => ({
      ...state,
      ...AUTH_INITIAL_STATE,
      email: action?.payload?.email,
      loading: true,
    }))
    .addCase(DO_LOGIN_SUCCESS_API, (state, action: any) => ({
      ...state,
      ...action.payload,
      loading: false,
    }))
    .addCase(DO_LOGIN_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DO_LOGIN_MICROSOFT_API, (state, action: any) => ({
      ...state,
      ...AUTH_INITIAL_STATE,
      email: action?.payload?.email,
      loading: true,
    }))
    .addCase(DO_LOGIN_GOOGLE_API, (state, action: any) => ({
      ...state,
      ...AUTH_INITIAL_STATE,
      email: action?.payload?.email,
      loading: true,
    }))
    .addCase(DO_LOGIN_MFA_API, (state) => ({ ...state, loading: true, error: null }))
    .addCase(DO_LOGIN_MFA_SUCCESS_API, (state, action: any) => ({
      ...state,
      ...action.payload,
      loading: false,
    }))
    .addCase(DO_LOGIN_MFA_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DO_RESEND_EMAIL_API, (state) => ({
      ...state,
      loading: true,
      error: null,
    }))
    .addCase(DO_RESEND_EMAIL_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(DO_RESEND_EMAIL_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DO_SIGNUP_API, (state, action: any) => ({
      ...state,
      loading: true,
      email: action?.payload?.email,
      error: null,
      response: null,
    }))
    .addCase(DO_SIGNUP_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(DO_SIGNUP_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DO_REFRESH_TOKEN_API, (state) => ({
      ...state,
      loading: true,
      response: null,
      error: null,
    }))
    .addCase(DO_REFRESH_TOKEN_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: { message: 'refreshToken', success: true },
      ...action.payload,
    }))
    .addCase(DO_REFRESH_TOKEN_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DO_MFA_RECOVERY_API, (state) => ({
      ...state,
      loading: true,
      error: null,
      response: null,
    }))
    .addCase(DO_MFA_RECOVERY_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(DO_MFA_RECOVERY_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DO_CHANGE_PASSWORD_API, (state) => ({
      ...state,
      loading: true,
      error: null,
      response: null,
    }))
    .addCase(DO_CHANGE_PASSWORD_SUCCESS_API, (state) => ({
      ...state,
      loading: false,
      response: { message: 'changePassword', success: true },
    }))
    .addCase(DO_CHANGE_PASSWORD_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }));
});
