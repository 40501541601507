import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import { RailzButton } from '@railzai/railz-uikit-react';

import drawerItemStyles from './drawer-items.style';

import ExternalLink from 'assets/icons/external-link';
import { openNewTab } from 'helpers/open-new-tab';

const DrawerDocsButton: React.FC<any> = ({ drawerOpen }: { drawerOpen: boolean }) => {
  const classes = drawerItemStyles();
  const { t } = useTranslation();

  return (
    <Box className={`${classes.documentationContainer} ${!drawerOpen && 'collapsed'}`}>
      <Typography className={classes.documentationContainerTitle}>
        {t('DASHBOARD_LEARN_MORE_TITLE')}
      </Typography>
      <Typography className={classes.documentationSubTitle}>
        {t('DASHBOARD_LEARN_MORE_SUBTITLE')}
      </Typography>

      <RailzButton
        data-testid="drawer-docs-button"
        type="outlined"
        onClick={(e): void => openNewTab(e, 'https://docs.railz.ai')}
        label={t('DASHBOARD_NAV_API_DOCS')}
        grow
      />
      <a
        type="icon"
        id="doc-link"
        onClick={(e): void => openNewTab(e, 'https://docs.railz.ai')}
        rel="noopener noreferrer"
        href="https://docs.railz.ai"
        data-testid="drawer-docs-link"
      >
        <ExternalLink />
      </a>
    </Box>
  );
};

export default DrawerDocsButton;
