import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    avatarProfilePicture: {
      height: theme.spacing(15.5),
      width: theme.spacing(15.5),
      backgroundColor: '#c4c4c4',
    },
    fileUploadInput: {
      display: 'none',
    },
    uploadView: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(3),
    },
    formInput: {
      marginBottom: theme.spacing(0),
    },

    button: {
      marginTop: theme.spacing(3),
      display: 'block',
    },
  }),
);
