import React from 'react';

import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import style from './style';

import {
  getUserTeam,
  getUserTeams,
  isProfileOnEnterprise,
} from 'store/features/account/profile/profile.selector';
import { Role, TeamRole } from 'types';
import { doRefreshTokenApi, isSandbox } from 'store/features/auth';

export default function TeamSwitchSelector(): React.ReactElement {
  const classes = style();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);

  const userTeams = useSelector(getUserTeams);
  const isSandboxEnabled = useSelector(isSandbox);
  const currentTeam = useSelector(getUserTeam);
  const shouldShowSomething = useSelector(isProfileOnEnterprise);
  const shouldShowMenu = userTeams.length > 1;

  const handleClose = (): void => setOpen(false);

  const isOnSandBoxMode = (role: Role): boolean => {
    const userHasARoleWithNoSandbox = [Role.ANALYST, Role.BILLING].includes(role);
    return Boolean(isSandboxEnabled) && !userHasARoleWithNoSandbox;
  };

  const handleTeamSelection = (teamUuid: string, role: Role): void => {
    dispatch(
      doRefreshTokenApi({
        sandbox: isOnSandBoxMode(role),
        teamUuid,
      }),
    );

    handleClose();
  };

  if (!shouldShowSomething || !currentTeam) return null;
  return (
    <div data-testid="team-switch" className={classes.teamSwitch}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={(): void => setOpen((prevOpen) => !prevOpen)}
        disabled={!shouldShowMenu}
        className={classes.teamSwitchButton}
      >
        <div className={classes.teamSwitchButtonTexts}>
          <Typography
            data-testid="team-switch-current-name"
            className={classes.teamSwitchTeamNameText}
            variant="subtitle1"
            tabIndex={0}
          >
            {currentTeam?.name?.slice(0, 21)}
            {currentTeam?.name?.length > 21 ? '…' : ''}
          </Typography>
          <Typography
            data-testid="team-switch-current-role"
            className={classes.teamSwitchTeamRoleText}
            variant="subtitle2"
            tabIndex={1}
          >
            {TeamRole[currentTeam.role]}
          </Typography>
        </div>
        {shouldShowMenu && <ExpandMore data-testid="team-switch-menu-arrow" />}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }): JSX.Element => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <Typography id="limitTitle" variant="button" className={classes.teamSwitchMenuHeader}>
                {t('DASHBOARD_SWITCH_TEAMS_LABEL')}
              </Typography>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} className={classes.teamSwitchMenu} disablePadding>
                  {userTeams.map(({ uuid, name, role, current }, index) => (
                    <MenuItem
                      key={uuid}
                      data-testid={`team-switch-menu-item`}
                      className={`${classes.teamSwitchMenuItem} ${current && 'current'}`}
                      aria-label={name}
                      tabIndex={index}
                      onClick={(): void => handleTeamSelection(uuid, role)}
                    >
                      <div className={classes.teamSwitchMenuItemTexts}>
                        <Typography className={classes.teamSwitchTeamNameText} variant="subtitle1">
                          {name.slice(0, 21)}
                          {name.length > 21 ? '…' : ''}
                        </Typography>
                        <Typography className={classes.teamSwitchTeamRoleText} variant="subtitle2">
                          {TeamRole[role]}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
