import { call, put, takeLatest } from 'redux-saga/effects';

import {
  TeamsResponse,
  TeamV2Response,
  AddTeamV2Response,
  SuccessResponse,
  UpdateTeamV2Response,
  TeamMembersResponse,
} from '../../../../types';

import { removeTeamFromProfile } from '../profile/profile.action';

import { default as TeamService } from './team.service';

import { AddOrUpdateTeamMemberResponse, TeamAction } from './team.state';
import {
  ADD_TEAM_V2_API,
  DELETE_TEAM_MEMBER_FROM_TEAM_API,
  deleteTeamMemberFromTeamFailureApi,
  deleteTeamMemberFromTeamSuccessApi,
  FETCH_TEAMS_API,
  fetchTeamsSuccessApi,
  fetchTeamsFailureApi,
  FETCH_TEAM_V2_API,
  fetchTeamV2SuccessApi,
  fetchTeamV2FailureApi,
  RESEND_TEAM_MEMBER_INVITATION_API,
  resendTeamMemberInvitationFailureApi,
  resendTeamMemberInvitationSuccessApi,
  addTeamMemberSuccessApiV2,
  addTeamMemberFailureApiV2,
  ADD_TEAM_MEMBER_API_V2,
  addTeamV2SuccessApi,
  addTeamV2FailureApi,
  UPDATE_TEAM_MEMBER_API_V2,
  updateTeamMemberSuccessApiV2,
  updateTeamMemberFailureApiV2,
  updateTeamMemberRoleSuccessApi,
  updateTeamMemberRoleFailureApi,
  UPDATE_TEAM_MEMBER_ROLE_API,
  UPDATE_TEAM_V2_API,
  deleteTeamSuccessApi,
  deleteTeamFailureApi,
  DELETE_TEAM_API,
  FETCH_TEAM_MEMBERS_API,
  fetchTeamMembersSuccessApi,
  fetchTeamMembersFailureApi,
  DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_API,
} from './team.action';

function* fetchTeamMembersApiCall(action: TeamAction): Generator<any> {
  try {
    const teamMembersResponse = (yield call(
      TeamService.getTeamMembers,
      action.payload,
    ) as unknown) as TeamMembersResponse;
    yield put(fetchTeamMembersSuccessApi(teamMembersResponse as TeamMembersResponse));
  } catch (error) {
    yield put(fetchTeamMembersFailureApi(error));
  }
}

function* fetchTeamMembersSaga(): Generator<any> {
  yield takeLatest(FETCH_TEAM_MEMBERS_API, fetchTeamMembersApiCall);
}

function* fetchTeamsApiCall(action: TeamAction): Generator<any> {
  try {
    const teamsResponse = (yield call(
      TeamService.getTeams,
      action.payload,
    ) as unknown) as TeamsResponse;
    yield put(fetchTeamsSuccessApi(teamsResponse as TeamsResponse));
  } catch (error) {
    yield put(fetchTeamsFailureApi(error));
  }
}

function* fetchTeamsSaga(): Generator<any> {
  yield takeLatest(FETCH_TEAMS_API, fetchTeamsApiCall);
}

function* fetchTeamV2ApiCall({ payload }: TeamAction): Generator<any> {
  try {
    const teamResponse = yield call(TeamService.getTeamV2, payload);
    yield put(fetchTeamV2SuccessApi(teamResponse as TeamV2Response));
  } catch (error) {
    yield put(fetchTeamV2FailureApi(error));
  }
}

function* fetchTeamV2Saga(): Generator<any> {
  yield takeLatest(FETCH_TEAM_V2_API, fetchTeamV2ApiCall);
}

function* addTeamV2ApiCall({ payload }: TeamAction): Generator<any> {
  try {
    const teamResponse = yield call(TeamService.addTeamV2, payload);
    yield put(addTeamV2SuccessApi(teamResponse as AddTeamV2Response));
  } catch (error) {
    yield put(addTeamV2FailureApi(error));
  }
}

function* addTeamV2Saga(): Generator<any> {
  yield takeLatest(ADD_TEAM_V2_API, addTeamV2ApiCall);
}

function* updateTeamV2ApiCall({ payload }: TeamAction): Generator<any> {
  try {
    const teamResponse = yield call(TeamService.updateTeamV2, payload);
    yield put(addTeamV2SuccessApi(teamResponse as UpdateTeamV2Response));
  } catch (error) {
    yield put(addTeamV2FailureApi(error));
  }
}

function* updateTeamV2Saga(): Generator<any> {
  yield takeLatest(UPDATE_TEAM_V2_API, updateTeamV2ApiCall);
}

function* addTeamMemberApiCallV2({ payload }: TeamAction): Generator<any> {
  try {
    const response = yield call(TeamService.addOrUpdateTeamMemberV2, payload);
    const users = (response as AddOrUpdateTeamMemberResponse).users;
    yield put(addTeamMemberSuccessApiV2(users));
  } catch (error) {
    yield put(addTeamMemberFailureApiV2(error));
  }
}

function* inviteTeamMemberSaga(): Generator<any> {
  yield takeLatest(ADD_TEAM_MEMBER_API_V2, addTeamMemberApiCallV2);
}

function* updateTeamMemberApiCallV2({ payload }: TeamAction): Generator<any> {
  try {
    const response = yield call(TeamService.addOrUpdateTeamMemberV2, payload);
    const users = (response as AddOrUpdateTeamMemberResponse).users;
    yield put(updateTeamMemberSuccessApiV2(users));
  } catch (error) {
    yield put(updateTeamMemberFailureApiV2(error));
  }
}

function* updateTeamMemberSagaV2(): Generator<any> {
  yield takeLatest(UPDATE_TEAM_MEMBER_API_V2, updateTeamMemberApiCallV2);
}

function* updateTeamMemberRoleApiCall({ payload }: TeamAction): Generator<any> {
  try {
    const response = yield call(TeamService.updateTeamMembersRoleV2, payload);
    yield put(updateTeamMemberRoleSuccessApi(response as SuccessResponse));
  } catch (error) {
    yield put(updateTeamMemberRoleFailureApi(error));
  }
}

function* updateTeamMemberRoleSaga(): Generator<any> {
  yield takeLatest(UPDATE_TEAM_MEMBER_ROLE_API, updateTeamMemberRoleApiCall);
}

function* deleteTeamMemberFromTeamApiCall({ payload }: TeamAction): Generator<any> {
  try {
    yield call(TeamService.deleteTeamMemberFromTeam, payload.teamId, payload.userId);
    yield put(deleteTeamMemberFromTeamSuccessApi({ success: true, message: 'member delete' }));
  } catch (error) {
    yield put(deleteTeamMemberFromTeamFailureApi({ ...error, message: ['member delete'] }));
  }
}

function* deleteTeamMemberFromTeamSaga(): Generator<any> {
  yield takeLatest(DELETE_TEAM_MEMBER_FROM_TEAM_API, deleteTeamMemberFromTeamApiCall);
}

function* deleteTeamMemberFromAllTeamsApiCall({ payload }: TeamAction): Generator<any> {
  try {
    yield call(TeamService.deleteTeamMemberFromAllTeams, payload.userId);
    yield put(deleteTeamMemberFromTeamSuccessApi({ success: true, message: 'member delete' }));
  } catch (error) {
    yield put(deleteTeamMemberFromTeamFailureApi({ ...error, message: ['member delete'] }));
  }
}

function* deleteTeamMemberFromAllTeamsSaga(): Generator<any> {
  yield takeLatest(DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_API, deleteTeamMemberFromAllTeamsApiCall);
}

function* deleteTeamApiCall({ payload }: TeamAction): Generator<any> {
  try {
    yield call(TeamService.deleteTeam, payload.uuid);
    yield put(deleteTeamSuccessApi({ success: true, message: 'delete', uuid: payload.uuid }));
    yield put(removeTeamFromProfile(payload.uuid));
  } catch (error) {
    yield put(deleteTeamFailureApi({ ...error, message: 'delete' }));
  }
}

function* deleteTeamSaga(): Generator<any> {
  yield takeLatest(DELETE_TEAM_API, deleteTeamApiCall);
}

function* resendTeamMemberInvitationApiCall({ payload }: TeamAction): Generator<any> {
  try {
    yield call(TeamService.resendTeamMemberInvitation, payload.email);
    yield put(resendTeamMemberInvitationSuccessApi({ success: true, message: 'resendInvitation' }));
  } catch (error) {
    yield put(resendTeamMemberInvitationFailureApi({ ...error, message: ['resendInvitation'] }));
  }
}

function* resendTeamMemberInvitationSaga(): Generator<any> {
  yield takeLatest(RESEND_TEAM_MEMBER_INVITATION_API, resendTeamMemberInvitationApiCall);
}

export {
  fetchTeamMembersApiCall,
  fetchTeamsApiCall,
  fetchTeamV2ApiCall,
  addTeamV2ApiCall,
  addTeamMemberApiCallV2,
  updateTeamMemberApiCallV2,
  updateTeamMemberRoleApiCall,
  deleteTeamApiCall,
  deleteTeamMemberFromTeamApiCall,
  deleteTeamMemberFromAllTeamsApiCall,
  resendTeamMemberInvitationApiCall,
};
export const teamSaga = [
  fetchTeamMembersSaga(),
  fetchTeamsSaga(),
  fetchTeamV2Saga(),
  addTeamV2Saga(),
  updateTeamV2Saga(),
  inviteTeamMemberSaga(),
  updateTeamMemberSagaV2(),
  updateTeamMemberRoleSaga(),
  deleteTeamSaga(),
  deleteTeamMemberFromTeamSaga(),
  deleteTeamMemberFromAllTeamsSaga(),
  resendTeamMemberInvitationSaga(),
];
