import React, { useEffect, useCallback, useState } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { getBusinessStats } from '../store/features/business/business.selector';
import {
  getCustomerState,
  isQuestionnaireComplete,
} from '../store/features/account/customer/customer.selector';
import { isUserLoggedIn, resetAuthInformation } from '../store/features/auth';
import { fetchProfileApi } from '../store/features/account/profile/profile.action';
import { getProfileState } from '../store/features/account/profile/profile.selector';

import useQuerySearch from 'hooks/use-query-search-update';

import { fetchBusinessStats } from 'store/features/business/business.action';
import { buildIntercomClientUpdatePayload } from 'helpers/intercom.helper';
import { Plan } from 'types';

interface Props {
  roles?: Array<string>;
}

const PrivateRoute: React.FC<RouteProps & Props> = ({ component: Component, roles, ...rest }) => {
  const dispatch = useDispatch();
  const [needToBootIntercom, setIntercomAlreadyBooted] = useState(true);
  const { boot, showNewMessages, update } = useIntercom();
  const location = useLocation();
  const customer = useSelector(getCustomerState);
  const profile = useSelector(getProfileState);
  const isLoggedIn = useSelector(isUserLoggedIn);

  const storeStats = useSelector(getBusinessStats);
  const isQuestionnaireCompleted = useSelector(isQuestionnaireComplete);

  const [queryParams] = useQuerySearch();

  useEffect(() => {
    dispatch(fetchProfileApi(true));
    if (!storeStats) {
      dispatch(fetchBusinessStats());
    }
    if (queryParams.get('loadIntercom')) {
      boot();
      showNewMessages();
    }
  }, []);

  const updateWithProps = useCallback((): any => {
    if (!isLoggedIn) return;
    const payload = buildIntercomClientUpdatePayload(profile, customer, storeStats);
    if (!payload) return;

    needToBootIntercom && boot(payload); // needed for reloads
    update(payload); // needed when logging in
    setIntercomAlreadyBooted(false);
  }, [storeStats, isLoggedIn, customer, profile, needToBootIntercom]);

  useEffect(() => {
    updateWithProps();
  }, [customer, storeStats, profile]);

  const render = (props: any): React.ReactElement => {
    if (!isLoggedIn) {
      dispatch(resetAuthInformation());
      let redirectUrl = '/login';
      if (props.location.pathname !== '/' || props.location.search !== '') {
        const path = props.location.pathname;
        if (!['/questionnaire', '/select-plan'].includes(path))
          redirectUrl +=
            '?redirect=' + encodeURIComponent(props.location.pathname + props.location.search);
      }
      return <Redirect to={redirectUrl} />;
    }
    if (
      customer?.uuid &&
      customer.billingPlan === Plan.FREE &&
      !isQuestionnaireCompleted &&
      location.pathname != '/questionnaire'
    ) {
      return <Redirect to="/questionnaire" />;
    }
    const userNoPermission = roles && profile && profile?.role && !roles.includes(profile?.role);
    if (userNoPermission) {
      return <Redirect to="/" />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
