import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiKey } from '../../../../types';

import {
  fetchApiKeysSuccess,
  fetchApiKeysFailure,
  FETCH_API_KEYS,
  generateApiKeySuccess,
  generateApiKeyFailure,
  deleteApiKeySuccess,
  deleteApiKeyFailure,
  GENERATE_API_KEY,
  DELETE_API_KEY,
} from './api-key.action';
import ApiKeyService from './api-key.service';
import { ApiKeyAction } from './api-key.state';

function* fetchApiKeysCall(): Generator {
  try {
    const resultApiKeys = yield call(ApiKeyService.getApiKeys);
    yield put(fetchApiKeysSuccess(resultApiKeys as ApiKey[]));
  } catch (error) {
    yield put(fetchApiKeysFailure({ ...error, message: ['fetch'] }));
  }
}

function* fetchApiKeysSaga(): Generator {
  yield takeLatest(FETCH_API_KEYS, fetchApiKeysCall);
}

function* generateApiKeyCall(action: ApiKeyAction): Generator {
  try {
    const apiKey = yield call(ApiKeyService.createApiKey, action.payload);
    yield put(generateApiKeySuccess({ success: true, message: 'create', apiKey }));
    yield call(fetchApiKeysCall);
  } catch (error) {
    yield put(generateApiKeyFailure({ ...error, message: ['create'] }));
  }
}

function* generateApiKeySaga(): Generator {
  yield takeLatest(GENERATE_API_KEY, generateApiKeyCall);
}

function* deleteApiKeyCall(action: ApiKeyAction): Generator {
  try {
    yield call(ApiKeyService.deleteApiKey, action.payload.identifier);
    yield put(deleteApiKeySuccess({ success: true, message: 'delete', name: action.payload.name }));
    yield call(fetchApiKeysCall);
  } catch (error) {
    yield put(deleteApiKeyFailure({ ...error, message: ['delete'] }));
  }
}

function* deleteApiKeySaga(): Generator {
  yield takeLatest(DELETE_API_KEY, deleteApiKeyCall);
}

export { generateApiKeyCall, fetchApiKeysCall, deleteApiKeyCall };

export const apiKeySaga = [generateApiKeySaga(), fetchApiKeysSaga(), deleteApiKeySaga()];
