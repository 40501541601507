import { createReducer } from '@reduxjs/toolkit';

import {
  CREATE_WEBHOOK_SECRET_API,
  CREATE_WEBHOOK_SECRET_FAILURE_API,
  CREATE_WEBHOOK_SECRET_SUCCESS_API,
  UPDATE_WEBHOOK_SECRET_API,
  UPDATE_WEBHOOK_SECRET_FAILURE_API,
  UPDATE_WEBHOOK_SECRET_SUCCESS_API,
  FETCH_WEBHOOK_SECRET_API,
  FETCH_WEBHOOK_SECRET_FAILURE_API,
  FETCH_WEBHOOK_SECRET_SUCCESS_API,
  DELETE_WEBHOOK_SECRET_API,
  DELETE_WEBHOOK_SECRET_SUCCESS_API,
  DELETE_WEBHOOK_SECRET_FAILURE_API,
  RESET_STORAGE_WEBHOOK_SECRET,
} from './webhook-secret.action';
import { WEBHOOK_SECRET_INITIAL_STATE, WEBHOOK_SECRET_STORE_NAME } from './webhook-secret.state';

/**
 * webhook Secret Reducers
 */
export const webhookSecretReducer = createReducer(WEBHOOK_SECRET_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_WEBHOOK_SECRET, () => WEBHOOK_SECRET_INITIAL_STATE)
    .addCase(FETCH_WEBHOOK_SECRET_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(FETCH_WEBHOOK_SECRET_SUCCESS_API, (state, action: any) => ({
      ...state,
      webhookSecret: action.payload,
      loading: false,
    }))
    .addCase(FETCH_WEBHOOK_SECRET_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(CREATE_WEBHOOK_SECRET_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(CREATE_WEBHOOK_SECRET_SUCCESS_API, (state, action: any) => ({
      ...state,
      webhookSecret: action.payload,
      loading: false,
    }))
    .addCase(CREATE_WEBHOOK_SECRET_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(UPDATE_WEBHOOK_SECRET_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_WEBHOOK_SECRET_SUCCESS_API, (state, action: any) => ({
      ...state,
      webhookSecret: action.payload,
      loading: false,
    }))
    .addCase(UPDATE_WEBHOOK_SECRET_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(DELETE_WEBHOOK_SECRET_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_WEBHOOK_SECRET_SUCCESS_API, (state) => ({
      ...state,
      webhookSecret: null,
      loading: false,
    }))
    .addCase(DELETE_WEBHOOK_SECRET_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }));
});

export const webhookSecretReducerConfig = {
  [WEBHOOK_SECRET_STORE_NAME]: webhookSecretReducer,
};
