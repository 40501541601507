export enum EventKind {
  _name = 'WEBHOOK_KIND',
  AUTH = 'auth',
  LOGIN = 'login',
  DATA = 'data',
  PUSH = 'push',
  BATCHPUSH = 'batchPush',
  CONNECTIONSTATUS = 'connectionStatus',
  CUSTOMERREQUEST = 'customerRequest',
  DELETE = 'delete',
  DATAPERTYPE = 'dataPerType',
}

export enum WebhookLogState {
  _name = 'WEBHOOK_LOG_STATE',
  ALL = 'all',
  SUCCESS = 'success',
  FAIL = 'error',
}

export enum WebhookLogDateRange {
  _name = 'WEBHOOK_LOG_DATE_RANGE',
  TODAY = 'today',
  LAST_HOUR = 'lastHour',
  LAST_SEVEN_DAYS = 'lastSevenDays',
  LAST_THIRTY_DAYS = 'lastThirtyDays',
  LAST_NINETY_DAYS = 'lastNinetyDays',
  LAST_SIX_MONTHS = 'lastSixMonths',
}

export enum AutoDeletePeriods {
  _name = 'SETTINGS_AUTO_DELETE',
  NEVER = 'never',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
  ONE_YEAR = 'oneYear',
}
