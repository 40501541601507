import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '100%',
      padding: 0,
      height: '100%',
      overflowX: 'hidden',
      [theme.breakpoints.only('xs')]: {
        overflowX: 'auto',
      },
    },
    outerLayout: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100vw',
      height: '100%',
    },
    firstLayout: {
      margin: 'auto',
      width: '50%',
      flexGrow: 1,
      boxSizing: 'border-box',
      paddingTop: theme.spacing(2),
      paddingLeft: `clamp(${theme.spacing(4)}px, 6vw, ${theme.spacing(10)}px)`,
      paddingRight: `clamp(${theme.spacing(4)}px, 6vw, ${theme.spacing(10)}px)`,
      paddingBottom: `clamp(${theme.spacing(4)}px, 10vh, ${theme.spacing(20)}px)`,
    },
    firstLayoutContent: {
      marginLeft: 'auto',
      maxWidth: 720,
      [theme.breakpoints.down('md')]: {
        margin: 'auto',
      },
    },
    secondLayout: {
      width: '50%',
      minWidth: '500px',
      flexGrow: 1,
      backgroundColor: '#F9F9F9',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
    imageWrapper: { margin: 'auto', padding: `${theme.spacing(5)}px` },
  }),
);
