import Moment, { Moment as MomentType } from 'moment-timezone';
import { camelCase, cloneDeep, isNil, snakeCase, omit } from 'lodash';
import { AsYouType } from 'libphonenumber-js';

import i18n from '../../translations';
import { formatNumber, formatPercentage } from '../format.helper';
import {
  descendingComparator,
  DropDownMap,
  getEnumKeyByEnumValue,
  sortByCustomOrder,
} from '../common.helper';
import {
  AccountingTransactionsType,
  AspType,
  ASP_SERVICE_TYPE,
  CommerceOrderFulfilmentStatus,
  CommerceOrderPaymentStatus,
  CommerceOrderStatus,
  CommercePaymentMethodType,
  CommerceTransactionStatus,
  CommerceTransactionType,
  Country,
  DisputeReasonType,
  DisputeStatusType,
  ITableInfo,
  PaginationProps,
  ProductStatusType,
  QuarterRoundType,
  ReportFrequency,
  ReportStatus,
  ReportType,
  ServiceType,
  ReportFinancialStatementType,
  ReportTypeDataTypeMappingByServiceType,
} from '../../types';

import { ReportDataType } from '../../store/features/report/report.state';

import { reportFinancialStatementTypes } from './constants';

import {
  ActiveStatus,
  Address,
  BusinessInformation,
  BusinessValuation,
  BusinessValuationParsed,
  Credits,
  CustomerVendorDto,
  DataType,
  FinancialRatio,
  FinancialRatioInfo,
  FinancialRatioTypeEnum,
  MultipleLinesInformation,
  ProbabilityOfDefault,
  TableBusinessInfoCell,
  TableHeaderKey,
  TableMultipleLinesInfoCell,
  TableRatioCell,
  TrackingCategory,
  TransactionsInformation,
} from './types';
import { generateTranslation } from './translation-utils';

import { Business, Connection } from 'pages/business/types/interfaces';

export const getAddress = (value: Address): string => {
  let newValue = '';
  if (value?.line1) {
    newValue += value?.line1;
    newValue += '\n';
    if (value?.line2) {
      newValue += value?.line2;
      newValue += '\n';
    }
  } else if (value?.streetName) {
    if (value?.streetNumber) {
      newValue += value?.streetNumber + ' ';
    }
    newValue += value?.streetName;
    newValue += '\n';
  }
  if (value?.city) {
    newValue += value?.city + '\n';
  }
  if (value?.region || value?.state || value?.zip || value?.postalCode) {
    if (value?.region) {
      newValue += value?.region + ' ';
    }
    if (value?.state) {
      newValue += value?.state + ' ';
    }
    if (value?.zip) {
      newValue += value?.zip + ' ';
    }
    if (value?.postalCode) {
      newValue += value?.postalCode + ' ';
    }
    newValue += '\n';
  }

  if (value?.country) {
    newValue += value?.country;
  }

  return newValue;
};

export const getNameBankConnection = (connections: Array<Connection>): Array<Connection> => {
  return connections
    .filter((connection) => connection.serviceName === 'plaid' && connection.status !== 'pending')
    .map((i) => {
      const accountsCount = i?.institution?.accounts?.length;
      const multipleAccounts = accountsCount > 1;
      const multipleConnectedText = `${accountsCount} connected`;
      const singleAccount = i?.institution?.accounts[0];
      const singleAccountText = `${singleAccount?.name} - ${singleAccount?.mask}`;

      return {
        ...i,
        label: `${i?.institution?.name} - ${
          multipleAccounts ? multipleConnectedText : singleAccountText
        }`,
      };
    });
};

export const buildReportDescription = (
  reportType: ReportType,
  selectedFinancialStatementType: ReportFinancialStatementType,
  connectionUuid: string,
  selectedBusiness: Business,
): string => {
  if (
    ![
      ReportType.FINANCIAL_FORECASTS,
      ReportType.BANK_TRANSACTIONS,
      ReportType.BANK_ACCOUNTS,
      ReportType.BANK_ASSET,
    ].includes(reportType)
  )
    return null;

  if (reportType === ReportType.FINANCIAL_FORECASTS) {
    return reportFinancialStatementTypes.find((s) => s.value === selectedFinancialStatementType)
      .name;
  }

  const connections = selectedBusiness?.connections.filter(
    (s) => s.connectionId === connectionUuid,
  );
  const connection = getNameBankConnection(connections)[0];
  return connection && connection['label'];
};

export const formatBusinessInfo = (businessInfo: BusinessInformation): TableBusinessInfoCell[] => {
  const displayFields = [
    TableHeaderKey.BUSINESS_NAME,
    TableHeaderKey.LEGAL_NAME,
    TableHeaderKey.BUSINESS_REGISTRATION_NUMBER,
    TableHeaderKey.MAILING_ADDRESS,
    TableHeaderKey.LEGAL_ADDRESS,
    TableHeaderKey.BUSINESS_EMAIL,
    TableHeaderKey.BUSINESS_TYPE,
    TableHeaderKey.WEBSITE,
    TableHeaderKey.PRIMARY_PHONE_NUMBER,
    TableHeaderKey.FISCAL_YEAR_END_DAY,
    TableHeaderKey.INDUSTRY_TYPE,
    TableHeaderKey.INDUSTRY_CODE,
    TableHeaderKey.BASE_CURRENCY,
    TableHeaderKey.ACCOUNTING_METHOD,
  ];
  return Object.keys(businessInfo)
    .sort(sortByCustomOrder(displayFields))
    .filter((key) => displayFields.includes(key as TableHeaderKey))
    .map((key) => {
      let value = businessInfo[key];
      switch (key) {
        case TableHeaderKey.FISCAL_YEAR_END_DAY:
          value = `${value} ${Moment(
            businessInfo[TableHeaderKey.FISCAL_YEAR_END_MONTH],
            'MM',
          ).format('MMMM')}`;
          break;
        case TableHeaderKey.LEGAL_ADDRESS:
        case TableHeaderKey.MAILING_ADDRESS:
          value = getAddress(value);
          break;
        default:
          break;
      }
      return {
        businessAttribute: i18n.t(
          `DASHBOARD_ENUM_REPORT_TABLE_HEADER_${getEnumKeyByEnumValue(
            TableHeaderKey,
            key as TableHeaderKey,
          )}`,
        ),
        value,
      };
    });
};

export const formatTransactions = (
  transactionsInfo: TransactionsInformation[],
  serviceName: AspType,
): TransactionsInformation[] => {
  return transactionsInfo.map((transaction) => {
    const isFbOrQbo = [AspType.FRESHBOOKS, AspType.QUICKBOOKS].includes(serviceName);
    return {
      [TableHeaderKey.SUB_TOTAL]: isFbOrQbo
        ? i18n.t('DASHBOARD_REPORT_TABLE_NA')
        : transaction[TableHeaderKey.SUB_TOTAL],
      [TableHeaderKey.TAX]: isFbOrQbo
        ? i18n.t('DASHBOARD_REPORT_TABLE_NA')
        : transaction[TableHeaderKey.TAX],
      ...transaction,
    };
  });
};

export const formatBusinessValuations = (
  businessValuations: BusinessValuation[],
): BusinessValuationParsed[] =>
  businessValuations?.map((businessValuation) => ({
    [TableHeaderKey.END_DATE]: new Date(businessValuation.meta.endDate),
    [TableHeaderKey.LIQUIDATION_VALUE]: `$${formatValue(
      businessValuation.data.liquidationValue,
      DataType.NUMBER,
    )}`,
    [TableHeaderKey.DISCOUNTED_CASHFLOW_VALUE]: `$${formatValue(
      businessValuation.data.discountedCashflowValue,
      DataType.NUMBER,
    )}`,
    [TableHeaderKey.DISCOUNT_RATE]: `${formatValue(
      businessValuation.data.discountRate,
      DataType.NUMBER,
    )}`,
    [TableHeaderKey.MULTIPLE_TO_REVENUE_VALUE]: `$${formatValue(
      businessValuation.data.multipleToRevenueValue,
      DataType.NUMBER,
    )}`,
    [TableHeaderKey.REVENUE_MULTIPLE]: `${formatValue(
      businessValuation.data.revenueMultiple,
      DataType.NUMBER,
    )}`,
    [TableHeaderKey.FIRST_CHICAGO_VALUE]: `$${formatValue(
      businessValuation.data.firstChicagoValue,
      DataType.NUMBER,
    )}`,
  }));

export const formatTrackingCategories = (
  trackingCategories: TrackingCategory[],
): TrackingCategory[] =>
  trackingCategories?.map((trackingCategory) => ({
    ...trackingCategory,
    [TableHeaderKey.STATUS]: generateTranslation(ActiveStatus)[trackingCategory.status],
  }));

export const formatMultipleLines = (
  multipleLinesInfo: MultipleLinesInformation[],
): TableMultipleLinesInfoCell[] => {
  const toReturn = [];
  multipleLinesInfo.forEach((multipleLines) => {
    multipleLines.lines?.forEach((line) => {
      toReturn.push({
        ...multipleLines,
        ...line,
        totalAmount: formatNumber(line.totalAmount as number),
        debit: formatNumber(line.debit as number),
        credit: formatNumber(line.credit as number),
      });
    });
  });
  return toReturn;
};

export const formatFinancialRatios = (financialRatioInfo: FinancialRatioInfo): TableRatioCell[] => {
  const financialRatioType = financialRatioInfo.data;
  const { endDate } = financialRatioInfo.meta;
  const ratios = [];
  Object.keys(financialRatioType).forEach((type) => {
    const ratioType = i18n.t(
      `DASHBOARD_ENUM_REPORT_FINANCIAL_RATIO_TYPE_${getEnumKeyByEnumValue(
        FinancialRatioTypeEnum,
        type as FinancialRatioTypeEnum,
      )}`,
    );
    const ratioInfo = financialRatioType[type];
    Object.keys(ratioInfo).forEach((ratioKey) => {
      const ratio = i18n.t(
        `DASHBOARD_ENUM_REPORT_FINANCIAL_RATIO_${getEnumKeyByEnumValue(
          FinancialRatio,
          ratioKey as FinancialRatio,
        )}`,
      );
      let ratioValue = cloneDeep(ratioInfo[ratioKey]);
      switch (ratioKey) {
        case FinancialRatio.LIQUIDATION_VALUE:
        case FinancialRatio.GROSS_BURN_RATE:
        case FinancialRatio.ABSOLUTE_LIQUIDITY:
        case FinancialRatio.GROSS_BURN:
        case FinancialRatio.DEBT_TO_ENTERPRISE_VALUE:
        case FinancialRatio.ENTERPRISE_VALUE:
        case FinancialRatio.EBITDA:
        case FinancialRatio.FREE_CASHFLOW:
        case FinancialRatio.WORKING_CAPITAL:
          ratioValue = `$${formatValue(ratioValue, DataType.NUMBER, 2)}`;
          break;
        case FinancialRatio.PROBABILITY_OF_DEFAULT:
        case FinancialRatio.INTEREST_BANK_LOAN:
        case FinancialRatio.EBITDA_MARGIN:
        case FinancialRatio.GROSS_MARGIN:
        case FinancialRatio.NET_PROFIT_MARGIN:
        case FinancialRatio.OPERATING_MARGIN:
        case FinancialRatio.RETURN_ON_EQUITY:
          ratioValue = `${formatValue(ratioValue * 100, DataType.NUMBER, 2)}%`;
          break;
        default:
          ratioValue = `${formatValue(ratioValue, DataType.NUMBER, 2)}`;
      }
      ratios.push({ endDate, ratioType, ratio, ratioValue, originalValue: ratioInfo[ratioKey] });
    });
  });
  return ratios;
};
export const formatCustomerVendor = (customerVendor: CustomerVendorDto): CustomerVendorDto => {
  const { addresses } = customerVendor;
  let { phone } = customerVendor;
  const isUsOrCa =
    addresses &&
    addresses.some(
      (address) => address.country === Country.CANADA || address.country === Country.USA,
    );
  if (isUsOrCa && !!phone) {
    phone = new AsYouType('US').input(phone);
  }
  return { ...customerVendor, phone };
};

export const formatProbabilityOfDefault = (
  defaultsInfo: ProbabilityOfDefault[],
): TransactionsInformation[] => {
  return defaultsInfo?.reduce((parentValue, probability) => {
    const models = Object.keys(probability.data).map((probabilityKey) => ({
      date: probability.meta.endDate,
      model: i18n.t(`DASHBOARD_ENUM_REPORT_TABLE_LABEL_${probabilityKey.toUpperCase()}`),
      value: formatNumber(probability.data[probabilityKey]),
    }));
    return parentValue.concat(models);
  }, []);
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatCredits = (data: Credits): TransactionsInformation[] => {
  const toReturn = [];
  if (data?.reports) {
    for (const item of data.reports) {
      toReturn.push({ ...item.data, ...item?.meta });
    }
  }
  return toReturn;
};

const STATUSES_KEYS = {
  [DataType.STATUS]: ReportStatus,
  [DataType.PRODUCT_STATUS]: ProductStatusType,
  [DataType.DISPUTE_STATUS]: DisputeStatusType,
  [DataType.DISPUTE_REASON]: DisputeReasonType,
  [DataType.COMMERCE_TRANSACTION_STATUS]: CommerceTransactionStatus,
  [DataType.COMMERCE_TRANSACTION_TYPE]: CommerceTransactionType,
  [DataType.COMMERCE_ORDER_STATUS]: CommerceOrderStatus,
  [DataType.COMMERCE_ORDER_PAYMENT_STATUS]: CommerceOrderPaymentStatus,
  [DataType.COMMERCE_ORDER_FULFILMENT_STATUS]: CommerceOrderFulfilmentStatus,
  [DataType.COMMERCE_PAYMENT_METHOD]: CommercePaymentMethodType,
  [DataType.ACC_TRANSACTION_TYPE]: AccountingTransactionsType,
};

function getEnumTranslatedValue(type: DataType, value: string): string {
  if (!value) return '';

  const key = `DASHBOARD_ENUM_${String(type).toUpperCase()}_${
    getEnumKeyByEnumValue(STATUSES_KEYS[type], camelCase(value)) as string
  }`;

  return i18n.t(key);
}

export const formatValue = (
  value: number | string | Array<string>,
  type: string,
  decimals = 2,
  key: string = null,
): string => {
  if (value === 'N/A') return value;
  switch (type) {
    case DataType.ARRAY:
      return value && typeof value === 'object' ? value.join(', ') : '';
    case DataType.DATE:
      return value ? Moment(value).utc().format('DD MMM, YYYY') : '';
    case DataType.SOURCE_MODIFIED_DATE:
      return value ? Moment(value).utc().format('DD MMM, YYYY') : '-';
    case DataType.NUMBER:
      if (!isNil(value)) {
        if (value === 0 && (key === 'debit' || key === 'credit')) {
          return i18n.t('DASHBOARD_REPORT_TABLE_NA');
        }
        return formatNumber(value as number, decimals);
      }

      return '';
    case DataType.PAYMENT_METHODS_STATUS:
      return (isNil(value) ? '-' : value) as string;
    case DataType.BOOLEAN:
      return Boolean(value) === true ? i18n.t('DASHBOARD_YES') : i18n.t('DASHBOARD_NO');
    case DataType.STATUS:
    case DataType.ACC_TRANSACTION_TYPE:
    case DataType.PRODUCT_STATUS:
    case DataType.DISPUTE_STATUS:
    case DataType.DISPUTE_REASON:
    case DataType.COMMERCE_TRANSACTION_STATUS:
    case DataType.COMMERCE_TRANSACTION_TYPE:
    case DataType.COMMERCE_ORDER_STATUS:
    case DataType.COMMERCE_ORDER_PAYMENT_STATUS:
    case DataType.COMMERCE_ORDER_FULFILMENT_STATUS:
    case DataType.COMMERCE_PAYMENT_METHOD:
      return getEnumTranslatedValue(type, value as string);
    case DataType.NUMBER_YTD_0:
      return (value as number) > 0
        ? formatNumber(value as number)
        : i18n.t('DASHBOARD_REPORT_TABLE_NA');
    case DataType.NUMBER_YTD:
      return (value as number) > 0
        ? formatNumber(value as number)
        : i18n.t('DASHBOARD_REPORT_TABLE_NA');
    case DataType.PERCENTAGE:
      return formatPercentage(value as number);
    case DataType.PERCENTAGE_OVER_100:
      return formatPercentage((value as number) / 100);
    case DataType.CURRENCY:
      return formatNumber(value as number) === '' ? '-' : '$' + formatNumber(value as number);
    case DataType.UPLOAD_DATE:
      return value ? Moment(value).utc().format('DD MMM, YYYY') : '-';
    case DataType.FILE_NAME:
    case DataType.CONTENT_TYPE:
    case DataType.FILE_SIZE:
    case DataType.STRING_WITH_DASH_FALLBACK:
      return value ? (value as string) : '-';
    case DataType.CAPITALIZED_STRING:
      return value ? String(value).charAt(0).toUpperCase() + String(value).slice(1) : '';
    case DataType.NUMBER_WITH_0_FALLBACK:
      return value ? String(value) : '0.00';
    default:
      return value as string;
  }
};

const REPORTS_HIDE_FREQUENCY = [
  ReportType.DEPOSITS,
  ReportType.ESTIMATES,
  ReportType.INVOICES,
  ReportType.BILLS,
  ReportType.INVOICE_CREDIT_NOTES,
  ReportType.BILL_CREDIT_NOTES,
  ReportType.PURCHASE_ORDERS,
  ReportType.BANK_ACCOUNTS,
  ReportType.BANK_ASSET,
];
export const showFrequency = (type: ReportType | string): boolean => {
  return !REPORTS_HIDE_FREQUENCY.includes(type as ReportType);
};

export const showStatus = (type: ReportType | string): boolean => {
  switch (type) {
    case ReportType.DEPOSITS:
    case ReportType.ESTIMATES:
    case ReportType.INVOICES:
    case ReportType.BILLS:
    case ReportType.INVOICE_CREDIT_NOTES:
    case ReportType.BILL_CREDIT_NOTES:
    case ReportType.PURCHASE_ORDERS:
      return true;
    default:
      return false;
  }
};

export const showFinancialStatementType = (type: ReportType | string): boolean => {
  return type === ReportType.FINANCIAL_FORECASTS;
};

export const showDateRangeFilterIndicator = (type: ReportType): boolean => {
  if (disableDate(type)) return false;
  return ![ReportType.FINANCIAL_SUMMARY, ReportType.BANK_RECONCILIATION].includes(type);
};

const REPORTS_DISABLE_FREQUENCY = [
  ReportType.ACCOUNTS,
  ReportType.ATTACHMENTS,
  ReportType.BANK_TRANSFER,
  ReportType.BILL_PAYMENTS,
  ReportType.BILL_PAYMENT_REQUEST,
  ReportType.BUSINESS_INFO,
  ReportType.BUSINESS_VALUATIONS,
  ReportType.CUSTOMERS,
  ReportType.EMPLOYEES,
  ReportType.INVENTORY,
  ReportType.INVOICE_PAYMENTS,
  ReportType.JOURNAL_ENTRIES,
  ReportType.PURCHASE_ORDERS,
  ReportType.REFUNDS,
  ReportType.TAX_RATES,
  ReportType.TRACKING_CATEGORIES,
  ReportType.ACCOUNTING_TRANSACTIONS,
  ReportType.TRANSACTIONS,
  ReportType.VENDORS,
  ReportType.BANK_ACCOUNTS,
  ReportType.BANK_TRANSACTIONS,
  ReportType.PAYMENT_METHODS,
  ReportType.PROBABILITY_OF_DEFAULT,
  ReportType.BANKING_RECONCILIATION,
  ReportType.CREDITS,
  ReportType.PRODUCTS,
  ReportType.DISPUTES,
  ReportType.ORDERS,
  ReportType.ACCOUNTING_BANK_TRANSACTIONS,
  ReportType.ACCOUNTING_BANK_ACCOUNTS,
  ReportType.EXPENSES,
];
export const disableFrequency = (type: ReportType | string): boolean => {
  return REPORTS_DISABLE_FREQUENCY.includes(type as ReportType);
};
const REPORTS_ACCOUNTING_METHOD = [
  ReportType.BALANCE_SHEETS,
  ReportType.TRIAL_BALANCES,
  ReportType.INCOME_STATEMENTS,
];
export const disableAccountingMethod = (type: ReportType | string): boolean => {
  return !REPORTS_ACCOUNTING_METHOD.includes(type as ReportType);
};

const ASP_ACCOUNTING_METHOD = [AspType.QUICKBOOKS, AspType.FRESHBOOKS, AspType.XERO];
export const disableAccountingMethodAsp = (type: AspType | string): boolean => {
  return !ASP_ACCOUNTING_METHOD.includes(type as AspType);
};

export const noFrequency = (type: ReportType | string): boolean => {
  return [...REPORTS_DISABLE_FREQUENCY, ...REPORTS_HIDE_FREQUENCY].includes(type as ReportType);
};

export const hasDefaultSpacing = (type: ReportType): boolean => {
  return [ReportType.FINANCIAL_SUMMARY].includes(type);
};

export const useDefaultBusinessConnection = (type?: ReportType): boolean => {
  if (!type) return true;
  return [ReportType.FINANCIAL_SUMMARY, ReportType.BANK_RECONCILIATION].includes(type);
};

export const disableDate = (type: ReportType | string): boolean => {
  switch (type) {
    case ReportType.ACCOUNTS:
    case ReportType.BUSINESS_INFO:
    case ReportType.CUSTOMERS:
    case ReportType.INVENTORY:
    case ReportType.TAX_RATES:
    case ReportType.VENDORS:
    case ReportType.EMPLOYEES:
    case ReportType.TRACKING_CATEGORIES:
    case ReportType.BANK_ACCOUNTS:
    case ReportType.ACCOUNTING_BANK_ACCOUNTS:
    case ReportType.PAYMENT_METHODS:
    case ReportType.ATTACHMENTS:
    case ReportType.BANK_ASSET:
      return true;
    default:
      return false;
  }
};

export const wordWrap = (str = '', maxWidth = 10): string => {
  const testWhite = (x: string): boolean => {
    const white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  };
  const testDash = (x: string): boolean => {
    const dash = new RegExp(/^-$/);
    return dash.test(x.charAt(0));
  };
  const newLineStr = '\n';
  let found = false;
  let res = '';
  while (str.length > maxWidth) {
    found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join('');
        str = str.slice(i + 1);
        found = true;
        break;
      }
      if (testDash(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join('') + '-';
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('');
      str = str.slice(maxWidth);
    }
  }

  return res + str;
};

export const pdfConfig = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  title: string,
  headerTable: any[],
  widths: string[] = [50, ...Array(headerTable.length - 1).fill('auto')],
): ITableInfo => ({
  pageOrientation: 'landscape',
  content: [
    { text: title, style: 'header' },
    {
      style: 'tableExample',
      table: {
        headerRows: 1,
        body: [
          ((): any => headerTable.map(({ name }) => ({ text: name, style: 'tableHeader' })))(),
          ...data,
        ],
        widths,
      },
      layout: {
        hLineWidth: function (i, node): boolean | number {
          return i === 0 || i === node.table.body.length ? 2 : 1;
        },
        vLineWidth: function (i, node): boolean | number {
          return i === 0 || i === node.table.widths.length ? 2 : 1;
        },
        hLineColor: function (i, node): boolean | string {
          return i === 0 || i === node.table.body.length ? 'black' : 'gray';
        },
        vLineColor: function (i, node): boolean | string {
          return i === 0 || i === node.table.widths.length ? 'black' : 'gray';
        },
      },
    },
  ],
  styles: {
    header: {
      fontSize: 12,
      bold: true,
      margin: [0, 0, 0, 10],
    },
    subheader: {
      fontSize: 10,
      bold: true,
      margin: [0, 10, 0, 5],
    },
    tableExample: {
      margin: [0, 5, 0, 15],
    },
    tableHeader: {
      bold: true,
      fontSize: 10,
      color: 'black',
    },
  },
  defaultStyle: {
    fontSize: 10,
  },
});

export const getDateQuarter = (date: MomentType, roundTo: QuarterRoundType): MomentType => {
  if (roundTo === 'start') {
    return date
      .clone()
      .quarter(date.quarter())
      .subtract(1, ReportFrequency.QUARTER)
      .startOf(ReportFrequency.QUARTER);
  }
  return date.clone().quarter(date.quarter()).endOf(ReportFrequency.QUARTER);
};

export const formatReportData = ({
  data,
  selectedReportType,
}: {
  data: any;
  selectedReportType: string;
  serviceName?: AspType;
}): any[] => {
  let mergedData = [];
  switch (selectedReportType) {
    case ReportType.ACCOUNTS:
    case ReportType.ACCOUNTING_TRANSACTIONS:
    case ReportType.BANK_TRANSFER:
    case ReportType.INVENTORY:
    case ReportType.TAX_RATES:
    case ReportType.PRODUCTS:
    case ReportType.DISPUTES:
    case ReportType.EXPENSES:
      mergedData = mergedData.concat(data?.data);
      break;
    case ReportType.CUSTOMERS:
    case ReportType.VENDORS:
      if (!isNil(data?.data)) {
        mergedData.push(
          ...data.data.map((x) => ({
            ...data.meta,
            ...formatCustomerVendor(x),
          })),
        );
      }
      break;
    case ReportType.BUSINESS_INFO:
      mergedData = formatBusinessInfo(data.data);
      break;
    case ReportType.JOURNAL_ENTRIES:
    case ReportType.DEPOSITS:
      mergedData = formatMultipleLines(data.data);
      break;
    case ReportType.BUSINESS_VALUATIONS:
      mergedData = formatBusinessValuations(data?.reports);
      break;
    case ReportType.TRANSACTIONS:
    case ReportType.ORDERS:
      if (Array.isArray(data.data)) {
        mergedData = data.data;
      }
      break;
    case ReportType.FINANCIAL_RATIOS:
      if (data?.ratios) {
        for (const item of data.ratios) {
          mergedData = mergedData.concat(formatFinancialRatios(item));
        }
      }
      break;
    case ReportType.TRACKING_CATEGORIES:
      mergedData = formatTrackingCategories(data?.data);
      break;
    case ReportType.BANK_ACCOUNTS:
    case ReportType.BILLS:
    case ReportType.BILL_CREDIT_NOTES:
    case ReportType.INVOICES:
    case ReportType.INVOICE_PAYMENTS:
    case ReportType.EMPLOYEES:
    case ReportType.INVOICE_CREDIT_NOTES:
    case ReportType.ESTIMATES:
    case ReportType.PURCHASE_ORDERS:
    case ReportType.REFUNDS:
    case ReportType.BILL_PAYMENT_REQUEST:
      mergedData = data?.data;
      break;
    case ReportType.BILL_PAYMENTS:
      mergedData = data?.data?.map((payments) => ({ ...payments, paymentDate: payments.date }));
      break;
    case ReportType.ACCOUNTING_BANK_ACCOUNTS:
      mergedData = data?.data?.map((account) => ({
        ...account,
        institutionNameAndBankCode: [account.institutionName, account.bankCode]
          .filter(Boolean)
          .join(' - '),
      }));
      break;
    case ReportType.PROBABILITY_OF_DEFAULT:
      mergedData = formatProbabilityOfDefault(data?.reports);
      break;
    case ReportType.CREDITS:
      mergedData = formatCredits(data);
      break;
    case ReportType.FINANCIAL_FORECASTS:
      if (data?.reports) {
        for (const item of data.reports) {
          mergedData.push(
            ...(item.data || []).map((x) => ({
              ...x,
              ...omit(item?.meta, 'endDate'),
            })),
          );
        }
      }
      break;
    case ReportType.PAYMENT_METHODS:
    case ReportType.ATTACHMENTS:
    case ReportType.ACCOUNTING_BANK_TRANSACTIONS:
    case ReportType.BANK_TRANSACTIONS:
    case ReportType.BANK_ASSET:
      if (!isNil(data?.data)) {
        mergedData.push(
          ...data.data.map((x) => ({
            ...data.meta,
            ...x,
          })),
        );
      }
      break;
    default:
      if (data?.reports) {
        for (const item of data.reports) {
          mergedData.push(
            ...(item.data || []).map((x) => ({
              ...x,
              ...item?.meta,
            })),
          );
        }
      }
  }

  return mergedData;
};

export const formatDataType = (
  name: string,
  dataTypes: ReportDataType[],
  serviceType: ServiceType,
): Array<DropDownMap> => {
  return dataTypes
    .filter(
      (dataType) =>
        dataType.action.includes('pull') &&
        i18n.exists(`DASHBOARD_ENUM_${name}_${snakeCase(dataType.name).toUpperCase()}`),
    )
    .map((dataType) => ({
      name: i18n.t([`DASHBOARD_ENUM_${name}_${snakeCase(dataType.name).toUpperCase()}`]),
      value: dataType.name,
      reportType: dataType.reportType,
      serviceType: serviceType,
    }))
    .sort((a, b) => descendingComparator(b, a, 'name'));
};

export function addPageStatsToPagination(
  pagination: PaginationProps,
  customLimit?: number,
): PaginationProps {
  const limit = customLimit ? customLimit : pagination?.limit;
  const totalPages = Math.ceil(pagination?.count / limit) || 1;
  const currentPage = pagination?.offset / limit + 1 || 1;
  return { ...pagination, currentPage, totalPages };
}

export const checkBusinessHasAccountingAndBanking = (selectedBusiness: Business): boolean => {
  try {
    let hasAccounting = false;
    let hasBank = false;
    for (const connection of selectedBusiness.connections) {
      if (ASP_SERVICE_TYPE[connection.serviceName] === ServiceType.ACCOUNTING) {
        hasAccounting = true;
      } else if (ASP_SERVICE_TYPE[connection.serviceName] === ServiceType.BANKING) {
        hasBank = true;
      }
    }
    return hasAccounting && hasBank;
  } catch (e) {
    return false;
  }
};

export function getReportUniqueIdentifier(
  report: Record<'value', ReportType>,
  serviceType: ServiceType,
): ReportType {
  if (!serviceType) return report.value;
  if (!ReportTypeDataTypeMappingByServiceType[serviceType]?.[report.value]) return report.value;
  const mapper = ReportTypeDataTypeMappingByServiceType[serviceType][report.value];

  return mapper.uniqueValue || (mapper.value as ReportType) || report.value;
}

const setTimeToMidnight = (date: Date): Date => {
  const localDate = date;
  localDate.setHours(0);
  localDate.setMinutes(0);
  localDate.setSeconds(0);
  localDate.setMilliseconds(0);
  return localDate;
};

export function isOutsideDateRange(
  checkStartDate: boolean,
  dateString: string,
  minDate: Date,
  maxDate: Date,
): boolean {
  let dateVal = new Date(dateString);
  dateVal = setTimeToMidnight(dateVal);
  const minDateVal = setTimeToMidnight(minDate);

  if (checkStartDate) {
    if (dateVal >= minDateVal) return false;
  } else {
    if (dateVal <= maxDate && dateVal >= minDateVal) return false;
  }
  return true;
}
