import React from 'react';

import { Switch, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import useStyle from './style';

import { View } from 'components';

interface Props {
  onChange?: any;
  enabled?: boolean;
  disabled?: boolean;
}

export const Toggle: React.FC<Props> = ({
  onChange,
  disabled = false,
  enabled = true,
}): React.ReactElement => {
  const { t } = useTranslation();

  const classes = useStyle();

  return (
    <View flexDirection="row" className={disabled && classes.toggleDisabled}>
      <Typography className={classes.toggleTitle} color={enabled ? 'primary' : 'textSecondary'}>
        {enabled ? t('DASHBOARD_TOGGLE_DISABLE') : t('DASHBOARD_TOGGLE_ENABLE')}
      </Typography>
      <Switch
        checked={enabled}
        inputProps={{
          'aria-label': `${t(enabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE')}`,
        }}
        onChange={(_, checked): Promise<void> => onChange(checked)}
        color="primary"
        className={classes.checkboxContainer}
      />
    </View>
  );
};
