import React, { useEffect, useState } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getConnectionServiceName } from 'helpers/common.helper';
import { Service } from 'helpers/report-helpers/types';

interface Props {
  services: Service[];
  selectedService: Service;
  errorService?: string;
  handleSelected: (string) => void;
}

export default function ReportFilterServices({
  services,
  selectedService: parentSelectedService,
  errorService,
  handleSelected,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedService, setSelectedService] = useState<Service>();

  useEffect(() => {
    if (parentSelectedService) {
      const serviceSelected = services.find(
        ({ connectionId }) => connectionId === parentSelectedService.connectionId,
      );
      setSelectedService(serviceSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentSelectedService]);

  const onChange = (event): void => {
    const serviceSelected = services.find(
      ({ connectionId }) => connectionId === event.target.value,
    );
    setSelectedService(serviceSelected);
    handleSelected && handleSelected(serviceSelected);
  };

  return (
    <TextField
      fullWidth
      label={t('DASHBOARD_REPORT_SERVICE_LABEL')}
      placeholder={t('DASHBOARD_REPORT_SERVICE_PLACEHOLDER')}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      select
      value={selectedService?.connectionId || ''}
      onChange={onChange}
      size="small"
      error={!!errorService}
      helperText={t(errorService)}
      FormHelperTextProps={{
        className: 'error-text-custom',
      }}
      data-testid="services-text-input"
      inputProps={{
        'data-testid': 'services-select-input',
      }}
    >
      {services.map((item, index) => (
        <MenuItem key={index} value={item.connectionId}>
          {getConnectionServiceName(item)}
        </MenuItem>
      ))}
    </TextField>
  );
}
