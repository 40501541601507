import * as xlsx from 'xlsx';
import FileSaver from 'file-saver';
import pdfmake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { camelCase } from 'lodash';

import { ExportFileType } from './constants';
import { TableHeaderKey } from './types';
import { formatValue, pdfConfig, wordWrap } from './utils';

import { ITableField } from 'types';
import { getNestedProperty } from 'helpers/common.helper';

type Exporter = (
  data: any[],
  fileName: string,
  headerTable: ITableField[],
  type: ExportFileType,
) => void;

export const generateDownloadableReport = (data: any[], headerTable = [], json = false): any[] =>
  data?.length > 0
    ? data.map((item) => {
        const result = {};
        headerTable.forEach(({ keys, name, type, key }) => {
          switch (key) {
            case TableHeaderKey.RATIO_VALUE:
              // eslint-disable-next-line no-case-declarations
              const nestedProperty = getNestedProperty(item, ['originalValue']);
              result[json ? camelCase(name) : name] = formatValue(
                json ? nestedProperty : String(nestedProperty),
                type,
              );
              break;
            default:
              result[json ? camelCase(name) : name] = formatValue(
                getNestedProperty(item, keys),
                type,
              );
          }
        });
        return result;
      })
    : [];

const saveDataToSheet: Exporter = (data, fileName, headerTable, type) => {
  const splitFileName = fileName.split('_');
  const sheetName = splitFileName.length > 1 ? splitFileName[splitFileName.length - 1] : fileName;

  const sheet = xlsx.utils.json_to_sheet(generateDownloadableReport(data, headerTable));
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, sheet, sheetName.slice(0, 31));
  xlsx.writeFile(wb, `${fileName}.${type}`);
};

const saveReportToPdf: Exporter = (report, fileName, hdTable) => {
  pdfmake.vfs = pdfFonts.pdfMake.vfs;

  const doc = pdfConfig(
    report.map((item) =>
      hdTable.map(({ keys, type, key }: any) => {
        const MAX_CHARACTERS = 200;
        const columnSize = Math.floor(MAX_CHARACTERS / hdTable.length);
        if (key === TableHeaderKey.RATIO_VALUE) {
          return wordWrap(
            formatValue(String(getNestedProperty(item, ['originalValue'])), type) || '',
            columnSize,
          );
        }
        return wordWrap(formatValue(getNestedProperty(item, keys), type) || '', columnSize);
      }),
    ),
    fileName,
    hdTable,
  );
  pdfmake.createPdf(doc).download(fileName);
};

const saveReportToJson: Exporter = (report, fileName, hdTable) => {
  const fileToSave = new Blob([JSON.stringify(generateDownloadableReport(report, hdTable, true))], {
    type: 'application/json',
  });
  FileSaver.saveAs(fileToSave, `${fileName}.json`);
};

const fileExporters: Record<ExportFileType, Exporter> = {
  pdf: saveReportToPdf,
  xlsx: saveDataToSheet,
  csv: saveDataToSheet,
  json: saveReportToJson,
};

export const exportFileFunction: Exporter = (
  data: any[],
  fileName: string,
  headerTable = [],
  type = ExportFileType.xlsx,
): void => {
  const exporterFunction = fileExporters[type];
  exporterFunction(data, fileName, headerTable, type);
};
