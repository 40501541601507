import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      maxWidth: theme.spacing(58),
    },
    main: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: 'auto',
      overflowX: 'hidden',
      padding: `${theme.spacing(2)}px clamp(${theme.spacing(2)}px, 4vw, ${theme.spacing(10)}px)`,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(58),
      },
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      marginTop: `clamp(${theme.spacing(2)}px, 4vw, ${theme.spacing(10)}px)`,
      paddingBottom: theme.spacing(1),
    },
    description: {
      marginBottom: theme.spacing(4.375),
    },
    logoIcon: {
      width: theme.spacing(11.25),
      height: theme.spacing(3.625),
    },
    formGrid: {
      paddingBottom: theme.spacing(0.875),
    },
    radioInputs: {
      '&.MuiFormGroup-root': {
        gap: theme.spacing(4),
      },
    },
    logout: {
      display: 'flex',
      gap: theme.spacing(3),
      fontSize: '1rem',
      fontWeight: 500,
      paddingTop: theme.spacing(1.5),
      cursor: 'pointer',
      width: 'fit-content',
    },
    cta: {
      paddingTop: theme.spacing(2),
    },
    illustration: {
      position: 'fixed',
      height: 'clamp(280px, 35%, 447px)',
      width: 'clamp(280px, 70%, 600px)',
      top: 'clamp(-120px, -90%, -447px)',
      right: '-5%',
      zIndex: -1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        objectFit: 'contain',
        maxWidth: '100%',
        width: '100%',
      },
    },
    mainContainer: {
      margin: 'auto',
    },
    errorText: {
      marginRight: theme.spacing(1.75),
      marginLeft: theme.spacing(1.75),
    },
  }),
);
