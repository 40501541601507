import { AspType, ReportType, ReportTypeDataTypeMapping, ServiceType } from './enum';

export const ASP_SERVICE_TYPE = {
  [AspType.QUICKBOOKS]: ServiceType.ACCOUNTING,
  [AspType.QUICKBOOKS_DESKTOP]: ServiceType.ACCOUNTING,
  [AspType.ORACLE_NETSUITE]: ServiceType.ACCOUNTING,
  [AspType.SAGE_BUSINESS_CLOUD]: ServiceType.ACCOUNTING,
  [AspType.SAGE_INTACCT]: ServiceType.ACCOUNTING,
  [AspType.FRESHBOOKS]: ServiceType.ACCOUNTING,
  [AspType.XERO]: ServiceType.ACCOUNTING,
  [AspType.DYNAMICS_BUSINESS_CENTRAL]: ServiceType.ACCOUNTING,
  [AspType.WAVE]: ServiceType.ACCOUNTING,
  [AspType.MYOB]: ServiceType.ACCOUNTING,
  [AspType.ZOHO_BOOKS]: ServiceType.ACCOUNTING,
  [AspType.SANDBOX]: ServiceType.COMMERCE,
  [AspType.PLAID]: ServiceType.BANKING,
  [AspType.SHOPIFY]: ServiceType.COMMERCE,
  [AspType.SQUARE]: ServiceType.COMMERCE,
};

export const ASP_SERVICE_BY_TYPE = Object.entries(ASP_SERVICE_TYPE).reduce((acum, [key, type]) => {
  Array.isArray(acum[type]) ? acum[type].push(key) : (acum[type] = [key]);
  return acum;
}, {}) as Record<ServiceType, AspType[]>;

/**
 * Returns true if the report type is analytics
 * @param {ReportType} reportType
 * @returns {boolean}
 */
export const isAnalyticsReport = (report: ReportType): boolean => {
  return (
    Object.values(ReportTypeDataTypeMapping)
      .filter((dataType) => dataType.serviceType === ServiceType.ANALYTICS)
      .map((dataType) => dataType.value)
      .indexOf(report) >= 0
  );
};
