import i18n from '../../translations';

import { Enum } from 'helpers/common.helper';

export const generateTranslation = (enumVar: Enum): Enum => {
  if (!enumVar._name)
    throw new Error('Enum must have meta field _name for generating dropdown options');
  return Object.keys(enumVar).reduce((acc, curr) => {
    if (curr !== '_name') {
      return {
        ...acc,
        [enumVar[curr]]: i18n.t([`DASHBOARD_ENUM_${enumVar._name}_${curr}`]),
      };
    }
    return { ...acc };
  }, {});
};
