import { call, put, takeLatest } from 'redux-saga/effects';

import { Webhook, WebhookHistoryParams, WebhookHistoryResponse } from '../../../../types';

import {
  FETCH_WEBHOOK_API,
  CREATE_WEBHOOK_API,
  DELETE_WEBHOOK_API,
  UPDATE_WEBHOOK_API,
  deleteWebhookFailureApi,
  deleteWebhookSuccessApi,
  createWebhookFailureApi,
  createWebhookSuccessApi,
  fetchWebhookFailureApi,
  fetchWebhookSuccessApi,
  updateWebhookSuccessApi,
  updateWebhookFailureApi,
  FETCH_WEBHOOK_HISTORY_API,
  fetchWebhookHistorySuccessApi,
  fetchWebhookHistoryFailureApi,
} from './webhook.action';

import { default as WebhookService } from './webhook.service';
import { WebhookAction } from './webhook.state';

import { ErrorResponse } from 'types/interface/error.interface';

export const buildErrorMessage = (
  error: ErrorResponse,
  context: 'create' | 'update' | 'fetch' | 'delete',
): ErrorResponse => {
  return {
    ...error,
    message: ['Webhook', context].concat(error.message),
  };
};

function* fetchWebhookApiCall(): Generator<any> {
  try {
    const resultWebhook = yield call(WebhookService.getWebhooks);
    yield put(fetchWebhookSuccessApi(resultWebhook as Required<Webhook>[]));
  } catch (error) {
    yield put(fetchWebhookFailureApi(buildErrorMessage(error, 'fetch')));
  }
}

function* fetchWebhookSaga(): Generator<any> {
  yield takeLatest(FETCH_WEBHOOK_API, fetchWebhookApiCall);
}

function* createWebhookApiCall(action: WebhookAction<Webhook>): Generator<any> {
  try {
    const resultWebhook = yield call(WebhookService.createWebhook, action.payload);
    yield put(createWebhookSuccessApi(resultWebhook as Required<Webhook>));
  } catch (error) {
    yield put(createWebhookFailureApi(buildErrorMessage(error, 'create')));
  }
}

function* createWebhookSaga(): Generator<any> {
  yield takeLatest(CREATE_WEBHOOK_API, createWebhookApiCall);
}

function* updateWebhookApiCall(action: WebhookAction<Webhook>): Generator<any> {
  try {
    const resultWebhook = yield call(WebhookService.updateWebhook, action.payload);
    yield put(updateWebhookSuccessApi(resultWebhook as Required<Webhook>));
  } catch (error) {
    yield put(updateWebhookFailureApi(buildErrorMessage(error, 'update')));
  }
}

function* updateWebhookSaga(): Generator<any> {
  yield takeLatest(UPDATE_WEBHOOK_API, updateWebhookApiCall);
}

function* deleteWebhookApiCall(action: WebhookAction<string>): Generator<any> {
  try {
    const result = yield call(WebhookService.deleteWebhook, action.payload);
    yield put(deleteWebhookSuccessApi((result as any).deleted as string));
  } catch (error) {
    yield put(deleteWebhookFailureApi(buildErrorMessage(error, 'delete')));
  }
}

function* deleteWebhookSaga(): Generator<any> {
  yield takeLatest(DELETE_WEBHOOK_API, deleteWebhookApiCall);
}

function* fetchWebhookHistoryApiCall(action: WebhookAction<WebhookHistoryParams>): Generator<any> {
  try {
    const resultWebhookHistory = (yield call(
      WebhookService.getWebhookHistory,
      action.payload,
    ) as unknown) as WebhookHistoryResponse;
    yield put(fetchWebhookHistorySuccessApi(resultWebhookHistory));
  } catch (error) {
    yield put(fetchWebhookHistoryFailureApi(buildErrorMessage(error, 'fetch')));
  }
}

function* fetchWebhookHistorySaga(): Generator<any> {
  yield takeLatest(FETCH_WEBHOOK_HISTORY_API, fetchWebhookHistoryApiCall);
}

export {
  createWebhookApiCall,
  updateWebhookApiCall,
  fetchWebhookApiCall,
  deleteWebhookApiCall,
  fetchWebhookHistoryApiCall,
};

export const webhookSaga = [
  createWebhookSaga(),
  updateWebhookSaga(),
  fetchWebhookSaga(),
  deleteWebhookSaga(),
  fetchWebhookHistorySaga(),
];
