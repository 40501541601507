import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 4,
    },
    searchForm: {
      padding: '3px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #BDBDBD',
      boxSizing: 'border-box',
      boxShadow: 'none',
      minWidth: 100,
    },
  }),
);
