import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { ApiKey } from 'types';
import { EventService } from 'services';
import AlertDialog from 'components/dialog';

interface Props {
  isOpen: boolean;
  apiKey: ApiKey;
  onClose: () => void;
  onDelete: (params: { identifier: string; name: string }) => void;
}

export default function ApiKeysDelete({ isOpen, apiKey, onDelete, onClose }: Props): JSX.Element {
  const { t } = useTranslation();

  const handleDelete = async (): Promise<void> => {
    try {
      onDelete({ identifier: apiKey.identifier, name: apiKey.name });
      onClose();
    } catch (err) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: <Trans i18nKey="DASHBOARD_API_DELETE_KEY_FAIL"></Trans>,
      });
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('DASHBOARD_API_DELETE_KEY_TITLE')}
      cancel={{
        label: t('DASHBOARD_CANCEL_CTA'),
        onClick: onClose,
        type: 'gray',
        'data-testid': 'close-button',
      }}
      confirm={{
        label: t('DASHBOARD_API_DELETE_KEY_TITLE'),
        onClick: handleDelete,
        type: 'error',
        'data-testid': 'delete-button',
      }}
      showCloseButton
    >
      <Typography>
        <Trans
          i18nKey="DASHBOARD_API_DELETE_KEY_DESCRIPTION"
          values={{ name: apiKey.name }}
          components={{ bold: <strong /> }}
        ></Trans>
      </Typography>
    </AlertDialog>
  );
}
