import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogTitle, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../../store';
import {
  getGeneratedAuth,
  getProfileError,
  getProfileState,
  getRecoveryCode,
  isProfileBaseLoading,
} from '../../../store/features/account/profile/profile.selector';
import {
  enableMfaApi,
  setProfileMfa,
} from '../../../store/features/account/profile/profile.action';
import { ErrorResponse } from '../../../types/interface/error.interface';
import {
  GeneratedMfaResponse,
  ProfileState,
} from '../../../store/features/account/profile/profile.state';
import { isCustomerBaseLoading } from '../../../store/features/account/customer/customer.selector';

import RecoveryCodeDialog from './recovery-code-dialog';
import style from './style';

import { showSnackbar } from 'helpers/common.helper';

import Loading from 'layout/loading';
import { FormInput } from 'components/form';

interface IProps {
  isOpen: boolean;
}

interface IFormValue {
  authCode: string;
}

export const validationSchema = yup.object().shape({
  authCode: yup.string().required('DASHBOARD_LOGIN_MFA_REQUIRED').nullable(),
});

const initialValues: IFormValue = {
  authCode: '',
};

const MfaForm: React.FC<IProps> = () => {
  const [isRecoveryDialogVisible, setIsRecoveryDialogVisible] = useState(false);
  const classes = style();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const confirmRecoveryDialog = {
    label: t('DASHBOARD_API_BACKUP_CTA_ACTION'),
    onClick: (): void => closeRecoveryDialog(),
  };
  const store = storeService.getStore();
  const user = useSelector<ReturnType<typeof store.getState>, ProfileState>(getProfileState);
  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse>(getProfileError);
  const recoveryCode = useSelector<ReturnType<typeof store.getState>, string>(getRecoveryCode);
  const authInfo = useSelector<ReturnType<typeof store.getState>, GeneratedMfaResponse>(
    getGeneratedAuth,
  );
  const loading = useSelector<ReturnType<typeof store.getState>, boolean>(isCustomerBaseLoading);
  const profileLoading = useSelector<ReturnType<typeof store.getState>, boolean>(
    isProfileBaseLoading,
  );

  const closeRecoveryDialog = (): void => {
    dispatch(setProfileMfa(true));
    setIsRecoveryDialogVisible(false);
  };

  const { handleSubmit, errors, control, reset, formState } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;

  useEffect(() => {
    if (user && !user.mfaEnabled) {
      reset();
    }
  }, [user]);

  useEffect(() => {
    if (recoveryCode) {
      setIsRecoveryDialogVisible(true);
      showSnackbar({
        message: t('DASHBOARD_SECURITY_2FACTOR_TOAST_SUCCESS'),
        type: 'success',
      });
    }
  }, [recoveryCode]);

  useEffect(() => {
    if (error) {
      if (error.code === 401) {
        showSnackbar({
          type: 'error',
          message: t('DASHBOARD_SECURITY_MFA_AUTH_CODE_INVALID'),
        });
      } else {
        showSnackbar({
          type: 'error',
          message: t('DASHBOARD_SECURITY_2FACTOR_TOAST_ERROR'),
        });
      }
    }
  }, [error]);

  const onSubmit = async ({ authCode }): Promise<void> => {
    dispatch(enableMfaApi({ token: authCode, secret: authInfo?.secret }));
  };

  if (loading && profileLoading) return <Loading />;

  const trans = (
    <Trans
      i18nKey="DASHBOARD_SECURITY_MFA_SETUP_DESCRIPTION"
      values={{ authCode: authInfo?.secret }}
      components={{
        span: <code className={classes.authCode}>{{ authCode: authInfo?.secret }}</code>,
      }}
    />
  );

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.qrContainer}>
          {authInfo?.otpAuth && (
            <QRCode className={classes.qr} size={210} value={authInfo?.otpAuth} />
          )}
        </Box>
        <Box flexGrow={1} className={classes.contentContainer}>
          <DialogTitle id="mfa-enable-dialog" className={classes.title}>
            {t('DASHBOARD_SECURITY_MFA_SETUP_HEADER')}
          </DialogTitle>

          <Typography className={classes.description}>{trans}</Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.formContainer}>
              <FormInput
                testid="input-mfa"
                className={classes.input}
                placeholder="DASHBOARD_SECURITY_MFA_AUTH_CODE_LABEL"
                label="DASHBOARD_SECURITY_MFA_AUTH_CODE_LABEL"
                variant="outlined"
                name="authCode"
                control={control}
                errorobj={errors}
                inputProps={{ maxLength: 6 }}
                fullWidth
              />
              <Box className={classes.actions}>
                <RailzButton
                  data-testid="cta-mfa"
                  buttonType="submit"
                  label={t('DASHBOARD_SECURITY_MFA_AUTH_CODE_CTA_SAVE')}
                  size="large"
                  className={classes.submitButton}
                  isDisabled={Boolean(errors.authCode || isSubmitting)}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <RecoveryCodeDialog
        isOpen={isRecoveryDialogVisible}
        confirm={confirmRecoveryDialog}
        recoveryCode={recoveryCode}
      />
    </>
  );
};
export default MfaForm;
