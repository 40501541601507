import React, { useEffect, useState } from 'react';

import { Link, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { FREE_PLAN_CONNECTION_LIMIT } from '../../types/constants';

import style from './style';

interface Props {
  amountConnections: number;
}

interface Params {
  title?: string;
  styleBoxBanner?: string;
  styleLink?: string;
  showBanner: boolean;
}

export default function FreeLimitBanner({ amountConnections }: Props): React.ReactElement {
  const classes = style();
  const { t } = useTranslation();

  const params: Params = {
    showBanner: false,
  };
  const [parameters, setParameters] = useState(params);

  useEffect(() => {
    renderBanner(amountConnections);
  }, [amountConnections]);

  const renderBanner = (connections: number): void => {
    if (connections > 3 && connections < FREE_PLAN_CONNECTION_LIMIT) {
      setParameters({
        showBanner: true,
        styleBoxBanner: classes.limitBannerWarning,
        styleLink: classes.upgradeWarningLink,
        title: t('DASHBOARD_LIMIT_WARNING_BANNER'),
      });
    }
    if (connections >= FREE_PLAN_CONNECTION_LIMIT) {
      setParameters({
        showBanner: true,
        styleBoxBanner: classes.limitBannerDanger,
        styleLink: classes.upgradeDangerLink,
        title: t('DASHBOARD_LIMIT_DANGER_BANNER'),
      });
    }
    if (connections < 3) {
      setParameters({ showBanner: false });
    }
  };

  return (
    parameters.showBanner && (
      <div data-testid="limit-banner" className={parameters.styleBoxBanner}>
        <Typography id="limitTitle" className={classes.limitTitle} tabIndex={0}>
          {parameters.title}
          <Link
            className={[classes.upgradeLink, parameters.styleLink].join(' ')}
            href={'/account/billing'}
          >
            {t('DASHBOARD_LIMIT_BANNER_UPGRADE_LINK')}
          </Link>
        </Typography>
      </div>
    )
  );
}
