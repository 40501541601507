import React from 'react';
import { useTranslation } from 'react-i18next';

import { SSOButton } from './button';

import googleLogo from 'assets/images/google.svg';
import Config from 'config';

const GoogleLogin: React.FC = () => {
  const { t } = useTranslation();
  const clientId = Config.GOOGLE_CLIENT_ID;
  const redirectUri = Config.GOOGLE_REDIRECT_URI;
  const scopes = Config.GOOGLE_SCOPES;
  const fullURI =
    `${Config.GOOGLE_BASE_AUTH_URL}?client_id=${clientId}&redirect_uri=` +
    `${redirectUri}&response_type=code&scope=${scopes.join(' ')}`;

  return (
    <SSOButton
      id="googleSSO"
      logo={googleLogo}
      label={t('DASHBOARD_LOGIN_SSO_GOOGLE')}
      onClick={(): void => {
        location.href = fullURI;
      }}
      iconStyle={{ paddingRight: '14px' }}
    />
  );
};

export default GoogleLogin;
