import React from 'react';

interface Props {
  color?: string;
}

const DoubleArrow: React.FC<Props> = ({ color = '#424242' }): React.ReactElement => {
  return (
    <svg width="26" height="6" viewBox="0 0 26 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3L5 5.88675V0.113249L0 3ZM26 3L21 0.113249V5.88675L26 3ZM4.5 3.5H21.5V2.5H4.5V3.5Z"
        fill={color}
      />
    </svg>
  );
};

export default DoubleArrow;
