import { ApiResponse } from '../../../types/interface/common.interface';
import { BusinessCrudResponse, Services } from '../../../types';
import { Business, Connection } from '../../../pages/business/types/interfaces';

import {
  BusinessEventStat,
  BusinessFilter,
  BusinessResponse,
  CreateBusinessParam,
  GenerateUrlPayload,
  RenameBusinessParam,
} from './business.state';

import { ErrorResponse } from 'types/interface/error.interface';

export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS';

export const RESET_BUSINESS_SUGGESTIONS = 'RESET_BUSINESS_SUGGESTIONS';
export const FETCH_BUSINESS_SUGGESTIONS = 'FETCH_BUSINESS_SUGGESTIONS';
export const FETCH_BUSINESS_SUGGESTIONS_SUCCESS = 'FETCH_BUSINESS_SUGGESTIONS_SUCCESS';
export const FETCH_BUSINESS_SUGGESTIONS_FAILURE = 'FETCH_BUSINESS_SUGGESTIONS_FAILURE';

export const FETCH_BUSINESSES = 'FETCH_BUSINESSES';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const FETCH_BUSINESSES_FAILURE = 'FETCH_BUSINESSES_FAILURE';

export const FETCH_SINGLE_BUSINESSES = 'FETCH_SINGLE_BUSINESSES';
export const FETCH_SINGLE_BUSINESSES_SUCCESS = 'FETCH_SINGLE_BUSINESSES_SUCCESS';
export const FETCH_SINGLE_BUSINESSES_FAILURE = 'FETCH_SINGLE_BUSINESSES_FAILURE';

export const FETCH_BUSINESSES_STATS = 'FETCH_BUSINESSES_STATS';
export const FETCH_BUSINESSES_STATS_SUCCESS = 'FETCH_BUSINESSES_STATS_SUCCESS';
export const FETCH_BUSINESSES_STATS_FAILURE = 'FETCH_BUSINESSES_STATS_FAILURE';

export const FETCH_BUSINESSES_GET_SERVICES = 'FETCH_BUSINESSES_GET_SERVICES';
export const FETCH_BUSINESSES_GET_SERVICES_SUCCESS = 'FETCH_BUSINESSES_GET_SERVICES_SUCCESS';
export const FETCH_BUSINESSES_GET_SERVICES_FAILURE = 'FETCH_BUSINESSES_GET_SERVICES_FAILURE';

export const DISCONNECT_CONNECTION = 'DISCONNECT_CONNECTION';
export const DISCONNECT_CONNECTION_SUCCESS = 'DISCONNECT_CONNECTION_SUCCESS';
export const DISCONNECT_CONNECTION_FAILURE = 'DISCONNECT_CONNECTION_FAILURE';

export const CREATE_BUSINESS_API = 'CREATE_BUSINESS_API';
export const CREATE_BUSINESS_SUCCESS_API = 'CREATE_BUSINESS_SUCCESS_API';
export const CREATE_BUSINESS_FAILURE_API = 'CREATE_BUSINESS_FAILURE_API';

export const RENAME_BUSINESS_API = 'RENAME_BUSINESS_API';
export const RENAME_BUSINESS_SUCCESS_API = 'RENAME_BUSINESS_SUCCESS_API';
export const RENAME_BUSINESS_FAILURE_API = 'RENAME_BUSINESS_FAILURE_API';

export const GENERATE_BUSINESS_URL_API = 'GENERATE_BUSINESS_URL_API';
export const GENERATE_BUSINESS_URL_SUCCESS_API = 'GENERATE_BUSINESS_URL_SUCCESS_API';
export const GENERATE_BUSINESS_URL_FAILURE_API = 'GENERATE_BUSINESS_URL_FAILURE_API';

export const RESET_RESPONSE = 'RESET_RESPONSE';
export const RESET_GENERATED_URL = 'RESET_GENERATED_URL';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_BUSINESS_INFORMATION = 'RESET_BUSINESS_INFORMATION';

export const DELETE_BUSINESS_API = 'DELETE_BUSINESS_API';
export const DELETE_BUSINESS_SUCCESS_API = 'DELETE_BUSINESS_SUCCESS_API';
export const DELETE_BUSINESS_FAILURE_API = 'DELETE_BUSINESS_FAILURE_API';

export const RESET_STORAGE_BUSINESS = 'RESET_STORAGE_BUSINESS';

export const resetBusinessStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_BUSINESS,
  };
};

export const resetBusinessSuggestions = (): { type: string } => {
  return {
    type: RESET_BUSINESS_SUGGESTIONS,
  };
};

export const fetchBusinessSuggestions = (
  payload?: BusinessFilter | string,
): { type: string; payload: BusinessFilter | string } => {
  return { type: FETCH_BUSINESS_SUGGESTIONS, payload };
};

export const fetchBusinessSuggestionsSuccessApi = (
  payload: BusinessResponse,
): { payload: BusinessResponse; type: string } => {
  return { type: FETCH_BUSINESS_SUGGESTIONS_SUCCESS, payload };
};
export const fetchBusinessSuggestionsFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return { type: FETCH_BUSINESS_SUGGESTIONS_FAILURE, payload };
};

export const fetchBusinesses = (
  payload?: BusinessFilter | string,
): { type: string; payload: BusinessFilter | string } => {
  return { type: FETCH_BUSINESSES, payload };
};

export const fetchBusinessesSuccessApi = (
  payload: BusinessResponse,
): { payload: BusinessResponse; type: string } => {
  return { type: FETCH_BUSINESSES_SUCCESS, payload };
};
export const fetchBusinessesFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return { type: FETCH_BUSINESSES_FAILURE, payload };
};

export const fetchSingleBusinesses = (
  payload?: BusinessFilter | string,
): { type: string; payload: BusinessFilter | string } => {
  return { type: FETCH_SINGLE_BUSINESSES, payload };
};
export const fetchSingleBusinessesSuccessApi = (
  payload: BusinessResponse,
): { payload: BusinessResponse; type: string } => {
  return { type: FETCH_SINGLE_BUSINESSES_SUCCESS, payload };
};
export const fetchSingleBusinessesFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return { type: FETCH_SINGLE_BUSINESSES_FAILURE, payload };
};

export const fetchBusinessStats = (): { type: string } => {
  return { type: FETCH_BUSINESSES_STATS };
};
export const fetchBusinessStatsSuccessApi = (
  payload: BusinessEventStat,
): { payload: BusinessEventStat; type: string } => {
  return { type: FETCH_BUSINESSES_STATS_SUCCESS, payload };
};
export const fetchBusinessStatsFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return { type: FETCH_BUSINESSES_STATS_FAILURE, payload };
};

export const fetchServices = (): { type: string } => {
  return { type: FETCH_BUSINESSES_GET_SERVICES };
};

export const fetchServicesSuccessApi = (
  payload: Services[],
): { payload: Services[]; type: string } => {
  return { type: FETCH_BUSINESSES_GET_SERVICES_SUCCESS, payload };
};
export const fetchServicesFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return { type: FETCH_BUSINESSES_GET_SERVICES_FAILURE, payload };
};
export const setSelectedBusiness = (payload: Business): { payload: Business; type: string } => {
  return { type: SET_SELECTED_BUSINESS, payload };
};

export const disconnectConnectionApi = (
  payload: Connection,
): { payload: Connection; type: string } => {
  return {
    type: DISCONNECT_CONNECTION,
    payload,
  };
};

export const disconnectConnectionSuccessApi = (
  payload: ApiResponse,
): { payload: ApiResponse; type: string } => {
  return {
    type: DISCONNECT_CONNECTION_SUCCESS,
    payload,
  };
};

export const disconnectConnectionFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DISCONNECT_CONNECTION_FAILURE,
    payload,
  };
};

export const deleteBusinessApi = (payload: string): { payload: string; type: string } => {
  return {
    type: DELETE_BUSINESS_API,
    payload,
  };
};

export const deleteBusinessSuccessApi = (
  payload: ApiResponse,
): { payload: ApiResponse; type: string } => {
  return {
    type: DELETE_BUSINESS_SUCCESS_API,
    payload,
  };
};

export const deleteBusinessFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DELETE_BUSINESS_FAILURE_API,
    payload,
  };
};

export const resetBusinessInformation = (): { type: string } => {
  return {
    type: RESET_BUSINESS_INFORMATION,
  };
};

export const resetBusinessError = (): { type: string } => {
  return {
    type: RESET_ERROR,
  };
};

export const resetBusinessResponse = (): { type: string } => {
  return {
    type: RESET_RESPONSE,
  };
};

export const resetGeneratedUrl = (): { type: string } => {
  return {
    type: RESET_GENERATED_URL,
  };
};

export const createBusinessesApi = (
  payload: CreateBusinessParam,
): { payload: CreateBusinessParam; type: string } => {
  return {
    type: CREATE_BUSINESS_API,
    payload,
  };
};

export const createBusinessesSuccessApi = (
  payload: ApiResponse & BusinessCrudResponse,
): { payload: ApiResponse & BusinessCrudResponse; type: string } => {
  return {
    type: CREATE_BUSINESS_SUCCESS_API,
    payload,
  };
};

export const createBusinessesFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: CREATE_BUSINESS_FAILURE_API,
    payload,
  };
};

export const renameBusinessApi = (
  payload: RenameBusinessParam,
): { payload: RenameBusinessParam; type: string } => {
  return {
    type: RENAME_BUSINESS_API,
    payload,
  };
};

export const renameBusinessSuccessApi = (
  payload: ApiResponse,
): { payload: ApiResponse; type: string } => {
  return {
    type: RENAME_BUSINESS_SUCCESS_API,
    payload,
  };
};

export const renameBusinessFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: RENAME_BUSINESS_FAILURE_API,
    payload,
  };
};

export const generateBusinessUrlApi = (
  payload: GenerateUrlPayload,
): { payload: GenerateUrlPayload; type: string } => {
  return {
    type: GENERATE_BUSINESS_URL_API,
    payload,
  };
};

export const generateBusinessUrlSuccessApi = (
  payload: string,
): { payload: string; type: string } => {
  return {
    type: GENERATE_BUSINESS_URL_SUCCESS_API,
    payload,
  };
};

export const generateBusinessUrlFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: GENERATE_BUSINESS_URL_FAILURE_API,
    payload,
  };
};
