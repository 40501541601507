import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

type ScrollViewClassKey = 'root' | 'horizontal';

const styles: any = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    height: '100%',
  },
  horizontal: {
    flexDirection: 'row',
    overflowY: 'hidden',
    overflowX: 'auto',
  },
};

interface IProps {
  horizontal?: boolean;
  style?: any;
  classNames?: any;
}

interface IStyleProps {
  classes: {
    [key in ScrollViewClassKey]: string;
  };
  'data-testid'?: string;
}

const ScrollView: React.FC<IProps & IStyleProps> = (props) => {
  const classes = props.classes;
  return (
    <div
      className={classNames(classes.root, `${props.classNames}`, {
        [classes.horizontal]: props.horizontal,
      })}
      data-testid={props['data-testid'] || 'scroll-view'}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default withStyles(styles)(ScrollView) as React.FC<IProps>;
