import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
      gap: theme.spacing(1),

      '& button': {
        height: '100%',
        margin: '0 !important',
        paddingLeft: `${theme.spacing(2)}px !important`,
        paddingRight: `${theme.spacing(2)}px !important`,
      },
    },
    cancel: {
      '& button': {
        color: `${theme.palette.grey[800]} !important`,
        backgroundColor: `${theme.palette.grey[100]} !important`,
      },
      '& button:hover': {
        color: `${theme.palette.grey[800]} !important`,
        backgroundColor: `${theme.palette.grey[300]} !important`,
      },
      '& button:focus': {
        color: `${theme.palette.grey[800]} !important`,
        backgroundColor: `${theme.palette.grey[300]} !important`,
      },
    },
  }),
);
