import React, { useState } from 'react';
import moment from 'moment-timezone';

import { Chip, TableRow, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { Business, BusinessWithQuery } from '../types/interfaces';
import { ActionTypes, BusinessStatus } from '../types/enums';
import ServiceChip from '../../../components/service-chip';
import { OnRowClick, Role } from '../../../types';

import { getUserRole } from '../../../store/features/account/profile/profile.selector';

import BusinessGenerateLink from '../business-controls/generate-link';

import BusinessDelete from '../business-delete/business-delete';

import { RailzTableCell } from '../../../components/table/link-cell';

import BusinessRowMenu from './business-row-menu';

import useStyles from './style';

import { isSandbox } from 'store/features/auth/auth.selector';

import { getLocalizedStatus } from 'helpers/common.helper';

interface Props {
  isLoading: boolean;
  business: BusinessWithQuery[];
  updateBusiness: () => void;
  onRowClick?: OnRowClick;
}

const BusinessRow: React.FC<Props> = ({
  business,
  isLoading,
  updateBusiness,
  onRowClick = null,
}) => {
  const classes = useStyles();
  const [currentBusiness, setCurrentBusiness] = useState<Business>(null);
  const [currentActionType, setCurrentActionType] = useState<ActionTypes>(null);
  const role = useSelector(getUserRole);
  const isSandboxEnabled = useSelector(isSandbox);

  const rows =
    business &&
    business?.length &&
    business?.map((businessElement: BusinessWithQuery, i: number) => {
      const displayViewData =
        businessElement.status === BusinessStatus.ACTIVE ||
        businessElement.status === BusinessStatus.INACTIVE;

      const validRoleToViewData = [
        Role.SUPER_ADMINISTRATOR,
        Role.ADMINISTRATOR,
        Role.ANALYST,
        isSandboxEnabled && Role.DEVELOPER,
      ].includes(role);

      let onRowClickItems = null;

      if (onRowClick && validRoleToViewData) {
        onRowClickItems = onRowClick?.formatLinkParams(businessElement);
      }

      const enableRowClick = onRowClick && onRowClickItems && displayViewData;
      const link = enableRowClick && {
        pathname: onRowClick?.pathName,
        search: onRowClickItems?.searchParam,
      };

      return (
        <TableRow
          key={`${businessElement.businessName}-${i}`}
          className={`${enableRowClick ? classes.tableRowHover : ''} ${classes.tableRow}`}
        >
          <RailzTableCell className={classes.tableCell} link={link}>
            <Typography className={classes.nameRowLabel}>{businessElement.businessName}</Typography>
            <Typography className={classes.uuidRowLabel}>{businessElement.businessUuid}</Typography>
          </RailzTableCell>
          <RailzTableCell link={link}>
            <Chip
              key={i}
              label={getLocalizedStatus(businessElement.status)}
              className={`${classes.chipDefault}
                ${
                  businessElement.status !== BusinessStatus.ACTIVE
                    ? classes.inactiveChip
                    : classes.activeChip
                }`}
              size="small"
              style={{ marginRight: 4 }}
            />
          </RailzTableCell>
          <RailzTableCell style={{ minWidth: 110 }} link={link}>
            {moment(businessElement.createdAt).format('DD MMM YYYY')}
          </RailzTableCell>
          <RailzTableCell style={{ minWidth: 110 }} link={link}>
            {moment(businessElement.updatedAt).format('DD MMM YYYY')}
          </RailzTableCell>
          <RailzTableCell link={link}>
            <ServiceChip business={businessElement} />
          </RailzTableCell>
          {[
            Role.SUPER_ADMINISTRATOR,
            Role.ADMINISTRATOR,
            isSandboxEnabled && Role.DEVELOPER,
          ].includes(role) ? (
            <RailzTableCell link={link}>
              <BusinessRowMenu
                business={businessElement}
                setCurrentBusiness={setCurrentBusiness}
                setCurrentActionType={setCurrentActionType}
              />
            </RailzTableCell>
          ) : null}
        </TableRow>
      );
    });

  const rendered = (
    <>
      {rows}
      <BusinessGenerateLink
        currentBusiness={currentBusiness}
        currentActionType={currentActionType}
        setCurrentActionType={setCurrentActionType}
      />
      {[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, isSandboxEnabled && Role.DEVELOPER].includes(
        role,
      ) ? (
        <BusinessDelete currentBusiness={currentBusiness} updateBusiness={updateBusiness} />
      ) : null}
    </>
  );

  return Boolean(business?.length) && !isLoading ? rendered : <TableRow />;
};

export default BusinessRow;
