import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(0.5),
      minHeight: theme.spacing(5),
      minWidth: 100,
      maxWidth: 110,
      '& .MuiSelect-select': {
        fontSize: '0.875rem',
      },
      ['@media screen and (max-width: 768px)']: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    menu: {
      marginTop: theme.spacing(),

      '& ul': {
        maxHeight: 240,
        marginBottom: theme.spacing(),
        '& .MuiListItem-root': {
          fontSize: '0.875rem',
        },
      },
    },
    menuItem: {
      padding: ` 0px ${theme.spacing(2)}px 0px 0px`,

      '&.Mui-selected': {
        backgroundColor: theme.palette.common.white,
      },
    },
  }),
);
