import {
  ApiResponse,
  ConnectUrlResponse,
  IpWhitelistResponse,
  IpWhitelistUpdateParameter,
} from '../../../types';

import { ErrorResponse } from 'types/interface/error.interface';

/** StateActions */
export const RESET_CONNECT_ERROR = 'RESET_CONNECT_ERROR';
export const RESET_CONNECT_API_RESPONSE = 'RESET_CONNECT_API_RESPONSE';

export const UPDATE_IP_WHITELIST_API = 'UPDATE_IP_WHITELIST_API';
export const UPDATE_IP_WHITELIST_SUCCESS_API = 'UPDATE_IP_WHITELIST_SUCCESS_API';
export const UPDATE_IP_WHITELIST_FAILURE_API = 'UPDATE_IP_WHITELIST_FAILURE_API';

export const DELETE_IP_WHITELIST_API = 'DELETE_IP_WHITELIST_API';
export const DELETE_IP_WHITELIST_SUCCESS_API = 'DELETE_IP_WHITELIST_SUCCESS_API';
export const DELETE_IP_WHITELIST_FAILURE_API = 'DELETE_IP_WHITELIST_FAILURE_API';

export const CREATE_IP_WHITELIST_API = 'CREATE_IP_WHITELIST_API';
export const CREATE_IP_WHITELIST_SUCCESS_API = 'CREATE_IP_WHITELIST_SUCCESS_API';
export const CREATE_IP_WHITELIST_FAILURE_API = 'CREATE_IP_WHITELIST_FAILURE_API';

export const GET_IP_WHITELIST_API = 'GET_IP_WHITELIST_API';
export const GET_IP_WHITELIST_SUCCESS_API = 'GET_IP_WHITELIST_SUCCESS_API';
export const GET_IP_WHITELIST_FAILURE_API = 'GET_IP_WHITELIST_FAILURE_API';

export const GET_CONNECT_URL_API = 'GET_CONNECT_URL_API';
export const GET_CONNECT_URL_SUCCESS_API = 'GET_CONNECT_URL_SUCCESS_API';
export const GET_CONNECT_URL_FAILURE_API = 'GET_CONNECT_URL_FAILURE_API';

export const RESET_STORAGE_CONNECT = 'RESET_STORAGE_CONNECT';

export const resetConnectStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_CONNECT,
  };
};

export const resetConnectError = (): { type: string } => {
  return {
    type: RESET_CONNECT_ERROR,
  };
};

export const resetConnectApiResponse = (): { type: string } => {
  return {
    type: RESET_CONNECT_API_RESPONSE,
  };
};

export const updateIpWhitelistApi = (
  payload: IpWhitelistUpdateParameter,
): { payload: IpWhitelistUpdateParameter; type: string } => {
  return {
    type: UPDATE_IP_WHITELIST_API,
    payload,
  };
};

export const updateIpWhitelistSuccessApi = (
  payload: ApiResponse,
): { payload: ApiResponse; type: string } => {
  return {
    type: UPDATE_IP_WHITELIST_SUCCESS_API,
    payload,
  };
};

export const updateIpWhitelistFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: UPDATE_IP_WHITELIST_FAILURE_API,
    payload,
  };
};

export const createIpWhitelistApi = (
  payload: IpWhitelistResponse,
): { payload: IpWhitelistResponse; type: string } => {
  return {
    type: CREATE_IP_WHITELIST_API,
    payload,
  };
};

export const createIpWhitelistSuccessApi = (
  payload: ApiResponse,
): { payload: ApiResponse; type: string } => {
  return {
    type: CREATE_IP_WHITELIST_SUCCESS_API,
    payload,
  };
};

export const createIpWhitelistFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: CREATE_IP_WHITELIST_FAILURE_API,
    payload,
  };
};

export const deleteIpWhitelistApi = (payload: number): { payload: number; type: string } => {
  return {
    type: DELETE_IP_WHITELIST_API,
    payload,
  };
};

export const deleteIpWhitelistSuccessApi = (
  payload: ApiResponse,
): { payload: ApiResponse; type: string } => {
  return {
    type: DELETE_IP_WHITELIST_SUCCESS_API,
    payload,
  };
};

export const deleteIpWhitelistFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: DELETE_IP_WHITELIST_FAILURE_API,
    payload,
  };
};

export const getIpWhitelistApi = (): { type: string } => {
  return {
    type: GET_IP_WHITELIST_API,
  };
};

export const getIpWhitelistSuccessApi = (
  payload: IpWhitelistResponse[],
): { payload: IpWhitelistResponse[]; type: string } => {
  return {
    type: GET_IP_WHITELIST_SUCCESS_API,
    payload,
  };
};

export const getIpWhitelistFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: GET_IP_WHITELIST_FAILURE_API,
    payload,
  };
};

export const getConnectUrlApi = (): { type: string } => {
  return {
    type: GET_CONNECT_URL_API,
  };
};

export const getConnectUrlSuccessApi = (
  payload: ConnectUrlResponse,
): { payload: ConnectUrlResponse; type: string } => {
  return {
    type: GET_CONNECT_URL_SUCCESS_API,
    payload,
  };
};

export const getConnectUrlFailureApi = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: GET_CONNECT_URL_FAILURE_API,
    payload,
  };
};
