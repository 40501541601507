import axios from 'axios';

import Config from 'config';
import { WebhookConfig, WebhookSecret, WebhookSecretRequest } from 'types';

export const webhookSecretServiceUrl = `${Config.SERVER_URL}/webhooks/secret/`;
export const webhookConfigServiceUrl = `${Config.SERVER_URL}/webhooks/config/`;

class WebhookService {
  async get(): Promise<WebhookSecret | undefined> {
    const webhook = (await axios.get(webhookConfigServiceUrl)) as WebhookSecret & WebhookConfig;
    return webhook;
  }

  async create(payload: WebhookSecretRequest): Promise<WebhookSecret> {
    return await axios.post(webhookSecretServiceUrl, payload);
  }

  async update(payload: WebhookSecretRequest): Promise<WebhookSecret> {
    return await axios.put(webhookSecretServiceUrl, payload);
  }

  async delete(): Promise<undefined> {
    await axios.delete(webhookSecretServiceUrl);
    return;
  }
}

export default new WebhookService();
