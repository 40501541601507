import { ErrorResponse } from '../../../../types/interface/error.interface';

import {
  AddOrUpdateTeamMemberV2,
  AddTeamV2,
  ResendTeamMemberInvitation,
  AddOrUpdateTeamMemberSuccessPayload,
  UpdateMemberRole,
  TeamsFilter,
  UpdateTeamV2,
} from './team.state';

import {
  AddTeamV2Response,
  TeamV2Response,
  SuccessResponse,
  TeamResponse,
  TeamsResponse,
  TeamUserResponse,
  UpdateTeamV2Response,
  TeamMembersResponse,
  PaginationProps,
} from 'types/interface';
import { handlePagination } from 'store/utils';

export const RESET_TEAM_ERROR = 'RESET_TEAM_ERROR';
export const RESET_TEAM_RESPONSE = 'RESET_TEAM_RESPONSE';
export const RESET_TEAM_STATE = 'RESET_TEAM_STATE';

export const FETCH_TEAM_API = 'FETCH_TEAM_API';
export const FETCH_TEAM_SUCCESS_API = 'FETCH_TEAM_SUCCESS_API';
export const FETCH_TEAM_FAILURE_API = 'FETCH_TEAM_FAILURE_API';

export const FETCH_TEAM_MEMBERS_API = 'FETCH_TEAM_MEMBERS_API';
export const FETCH_TEAM_MEMBERS_SUCCESS_API = 'FETCH_TEAM_MEMBERS_SUCCESS_API';
export const FETCH_TEAM_MEMBERS_FAILURE_API = 'FETCH_TEAM_MEMBERS_FAILURE_API';

export const FETCH_TEAMS_API = 'FETCH_TEAMS_API';
export const FETCH_TEAMS_SUCCESS_API = 'FETCH_TEAMS_SUCCESS_API';
export const FETCH_TEAMS_FAILURE_API = 'FETCH_TEAMS_FAILURE_API';

export const FETCH_TEAM_V2_API = 'FETCH_TEAM_V2_API';
export const FETCH_TEAM_V2_SUCCESS_API = 'FETCH_TEAM_V2_SUCCESS_API';
export const FETCH_TEAM_V2_FAILURE_API = 'FETCH_TEAM_V2_FAILURE_API';

export const ADD_TEAM_V2_API = 'ADD_TEAM_V2_API';
export const ADD_TEAM_V2_SUCCESS_API = 'ADD_TEAM_V2_SUCCESS_API';
export const ADD_TEAM_V2_FAILURE_API = 'ADD_TEAM_V2_FAILURE_API';

export const UPDATE_TEAM_V2_API = 'UPDATE_TEAM_V2_API';
export const UPDATE_TEAM_V2_SUCCESS_API = 'UPDATE_TEAM_V2_SUCCESS_API';
export const UPDATE_TEAM_V2_FAILURE_API = 'UPDATE_TEAM_V2_FAILURE_API';

export const ADD_TEAM_MEMBER_API_V2 = 'ADD_TEAM_MEMBER_API_V2';
export const ADD_TEAM_MEMBER_SUCCESS_API_V2 = 'ADD_TEAM_MEMBER_SUCCESS_API_V2';
export const ADD_TEAM_MEMBER_FAILURE_API_V2 = 'ADD_TEAM_MEMBER_FAILURE_API_V2';

export const UPDATE_TEAM_MEMBER_API_V2 = 'UPDATE_TEAM_MEMBER_API_V2';
export const UPDATE_TEAM_MEMBER_SUCCESS_API_V2 = 'UPDATE_TEAM_MEMBER_SUCCESS_API_V2';
export const UPDATE_TEAM_MEMBER_FAILURE_API_V2 = 'UPDATE_TEAM_MEMBER_FAILURE_API_V2';

export const UPDATE_MEMBERS_OWNER_SUCCESS_API_V2 = 'UPDATE_MEMBERS_OWNER_SUCCESS_API_V2';

export const UPDATE_TEAM_MEMBER_ROLE_API = 'UPDATE_TEAM_MEMBER_ROLE_API';
export const UPDATE_TEAM_MEMBER_ROLE_SUCCESS_API = 'UPDATE_TEAM_MEMBER_ROLE_SUCCESS_API';
export const UPDATE_TEAM_MEMBER_ROLE_FAILURE_API = 'UPDATE_TEAM_MEMBER_ROLE_FAILURE_API';

export const DELETE_TEAM_MEMBER_API = 'DELETE_TEAM_MEMBER_API';
export const DELETE_TEAM_MEMBER_SUCCESS_API = 'DELETE_TEAM_MEMBER_SUCCESS_API';
export const DELETE_TEAM_MEMBER_FAILURE_API = 'DELETE_TEAM_MEMBER_FAILURE_API';

export const DELETE_TEAM_API = 'DELETE_TEAM_API';
export const DELETE_TEAM_SUCCESS_API = 'DELETE_TEAM_SUCCESS_API';
export const DELETE_TEAM_FAILURE_API = 'DELETE_TEAM_FAILURE_API';
export const DELETE_TEAM_MEMBER_FROM_TEAM_API = 'DELETE_TEAM_MEMBER_FROM_TEAM_API';
export const DELETE_TEAM_MEMBER_FROM_TEAM_SUCCESS_API = 'DELETE_TEAM_MEMBER_FROM_TEAM_SUCCESS_API';
export const DELETE_TEAM_MEMBER_FROM_TEAM_FAILURE_API = 'DELETE_TEAM_MEMBER_FROM_TEAM_FAILURE_API';

export const DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_API = 'DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_API';
export const DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_SUCCESS_API =
  'DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_SUCCESS_API';
export const DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_FAILURE_API =
  'DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_FAILURE_API';

export const RESEND_TEAM_MEMBER_INVITATION_API = 'RESEND_TEAM_MEMBER_INVITATION_API';
export const RESEND_TEAM_MEMBER_INVITATION_SUCCESS_API =
  'RESEND_TEAM_MEMBER_INVITATION_SUCCESS_API';
export const RESEND_TEAM_MEMBER_INVITATION_FAILURE_API =
  'RESEND_TEAM_MEMBER_INVITATION_FAILURE_API';

export const RESET_STORAGE_TEAM = 'RESET_STORAGE_TEAM';

export const resetTeamStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_TEAM,
  };
};

export const resetTeamError = (): { type: string } => {
  return {
    type: RESET_TEAM_ERROR,
  };
};

export const resetTeamResponse = (): { type: string } => {
  return {
    type: RESET_TEAM_RESPONSE,
  };
};

export const resetTeamState = (): { type: string } => {
  return {
    type: RESET_TEAM_STATE,
  };
};

export const fetchTeamApi = (): { type: string } => {
  return {
    type: FETCH_TEAM_API,
  };
};

export const fetchTeamSuccessApi = (
  payload: TeamResponse,
): { type: string; payload: TeamResponse } => {
  return {
    type: FETCH_TEAM_SUCCESS_API,
    payload,
  };
};

export const fetchTeamFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_TEAM_FAILURE_API,
    payload,
  };
};

export const fetchTeamsApi = (
  payload?: TeamsFilter | string,
): { type: string; payload: TeamsFilter | string } => {
  return {
    type: FETCH_TEAMS_API,
    payload,
  };
};

export const fetchTeamsSuccessApi = (
  payload: TeamsResponse,
): { type: string; payload: TeamsResponse } => {
  return {
    type: FETCH_TEAMS_SUCCESS_API,
    payload,
  };
};

export const fetchTeamsFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_TEAMS_FAILURE_API,
    payload,
  };
};

export const fetchTeamV2Api = (payload: PaginationProps): { type: string; payload: any } => {
  const params = handlePagination(payload);
  return {
    type: FETCH_TEAM_V2_API,
    payload: params,
  };
};

export const fetchTeamV2SuccessApi = (
  payload: TeamV2Response,
): { type: string; payload: TeamV2Response } => {
  return {
    type: FETCH_TEAM_V2_SUCCESS_API,
    payload,
  };
};

export const addTeamV2Api = (payload: AddTeamV2): { type: string; payload: AddTeamV2 } => {
  return {
    type: ADD_TEAM_V2_API,
    payload,
  };
};

export const addTeamV2SuccessApi = (
  payload: AddTeamV2Response,
): { type: string; payload: AddTeamV2Response } => {
  return {
    type: ADD_TEAM_V2_SUCCESS_API,
    payload,
  };
};

export const addTeamV2FailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: ADD_TEAM_V2_FAILURE_API,
    payload,
  };
};

export const updateTeamV2Api = (payload: UpdateTeamV2): { type: string; payload: UpdateTeamV2 } => {
  return {
    type: UPDATE_TEAM_V2_API,
    payload,
  };
};

export const updateTeamV2SuccessApi = (
  payload: UpdateTeamV2Response,
): { type: string; payload: UpdateTeamV2Response } => {
  return {
    type: UPDATE_TEAM_V2_SUCCESS_API,
    payload,
  };
};

export const updateTeamV2FailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_TEAM_V2_FAILURE_API,
    payload,
  };
};

export const fetchTeamV2FailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_TEAM_V2_FAILURE_API,
    payload,
  };
};

export const addTeamMemberFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: ADD_TEAM_MEMBER_FAILURE_API_V2,
    payload,
  };
};

export const addTeamMemberApiV2 = (
  payload: AddOrUpdateTeamMemberV2,
): { type: string; payload: AddOrUpdateTeamMemberV2 } => {
  return {
    type: ADD_TEAM_MEMBER_API_V2,
    payload,
  };
};

export const addTeamMemberSuccessApiV2 = (
  users: TeamUserResponse[],
): { type: string; payload: AddOrUpdateTeamMemberSuccessPayload } => {
  return {
    type: ADD_TEAM_MEMBER_SUCCESS_API_V2,
    payload: { success: true, message: 'inviteMember', users },
  };
};

export const addTeamMemberFailureApiV2 = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: ADD_TEAM_MEMBER_FAILURE_API_V2,
    payload,
  };
};

export const updateTeamMemberApiV2 = (
  payload: AddOrUpdateTeamMemberV2,
): { type: string; payload: AddOrUpdateTeamMemberV2 } => {
  return {
    type: UPDATE_TEAM_MEMBER_API_V2,
    payload,
  };
};

export const updateTeamMemberSuccessApiV2 = (
  users: TeamUserResponse[],
): { type: string; payload: AddOrUpdateTeamMemberSuccessPayload } => {
  return {
    type: UPDATE_TEAM_MEMBER_SUCCESS_API_V2,
    payload: { success: true, message: 'updateMember', users },
  };
};

export const updateMembersOwnerSuccessApiV2 = (uuid: string): { type: string; payload: any } => {
  return {
    type: UPDATE_MEMBERS_OWNER_SUCCESS_API_V2,
    payload: { uuid },
  };
};

export const updateTeamMemberFailureApiV2 = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_TEAM_MEMBER_FAILURE_API_V2,
    payload,
  };
};

export const updateTeamMemberRoleApi = (
  payload: UpdateMemberRole,
): { type: string; payload: UpdateMemberRole } => {
  return {
    type: UPDATE_TEAM_MEMBER_ROLE_API,
    payload,
  };
};

export const updateTeamMemberRoleSuccessApi = (
  payload: SuccessResponse,
): { type: string; payload: SuccessResponse } => {
  return {
    type: UPDATE_TEAM_MEMBER_ROLE_SUCCESS_API,
    payload,
  };
};

export const updateTeamMemberRoleFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_TEAM_MEMBER_ROLE_FAILURE_API,
    payload,
  };
};

export const deleteTeamMemberApi = (uuid: string): { type: string; payload: { uuid: string } } => {
  return {
    type: DELETE_TEAM_MEMBER_API,
    payload: { uuid },
  };
};

export const deleteTeamMemberSuccessApi = (
  payload: SuccessResponse,
): { type: string; payload: SuccessResponse } => {
  return {
    type: DELETE_TEAM_MEMBER_SUCCESS_API,
    payload,
  };
};

export const deleteTeamMemberFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: DELETE_TEAM_MEMBER_FAILURE_API,
    payload,
  };
};

export const deleteTeamMemberFromTeamApi = (
  userId: string,
  teamId: string,
): { type: string; payload: { userId: string; teamId: string } } => {
  return {
    type: DELETE_TEAM_MEMBER_FROM_TEAM_API,
    payload: { userId, teamId },
  };
};

export const deleteTeamMemberFromTeamSuccessApi = (
  payload: SuccessResponse,
): { type: string; payload: SuccessResponse } => {
  return {
    type: DELETE_TEAM_MEMBER_FROM_TEAM_SUCCESS_API,
    payload,
  };
};

export const deleteTeamMemberFromTeamFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: DELETE_TEAM_MEMBER_FROM_TEAM_FAILURE_API,
    payload,
  };
};

export const deleteTeamMemberFromAllTeamsApi = (
  userId: string,
): { type: string; payload: { userId: string } } => {
  return {
    type: DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_API,
    payload: { userId },
  };
};

export const deleteTeamMemberFromAllTeamsSuccessApi = (
  payload: SuccessResponse,
): { type: string; payload: SuccessResponse } => {
  return {
    type: DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_SUCCESS_API,
    payload,
  };
};

export const deleteTeamMemberFromAllTeamsFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_FAILURE_API,
    payload,
  };
};

export const resendTeamMemberInvitationApi = (
  payload: ResendTeamMemberInvitation,
): { type: string; payload: ResendTeamMemberInvitation } => {
  return {
    type: RESEND_TEAM_MEMBER_INVITATION_API,
    payload,
  };
};

export const resendTeamMemberInvitationSuccessApi = (
  payload: SuccessResponse,
): { type: string; payload: SuccessResponse } => {
  return {
    type: RESEND_TEAM_MEMBER_INVITATION_SUCCESS_API,
    payload,
  };
};

export const resendTeamMemberInvitationFailureApi = (
  payload: ErrorResponse,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: RESEND_TEAM_MEMBER_INVITATION_FAILURE_API,
    payload,
  };
};

export const deleteTeamApi = (uuid: string): { type: string; payload: { uuid: string } } => {
  return {
    type: DELETE_TEAM_API,
    payload: { uuid },
  };
};

export const deleteTeamSuccessApi = (
  payload: SuccessResponse & { uuid: string },
): { type: string; payload: SuccessResponse & { uuid: string } } => {
  return {
    type: DELETE_TEAM_SUCCESS_API,
    payload,
  };
};

export const fetchTeamMembersApi = (
  payload?: PaginationProps,
): { type: string; payload: PaginationProps | string } => {
  const { limit, offset } = handlePagination(payload);
  return {
    type: FETCH_TEAM_MEMBERS_API,
    payload: { limit, offset },
  };
};

export const fetchTeamMembersSuccessApi = (
  payload: TeamMembersResponse,
): { type: string; payload: TeamMembersResponse } => {
  return {
    type: FETCH_TEAM_MEMBERS_SUCCESS_API,
    payload,
  };
};

export const deleteTeamFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: DELETE_TEAM_FAILURE_API,
    payload,
  };
};

export const fetchTeamMembersFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_TEAM_MEMBERS_FAILURE_API,
    payload,
  };
};
