import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router';
import { isEmpty } from 'lodash';

import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';

import AlertDialog from 'components/dialog';

interface Props {
  showMessage: boolean;
  setLastLocation?: any;
  handleDiscardModal: any;
  title: string;
  description: string;
  confirmLabel: string;
  cancelLabel: string;
  continueAfterDiscard?: boolean;
}

export default function BlockerNavigation({
  showMessage,
  setLastLocation = (): void => void {},
  handleDiscardModal,
  title,
  description,
  confirmLabel,
  cancelLabel,
  continueAfterDiscard = false,
}: Props): JSX.Element {
  const [localLastLocation, setLocalLastLocation] = useState('');
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();
  const [showLeavePageMessage, setShowLeavePageMessage] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    window.onbeforeunload = (): boolean => {
      if (showMessage) {
        return showMessage;
      }
    };
  }, [showMessage]);

  useEffect(() => {
    if (location.state && !isEmpty(localStorage.getItem('lastLocation'))) {
      setShowLeavePageMessage(true);
      localStorage.removeItem('lastLocation');
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <Prompt
        when={showMessage}
        message={(location, action): boolean => {
          if (!isEmpty(action) && action !== 'REPLACE') {
            setShowLeavePageMessage(true);
            setLocalLastLocation(location['pathname']);
            setLastLocation(location['pathname']);
            return !showMessage || !!(location.state && Boolean(location.state['discard']));
          }
        }}
      ></Prompt>
      <AlertDialog
        isOpen={showLeavePageMessage}
        title={t(title)}
        showCloseButton
        onClose={(): void => setShowLeavePageMessage(false)}
        cancel={{
          label: t(cancelLabel),
          onClick: () => setShowLeavePageMessage(false),
          'data-testid': 'alert-cancel-button',
          type: 'gray',
          className: classes.cancelCta,
        }}
        confirm={{
          className: classes.confirmCta,
          label: t(confirmLabel),
          onClick: (): void => {
            handleDiscardModal();
            if (continueAfterDiscard) history.push(localLastLocation, { discard: 'true' });
          },
          'data-testid': 'contact-sales-button',
        }}
      >
        <Typography className={classes.description}>{t(description)}</Typography>
      </AlertDialog>
    </>
  );
}
