import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },

    row: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),

      '& > div': {
        width: '40%',
        minWidth: '220px',
        flexGrow: 1,
      },

      '& div': {
        maxWidth: 'none !important',
      },
    },

    termsLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },

    termsLink: {
      textDecoration: 'none',
      marginLeft: '.2ch',
      color: theme.palette.primary.main,

      '&:hover': {
        textDecoration: 'underline',
      },

      '&:first-of-type': {
        marginRight: '.2ch',
      },
    },

    termContainer: {
      marginTop: theme.spacing(3),
    },

    signupLabel: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginLeft: theme.spacing(1),
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.primary.dark,
      },
    },

    footer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      gap: theme.spacing(3),
    },
    captchaContainer: {
      width: theme.spacing(38),
      height: theme.spacing(9.75),
    },
    captchaError: {
      color: theme.palette.error.main,
      margin: `3px ${theme.spacing(1.75)}px 0px ${theme.spacing(1.75)}px`,
      fontWeight: Number(theme.typography.fontWeightRegular),
      lineHeight: '1.66',
      fontSize: '0.75rem',
    },
  }),
);
