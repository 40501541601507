import React, { useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { Alert } from '@material-ui/lab';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import useStyle from './style';

import AlertDialog from 'components/dialog';

import { getTeamApiResponse, getTeamError } from 'store/features/account/team/team.selector';
import {
  deleteTeamMemberFromTeamApi,
  fetchTeamApi,
  fetchTeamV2Api,
  resetTeamError,
  resetTeamResponse,
} from 'store/features/account/team/team.action';
import { EventService } from 'services';

interface Props {
  member: any;
  fetchTeamPayload: any;
  onClose: () => void;
  teamUuid: string;
  teamName: string;
}

const DeleteTeamMember: React.FC<Props> = ({
  member,
  onClose,
  teamUuid,
  teamName,
  fetchTeamPayload,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const teamResponse = useSelector(getTeamApiResponse);
  const teamErrorMessage = useSelector(getTeamError);
  const getFullName = (member): string => `${member?.firstName || ''} ${member?.lastName || ''}`;
  const fullName = member?.firstName || member?.lastName ? getFullName(member) : member?.email;

  const handleConfirm = (): void => {
    dispatch(deleteTeamMemberFromTeamApi(member.userUuid, teamUuid));
  };

  useEffect(() => {
    if (teamErrorMessage) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: (
          <Trans
            values={{
              fullName: fullName,
              name: teamName,
            }}
            i18nKey="DASHBOARD_TEAM_DELETE_ERROR"
            components={{ bold: <strong /> }}
          ></Trans>
        ),
      });
      dispatch(resetTeamError());
    }
    if (teamResponse) {
      dispatch(fetchTeamApi());
      dispatch(resetTeamResponse());
      dispatch(fetchTeamV2Api(fetchTeamPayload));

      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: (
          <Trans
            values={{
              fullName: fullName,
              name: teamName,
            }}
            i18nKey={
              member?.isMultiTeam
                ? 'DASHBOARD_TEAM_DELETE_SUCCESS'
                : 'DASHBOARD_TEAM_MEMBER_ACCOUNT_DELETE_SUCCESS'
            }
          ></Trans>
        ),
      });
      onClose();
    }
  }, [teamErrorMessage, teamResponse]);

  return (
    <AlertDialog
      showCloseButton
      isOpen={true}
      title={
        member?.isMultiTeam
          ? t('DASHBOARD_TEAM_MEMBER_REMOVE_MODAL')
          : t('DASHBOARD_TEAM_MEMBER_DELETE_MODAL')
      }
      onClose={onClose}
      cancel={{
        label: t('DASHBOARD_TEAM_DELETE_MODAL_CANCEL_CTA'),
        type: 'gray',
        onClick: onClose,
      }}
      confirm={{
        label: member?.isMultiTeam
          ? t('DASHBOARD_TEAM_MEMBER_CTA')
          : t('DASHBOARD_TEAM_MEMBER_ACCOUNT_CTA'),
        onClick: handleConfirm,
        type: 'error',
        'data-testid': 'delete-confirm-button',
      }}
    >
      <>
        <div className={classes.container}>
          {!member?.isMultiTeam && (
            <Alert
              severity="warning"
              className={classes.confirmationAlert}
              iconMapping={{ warning: <WarningRoundedIcon fontSize="inherit" /> }}
              data-testid={`delete-warning`}
            >
              <Trans
                i18nKey={'DASHBOARD_TEAMS_DELETE_ACCOUNT_MODAL_BODY_WARNING'}
                values={{
                  fullName: fullName,
                }}
                shouldUnescape={true}
                components={{ bold: <strong /> }}
                tOptions={{ interpolation: { escapeValue: true } }}
              />
            </Alert>
          )}
          <div className={classes.text} id="delete-modal-body">
            <Trans
              i18nKey={
                member?.isMultiTeam
                  ? 'DASHBOARD_TEAM_MEMBER_REMOVE_MODAL_BODY'
                  : 'DASHBOARD_TEAMS_DELETE_ACCOUNT_MODAL_BODY'
              }
              values={{
                name: teamName,
                fullName: fullName,
              }}
              shouldUnescape={true}
              components={{ bold: <strong /> }}
              tOptions={{ interpolation: { escapeValue: true } }}
            />
          </div>
        </div>
      </>
    </AlertDialog>
  );
};

export default DeleteTeamMember;
