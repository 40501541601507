import React from 'react';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { View } from '../view';

import useStyle from './style';

interface BaseProps {
  loadingText?: string;
  loadingStyle?: {
    width: string;
    height: string;
    color?: 'primary' | 'secondary' | 'inherit';
  };
  testId: string;
}

interface Props extends BaseProps {
  isBackdrop?: boolean;
  loading: boolean;
  loadingStyle?: {
    width: string;
    height: string;
    color?: 'primary' | 'secondary' | 'inherit';
  };
}

function LoadingComponent({ loadingText, loadingStyle, testId }: BaseProps): React.ReactElement {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <div className={classes.alignment} data-testid={`${testId}-loading`}>
      <View flexDirection="row" justifyContent="center">
        <CircularProgress style={loadingStyle} color={loadingStyle?.color || 'primary'} />
      </View>
      {loadingText && (
        <View flexDirection="row" justifyContent={'center'}>
          <Typography className={classes.text}>{t(loadingText)}</Typography>
        </View>
      )}
    </div>
  );
}

export default function Loading({
  loading,
  testId,
  loadingText,
  isBackdrop = true,
  loadingStyle,
}: Props): React.ReactElement {
  const classes = useStyle();

  return (
    <>
      {isBackdrop ? (
        <Backdrop open={loading} className={classes.backDrop} data-testid={testId + '-backdrop'}>
          <LoadingComponent loadingText={loadingText} testId={testId} loadingStyle={loadingStyle} />
        </Backdrop>
      ) : (
        <LoadingComponent loadingText={loadingText} testId={testId} loadingStyle={loadingStyle} />
      )}
    </>
  );
}
