import React, { useEffect, useState } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { reportFinancialStatementTypes } from '../../../helpers/report-helpers/constants';

import { ReportFinancialStatementType } from 'types';

interface Props {
  selectedFinancialStatementType: string;
  handleSelected: (string) => void;
}

export default function FinancialStatementType({
  selectedFinancialStatementType: parentSelectedFinancialStatementType,
  handleSelected,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedFinancialStatementType, setSelectedFinancialStatementType] = useState(null);

  useEffect(() => {
    setSelectedFinancialStatementType(parentSelectedFinancialStatementType);
  }, [parentSelectedFinancialStatementType]);

  const onChange = (event): void => {
    setSelectedFinancialStatementType(event.target.value as ReportFinancialStatementType);
    handleSelected && handleSelected(event.target.value as ReportFinancialStatementType);
  };

  return (
    <TextField
      fullWidth
      label={t('DASHBOARD_REPORT_FINANCIAL_STATEMENT_TYPE_LABEL')}
      placeholder={t('DASHBOARD_REPORT_REPORT_TYPE_PLACEHOLDER')}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      select
      value={selectedFinancialStatementType || ''}
      onChange={onChange}
      size="small"
      inputProps={{ 'data-testid': 'financial-statement-type-input-access' }}
      data-testid="financial-statement-type-input"
    >
      {reportFinancialStatementTypes.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
