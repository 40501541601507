import React from 'react';

import { HttpStatusCode } from 'axios';

import { HttpStatus, HttpStatusError } from '../../../types';

import Status404 from 'assets/images/Error404.svg';

import Status202 from 'assets/images/status202.png';
import Status204 from 'assets/images/status204.png';
import Status500 from 'assets/images/status500.png';

interface Props {
  style?: {
    width: number;
    height: number;
  };
  statusCode: number;
}

const styles = {
  height: 57,
};

export default function ErrorIcon({ style, statusCode }: Props): React.ReactElement {
  switch (statusCode) {
    case HttpStatusError.INTERNAL_SERVER_ERROR:
      return <img src={Status500} style={style || styles} alt={''} />;
    case HttpStatusError.NOT_FOUND:
      return <img src={Status404} style={style || styles} alt={''} />;
    case HttpStatus.NO_DATA || HttpStatusCode.UnprocessableEntity:
      return <img src={Status204} style={style || styles} alt={''} />;
    case HttpStatus.PENDING:
      return <img src={Status202} style={style || styles} alt={''} />;
    default:
      return null;
  }
}
