import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import {
  doLoginApi,
  doLoginGoogleApi,
  doLoginMicrosoftApi,
  getErrorResponse,
  isMfaEnabled,
  isSandbox,
  isUserLoggedIn,
  resetAuthError,
  resetAuthInformation,
} from '../../store/features/auth';

import LoginForm from './login-form';

import useStyle from './style';

import GoogleLogin from './sso/google';
import MicrosoftLogin from './sso/microsoft';

import { View } from 'components';
import { getRedirectToSandbox, showSnackbar } from 'helpers/common.helper';
import { useEventContext } from 'providers/events-provider';

import useQuerySearch from 'hooks/use-query-search';

import SignInBg from 'assets/images/SignInBg.svg';
import LayoutImage from 'pages/layout-image/layout-image.page';

const Login: React.FC = () => {
  const classes = useStyle();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const query = useQuerySearch();
  const dispatch = useDispatch();

  const { setInitialized } = useEventContext();
  const [isGoogleLoginExecuted, setGoogleLoginExecuted] = useState(false);
  const error = useSelector(getErrorResponse);
  const isLoggedIn = useSelector(isUserLoggedIn);
  const sandbox = useSelector(isSandbox);
  const isMfa = useSelector(isMfaEnabled);

  useEffect(() => {
    setInitialized(false);
    dispatch(resetAuthInformation());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const code = query.get('code');
    if (code && !isGoogleLoginExecuted) {
      loginGoogle(code);
      setGoogleLoginExecuted(true);
    }
  }, [query]);

  useEffect(() => {
    if (error) {
      dispatch(resetAuthError());
      if (error?.statusCode === 403 && error?.message?.includes('Verify email')) {
        history.replace('/verification');
      } else {
        if (error.code === 106 || error.code === 107) {
          renderError(t('DASHBOARD_LOGIN_EMAIL_SSO_REQUIRED'));
          return;
        }
        if (error.code === 109) {
          renderError(t('DASHBOARD_LOGIN_EMAIL_SSO_PASSWORD'));
          return;
        }

        const errorExists = error?.message?.length > 0 && i18n.exists(error.message[0]);
        renderError(errorExists ? t(error.message[0]) : t('DASHBOARD_LOGIN_FAILED'));
      }
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (isMfa) {
      history.replace('/login/mfa');
    }
    // eslint-disable-next-line
  }, [isMfa]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!sandbox && getRedirectToSandbox()) {
        // if after logging in, we are not in sandbox,
        // it means that the role is not supposed to enter sandbox mode
        history.push('/');
        showSnackbar({
          message: t('DASHBOARD_ENUM_ERROR_MESSAGE_RESTRICTED_SANDBOX'),
          type: 'error',
        });
      } else {
        history.push(query.get('redirect') || '/');
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const renderError = (msg = t('DASHBOARD_LOGIN_FAILED')): void => {
    showSnackbar({
      message: msg,
      type: 'warning',
    });
  };

  const login = async (values): Promise<void> => {
    dispatch(doLoginApi(values));
  };

  const loginMicrosoft = async (values): Promise<void> => {
    dispatch(doLoginMicrosoftApi(values));
  };

  const loginGoogle = async (values): Promise<void> => {
    dispatch(doLoginGoogleApi(values));

    query.delete('code');
    query.delete('scope');
    query.delete('prompt');
    query.delete('hd');
    query.delete('authuser');

    history.replace({ search: query.toString() });
  };

  return (
    <LayoutImage image={SignInBg}>
      <div>
        <Typography className={classes.header}>{t('DASHBOARD_SIGNIN_HEADER')}</Typography>
        <Typography className={classes.description}>{t('DASHBOARD_SIGNIN_SUBHEADER')}</Typography>
        <LoginForm login={login} />

        <View className={classes.ssoLogin}>
          <GoogleLogin />
          <MicrosoftLogin
            onSuccess={async (accessToken): Promise<void> => {
              loginMicrosoft(accessToken);
            }}
            onFailure={(): void => {
              renderError();
            }}
          />
        </View>
        <Typography>
          {t('DASHBOARD_SIGNIN_CTA_SIGNUP')}{' '}
          <Link to="/signup" className={classes.signUpLabel}>
            {t('DASHBOARD_SIGNIN_CTA_SIGNUP_LINK_LABEL')}
          </Link>
        </Typography>
      </div>
    </LayoutImage>
  );
};

export default Login;
