import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes rotate': {
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    root: {
      margin: '1px 0',
      '& h4': {
        textTransform: 'uppercase',
        color: theme.palette.grey[800],
        fontWeight: 600,
        fontSize: '0.75rem',
        padding: `${theme.spacing(0.5)}px 0`,
        marginBottom: theme.spacing(2),
        letterSpacing: 1,
      },

      '& .resetLoading': {
        animation: `$rotate 1.05s linear infinite`,
      },
    },
    form: {
      padding: theme.spacing(3),

      ['@media screen and (min-width: 1080px)']: {
        overflow: 'auto',
        height: '100%',
      },
    },
    introduction: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
      marginBottom: theme.spacing(1),
      '& h2': {
        marginBottom: theme.spacing(2),
        fontSize: '1.25rem',
      },
    },
    header: {
      display: 'flex',
    },
    subHeader: {
      fontWeight: 600,
      fontSize: '.75rem',
      marginBottom: theme.spacing(1),
      color: theme.palette.grey[800],
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
      '&.extraMargin': {
        marginBottom: theme.spacing(2),
      },
    },
    saveSitesUrlContainer: {
      marginTop: theme.spacing(3),
    },
    title: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    subTitle: {
      fontWeight: 600,
      fontSize: '.75rem',
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[800],
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
    },
    description: {
      fontWeight: 400,
      fontSize: '1rem',
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[800],
    },
    headingTitle: {
      fontWeight: 600,
      fontSize: '.75rem',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[800],
      letterSpacing: '0.02em',
      paddingRight: theme.spacing(0.7),
    },
    labelDescription: {
      fontWeight: 600,
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
      backgroundColor: '#ffffff',
      position: 'sticky',
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 1,
      padding: theme.spacing(2),

      ['@media screen and (min-width: 1080px)']: {
        position: 'absolute',
      },
    },
    classAccordion: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    button: {
      fontSize: '16px',
      color: '#fff',
      '&:disabled': {
        color: '#000',
      },
    },
    headingTitleContainer: {
      display: 'flex',
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(4.5),
    },
    label: {
      fontSize: '0.75rem',
      paddingRight: theme.spacing(0.7),
    },
    colorSelectors: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      maxWidth: '480px',
      boxSizing: 'border-box',
    },
    colorSelector: {
      flexGrow: 1,
      boxSizing: 'border-box',
      width: '45%',
      minWidth: '140px',
    },
    iconInputUrl: {
      color: theme.palette.grey[800],
      paddingRight: 0,
      flexShrink: 0,
      height: theme.spacing(5),
      width: theme.spacing(5),
      padding: 0,
    },
    error: {
      fontSize: '12px',
      color: 'red',
    },
    uploadSubtitle: {
      color: '#4F4F4F',
      fontSize: '0.75rem',
      marginTop: theme.spacing(1),
    },
    uploadDisabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    brandDisabled: {
      opacity: 0.5,
    },
    clipBoardTextWithInputs: {
      fontSize: '0.75rem',
      marginBottom: theme.spacing(1),
    },
    clipBoardText: {
      fontSize: '0.75rem',
      marginTop: theme.spacing(0.5),
    },
    containerRefreshLink: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(),
    },
    linkLearnMore: {
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    createdAt: {
      color: theme.palette.grey[800],
      fontSize: '0.75rem',
    },
    inputContainer: {
      width: '100%',
    },
    brandResetContainer: {
      marginTop: theme.spacing(),
    },
    contentInputItem: {
      marginBottom: theme.spacing(2),
    },
    toggleContainer: {
      display: 'flex',
      maxWidth: '480px',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    toggleSwitchContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    toggleSwitchText: {
      fontSize: '0.875rem',
      paddingRight: theme.spacing(1),
      color: '#4F4F4F',
    },
    toggleText: {
      fontWeight: theme.typography.fontWeightMedium as number,
    },
    discardCta: {
      backgroundColor: '#F9F9F9',
      '& span': {
        color: '#424242',
        fontSize: '1rem',
      },
      marginRight: theme.spacing(2),
    },
  }),
);
