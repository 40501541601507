import React, { useRef, useState } from 'react';
import { ClickAwayListener, Tooltip as MuiTooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import useStyles from './styles';

export const TooltipIcon = ({
  title,
  id,
  className,
  bigIcon,
}: {
  id: string;
  title: string;
  className?: string;
  bigIcon?: boolean;
}): JSX.Element => {
  const ref = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={(): void => setOpen(false)}>
      <div
        className={`${classes.tooltipContainer} ${className}`}
        ref={ref}
        data-testid="tooltip-container"
      >
        <MuiTooltip
          title={title}
          arrow
          placement="left"
          disableHoverListener
          disableTouchListener
          PopperProps={{
            className: classes.popper,
            anchorEl: ref.current,
            ref: () => ref.current,
          }}
          id={id || 'tooltip'}
          onClick={(event): void => {
            event.stopPropagation();
            event.bubbles = false;
            setOpen(!open);
          }}
          onPointerEnter={(): void => setOpen(true)}
          onPointerLeave={(): void => setOpen(false)}
          open={open}
        >
          <HelpIcon
            className={`${classes.tooltipIcon} ${bigIcon && 'bigIcon'}`}
            data-opened={open}
          />
        </MuiTooltip>
      </div>
    </ClickAwayListener>
  );
};
