/* eslint-disable max-len */
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR, DEFAULT_SECONDARY_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      margin: 0,
    },

    header: {
      lineHeight: 1.6,
      display: 'flex',
      gap: theme.spacing(2),
    },

    title: {
      fontWeight: 600,
      fontSize: '1rem',
      display: 'contents',
    },

    chip: {
      fontSize: '0.75rem',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },

    chipInactive: {
      color: theme.palette.grey['800'],
      backgroundColor: theme.palette.grey['200'],
    },

    chipActive: {
      color: ACTIVE_TEXT_COLOR,
      backgroundColor: DEFAULT_SECONDARY_COLOR['main'],
    },

    description: {
      maxWidth: 732,
      margin: `0px 0px ${theme.spacing()}px 0px`,
    },

    formGrid: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
    },

    radioInputs: {
      '&.MuiFormGroup-root': {
        gap: theme.spacing(4),
        marginBottom: theme.spacing(1.5),
      },
      '&.MuiFormGroup-root .MuiFormControlLabel-root:first-child': {
        marginLeft: 0,
      },
      '& .MuiFormControlLabel-root ': {
        '& .MuiIconButton-label, & .MuiTouchRipple-root': { margin: 0 },
      },
    },

    methodAndAuthUrlInput: {
      '& .MuiInput-underline:hover, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:before':
        {
          // this removes the underlines of the select input
          borderWidth: 0,
          borderColor: 'transparent',
        },
      '& .MuiInputBase-root > .MuiFormControl-root.MuiTextField-root': {
        // this is the select input styles
        width: 80,
        minWidth: 80,
        '& .MuiInput-formControl': {
          marginRight: 12,
          borderRight: `1px solid ${theme.palette.grey[400]}`,
          height: 24,
        },
      },
      '& .MuiInputBase-root.Mui-focused .MuiInput-formControl': {
        // color change on focus and error
        borderRightColor: theme.palette.primary.main,
      },
      '& .MuiInputBase-root.Mui-error  .MuiInput-formControl': {
        borderRightColor: theme.palette.error.main,
      },
      '& input.MuiInputBase-inputAdornedStart': {
        // this reduces a weird padding, where the input was really tall, for some reason, and pushes the url to the right
        padding: `8px ${theme.spacing(2)}px`,
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        // this is the label for the input, moving to its correct place (inside the input or on top)
        '&.Mui-focused, &.MuiFormLabel-filled': {
          transform: 'translate(100px, -6px) scale(0.75)',
        },
        '&:not(.MuiFormLabel-filled):not(.Mui-focused)': {
          transform: 'translate(100px, 12px)',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        // this moves the "hole" of the top border of the input closer to the center
        padding: '0px 94px',
      },
      '& .MuiFormLabel-filled + .MuiInputBase-root legend, .MuiInputBase-root.Mui-focused legend': {
        // this shows the hole of the top border of the input
        maxWidth: '1000px',
      },
      '& .MuiInputBase-root legend': {
        // this hides the hole of the top border of the input
        maxWidth: 0,
      },
    },
    button: {
      margin: `${theme.spacing(3)}px 0px ${theme.spacing()}px`,
      height: `${theme.spacing() * 5}px`,
    },
    buttonTextStyle: {
      fontSize: '0.875rem',
    },
  });
});
