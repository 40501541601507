import React from 'react';
import MaterialUiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

import { Typography } from '@material-ui/core';

import { Link } from 'react-router-dom';

import style from './style';

import VectorStart from 'assets/icons/vector-start';
interface Props {
  header: React.ReactNode;
  children: React.ReactNode;
  showAccordion: boolean;
  showInfoIcon?: boolean;
  className?: string;
  hideSides?: boolean;
  showPlanMessage?: boolean;
  planMessage?: string;
  classNameAccordion?: string;
  muiProps?: object;
  dataTestId?: string;
  accordianRef?: any;
}

export default function Accordion({
  header,
  showAccordion = true,
  showInfoIcon = false,
  children,
  className = '',
  classNameAccordion = '',
  hideSides = false,
  showPlanMessage = false,
  planMessage = '',
  muiProps = {},
  dataTestId,
  accordianRef = null,
}: Props): JSX.Element {
  const classes = style();

  return (
    <div className={[classes.root, className].join(' ')}>
      <MaterialUiAccordion
        data-testid={dataTestId || 'accordion'}
        {...muiProps}
        className={[
          classes.accordion,
          hideSides ? classes.hideOutline : classes.outline,
          classNameAccordion,
        ].join(' ')}
        ref={accordianRef}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={showAccordion && <ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          data-testid="accordion-summary"
        >
          {showInfoIcon && <InfoIcon className={classes.iconInfo} />}
          {header}
          {showPlanMessage && (
            <Link to="/account/billing" className={classes.linkPlanUpdate} aria-label={planMessage}>
              <Typography className={classes.textPlanUpdate}>
                <span className={classes.iconPlanUpdate}>
                  <VectorStart></VectorStart>
                </span>
                {planMessage}
              </Typography>
            </Link>
          )}
        </AccordionSummary>
        {showAccordion && (
          <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
        )}
      </MaterialUiAccordion>
    </div>
  );
}
