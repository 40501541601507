import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';

import ProgressBarPreview from '../progress-bar-preview';

import ButtonPreview from '../button-preview';

import IntegrationsContent from '../integrations-content';

import useStyle from './style';

interface Props {
  brandColor: string;
  accentColor: string;
  overviewEnabled: boolean;
  summaryEnabled: boolean;
  helpLinkEnabled: boolean;
  helpLinkBody: string;
  termsAndConditionsEnabled: boolean;
  termsAndConditionsBody: string;
  expandedContentSection: string;
  accountingRequired: boolean;
  bankingRequired: boolean;
  commerceRequired: boolean;
}

const OverviewContent: React.FC<Props> = ({
  brandColor,
  accentColor,
  overviewEnabled,
  summaryEnabled,
  helpLinkEnabled,
  helpLinkBody,
  termsAndConditionsEnabled,
  termsAndConditionsBody,
  expandedContentSection,
  accountingRequired,
  bankingRequired,
  commerceRequired,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const isIntegration = expandedContentSection === 'integrations';
  const isSummary = summaryEnabled && !overviewEnabled;
  return (
    <div className={classes.visible} data-testid="overview-preview">
      {overviewEnabled || summaryEnabled || isIntegration ? (
        <>
          <ProgressBarPreview
            summaryEnabled={summaryEnabled}
            overviewEnabled={overviewEnabled}
            isIntegration={isIntegration}
            accentColor={accentColor}
          />
          <Typography className={classes.heading} variant="h4">
            {isSummary ? 'Summary' : 'Share Financial Information'}
          </Typography>

          <ul
            className={classes.servicesList}
            style={{ '--brand-secondary-background': `#${accentColor}` } as React.CSSProperties}
          >
            <li>
              <span className={classes.serviceName}>
                {isSummary && <DoneIcon className={classes.checkmark} />}

                {isIntegration && !accountingRequired ? (
                  <Trans
                    components={{ italic: <i /> }}
                    i18nKey="DASHBOARD_SITES_INTEGRATIONS_ACCOUNTING_OPTIONAL"
                  />
                ) : (
                  t('DASHBOARD_NAV_INTEGRATIONS_ACCOUNTING')
                )}
              </span>
            </li>
            <li>
              <span className={classes.serviceName}>
                {isSummary && <DoneIcon className={classes.checkmark} />}

                {isIntegration && !bankingRequired ? (
                  <Trans
                    components={{ italic: <i /> }}
                    i18nKey="DASHBOARD_SITES_INTEGRATIONS_BANKING_OPTIONAL"
                  />
                ) : (
                  t('DASHBOARD_NAV_INTEGRATIONS_BANKING')
                )}
              </span>
            </li>
            <li>
              <span className={classes.serviceName}>
                {isSummary && <DoneIcon className={classes.checkmark} />}

                {expandedContentSection === 'integrations' && !commerceRequired ? (
                  <Trans
                    components={{ italic: <i /> }}
                    i18nKey="DASHBOARD_SITES_INTEGRATIONS_COMMERCE_OPTIONAL"
                  />
                ) : (
                  t('DASHBOARD_NAV_INTEGRATIONS_COMMERCE')
                )}
              </span>
            </li>
          </ul>

          <ButtonPreview brandColor={brandColor} />

          <div className={classes.linkContainer}>
            {helpLinkEnabled && expandedContentSection === 'help-link' ? (
              <span className={classes.helpLink}>
                {helpLinkBody !== undefined
                  ? helpLinkBody.substring(0, 50)
                  : t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_DIPLAY')}
              </span>
            ) : null}

            {termsAndConditionsEnabled && expandedContentSection === 'terms-and-conditions' ? (
              <span className={classes.helpLink}>
                {termsAndConditionsBody !== undefined
                  ? termsAndConditionsBody.substring(0, 50)
                  : t('DASHBOARD_SIGNUP_PRIVACY_LINK')}
              </span>
            ) : null}
          </div>
        </>
      ) : (
        <IntegrationsContent
          brandColor={brandColor}
          accentColor={accentColor}
          overviewEnabled={overviewEnabled}
          summaryEnabled={summaryEnabled}
          helpLinkEnabled={helpLinkEnabled}
          helpLinkBody={helpLinkBody}
          termsAndConditionsEnabled={termsAndConditionsEnabled}
          termsAndConditionsBody={termsAndConditionsBody}
          expandedContentSection={expandedContentSection}
        />
      )}
    </div>
  );
};

export default OverviewContent;
