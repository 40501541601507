import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      justifyContent: 'flex-start',
      padding: '0',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },

    secret: {
      wordWrap: 'break-word',
    },
  });
});
