import React from 'react';

const Icon = (): JSX.Element => (
  /* eslint-disable */
  <svg
    width="216"
    height="208"
    viewBox="0 0 216 208"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M162.5 107.5L158 104L162 81.5L166 84.5L162.5 107.5Z" fill="#EBFFF0" />
    <path d="M168 121L163.5 117.5L169 87L173 90L168 121Z" fill="#EBFFF0" />
    <path d="M173 134L168.5 130.5L175.5 93L179.5 96L173 134Z" fill="#EBFFF0" />
    <path opacity="0.25" d="M76.5 27.5L27.5 54.5L77 83L76.5 27.5Z" fill="#25E896" />
    <path opacity="0.25" d="M76.8643 83.5L128 55L76.8644 27L76.8643 83.5Z" fill="#AEFFC5" />
    <path opacity="0.25" d="M22 121.5V65L70.5 92.5L22 121.5Z" fill="#219A67" />
    <path
      opacity="0.25"
      d="M70.587 93.0747L70.5 149.575L22.0424 122L70.587 93.0747Z"
      fill="#19564A"
    />
    <path
      d="M180.059 155.022C180.059 155.667 179.76 156.322 179.135 156.962C178.508 157.605 177.576 158.206 176.385 158.722C174.005 159.754 170.682 160.404 166.986 160.404C163.289 160.404 159.967 159.754 157.587 158.722C156.396 158.206 155.464 157.605 154.837 156.962C154.212 156.322 153.912 155.667 153.912 155.022C153.912 154.377 154.212 153.722 154.837 153.082C155.464 152.439 156.396 151.838 157.587 151.322C159.967 150.29 163.289 149.64 166.986 149.64C170.682 149.64 174.005 150.29 176.385 151.322C177.576 151.838 178.508 152.439 179.135 153.082C179.76 153.722 180.059 154.377 180.059 155.022Z"
      fill="#EBFFF0"
      stroke="#757575"
    />
    <path
      d="M137.256 155.783L162.329 141.738L198.246 153.24L172.132 167.828L157.163 163.475"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M173.604 154.093C170.527 153.216 167.672 153.909 166.844 154.846C166.015 155.783 166.743 156.899 168.475 157.593"
      stroke="#757575"
      strokeLinecap="round"
    />
    <ellipse cx="162" cy="155.5" rx="1" ry="0.5" fill="#757575" />
    <ellipse cx="166" cy="152.5" rx="1" ry="0.5" fill="#757575" />
    <path
      d="M16 54.4793V124.609L77.0803 159.899V89.7701M16 54.4793L77.0803 89.7701M16 54.4793L77.0803 20.5458L138.161 54.4793L77.0803 89.7701"
      stroke="#757575"
    />
    <path d="M21.8818 121.894V64.8856L70.2937 92.4848V149.946L21.8818 121.894Z" stroke="#757575" />
    <path
      d="M83.8671 149.493L83.8671 92.4848L132.279 64.4331V73.0296M113.276 131.848L123.682 125.901M132.279 106.511V111.94"
      stroke="#757575"
    />
    <path d="M70.2937 92.9373L21.8818 121.441" stroke="#757575" />
    <path
      d="M76.6548 27.1062L127.443 54.4793L77.1342 83.2842L26.8586 54.78L76.6548 27.1062Z"
      stroke="#757575"
    />
    <path d="M77.1342 83.2842L76.6548 27.1062" stroke="#757575" />
    <path
      d="M138.613 54.4792V80.7212M77.0803 160.352L92.0111 151.459M119 135.5L125.699 131.395M138.613 102.212V111.94"
      stroke="#757575"
    />
    <path
      d="M84.3195 105.153C91.1062 105.153 100.155 106.058 105.132 118.727C108.745 127.924 110 132 120.968 135.92C131.653 139.739 126.849 148.136 133.636 153.565C140.423 158.994 146.757 153.113 155.806 162.162C163.045 169.401 164.553 176.64 164.403 179.355L161.235 177.997L159.426 182.069L156.711 180.712L154.901 185.236L152.186 183.879L150.377 187.951L147.21 186.594L144.947 191.118L141.78 189.308L139.97 193.833L136.803 192.023L134.089 197C133.787 193.38 131.109 185.264 124.594 180.197C116.449 173.862 113.729 178.45 107.394 173.925C101.06 169.401 102.917 162.957 100.155 158.09C96.5355 151.711 88.8261 150.207 84 149M84.3195 120.989L95.1782 114.202"
      stroke="#757575"
    />
    <path d="M126.397 175.735L144.947 163.971" stroke="#757575" />
    <path d="M133.636 182.522L144.043 176.188" stroke="#757575" />
    <path d="M148.115 173.925L154.449 170.306" stroke="#757575" />
    <path
      d="M88.6365 139.205L103.775 130.038M94.7241 146.03L110.325 136.42M86.1291 130.038L89.7487 127.776"
      stroke="#757575"
    />
    <path d="M94.2733 125.061L101.06 120.989" stroke="#757575" />
    <path d="M101.512 151.303L117.348 141.349" stroke="#757575" />
    <path d="M105.584 159.447L125.04 147.683" stroke="#757575" />
    <path d="M107.847 167.591L118.253 161.257" stroke="#757575" />
    <path d="M115.086 173.02L118.705 170.758" stroke="#757575" />
    <path d="M121.42 169.401L136.351 159.899" stroke="#757575" />
    <path
      d="M97.9143 93.3951L131.795 72.6555L150.025 95.4471L121.873 112.598"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path d="M120.063 98.3666L135.446 88.8652" stroke="#757575" />
    <path d="M125.945 100.176L137.256 92.9373" stroke="#757575" />
    <path
      d="M131.813 72.6601L132.256 72.4283C132.192 72.3055 132.081 72.2149 131.947 72.178C131.814 72.1411 131.671 72.1612 131.553 72.2336L131.813 72.6601ZM143.969 96.0511L144.228 96.4776C144.453 96.3402 144.533 96.0524 144.412 95.8192L143.969 96.0511ZM97.8957 93.4102L97.6359 92.9837C97.4113 93.1211 97.3309 93.4089 97.452 93.642L97.8957 93.4102ZM110.586 117.837L111.03 117.605L111.03 117.605L110.586 117.837ZM112.173 120.891L111.729 121.122C111.835 121.326 112.066 121.431 112.289 121.377C112.512 121.322 112.671 121.123 112.672 120.893L112.173 120.891ZM112.173 120.885L111.914 120.457C111.765 120.548 111.674 120.708 111.673 120.882L112.173 120.885ZM122.744 114.448L123.003 114.875C123.228 114.738 123.309 114.449 123.188 114.216L122.744 114.448ZM120.61 110.341L120.351 109.915C120.126 110.052 120.046 110.34 120.167 110.573L120.61 110.341ZM131.369 72.8919L143.525 96.2829L144.412 95.8192L132.256 72.4283L131.369 72.8919ZM98.1554 93.8368L132.072 73.0866L131.553 72.2336L97.6359 92.9837L98.1554 93.8368ZM111.03 117.605L98.3393 93.1785L97.452 93.642L110.143 118.069L111.03 117.605ZM112.616 120.659L111.03 117.605L110.143 118.069L111.729 121.122L112.616 120.659ZM111.673 120.882L111.673 120.888L112.672 120.893L112.673 120.887L111.673 120.882ZM122.485 114.021L111.914 120.457L112.432 121.312L123.003 114.875L122.485 114.021ZM123.188 114.216L121.054 110.109L120.167 110.573L122.3 114.68L123.188 114.216ZM120.87 110.768L144.228 96.4776L143.709 95.6246L120.351 109.915L120.87 110.768Z"
      fill="#757575"
    />
    <path
      d="M115.246 91.2357L114.031 88.8952L104.81 94.5393L106.026 96.88L115.246 91.2357Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M151.74 143.097L149.014 144.816C146.582 146.299 142.018 146.712 138.129 144.844C130.349 141.106 123.792 131.428 123.531 123.299C123.365 119.271 125.331 115.737 127.763 114.254L131.397 111.987"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M135.449 122.375C140.899 124.162 143.965 130.089 144.102 133.505C144.133 134.26 144.045 134.935 143.858 135.517"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M142.025 142.616C134.241 138.892 127.687 129.23 127.357 121.044C127.028 112.859 133.123 108.651 140.907 112.376C148.693 116.169 155.247 125.831 155.574 133.949C155.903 142.134 149.811 146.409 142.025 142.616ZM141.222 120.853C137.556 119.116 134.686 120.759 134.842 124.647C134.996 128.467 138.042 133.035 141.782 134.768C145.378 136.577 148.245 134.866 148.092 131.046C147.935 127.158 144.892 122.658 141.222 120.853Z"
      fill="#C7E6D9"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M134.818 124.639L127.389 121.018M139.535 141.2L140.466 134.135"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M173.134 134.216L179.216 95.4572L174.944 92.0976L168.888 130.839L173.134 134.216Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M171.744 93.3556L175.054 92.1364L168.997 130.878L163.943 132.759L165.728 121.343"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M168.866 130.901L163.917 132.754L168.106 136.109L173.034 134.253L168.866 130.901Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M167.82 120.691L172.584 90.1193L168.316 86.7585L163.555 117.309L167.82 120.691Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M165.338 87.9277L168.42 86.7852L163.658 117.336L158.633 119.219L160.342 108.254"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M163.546 117.363L158.619 119.218L162.79 122.548L167.736 120.718L163.546 117.363Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M162.44 107.423L165.953 84.7852L161.694 81.4018L158.181 104.04L162.44 107.423Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M161.799 81.4364L156.783 83.3164L153.269 105.954L158.285 104.075L161.799 81.4364Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
    <path
      d="M158.173 104.094L153.245 105.949L157.436 109.304L162.364 107.449L158.173 104.094Z"
      stroke="#757575"
      strokeLinejoin="round"
    />
  </svg>
  /* eslint-disable */
);

export default Icon;
