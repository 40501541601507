import i18next from 'i18next';

import { generateDropdown } from 'helpers/common.helper';
import { AllDataTypes } from 'pages/data-sync/types/data-sync';
import { EventKind, Webhook, WebhookEvent } from 'types';

export const MAX_WEBHOOKS_PER_EVENT = 5;

/**
 * Generates the list of selectable events for the webhooks, according to the sandbox state.
 */
export const getWebhookEvents = (sandbox: boolean): Array<{ value; name }> => {
  return generateDropdown(EventKind).filter((item) => (sandbox ? item.value !== 'delete' : true));
};

/**
 * Generates stats of the usage of each event type of the webhooks
 * @param otherWebhooks the list of webhooks already created
 * @param sandbox the sandbox state (affects the limit of possible events)
 * @returns an object with the count of each event
 */
export const getEventUsageStats = (
  otherWebhooks: Webhook[],
  sandbox: boolean,
): Record<WebhookEvent, number> => {
  const eventCounter = getWebhookEvents(sandbox).reduce((acc, { value }) => {
    acc[value] = 0;
    return acc;
  }, {});

  return otherWebhooks.reduce((counter, { event }) => {
    event.forEach((value) => {
      counter[value]++;
    });
    return counter;
  }, eventCounter as Record<WebhookEvent, number>);
};

/**
 * Checks if the creation of a webhook would go over the limit of webhooks per event
 * @param otherWebhooks the list of webhooks already created
 * @param sandbox the sandbox state (affects the limit of possible events)
 */
export const webhookCreationWouldGoOverLimit = (
  otherWebhooks: Webhook[],
  sandbox: boolean,
): boolean =>
  Object.values(getEventUsageStats(otherWebhooks, sandbox)).every(
    (v) => v >= MAX_WEBHOOKS_PER_EVENT,
  );

/**
 * Provides the part of label for the webhook request types count (for the dataPerType event)
 * e.g. (3) or (All)
 */
export const getWebhookRequestTypesCountLabel = (
  requestTypesCount: number,
  allDataTypes: AllDataTypes,
): string => {
  if (!requestTypesCount) return '';
  const totalCount = allDataTypes ? Object.values(allDataTypes).length : 0;
  if (requestTypesCount !== totalCount) return ` (${requestTypesCount})`;
  return ` (${i18next.t('DASHBOARD_DEVELOPERS_WEBHOOKS_ENUM_ALL')})`;
};

export enum WEBHOOK_PAYLOAD_TYPE {
  FULL = 'full',
  SIMPLE = 'simple',
}
