import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LinkIcon from '@material-ui/icons/Link';
import CreateIcon from '@material-ui/icons/Create';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { Business } from '../types/interfaces';
import { ActionTypes } from '../types/enums';
import { useBusinessContext } from '../provider/business-event';

interface Props {
  business: Business;
  setCurrentBusiness: (Business) => void;
  setCurrentActionType: (ActionTypes) => void;
}

const BusinessRowMenu: React.FC<Props> = ({
  business,
  setCurrentBusiness,
  setCurrentActionType,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setEvent, setContextBusiness, setOpenUpdateOrCreateModal, setOpenDeleteBusinessModal } =
    useBusinessContext();
  const theme = useTheme();

  const overflowOptions = [
    {
      id: ActionTypes.MANAGE_SERVICES,
      icon: <SettingsIcon />,
      label: t('DASHBOARD_BUSINESS_MENU_MANAGE_SERVICES'),
      ariaLabel: t('DASHBOARD_BUSINESS_MENU_MANAGE_SERVICES_ARIA_LABEL'),
      action: (business: Business): void => {
        setEvent(ActionTypes.MANAGE_SERVICES);
        setContextBusiness(business);
      },
    },
    {
      id: ActionTypes.GENERATE_LINK,
      icon: <LinkIcon />,
      label: t('DASHBOARD_BUSINESS_MENU_GENERATE_LINK'),
      ariaLabel: t('DASHBOARD_BUSINESS_MENU_GENERATE_LINK_ARIA_LABEL'),
      action: (business: Business): void => {
        setCurrentBusiness(business);
        setCurrentActionType(ActionTypes.GENERATE_LINK);
      },
      testid: 'generate-link-option',
    },
    {
      id: ActionTypes.RENAME,
      icon: <CreateIcon />,
      label: t('DASHBOARD_BUSINESS_MENU_RENAME'),
      ariaLabel: t('DASHBOARD_BUSINESS_MENU_RENAME_ARIA_LABEL'),
      action: (business: Business): void => {
        setEvent(ActionTypes.RENAME);
        setContextBusiness(business);
        setOpenUpdateOrCreateModal(true);
      },
    },
    {
      id: ActionTypes.DELETE,
      icon: <DeleteOutlineIcon />,
      label: t('DASHBOARD_BUSINESS_MENU_DELETE'),
      action: (business: Business): void => {
        setCurrentBusiness(business);
        setCurrentActionType(ActionTypes.DELETE);
        setOpenDeleteBusinessModal(true);
      },
      color: theme.palette.error.main,
    },
  ];

  return (
    <>
      <IconButton
        aria-owns={anchorEl ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={(event): void => setAnchorEl(event.currentTarget)}
        data-testid="overflow-menu-button"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(): void => setAnchorEl(null)}
      >
        {overflowOptions.map((option) => {
          if (
            ['new', 'inactive'].includes(business.status) &&
            option.id === ActionTypes.MANAGE_SERVICES
          ) {
            return (
              <MenuItem key={option.id} disabled>
                {option.icon}
                <span style={{ marginLeft: '12px' }}>{option.label}</span>
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={option.id}
              aria-label={option?.ariaLabel}
              onClick={(): void => {
                setAnchorEl(null);
                option.action && option.action(business);
              }}
              data-testid={option.testid}
              style={{ color: option?.color }}
            >
              {option.icon}
              <span style={{ marginLeft: '12px' }}>{option.label}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default BusinessRowMenu;
