import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    field: {
      fontWeight: 600,
      fontSize: '0.75rem',
      color: theme.palette.grey[800],
      paddingRight: theme.spacing(1.25),
    },
    avatar: {
      marginLeft: '-10px',
      width: theme.spacing(9),
      height: theme.spacing(9),
      borderRadius: '50%',
      border: '2px solid white',
      color: 'black',
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      align: 'center',
      backgroundColor: '#E0E0E0',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      '&:last-of-type:not(:nth-of-type(-n+5))': {
        backgroundColor: '#BDBDBD',
      },
    },
    initials: {
      fontSize: '1.75rem',
      textTransform: 'uppercase',
      verticalAlign: 'middle',
      textAlign: 'center',
      margin: '0 auto',
      fontWeight: 500,
    },
    profileIcon: {
      paddingTop: '4px',
      margin: '0 auto',
    },
    icon: {
      width: theme.spacing(4.645),
      height: theme.spacing(4.645),
    },
    containerDetails: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
    labelContainer: {
      paddingTop: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(3),
    },
  }),
);
