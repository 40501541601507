import React from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from './style';

import { FormInput } from 'components/form';
import { PASSWORD_REGEX } from 'helpers/regex.helper';

import { View } from 'components/view';

interface FormValue {
  firstName?: string;
  lastName?: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('DASHBOARD_SIGNUP_FIRST_NAME_REQUIRED')
    .min(2, 'DASHBOARD_PROFILE_FIRST_NAME_CRUD_MIN')
    .max(40, 'DASHBOARD_PROFILE_FIRST_NAME_CRUD_MAX')
    .nullable(),
  lastName: yup
    .string()
    .required('DASHBOARD_SIGNUP_LAST_NAME_REQUIRED')
    .min(2, 'DASHBOARD_PROFILE_LAST_NAME_CRUD_MIN')
    .max(40, 'DASHBOARD_PROFILE_LAST_NAME_CRUD_MAX')
    .nullable(),
  newPassword: yup
    .string()
    .required('DASHBOARD_RESET_PASSWORD_VERIFY_PASSWORD_REQUIRED')
    .matches(PASSWORD_REGEX, 'DASHBOARD_RESET_PASSWORD_VERIFY_NEW_PASSWORD_INVALID')
    .nullable(),
  confirmPassword: yup
    .string()
    .required('DASHBOARD_RESET_PASSWORD_VERIFY_PASSWORD_REQUIRED')
    .oneOf([yup.ref('newPassword'), null], 'DASHBOARD_RESET_PASSWORD_VERIFY_PASSWORD_NOT_MATCHING')
    .nullable(),
});

const initialValues: FormValue = {
  firstName: '',
  lastName: '',
  newPassword: '',
  confirmPassword: '',
};

interface Props {
  onSubmit: (values: any) => Promise<void>;
}

export default function SetupAccountForm({ onSubmit }: Props): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();

  const { handleSubmit, errors, formState, control } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;

  const onSubmitCall = async (values): Promise<void> => {
    onSubmit && onSubmit(values);
  };

  return (
    <View style={{ paddingTop: '16px' }}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitCall)}>
        <div className={classes.row}>
          <FormInput
            defaultValue=""
            label="DASHBOARD_SIGNUP_FIRST_NAME_LABEL"
            placeholder="DASHBOARD_SIGNUP_FIRST_NAME_PLACEHOLDER"
            margin="normal"
            fullWidth
            name="firstName"
            errorobj={errors}
            control={control}
            testid="first-name-input"
          />
          <FormInput
            defaultValue=""
            label="DASHBOARD_SIGNUP_LAST_NAME_LABEL"
            placeholder="DASHBOARD_SIGNUP_LAST_NAME_PLACEHOLDER"
            margin="normal"
            fullWidth
            name="lastName"
            errorobj={errors}
            control={control}
            testid="last-name-input"
          />
        </div>

        <FormInput
          label="DASHBOARD_SIGNUP_PASSWORD_PLACEHOLDER"
          placeholder="DASHBOARD_SIGNUP_PASSWORD_PLACEHOLDER"
          type="password"
          margin="normal"
          fullWidth
          name="newPassword"
          errorobj={errors}
          control={control}
          testid="password-input"
        />
        <FormInput
          label="DASHBOARD_RESET_PASSWORD_VERIFY_CONFIRM_PASSWORD_LABEL"
          placeholder="DASHBOARD_RESET_PASSWORD_VERIFY_CONFIRM_PASSWORD_LABEL"
          type="password"
          margin="normal"
          fullWidth
          name="confirmPassword"
          errorobj={errors}
          control={control}
          testid="confirm-input"
        />
        <RailzButton
          size="large"
          buttonType="submit"
          isDisabled={isSubmitting}
          data-testid="submit"
          label={t('DASHBOARD_SIGNUP_CTA')}
          className={classes.createAccountButton}
        />
      </form>
    </View>
  );
}
