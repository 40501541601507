import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';

import moment, { Moment } from 'moment-timezone';

import { getDateQuarter, hasDefaultSpacing, noFrequency } from '../../helpers/report-helpers/utils';

import useStyles from './styles';

import Frequencies from './frequencies';

import StartDate from './start-date';

import EndDate from './end-date';

import SaveButton from './save-button';

import Status from './status';

import FinancialStatementType from './financialStatementType';

import ServiceName from './service-name';

import AccountingMethod from './accounting-method';

import {
  AllReportFrequency,
  QuarterRoundType,
  ReportAccountingMethod,
  ReportFinancialStatementType,
  ReportFrequency,
  ReportQuery,
  ReportStatus,
  ReportType,
} from 'types';
import { Service } from 'helpers/report-helpers/types';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import { Business } from 'pages/business/types/interfaces';

interface Props {
  onReady: (params: Partial<ReportQuery>) => void;
  onSubmit: (params: Partial<ReportQuery>) => Promise<void>;
  setSelectedReportFrequency: (string) => void;
  setSelectedFinancialStatementType: (string) => void;
  setSelectedStatus: (string) => void;
  setSelectedStartDate: (Moment) => void;
  setSelectedEndDate: (Moment) => void;
  setDateView: (string) => void;
  setSelectedConnection: (string) => void;
  setSelectedAccountingMethod: (ReportAccountingMethod) => void;
  startDate: Moment;
  endDate: Moment;
  firstRecordDate?: Date | null;
  maxEndDate?: Moment;
  showFrequency: boolean;
  showStatus: boolean;
  forcedHideFrequency?: boolean;
  showFinancialStatementType?: boolean;
  selectedStatus: ReportStatus;
  selectedFinancialStatementType: ReportFinancialStatementType;
  disableDates: boolean;
  showButtonMenu?: boolean;
  selectedReportType: ReportType;
  dateView: AllReportFrequency;
  selectedService: Service;
  children?: React.ReactNode;
  selectedBusiness: Business;
  selectedConnection: any;
  selectedAccountingMethod: ReportAccountingMethod;
  showAccountingMethod: boolean;
}

export default function ReportFilter({
  children,
  onReady,
  onSubmit,
  setSelectedReportFrequency: parentSelectedReportFrequency,
  setSelectedStatus,
  setSelectedStartDate: parentSetSelectedStartDate,
  setSelectedEndDate: parentSetSelectedEndDate,
  selectedConnection: parentSelectedConnection,
  setDateView,
  setSelectedFinancialStatementType,
  setSelectedConnection: parentSetSelectedConnection,
  setSelectedAccountingMethod,
  selectedFinancialStatementType,
  showStatus,
  disableDates,
  showFrequency,
  forcedHideFrequency,
  showFinancialStatementType,
  selectedStatus = ReportStatus.ALL,
  selectedReportType,
  selectedService,
  dateView,
  startDate: parentStartDate,
  endDate: parentEndDate,
  maxEndDate: parentMaxEndDate,
  selectedAccountingMethod,
  showAccountingMethod,
  selectedBusiness,
}: Props): JSX.Element {
  const classes = useStyles();
  const [querySearch] = useQuerySearchUpdate();
  const [selectedReportFrequency, setSelectedReportFrequency] = useState(ReportFrequency.MONTH);

  const [selectedEndDate, setSelectedEndDate] = useState(parentEndDate || moment());
  const [locales, setLocales] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(parentMaxEndDate || parentEndDate || moment());

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().subtract(1, 'months').startOf(ReportFrequency.MONTH),
  );

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleSelectedReportFrequency = (reportFrequency: ReportFrequency): void => {
    setSelectedReportFrequency(reportFrequency);
    parentSelectedReportFrequency && parentSelectedReportFrequency(reportFrequency);
  };

  const handleSetSelectedStartDate = (startDate: Moment): void => {
    setSelectedStartDate(startDate);
    parentSetSelectedStartDate && parentSetSelectedStartDate(startDate);
  };

  const handleSetSelectedEndDate = (endDate: Moment): void => {
    setSelectedEndDate(endDate);
    parentSetSelectedEndDate && parentSetSelectedEndDate(endDate);
  };

  const handleSelectedServiceName = (selectedBankAccount: any): void => {
    parentSetSelectedConnection(selectedBankAccount);
  };

  const handleUpdateDefaultDates = (): void => {
    let start: Moment = parentStartDate;
    let end: Moment = parentEndDate;
    let maxEndDate: Moment = parentMaxEndDate || parentEndDate;
    let firstRecordDate: Moment = selectedService?.firstRecordDate
      ? moment(selectedService.firstRecordDate)
      : start.clone();

    function getFirstRecord(startDate: Moment): Moment {
      if (startDate.isSameOrBefore(firstRecordDate)) {
        return startDate.clone();
      }
      return firstRecordDate;
    }
    const addInitialSpacer = hasDefaultSpacing(selectedReportType);

    switch (selectedReportFrequency) {
      case ReportFrequency.YEAR:
        start = moment(maxEndDate).subtract(1, ReportFrequency.YEAR).startOf(ReportFrequency.YEAR);
        end = moment(start).clone().endOf(ReportFrequency.YEAR);
        maxEndDate = moment(maxEndDate).endOf(ReportFrequency.YEAR);
        firstRecordDate = getFirstRecord(start).startOf(ReportFrequency.YEAR);
        break;
      case ReportFrequency.MONTH:
        start = moment(maxEndDate)
          .subtract(addInitialSpacer ? 13 : 1, ReportFrequency.MONTH)
          .startOf(ReportFrequency.MONTH);
        end = moment(start)
          .clone()
          .add(addInitialSpacer ? 12 : 0, ReportFrequency.MONTH)
          .endOf(ReportFrequency.MONTH);
        maxEndDate = moment(maxEndDate).endOf(ReportFrequency.MONTH);
        firstRecordDate = getFirstRecord(start).startOf(ReportFrequency.MONTH);
        break;
      case ReportFrequency.QUARTER:
        start = getDateQuarter(maxEndDate, QuarterRoundType.START);
        end = getDateQuarter(start.clone(), QuarterRoundType.END);
        maxEndDate = moment(maxEndDate).endOf(ReportFrequency.QUARTER);
        firstRecordDate = getDateQuarter(firstRecordDate, QuarterRoundType.START);
        break;
      default:
        start = moment(maxEndDate)
          .subtract(1, ReportFrequency.MONTH)
          .startOf(ReportFrequency.MONTH);
        end = start.clone().endOf(ReportFrequency.MONTH);
        maxEndDate = moment(maxEndDate);
        firstRecordDate = getFirstRecord(start);
        break;
    }
    const differenceStart = moment(start).diff(moment(firstRecordDate), 'day');
    start = differenceStart >= 1 ? start : firstRecordDate;
    // if reportType is financial forecast, we allow to select end date in the future for 36 months
    // also we set default start and end date a month later to reflect forecast
    if (selectedReportType === ReportType.FINANCIAL_FORECASTS) {
      start = moment(start).add(1, ReportFrequency.MONTH);
      end = start.clone().endOf(ReportFrequency.MONTH);
      maxEndDate = moment(parentMaxEndDate || parentEndDate).add(36, ReportFrequency.MONTH);
      switch (selectedReportFrequency) {
        case ReportFrequency.YEAR:
          maxEndDate = moment(maxEndDate).endOf(ReportFrequency.YEAR);
          break;
        case ReportFrequency.QUARTER:
          maxEndDate = moment(maxEndDate).endOf(ReportFrequency.QUARTER);
          break;
        default:
          maxEndDate = moment(maxEndDate).endOf(ReportFrequency.MONTH);
          break;
      }
    }
    setMaxEndDate(maxEndDate);
    setDateView(selectedReportFrequency || AllReportFrequency.ALL);

    const queryStart = querySearch.get('startDate') ? moment(querySearch.get('startDate')) : start;
    const queryEnd = querySearch.get('endDate') ? moment(querySearch.get('endDate')) : end;
    onReady({ startDate: queryStart, endDate: queryEnd });
    handleSetSelectedStartDate(queryStart);
    handleSetSelectedEndDate(queryEnd);
  };

  useEffect(() => {
    handleUpdateDefaultDates();
  }, [selectedService, selectedReportFrequency]);

  useEffect(() => {
    if (selectedReportType && noFrequency(selectedReportType)) {
      handleSelectedReportFrequency(null);
    } else {
      handleSelectedReportFrequency(ReportFrequency.MONTH);
    }
  }, [selectedReportType]);

  useEffect(() => {
    if (!selectedEndDate?.isValid() || !selectedStartDate?.isValid()) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [selectedStartDate, selectedEndDate]);

  const handleStartDateChange = (newDate: Moment): void => {
    if (!newDate) {
      handleSetSelectedStartDate(null);
      return;
    }
    let date: Moment = selectedReportFrequency
      ? newDate.startOf(selectedReportFrequency as any)
      : newDate.startOf('day');
    const minDate = moment(selectedService.firstRecordDate);

    if (date.isBefore(minDate)) {
      if (selectedReportType === ReportType.FINANCIAL_FORECASTS) {
        date = minDate.startOf('month');
      } else {
        date = minDate;
      }
    }

    if (date.isAfter(selectedEndDate)) {
      if (!selectedReportFrequency) {
        handleSetSelectedEndDate(moment(date).add(1, ReportFrequency.MONTH));
      } else {
        handleSetSelectedEndDate(moment(date).endOf(selectedReportFrequency as any));
      }
    }
    handleSetSelectedStartDate(date);
    setLocales({ ...locales, startDate: date.toLocaleString() });
  };

  const handleEndDateChange = (newDate: Moment): void => {
    if (!newDate) {
      handleSetSelectedEndDate(null);
      return;
    }
    const date: Moment = selectedReportFrequency
      ? newDate.endOf(selectedReportFrequency as any)
      : newDate.endOf('day');

    handleSetSelectedEndDate(date);
    setLocales({ ...locales, endDate: date.toLocaleString() });
  };

  return (
    <Grid
      className={classes.root}
      justifyContent="flex-end"
      container
      spacing={2}
      direction="row"
      data-testid="report-filter"
    >
      {children}
      {showAccountingMethod && (
        <Grid item md xs={12} sm>
          <AccountingMethod
            selectedAccountingMethod={selectedAccountingMethod}
            handleSelected={setSelectedAccountingMethod}
          />
        </Grid>
      )}
      {showFinancialStatementType && (
        <Grid item md xs={12} sm>
          <FinancialStatementType
            selectedFinancialStatementType={selectedFinancialStatementType}
            handleSelected={setSelectedFinancialStatementType}
          />
        </Grid>
      )}

      {[ReportType.BANK_TRANSACTIONS, ReportType.BANK_ACCOUNTS, ReportType.BANK_ASSET].includes(
        selectedReportType,
      ) && (
        <Grid item md xs={12} sm>
          <ServiceName
            handleSelected={handleSelectedServiceName}
            selectedBusiness={selectedBusiness}
            selectedConnection={parentSelectedConnection}
          />
        </Grid>
      )}

      {showFrequency && (
        <Grid item md xs={12} sm>
          <Frequencies
            selectedReportFrequency={selectedReportFrequency}
            forcedHideFrequency={forcedHideFrequency}
            reportType={selectedReportType}
            handleSelected={handleSelectedReportFrequency}
          />
        </Grid>
      )}

      {showStatus && (
        <Grid item md xs={12} sm>
          <Status
            selectedReportType={selectedReportType}
            selectedStatus={selectedStatus}
            handleSelected={setSelectedStatus}
          />
        </Grid>
      )}

      <Grid item md xs={12} sm>
        <StartDate
          dateView={dateView}
          selectedReportType={selectedReportType}
          selectedStartDate={selectedStartDate}
          firstRecordDate={selectedService && moment(selectedService.firstRecordDate).toDate()}
          disableDates={disableDates}
          handleSelected={handleStartDateChange}
        />
      </Grid>
      <Grid item md xs={12} sm>
        <EndDate
          dateView={dateView}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          selectedReportFrequency={selectedReportFrequency}
          maxEndDate={maxEndDate}
          firstRecordDate={selectedService && moment(selectedService.firstRecordDate).toDate()}
          disableDates={disableDates}
          handleSelected={handleEndDateChange}
        />
      </Grid>
      <Grid item md xs={12} sm className={classes.saveButtonContainer}>
        <SaveButton submitDisabled={submitDisabled} saveReport={onSubmit} />
      </Grid>
    </Grid>
  );
}
