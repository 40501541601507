import moment from 'moment-timezone';

import { pick } from 'lodash';

import { RVReportTypes, RVFilterAll } from '@railzai/railz-visualizations';

import { RouteComponentProps } from 'react-router-dom';

import {
  AspType,
  ASP_SERVICE_TYPE,
  CalculatedFilterResponse,
  ReportFrequency,
  ReportStatus,
  ReportType,
  ServiceType,
} from '../../types';
import { Business, Connection } from '../../pages/business/types/interfaces';
import { ConnectionStatus } from '../../pages/business/types/enums';

const isTypeAccounting = (connection: Connection): boolean =>
  ASP_SERVICE_TYPE[connection.serviceName] === ServiceType.ACCOUNTING;
const hasData = (connection: Connection): boolean => !!connection.firstRecordDate;
const isActive = (connection: Connection): boolean => connection.status === ConnectionStatus.ACTIVE;

export const calculateBusinessDefaultConnection = (selectedBusiness: Business): Connection => {
  if (!selectedBusiness?.connections?.length) return undefined;

  let singleConnection = selectedBusiness?.connections.find(
    (connection) => isTypeAccounting(connection) && hasData(connection) && isActive(connection),
  );
  if (!singleConnection) {
    singleConnection = selectedBusiness?.connections.find(
      (connection) => isTypeAccounting(connection) && isActive(connection),
    );
  }
  if (!singleConnection) {
    singleConnection = selectedBusiness?.connections.find(
      (connection) => isTypeAccounting(connection) && hasData(connection),
    );
  }
  // Fallback in case there's only banking or commerce connections
  if (!singleConnection) {
    singleConnection = selectedBusiness?.connections?.[0];
  }

  return singleConnection;
};

export const calculateFilter = (
  selectedBusiness: Business,
  reportFrequency = ReportFrequency.MONTH,
): CalculatedFilterResponse => {
  const singleConnection = calculateBusinessDefaultConnection(selectedBusiness);
  const subtractionFrequency = (reportFrequency + 's') as any;
  const endDate = moment()
    .utc()
    .subtract(1, subtractionFrequency)
    .endOf(reportFrequency as any)
    .format('YYYY-MM-DD')
    .toString();
  const firstRecordDate = moment(singleConnection?.firstRecordDate || moment().subtract(1, 'month'))
    .utc()
    .startOf(reportFrequency as any);
  const currentStartDate = moment(endDate)
    .utc()
    .subtract(12, subtractionFrequency)
    .startOf(reportFrequency as any)
    .format('YYYY-MM-DD')
    .toString();
  const difference = moment(endDate).diff(moment(firstRecordDate), reportFrequency as any, true);
  const startDate =
    difference > 0 && difference <= 12
      ? firstRecordDate.format('YYYY-MM-DD').toString()
      : currentStartDate;
  return {
    filter: {
      startDate,
      connectionUuid: singleConnection?.connectionId,
      serviceName: singleConnection?.serviceName,
      businessName: selectedBusiness?.businessName,
      endDate,
      reportFrequency,
    },
    firstRecordDate: firstRecordDate.format('YYYY-MM-DD'),
  };
};

export const clearedOutFilterForCharts = {
  status: undefined,
  startDate: undefined,
  endDate: undefined,
  reportType: undefined,
  reportName: undefined,
  accountingMethod: undefined,
  orderBy: undefined,
  offset: undefined,
  limit: undefined,
  reportFrequency: ReportFrequency.MONTH,
  connectionUuid: undefined,
  serviceName: undefined,
};

export const formatFilter = (filter: RVFilterAll): RVFilterAll => {
  let allParameters;
  if ([RVReportTypes.BILLS, RVReportTypes.INVOICES].includes(filter.reportType)) {
    allParameters = pick(filter, ['startDate', 'endDate', 'reportType', 'connectionUuid']);
  } else {
    allParameters = pick(filter, [
      'startDate',
      'endDate',
      'reportFrequency',
      'reportType',
      'connectionUuid',
    ]);
  }
  return allParameters;
};

export const calculateFilterByQuery = (
  business: Business,
  query: URLSearchParams,
): CalculatedFilterResponse => {
  const reportFrequency = query?.get('reportFrequency') as ReportFrequency;
  const { filter, firstRecordDate } = calculateFilter(
    business,
    reportFrequency || ReportFrequency.MONTH,
  );
  return {
    filter: {
      startDate: query?.get('startDate'),
      serviceName: (query?.get('serviceName') as AspType) || filter?.serviceName,
      connectionUuid: query?.get('connectionUuid') || filter?.connectionUuid,
      businessName: filter?.businessName,
      reportType: (query?.get('reportType') as ReportType) || filter?.reportType,
      endDate: query?.get('endDate'),
      status: query?.get('status') as ReportStatus,
      reportFrequency: reportFrequency || filter?.reportFrequency,
    },
    firstRecordDate,
  };
};

export const setQuerySearch = (
  location: RouteComponentProps['location'],
  history: RouteComponentProps['history'],
  params: { [key: string]: string },
): URLSearchParams => {
  const searchParams = new URLSearchParams(location?.search);
  if (searchParams && params) {
    Object.keys(params).forEach((param) => {
      if (params[param]) {
        searchParams.set(param, params[param]);
      } else {
        searchParams.delete(param);
      }
    });
    if (history) {
      history.replace({ search: searchParams.toString() });
    }
  }
  return searchParams;
};
