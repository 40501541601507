import { all, call, put, takeLatest } from 'redux-saga/effects';

import { isNil } from 'lodash';

import { EXPIRES_IN, SuccessResponse } from '../../../types';

import {
  DO_CHANGE_PASSWORD_API,
  DO_LOGIN_API,
  DO_LOGIN_MFA_API,
  DO_MFA_RECOVERY_API,
  DO_REFRESH_TOKEN_API,
  DO_RESEND_EMAIL_API,
  DO_SIGNUP_API,
  doChangePasswordFailureApi,
  doChangePasswordSuccessApi,
  doLoginFailureApi,
  doLoginMfaFailureApi,
  doLoginMfaSuccessApi,
  doLoginSuccessApi,
  doMfaRecoveryFailureApi,
  doMfaRecoverySuccessApi,
  doRefreshTokenFailureApi,
  doRefreshTokenSuccessApi,
  doResendEmailFailureApi,
  doResendEmailSuccessApi,
  doSignupFailureApi,
  doSignupSuccessApi,
  DO_LOGIN_MICROSOFT_API,
  DO_LOGIN_GOOGLE_API,
  RESET_STORAGE_AUTH,
  resetStoreAuth,
  RESET_AUTH_INFORMATION,
} from './auth.action';
import { default as AuthService } from './auth.service';
import { AuthAction, LoginApiResponse, LoginResponse, VerifyEmailResponse } from './auth.state';

import { getRedirectToSandbox } from 'helpers/common.helper';
import { SENTRY_CONTEXTS, resetAllSentryContexts, updateSentryContext } from 'services';

// Worker Saga: will be fired on DO_LOGIN_API actions
function* doAuthApiCall(action: AuthAction): Generator<any> {
  try {
    if (getRedirectToSandbox()) {
      action.payload.sandbox = true;
    }
    updateSentryContext(SENTRY_CONTEXTS.USER, {
      email: action.payload?.email,
    });

    const loginProfile = (yield call(
      AuthService.login,
      action.payload,
    ) as unknown) as LoginApiResponse;

    if (!isNil(loginProfile)) {
      updateSentryContext(SENTRY_CONTEXTS.USER, {
        email: loginProfile?.email,
        id: loginProfile?.user?.uuid,
      });

      updateSentryContext(SENTRY_CONTEXTS.SANDBOX, { sandbox: !!loginProfile.sandbox });
      if (loginProfile?.user && !loginProfile?.user?.mfaEnabled) {
        loginProfile['expiredOn'] = Date.now() + EXPIRES_IN;
      }
      yield put(doLoginSuccessApi(loginProfile as LoginResponse));
    } else {
      yield put(doLoginFailureApi({ statusCode: 401, message: 'Unauthorized' }));
    }
  } catch (error) {
    yield put(doLoginFailureApi(error));
  }
}

function* doAuthMicrosoftApiCall(action: AuthAction): Generator<any> {
  try {
    const loginProfile = (yield call(
      AuthService.loginMicrosoft,
      action.payload,
    ) as unknown) as LoginApiResponse;

    if (!isNil(loginProfile)) {
      updateSentryContext(SENTRY_CONTEXTS.USER, { email: loginProfile?.email });
      updateSentryContext(SENTRY_CONTEXTS.SANDBOX, { sandbox: !!loginProfile?.sandbox });
      if (loginProfile?.user && !loginProfile?.user?.mfaEnabled) {
        loginProfile['expiredOn'] = Date.now() + EXPIRES_IN;
      }
      yield put(doLoginSuccessApi(loginProfile as LoginResponse));
    }
    // yield put(doLoginSuccessApi(loginProfile as LoginResponse));
  } catch (error) {
    yield put(doLoginFailureApi(error));
  }
}

function* doAuthGoogleApiCall(action: AuthAction): Generator<any> {
  try {
    const loginProfile = (yield call(
      AuthService.loginGoogle,
      action.payload,
    ) as unknown) as LoginApiResponse;

    if (!isNil(loginProfile)) {
      updateSentryContext(SENTRY_CONTEXTS.USER, { email: loginProfile?.email });
      updateSentryContext(SENTRY_CONTEXTS.SANDBOX, { sandbox: !!loginProfile?.sandbox });
      if (loginProfile?.user && !loginProfile?.user?.mfaEnabled) {
        loginProfile['expiredOn'] = Date.now() + EXPIRES_IN;
      }
      yield put(doLoginSuccessApi(loginProfile as LoginResponse));
    }
    // yield put(doLoginSuccessApi(loginProfile as LoginResponse));
  } catch (error) {
    yield put(doLoginFailureApi(error));
  }
}

function* doAuthSaga(): Generator<any> {
  yield takeLatest(DO_LOGIN_API, doAuthApiCall);
}

function* doAuthMicrosoftSaga(): Generator<any> {
  yield takeLatest(DO_LOGIN_MICROSOFT_API, doAuthMicrosoftApiCall);
}

function* doAuthGoogleSaga(): Generator<any> {
  yield takeLatest(DO_LOGIN_GOOGLE_API, doAuthGoogleApiCall);
}

// Worker Saga: will be fired on DO_LOGIN_MFA_API actions
function* doAuthMfaApiCall(action: AuthAction): Generator<any> {
  try {
    const loginProfile = (yield call(
      AuthService.mfaLogin,
      action.payload,
    ) as unknown) as LoginApiResponse;
    if (!isNil(loginProfile)) {
      updateSentryContext(SENTRY_CONTEXTS.USER, { email: loginProfile?.email });
      updateSentryContext(SENTRY_CONTEXTS.SANDBOX, { sandbox: !!loginProfile?.sandbox });
      loginProfile['expiredOn'] = Date.now() + EXPIRES_IN;
      yield put(doLoginMfaSuccessApi(loginProfile as LoginResponse));
    } else {
      yield put(doLoginMfaFailureApi({ statusCode: 401, message: ['Unauthorized'] }));
    }
  } catch (error) {
    yield put(doLoginMfaFailureApi(error));
  }
}

function* doAuthMfaSaga(): Generator<any> {
  yield takeLatest(DO_LOGIN_MFA_API, doAuthMfaApiCall);
}

function* doResendEmailApiCall(action: AuthAction): Generator<any> {
  try {
    const resendProfile = (yield call(
      AuthService.resendVerifyEmail,
      action.payload,
    ) as unknown) as VerifyEmailResponse;
    if (!isNil(resendProfile)) {
      yield put(doResendEmailSuccessApi(resendProfile));
    } else {
      yield put(doResendEmailFailureApi({ statusCode: 401, message: ['Unauthorized'] }));
    }
  } catch (error) {
    yield put(doResendEmailFailureApi(error));
  }
}

function* doResendEmailSaga(): Generator<any> {
  yield takeLatest(DO_RESEND_EMAIL_API, doResendEmailApiCall);
}

function* doSignupApiCall(action: AuthAction): Generator<any> {
  try {
    const response = (yield call(AuthService.signup, action.payload) as unknown) as SuccessResponse;
    if (!isNil(response)) {
      yield put(doSignupSuccessApi(response));
    } else {
      yield put(doSignupFailureApi({ statusCode: 401, message: ['Unauthorized'] }));
    }
  } catch (error) {
    yield put(doSignupFailureApi(error));
  }
}

function* doSignupSaga(): Generator<any> {
  yield takeLatest(DO_SIGNUP_API, doSignupApiCall);
}

function* doMfaRecoveryApiCall(action: AuthAction): Generator<any> {
  try {
    const response = (yield call(
      AuthService.mfaRecovery,
      action.payload,
    ) as unknown) as SuccessResponse;
    if (!isNil(response)) {
      yield put(doMfaRecoverySuccessApi(response));
    } else {
      yield put(doMfaRecoveryFailureApi({ statusCode: 401, message: ['Unauthorized'] }));
    }
  } catch (error) {
    yield put(doMfaRecoveryFailureApi(error));
  }
}

function* doMfaRecoverySaga(): Generator<any> {
  yield takeLatest(DO_MFA_RECOVERY_API, doMfaRecoveryApiCall);
}

function* doRefreshTokenApiCall(action: AuthAction): Generator<any> {
  try {
    const response = (yield call(
      AuthService.refreshToken,
      action.payload,
    ) as unknown) as LoginApiResponse;
    if (!isNil(response)) {
      response['expiredOn'] = Date.now() + EXPIRES_IN;
      response['sandbox'] = !!action?.payload?.sandbox;

      updateSentryContext(SENTRY_CONTEXTS.USER, { email: response.email });
      updateSentryContext(SENTRY_CONTEXTS.SANDBOX, { sandbox: response['sandbox'] });

      yield put(doRefreshTokenSuccessApi(response as LoginResponse));
      yield put(resetStoreAuth());
    } else {
      yield put(doRefreshTokenFailureApi({ statusCode: 401, message: 'Unauthorized' }));
    }
  } catch (error) {
    yield put(doRefreshTokenFailureApi(error));
  }
}

function* doRefreshTokenSaga(): Generator<any> {
  yield takeLatest(DO_REFRESH_TOKEN_API, doRefreshTokenApiCall);
}

function* doChangePasswordApiCall(action: AuthAction): Generator<any> {
  try {
    yield call(AuthService.changePassword, action.payload);
    yield put(doChangePasswordSuccessApi());
  } catch (error) {
    yield put(doChangePasswordFailureApi(error));
  }
}

function* doChangePasswordSaga(): Generator<any> {
  yield takeLatest(DO_CHANGE_PASSWORD_API, doChangePasswordApiCall);
}

function* resetAuthInfo(): Generator<void> {
  yield resetAllSentryContexts();
}

function* resetAuthInfoSaga(): Generator<any> {
  yield takeLatest(RESET_AUTH_INFORMATION, resetAuthInfo);
}

function* refreshStorage(): Generator<any> {
  try {
    yield put({ type: 'FETCH_PROFILE_API', payload: true }),
      // clean storage
      yield all([
        put({ type: 'RESET_STORAGE_BUSINESS' }),
        put({ type: 'RESET_STORAGE_INTEGRATION' }),
        put({ type: 'RESET_STORAGE_CONNECT' }),
        put({ type: 'RESET_STORAGE_API_KEY' }),
        put({ type: 'RESET_STORAGE_WEBHOOK' }),
        put({ type: 'RESET_STORAGE_WEBHOOK_SECRET' }),
        put({ type: 'RESET_STORAGE_REPORT' }),
      ]);

    // load storage
    yield all([
      put({ type: 'FETCH_BUSINESSES_STATS' }),
      put({ type: 'FETCH_BUSINESSES_GET_SERVICES' }),
      put({ type: 'FETCH_DATA_TYPES_API' }),
    ]);
  } catch (error) {
    yield put(doLoginFailureApi(error));
  }
}

function* doRefreshStorage(): Generator<any> {
  yield takeLatest(RESET_STORAGE_AUTH, refreshStorage);
}

export {
  doMfaRecoveryApiCall,
  doAuthApiCall,
  doResendEmailApiCall,
  doSignupApiCall,
  doRefreshTokenApiCall,
  doAuthMfaApiCall,
  doChangePasswordApiCall,
  doAuthMicrosoftApiCall,
  doAuthGoogleApiCall,
  resetAuthInfo,
};
export const authSaga = [
  doAuthSaga(),
  doAuthMfaSaga(),
  doResendEmailSaga(),
  doSignupSaga(),
  doMfaRecoverySaga(),
  doRefreshTokenSaga(),
  doChangePasswordSaga(),
  doAuthMicrosoftSaga(),
  doAuthGoogleSaga(),
  doRefreshStorage(),
  resetAuthInfoSaga(),
];
