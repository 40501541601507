import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import {
  RVReportTypes,
  RVFilterCashflowStatements,
  RVFilterFinancialRatio,
  RVFilterInvoices,
  RVFilterExpenses,
  RVFilterRevenue,
  RVFilterCreditScore,
  RVFilterIncomeStatements,
  RVFilterBills,
  RVFilterBalanceSheet,
  RVFilterBusinessValuations,
} from '@railzai/railz-visualizations';

import {
  RailzFinancialRatios,
  RailzStatementsChart,
  RailzTransactionsControl,
  RailzIncomeStatements,
  RailzCreditScore,
  RailzBusinessValuations,
} from '@railzai/railz-visualizations-react';

import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';

import useStyle from '../../../style';
import { getAccessToken } from '../../../../../store/features/auth';

import Config from '../../../../../config';

import {
  formatFilter,
  clearedOutFilterForCharts,
} from '../../../../../helpers/business-helpers/financial-summary-helper';

import { chartOptions } from '../summary-charts-options';

import BusinessReportFilter from './business-report-filter';

import { ReportFrequency, ReportType } from 'types';
import AccountsTable from 'components/accounts-table';

const SummaryCharts = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [configuration, setConfiguration] = useState(null);
  const [filterInfo, setFilterInfo] = useState(null);
  const [bankFilterInfo, setBankFilterInfo] = useState(null);
  const token = useSelector(getAccessToken);

  useEffect(() => {
    if (token) {
      setConfiguration({
        token,
        endpoint: Config.FEEDER_SERVER,
        debug: Config.DEBUG,
      });
    }
  }, [token]);

  const handleFilterChange = (filter): void => {
    const { connectionUuid, reportFrequency, bankConnectionUuid } = filter;

    const summaryCharsFilter = {
      ...clearedOutFilterForCharts,
      connectionUuid,
      reportFrequency: reportFrequency
        ? (reportFrequency as ReportFrequency)
        : ReportFrequency.MONTH,
      startDate: moment(filter.startDate).format('YYYY-MM-DD'),
      endDate: moment(filter.endDate).format('YYYY-MM-DD'),
    };
    const bankFilter = {
      ...clearedOutFilterForCharts,
      connectionUuid: bankConnectionUuid,
      allBankConnections: filter.allBankConnections,
      reportFrequency: reportFrequency
        ? (reportFrequency as ReportFrequency)
        : ReportFrequency.MONTH,
      startDate: moment(filter.startDate).format('YYYY-MM-DD'),
      endDate: moment(filter.endDate).format('YYYY-MM-DD'),
    };
    bankFilter.connectionUuid && setBankFilterInfo(bankFilter);
    summaryCharsFilter.connectionUuid && setFilterInfo(summaryCharsFilter);
  };

  return (
    <>
      <BusinessReportFilter
        reportName={t('DASHBOARD_SUMMARY_TITLE')}
        forcedReportType={ReportType.FINANCIAL_SUMMARY}
        applyFilter={handleFilterChange}
      />
      <Grid container spacing={2} data-testid="report-summary">
        <Grid container className={classes.summarySubContainer} spacing={2}>
          <Grid item xs={12} md={4}>
            {filterInfo && (
              <RailzTransactionsControl
                data-testid="sdk-invoices"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.INVOICES,
                  }) as RVFilterInvoices
                }
                options={chartOptions}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {filterInfo && (
              <RailzTransactionsControl
                data-testid="sdk-bills"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.BILLS,
                  }) as RVFilterBills
                }
                options={chartOptions}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {filterInfo && (
              <AccountsTable
                testId={'accounts-summary'}
                title={'DASHBOARD_FINANCIAL_SUMMARY_CHART_TITLE_BANKACCOUNTS'}
                allBankConnections={bankFilterInfo?.allBankConnections}
              />
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.summarySubContainer} spacing={2}>
          <Grid item xs={12} md={5}>
            {filterInfo && (
              <RailzIncomeStatements
                data-testid="sdk-expenses"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.EXPENSES,
                  }) as RVFilterExpenses
                }
                options={chartOptions}
              />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            {filterInfo && (
              <RailzIncomeStatements
                data-testid="sdk-revenue"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.REVENUE,
                  }) as RVFilterRevenue
                }
                options={chartOptions}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            {filterInfo && (
              <RailzCreditScore
                data-testid="sdk-credit-score"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.CREDIT_SCORE,
                  }) as RVFilterCreditScore
                }
                options={chartOptions}
              />
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.summarySubContainer} spacing={2}>
          <Grid item xs={12} md={7} lg={8}>
            {filterInfo && (
              <RailzStatementsChart
                data-testid="sdk-cashflow-statements"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.CASHFLOW_STATEMENTS,
                  }) as RVFilterCashflowStatements
                }
                options={chartOptions}
              />
            )}
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            {filterInfo && (
              <RailzFinancialRatios
                data-testid="sdk-financial-ratio"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.FINANCIAL_RATIO,
                  }) as RVFilterFinancialRatio
                }
                options={chartOptions}
              />
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.summarySubContainer} spacing={2}>
          <Grid item xs={12} md={6}>
            {filterInfo && (
              <RailzStatementsChart
                data-testid="sdk-balance-sheet"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.BALANCE_SHEET,
                  }) as RVFilterBalanceSheet
                }
                options={chartOptions}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {filterInfo && (
              <RailzStatementsChart
                data-testid="sdk-income-statements"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.INCOME_STATEMENTS,
                  }) as RVFilterIncomeStatements
                }
                options={chartOptions}
              />
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            {filterInfo && (
              <RailzBusinessValuations
                data-testid="sdk-business-valuations"
                configuration={configuration}
                filter={
                  formatFilter({
                    ...filterInfo,
                    reportType: RVReportTypes.BUSINESS_VALUATIONS,
                  }) as RVFilterBusinessValuations
                }
                options={chartOptions}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryCharts;
