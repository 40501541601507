import React from 'react';

import { Chip } from '@material-ui/core';

import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import { Business } from '../../pages/business/types/interfaces';
import { ConnectionStatus } from '../../pages/business/types/enums';
import { ServiceType } from '../../types';
import { getConnectionServiceName } from '../../helpers/common.helper';
import CircleIndicator from '../circle-indicator';

import { getServices } from '../../store/features/business/business.selector';

import useStyle from './style';

interface Props {
  label?: string;
  testId?: string;
  business: Business;
}

export default function ServiceChip({ business, label, testId }: Props): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyle();

  const services = useSelector(getServices);
  return (
    <>
      {label && (
        <Typography className={classes.title} data-testid={`${testId}-label`}>
          {t(label)}
        </Typography>
      )}
      {Boolean(business.connections) &&
        business.connections
          .filter(({ status }) => status !== ConnectionStatus.PENDING)
          .map((connection) => ({
            ...connection,
            kind: services?.find(({ name }) => name === connection.serviceName)?.kind,
          }))
          .sort((connectionA, connectionB) => {
            const textA = connectionA.serviceName.toUpperCase();
            const textB = connectionB.serviceName.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .sort((connectionA, connectionB) => {
            if (connectionA.status === ConnectionStatus.ACTIVE) {
              if (connectionB.status === ConnectionStatus.ACTIVE) {
                return 0;
              }
              return -1;
            }
            return 1;
          })
          .sort((connectionA, connectionB) => {
            if (connectionA.kind === ServiceType.ACCOUNTING) {
              if (connectionB.kind === ServiceType.ACCOUNTING) {
                return 0;
              }
              return -1;
            }
            return 1;
          })
          .map((connection, index) => {
            return (
              <Chip
                key={index}
                data-testid={`${testId}-${index}`}
                label={getConnectionServiceName(connection)}
                size="small"
                className={classes.chip}
                avatar={<CircleIndicator type={connection.status} />}
                variant="outlined"
              />
            );
          })}
    </>
  );
}
