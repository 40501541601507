import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormGroup, FormHelperText, Grid, Tooltip, Typography, useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import Alert from '@material-ui/lab/Alert';

import useStyles from './style';

import SuccessIcon from './success-icon';

import {
  ApiKey,
  ApiKeyGenerateResponse,
  SPECIAL_CHARS,
  Scope,
  SelectableScopes,
  TranslationLabels,
} from 'types';
import { maskSecret } from 'helpers/api-keys';
import ClipboardInput from 'pages/business/input-clipboard';
import AlertDialog from 'components/dialog';
import { FormCheckBox, FormInput } from 'components/form';

interface Props {
  apiKeys: ApiKey[];
  generatedApiKey?: ApiKeyGenerateResponse;
  onGenerate: (values: Partial<ApiKey>) => void;
  onClose: () => void;
}

const downloadApiKey = (generatedApiKey: ApiKeyGenerateResponse): void => {
  const content = `Identifier: ${generatedApiKey.identifier}\nSecret: ${generatedApiKey.secret}`;
  const blob = new Blob([content], { type: 'text/plain' });

  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = 'api-keys.txt';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

type ScopesFormValueTransformer = (
  formValue: Record<keyof typeof SelectableScopes, boolean>[],
) => { key: string; selected: boolean; value: Scope[] }[];
type ParsedScopesFormValue = ReturnType<ScopesFormValueTransformer>;

const transformer: ScopesFormValueTransformer = (formValue) =>
  formValue.map((scope) => {
    const [key, selected] = Object.entries(scope)[0];
    return {
      key,
      value: SelectableScopes[key],
      selected,
    };
  });

const ScopeInputLabels = Object.entries(SelectableScopes).map(([name, values], index) => {
  const optionlabel =
    values.length > 1
      ? 'DASHBOARD_API_KEY_MODAL_RAILZ_API_OPTION_FULL'
      : 'DASHBOARD_API_KEY_MODAL_VISUALIZATIONS_SDK_OPTION_READ';
  return { inputName: `scopes.${index}.${name}`, label: TranslationLabels[name], optionlabel };
});

export default function ApiKeysGenerate({
  generatedApiKey,
  apiKeys: otherApiKeys,
  onGenerate,
  onClose,
}: Props): JSX.Element {
  const [isIdentifierCopied, setIsIdentifierCopied] = useState(false);
  const [isSecretCopied, setIsSecretCopied] = useState(false);
  const [isSdkWarning, setSdkWarning] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(t('DASHBOARD_API_KEY_REQUIRED'))
      .nullable()
      .min(2, t('DASHBOARD_API_CRUD_MIN'))
      .max(40, t('DASHBOARD_API_CRUD_MAX'))
      .test(
        'hasDuplicate',
        t('DASHBOARD_API_CRUD_DUPLICATE'),
        (value) =>
          !otherApiKeys.find((key) => key.name.trim().toLowerCase() === value.toLowerCase()),
      )
      .test({
        message: t('DASHBOARD_CRUD_FAIL_SPECIAL_CHARS'),
        test: (value) => !SPECIAL_CHARS.some((char) => value.includes(char)),
      }),
    scopes: yup
      .array()
      .transform(transformer)
      .test({
        test: (values: ParsedScopesFormValue) => values.some(({ selected }) => selected),
        message: 'DASHBOARD_API_KEY_PERMISSIONS_ERROR_EMPTY',
      }),
  });

  const { handleSubmit, errors, control, trigger, getValues, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      scopes: undefined,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmitData: (data: { name: string; scopes: ParsedScopesFormValue }) => void = (data) =>
    onGenerate({
      name: data.name.trim(),
      scopes: data.scopes
        .filter(({ selected }) => selected)
        .reduce((acum, { value }) => acum.concat(value), [] as Scope[]),
    });

  const renderInitialContent = (): JSX.Element => (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitData)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInput
            margin="dense"
            className={classes.generateApiKeyText}
            label="DASHBOARD_API_NAME_LABEL"
            variant="outlined"
            autoFocus
            fullWidth
            name="name"
            errorobj={errors}
            control={control}
            testid={`api-key-name-input`}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.listDescription} variant="subtitle2" paragraph={true}>
            {t('DASHBOARD_API_KEY_MODAL_DESCRIPTION')}
          </Typography>
          <FormGroup data-testid={`api-key-scopes-inputs`}>
            <Grid container className={classes.mainGrid}>
              <Grid container className={classes.listElementWrapper}>
                <Grid item xs={8}>
                  <Typography className={classes.tableFontSize}>
                    {t('DASHBOARD_API_KEY_MODAL_HEADER_SCOPE')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.tableFontSize}>
                    {t('DASHBOARD_API_KEY_MODAL_HEADER_PERMISSIONS')}
                  </Typography>
                </Grid>
              </Grid>
              {ScopeInputLabels.map(({ inputName, label, optionlabel }) => {
                return (
                  <Grid key={inputName} container className={classes.listElementWrapper}>
                    <Grid item xs={8} className={classes.listElement}>
                      <Typography variant="subtitle2">{t(label)}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormCheckBox
                        onChange={(): void => {
                          trigger('scopes');
                          const allScopes = getValues().scopes;
                          const selected = transformer(allScopes).filter(
                            ({ selected }) => selected,
                          );
                          const selectedScopes = selected.some(({ key }) => key === 'SDK');
                          setSdkWarning(selected?.length > 1 && selectedScopes);
                        }}
                        testid={`form-${inputName}`}
                        control={control}
                        name={inputName}
                        className={`${classes.listElement} ${classes.checkBox}`}
                        label={t(optionlabel)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <FormHelperText
              className={`MuiFormHelperText-contained error-text Mui-error ${classes.bottomError}`}
            >
              {errors?.scopes && t(errors?.scopes.message)}
            </FormHelperText>
          </FormGroup>
        </Grid>
        {isSdkWarning && (
          <Grid item xs={12} className={classes.confirmationAlert}>
            <Alert
              severity="warning"
              iconMapping={{ warning: <WarningRoundedIcon fontSize="inherit" /> }}
            >
              <Trans i18nKey="DASHBOARD_API_KEY_PERMISSIONS_WARNING" />
            </Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );

  const renderSuccessMessage = (): JSX.Element => (
    <>
      <div className={classes.successDescriptionContainer}>
        <SuccessIcon />
        <Typography>
          <Trans
            i18nKey="DASHBOARD_API_NEW_KEY_DESCRIPTION"
            components={{ bold: <strong className={classes.newKeyDescriptionBold} /> }}
          ></Trans>
        </Typography>
      </div>
      <div>
        <Tooltip title={t('DASHBOARD_API_BACKUP_CTA_COPY')}>
          <>
            <ClipboardInput
              label={t('DASHBOARD_API_IDENTIFIER_LABEL')}
              generatedContent={generatedApiKey.identifier}
              setCopied={(): void => {
                setIsIdentifierCopied(true);
                setIsSecretCopied(false);
              }}
              className={classes.clipboardInput}
              iconStyle={{ fill: theme.palette.grey[800] }}
            ></ClipboardInput>
          </>
        </Tooltip>
        <Typography
          className={classes.copiedToClipboard}
          style={{ display: !isIdentifierCopied ? 'none' : 'inline' }}
        >
          {t('DASHBOARD_GENERATE_LINK_COPIED')}
        </Typography>

        <Tooltip title={t('DASHBOARD_API_BACKUP_CTA_COPY')}>
          <>
            <ClipboardInput
              label={t('DASHBOARD_API_SECRET_LABEL')}
              generatedContent={maskSecret(generatedApiKey.secret)}
              contentToCopy={generatedApiKey.secret}
              setCopied={(): void => {
                setIsSecretCopied(true);
                setIsIdentifierCopied(false);
              }}
              className={classes.clipboardInput}
              iconStyle={{ fill: theme.palette.grey[800] }}
            ></ClipboardInput>
          </>
        </Tooltip>
        <Typography
          className={classes.copiedToClipboard}
          style={{ display: !isSecretCopied ? 'none' : 'inline' }}
        >
          {t('DASHBOARD_GENERATE_LINK_COPIED')}
        </Typography>
      </div>
    </>
  );
  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      title={generatedApiKey ? t('DASHBOARD_API_NEW_KEY_TITLE') : t('DASHBOARD_API_NEW_TITLE')}
      cancel={
        formState.isDirty && {
          label: generatedApiKey
            ? t('DASHBOARD_CLOSE_MODAL')
            : t('DASHBOARD_TEAM_DELETE_MODAL_CANCEL_CTA'),
          onClick: onClose,
          type: 'gray',
        }
      }
      confirm={{
        label: generatedApiKey
          ? t('DASHBOARD_API_BACKUP_CTA_DOWNLOAD')
          : t('DASHBOARD_API_GENERATE_CTA'),
        onClick: generatedApiKey
          ? (): void => downloadApiKey(generatedApiKey)
          : handleSubmit(onSubmitData),
        icon: generatedApiKey ? <GetAppIcon /> : undefined,
        'data-testid': 'api-key-submit',
      }}
      showCloseButton
    >
      {!generatedApiKey ? renderInitialContent() : renderSuccessMessage()}
    </AlertDialog>
  );
}
