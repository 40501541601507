import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';

import makeStyles from './style';

import { setClipboard } from 'helpers/common.helper';

import AlertDialog from 'components/dialog';

interface IProps {
  isOpen: boolean;
  confirm: {
    label: string;
    onClick?: () => void;
  };
  recoveryCode: string;
}

const RecoveryCodeDialog: React.FC<IProps> = ({ isOpen = false, confirm, recoveryCode }) => {
  const { t } = useTranslation();
  const classes = makeStyles();
  const [copied, setCopied] = useState(false);
  const copy = (): void => {
    setClipboard(recoveryCode);
    setCopied(true);
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      confirm={confirm}
      title={t('DASHBOARD_SECURITY_MFA_RECOVERY_CODE_HEADER')}
    >
      <Box>
        <Box
          className={classes.backupCodeContainer}
          border={1}
          borderRadius={6}
          borderColor="grey.500"
        >
          <Typography className={classes.code} color="textSecondary">
            {recoveryCode}
          </Typography>
          <IconButton
            onClick={copy}
            aria-label={t('DASHBOARD_SECURITY_MFA_RECOVERY_CODE_CTA_COPY')}
            data-testid="copy-recovery-code"
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
        {copied && (
          <span className={classes.copiedText}>{t('DASHBOARD_GENERATE_LINK_COPIED')}</span>
        )}

        <Typography id="alert-dialog-description">
          {t('DASHBOARD_SECURITY_MFA_RECOVERY_CODE_DESCRIPTION')}
        </Typography>
      </Box>
    </AlertDialog>
  );
};
export default RecoveryCodeDialog;
