import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import {
  doRefreshTokenApi,
  isRefreshedToken,
  isSandbox,
  resetAuthResponse,
} from '../../../store/features/auth';

import { getUserRole } from '../../../store/features/account/profile/profile.selector';

import drawerItemStyles from './drawer-items.style';

import DrawerSandboxSwitch from './drawer-sandbox-switch';

import DrawerDocsButton from './drawer-docs-button';

import { DrawerItem } from 'layout/helpers/interfaces';
import { getDrawerItems, isItemAllowedPerRole } from 'layout/helpers/util';

import { Role } from 'types';

const isActive = (
  currentPath: string,
  itemPath: string,
  itemChildPaths: string[],
): (() => boolean) => {
  const paths = Array.isArray(itemChildPaths) ? itemChildPaths.concat(itemPath) : [itemPath];
  return () => paths.indexOf(currentPath) !== -1;
};

const ForwardNavLink = React.forwardRef(
  (props: NavLinkProps, ref: React.RefObject<HTMLAnchorElement>) => (
    <NavLink {...props} innerRef={ref} />
  ),
);
ForwardNavLink.displayName = 'ForwardNavLink';

function goBackAndForwardToForceReload(
  history: any,
  newAllowedPaths: DrawerItem[],
  originalPath: string,
): void {
  history.push('/');
  const originalPathIsAllowed = newAllowedPaths.some(
    ({ path, childPaths }) =>
      path === originalPath || (childPaths && childPaths.some((ch) => ch === originalPath)),
  );
  if (originalPathIsAllowed) {
    setTimeout(() => {
      history.push(originalPath);
    }, 0);
  }
}

/**
 * Component responsible of rendering the navbar
 * (logo, collapse button, and logout buttons not included)
 */
const DrawerItems: React.FC<{ drawerOpen: boolean }> = ({ drawerOpen }) => {
  const location = useLocation();
  const [activeChild, setActiveChild] = useState(location.pathname);
  const classes = drawerItemStyles();
  const { t } = useTranslation();
  const [items, setItems] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isSandboxEnabled = useSelector(isSandbox);
  const role = useSelector(getUserRole);
  const userRefreshedToken = useSelector(isRefreshedToken);

  useEffect(() => {
    if (!role) return; // the user is fetched after the login process, so we need to wait for it

    const isSuperAdmin = role === Role.SUPER_ADMINISTRATOR;
    const allowedPaths = getDrawerItems(isSandboxEnabled, isSuperAdmin);
    setItems(allowedPaths);

    if (userRefreshedToken) {
      goBackAndForwardToForceReload(history, allowedPaths, activeChild);
      dispatch(resetAuthResponse());
    }
  }, [role, userRefreshedToken]);

  const handleToggleSandbox = (sandbox: boolean): void => {
    dispatch(doRefreshTokenApi({ sandbox }));
  };

  /**
   * Creates a List Item.
   * @param item A Drawer Item, can be the first level (root) or a leaf.
   * @param key  key of the component
   * @param isChild boolean flag to denote if it's a root or a leaf.
   * @returns a ListItem component (a React Element)
   */
  const getListItem = (item: DrawerItem, key?: string, isChild?: boolean): JSX.Element => {
    if (!isItemAllowedPerRole(item, role)) return null;
    const isActiveFn = isActive(location.pathname, item.path, item.childPaths);
    if (isChild) {
      // Second-Level List Item
      return (
        <ListItem
          button
          data-testid={key}
          aria-label={t(item.title)}
          key={key}
          component={ForwardNavLink}
          to={item.path}
          exact
          activeClassName={classes.activeNavLinkMenu}
          isActive={isActiveFn}
          classes={{ root: classes.listItem }}
        >
          {Boolean(item.icon) && <ListItemIcon>{item.icon}</ListItemIcon>}
          {drawerOpen && <ListItemText primary={t(item.title)} />}
        </ListItem>
      );
    }
    const hasChildrenItems = Array.isArray(item.childs) && item.childs.length;

    if (hasChildrenItems) {
      // First-Level/With-Children  List Item
      return (
        <ListItem
          button
          data-testid={key}
          aria-label={t(item.title)}
          key={key}
          classes={{ root: classes.listItem }}
          className={isActiveFn() ? classes.activeNavLink : ''}
          onClick={(): void => setActiveChild(activeChild === item.path ? null : item.path)}
        >
          {Boolean(item.icon) && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={t(item.title)} classes={{ primary: classes.linkText }} />
          {activeChild === item.path ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      );
    }
    return (
      // First-Level/No-Children  List Item
      <ListItem
        button
        key={key}
        data-testid={key}
        component={ForwardNavLink}
        to={item.path}
        exact
        activeClassName={classes.activeNavLink}
        isActive={isActiveFn}
        classes={{ root: classes.listItem }}
        aria-label={t(item.title)}
      >
        {Boolean(item.icon) && <ListItemIcon>{item.icon}</ListItemIcon>}
        <ListItemText primary={t(item.title)} classes={{ primary: classes.linkText }} />
      </ListItem>
    );
  };

  const showLoadingSpinner = !role || !Array.isArray(items);
  if (showLoadingSpinner) return null;

  return (
    <>
      <List className={classes.root} component={'div'} data-testid="drawer-items">
        {items.map((item: DrawerItem, rootIndex: number) => (
          <React.Fragment key={`${rootIndex}-wrapper`}>
            {
              getListItem(item, `${rootIndex}-root`) // roots
            }

            {Array.isArray(item.childs) && (
              <Collapse in={item.childPaths.includes(activeChild)} timeout="auto" unmountOnExit>
                <List
                  disablePadding
                  style={{ paddingLeft: drawerOpen ? '40px' : 0 }}
                  component={'div'}
                >
                  {
                    item.childs.map((child, leafIndex) =>
                      getListItem(child, `${rootIndex}-${leafIndex}-leaf`, true),
                    ) // leafs
                  }
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
        <DrawerSandboxSwitch
          role={role}
          handleToggleSandbox={handleToggleSandbox}
          isSandboxEnabled={isSandboxEnabled}
          drawerOpen={drawerOpen}
        />
      </List>
      <DrawerDocsButton drawerOpen={drawerOpen} />
    </>
  );
};

export default DrawerItems;
