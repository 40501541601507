import React from 'react';

import IntegrationList from './integration-list';

import { ServiceType } from 'types';

import { withLayout } from 'layout';

export const AccountingIntegrationAccounting = withLayout(() => (
  <IntegrationList type={ServiceType.ACCOUNTING} />
));
export const AccountingIntegrationBanking = withLayout(() => (
  <IntegrationList type={ServiceType.BANKING} />
));
export const AccountingIntegrationCommerce = withLayout(() => (
  <IntegrationList type={ServiceType.COMMERCE} />
));
