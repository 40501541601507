import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import { EXPIRES_IN } from '../../types';
import storeService from '../../store';
import { getExpiredOn, isUserLoggedIn, resetAuthExpiredOn } from '../../store/features/auth';

interface IProps {
  onIdle: any;
}

const IdlTimer = ({ onIdle }: IProps): null => {
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const expiredOn = useSelector<ReturnType<typeof store.getState>, number>(getExpiredOn);
  const isLoggedIn = useSelector<ReturnType<typeof store.getState>, boolean>(isUserLoggedIn);
  useEffect(() => {
    if (expiredOn) {
      const spentTime = expiredOn - Date.now();
      if (spentTime > 0 && isLoggedIn) {
        dispatch(resetAuthExpiredOn());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIdleTimer({
    timeout: EXPIRES_IN,
    onIdle,
    debounce: 500,
  });

  return null;
};

export default IdlTimer;
