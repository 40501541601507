import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Typography } from '@material-ui/core';

import { RailzButton } from '@railzai/railz-uikit-react';

import { ArrowBack } from '@material-ui/icons';

import useLoginStyles from '../login/style';
import {
  doResendEmailApi,
  getErrorResponse,
  getMessage,
  getUserEmail,
  resetAuthError,
  resetAuthResponse,
} from '../../store/features/auth';
import storeService from '../../store';
import { ErrorResponse } from '../../types/interface/error.interface';

import useStyle from './style';

import { useEventContext } from 'providers/events-provider';
import { showSnackbar } from 'helpers/common.helper';
import LayoutImage from 'pages/layout-image/layout-image.page';

const VerifyEmailPage: React.FC = () => {
  const { setInitialized } = useEventContext();
  const classes = useStyle();
  const loginClasses = useLoginStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const store = storeService.getStore();
  const message = useSelector<ReturnType<typeof store.getState>, string>(getMessage);
  const email = useSelector<ReturnType<typeof store.getState>, string>(getUserEmail);
  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse | any>(
    getErrorResponse,
  );

  useEffect(() => {
    setInitialized(false);
    if (!email) {
      history.replace('/login');
    }
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    if (message) {
      dispatch(resetAuthResponse());
      showSnackbar({
        message: t('DASHBOARD_HOME_VERIFY_EMAIL_SUCCESS'),
        type: 'success',
      });
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (error) {
      dispatch(resetAuthError());
      const errorExists = error?.message?.length > 0 && i18n.exists(error.message[0]);
      showSnackbar({
        message: errorExists ? t(error.message[0]) : t('DASHBOARD_SEND_VERIFICATION_FAILED'),
        type: 'error',
      });
    }
    // eslint-disable-next-line
  }, [error]);

  const handleSigninNavigation = (): void => {
    history.push('/login');
  };

  const resendEmail = async (): Promise<void> => {
    dispatch(doResendEmailApi({ email }));
  };

  return (
    <LayoutImage>
      <Typography className={classes.header}>{t('DASHBOARD_EMAIL_VERIFY_HEADER')}</Typography>
      <Typography className={classes.description}>
        <Trans
          i18nKey="DASHBOARD_EMAIL_VERIFY_CTA"
          values={{ email }}
          components={{ italic: <i />, bold: <strong /> }}
        />
      </Typography>
      <Typography>
        <Trans i18nKey="DASHBOARD_EMAIL_VERIFY_RESEND_CTA" />
        <Link onClick={resendEmail} className={loginClasses.link}>
          {t('DASHBOARD_EMAIL_VERIFY_RESEND_CTA_LINK')}
        </Link>
      </Typography>
      <RailzButton
        className={classes.backButton}
        type="text primary"
        label={t('DASHBOARD_BACK_TO_LOGIN')}
        onButtonClick={handleSigninNavigation}
      >
        <span slot="prefix">
          <ArrowBack fontSize="small" />
        </span>
      </RailzButton>
    </LayoutImage>
  );
};

export default VerifyEmailPage;
