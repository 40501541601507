import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes appear': {
      from: {
        transform: 'scale(.8)',
        opacity: 0,
        filter: 'blur(5px)',
      },
    },

    invisible: {
      height: 0,
      opacity: 0,
      visibility: 'hidden',
    },

    visible: {
      opacity: 1,
      visibility: 'visible',
      animation: `$appear .2s ease-in-out both`,
    },

    seoPreview: {
      width: 220,
      height: 44,

      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',

      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightRegular as number,
      backgroundColor: '#eee',
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',

      '& .title': {
        position: 'relative',
        color: theme.palette.grey[800],
        marginRight: theme.spacing(1),
        overflow: 'hidden',
        lineHeight: '24px',
        width: 'calc(100% - 16px - 12px)', // 16px for favicon, 12px for close icon
        whiteSpace: 'nowrap',

        maskImage: 'linear-gradient(to right, #eee 132px, transparent 140px)',
        // 👆🏻 extra effect to fade the text when it's too long on the last pixels
      },

      '& .favicon, & .MuiSvgIcon-root.close': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 16,
        height: 16,
      },

      '& .MuiSvgIcon-root.close': {
        color: theme.palette.grey[800],
        marginLeft: 'auto',
        fontSize: '1rem',
        marginRight: theme.spacing(2),
      },

      '& .favicon': {
        minWidth: 16,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(2),
      },

      '& .favicon img': {
        maxWidth: 16,
        maxHeight: 16,
      },
    },
  }),
);
