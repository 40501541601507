import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    view: {
      height: '100%',
    },

    viewHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
      gap: theme.spacing(2),
    },

    primaryCta: {
      alignSelf: 'flex-end',
    },

    viewContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      minHeight: '0',
      padding: `0 ${theme.spacing(4)}px`,
      position: 'relative',

      '& table': {
        paddingBottom: `${theme.spacing(10)}px`,
      },
    },

    reasonsSection: {
      marginTop: theme.spacing(1),
    },
    reasonsLabel: {
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[800],
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    reasonsSelect: {
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    membersContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    createTeamContainer: {
      paddingBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    actionIcon: {
      '&:hover': {
        backgroundColor: '#E0E0E0',
      },
    },
    teamNameContainer: {
      display: 'flex',
      alignItems: 'baseline',
      gap: theme.spacing(2),
    },
    teamNameLinkTxt: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    defaultIconContainer: {
      alignSelf: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100,
    },

    currentTeamText: {
      color: theme.palette.primary.main,
      fontWeight: 800,
      fontSize: '0.625rem',
      letterSpacing: '.1em',
      textTransform: 'uppercase',
      flexShrink: 0,
    },

    totalMembers: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.grey[800],
      minWidth: theme.spacing(12),
    },

    avatarContainer: {
      display: 'flex',
      marginLeft: theme.spacing(4),
    },

    avatar: {
      marginLeft: '-10px',
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: '50%',
      border: '2px solid white',
      color: 'black',
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      align: 'center',
      backgroundColor: '#E0E0E0',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',

      '&:last-of-type:not(:nth-of-type(-n+5))': {
        backgroundColor: '#BDBDBD',
      },
    },

    initials: {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      verticalAlign: 'middle',
      textAlign: 'center',
      margin: '0 auto',
      fontWeight: 500,
    },

    profileIcon: {
      paddingTop: '4px',
      margin: '0 auto',
    },

    link: {
      fontWeight: Number(theme.typography.fontWeightBold),
      color: ACTIVE_TEXT_COLOR,
      textDecoration: 'underline',
    },

    textPlanUpdate: {
      color: ACTIVE_TEXT_COLOR,
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.125rem',
      textAlign: 'end',
      paddingBottom: theme.spacing(4),
    },

    iconPlanUpdate: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },

    linkPlanUpdate: {
      textDecoration: 'none',
      marginRight: '0.25rem',
    },

    pagination: {
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      backgroundColor: 'white',
      zIndex: 1,
      boxShadow: '0 0 32px -8px rgba(0, 0, 0, 0.2)',
      paddingBottom: theme.spacing(5),

      '& .Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);
