import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import useStyle from './style';

import ResetPasswordForm from './reset-password-form';

import SetupAccountForm from './setup-account-form';

import { View } from 'components';
import { AuthService, CustomerService } from 'services';

import { showSnackbar } from 'helpers/common.helper';

import useQuerySearch from 'hooks/use-query-search';

import LayoutImage from 'pages/layout-image/layout-image.page';

const getKeyMessage = (message): string => {
  switch (message) {
    case 'TOKEN NO LONGER VALID':
      return 'DASHBOARD_RESET_PASSWORD_INVALID_LINK';
    case 'Your new password must be different than your current password.':
      return 'PASSWORD_MATCHES_HISTORY';
    default:
      return 'DASHBOARD_ENUM_ERROR_MESSAGE_UNKNOWN';
  }
};

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [token, setToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const isVerifyTeam = useRouteMatch('/verifyUser');

  const query = useQuerySearch();
  const history = useHistory();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (isVerifyTeam) {
          // Check if a token is already in the local storage
          const storedToken = window.localStorage.getItem('verification-token');
          if (storedToken) {
            setToken(storedToken);
          } else {
            // initial token is to verify the e-mail, the response returns the password token
            const response = await CustomerService.verifyUser(query.get('token'));
            if (response.isSSOEnabled) {
              showSnackbar({
                message: t('DASHBOARD_ACCOUNT_SSO_VERIFIED'),
                type: 'success',
              });
              history.push('/login');
            }
            setToken(response['token']);
            window.localStorage.setItem('verification-token', response['token']);
          }
        }
      } catch (error) {
        // If there is an error verifying the user, send them to the verifyEmail page
        // this could be refactored with BE changes for handling the invite URLS, and creating
        // a generic 'verifyError' component - ZF
        history.replace('/verifyEmail');
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values): Promise<void> => {
    try {
      window.localStorage.removeItem('verification-token');
      const { firstName, lastName, newPassword } = values;
      if (firstName && lastName) {
        await AuthService.setupAccount(
          { firstName, lastName, password: newPassword },
          token ? token : query.get('token'),
        );
      } else {
        await AuthService.resetPassword(
          { password: newPassword },
          token ? token : query.get('token'),
        );
      }
      showSnackbar({ message: t('DASHBOARD_RESET_PASSWORD_SUCCESS'), type: 'success' });
      history.replace('/login');
    } catch (error) {
      showSnackbar({ message: t(getKeyMessage(error?.message?.[0])), type: 'error' });
    }
  };

  return (
    <LayoutImage>
      <View role="main">
        {!isLoading && (
          <>
            {isVerifyTeam ? (
              <>
                <Typography className={classes.header}>
                  {t('DASHBOARD_ACCOUNT_SETUP_TITLE')}
                </Typography>
                <Typography className={classes.description}>
                  {t('DASHBOARD_ACCOUNT_SETUP_BODY')}
                </Typography>
                <SetupAccountForm onSubmit={onSubmit}></SetupAccountForm>
              </>
            ) : (
              <>
                <Typography className={classes.header}>
                  {t('DASHBOARD_RESET_PASSWORD_VERIFY_HEADER')}
                </Typography>
                <ResetPasswordForm onSubmit={onSubmit} />
              </>
            )}
          </>
        )}
      </View>
    </LayoutImage>
  );
};

export default ResetPassword;
