import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      paddingBottom: theme.spacing(3),
    },
    icon: {
      color: '#F2A64A',
      paddingRight: theme.spacing(2),
    },
  }),
);
