import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    progressBarContainer: {
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      margin: '0 auto 1.5em',
      transform: 'scale(1.2)',
      pointerEvents: 'none',
      minWidth: '100%',
    },
  }),
);
