import React from 'react';

import { HttpStatusCode } from 'axios';

import ErrorComponent from '../error-component';

import { HttpStatus, HttpStatusError } from 'types';

interface Props {
  statusCode: number;
  testId?: string;
}

export default function ErrorChartComponent({ statusCode, testId }: Props): JSX.Element {
  if (!statusCode || statusCode === HttpStatus.OK) {
    return null;
  } else if (statusCode === HttpStatus.PENDING) {
    const argument = {
      statusCode: 202,
      showTitle: false,
      subTitle: 'DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_202',
      imageStyle: {
        height: 56,
      },
      testId: testId,
    };
    return <ErrorComponent {...argument} />;
  } else if (statusCode === HttpStatus.NO_DATA) {
    const argument = {
      statusCode: 204,
      showTitle: false,
      subTitle: 'DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_204',
      imageStyle: {
        height: 56,
      },
      testId: testId,
    };
    return <ErrorComponent {...argument} />;
  } else if (statusCode === HttpStatusError.NOT_FOUND) {
    const argument = {
      statusCode: 404,
      showTitle: false,
      subTitle: 'DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_404',
      imageStyle: {
        height: 56,
      },
      testId: testId,
    };
    return <ErrorComponent {...argument} />;
  } else if (statusCode === HttpStatusCode.UnprocessableEntity) {
    const argument = {
      statusCode: 204,
      showTitle: false,
      subTitle: 'DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_422',
      imageStyle: {
        height: 56,
      },
      testId: testId,
    };
    return <ErrorComponent {...argument} />;
  } else {
    const argument = {
      statusCode: 500,
      showTitle: false,
      subTitle: 'DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_500',
      imageStyle: {
        height: 56,
      },
      testId: testId,
    };
    return <ErrorComponent {...argument} />;
  }
}
