import React, { useEffect, useRef, useState } from 'react';

import { Avatar, Grid } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../../store';
import { getErrorMessage, showSnackbar, formChange } from '../../../helpers/common.helper';
import { ProfileState } from '../../../store/features/account/profile/profile.state';
import {
  getProfileErrorMessage,
  getProfileState,
  isProfileApiSuccess,
  isProfileApiUpdateSuccess,
} from '../../../store/features/account/profile/profile.selector';
import {
  resetProfileError,
  resetProfileResponse,
  updateProfilePicApi,
} from '../../../store/features/account/profile/profile.action';

import useStyle from './style';

import { EMAIL_INVALID_DOMAINS_REGEX } from 'helpers/regex.helper';
import { FormInput } from 'components/form';
import { View } from 'components';

interface FormValue {
  email: string;
  firstName: string;
  lastName: string;
}

const validationSchema: SchemaOf<FormValue> = yup.object().shape({
  firstName: yup
    .string()
    .required('DASHBOARD_PROFILE_FIRST_NAME_REQUIRED')
    .min(2, 'DASHBOARD_PROFILE_FIRST_NAME_CRUD_MIN')
    .max(40, 'DASHBOARD_PROFILE_FIRST_NAME_CRUD_MAX')
    .nullable(),
  lastName: yup
    .string()
    .required('DASHBOARD_PROFILE_LAST_NAME_REQUIRED')
    .min(2, 'DASHBOARD_PROFILE_LAST_NAME_CRUD_MIN')
    .max(40, 'DASHBOARD_PROFILE_LAST_NAME_CRUD_MAX')
    .nullable(),
  email: yup
    .string()
    .email('DASHBOARD_PROFILE_EMAIL_REQUIRED')
    .required('DASHBOARD_PROFILE_EMAIL_REQUIRED')
    .matches(EMAIL_INVALID_DOMAINS_REGEX, 'DASHBOARD_EMAIL_NOT_WORK_EMAIL')
    .nullable(),
});

interface Props {
  submitAction: any;
}

export default function ProfileForm({ submitAction }: Props): JSX.Element {
  const store = storeService.getStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyle();
  const avatarRef = useRef(null);
  const fileInputRef = useRef(null);
  const profile = useSelector<ReturnType<typeof store.getState>, ProfileState>(getProfileState);
  const isSuccess = useSelector<ReturnType<typeof store.getState>, boolean>(isProfileApiSuccess);

  const isUpdateSuccess = useSelector<ReturnType<typeof store.getState>, boolean>(
    isProfileApiUpdateSuccess,
  );
  const errorMessage = useSelector<ReturnType<typeof store.getState>, string[]>(
    getProfileErrorMessage,
  );
  const [profilePicture, setProfilePicture] = useState(profile?.profilePic);
  const [isUpdateToastGeneric, setIsUpdateToastGeneric] = useState(true);

  const { handleSubmit, errors, control, formState, reset, setError, clearErrors } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      email: profile?.email || '',
      profilePic: profile?.profilePic,
    },
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;
  useEffect(() => {
    (async (): Promise<void> => {
      if (!isSubmitting) {
        reset({
          firstName: profile.firstName || '',
          lastName: profile.lastName || '',
          email: profile.email || '',
        });
      }
    })();
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    setProfilePicture(profile.profilePic);
  }, [profile?.profilePic]);

  useEffect(() => {
    if (isSuccess && profile?.url) {
      setProfilePicture(profile?.url);
      dispatch(resetProfileResponse());
    }
    // eslint-disable-next-line
  }, [isSuccess, profile?.url]);

  useEffect(() => {
    if (isUpdateSuccess) {
      showSnackbar({
        message: isUpdateToastGeneric
          ? t('DASHBOARD_PROFILE_UPDATE_SUCCESS')
          : t('DASHBOARD_PROFILE_EMAIL_UPDATE_SUCCESS', {
              email: profile?.changeEmail || profile.email,
            }),
        type: 'success',
      });
      dispatch(resetProfileResponse());
    }
    // eslint-disable-next-line
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      if (errorMessage.includes('File too large')) {
        setError('profilePic', { message: 'DASHBOARD_PROFILE_PICTURE_TOO_BIG' });
        return;
      } else if (errorMessage.includes('Invalid file content type')) {
        setError('profilePic', { message: 'DASHBOARD_PROFILE_PICTURE_WRONG_FORMAT' });
        return;
      }
      const message = getErrorMessage(errorMessage[0]);
      if (
        [
          'DASHBOARD_PROFILE_EMAIL_DUPLICATE',
          'DASHBOARD_SIGNUP_EMAIL_NOT_VALID',
          'DASHBOARD_SIGNUP_EMAIL_DISPOSABLE_EMAIL',
        ].includes(message)
      ) {
        setError('email', { message });
        return;
      }
      showSnackbar({
        type: 'error',
        message: t(message),
      });
      dispatch(resetProfileError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const triggerFileUpload = (): void => {
    fileInputRef.current.click();
  };

  const handleFileChange = async ({ target: { files } }): Promise<void> => {
    if (!files?.length) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    const maxBlockSize = 5 * 1024 * 1024; //5MB
    if (maxBlockSize < files[0].size) {
      setError('profilePic', { message: 'DASHBOARD_PROFILE_PICTURE_TOO_BIG' });
      return;
    }
    reader.onloadend = async (): Promise<void> => {
      setProfilePicture(reader.result as any);
    };
    const data = new FormData();
    data.append('file', files[0]);
    clearErrors('profilePic');
    dispatch(updateProfilePicApi(data));
  };
  const onSubmit = async (values): Promise<void> => {
    if (profilePicture) {
      values.profilePic = profilePicture;
    }
    if (submitAction && formChange(values, profile)) {
      if (!isUpdateToastGeneric && values.email === profile.email) {
        setIsUpdateToastGeneric(true);
      } else if (values.email !== profile.email) {
        setIsUpdateToastGeneric(false);
      }
      await submitAction(values);
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }
  };

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <View alignItems="center" justifyContent="center">
              <Avatar
                src={profilePicture}
                className={classes.avatarProfilePicture}
                ref={avatarRef}
              />
              {errors['profilePic'] && (
                <FormControl variant="outlined" error={errors['profilePic'] ? true : false}>
                  <FormHelperText>{t(errors['profilePic']?.message)}</FormHelperText>
                </FormControl>
              )}
              <View className={classes.uploadView}>
                <input
                  type="file"
                  accept="image/*"
                  className={classes.fileUploadInput}
                  ref={fileInputRef}
                  multiple={false}
                  onChange={handleFileChange}
                  data-testid="photo-upload"
                />

                <RailzButton
                  type="outlined"
                  buttonType="button"
                  data-testid="upload-button"
                  onButtonClick={triggerFileUpload}
                  label={t('DASHBOARD_PROFILE_CTA_CHANGE_PICTURE')}
                />
              </View>
            </View>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              className={classes.formInput}
              placeholder="DASHBOARD_PROFILE_FIRST_NAME_PLACEHOLDER"
              label="DASHBOARD_PROFILE_FIRST_NAME_LABEL"
              fullWidth
              margin="dense"
              name="firstName"
              errorobj={errors}
              control={control}
              testid="first-name-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="DASHBOARD_PROFILE_LAST_NAME_PLACEHOLDER"
              className={classes.formInput}
              label="DASHBOARD_PROFILE_LAST_NAME_LABEL"
              variant="outlined"
              fullWidth
              margin="dense"
              name="lastName"
              errorobj={errors}
              control={control}
              testid="last-name-input"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              placeholder="DASHBOARD_PROFILE_EMAIL_PLACEHOLDER"
              className={classes.formInput}
              label="DASHBOARD_PROFILE_EMAIL_LABEL"
              type="email"
              fullWidth
              margin="dense"
              name="email"
              errorobj={errors}
              control={control}
              testid="email-input"
            />
          </Grid>
        </Grid>

        <RailzButton
          size="large"
          onButtonClick={(): any => handleSubmit(onSubmit)}
          loading={isSubmitting || profile.loading}
          className={classes.button}
          data-testid="submit"
          label={t('DASHBOARD_PROFILE_CTA')}
        />
      </form>
    </>
  );
}
