import React from 'react';
import { IconButton, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import useStyle from './style';

export interface SearchProps {
  handleSearch?: (e) => void;
  inputProps?: any;
  placeholder?: string;
  style?: any;
  type?: 'button' | 'reset' | 'submit';
  className?: any;
  value?: string;
}

const Search: React.FC<SearchProps> = React.forwardRef(
  (
    {
      handleSearch,
      inputProps,
      placeholder = '',
      style,
      type = 'submit',
      className = null,
      value = '',
    },
    ref: React.RefObject<HTMLElement>,
  ) => {
    const classes = useStyle();
    return (
      <Paper component="form" className={`${classes.searchForm} ${className}`} style={style}>
        <IconButton className={classes.iconButton} aria-label="search" type={type}>
          <SearchIcon style={{ color: '#424242' }} />
        </IconButton>
        <InputBase
          value={value}
          className={classes.input}
          placeholder={placeholder}
          inputProps={{ ...inputProps, 'data-testid': 'search-input', style: { color: '#424242' } }}
          ref={ref}
          onKeyPress={(event): void => {
            if (event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
              handleSearch(event);
            }
          }}
          onChange={(event): void => handleSearch(event)}
        />
      </Paper>
    );
  },
);

export default Search;
