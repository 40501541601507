import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

import style from './style';

interface Props {
  sandbox: boolean;
}

export default function AlertIntegrationKeys({ sandbox }: Props): JSX.Element {
  const classes = style();
  const { t } = useTranslation();
  return (
    <Alert severity="info" icon={<InfoIcon />} classes={{ root: classes.alert }}>
      <AlertTitle>
        <Typography variant="h2" className={classes.heading}>
          {sandbox
            ? t('DASHBOARD_BANKING_INTEGRATION_KEYS_HEADER')
            : t('DASHBOARD_INTEGRATION_KEYS_CONTENT_HEADER')}
        </Typography>
      </AlertTitle>
      <Typography variant="body2">
        {sandbox
          ? t('DASHBOARD_HOME_CONFIGURE_KEYS_CONTENT_SANDBOX')
          : t('DASHBOARD_HOME_CONFIGURE_KEYS_CONTENT')}
      </Typography>
    </Alert>
  );
}
