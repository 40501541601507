import { createSelector } from 'reselect';

import { ACCOUNT_STORE_NAME } from '../account.state';
import { AllState } from '../../../state';

import { ProfileState } from './profile.state';

import { Plan, Role } from 'types';

/** Select Profile State */
export const getProfileState = (state: AllState): ProfileState => state[ACCOUNT_STORE_NAME].profile;

export const getUserRole = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.role,
);

export const getUserEmail = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.email,
);

export const getUserTeam = createSelector(getProfileState, (profile: ProfileState) => {
  if (!profile?.teamUuid || !Array.isArray(profile?.teams)) return undefined;
  return profile.teams.find(({ uuid }) => uuid === profile.teamUuid);
});

export const getUserTeams = createSelector(getProfileState, (profile: ProfileState) => {
  if (!profile?.teamUuid || !Array.isArray(profile?.teams)) return [];
  return profile.teams.map((team) => ({ ...team, current: profile.teamUuid === team.uuid }));
});

export const getRecoveryCode = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.response && profile.response.recoveryCode,
);

export const getGeneratedAuth = createSelector(
  getProfileState,
  (profile: ProfileState) =>
    profile && profile.response && profile.response.otpAuth && profile.response,
);

export const isProfileMfaEnabled = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.mfaEnabled,
);

export const isProfileLoading = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.loading,
);

export const isProfileBaseLoading = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.baseLoading,
);

export const getProfileError = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.error,
);

export const getProfileErrorMessage = createSelector(
  getProfileState,
  (profile: ProfileState) =>
    profile && profile.error && profile.error.message?.length > 0 && profile.error.message,
);

export const isProfileApiSuccess = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.response && profile.response.success,
);

export const getProfilePlan = createSelector(
  getProfileState,
  (profile: ProfileState) => profile?.billingPlan as Plan,
);

export const isProfileOnFreePlan = createSelector(
  getProfileState,
  (profile: ProfileState) => profile?.billingPlan === Plan.FREE,
);

export const isProfileOnPayAsYouGo = createSelector(
  getProfileState,
  (profile: ProfileState) => profile?.billingPlan === Plan.PAY_AS_YOU_GO,
);

export const isProfileOnEnterprise = createSelector(
  getProfileState,
  (profile: ProfileState) => profile?.billingPlan === Plan.CUSTOM,
);

export const getRoleOnTeam = createSelector(
  [getProfileState, (_state, teamUuid: string): string => teamUuid],
  (profile: ProfileState, teamUuid: string): Role => {
    const teams = profile.teams || [];
    const team = teams.find(({ uuid }) => uuid === teamUuid);
    if (!team) return undefined;
    return team.role;
  },
);

export const isProfileApiUpdateSuccess = createSelector(
  getProfileState,
  (profile: ProfileState) =>
    profile &&
    profile.response &&
    profile.response.success &&
    profile.response.message === 'updateProfile',
);

export const getProfileApiResponseMsg = createSelector(
  getProfileState,
  (profile: ProfileState) => profile && profile.response && profile.response.message,
);
