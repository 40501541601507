import { WebhookConfig } from '../../../../types';

import { WebhookConfigActionCreator } from './webhook-config.state';

import { ErrorResponse } from 'types/interface/error.interface';

/** StateWebhookCONFIGActions */
export const FETCH_WEBHOOK_CONFIG_SUCCESS_API = 'FETCH_WEBHOOK_CONFIG_SUCCESS_API';
export const FETCH_WEBHOOK_CONFIG_FAILURE_API = 'FETCH_WEBHOOK_CONFIG_FAILURE_API';

export const UPDATE_WEBHOOK_CONFIG_API = 'UPDATE_WEBHOOK_CONFIG_API';
export const UPDATE_WEBHOOK_CONFIG_SUCCESS_API = 'UPDATE_WEBHOOK_CONFIG_SUCCESS_API';
export const UPDATE_WEBHOOK_CONFIG_FAILURE_API = 'UPDATE_WEBHOOK_CONFIG_FAILURE_API';

export const DELETE_WEBHOOK_CONFIG_API = 'DELETE_WEBHOOK_CONFIG_API';
export const DELETE_WEBHOOK_CONFIG_SUCCESS_API = 'DELETE_WEBHOOK_CONFIG_SUCCESS_API';
export const DELETE_WEBHOOK_CONFIG_FAILURE_API = 'DELETE_WEBHOOK_CONFIG_FAILURE_API';
/** WebhookCONFIGAction Creators */
export const RESET_STORAGE_WEBHOOK_CONFIG = 'RESET_STORAGE_WEBHOOK_CONFIG';
export const RESET_WEBHOOK_CONFIG_ERROR = 'RESET_WEBHOOK_CONFIG_ERROR';
export const RESET_WEBHOOK_CONFIG_RESPONSE = 'RESET_WEBHOOK_CONFIG_RESPONSE';

export const resetWebhookConfigStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_WEBHOOK_CONFIG,
  };
};

export const resetWebhookConfigError = (): { type: string } => {
  return {
    type: RESET_WEBHOOK_CONFIG_ERROR,
  };
};
export const resetWebhookConfigResponse = (): { type: string } => {
  return {
    type: RESET_WEBHOOK_CONFIG_RESPONSE,
  };
};

// FETCH
export const fetchWebhookConfigSuccessApi: WebhookConfigActionCreator<WebhookConfig> = (
  payload,
) => ({
  type: FETCH_WEBHOOK_CONFIG_SUCCESS_API,
  payload,
});

export const fetchWebhookConfigFailureApi: WebhookConfigActionCreator<ErrorResponse> = (
  payload,
) => ({
  type: FETCH_WEBHOOK_CONFIG_FAILURE_API,
  payload,
});

// UPDATE
export const updateWebhookConfigApi: WebhookConfigActionCreator<Partial<WebhookConfig>> = (
  payload,
) => ({
  type: UPDATE_WEBHOOK_CONFIG_API,
  payload,
});

export const updateWebhookConfigSuccessApi: WebhookConfigActionCreator<WebhookConfig> = (
  payload,
) => ({
  type: UPDATE_WEBHOOK_CONFIG_SUCCESS_API,
  payload,
});

export const updateWebhookConfigFailureApi: WebhookConfigActionCreator<ErrorResponse> = (
  payload,
) => ({
  type: UPDATE_WEBHOOK_CONFIG_FAILURE_API,
  payload,
});

// DELETE
export const deleteWebhookConfigApi: WebhookConfigActionCreator = () => ({
  type: DELETE_WEBHOOK_CONFIG_API,
});

export const deleteWebhookConfigSuccessApi: WebhookConfigActionCreator<WebhookConfig> = () => ({
  type: DELETE_WEBHOOK_CONFIG_SUCCESS_API,
});

export const deleteWebhookConfigFailureApi: WebhookConfigActionCreator<ErrorResponse> = (
  payload,
) => ({
  type: DELETE_WEBHOOK_CONFIG_FAILURE_API,
  payload,
});
