import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './styles.css';
import { Business, Connection } from 'pages/business/types/interfaces';
import { getNameBankConnection } from 'helpers/report-helpers';

interface Props {
  selectedBusiness: Business;
  handleSelected: (string) => void;
  selectedConnection: Connection;
}

export default function ServiceName({
  selectedBusiness,
  handleSelected,
  selectedConnection: parentSelectedConnection,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [serviceNameOptions, setServiceNameOptions] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState(null);

  useEffect(() => {
    if (selectedBusiness && selectedBusiness?.connections?.length) {
      const bankMenuOptions = getNameBankConnection(selectedBusiness?.connections);
      setServiceNameOptions(bankMenuOptions);
    }
  }, [selectedBusiness]);

  useEffect(() => {
    if (serviceNameOptions?.length) {
      setSelectedServiceName(serviceNameOptions[0]);
    }
  }, [serviceNameOptions]);

  useEffect(() => {
    setSelectedServiceName(parentSelectedConnection?.connectionId);
  }, [parentSelectedConnection]);

  const onChange = (event): void => {
    setSelectedServiceName(event.target.value);

    const selectedConnection = serviceNameOptions.filter(
      (service) => service.connectionId === event.target.value,
    );

    handleSelected && handleSelected(selectedConnection?.length ? selectedConnection[0] : {});
  };

  return (
    <TextField
      fullWidth
      label={t('DASHBOARD_REPORT_SERVICE_LABEL')}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      select
      value={selectedServiceName || ''}
      onChange={onChange}
      data-testid="status-input"
    >
      {serviceNameOptions?.length &&
        serviceNameOptions.map((service, index) => (
          <MenuItem key={index} value={service.connectionId}>
            {service.label}
          </MenuItem>
        ))}
    </TextField>
  );
}
