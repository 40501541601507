import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      paddingBottom: '1.5rem',
      wordBreak: 'break-word',
    },
    icon: {
      color: theme.palette.primary.main,
      paddingRight: '1rem',
    },
    copiedText: {
      fontSize: '0.75rem',
    },
  });
});
