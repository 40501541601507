import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import * as yup from 'yup';

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import {
  doChangePasswordApi,
  getErrorMessageResponse,
  isPasswordChangeSuccess,
  resetAuthError,
  resetAuthResponse,
} from '../../../store/features/auth';

import useStyle from './style';

import { FormInput } from 'components/form';
import { handleError, showSnackbar } from 'helpers/common.helper';
import { PASSWORD_REGEX } from 'helpers/regex.helper';

import AlertDialog from 'components/dialog';

interface IFormValue {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('DASHBOARD_PROFILE_PASSWORD_REQUIRED').nullable(),
  newPassword: yup
    .string()
    .required('DASHBOARD_PROFILE_PASSWORD_REQUIRED')
    .matches(PASSWORD_REGEX, 'DASHBOARD_PROFILE_NEW_PASSWORD_INVALID')
    .notOneOf([yup.ref('currentPassword'), null], 'PASSWORD_MATCHES_HISTORY')
    .nullable(),
  confirmPassword: yup
    .string()
    .required('DASHBOARD_PROFILE_PASSWORD_NOT_MATCHING')
    .oneOf([yup.ref('newPassword'), null], 'DASHBOARD_PROFILE_PASSWORD_NOT_MATCHING')
    .notOneOf([yup.ref('currentPassword'), null], 'PASSWORD_MATCHES_HISTORY')
    .nullable(),
});

const initialValues: IFormValue = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const PasswordForm: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [loading, setIsLoading] = useState(false);
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { handleSubmit, errors, control, formState, setError } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;

  const isApiSuccess = useSelector(isPasswordChangeSuccess);
  useEffect(() => {
    if (isApiSuccess) {
      onClose();
      dispatch(resetAuthResponse());
      showSnackbar({ message: t('DASHBOARD_RESET_PASSWORD_SUCCESS'), type: 'success' });
    }
    //eslint-disable-next-line
  }, [isApiSuccess]);

  const errorMessage = useSelector(getErrorMessageResponse);

  useEffect(() => {
    if (errorMessage && errorMessage.length) {
      setIsLoading(false);
      dispatch(resetAuthError());
      setError('currentPassword', { message: errorMessage[0] });
      handleError(t('DASHBOARD_RESET_PASSWORD_UNSUCCESSFUL'));
    }
    //eslint-disable-next-line
  }, [errorMessage]);

  const onSubmit = async (values): Promise<void> => {
    setIsLoading(true);
    const { currentPassword, newPassword } = values;
    dispatch(doChangePasswordApi({ currentPassword, newPassword }));
  };

  return (
    <AlertDialog
      isOpen={true}
      title={t('DASHBOARD_SECURITY_PASSWORD_CTA')}
      showCloseButton
      onClose={onClose}
      headerStyle={{ marginBottom: 0 }}
      cancel={{
        label: t('DASHBOARD_CANCEL_CTA'),
        disabled: isSubmitting || loading,
        onClick: onClose,
        'data-testid': 'close-button',
        type: 'gray',
      }}
      confirm={{
        onClick: handleSubmit(onSubmit),
        disabled: isSubmitting || loading,
        label: t('DASHBOARD_BUSINESS_RENAME_SAVE'),
      }}
      isLoading={isSubmitting || loading}
    >
      <Box>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
        >
          <Grid item xs={12}>
            <FormInput
              label="DASHBOARD_SECURITY_CURRENT_PASSWORD_LABEL"
              placeholder="DASHBOARD_SECURITY_CURRENT_PASSWORD_PLACEHOLDER"
              type="password"
              testid="current-password"
              fullWidth
              margin="dense"
              name="currentPassword"
              className={classes.input}
              errorobj={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label="DASHBOARD_RESET_PASSWORD_VERIFY_NEW_PASSWORD_LABEL"
              placeholder="DASHBOARD_SECURITY_NEW_PASSWORD_PLACEHOLDER"
              type="password"
              testid="current-password-new"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.input}
              name="newPassword"
              errorobj={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              testid="current-password-new-verify"
              label="DASHBOARD_SECURITY_CONFIRM_PASSWORD_LABEL"
              placeholder="DASHBOARD_RESET_PASSWORD_VERIFY_CONFIRM_PASSWORD_PLACEHOLDER"
              type="password"
              margin="dense"
              variant="outlined"
              className={classes.input}
              fullWidth
              name="confirmPassword"
              errorobj={errors}
              control={control}
            />
          </Grid>
        </form>
      </Box>
    </AlertDialog>
  );
};
export default PasswordForm;
