import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Link, Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { RailzButton } from '@railzai/railz-uikit-react';

import Tabs from '../../components/tab';

import { AllDataTypesByCategory, ItemDataType } from './types/data-sync';

import DataSyncTable from './table';

import style from './style';

import PageDescription from 'components/page-description/page-description';
import useDefaultStyles from 'assets/styles/style';

import { ServiceType } from 'types';
import useQuerySearchUpdate from 'hooks/use-query-search-update';

import { updateSyncConfigsApi } from 'store/features/integration/integration.action';
import { formChange, showSnackbar } from 'helpers/common.helper';
import AlertDialog from 'components/dialog';
import { getSyncConfigs } from 'store/features/integration/integration.selector';
import { getExpandedDataTypes } from 'store/features/report/report.selector';

import { getDataTypesServiceTypeGrouper } from 'helpers/data-sync/utils';
import { openNewTab } from 'helpers/open-new-tab';
import {
  ItemDataTypeConfigStatus,
  PulledDataTypes,
} from 'store/features/integration/integration.state';

/** Formats the values stored in dataTypesByCategory to be sent to the backend*/
const formatAllDataToSave = (dataTypesByCategory: AllDataTypesByCategory): PulledDataTypes => {
  return Object.values(dataTypesByCategory).reduce(
    (acum, dataTypes) => ({
      ...dataTypes.reduce((acc: PulledDataTypes, dataType: ItemDataType) => {
        acc[dataType.key] = dataType.configStatus;
        return acc;
      }, acum),
    }),
    {},
  );
};

/** Updates the local state with the form values */
const updateLocalStateWithFormValues = (
  formValues: Record<string, { configStatus: ItemDataTypeConfigStatus }>,
  localState: AllDataTypesByCategory,
  tabValue: ServiceType,
): AllDataTypesByCategory => {
  localState[tabValue].forEach((item) => {
    item.configStatus = formValues[item.key].configStatus;
  });
  return localState;
};

const DATA_SYNC_DOC_LINK =
  'https://docs.railz.ai/docs/dashboard-data-sync-settings#selecting-data-types';

export default function InitialSync(): JSX.Element {
  const classes = style();
  const { pulledDataTypes: syncConfigs } = useSelector(getSyncConfigs);
  const dataTypes = useSelector(getExpandedDataTypes);
  const dispatch = useDispatch();
  const [dataTypesByCategory, setDataTypesByCategory] = useState<AllDataTypesByCategory>();
  const [showNotShowAgain, setShowNotShowAgain] = useState(false);
  const [notShowAgain, setNotShowAgain] = useState(false);

  const [querySearch, setUserSearch] = useQuerySearchUpdate();
  const [tabValue, setTabValue] = useState<ServiceType>(
    (querySearch?.get('tab') as ServiceType) || ServiceType.ACCOUNTING,
  );

  const { t } = useTranslation();

  const defaultClasses = useDefaultStyles();

  const { control, setValue, getValues, trigger } = useForm({ mode: 'onChange' });

  const tabValues = [
    { label: t('DASHBOARD_NAV_INTEGRATIONS_ACCOUNTING'), value: ServiceType.ACCOUNTING },
    { label: t('DASHBOARD_NAV_INTEGRATIONS_BANKING'), value: ServiceType.BANKING },
    { label: t('DASHBOARD_NAV_INTEGRATIONS_COMMERCE'), value: ServiceType.COMMERCE },
    { label: t('DASHBOARD_NAV_INTEGRATIONS_ANALYTICS'), value: ServiceType.ANALYTICS },
  ];

  const setDataTypesLocalState = (): AllDataTypesByCategory => {
    if (!dataTypesByCategory) return;
    const updatedData = updateLocalStateWithFormValues(getValues(), dataTypesByCategory, tabValue);
    setDataTypesByCategory(updatedData);
    return updatedData;
  };

  const handleTabChanged = (tab: ServiceType): void => {
    if (tab === tabValue) return;
    setDataTypesLocalState();
    setTabValue(tab);
    setUserSearch({ tab });
  };

  const TabComponent = useCallback(
    ({ value, changeOnDefault }): JSX.Element => {
      if (!dataTypesByCategory || !tabValue) return null;
      let rows: ItemDataType[] = dataTypesByCategory[value];

      if (!dataTypesByCategory[value]) {
        changeOnDefault(ServiceType.ACCOUNTING);
        rows = dataTypesByCategory[ServiceType.ACCOUNTING];
      }

      const props = { control, getValues, setValue, trigger, rows };
      return <DataSyncTable {...props} />;
    },
    [dataTypesByCategory, tabValue],
  );

  const saveChanges = (pulledDataTypes = null): void => {
    dispatch(updateSyncConfigsApi({ pulledDataTypes }));
  };

  const formatDataAndValidate = (): { data: PulledDataTypes; formChanged: boolean } => {
    const updatedValues = setDataTypesLocalState();
    const data = formatAllDataToSave(updatedValues);
    const formChanged = formChange(data, syncConfigs);
    if (!formChanged) {
      showSnackbar({
        message: t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_TOAST_NONE'),
        type: 'success',
      });
    }
    return { data, formChanged };
  };

  const onSubmit = (): void => {
    const showDialog = localStorage.getItem('data-sync-not-show-again') !== 'true';
    const { data, formChanged } = formatDataAndValidate();
    if (showDialog && formChanged) {
      setShowNotShowAgain(true);
    } else if (formChanged) {
      saveChanges(data);
    }
  };

  const handleClose = (): void => setShowNotShowAgain(false);

  const handleConfirmation = (): void => {
    if (notShowAgain) {
      localStorage.setItem('data-sync-not-show-again', 'true');
    }
    const { data, formChanged } = formatDataAndValidate();
    if (formChanged) {
      saveChanges(data);
    }
    handleClose();
  };

  useEffect(() => {
    if (!dataTypes || !Object.keys(syncConfigs).length) return;
    const data = getDataTypesServiceTypeGrouper(dataTypes, syncConfigs);
    setDataTypesByCategory(data);
  }, [dataTypes, syncConfigs]);

  return (
    <>
      <Typography className={classes.initialSyncTitle}>
        {t('DASHBOARD_DATA_SYNC_INITIAL_SYNC_TITLE')}
      </Typography>
      <PageDescription
        subtitle="DASHBOARD_DATA_SYNC_INITIAL_SYNC_DESCRIPTION"
        className={classes.description}
        subtitleComponents={{
          a: (
            <Link
              href={DATA_SYNC_DOC_LINK}
              onClick={(e): void => openNewTab(e, DATA_SYNC_DOC_LINK)}
              className={defaultClasses.link}
              rel="noopener noreferrer"
            >
              {t('DASHBOARD_LEARN_MORE_CTA')}
            </Link>
          ),
        }}
      />
      <Tabs value={tabValue} handleChange={handleTabChanged} tabs={tabValues} />
      <form noValidate autoComplete="off">
        <TabComponent value={tabValue} changeOnDefault={handleTabChanged} />
      </form>
      <div className={classes.ctaSaveContainer}>
        <RailzButton
          onClick={onSubmit}
          type="primary"
          data-testid={'save-button-initial-sync'}
          label={t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_MODAL')}
        />
      </div>

      <AlertDialog
        isOpen={showNotShowAgain}
        title={t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_MODAL')}
        onClose={(): void => handleClose()}
        showCloseButton
        cancel={{
          label: t('DASHBOARD_CANCEL_CTA'),
          onClick: () => handleClose(),
          'data-testid': 'alert-cancel-button',
          type: 'gray',
        }}
        confirm={{
          label: t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_MODAL'),
          'data-testid': 'data-sync-confirm-button',
          onClick: (): void => {
            handleConfirmation();
          },
        }}
      >
        <div>
          <div>{t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_MODAL_DESCRIPTION')}</div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              color="primary"
              onClick={({ target }): void => setNotShowAgain(target['checked'])}
              data-testid="check-not-show"
              className={classes.checkbox}
            ></Checkbox>
            <Typography className={classes.labelNotShowAgain}>
              {t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_MODAL_DESCRIPTION_OPT_OUT')}
            </Typography>
          </div>
        </div>
      </AlertDialog>
    </>
  );
}
