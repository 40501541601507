import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.25),
      },
    },
    commaContainer: {
      marginLeft: theme.spacing(1.5),
    },
    imgContainer: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& railz-logo': {
        width: '100%',
        flexShrink: 0,
      },

      '& img': {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
    aspName: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: '1.375rem',
      color: theme.palette.grey[800],
      marginLeft: theme.spacing(1.5),
    },
    arrowIconContainer: {
      marginLeft: theme.spacing(1.9),
      marginRight: theme.spacing(1.9),
      display: 'flex',
      alignItems: 'center',
    },
    verticalLineContainer: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    reconcileTitle: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '1.059rem',
      color: '#424242',
    },
    reconcileDate: {
      fontWeight: theme.typography.fontWeightRegular as number,
      fontSize: '0.875rem',
      lineHeight: '1.059rem',
      marginLeft: theme.spacing(1.2),
      color: '#424242',
    },
  });
});
