import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) => {
  return createStyles({
    noData: {
      borderBottom: 'none',
      textAlign: 'center',
    },

    tableRoot: {
      scrollBehavior: 'smooth',
      scrollPaddingTop: 'calc(80px + 20vh)',
      overflow: 'visible',
      position: 'relative',
      flexGrow: 1,
      minHeight: '180px',
      maxWidth: '100%',
      ['@media screen and (max-width: 768px)']: {
        overflow: 'auto',
      },

      '& table': {
        tableLayout: 'fixed',
        width: '100%',
        ['@media screen and (max-width: 768px)']: {
          tableLayout: 'auto',
        },
      },

      '& td': {
        height: theme.spacing(7),
        position: 'relative',
        boxSizing: 'border-box',
        flexGrow: 1,
      },
    },

    loadingContainer: {
      position: 'absolute',
      top: '32px',
      left: 0,
      right: 0,
      bottom: 0,
      display: 'grid',
      placeItems: 'center',
      zIndex: 4,
    },

    tableHead: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      backgroundColor: 'white',
    },

    tableHeadCell: {
      paddingBottom: theme.spacing(1),
      fontSize: '0.75rem',
      color: theme.palette.grey[800],
      fontWeight: 600,
      letterSpacing: '0.06em',
      outline: 'none',
      boxShadow: 'none',
      whiteSpace: 'nowrap',

      '&.MuiTableCell-stickyHeader': {
        backgroundColor: 'white',
        zIndex: 10,
      },

      '& span': {
        position: 'relative',
      },

      '& span::after': {
        position: 'absolute',
        content: '""',
        top: theme.spacing(-0.5),
        left: theme.spacing(-1),
        right: theme.spacing(-1),
        bottom: theme.spacing(-0.5),
        borderRadius: '2px',
        transition: 'all 0.2s ease-in-out',
      },

      '&:focus-visible span::after': {
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      },
    },

    tableRowHover: {
      '&:hover': {
        background: theme.palette.grey[100],
        cursor: 'pointer',
      },
    },

    tableRow: {
      position: 'relative',
      transform: 'translateZ(0)',

      '&:focus-within': {
        background: theme.palette.grey[100],
      },

      '&:last-of-type td': {
        borderBottom: 'none',
      },
      '&.Mui-selected': {
        background: theme.palette.grey[100],
      },
    },

    tableRowRoot: {
      '&$tableRowSelected': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    tableRowSelected: {
      backgroundColor: theme.palette.primary.main,
    },

    linkCell: {
      cursor: 'pointer',
      '&:has(button:not(:disabled))': {
        cursor: 'default',
      },
    },

    rowLink: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: theme.spacing(9),
      outline: 'none',
      boxShadow: 'none',

      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    },

    linkLabel: {
      position: 'absolute',
      clip: 'rect(0 0 0 0)',
      width: '1px',
      height: '1px',
      margin: '-1px',
      padding: 0,
      border: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });
});
