import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    view: {
      padding: theme.spacing(3),
      height: '100%',
    },
    roleContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(0.5),
    },
    roleDescription: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      gap: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    },

    viewContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      minHeight: '0',
      padding: `0 ${theme.spacing(4)}px`,
      position: 'relative',
      '& table': {
        paddingBottom: `${theme.spacing(10)}px`,
      },
    },

    label: {
      fontWeight: 600,
    },
    role: {
      textTransform: 'capitalize',
    },
    createTeamContainer: {
      paddingBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    menuItem: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '& svg': {
        fontSize: '1rem',
        marginRight: 10,
      },
    },
    ownerText: {
      color: theme.palette.primary.main,
      fontWeight: 800,
      textTransform: 'uppercase',
      fontSize: '0.625rem',
      letterSpacing: '.1em',
    },
    youText: {
      color: theme.palette.primary.main,
      fontWeight: 800,
      fontSize: '0.625rem',
      letterSpacing: '.1em',
      textTransform: 'uppercase',
      marginLeft: theme.spacing(2),
    },
    link: {
      fontWeight: Number(theme.typography.fontWeightBold),
      color: ACTIVE_TEXT_COLOR,
      textDecoration: 'underline',
    },
    email: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      '& .Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);
