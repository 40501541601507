import { createReducer } from '@reduxjs/toolkit';

import { TEAM_INITIAL_STATE } from './team.state';
import {
  ADD_TEAM_V2_API,
  ADD_TEAM_V2_SUCCESS_API,
  ADD_TEAM_V2_FAILURE_API,
  FETCH_TEAMS_API,
  FETCH_TEAMS_FAILURE_API,
  FETCH_TEAMS_SUCCESS_API,
  FETCH_TEAM_V2_API,
  FETCH_TEAM_V2_SUCCESS_API,
  FETCH_TEAM_V2_FAILURE_API,
  RESEND_TEAM_MEMBER_INVITATION_API,
  RESEND_TEAM_MEMBER_INVITATION_FAILURE_API,
  RESEND_TEAM_MEMBER_INVITATION_SUCCESS_API,
  RESET_TEAM_ERROR,
  RESET_TEAM_RESPONSE,
  RESET_TEAM_STATE,
  ADD_TEAM_MEMBER_API_V2,
  ADD_TEAM_MEMBER_SUCCESS_API_V2,
  ADD_TEAM_MEMBER_FAILURE_API_V2,
  UPDATE_TEAM_MEMBER_API_V2,
  UPDATE_TEAM_MEMBER_SUCCESS_API_V2,
  UPDATE_TEAM_MEMBER_FAILURE_API_V2,
  UPDATE_TEAM_MEMBER_ROLE_API,
  UPDATE_TEAM_MEMBER_ROLE_SUCCESS_API,
  UPDATE_TEAM_MEMBER_ROLE_FAILURE_API,
  DELETE_TEAM_API,
  DELETE_TEAM_SUCCESS_API,
  DELETE_TEAM_FAILURE_API,
  FETCH_TEAM_MEMBERS_API,
  FETCH_TEAM_MEMBERS_SUCCESS_API,
  FETCH_TEAM_MEMBERS_FAILURE_API,
  DELETE_TEAM_MEMBER_FROM_TEAM_API,
  DELETE_TEAM_MEMBER_FROM_TEAM_SUCCESS_API,
  DELETE_TEAM_MEMBER_FROM_TEAM_FAILURE_API,
  DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_API,
  DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_SUCCESS_API,
  DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_FAILURE_API,
  UPDATE_TEAM_V2_API,
  UPDATE_TEAM_V2_SUCCESS_API,
  UPDATE_TEAM_V2_FAILURE_API,
  UPDATE_MEMBERS_OWNER_SUCCESS_API_V2,
  RESET_STORAGE_TEAM,
} from './team.action';

import { TeamsResponse, TeamUserResponse } from 'types';

export const teamMemberUpdater = (
  originalMemberList: TeamUserResponse[],
  newOrUpdatedMembers: TeamUserResponse[],
): TeamUserResponse[] => {
  const newUsers = [...originalMemberList];
  newOrUpdatedMembers.forEach((element) => {
    const index = newUsers.findIndex((el) => el.userUuid === element.userUuid);
    if (index !== -1) {
      newUsers.splice(index, 1, element);
    } else {
      newUsers.push(element);
    }
  });
  return newUsers;
};

export const teamDeleter = (originalTeams: TeamsResponse, uuid: string): TeamsResponse => {
  return {
    meta: { ...originalTeams.meta, count: originalTeams.meta.count - 1 },
    teams: originalTeams.teams.filter((element) => element.uuid !== uuid),
  };
};

export const teamReducer = createReducer(TEAM_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_TEAM, () => TEAM_INITIAL_STATE)
    .addCase(RESET_TEAM_ERROR, (state) => ({ ...state, error: null }))
    .addCase(RESET_TEAM_RESPONSE, (state) => ({ ...state, response: null }))
    .addCase(RESET_TEAM_STATE, () => ({ ...TEAM_INITIAL_STATE }))
    .addCase(FETCH_TEAM_MEMBERS_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(FETCH_TEAM_MEMBERS_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      teamMembers: action.payload,
    }))
    .addCase(FETCH_TEAM_MEMBERS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: { ...action.payload, message: ['fetch'] },
    }))
    .addCase(FETCH_TEAMS_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(FETCH_TEAMS_SUCCESS_API, (state, action: { type: any; payload: TeamsResponse }) => ({
      ...state,
      loading: false,
      teams: action.payload,
    }))
    .addCase(FETCH_TEAMS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: { ...action.payload, message: ['fetch'] },
    }))
    .addCase(FETCH_TEAM_V2_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(FETCH_TEAM_V2_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      team: action.payload,
    }))
    .addCase(FETCH_TEAM_V2_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: { ...action.payload, message: ['fetch'] },
    }))
    .addCase(ADD_TEAM_MEMBER_API_V2, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_TEAM_MEMBER_API_V2, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(ADD_TEAM_MEMBER_SUCCESS_API_V2, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
      team: state.team && {
        ...state?.team,
        users: teamMemberUpdater(state.team.users, action.payload.users),
      },
    }))
    .addCase(UPDATE_TEAM_MEMBER_SUCCESS_API_V2, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
      team: { ...state.team, users: teamMemberUpdater(state.team.users, action.payload.users) },
    }))
    .addCase(UPDATE_MEMBERS_OWNER_SUCCESS_API_V2, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(ADD_TEAM_V2_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(ADD_TEAM_V2_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(ADD_TEAM_V2_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_TEAM_V2_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_TEAM_V2_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(UPDATE_TEAM_V2_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(ADD_TEAM_MEMBER_FAILURE_API_V2, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_TEAM_MEMBER_FAILURE_API_V2, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DELETE_TEAM_MEMBER_FROM_TEAM_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_TEAM_MEMBER_FROM_TEAM_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(DELETE_TEAM_MEMBER_FROM_TEAM_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(DELETE_TEAM_MEMBER_FROM_ALL_TEAMS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DELETE_TEAM_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_TEAM_SUCCESS_API, (state, action: any) => ({
      ...state,
      teams: teamDeleter(state.teams, action.payload.uuid),
      loading: false,
      response: action.payload,
    }))
    .addCase(DELETE_TEAM_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(RESEND_TEAM_MEMBER_INVITATION_API, (state: any) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(RESEND_TEAM_MEMBER_INVITATION_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(RESEND_TEAM_MEMBER_INVITATION_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_TEAM_MEMBER_ROLE_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_TEAM_MEMBER_ROLE_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(UPDATE_TEAM_MEMBER_ROLE_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }));
});
