import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
      gapY: theme.spacing(2),
      '& .MuiGrid-root.MuiGrid-item': {
        paddingBottom: 0,
        paddingTop: 0,
      },
      '& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-true': {
        minWidth: theme.spacing(23),
      },
    },
    saveButtonContainer: {
      minWidth: theme.spacing(22),

      display: 'flex',
      alignItems: 'center',

      '&.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-true': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
      },
    },
  }),
);
