import React, { useEffect, useState } from 'react';

import { Paper, Typography, Grid, IconButton } from '@material-ui/core';

import { useSelector } from 'react-redux';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useTranslation } from 'react-i18next';

import useStyle from '../../style';

import ServiceChip from '../../../../components/service-chip';
import storeService from '../../../../store';

import { Business, Connection } from '../../../business/types/interfaces';
import DateDisplay from '../../../../components/date-display';

import { ConnectionStatus } from '../../../business/types/enums';

import BusinessName from './business-name';

import { getSelectedBusiness } from 'store/features/business/business.selector';
import { setClipboard } from 'helpers/common.helper';

function CopyIcon({ text, className }: { text: string; className: string }): JSX.Element {
  const [copied, setCopied] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <span className={className}>
      <IconButton
        data-testid={'copy-button'}
        className={'copy-button'}
        onClick={(): void => {
          setClipboard(text);
          setCopied(true);
          setTimeout(() => setCopied(false), 10000);
        }}
      >
        <FileCopyIcon className="copy-button__icon" />
      </IconButton>
      {copied && (
        <span className={'copy-button-copied-text'} data-testid={'copy-success-message'}>
          {t('DASHBOARD_TEXT_COPIED_ICON_SHORT_FEEDBACK')}
        </span>
      )}
    </span>
  );
}

export default function BusinessInfo(): JSX.Element {
  const classes = useStyle();
  const store = storeService.getStore();
  const [connection, setConnection] = useState<Connection>(null);
  const selectedBusiness = useSelector<ReturnType<typeof store.getState>, Business>(
    getSelectedBusiness,
  );

  useEffect(() => {
    if (selectedBusiness) {
      const singleConnection = selectedBusiness.connections?.find(
        (connection) =>
          (!!connection.firstRecordDate && connection.status === ConnectionStatus.ACTIVE) ||
          !!connection.firstRecordDate ||
          connection.status === ConnectionStatus.ACTIVE,
      );
      setConnection(singleConnection);
    }
  }, [selectedBusiness]);

  return (
    <Grid item xs={12} md={12}>
      <Paper className={classes.headline}>
        <Grid container spacing={1}>
          <Grid item md={5} xs={12} sm={12} className={classes.businessName}>
            <BusinessName errorBusiness={''} />
            <Typography className={classes.uuidRowLabel}>
              {selectedBusiness?.businessUuid}
              <CopyIcon text={selectedBusiness?.businessUuid} className={classes.copyIcon} />
            </Typography>
          </Grid>
          <Grid item md={2} xs={6} sm={3}>
            <DateDisplay
              date={connection?.createdAt}
              label={'DASHBOARD_BUSINESS_CREATED'}
              testId={'business-info-start'}
            />
          </Grid>
          <Grid item md={2} xs={6} sm={3}>
            <DateDisplay
              date={connection?.updatedAt}
              label={'DASHBOARD_FINANCIAL_SUMMARY_UPDATED'}
              testId={'business-info-end'}
            />
          </Grid>
          <Grid item md xs={12} sm={6}>
            {selectedBusiness && (
              <ServiceChip
                business={selectedBusiness}
                label={'DASHBOARD_FINANCIAL_SUMMARY_SERVICE_TITLE'}
                testId={'business-info-chip'}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
