import Moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Pagination } from '@material-ui/lab';

import { Chip, Link } from '@material-ui/core';

import classNames from 'classnames';

import useStyles from './style';

import WebhookLogsEmptyState from './webhook-logs-empty-state';

import WebhookHistoryDetail from './webhook-history-detail';

import WebhookLogsFilters from './webhook-logs-filters';

import useDefaultStyles from 'assets/styles/style';

import { Header, ScrollView, View } from 'components';
import PageDescription from 'components/page-description/page-description';
import {
  PaginationProps,
  PAGINATION_LIMIT,
  EventKind,
  WebhookDateRange,
  WEBHOOK_STATE,
} from 'types';
import { openNewTab } from 'helpers/open-new-tab';
import BasicTable from 'components/table';
import {
  getWebhookHistory,
  getWebhooks,
  isWebhookLoading,
} from 'store/features/developer/webhook/webhook.selector';
import {
  fetchWebhookApi,
  fetchWebhookHistoryApi,
} from 'store/features/developer/webhook/webhook.action';
import { getConnectionServiceName, getEnumKeyByEnumValue } from 'helpers/common.helper';

import { getDateRange } from 'helpers/format.helper';

interface Props {
  testid?: string;
}

const WebhookLogs: React.FC<Props> = ({ testid = 'test-header-webhook-history' }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const defaultClasses = useDefaultStyles();

  const [webhookHistoryData, setWebhookHistoryData] = useState([]);
  const [queryFilter, setQueryFilter] = useState(getDateRange(0, 7, 0));
  const [selectedLog, setSelectedLog] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const isLoading = useSelector(isWebhookLoading);
  const webhookResult = useSelector(getWebhooks);
  const { loading: isHistoryLoading, webhookHistory } = useSelector(getWebhookHistory);

  useEffect(() => {
    refreshWebhookLogs(queryFilter);
    dispatch(fetchWebhookApi());
  }, []);

  useEffect(() => {
    if (webhookHistory.meta && webhookHistory.webhookHistory) {
      setWebhookHistoryData(webhookHistory.webhookHistory);
    }
  }, [webhookHistory]);

  const refreshWebhookLogs = (params?: PaginationProps & WebhookDateRange): any => {
    let localOffset = Number((currentPage - 1) * PAGINATION_LIMIT);
    if (params?.offset !== undefined) localOffset = params?.offset;

    const payload = {
      ...params,
      limit: params?.limit || PAGINATION_LIMIT,
      offset: localOffset,
    };
    return dispatch(fetchWebhookHistoryApi(payload));
  };

  const openWebhookHistoyDetails = (row): void => {
    setSelectedLog(row);
  };

  const closeWebhookDetails = (): void => {
    setSelectedLog(null);
  };

  const handleSelectedFilter = (filterParams): void => {
    setQueryFilter(filterParams);
    setSelectedLog(null);
    setCurrentPage(1);
  };

  const columns = [
    {
      key: 'statusCode',
      name: t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_STATUS'),
      className: classNames(classes.tableHead, classes.statusColumn),
      render: ({ status }): React.ReactElement => (
        <Chip
          className={`${classes.chip} ${
            status === 'success'
              ? `${classes.tableChipActive} ${classes.chipActive}`
              : `${classes.tableChipInactive} ${classes.chipInactive}`
          }`}
          data-testid={`webhook-secret-status-chip`}
          label={WEBHOOK_STATE[status]}
          size="small"
        />
      ),
    },

    {
      key: 'event',
      name: t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_EVENT'),
      className: classNames(classes.tableHead, classes.eventColumn),
      render: ({ event }): React.ReactElement => <span className={classes.rowText}>{event}</span>,
    },
    {
      key: 'connectionUuid',
      name: t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_CONNECTIONUUID'),
      className: classNames(classes.tableHead, classes.connUuidColumn),
      render: ({ connectionUuid }): React.ReactElement => (
        <span className={classes.rowText}>{connectionUuid}</span>
      ),
    },
    {
      key: 'serviceName',
      name: t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_SERVICE_NAME'),
      className: classNames(classes.tableHead, classes.serviceNameColumn),
      render: ({ serviceName }): React.ReactElement => (
        <span className={classes.rowText}>{serviceName}</span>
      ),
    },
    {
      key: 'timestamp',
      name: t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_TIME_STAMP'),
      className: classNames(classes.tableHead, classes.timestampColumn),
      render: ({ timestamp }): React.ReactElement => (
        <span className={`${classes.rowTextWrap} ${classes.rowText}`}>
          {Moment(timestamp).format('DD MMM YYYY hh:mm:ss A')}
        </span>
      ),
    },
  ];

  return (
    <>
      <Header
        drawerMenu={true}
        leftComponent={null}
        rightComponent={null}
        title={t('DASHBOARD_DEVELOPERS_WEBHOOKS_LOGS_TITLE')}
        testId={testid}
      />
      <View className={classes.view}>
        <div className={`${classes.pageDesc} ${selectedLog && 'selected'}`}>
          <PageDescription
            title="DASHBOARD_DEVELOPERS_WEBHOOKS_LOGS_HEADER"
            subtitle="DASHBOARD_DEVELOPERS_WEBHOOKS_LOGS_DESCRIPTION"
            subtitleComponents={{
              a: (
                <Link
                  href="https://docs.railz.ai/reference/webhooks"
                  onClick={(e): void => openNewTab(e, 'https://docs.railz.ai/reference/webhooks')}
                  rel="noopener noreferrer"
                  className={defaultClasses.link}
                >
                  {t('DASHBOARD_LEARN_MORE_CTA')}
                </Link>
              ),
            }}
          />
        </div>
        <WebhookLogsFilters
          handleSelected={handleSelectedFilter}
          componentClasses={`${classes.filterContainer} ${selectedLog && 'selected'}`}
        />
        <div className={classes.mainGrid}>
          <div className={`${classes.leftSideContainer} ${selectedLog && 'selected'}`}>
            <ScrollView classNames={classes.scrollContainer}>
              {!isLoading && !isHistoryLoading && webhookHistory?.webhookHistory?.length === 0 ? (
                <WebhookLogsEmptyState
                  hasWebhooks={webhookResult?.length > 0}
                  hasWebhookHistory={webhookHistoryData?.length > 0}
                />
              ) : (
                <BasicTable
                  data-testid="webhook-history-table"
                  columns={columns}
                  rows={webhookHistoryData?.map((webhookData) => {
                    return {
                      ...webhookData,
                      statusCode: webhookData.statusCode,
                      event: t(
                        `DASHBOARD_ENUM_WEBHOOK_KIND_${getEnumKeyByEnumValue(
                          EventKind,
                          webhookData.event,
                        )}`,
                      ),
                      connectionUuid: webhookData.connectionUuid,
                      serviceName: getConnectionServiceName({
                        serviceName: webhookData.serviceName,
                        isMockData: webhookData.sandbox ? webhookData?.payload?.isMockData : false,
                      }),
                      timestamp: webhookData.timestamp,
                    };
                  })}
                  stickyHeader={true}
                  isPaperComponent={false}
                  isHoverable={true}
                  onRowClick={{ actionFunction: openWebhookHistoyDetails }}
                  isLoading={isHistoryLoading}
                  selectionKey={'requestId'}
                  selectionId={selectedLog?.requestId}
                />
              )}
            </ScrollView>

            {webhookHistory?.meta?.totalPages > 1 && (
              <div className={classes.pagination}>
                <Pagination
                  count={webhookHistory?.meta?.totalPages}
                  page={currentPage}
                  defaultPage={1}
                  onChange={(_, pagerNumber): void => {
                    setCurrentPage(pagerNumber);
                    refreshWebhookLogs({
                      ...queryFilter,
                      offset: (pagerNumber - 1) * PAGINATION_LIMIT,
                    });
                  }}
                />
              </div>
            )}
          </div>

          {selectedLog && (
            <div className={classes.rightSideContainer}>
              <WebhookHistoryDetail
                webhookHistory={selectedLog}
                handleClose={closeWebhookDetails}
              />
            </div>
          )}
        </div>
      </View>
    </>
  );
};

export default WebhookLogs;
