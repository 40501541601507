import { createSelector } from 'reselect';

import { AllState } from '../../state';
import { FormattedDataTypes } from '../report/report.state';

import { IntegrationState } from './integration.state';

import { getLocalizedService } from 'helpers/common.helper';
import type { Integration } from 'types';

/** Select Integration State */
export const getIntegrationState = (state: AllState): IntegrationState => state.integration;

export const getDataTypesState = (state: AllState): FormattedDataTypes =>
  state.report && state.report.aspDataTypes && state.report.aspDataTypes.reportTypes;

export const getIntegrations = createSelector(
  getIntegrationState,
  (integration: IntegrationState) => {
    if (!integration?.integrations?.length) return [];
    // return only the non-deleted ones by default
    return integration.integrations.filter((integration) => !integration.deletedAt);
  },
);

function integrationsWithConnectionsSelector(integration: IntegrationState): Array<Integration> {
  if (!integration?.integrations?.length) return [];
  return integration.integrations
    .map((integration) => {
      if (!integration.connectionCount) return;
      if (!integration.uuid) return;
      const name = getLocalizedService(integration.service);
      if (!integration.isMockData) return { ...integration, name };
      return { ...integration, name: `Railz Sandbox - ` + name };
    })
    .filter(Boolean)
    .sort((a, b) => a.name.localeCompare(b.name));
}

export const getIntegrationsWithConnections = createSelector(
  getIntegrationState,
  integrationsWithConnectionsSelector,
);

export const getGroupedIntegrationsWithConnections = createSelector(
  getIntegrationState,
  (integration: IntegrationState) => {
    const integrations = integrationsWithConnectionsSelector(integration);
    if (!integrations?.length) return [];
    const groups = integrations.reduce((acc, integration) => {
      if (!acc[integration.name]) {
        acc[integration.name] = [];
      }
      acc[integration.name].push(integration.uuid);
      return acc;
    }, {}) as Record<string, string[]>;

    const groupsAsArray = Object.entries(groups).map(([name, uuids]) => ({ name, uuids }));
    return groupsAsArray as Array<{ name: string; uuids: string[] }>;
  },
);

export const getSyncConfigs = createSelector(
  getIntegrationState,
  (integration: IntegrationState) => integration && integration.syncConfigs,
);

export const getError = createSelector(
  getIntegrationState,
  (integration: IntegrationState) => integration && integration.error,
);

export const getResponse = createSelector(
  getIntegrationState,
  (integration: IntegrationState) => integration && integration.success,
);

export const isIntegrationLoading = createSelector(
  getIntegrationState,
  (integration: IntegrationState) => integration.loading,
);
export const isIntegrationActive = createSelector(
  getIntegrationState,
  (integration: IntegrationState) => {
    return integration && integration.isActive;
  },
);
