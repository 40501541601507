import React, { useMemo } from 'react';
import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { MouseEventHandler } from 'styled-jsx/node_modules/@types/react';
import { useSelector } from 'react-redux';

import { getUserRole } from '../../../store/features/account/profile/profile.selector';
import storeService from '../../../store';
import { Role } from '../../../types';

interface Props {
  columnsHeader: any;
  classes: any;
  orderBy: any;
  order: any;
  handleSort: (key: string) => MouseEventHandler<HTMLSpanElement>;
  testid?: string;
}

const BusinessHeader: React.FC<Props> = ({
  columnsHeader,
  orderBy,
  order,
  classes,
  handleSort,
  testid = 'business-header',
}) => {
  const { t } = useTranslation();
  const store = storeService.getStore();
  const role = useSelector<ReturnType<typeof store.getState>, Role>(getUserRole);
  const headers = useMemo(
    () =>
      columnsHeader &&
      columnsHeader?.length &&
      columnsHeader?.map((headCell) =>
        headCell?.role && !headCell?.role?.includes(role) ? null : (
          <TableCell
            key={headCell.key}
            className={classes.tableHeadCell}
            sortDirection={orderBy === headCell.key && headCell.showSort ? order : false}
            data-testid={`${headCell.key}-td`}
            placeholder="business-header-td"
            aria-label={t(headCell.name)}
          >
            {headCell.showSort ? (
              <TableSortLabel
                active={orderBy === headCell.key}
                direction={orderBy === headCell.key ? order : 'asc'}
                onClick={handleSort(headCell.key)}
                data-testid={`${headCell.key}-label`}
              >
                {t(headCell.name)}
                {orderBy === headCell.key ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel hideSortIcon data-testid={`${headCell.key}-label`}>
                {t(headCell.name)}
              </TableSortLabel>
            )}
          </TableCell>
        ),
      ),
    //eslint-disable-next-line
    [columnsHeader, orderBy, order, classes, t, handleSort],
  );

  return columnsHeader.length ? (
    <TableRow data-testid={`${testid}-row`}>{headers}</TableRow>
  ) : (
    <TableRow />
  );
};

export default BusinessHeader;
