import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: theme.spacing(12),
      gap: theme.spacing(1),
      position: 'relative',
      transition: 'all 0.2s ease-in-out',

      '&:has($link):hover $headerContent': {
        transform: 'translate3d(0, -50%, 0) !important',
      },

      '&:has($link):focus-within $headerContent': {
        transform: 'translate3d(0, -50%, 0) !important',
      },

      '&:hover $link': {
        opacity: '1 !important',
        transform: 'translate3d(0,0,0) !important',
      },

      '&:hover $linkLabel': {
        opacity: '1 !important',
        transform: 'translate3d(0,0,0) !important',
      },

      '&:hover $linkIcon': {
        opacity: '1 !important',
        transform: 'translate3d(0,0,0) scale(.8) !important',
      },
    },

    container: {
      position: 'relative',
      width: '100%',
      borderRadius: theme.spacing(1),
      display: 'flex',
      flexGrow: 1,
      boxShadow: `0 0 0 1px ${theme.palette.grey[300]}`,
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      transition: 'all 0.2s ease-in-out',
      color: '#070A0E',

      '& *': {
        boxSizing: 'border-box',
      },

      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      },

      '&.pending': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
      },

      '&.inactive': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        boxShadow: `0 0 0 1px ${theme.palette.grey[100]}`,
      },

      '&:hover $tooltipIcon': {
        opacity: '1 !important',
        transform: 'scale(1) !important',
        pointerEvents: 'auto',
      },
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: `0 ${theme.spacing(1)}px`,
      gap: theme.spacing(1),
      marginRight: theme.spacing(2),
      lineHeight: 1,
      flexShrink: 1,
      transition: 'all 0.2s ease-in-out',
      willChange: 'transform',
    },

    headerContent: {
      position: 'relative',
      transition: 'all 0.2s ease-in-out',
    },

    title: {
      fontSize: '18px',
      fontWeight: 600,
      display: 'block',
    },

    descriptionContainer: {
      position: 'relative',
      display: 'flex',

      '&:has($dateRange):hover $description': {
        backgroundColor: theme.palette.grey[100],
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
        borderRadius: theme.spacing(1),
      },

      '&:has($dateRange):hover $dateRange': {
        transform: 'translate3d(0,0,0)',
        opacity: 1,
        pointerEvents: 'auto',
      },
    },

    description: {
      fontFeatureSettings: `'clig' off, 'liga' off`,
      fontSize: '0.75rem',
      fontWeight: +theme.typography.fontWeightMedium,
      color: theme.palette.grey[800],
      transition: 'all 0.2s ease-in-out',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      marginLeft: theme.spacing(-1),
      marginTop: theme.spacing(0.5),
    },

    dateRange: {
      position: 'absolute',
      left: 0,
      top: '100%',
      fontSize: '10px',
      color: theme.palette.grey[100],
      fontWeight: +theme.typography.fontWeightBold,
      padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
      backgroundColor: theme.palette.grey[900],
      borderRadius: theme.spacing(1),
      boxShadow: `0 4px 8px -2px ${theme.palette.grey[900]}`,
      whiteSpace: 'nowrap',
      zIndex: 1,
      transition: 'all 0.2s ease-in-out',
      opacity: 0,
      visiblity: 'visible',
      pointerEvents: 'none',
      transformOrigin: 'top left',
      transform: 'translate3d(0, -25% ,0) scale(.8)',
    },

    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.lifetime': {
        color: theme.palette.grey[800],
      },
    },

    statContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      minHeight: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 1,
    },

    value: {
      lineHeight: 1,
      fontSize: '36px',
      fontWeight: +theme.typography.fontWeightBold,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },

    valueDescription: {
      lineHeight: 1,
      fontSize: '0.75rem',
      fontWeight: +theme.typography.fontWeightRegular,
      color: theme.palette.grey[800],
      fontFeatureSettings: `'clig' off, 'liga' off`,
      textAlign: 'right',
      '& b, & strong': {
        color: theme.palette.common.black,
        fontWeight: 600,
      },
    },

    tooltipContainer: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      width: '80%',
      maxWidth: '22ch',
      transition: 'all 0.2s ease-in-out',
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'none',

      '&:hover $tooltip': {
        pointerEvents: 'auto',
        opacity: 1,
        transform: `translate3d(${theme.spacing(1)}px, 0 ,0) scale(1)`,
      },

      '&:hover $tooltipText': {
        opacity: 1,
        transform: 'scale(1)',
      },
    },

    tooltipIcon: {
      marginLeft: 'auto',
      transition: 'all 0.2s ease-in-out',
      pointerEvents: 'auto',
      transformOrigin: 'top right',
      willChange: 'transform, opacity',
      opacity: 0.5,
      transform: 'scale(.5)',
    },

    tooltip: {
      width: '100%',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      background: `var(--primary-dark-green, #003032)`,
      borderRadius: theme.spacing(1),
      boxShadow: `0 4px 8px -2px ${theme.palette.grey[900]}`,
      zIndex: 1,
      opacity: 0,
      transition: 'all 0.2s ease-in-out',
      transformOrigin: 'top right',
      transform: 'translate3d(0, -25% ,0) scale(.5)',
    },

    tooltipText: {
      fontSize: '0.75rem',
      lineHeight: 1.4,
      color: theme.palette.grey[100],
      fontWeight: +theme.typography.fontWeightMedium,
      display: 'block',
      opacity: 0,
      transition: 'all 0.4s ease-in-out',
      transformOrigin: 'top right',
      transform: `scale(.8)`,
    },

    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      gap: theme.spacing(1),
      position: 'absolute',
      top: '100%',
      borderRadius: theme.spacing(1),
      transition: 'all 0.2s ease-in-out',
      transform: 'translate3d(0, 25%, 0)',
      opacity: 0,
      margin: `-2px ${theme.spacing(-1)}px`,
      padding: `2px ${theme.spacing(1)}px`,

      '&:focus': {
        outline: 'none',
        boxShadow: `inset 0 0 0 1px white, 0 0 0 1px ${theme.palette.primary.main}`,
        opacity: '1 !important',
        transform: 'translate3d(0,0,0) !important',
      },
      '&:focus $linkLabel': {
        opacity: '1 !important',
        transform: 'translate3d(0,0,0) !important',
      },
      '&:focus $linkIcon': {
        opacity: '1 !important',
        transform: 'translate3d(0,0,0) !important',
      },
    },

    linkLabel: {
      fontSize: '0.75rem',
      whiteSpace: 'nowrap',
      transition: 'all 0.2s ease-in-out',
      transform: 'translate3d(0, 25%, 0)',
      opacity: 0,
    },

    linkIcon: {
      transition: 'all 0.4s ease-in-out',
      transformOrigin: 'center left',
      transform: 'translate3d(-200%, 0, 0) scale(0)',
      opacity: 0,
    },
  }),
);
