import axios from 'axios';

import Config from '../config';

class AuthService {
  async resendVerifyEmail(data): Promise<void> {
    await axios.post(`${Config.SERVER_URL}/auth/requestVerifyEmail`, data);
  }

  async verifyEmail(token): Promise<void> {
    await axios.post(`${Config.SERVER_URL}/auth/verifyEmail`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      external: true,
    });
  }

  async forgotPassword(email: string): Promise<void> {
    await axios.post(`${Config.SERVER_URL}/auth/requestResetPassword`, {
      email,
    });
  }

  async resetPassword(values: { password: string }, token: string): Promise<void> {
    await axios.post(`${Config.SERVER_URL}/auth/resetPassword`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      external: true,
    });
  }

  async setupAccount(
    values: { firstName: string; lastName: string; password: string },
    token: string,
  ): Promise<void> {
    await axios.post(`${Config.SERVER_URL}/auth/setupAccount`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      external: true,
    });
  }
}

export default new AuthService();
