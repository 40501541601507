import React, { useEffect } from 'react';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './style';

import YearsToSync from './years-to-sync';

import InitialSync from './initial-sync';

import DataSyncFrequency from './data-sync-frequency';

import { Header, ScrollView, View } from 'components';
import PageDescription from 'components/page-description/page-description';
import useDefaultStyles from 'assets/styles/style';

import { fetchSyncConfigsApi } from 'store/features/integration/integration.action';
import { fetchDataTypesApi } from 'store/features/report/report.action';
import { showSnackbar } from 'helpers/common.helper';
import { getError, getResponse } from 'store/features/integration/integration.selector';
import { openNewTab } from 'helpers/open-new-tab';

export default function DataSync(): JSX.Element {
  const { t } = useTranslation();
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector(getError);
  const response = useSelector(getResponse);

  useEffect(() => {
    dispatch(fetchSyncConfigsApi());
    dispatch(fetchDataTypesApi());
  }, []);

  useEffect(() => {
    if (error) {
      showSnackbar({
        message: t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_TOAST_ERROR'),
        type: 'error',
      });
    }
    if (response) {
      showSnackbar({
        message: t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_TOAST_SUCCESS'),
        type: 'success',
      });
    }
  }, [error, response]);

  return (
    <>
      <Header
        drawerMenu={true}
        title={t('DASHBOARD_NAV_DATA_SYNC')}
        leftComponent={null}
        rightComponent={null}
        testId={'header-data-sync'}
      />
      <ScrollView classNames={classes.view}>
        <div className={classes.viewHeader}>
          <PageDescription
            title="DASHBOARD_DATA_SYNC_TITLE"
            subtitle="DASHBOARD_DATA_SYNC_DESCRIPTION"
            subtitleComponents={{
              a: (
                <Link
                  href="https://docs.railz.ai/docs/dashboard-data-sync-settings"
                  onClick={(e): void =>
                    openNewTab(e, 'https://docs.railz.ai/docs/dashboard-data-sync-settings')
                  }
                  rel="noopener noreferrer"
                  className={defaultClasses.link}
                >
                  {t('DASHBOARD_LEARN_MORE_CTA')}
                </Link>
              ),
            }}
          />
          <YearsToSync></YearsToSync>
          <DataSyncFrequency></DataSyncFrequency>
        </div>
        <View className={classes.viewContent}>
          <InitialSync></InitialSync>
        </View>
      </ScrollView>
    </>
  );
}
