import { Trans, useTranslation } from 'react-i18next';
import { Box, Card, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../store';

import {
  enableDisableSSOApi,
  resetCustomerResponse,
} from '../../store/features/account/customer/customer.action';

import useStyle from './style';

import { Toggle } from './toggle';

import { Header } from 'components';
import AlertDialog from 'components/dialog';
import { generateDropdown, showSnackbar } from 'helpers/common.helper';
import { CustomerState } from 'store/features/account/customer/customer.state';
import { getCustomerState } from 'store/features/account/customer/customer.selector';
import { AutoDeletePeriods, Plan, Role } from 'types';
import VectorStart from 'assets/icons/vector-start';
import { getProfileState, getUserRole } from 'store/features/account/profile/profile.selector';
import { ProfileState } from 'store/features/account/profile/profile.state';
import { openNewTab } from 'helpers/open-new-tab';
import {
  resetProfileResponse,
  updateTeamSettingsApi,
} from 'store/features/account/profile/profile.action';

const autoDeletePeriodDropdown = generateDropdown(AutoDeletePeriods);

interface Props {
  testid?: string;
}

export const Settings: React.FC<Props> = ({
  testid = 'test-header-settings-page',
}): React.ReactElement => {
  const { t } = useTranslation();
  const [showDisableSSOModal, setShowDisableSSOModal] = useState(false);
  const [showEnableSSOModal, setShowEnableSSOModal] = useState(false);
  const store = storeService.getStore();
  const customer = useSelector<ReturnType<typeof store.getState>, CustomerState>(getCustomerState);
  const role = useSelector<ReturnType<typeof store.getState>, Role>(getUserRole);
  const profile = useSelector<ReturnType<typeof store.getState>, ProfileState>(getProfileState);
  const [ssoEnabled, setSsoEnabled] = useState(profile?.ssoEnabled || false);
  const classes = useStyle();
  const dispatch = useDispatch();
  const isEnterprise = customer?.billingPlan === Plan.CUSTOM;
  const isSuperAdmin = Role.SUPER_ADMINISTRATOR === role;
  const [autoDeletePeriod, setAutoDeletePeriod] = useState(
    profile?.teamSettings?.autoDeleteBusinessPeriod || AutoDeletePeriods.NEVER,
  );

  const enableSSO = (): void => {
    if (ssoEnabled) {
      dispatch(enableDisableSSOApi({ ssoEnabled: false }));
      setShowDisableSSOModal(false);
    } else {
      dispatch(enableDisableSSOApi({ ssoEnabled: true }));
      setShowEnableSSOModal(false);
    }
  };

  const onPeriodChange = (event): void => {
    setAutoDeletePeriod(event.target.value);
  };

  const submitTeamSettings = (): void => {
    dispatch(updateTeamSettingsApi({ autoDeleteBusinessPeriod: autoDeletePeriod }));
  };

  useEffect(() => {
    setSsoEnabled(customer?.ssoEnabled);
  }, [customer?.ssoEnabled]);

  useEffect(() => {
    setAutoDeletePeriod(profile?.teamSettings?.autoDeleteBusinessPeriod);
  }, [profile?.teamSettings]);

  useEffect(() => {
    if (profile.error) {
      if (profile?.error?.type === 'teamSettingsUpdate') {
        showSnackbar({ message: t('DASHBOARD_SAVE_TOAST_ERROR'), type: 'error' });
        setAutoDeletePeriod(profile?.teamSettings?.autoDeleteBusinessPeriod);
      } else {
        showSnackbar({ message: t('DASHBOARD_SIGNUP_GENERIC_ERROR'), type: 'error' });
      }
    }
  }, [profile.loading]);

  useEffect(() => {
    if (profile?.response) {
      showSnackbar({
        message: t('DASHBOARD_SAVE_TOAST_SUCCESS'),
        type: 'success',
      });
    }
    dispatch(resetProfileResponse());
  }, [profile?.response]);

  useEffect(() => {
    if (customer?.response?.success && customer.ssoEnabled != ssoEnabled) {
      showSnackbar({
        message: t(
          customer.ssoEnabled
            ? 'DASHBOARD_SETTINGS_SSO_SUCCESS_ENABLED_TOAST'
            : 'DASHBOARD_SETTINGS_SSO_SUCCESS_DISABLE_TOAST',
        ),
        type: 'success',
      });

      dispatch(resetCustomerResponse());
    }
  }, [customer?.response]);

  const toggleSSO = (): void => {
    ssoEnabled ? setShowDisableSSOModal(true) : setShowEnableSSOModal(true);
  };

  return (
    <>
      <Header
        drawerMenu={true}
        title={t('DASHBOARD_NAV_SETTINGS')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />
      <Card className={classes.loginContainer}>
        <Typography className={classes.mainTitle}>
          {t('DASHBOARD_SETTINGS_DESCRIPTION_HEADER')}
        </Typography>
        <Typography>{t('DASHBOARD_SETTINGS_DESCRIPTION_SUBHEADER')}</Typography>
        <Box className={classes.containerAction}>
          <Grid className={classes.gridContainer}>
            <Typography className={classes.mainTitle}>{t('DASHBOARD_SETTINGS_HEADER')}</Typography>
            {!isEnterprise && (
              <Link
                to="/account/billing"
                className={classes.linkPlanUpdate}
                aria-label={t('DASHBOARD_SETTINGS_UPDATE_PLAN_ENTERPRISE')}
              >
                <Typography className={classes.textPlanUpdate}>
                  <span className={classes.iconPlanUpdate}>
                    <VectorStart></VectorStart>
                  </span>
                  {t('DASHBOARD_SETTINGS_UPDATE_PLAN_ENTERPRISE')}
                </Typography>
              </Link>
            )}
          </Grid>
          <Grid className={classes.containerGrid}>
            <Typography className={classes.description}>
              <Trans
                i18nKey="DASHBOARD_SETTINGS_SUBHEADER"
                components={{
                  a: (
                    <a
                      onClick={(e): void =>
                        openNewTab(e, 'https://docs.railz.ai/docs/third-party-sso')
                      }
                      rel="noopener noreferrer"
                      className={classes.linkLearnMore}
                      href="https://docs.railz.ai/docs/third-party-sso"
                    >
                      {t('DASHBOARD_LEARN_MORE_CTA')}
                    </a>
                  ),
                }}
              />
            </Typography>
            <Toggle
              disabled={!isEnterprise || !isSuperAdmin}
              onChange={toggleSSO}
              enabled={ssoEnabled}
            ></Toggle>
          </Grid>
        </Box>
      </Card>
      <Card className={classes.container}>
        <Typography className={classes.mainTitle}>
          {t('DASHBOARD_BUSINESS_SETTINGS_DESCRIPTION_HEADER')}
        </Typography>
        <Typography>{t('DASHBOARD_BUSINESS_SETTINGS_DESCRIPTION_SUBHEADER')}</Typography>
        <Box className={classes.containerAction}>
          <Grid className={classes.gridContainer}>
            <Typography className={classes.mainTitle}>
              {t('DASHBOARD_AUTO_DELETE_SETTINGS_HEADER')}
            </Typography>
          </Grid>
          <Grid className={classes.containerGrid}>
            <Typography className={classes.description}>
              <Trans
                i18nKey="DASHBOARD_AUTO_DELETE_SETTINGS_SUBHEADER"
                components={{ b: <strong /> }}
              />
            </Typography>
          </Grid>
          <TextField
            label={t('DASHBOARD_SETTINGS_AUTO_DELETE_PERIOD_LABEL')}
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              'data-testid': 'auto-delete-period-dropdown',
            }}
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                PopoverClasses: {
                  paper: classes.menu,
                },
              },
            }}
            select
            value={autoDeletePeriod}
            onChange={onPeriodChange}
            classes={{
              root: classes.autoDeleteSelect,
            }}
            data-testid="auto-delete-period-input"
          >
            {autoDeletePeriodDropdown.map((period, index) => (
              <MenuItem className={classes.menuItem} key={index} value={period.value}>
                {period?.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Card>
      {autoDeletePeriod !== profile?.teamSettings?.autoDeleteBusinessPeriod && (
        <div className={classes.buttonContainer}>
          <RailzButton
            size="medium"
            onClick={submitTeamSettings}
            data-testid="submit-auto-delete-period"
            label={t('DASHBOARD_BRAND_SAVE_CTA')}
            className={classes.saveCta}
          />
          <RailzButton
            size="small"
            onClick={(): void =>
              setAutoDeletePeriod(profile?.teamSettings?.autoDeleteBusinessPeriod)
            }
            data-testid="cancel-auto-delete-period"
            type="reset"
            label={t('DASHBOARD_CANCEL_CTA')}
            className={classes.discardCta}
          />
        </div>
      )}
      <AlertDialog
        title={t('DASHBOARD_SETTINGS_SSO_MODAL_HEADER')}
        isOpen={showDisableSSOModal}
        onClose={(): void => {
          setShowDisableSSOModal(false);
        }}
        showCloseButton
        cancel={{
          label: t('DASHBOARD_CANCEL_CTA'),
          type: 'gray',
          onClick: () => setShowDisableSSOModal(false),
          'data-testid': 'alert-cancel-button',
        }}
        confirm={{
          label: t('DASHBOARD_SETTINGS_MODAL_CTA'),
          onClick: enableSSO,
          type: 'error',
          'data-testid': 'cta-disable-sso',
        }}
      >
        <Typography>{t('DASHBOARD_SETTINGS_SSO_MODAL_BODY')}</Typography>
      </AlertDialog>

      <AlertDialog
        title={t('DASHBOARD_SETTINGS_SSO_ENABLE_MODAL')}
        isOpen={showEnableSSOModal}
        onClose={(): void => {
          setShowEnableSSOModal(false);
        }}
        showCloseButton
        cancel={{
          label: t('DASHBOARD_SETTINGS_SSO_ENABLE_MODAL_CANCEL'),
          onClick: () => setShowEnableSSOModal(false),
          'data-testid': 'alert-cancel-button',
          type: 'gray',
        }}
        confirm={{
          label: t('DASHBOARD_SETTINGS_SSO_ENABLE_MODAL_ENABLE'),
          onClick: enableSSO,
          'data-testid': 'cta-enable-sso',
        }}
      >
        <Typography>{t('DASHBOARD_SETTINGS_SSO_ENABLE_MODAL_BODY')}</Typography>
      </AlertDialog>
    </>
  );
};
