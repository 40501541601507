import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';

import { getPossibleFileObjectURL } from 'pages/sites/utils';

interface Props {
  uploadImage: string | File | null;
  title: string;
  body: string;
  railzWatermarkEnabled?: boolean;
}

const HEADER_PREVIEW_CHAR_LIMITS = {
  title: 50,
  body: 140,
};

const EmptyLogoPreview: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <div className={classes.logoContainerEmpty}>
      <div className={classes.emptyLogo}>
        <span>{t('DASHBOARD_BRAND_LOGO')}</span>
      </div>
    </div>
  );
};

const LogoPreview: React.FC<{ uploadImage: Props['uploadImage'] }> = ({ uploadImage }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <div className={classes.logoContainer}>
      <img
        className={classes.logo}
        src={getPossibleFileObjectURL(uploadImage)}
        alt={t('DASHBOARD_BRAND_UPLOAD_ALT_TEXT')}
      />
    </div>
  );
};

const HeaderContent: React.FC<Props> = ({ uploadImage, title, body, railzWatermarkEnabled }) => {
  const classes = useStyle();

  return (
    <>
      <div className={classes.headerContent}>
        {uploadImage ? <LogoPreview uploadImage={uploadImage} /> : <EmptyLogoPreview />}
        <Typography className={classes.heading} variant="h4">
          {title?.substring(0, HEADER_PREVIEW_CHAR_LIMITS.title)}
        </Typography>
        <Typography className={classes.description} variant="body2">
          {body?.substring(0, HEADER_PREVIEW_CHAR_LIMITS.body)}
        </Typography>
      </div>
      {!!railzWatermarkEnabled && (
        <div className={classes.footer}>
          <span>Powered by Railz</span>
        </div>
      )}
    </>
  );
};

export default HeaderContent;
