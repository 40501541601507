import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    '@keyframes appear': {
      from: {
        transform: 'scale(.8)',
        opacity: 0,
        filter: 'blur(5px)',
      },
    },

    visible: {
      opacity: 1,
      visibility: 'visible',
      animation: `$appear .2s ease-in-out both`,
    },

    // Content
    heading: {
      fontWeight: 600,
      marginBottom: '.5em',
      fontSize: '1.25em',
    },

    accentLinkText: {
      fontWeight: 600,
      fontSize: '0.85em',
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      display: 'block',
    },

    helpLink: {
      fontWeight: 600,
      fontSize: '0.85em',
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      marginTop: '2em',
      display: 'block',
    },

    cardList: {
      margin: '1.5em auto 0',
      padding: '0 1.5em',
      display: 'grid',
      gap: '.5em',
      width: '60%',

      '& li': {
        listStyle: 'none',
        padding: 0,
        margin: 0,
      },
    },

    serviceCard: {
      display: 'flex',
      alignItems: 'center',
      hyphens: 'auto',
      background: 'white',
      border: '4px solid transparent',
      borderRadius: '10px',
      boxShadow: '0 0 0 2px rgba(0,0,0, 0.1), 0 4px 6px -4px rgba(0,0,0, 0.5)',
      width: '100%',
      padding: 'clamp(.5em, 5%, 1em) clamp(.5em, 5%, 3em)',
      textAlign: 'left',

      '& img': {
        flexShrink: 1,
        objectFit: 'contain',
        width: 'clamp(1em, 20%, 2.5em)',
      },

      '& p': {
        flexGrow: 1,
        width: '80%',
        fontWeight: 600,
        fontSize: '.825em',
        paddingLeft: 'clamp(.5em, 5%, 3em)',
      },
    },
  }),
);
