import { createSelector } from 'reselect';

import moment from 'moment-timezone';

import { AllState } from '../../state';

import { BUSINESS_STORE_NAME, BusinessState } from './business.state';

/** Select Business State */
export const getBusinessState = (state: AllState): BusinessState => state[BUSINESS_STORE_NAME];

export const getSelectedBusiness = createSelector(getBusinessState, (business: BusinessState) => {
  if (!business || !business?.selectedBusiness) return undefined;
  return {
    ...business.selectedBusiness,
    connections: business.selectedBusiness.connections.map((connection) => {
      return {
        ...connection,
        firstRecordDate:
          connection.firstRecordDate || (connection.isMockData && moment('2017-01-01')),
      };
    }),
  };
});

export const getBusinesses = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.businesses,
);

export const getBusinessSuggestions = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.businessSuggestions,
);

export const getBusinessesMeta = createSelector(getBusinessState, (business: BusinessState) => {
  return {
    businesses: business && business.businesses,
    loading: business && business.businessLoading,
    meta: business && business.meta,
  };
});

export const getServices = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.services,
);

export const getBusinessStats = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.businessStats,
);

export const getBusinessEventItems = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.eventItems,
);

export const isLoading = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.loading,
);

export const isBusinessLoading = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.businessLoading,
);

export const getError = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.error,
);

export const getErrorMessage = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.error && business.error.message,
);

export const getErrorStatus = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.error && business.error.status,
);

export const getGeneratedUrl = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.generatedUrl,
);

export const getResponse = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.response,
);

export const getResponseMessage = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.response && business.response.message,
);

export const isSuccess = createSelector(
  getBusinessState,
  (business: BusinessState) => business && business.response && business.response.success,
);
