import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { Chip } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line max-len
import {
  WebhookConfig,
  WebhookSecret as WebhookSecretType,
} from '../../../../types/interface/webhook-secret.interface';

import Accordion from '../../../../components/accordion/accordion';

import { WebhookConfigForm } from '../webhook-config';

import { WEBHOOK_PAYLOAD_TYPE } from '../webhook.utils';

import style from './style';

import WebhookSecretForm from './form';

import {
  getWebhookSecret,
  getWebhookSecretState,
} from 'store/features/developer/webhook-secret/webhook-secret.selector';
// eslint-disable-next-line max-len
import { fetchWebhookSecretApi } from 'store/features/developer/webhook-secret/webhook-secret.action';
import LoadingShimmer from 'components/loading-shimmer';
import { getWebhookConfig } from 'store/features/developer/webhook-config/webhook-config.selector';

export default function WebhookSecret(): JSX.Element {
  const [webhookSecret, setWebhookSecret] = useState<WebhookSecretType>();
  const [webhookConfig, setWebhookConfig] = useState<WebhookConfig>();
  const classes = style();

  const dispatch = useDispatch();
  const webhookSecretSelected = useSelector(getWebhookSecret);

  const isSecretLoading = useSelector(getWebhookSecretState).loading;
  const webhookConfigSelector = useSelector(getWebhookConfig);

  const [chipLabel, setChipLabel] = useState('');
  const [configChipLabel, setConfigChipLabel] = useState('');

  useEffect(() => {
    dispatch(fetchWebhookSecretApi());
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    if (isSecretLoading) {
      setChipLabel(t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_BEAER_ENUM'));
    } else if (webhookSecretSelected) {
      setWebhookSecret(webhookSecretSelected);
      setChipLabel(t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_BEAER_ENUM'));
    } else {
      setChipLabel(t('DASHBOARD_DEVELOPERS_WEBHOOKS_NO_AUTHORIZATION_ENUM'));
    }
  }, [isSecretLoading, webhookSecretSelected]);

  useEffect(() => {
    if (isSecretLoading) {
      setConfigChipLabel(t('DASHBOARD_WEBHOOK_PAYLOAD_TYPE_FULL'));
    } else if (webhookConfigSelector) {
      setWebhookConfig(webhookConfigSelector);
      setConfigChipLabel(
        t(
          `DASHBOARD_WEBHOOK_PAYLOAD_TYPE_${(
            webhookConfigSelector?.payloadType || WEBHOOK_PAYLOAD_TYPE.FULL
          ).toUpperCase()}`,
        ),
      );
    }
  }, [isSecretLoading, webhookConfigSelector]);

  return (
    <>
      <Accordion
        className={classes.root}
        header={
          <span className={classes.header}>
            <Typography variant="h3" className={classes.title}>
              {t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_HEADER')}
            </Typography>
            <LoadingShimmer loading={isSecretLoading} borderRadius="80px">
              <Chip
                className={`${classes.chip} ${
                  webhookSecret ? classes.chipActive : classes.chipInactive
                }`}
                data-testid={`webhook-secret-status-chip`}
                label={chipLabel}
                size="small"
              />
            </LoadingShimmer>
          </span>
        }
        showAccordion={true}
        children={<WebhookSecretForm webhookSecret={webhookSecret} isLoading={isSecretLoading} />}
      />
      <Accordion
        className={classes.root}
        header={
          <span className={classes.header}>
            <Typography variant="h3" className={classes.title}>
              {t('DASHBOARD_DEVELOPERS_WEBHOOKS_CONFIGURE_WEBHOOKS_HEADER')}
            </Typography>
            <LoadingShimmer loading={isSecretLoading} borderRadius="80px">
              <Chip
                className={`${classes.chip} ${classes.chipActive}`}
                data-testid={`webhook-config-status-chip`}
                label={configChipLabel}
                size="small"
              />
            </LoadingShimmer>
          </span>
        }
        showAccordion={true}
        children={
          <WebhookConfigForm
            webhookConfig={webhookConfig}
            isWebhookSecretLoading={isSecretLoading}
          />
        }
      />
    </>
  );
}
