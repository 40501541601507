import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Chip } from '@material-ui/core';

import { RailzButton } from '@railzai/railz-uikit-react';

import Config from '../../../../../config';

import { ReportTypeUrlMapping } from '../../../../../helpers/report-helpers/constants';

import useStyle from './style';

import Dialog from 'components/dialog';

import { ReportData } from 'store/features/report/report.state';
import { CodeBlock } from 'components/code-block';
import { ReportApiReferenceUrl } from 'types';
import { ExpandArrowsIcon } from 'assets/icons/expand-arrows';

interface Props {
  reportName: string;
  data: Partial<ReportData>;
  handleClose: () => void;
}

const buildCodeBlocks = (data: Props['data']): Array<CodeBlock> => {
  const url = new URL(`${Config.FEEDER_SERVER}/${ReportTypeUrlMapping[data.requestParams.type]}`);

  return [
    {
      id: 'url',
      code: url.toString(),
      title: 'DASHBOARD_BUSINESS_REPORTS_API_RESPONSE_URL_BLOCK_TITLE',
      numberedLines: false,
      language: 'http',
    },
    {
      id: 'queryParams',
      code: JSON.stringify(data.requestParams?.params, null, 1),
      title: 'DASHBOARD_BUSINESS_REPORTS_API_RESPONSE_QUERY_PARAMS_BLOCK_TITLE',
      numberedLines: false,
      language: 'json',
    },
    {
      id: 'response',
      code: JSON.stringify(data.data, null, 1),
      title: 'DASHBOARD_BUSINESS_REPORTS_API_RESPONSE_RESPONSE_BLOCK_TITLE',
      numberedLines: true,
      language: 'json',
    },
  ];
};

export const ReportInfoModal: React.FC<Props> = ({ reportName, data, handleClose }) => {
  const [dataGroups] = useState(buildCodeBlocks(data));

  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Dialog
      classContent={classes.reportInfoModal}
      showCloseButton
      isOpen={true}
      title={t('DASHBOARD_BUSINESS_REPORTS_API_RESPONSE_TITLE')}
      onClose={(): void => handleClose()}
    >
      <div className={classes.modalHeaderTitle}>
        <span>
          {reportName}
          <Chip className={`chip`} label={'GET'} size="small" />
        </span>
        <RailzButton
          aria-label={t('DASHBOARD_BUSINESS_REPORTS_API_RESPONSE_REFERENCE_LINK_LABEL_ARIA')}
          size="small"
          type="text primary"
          target="_blank"
          href={ReportApiReferenceUrl[data.requestParams.type]}
          label={t('DASHBOARD_BUSINESS_REPORTS_API_RESPONSE_REFERENCE_LINK_LABEL')}
        >
          <span slot="suffix">
            <OpenInNewIcon fontSize="small" />
          </span>
        </RailzButton>
      </div>
      {dataGroups.map((group) => (
        <CodeBlock key={group.id} {...group} />
      ))}
    </Dialog>
  );
};

export const ReportInfoModalCtaButton: React.FC<{ handleOpen: () => void }> = ({ handleOpen }) => {
  const { t } = useTranslation();

  return (
    <RailzButton
      data-testid={`view-api-response-button`}
      type="outlined"
      label={t('DASHBOARD_BUSINESS_REPORTS_CTA_API_RESPONSE')}
      onButtonClick={handleOpen}
    >
      <span slot="suffix">
        <ExpandArrowsIcon />
      </span>
    </RailzButton>
  );
};
