import { createStyles, makeStyles, Theme } from '@material-ui/core';

import baseStyle from '../base-style';

export default makeStyles((theme: Theme) => {
  const baseStyles = baseStyle();
  return createStyles({
    ...baseStyles,
    container: {
      ...baseStyles.container,
      height: '202px',
      overflow: 'auto',
      justifyContent: 'flex-start',
    },
    subTitle: {
      fontSize: theme.spacing(1.75),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey['800'],
    },
    list: {
      width: '100%',
      bgcolor: theme.palette.background.paper,
      marginTop: theme.spacing(),
      '& .MuiOutlinedInput-notchedOutline': {
        borderStyle: 'none',
      },
      '& .MuiSelect-select:focus': {
        background: 'none',
      },
      '& .MuiSelect-outlined, .Mui-disabled': {
        paddingLeft: '0 !important',
        fontWeight: 600,
        color: '#424242',
      },
      '& .MuiFormControl-root': {
        maxWidth: '100%',
      },
    },
    item: {
      padding: 0,
    },
    itemPrimary: {
      fontSize: theme.spacing(1.75),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey['800'],
    },
    itemDashed: {
      display: 'flex',
      flexGrow: 1,
      borderBottom: `1px dashed ${theme.palette.grey[500]}`,
      margin: `0px ${theme.spacing(2)}px ${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    itemSecondary: {
      fontSize: theme.spacing(1.75),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey['900'],
    },
    itemSecondaryGrid: {
      textAlign: 'end',
    },
    sectionUl: {
      paddingLeft: 0,
    },
    section: {
      fontWeight: theme.typography.fontWeightMedium,
      color: 'black',
      lineHeight: theme.spacing(3.5),
      marginTop: theme.spacing(),
    },
  });
});
