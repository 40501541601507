import React from 'react';

import useStyle from './style';

interface Props {
  loading: boolean;
  children: React.ReactNode;
  borderRadius?: string;
}

export default function LoadingShimmer({
  loading,
  children,
  borderRadius,
}: Props): React.ReactElement {
  const classes = useStyle();

  return (
    <div
      // eslint-disable-next-line no-extra-boolean-cast
      className={`${classes.container} ${!!loading ? classes.loading : classes.loaded}`}
      style={{ borderRadius: borderRadius }}
    >
      <div className={classes.child}>{children}</div>
      <div className={classes.backdrop} data-testid="loading-backdrop">
        <div className={classes.shimmer} />
      </div>
    </div>
  );
}
