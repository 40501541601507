import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyles from './style';

import { ApiKey, Scope, TranslationLabels } from 'types';
import { maskIdentifier } from 'helpers/api-keys';
import { formatDate } from 'helpers/format.helper';
import BasicTable from 'components/table';

export enum ApiKeyActionTypes {
  DELETE = 'delete',
}

interface Props {
  apiKeys: ApiKey[];
  onAction: (type: ApiKeyActionTypes, apiKey: ApiKey) => void;
  copyToClipboard: (identifier: string) => void;
  copied: string;
  isLoading?: boolean;
}

const getScopeLabels = (scopes: Scope[]): { scopeLabel: string; permissionLabels: string[] }[] => {
  if (!scopes) return [{ scopeLabel: '', permissionLabels: [] }];
  const groupedScopes = scopes.reduce((acc, scope) => {
    const [scopeName, permission] = scope.split(':');
    acc[scopeName] = { ...acc[scopeName], [permission]: true };
    return acc;
  }, {});

  return Object.entries(groupedScopes).map(
    ([scopeName, permissions]: [string, Record<'READ' | 'WRITE', true>]) => {
      const scopeLabel = TranslationLabels[scopeName];
      const isFull = 'READ' in permissions && 'WRITE' in permissions;
      return {
        scopeLabel: scopeLabel,
        permissionLabels: isFull
          ? ['DASHBOARD_API_KEY_MODAL_RAILZ_API_OPTION_FULL']
          : ['DASHBOARD_API_KEY_MODAL_VISUALIZATIONS_SDK_OPTION_READ'],
      };
    },
  );
};

export default function ApiKeysTable({
  apiKeys,
  onAction,
  copyToClipboard,
  copied,
  isLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      key: 'name',
      name: t('DASHBOARD_API_NAME'),
      width: '25%',
      className: classes.tableHeader,
      render: ({ name }): React.ReactElement => (
        <Typography className={classes.tableFontSize}>{name}</Typography>
      ),
    },
    {
      key: 'identifier',
      name: t('DASHBOARD_API_IDENTIFIER_LABEL'),
      width: '18.75%',
      className: classes.tableHeader,
      render: ({ identifier }): React.ReactElement => (
        <div className={classes.identifierContainer}>
          <span className={classes.identifier}>
            <Typography className={classes.tableFontSize}>{maskIdentifier(identifier)}</Typography>
            <IconButton
              onClick={(): void => copyToClipboard(identifier)}
              data-testid="copy-container-button"
              className={classes.identifierCopyButton}
            >
              <CopyIcon />
            </IconButton>
          </span>
          {copied && identifier === copied && (
            <span className={classes.copiedText}>{t('DASHBOARD_API_KEY_IDENTIFIER_COPIED')}</span>
          )}
        </div>
      ),
    },
    {
      key: 'createdAt',
      name: t('DASHBOARD_BUSINESS_CREATED'),
      width: '10%',
      className: classes.tableHeader,
      render: ({ createdAt }): React.ReactElement => (
        <Typography className={classes.tableFontSize}>
          {formatDate(createdAt, 'D MMM YYYY h:mm:ss A')}
        </Typography>
      ),
    },
    {
      key: 'lastUsedAt',
      name: t('DASHBOARD_API_LASTUSED_LABEL'),
      width: '10%',
      className: classes.tableHeader,
      render: ({ lastUsedAt }): React.ReactElement => (
        <Typography className={classes.tableFontSize}>
          {lastUsedAt ? formatDate(lastUsedAt, 'D MMM YYYY h:mm:ss A') : '-'}
        </Typography>
      ),
    },

    {
      key: 'scopes',
      name: t('DASHBOARD_API_PERMISSIONS_LABEL'),
      width: '20%',
      className: classes.tableHeader,
      render: ({ scopes }): React.ReactElement | React.ReactElement[] => {
        if (!scopes?.length)
          return (
            <Trans
              className={classes.tableFontSize}
              i18nKey="DASHBOARD_API_PERMISSIONS_LABEL_UNSCOPED"
              components={{ bold: <strong /> }}
            />
          );

        return getScopeLabels(scopes).map(({ scopeLabel, permissionLabels }) => (
          <Typography className={classes.tableFontSize} key={scopeLabel}>
            <span className={classes.tableBold}>
              <Trans i18nKey={scopeLabel} components={{ bold: <strong /> }} />:
            </span>{' '}
            {permissionLabels.map((permissionLabel) => (
              <Trans
                key={`${scopeLabel}-${permissionLabel}`}
                className={classes.tableFontSize}
                i18nKey={permissionLabel}
                components={{ bold: <strong /> }}
              />
            ))}
          </Typography>
        ));
      },
    },
    {
      key: 'actions',
      name: t('DASHBOARD_DEVELOPERS_WEBHOOKS_CTA'),
      width: '10%',
      className: classes.tableHeader,
      render: (apiKey): React.ReactElement => (
        <RailzButton
          onButtonClick={(): void => onAction(ApiKeyActionTypes.DELETE, apiKey)}
          type="text warning"
          label={t('DASHBOARD_TEAM_DELETE_CTA')}
          data-testid="actions-container"
        >
          <span slot="prefix">
            <DeleteIcon fontSize="small" />
          </span>
        </RailzButton>
      ),
    },
  ];

  return (
    <BasicTable
      isPaperComponent={false}
      columns={columns}
      rows={apiKeys}
      stickyHeader={true}
      isLoading={isLoading}
      noData={t('DASHBOARD_API_EMPTY')}
    />
  );
}
