import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    tableFontSize: { fontSize: '0.875rem' },
    tableBold: { fontWeight: 600, color: theme.palette.common.black },
    tableHeader: {
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      letterSpacing: '0.06rem',
      color: theme.palette.grey[800],
    },
    identifierContainer: {
      padding: theme.spacing(1),
    },
    identifier: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',

      '& p': {
        fontVariantNumeric: 'tabular-nums',
        minWidth: '140px',
      },
    },
    identifierCopyButton: {
      marginLeft: theme.spacing(1),
      color: theme.palette.grey[700],
    },
    actionsContainer: {
      '& *': { display: 'inline', verticalAlign: 'middle' },
      color: theme.palette.error.main,
      cursor: 'pointer',
    },
    copiedText: {
      fontSize: '0.75rem',
      margin: 0,
    },
  }),
);
