import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    confirmationAlert: {
      margin: `${theme.spacing(3)}px 0px ${theme.spacing(2)}px`,
      padding: theme.spacing(2),
      backgroundColor: '#FDF7E7',

      '& .MuiAlert-icon': {
        color: '#F2A74C',
        marginRight: '18.94px',
        padding: '3px 0px 0px',
      },
      '& .MuiAlert-message': {
        color: theme.palette.grey[800],
        fontSize: '1rem',
        lineHeight: '1.5rem',
        padding: 0,
      },
    },
    confirmationLabel: {
      color: theme.palette.grey[800],
      margin: 0,
    },
    confirmationInput: {
      '& .MuiFormHelperText-root.Mui-error': {
        margin: 0,
      },
    },
    loading: {
      textAlign: 'center',
      padding: `${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(8)}px`,
      gap: theme.spacing(3),

      '& .MuiCircularProgress-root': {
        fontSize: '3rem',
        color: theme.palette.primary.main,
      },
    },
  }),
);
