import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes shimmer': {
      to: {
        backgroundPositionX: '-200%',
      },
    },

    container: {
      position: 'relative',
    },

    loading: {
      overflow: 'hidden',

      '& $backdrop': {
        opacity: 1,
        visiblity: 'visible',
        pointerEvents: 'auto',
        backgroundColor: theme.palette.grey[200],
      },

      '& $child': {
        opacity: 0,
        visibility: 'hidden',
        filter: 'blur(20px)',
        transition: 'all 0.8s ease-in-out',
      },
    },

    loaded: {
      '& $backdrop': {
        opacity: 0,
        visiblity: 'hidden',
        pointerEvents: 'none',
        transition: 'all 0.8s ease-in-out 1s',
      },
    },

    child: {},

    backdrop: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 'inherit',
      overflow: 'hidden',
    },

    shimmer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.grey[200],
      transition: 'all 0.8s ease-in-out',

      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.grey[200],
        backgroundImage: `linear-gradient(
          70deg,
          hsla(0, 0%, 0%, 0) 35%,
          hsla(0, 0%, 100%, 1) 45%,
          hsla(0, 0%, 0%, 0) 65%
        )`,
        backgroundSize: '200% 100%',
        animation: '$shimmer 1.8s infinite cubic-bezier(.8, 0.6, 0.4, 0)',
        transition: 'all 0.8s ease-in-out',
      },
    },
  }),
);
