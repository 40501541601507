import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';

import { AUTH_STORE_NAME } from './auth.state';
import { authReducer } from './auth.reducer';

const authPersistConfig = {
  key: AUTH_STORE_NAME,
  storage,
  blacklist: ['email', 'user', 'error', 'loading', 'response'],
};

export const authReducerConfig = {
  [AUTH_STORE_NAME]: persistReducer(authPersistConfig, authReducer),
};
