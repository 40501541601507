import { ReportParam } from 'store/features/report/report.state';
import { PaginationProps } from 'types';

// TODO: Split file
export enum TableHeaderKey {
  _name = 'REPORT_TABLE_HEADER',
  ACCOUNT = 'account',
  ACCOUNT_ID = 'accountId',
  ACCOUNT_CODE = 'nominalCode',
  ACCURACY_SCORE = 'accuracyScore',
  ACCOUNTING_TRANSACTIONS_COUNT = 'accountingTransactionsCount',
  ACCOUNTING_TRANSACTIONS_AMOUNT = 'accountingTransactionsAmount',
  ACCOUNTING_TRANSACTIONS_TOTAL_VALUE = 'accountingTransactionsTotalValue',
  ACCOUNTING_TRANSACTIONS_TYPE = 'accountingTransactionType',
  BANK_TRANSACTIONS_COUNT = 'bankTransactionsCount',
  BANK_TRANSACTIONS_AMOUNT = 'bankTransactionsAmount',
  BANK_TRANSACTIONS_UNRECONCILED_COUNT = 'bankTransactionsUnreconciledCount',
  BANK_TRANSACTIONS_UNRECONCILED_AMOUNT = 'bankTransactionsUnreconciledAmount',
  BANK_TRANSACTIONS_TOTAL_VALUE = 'bankTransactionsTotalValue',
  DAILY_ACCURACY_SCORE = 'dailyAccuracyScore',
  WEIGHTED_PERCENTAGE_ERROR = 'weightedPercentageError',
  CATEGORIES = 'categories',
  CODE = 'code',
  CREDIT = 'credit',
  CURRENT = 'current',
  DATE = 'date',
  DEBIT = 'debit',
  EFFECTIVE_TAX_RATE = 'effectiveTaxRate',
  END_DATE = 'endDate',
  GROUP = 'group',
  NAME = 'name',
  SECTION = 'section',
  SUB_ACCOUNT = 'subAccount',
  SUB_GROUP = 'subGroup',
  SUB_SECTION = 'subSection',
  SUB_TOTAL = 'subTotal',
  SERVICE_NAME = 'serviceName',
  TAX = 'tax',
  TAX_AMOUNT = 'taxAmount',
  TOTAL = 'total',
  TOTAL_AMOUNT = 'totalAmount',
  VALUE = 'value',
  VALUATION_TYPE = 'valuationType',
  BUSINESS_ATTRIBUTE = 'businessAttribute',
  YTD_DEBIT = 'ytdCredit',
  YTD_CREDIT = 'ytdDebit',
  REMAINING_CREDIT = 'remainingCredit',
  ID = 'id',
  IDENTIFIER = 'identifier',
  ORDER_IDENTIFIER = 'orderIdentifier',
  INSTITUTION = 'institutionName',
  BANK = 'bank',
  EMPLOYEE_NAME = 'employeeName',
  HIRED_DATE = 'hiredDate',
  RELEASED_DATE = 'releasedDate',
  INVOICE_NUMBER = 'invoiceNumber',
  CUSTOMER_REF = 'customerRef',
  CUSTOMER_NAME = 'customerName',
  CUSTOMER = 'customer',
  POSTED_DATE = 'postedDate',
  EXPIRY_DATE = 'expiryDate',
  ALLOCATED_DATE = 'allocatedDate',
  DUE_DATE = 'dueDate',
  UPDATED_AT = 'updatedAt',
  PAYMENT_DATE = 'paymentDate',
  CURRENCY = 'currency',
  CURRENCY_REF = 'currencyRef',
  STATUS = 'status',
  PAYMENT_METHODS_STATUS = 'paymentMethodsStatus',
  INVENTORY_STATUS = 'itemStatus',
  INVOICE_AMOUNT = 'totalAmount',
  AMOUNT_DUE = 'amountDue',
  VENDOR_CUSTOMER_NAME = 'vendorCustomerName',
  VENDOR_REF = 'vendorRef',
  ENTITY_REF = 'entityRef',
  ACCOUNT_REF = 'accountRef',
  PARENT_REF = 'parentRef',
  PARENT_REFERENCE_ID = 'parentReferenceId',
  PARENT_REFERENCE_NAME = 'parentReferenceName',
  PARENT_TRANSACTION_ID = 'parentTransactionId',
  VENDOR_NAME = 'vendorName',
  CONTACT_NAME = 'contactName',
  EMAIL_ADDRESS = 'emailAddress',
  PHONE = 'phone',
  TAX_NUMBER = 'taxNumber',
  TOTAL_TAX_RATE = 'totalTaxRate',
  ACCOUNT_NAME = 'accountName',
  ENTITY_NAME = 'entityName',
  BILL_NUMBER = 'billNumber',
  BALANCE = 'balance',
  CURRENT_BALANCE = 'currentBalance',
  LIMIT = 'limit',
  BILL_AMOUNT = 'totalAmount',
  CREDIT_NOTE_AMOUNT = 'creditNoteAmount',
  AMOUNT = 'totalAmount',
  AMOUNT_VALUE = 'amount',
  TYPE = 'type',
  ACCOUNT_TYPE = 'accountType',
  ACCOUNT_NUMBER = 'accountNumber',
  INSTITUTION_NAME_AND_BANK_CODE = 'institutionNameAndBankCode',
  DESCRIPTION = 'description',
  SUB_TYPE = 'subType',
  ACCOUNT_SUB_TYPE = 'accountSubType',
  ACTIVE = 'isActive',
  BANK_ACCOUNT = 'bankAccount',
  BANK_ACCOUNT_REF = 'bankAccountRef',
  IS_BANK_ACCOUNT = 'isBankAccount',
  BUSINESS_NAME = 'businessName',
  BUSINESS_REGISTRATION_NUMBER = 'businessRegistrationNumber',
  MAILING_ADDRESS = 'mailingAddress',
  LEGAL_NAME = 'legalName',
  LEGAL_ADDRESS = 'legalAddress',
  BUSINESS_EMAIL = 'businessEmail',
  BUSINESS_TYPE = 'businessType',
  WEBSITE = 'website',
  PRIMARY_PHONE_NUMBER = 'primaryPhoneNumber',
  FISCAL_YEAR_END_DAY = 'fiscalYearEndDay',
  FISCAL_YEAR_END_MONTH = 'fiscalYearEndMonth',
  INDUSTRY_TYPE = 'industryType',
  INDUSTRY_CODE = 'industryCode',
  BASE_CURRENCY = 'baseCurrency',
  ACCOUNTING_METHOD = 'accountingMethod',
  RATIO = 'ratio',
  RATIO_TYPE = 'ratioType',
  RATIO_VALUE = 'ratioValue',
  LIQUIDATION_VALUE = 'liquidationValue',
  MODEL = 'model',
  DISCOUNTED_CASHFLOW_VALUE = 'discountedCashflowValue',
  DISCOUNT_RATE = 'discountRate',
  MULTIPLE_TO_REVENUE_VALUE = 'multipleToRevenueValue',
  REVENUE_MULTIPLE = 'revenueMultiple',
  FIRST_CHICAGO_VALUE = 'firstChicagoValue',
  TRANSACTION_ID = 'id',
  TRANSACTION_TYPE = 'transactionType',
  FROM_ACCOUNT = 'fromAccountRef',
  TO_ACCOUNT = 'toAccountRef',
  DPD_30 = 'dpd30',
  DPD_60 = 'dpd60',
  DPD_90 = 'dpd90',
  DPD_90_PLUS = 'dpd90+',
  TOTAL_PAST_DUE = 'totalPastDue',
  SP_RATING = 'spRating',
  MOODYS_RATING = 'moodysRating',
  FITCH_RATING = 'fitchRating',
  DBRS_RATING = 'dbrsRating',
  SCORE_PIT = 'scorePit',
  RAILZ_RATING = 'railzRating',
  CREATED_DATE = 'createdDate',
  UPLOAD_DATE = 'uploadDate',
  SOURCE_MODIFIED_DATE = 'sourceModifiedDate',
  CONTENT_TYPE = 'contentType',
  FILE_NAME = 'fileName',
  PRODUCT_IDENTIFIER = 'productIdentifier',
  CATEGORY = 'category',
  DISPUTE_IDENTIFIER = 'disputeIdentifier',
  ORDER_NO = 'orderNo',
  ORDER_REF = 'orderRef',
  DISPUTE_REASON = 'disputeReason',
  REASON = 'reason',
  PAYMENT_METHOD_TYPE = 'paymentMethodType',
  PAYMENT_METHOD_REF = 'paymentMethodRef',
  PAYMENT_METHOD = 'paymentMethod',
  SYMBOL = 'symbol',
  TOTAL_ORDER_AMOUNT = 'totalAmount',
  ORDER_STATUS = 'status',
  PAYMENT_STATUS = 'paymentStatus',
  FULFILMENT_STATUS = 'fulfillmentStatus',
  MATCHED = 'matched',
  FILE_SIZE = 'fileSize',
  AVAILABLE_BALANCE = 'availableBalance',
  STATE = 'state',
  TOTAL_PAID = 'totalPaid',
  PAYEE_BANK_ACCOUNT = 'payeeBankAccount',
}

export enum DataType {
  DATE = 'date',
  NUMBER = 'number',
  NUMBER_WITH_0_FALLBACK = 'numberWithNAFallback',
  NUMBER_YTD_0 = 'numberYtd0',
  NUMBER_YTD = 'numberYtd',
  BOOLEAN = 'boolean',
  STATUS = 'status',
  STRING_WITH_DASH_FALLBACK = 'stringWithDashFallback',
  CAPITALIZED_STRING = 'capitalizedString',
  ACC_TRANSACTION_TYPE = 'ACC_TRANSACTION_TYPE',
  COMMERCE_TRANSACTION_TYPE = 'COMMERCE_TRANSACTION_TYPE',
  COMMERCE_TRANSACTION_STATUS = 'COMMERCE_TRANSACTION_STATUS',
  COMMERCE_ORDER_STATUS = 'COMMERCE_ORDER_STATUS',
  COMMERCE_ORDER_PAYMENT_STATUS = 'COMMERCE_ORDER_PAYMENT_STATUS',
  COMMERCE_ORDER_FULFILMENT_STATUS = 'COMMERCE_ORDER_FULFILMENT_STATUS',
  COMMERCE_PAYMENT_METHOD = 'COMMERCE_PAYMENT_METHOD',
  ARRAY = 'array',
  PERCENTAGE = 'percentage',
  PERCENTAGE_OVER_100 = 'percentageOver100',
  CURRENCY = 'currency',
  PRODUCT_STATUS = 'PRODUCT_STATUS',
  DISPUTE_STATUS = 'DISPUTE_STATUS',
  DISPUTE_REASON = 'DISPUTE_REASON',
  PAYMENT_METHODS_STATUS = 'paymentMethodsStatus',
  SOURCE_MODIFIED_DATE = 'sourceModifiedDate',
  FILE_SIZE = 'fileSize',
  CONTENT_TYPE = 'contentType',
  FILE_NAME = 'fileName',
  UPLOAD_DATE = 'uploadDate',
}

export enum ActiveStatus {
  _name = 'REPORT_TABLE_ACTIVE_STATUS',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export interface ReportMeta {
  reportId?: string;
  startDate?: Date;
  endDate?: Date;
  serviceName?: string;
  businessName?: string;
  reportFrequency?: string;
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ReportApiParam {
  connectionUuid: string;
  startDate?: string;
  endDate?: string;
  reportFrequency?: string;
  accountingMethod?: string;
  status?: string;
  financialStatementType?: string;
  offset?: string;
  orderBy?: string;
}

export interface BusinessInformation {
  businessName: string;
  legalName: string;
  mailingAddress: Address;
  fiscalYearEndMonth: number;
  fiscalYearEndDay: number;
  primaryPhoneNumber: string;
  businessType: string;
  baseCurrency: string;
  accountingMethod: string;
}

export interface TableBusinessInfoCell {
  businessAttribute: string;
  value: string;
}

export interface TransactionsInformation {
  tax: number;
  subTotal: number;
  data: any;
  meta: any;
}

interface BusinessValuationData {
  discountRate: number;
  discountedCashflowValue: number;
  firstChicagoValue: number;
  liquidationValue: number;
  multipleToRevenueValue: number;
  revenueMultiple: number;
}

interface BusinessValuationMeta {
  businessName: string;
  createdAt: string;
  endDate: string;
  reportId: string;
  serviceName: string;
  startDate: string;
  updatedAt: string;
}

export interface BusinessValuationParsed {
  endDate: Date;
  liquidationValue: string;
  discountedCashflowValue: string;
  discountRate: string;
  multipleToRevenueValue: string;
  revenueMultiple: string;
  firstChicagoValue: string;
}

export interface BusinessValuation {
  data: BusinessValuationData;
  meta: BusinessValuationMeta;
}

export interface TrackingCategory {
  id: string;
  name: string;
  status: string;
}

export interface MultipleLinesInformation {
  id: string;
  postedDate: string;
  lines: LineInformation[];
}

export interface LineInformation {
  credit: number | null;
  debit: number | null;
  totalAmount: number | null;
}

export interface TableMultipleLinesInfoCell {
  id: number;
  totalAmount: number;
}

export interface TableRatioCell {
  ratioType: string;
  ratio: string;
  value: string;
  originalValue: string;
}

export interface ReportType {
  name: string;
  value: string;
}

export interface ReportFrequency {
  name: string;
  value: string;
}

export interface Service {
  businessId: string;
  connectionId: string;
  createdAt: string;
  firstRecordDate: string;
  serviceName: string;
  status: string;
}

export interface Business {
  businessId: string;
  businessName: string;
  createdAt: string;
  status: string;
  updatedAt: string;
}

export enum FinancialRatioTypeEnum {
  _name = 'REPORT_FINANCIAL_RATIO_TYPE',
  CREDIT = 'credit',
  EFFICIENCY = 'efficiency',
  LEVERAGE = 'leverage',
  LIQUIDITY = 'liquidity',
  MARKET = 'market',
  PROFITABILITY = 'profitability',
  RELIABILITY = 'reliability',
}

export enum FinancialRatio {
  _name = 'REPORT_FINANCIAL_RATIO',
  ANNUAL_RECURRING_REVENUE_RATE = 'annualRecurringRevenueRate',
  ANNUAL_RECURRING_REVENUE = 'annualRecurringRevenue',
  CASHFLOW_COVERAGE_RATIO = 'cashFlowCoverageRatio',
  LEVERAGE_RATIO = 'leverageRatio',
  CHURN_RATE = 'churnRate',
  MONTHLY_RECURRING_REVENUE = 'monthlyRecurringRevenue',
  MONTHLY_RECURRING_REVENUE_RATE = 'monthlyRecurringRevenueRate',
  DISTANCE_TO_DEFAULT = 'distanceToDefault',
  PROBABILITY_OF_DEFAULT = 'probabilityOfDefault',
  LIQUIDATION_VALUE = 'liquidationValue',
  GROSS_BURN_RATE = 'grossBurnRate',
  INVENTORY_TURNOVER_RATIO = 'inventoryTurnoverRatio',
  RECEIVABLES_TURNOVER_RATIO = 'receivablesTurnoverRatio',
  ASSET_TURNOVER_RATIO = 'assetTurnoverRatio',
  EQUITY_TO_LT_ASSETS = 'equityToLTAssets',
  SHORT_DEBT_TO_EQUITY_RATIO = 'shortDebtToEquityRatio',
  DEBT_TO_EQUITY_RATIO = 'debtToEquityRatio',
  INTEREST_BANK_LOAN = 'interestBankLoan',
  INTEREST_COVERAGE_RATIO = 'interestCoverageRatio',
  DEBT_TO_ASSETS_RATIO = 'debtToAssetsRatio',
  DEBT_TO_SERVICE_COVERAGE_RATIO = 'debtServiceCoverageRatio',
  QUICK_RATIO = 'quickRatio',
  ABSOLUTE_LIQUIDITY = 'absoluteLiquidity',
  CURRENT_RATIO = 'currentRatio',
  GROSS_BURN = 'grossBurn',
  RUNWAY = 'runway',
  CASH_RATIO = 'cashRatio',
  FREE_CASHFLOW_RATIO = 'freeCashflowRatio',
  DEBT_TO_ENTERPRISE_VALUE = 'debtToEnterpriseValue',
  ENTERPRISE_VALUE = 'enterpriseValue',
  RETURN_ON_ASSETS = 'returnOnAssets',
  EBITDA = 'ebitda',
  EBITDA_MARGIN = 'ebitdaMargin',
  WORKING_CAPITAL = 'workingCapital',
  FREE_CASHFLOW = 'freeCashFlow',
  GROSS_MARGIN = 'grossMargin',
  NET_PROFIT_MARGIN = 'netProfitMargin',
  OPERATING_MARGIN = 'operatingMargin',
  RETURN_ON_EQUITY = 'returnOnEquity',
  REVENUE_CONCENTRATION_INDEX = 'revenueConcentrationIndex',
  AVERAGE_COLLECTION_PERIOD = 'averageCollectionPeriod',
  PAYABLES_CONVERSION_PERIOD = 'payablesConversionPeriod',
  LEVERAGE_INDEX = 'leverageIndex',
  TOTAL_ACCRUALS_TOTAL_ASSETS = 'totalAccrualsTotalAssets',
  GROSS_MARGIN_INDEX = 'grossMarginIndex',
  SALES_MARGIN_INDEX = 'salesGrowthIndex',
  SGA_EXPENSES_INDEX = 'sgaExpensesIndex',
  DAYS_SALES_RECEIVABLES_INDEX = 'daysSalesReceivablesIndex',
  ASSET_QUALITY_INDEX = 'assetQualityIndex',
  DEPRECIATION_INDEX = 'depreciationIndex',
  ACCOUNTS_RECEIVABLE_TUENOVER_RATIO = 'accountsReceivableTurnoverRatio',
  ACCOUNTS_PAYABLE_TUENOVER_RATIO = 'accountsPayableTurnoverRatio',
  DAYS_PAYABLE_OUTSTANDING = 'daysPayableOutstanding',
  DAYS_SALES_OUTSTANDING = 'daysSalesOutstanding',
  AVERAGE_OUTSTANDING_PAYABLES_BALANCE = 'averageOutstandingPayablesBalance',
  AVERAGE_OUTSTANDING_RECEIVABLES_BALANCE = 'averageOutstandingReceivablesBalance',
}

export interface Credit {
  distanceToDefault: number;
  probabilityOfDefault: number;
  liquidationValue: number;
}

export interface Efficiency {
  grossBurnRate: number;
  inventoryTurnoverRatio: number;
  receivablesTurnoverRatio: number;
  assetTurnoverRatio: number;
  payablesConversionPeriod: number;
  accountsReceivableTurnoverRatio: number;
  accountsPayableTurnoverRatio: number;
  daysPayableOutstanding: number;
  daysSalesOutstanding: number;
  averageOutstandingPayablesBalance: number;
  averageOutstandingReceivablesBalance: number;
}

export interface Leverage {
  equityToLTAssets: number;
  shortDebtToEquityRatio: number;
  debtToEquityRatio: number;
  interestBankLoan: number;
  interestCoverageRatio: number;
  debtToAssetsRatio: number;
  debtServiceCoverageRatio: number;
}

export interface Liquidity {
  quickRatio: number;
  absoluteLiquidity: number;
  currentRatio: number;
  grossBurn: number;
  runway: number;
  cashRatio: number;
  freeCashflowRatio: number;
}

export interface Market {
  debtToEnterpriseValue: number;
  enterpriseValue: number;
}

export interface Profitability {
  returnOnAssets: number;
  ebitda: number;
  ebitdaMargin: number;
  workingCapital: number;
  freeCashFlow: number;
  grossMargin: number;
  netProfitMargin: number;
  operatingMargin: number;
  returnOnEquity: number;
  revenueConcentrationIndex: number;
}

export interface FinancialRatioType {
  credit: Credit;
  efficiency: Efficiency;
  leverage: Leverage;
  liquidity: Liquidity;
  market: Market;
  profitability: Profitability;
}

export interface FinancialRatioInfo {
  data: FinancialRatioType;
  meta: ReportMeta;
}

export interface DefaultCurrency {
  id: string;
  currencyName: string;
}

export interface Address {
  streetNumber?: string;
  streetName?: string;
  state?: string;
  type?: string;
  line1?: string;
  line2?: string;
  zip?: string;
  city?: string;
  region?: string;
  country?: string;
  postalCode?: string;
}

export interface Phone {
  type: string;
  number: string;
}

export interface Contact {
  name: string;
  email: string;
  phone: Phone[];
  address: Address;
  status: string;
  modifiedDate: Date;
}

export interface CustomerVendorDto {
  id: string;
  customerName: string;
  contactName: string;
  emailAddress: string;
  website: string;
  defaultCurrency: DefaultCurrency;
  phone: string;
  addresses: Address[];
  contacts: Contact[];
  taxNumber: string;
  balance: number;
  status: string;
  sourceModifiedDate: Date;
}

export interface ProbabilityOfDefault {
  data: { [key: string]: number };
  meta: BusinessValuationMeta;
}

export interface Credits {
  count: number;
  reports: CreditsInfo[];
}

export interface ReconciledBankReports {
  count: number;
  reports: ReconciledBankReport[];
}

export interface ReconciledBankReport {
  data: any[];
  meta: CreditsInfoMeta;
}
interface CreditsInfoMeta {
  businessName: string;
  createdAt: string;
  endDate: string;
  reportId: string;
  serviceName: string;
  startDate: string;
  updatedAt: string;
}

export interface CreditsDataInfo {
  dbrsRating: string;
  description: string;
  fitchRating: string;
  moodysRating: string;
  railzGrade: string;
  railzRating: string;
  score12m: number;
  score24m: number;
  score36m: number;
  scorePit: number;
  spRating: string;
}

export interface CreditsInfo {
  data: CreditsDataInfo;
  meta: CreditsInfoMeta;
}

export interface PaginationData {
  pagination: PaginationProps;
  requestParams: ReportParam;
}
