import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    backDrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      flex: 1,
    },
    alignment: {
      display: 'grid',
      alignContent: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    text: {
      textAlign: 'center',
      display: 'flex',
      fontSize: 12,
      color: theme.palette.grey['600'],
      marginTop: 16,
    },
    textView: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '21px',
    },
  }),
);
