import React, { useEffect, useState } from 'react';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import { Box, Button, Typography } from '@material-ui/core';
import Moment, { Moment as MomentType } from 'moment-timezone';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { ReportFrequency } from '../../types';

import useStyles from './style';

interface Props {
  date: MomentType;
  isLandscape: boolean;
  goForward?: (date: MomentType) => void;
  goBack?: (date: MomentType) => void;
  minDate?: Date;
  maxDate?: Date;
}

const CustomToolbar = function ({
  date,
  isLandscape,
  goForward,
  goBack,
  minDate,
  maxDate = Moment().toDate(),
}: Props): JSX.Element {
  const classes = useStyles();
  // we need to update state to force a rerender. Material ui does not rerender this until
  // the dialog is closed otherwise
  const [, setDateLabel] = useState(date.format('YYYY'));
  const [isFutureDisabled, setIsFutureDisabled] = useState(false);
  const [isPastDisabled, setIsPastDisabled] = useState(false);

  useEffect(() => {
    if (date.year() + 1 > maxDate.getFullYear()) {
      setIsFutureDisabled(true);
    }
    if (minDate && Moment(minDate).year() === date.year()) {
      setIsPastDisabled(true);
    }
    return (): void => {
      setDateLabel(null);
      setIsFutureDisabled(false);
      setIsPastDisabled(false);
    };
  }, [date, minDate]);

  const handleforward = (): void => {
    const newDate = date.add(1, ReportFrequency.YEAR);
    goForward(newDate);
    setDateLabel(newDate.format('YYYY'));
  };

  const handleBack = (): void => {
    const newDate = date.subtract(1, ReportFrequency.YEAR);
    goBack(newDate);
    setDateLabel(newDate.format('YYYY'));
  };

  return (
    <PickerToolbar className={classes.toolbar} title={''} isLandscape={isLandscape}>
      <Box className={classes.toolboxContainer}>
        <Button
          className={classes.toolbarButton}
          endIcon={<ArrowBackIosIcon />}
          disabled={isPastDisabled}
          onClick={handleBack}
          data-testid="datepicker-back"
        />
        <Typography variant="h6" className={classes.date}>
          {date.format('YYYY')}
        </Typography>
        <Button
          disabled={isFutureDisabled}
          onClick={handleforward}
          data-testid="datepicker-forward"
          className={classes.toolbarButton}
          endIcon={<ArrowForwardIosIcon />}
        />
      </Box>
    </PickerToolbar>
  );
};

export default CustomToolbar;
