import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(3),
    },
    closeButtonDiv: {
      display: 'flex',
      justifyContent: 'end',
    },
    closeButton: {
      color: theme.palette.grey[800],
      padding: 0,
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: theme.typography.fontWeightBold as number,
      marginTop: 0,
      padding: 0,
      color: theme.palette.grey[900],
      lineHeight: '1.75rem',
    },
    content: {
      padding: theme.spacing(3),
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(3),
      alignItems: 'stretch',
      gap: theme.spacing(1),
      '& button': {
        height: '100%',
        paddingLeft: `${theme.spacing(2)}px !important`,
        paddingRight: `${theme.spacing(2)}px !important`,
      },
    },

    noMarginNoPadding: {
      margin: 0,
      padding: 0,
      color: theme.palette.grey[800],
    },
    dialogContent: { overflow: 'visible' },
    description: {
      color: theme.palette.grey[800],
    },

    error: {
      '& button': {
        backgroundColor: `${theme.palette.error.main} !important`,
      },
      '& button:hover': {
        backgroundColor: `${theme.palette.error.dark} !important`,
      },
      '& button:focus': {
        backgroundColor: `${theme.palette.error.dark} !important`,
      },
      '& button:focus-visible::before': {
        boxShadow: `inset 0 0 0 2px ${theme.palette.error.dark}, inset 0 0 0 4px white !important`,
      },
    },

    gray: {
      '& button': {
        color: `${theme.palette.grey[800]} !important`,
        backgroundColor: `${theme.palette.grey[100]} !important`,
      },
      '& button:hover': {
        color: `${theme.palette.grey[800]} !important`,
        backgroundColor: `${theme.palette.grey[300]} !important`,
      },
      '& button:focus': {
        color: `${theme.palette.grey[800]} !important`,
        backgroundColor: `${theme.palette.grey[300]} !important`,
      },
      '& button:focus-visible::before': {
        boxShadow: `inset 0 0 0 2px ${theme.palette.grey[600]}, inset 0 0 0 4px white !important`,
      },
    },
  }),
);
