import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from '../../../providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    saveButton: {
      marginTop: theme.spacing() * 2,
    },

    countrySection: {
      marginTop: theme.spacing(2),
    },
    countryLabel: {
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[800],
    },
    countrySelect: {
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    kind: {
      marginTop: theme.spacing(1),
      '& .MuiOutlinedInput-input': {
        padding: '0.625rem 0.875rem',
      },
    },
    service: {
      paddingLeft: theme.spacing(3),
    },
    serviceName: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(4),
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: '1.25rem',
      color: theme.palette.grey[900],
    },
    keysHeader: {
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '1rem',
      color: theme.palette.grey[900],
    },
    permissionTitle: {
      marginTop: theme.spacing(3),
    },
    permissionSubTitle: {
      color: theme.palette.grey[800],
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    confirmationAlert: {
      margin: `${theme.spacing(2)}px 0px`,
      padding: theme.spacing(2),
      backgroundColor: '#FDF7E7',

      '& .MuiAlert-icon': {
        color: '#F2A74C',
        marginRight: '18.94px',
        padding: '3px 0px 0px',
      },
      '& .MuiAlert-message': {
        color: theme.palette.grey[800],
        fontSize: '1rem',
        lineHeight: '1.5rem',
        padding: 0,
      },
    },
    confirmationLabel: {
      color: theme.palette.grey[800],
      margin: 0,
    },
    confirmationInput: {
      '& .MuiFormHelperText-root.Mui-error': {
        margin: 0,
      },
    },
    permissionActiveLink: {
      color: ACTIVE_TEXT_COLOR,
      marginLeft: theme.spacing(1),
    },
    permissionInActiveLink: {
      color: theme.palette.grey[800],
      marginLeft: theme.spacing(1),
    },
    linkLearnMore: {
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    multipleToggles: {
      marginBottom: theme.spacing(2),
    },
  }),
);
