import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { Chip } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { RailzButton } from '@railzai/railz-uikit-react';

import Accordion from '../../components/accordion/accordion';

import style from './style';

import { FormSelect } from 'components/form';

import {
  resetSyncConfigsApiResponse,
  updateSyncConfigsApi,
} from 'store/features/integration/integration.action';
import {
  getSyncConfigs,
  isIntegrationLoading,
} from 'store/features/integration/integration.selector';
import { showSnackbar } from 'helpers/common.helper';
import LoadingShimmer from 'components/loading-shimmer';
import i18n from 'translations';

export enum DATA_SYNC_FREQUENCY {
  NO_SYNC = 'noSync',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BI_WEEKLY = 'biWeekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

const SYNC_FREQUENCY_OPTIONS = Object.values(DATA_SYNC_FREQUENCY).map((option) => {
  return {
    name: i18n.t(`DASHBOARD_DATA_FREQUENCY_SYNC_${option.toUpperCase()}`),
    value: option,
  };
});

export default function DataSyncFrequency(): JSX.Element {
  const classes = style();
  const { dataSyncFrequency } = useSelector(getSyncConfigs);

  const isLoading = useSelector(isIntegrationLoading);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      dataSyncFrequency: dataSyncFrequency || DATA_SYNC_FREQUENCY.DAILY,
    },
  });

  const onSubmit = (values: { dataSyncFrequency: string }): void => {
    if (dataSyncFrequency === values.dataSyncFrequency) {
      showSnackbar({
        message: t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_TOAST_NONE'),
        type: 'success',
      });
    } else {
      dispatch(updateSyncConfigsApi({ dataSyncFrequency: values.dataSyncFrequency }));
    }
  };

  useEffect(() => {
    setValue('dataSyncFrequency', dataSyncFrequency);
  }, [dataSyncFrequency]);

  useEffect(() => {
    return () => {
      dispatch(resetSyncConfigsApiResponse());
    };
  }, []);

  return (
    <Accordion
      className=""
      header={
        <span className={classes.header}>
          <Typography variant="h3" className={classes.title}>
            {t('DASHBOARD_DATA_SYNC_FREQUENCY_TITLE')}
          </Typography>
          <LoadingShimmer loading={isLoading} borderRadius="80px">
            <Chip
              className={classes.chip}
              data-testid={`data-sync-freq-chip`}
              label={t(`DASHBOARD_DATA_FREQUENCY_SYNC_${dataSyncFrequency.toUpperCase()}`)}
              size="small"
            />
          </LoadingShimmer>
        </span>
      }
      showAccordion={true}
      children={
        <>
          <Typography className={classes.descriptionYears}>
            {t('DASHBOARD_DATA_SYNC_FREQUENCY_DESCRIPTION')}
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormSelect
              name="dataSyncFrequency"
              label="DASHBOARD_DATA_SYNC_FREQUENCY_LABEL"
              placeholder="DASHBOARD_DATA_SYNC_FREQUENCY_LABEL"
              options={SYNC_FREQUENCY_OPTIONS}
              errorobj={errors}
              control={control}
              className={classes.yearsInput}
              testid="data-sync-freq-input"
            />
            <div className={classes.ctaYears}>
              <RailzButton
                type="primary"
                data-testid="save-button-data-sync-freq"
                label={t('DASHBOARD_SAVE_CHANGES_CTA')}
                buttonType="submit"
              />
            </div>
          </form>
        </>
      }
    />
  );
}
