import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    loginContainer: {
      margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`,
      boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
      paddingBottom: theme.spacing(4),
    },
    container: {
      margin: theme.spacing(3),
      boxShadow: 'none',
    },
    toggleDisabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    textPlanUpdate: {
      color: ACTIVE_TEXT_COLOR,
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    iconPlanUpdate: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    linkPlanUpdate: {
      textDecoration: 'none',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
    gridContainer: {
      display: 'flex',
    },
    mainTitle: {
      fontWeight: 600,
      fontSize: '1,25rem',
      lineHeight: '2rem',
    },
    containerGrid: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    linkLearnMore: {
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    description: {
      marginTop: theme.spacing(0.75),
      width: '70%',
    },
    checkboxContainer: {
      marginRight: theme.spacing(2),
      marginLeft: 'auto',
    },
    containerAction: {
      marginTop: theme.spacing(4),
    },
    toggleTitle: {
      marginTop: theme.spacing(1),
    },
    autoDeleteSelect: {
      marginTop: theme.spacing(3),
      minWidth: 240,
      minHeight: theme.spacing(5),
      '& .MuiSelect-select': {
        fontSize: '0.875rem',
      },
    },
    menu: {
      marginTop: theme.spacing(),

      '& ul': {
        maxHeight: 240,
        '& .MuiListItem-root': {
          fontSize: '0.875rem',
          fontWeight: 400,
        },
      },
    },
    menuItem: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.common.white,
        color: ACTIVE_TEXT_COLOR,
        fontWeight: '600 !important',
      },
    },

    buttonContainer: {
      display: 'flex',
      marginTop: 'auto',
      backgroundColor: '#ffffff',
      zIndex: 1,
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      boxShadow: `0px -4px 4px rgba(0, 0, 0, 0.1)`,
    },
    saveCta: {
      paddingRight: theme.spacing(1),
    },
    discardCta: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#F9F9F9',
      '& span': {
        color: '#424242',
        fontSize: '0.875rem',
      },
    },
  }),
);
