import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
      hyphens: 'auto',
    },

    // Logo
    logoContainer: {
      maxWidth: '80%',
      marginBottom: 'clamp(1em, 5vh, 2em)',
      minHeight: '2em',
      minWidth: '6em',
    },

    logoContainerEmpty: {
      maxWidth: '60%',
      marginBottom: 'clamp(1em, 5vh, 2em)',
      minHeight: '2em',
      minWidth: '6em',
    },

    logo: {
      maxWidth: '100%',
      maxHeight: '25vh',
      objectFit: 'contain',
      objectPosition: 'top left',
      display: 'block',
    },

    emptyLogo: {
      maxWidth: '100%',
      aspectRatio: '1/1',
      display: 'grid',
      placeContent: 'center',
      position: 'relative',
      opacity: 0.5,
      fontSize: '.875em',

      '&:after': {
        content: `""`,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: '2px dashed currentColor',
        borderRadius: '10px',
      },
    },

    // Content
    heading: {
      fontWeight: 600,
      marginBottom: '.5em',
      fontSize: '1.25em',
    },

    description: {
      fontWeight: 400,
      fontSize: '0.875em',
    },

    footer: {
      alignSelf: 'flexEnd',
      opacity: 0.7,
      fontSize: '0.75em',
    },
  }),
);
