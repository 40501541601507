import { createSelector } from 'reselect';

import { AllState } from '../../../state';

import { DEVELOPER_STORE_NAME } from '../developer.state';

import { API_KEY_STORE_NAME, ApiKeyState } from './api-key.state';

/** Select API Key State */
export const getApiKeyState = (state: AllState): ApiKeyState =>
  state[DEVELOPER_STORE_NAME][API_KEY_STORE_NAME];

export const getApiKeys = createSelector(getApiKeyState, (apiKey: ApiKeyState) => {
  if (apiKey && !apiKey.loading) {
    return apiKey.apiKeys;
  } else {
    return [];
  }
});
export const getApiKeyError = createSelector(getApiKeyState, (apiKey: ApiKeyState) => apiKey.error);
export const getApiKeyResponse = createSelector(
  getApiKeyState,
  (apiKey: ApiKeyState) => apiKey.response,
);
