import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import React from 'react';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { foundation } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Typography from '@material-ui/core/Typography';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from './style';

import { setClipboard } from 'helpers/common.helper';

export interface ConnectCodeProps {
  code: string;
  identifier: string;
  header3?: string;
  header4?: string;
  description?: string;
  language?: string;
  setCopied?: (key: string) => void;
}

const ConnectCode: React.FC<ConnectCodeProps> = ({
  code,
  header3,
  header4,
  description,
  identifier,
  setCopied,
  language,
}: ConnectCodeProps) => {
  const { t } = useTranslation();

  const classes = useStyle();

  const copyCodeInfo = (): void => {
    setClipboard(code);
    setCopied(identifier);
  };

  return (
    <>
      {header3 && (
        <Typography variant="h3" className={classes.subHeader}>
          {t(header3)}
        </Typography>
      )}
      {description && (
        <Typography paragraph={true} className={classes.paragraph}>
          {t(description)}
        </Typography>
      )}
      {header4 && (
        <Typography variant="h4" className={classes.subHeaderH4}>
          {t(header4)}
        </Typography>
      )}
      {language !== 'plaintext' ? (
        <SyntaxHighlighter
          language={language}
          className={classes.code}
          data-testid={identifier + '-code'}
          style={foundation}
          wrapLines
          wrapLongLines
        >
          {code}
        </SyntaxHighlighter>
      ) : (
        <div
          className={`${classes.code} ${classes.plainTextCode}`}
          data-testid={identifier + '-plaintextcode'}
        >
          <pre>{code}</pre>
        </div>
      )}
      <Box>
        <RailzButton
          aria-label={t('DASHBOARD_RAILZ_CONNECT_COPY_ARIA_LABEL')}
          size="large"
          type="text primary"
          onClick={copyCodeInfo}
          data-testid={identifier + '-submit'}
          label={t('DASHBOARD_RAILZ_CONNECT_COPY_CTA')}
        />
      </Box>
    </>
  );
};

export default ConnectCode;
