import React from 'react';
import { Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import useStyles from './styles';

interface Props {
  title: string;
  color?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  className?: any;
  variant?: 'h1' | 'h2';
  tabIndex?: 0 | -1;
}

const Title = ({ title, className, color, variant = 'h1', tabIndex = 0 }: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Typography
      variant={variant}
      color={color || 'textSecondary'}
      className={`${classes.title} ${className}`}
      tabIndex={tabIndex}
    >
      {t(title)}
    </Typography>
  );
};

export default Title;
