/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Grid, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import RefreshIcon from '@material-ui/icons/Refresh';

import useStyle from '../style';

import { FormInput } from 'components/form';
import { setClipboard } from 'helpers/common.helper';
import FormInputWithCopy from 'components/form/input-with-copy';

interface Props {
  disabled: boolean;
  form: any;
  helpLinkEnabled: boolean;
  defaultContent: any;
  setHelpLinkEnabled: (bool: boolean) => void;
}

export default function HelpLinkSection({
  disabled,
  helpLinkEnabled,
  setHelpLinkEnabled,
  defaultContent,
  form: { errors, control, setValue, clearErrors, getValues, watch },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const [isDefault, setIsDefault] = useState(false);
  const [showCopyDone, setShowCopyDone] = useState(false);

  const watchAllFields = watch();

  const copyUrl = (): void => {
    const { helpLinkTitle } = getValues();
    setClipboard(helpLinkTitle).catch(console.error);
    setShowCopyDone(true);
  };

  useEffect(() => {
    const { helpLinkTitle, helpLinkBody } = getValues();
    setIsDefault(
      helpLinkTitle === defaultContent.helpLinkTitle &&
        helpLinkBody === defaultContent.helpLinkBody &&
        helpLinkEnabled === defaultContent.helpLinkEnabled,
    );
  }, [watchAllFields]);

  return (
    <Grid container className={disabled ? classes.uploadDisabled : null}>
      <Typography className={classes.description}>
        {t('DASHBOARD_SETTINGS_SITES_HELP_PAGE_BODY')}
      </Typography>
      <div className={classes.inputContainer}>
        <div className={classes.toggleContainer}>
          <Typography className={classes.toggleText}>
            {t('DASHBOARD_SETTINGS_SITES_HELP_PAGE_TOGGLE')}
          </Typography>
          <Switch
            checked={helpLinkEnabled}
            inputProps={{
              'aria-label': `${t(
                helpLinkEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
              )}`,
            }}
            onChange={(e): void => setHelpLinkEnabled(e.target.checked)}
            color="primary"
          />
        </div>
      </div>
      <Grid container>
        <div className={classes.inputContainer}>
          <FormInputWithCopy
            label={t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_TITLE')}
            placeholder={t('DASHBOARD_SITES_HELP_LINK_URL')}
            id="helpLinkTitle"
            name="helpLinkTitle"
            margin="dense"
            testid="help-link-title-id-input"
            fullWidth
            errorobj={errors}
            control={control}
            disabled={!helpLinkEnabled}
            className={showCopyDone ? undefined : classes.contentInputItem}
            onClick={copyUrl}
          />
          {showCopyDone && (
            <Typography className={classes.clipBoardTextWithInputs}>
              {t('DASHBOARD_GENERATE_LINK_COPIED')}
            </Typography>
          )}
          <FormInput
            defaultValue=""
            label={t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_COPY_TITLE')}
            placeholder={t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_DIPLAY')}
            id="helpLinkBody"
            name="helpLinkBody"
            margin="dense"
            testid="help-link-body-id-input"
            fullWidth
            errorobj={errors}
            control={control}
            disabled={!helpLinkEnabled}
            className={classes.contentInputItem}
          />
        </div>
      </Grid>
      {!isDefault && (
        <RailzButton
          data-testid="reset-help-link"
          type="text warning"
          buttonType="button"
          onClick={(): void => {
            setValue('helpLinkTitle', defaultContent.helpLinkTitle);
            setValue('helpLinkBody', defaultContent.helpLinkBody);
            setHelpLinkEnabled(defaultContent.helpLinkEnabled);
            clearErrors();
          }}
          label={t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_RESET')}
        >
          <span slot="prefix">
            <RefreshIcon fontSize="small" />
          </span>
        </RailzButton>
      )}
    </Grid>
  );
}
