import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      background: theme.palette.background.paper,
      height: 'fit-content',
      '& *': {
        color: theme.palette.text.primary,
      },
    },
    toolbarButton: {
      padding: 0,
      minWidth: 16,
      textTransform: 'none',
    },
    toolboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    date: {
      fontWeight: 600,
    },
  }),
);

export const popOverStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiInputBase-root input.MuiOutlinedInput-inputAdornedEnd': {
        paddingRight: 0,
      },
      '& .MuiInputAdornment-root button.MuiButtonBase-root.MuiIconButton-root': {
        /* the calendar icon in the base date-picker field */
        padding: 0,
      },

      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: theme.spacing(),
      },
    },
    yearList: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiIconButton-root:not(:disabled)': {
        color: 'black',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '14px',
      },
    },
    quarterInputField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      '& input': { cursor: 'pointer' },
    },
    yearDisplay: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '& span': { fontWeight: 600 },
    },
    quarterListText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    selectedOption: {
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      },

      '& span': { color: ACTIVE_TEXT_COLOR, fontWeight: 800 },
      '& p': { color: ACTIVE_TEXT_COLOR },
    },
  }),
);

export default useStyles;
