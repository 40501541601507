import { createSelector } from 'reselect';

import { AllState } from '../../state';

import { REPORT_STORE_NAME, ReportState } from './report.state';

import { expandDataTypes } from 'helpers/data-sync/utils';

/** Select Report State */
export const getReportState = (state: AllState): ReportState => state[REPORT_STORE_NAME];

export const getReport = createSelector(
  getReportState,
  (report: ReportState) => report && report.report,
);

export const getIsReportExportLoading = createSelector(
  getReportState,
  (report: ReportState) => report && !!report.reportExport?.loading,
);

export const getReportExportController = createSelector(
  getReportState,
  (report: ReportState) =>
    report && !!report.reportExport?.loading && report.reportExport?.controller,
);

export const getReportPaginationAndQuery = createSelector(getReportState, (report: ReportState) => {
  if (!report?.report || report?.report?.statusCode !== 200 || !report.report.requestParams)
    return null;
  const {
    data: { pagination },
    requestParams,
  } = report.report;
  return { pagination, requestParams };
});

export const getDataTypes = createSelector(
  getReportState,
  (report: ReportState) => report && report.aspDataTypes && report.aspDataTypes.reportTypes,
);

export const getExpandedDataTypes = createSelector(
  getReportState,
  (report: ReportState) =>
    report && report.aspDataTypes && expandDataTypes(report.aspDataTypes.reportTypes),
);

export const getBusinessSummaryFilter = createSelector(
  getReportState,
  (report: ReportState) => report && report.filter,
);

export const getBusinessSummary = createSelector(getReportState, (report: ReportState) => {
  return {
    bankAccountsSummary: report && report.bankAccountsSummary,
    financialRatiosSummary: report && report.financialRatiosSummary,
  };
});

export const getBusinessHistoryData = createSelector(getReportState, (report: ReportState) => {
  return report?.businessHistory;
});
