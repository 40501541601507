import axios, { AxiosResponse } from 'axios';

import { CustomerUrl, MfaRequest, SuccessResponse } from '../../../../types';

import { EnableDisableSSORequest } from '../customer/customer.state';

import {
  EnabledMfaResponse,
  EnableMfaRequest,
  GeneratedMfaResponse,
  ProfileResponse,
  ProfileState,
  TeamSettings,
} from './profile.state';

import Config from 'config';

class ProfileService {
  async getProfile(): Promise<AxiosResponse<ProfileState>> {
    return await axios.get<ProfileState>(`${Config.SERVER_URL}/customers/user`);
  }

  async updateProfile(data: any): Promise<AxiosResponse<ProfileState>> {
    return await axios.put<ProfileState>(`${Config.SERVER_URL}/customers/user`, data);
  }

  async uploadProfilePic(data: any): Promise<AxiosResponse<CustomerUrl>> {
    return await axios.post(`${Config.SERVER_URL}/customers/user/profile/upload`, data);
  }

  async disableMfa(payload: MfaRequest): Promise<AxiosResponse<SuccessResponse>> {
    return await axios.post<SuccessResponse>(
      `${Config.SERVER_URL}/customers/user/mfaDisable`,
      payload,
    );
  }

  async enableMfa(payload: EnableMfaRequest): Promise<AxiosResponse<EnabledMfaResponse>> {
    return await axios.post<EnabledMfaResponse>(
      `${Config.SERVER_URL}/customers/user/mfaEnable`,
      payload,
    );
  }

  async generateMfa(): Promise<AxiosResponse<GeneratedMfaResponse>> {
    return await axios.post<GeneratedMfaResponse>(`${Config.SERVER_URL}/customers/user/mfa`);
  }

  async enableDisableSSO(
    payload: EnableDisableSSORequest,
  ): Promise<AxiosResponse<ProfileResponse>> {
    return await axios.put<ProfileResponse>(`${Config.SERVER_URL}/customers/company`, payload);
  }

  async updateTeamSettings(payload: TeamSettings): Promise<AxiosResponse<TeamSettings>> {
    return await axios.patch<TeamSettings>(`${Config.SERVER_URL}/teams/teamSettings`, payload);
  }
}

export default new ProfileService();
