import React from 'react';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyle from './style';

import Logo from 'components/logo';
import LayoutIllustration from 'assets/images/LayoutIllustration.svg';

const LayoutImage: React.FC<{
  children: React.ReactNode;
  image?: string;
}> = ({ children, image = LayoutIllustration }: { children: React.ReactNode; image?: string }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Container role="main" className={classes.container}>
      <div className={classes.outerLayout}>
        <div className={classes.firstLayout}>
          <div className={classes.firstLayoutContent}>
            <Logo style={{ paddingBottom: 'clamp(40px, 8vh, 120px)' }} />
            {children}
          </div>
        </div>
        <div className={classes.secondLayout}>
          <div className={classes.imageWrapper}>
            <img
              src={image}
              alt={t('DASHBOARD_ILLUSTRATION_ALT')}
              style={{
                width: '100%',
                maxWidth: '544px',
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LayoutImage;
