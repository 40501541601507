import { call, put, select, takeLatest } from 'redux-saga/effects';

import { CustomerUrl, Role, SuccessResponse } from '../../../../types';

import { fetchIntegrationsApi } from '../../integration/integration.action';

import { fetchCustomerApi } from '../customer/customer.action';

import { isSandbox } from '../../auth';

import {
  DELETE_USER_MFA_API,
  deleteUserMfaFailureApi,
  deleteUserMfaSuccessApi,
  ENABLE_MFA_API,
  enableMfaFailureApi,
  enableMfaSuccessApi,
  FETCH_PROFILE_API,
  fetchProfileFailureApi,
  fetchProfileSuccessApi,
  GENERATE_MFA_API,
  generateMfaFailureApi,
  generateMfaSuccessApi,
  UPDATE_PROFILE_API,
  UPDATE_PROFILE_PICTURE_API,
  UPDATE_TEAM_SETTINGS_API,
  updateProfileFailureApi,
  updateProfilePicFailureApi,
  updateProfilePicSuccessApi,
  updateProfileSuccessApi,
  updateTeamSettingsFailureApi,
  updateTeamSettingsSuccessApi,
} from './profile.action';

import {
  EnabledMfaResponse,
  GeneratedMfaResponse,
  ProfileCompanyAction,
  ProfileState,
  TeamSettings,
  UpdateTeamSettingsAction,
} from './profile.state';

import { default as ProfileService } from './profile.service';

import { SENTRY_CONTEXTS, updateSentryContext } from 'services';

function* fetchProfileApiCall(action: ProfileCompanyAction): Generator<any> {
  try {
    const userProfile = (yield call(ProfileService.getProfile) as unknown) as ProfileState;
    const role = userProfile?.role;

    if (userProfile) {
      updateSentryContext(SENTRY_CONTEXTS.USER, {
        id: userProfile.uuid || userProfile.userUuid,
        email: userProfile.email,
      });

      updateSentryContext(SENTRY_CONTEXTS.TEAM, {
        uuid: userProfile.teamId || userProfile.teamUuid,
        teamRole: role,
      });
    }

    if (action.payload) {
      const sandbox = (yield select(isSandbox)) as boolean;
      updateSentryContext(SENTRY_CONTEXTS.SANDBOX, { sandbox: !!sandbox });
      if ([Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.BILLING].includes(role)) {
        yield put(fetchCustomerApi(true));
      }
      if ([Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER].includes(role)) {
        yield put(fetchIntegrationsApi());
      }
    }
    yield put(fetchProfileSuccessApi(userProfile));
  } catch (error) {
    yield put(fetchProfileFailureApi(error));
  }
}

function* fetchProfileSaga(): Generator<any> {
  yield takeLatest(FETCH_PROFILE_API, fetchProfileApiCall);
}

function* updateProfileApiCall({ payload }: ProfileCompanyAction): Generator<any> {
  try {
    const response = (yield call(ProfileService.updateProfile, payload) as unknown) as ProfileState;
    yield put(updateProfileSuccessApi(response));
  } catch (error) {
    yield put(updateProfileFailureApi(error));
  }
}

function* updateProfileSaga(): Generator<any> {
  yield takeLatest(UPDATE_PROFILE_API, updateProfileApiCall);
}

function* updateProfilePicApiCall({ payload }: ProfileCompanyAction): Generator<any> {
  try {
    const response = (yield call(
      ProfileService.uploadProfilePic,
      payload,
    ) as unknown) as CustomerUrl;
    yield put(updateProfilePicSuccessApi(response));
  } catch (error) {
    yield put(updateProfilePicFailureApi(error));
  }
}

function* updateProfilePicSaga(): Generator<any> {
  yield takeLatest(UPDATE_PROFILE_PICTURE_API, updateProfilePicApiCall);
}

function* deleteUserMfaApiCall({ payload }: ProfileCompanyAction): Generator<any> {
  try {
    const response = (yield call(ProfileService.disableMfa, payload) as unknown) as SuccessResponse;
    yield put(deleteUserMfaSuccessApi(response));
  } catch (error) {
    yield put(deleteUserMfaFailureApi(error));
  }
}

function* deleteUserMfaSaga(): Generator<any> {
  yield takeLatest(DELETE_USER_MFA_API, deleteUserMfaApiCall);
}

function* enableMfaApiCall({ payload }: ProfileCompanyAction): Generator<any> {
  try {
    const response = (yield call(
      ProfileService.enableMfa,
      payload,
    ) as unknown) as EnabledMfaResponse;
    yield put(enableMfaSuccessApi(response));
  } catch (error) {
    yield put(enableMfaFailureApi(error));
  }
}

function* enableMfaSaga(): Generator<any> {
  yield takeLatest(ENABLE_MFA_API, enableMfaApiCall);
}

function* generateMfaApiCall(): Generator<any> {
  try {
    const response = (yield call(ProfileService.generateMfa) as unknown) as GeneratedMfaResponse;
    yield put(generateMfaSuccessApi(response));
  } catch (error) {
    yield put(generateMfaFailureApi(error));
  }
}

function* generateMfaSaga(): Generator<any> {
  yield takeLatest(GENERATE_MFA_API, generateMfaApiCall);
}

function* updateTeamSettingsApiCall({ payload }: UpdateTeamSettingsAction): Generator<any> {
  try {
    const response = (yield call(
      ProfileService.updateTeamSettings,
      payload,
    ) as unknown) as TeamSettings;
    yield put(updateTeamSettingsSuccessApi(response));
  } catch (error) {
    yield put(updateTeamSettingsFailureApi(error));
  }
}

function* updateTeamSettingsSaga(): Generator<any> {
  yield takeLatest(UPDATE_TEAM_SETTINGS_API, updateTeamSettingsApiCall);
}

export {
  fetchProfileApiCall,
  updateProfileApiCall,
  updateProfilePicApiCall,
  generateMfaApiCall,
  enableMfaApiCall,
  deleteUserMfaApiCall,
};
export const profileSaga = [
  fetchProfileSaga(),
  updateProfileSaga(),
  updateProfilePicSaga(),
  deleteUserMfaSaga(),
  enableMfaSaga(),
  generateMfaSaga(),
  updateTeamSettingsSaga(),
];
