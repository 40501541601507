import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },

    view: {
      padding: theme.spacing(3),
      height: '100%',
      paddingBottom: 0,
    },

    scrollContainer: {
      paddingTop: theme.spacing(2),
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      padding: `${theme.spacing(3)}px 0`,

      '& .Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
    wrapperFilter: {
      marginBottom: theme.spacing(3),

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(2),

      ['@media screen and (max-width: 768px)']: {
        flexDirection: 'column',
        '&>.status-input-root, & > form, & railz-button': {
          width: '100%',
          maxWidth: '100%',
        },
      },
    },

    searchFilter: {
      minWidth: 160,
      width: '25%',
      '& input': {
        fontSize: '0.875rem',
      },
      ['@media screen and (max-width: 768px)']: {
        width: '100%',
        maxWidth: '100%',
      },
    },

    ctaButton: {
      '& button': {
        width: '100% !important',
        minWidth: '60px !important',
      },
    },

    addBusinessButton: {
      marginRight: 0,
      marginLeft: 'auto',
      '& button': {
        width: '184px !important',
        fontSize: '0.875rem !important',
      },
      ['@media screen and (max-width: 768px)']: {
        '& button': {
          width: '100% !important',
        },
      },
    },

    noData: {},

    tableRoot: {
      scrollBehavior: 'smooth',
      scrollPaddingTop: 'calc(80px + 20vh)',
      flexGrow: 1,
    },

    tableHead: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      backgroundColor: 'white',
    },

    tableHeadCell: {
      paddingBottom: theme.spacing(1),
      fontSize: '0.75rem',
      color: theme.palette.grey[800],
      fontWeight: 600,
      letterSpacing: '0.06em',
      whiteSpace: 'nowrap',

      '&.MuiTableCell-stickyHeader': {
        backgroundColor: 'white',
      },

      '& span': {
        position: 'relative',
      },

      '& span::after': {
        position: 'absolute',
        content: '""',
        top: 0,
        left: theme.spacing(-0.5),
        right: theme.spacing(-0.5),
        bottom: 0,
        borderRadius: '2px',
        transition: 'all 0.2s ease-in-out',
      },
      '& span:focus-visible::after': {
        boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
      },
    },
  }),
);
