import axios from 'axios';

import Config from 'config';

import { ApiResponse, ApiKeyGenerateResponse, ApiKey } from 'types';

class ApiKeyService {
  async getApiKeys(): Promise<ApiKey[]> {
    return await axios.get(`${Config.FEEDER_SERVER}/apiSecrets`);
  }

  async createApiKey(payload: Partial<ApiKey>): Promise<ApiKeyGenerateResponse> {
    return await axios.post(`${Config.FEEDER_SERVER}/apiSecrets`, payload);
  }

  async deleteApiKey(identifier: ApiKey['identifier']): Promise<ApiResponse> {
    return await axios.delete(`${Config.FEEDER_SERVER}/apiSecrets/${identifier}`);
  }
}

export default new ApiKeyService();
