import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      paddingBottom: theme.spacing(3),
    },
    text: {
      color: theme.palette.grey[800],
      fontSize: '1rem',
      lineHeight: '1.5rem',
      wordBreak: 'break-word',
    },
  }),
);
