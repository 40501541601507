import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RailzLogo } from '@railzai/railz-uikit-react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import style from './style';

import VerticalLine from 'assets/icons/vertical-line';

import { AspType } from 'types/enum';

import ArrowIcon from 'assets/icons/double-arrow';

import { getSelectedBusiness } from 'store/features/business/business.selector';
import { getBusinessSummaryFilter } from 'store/features/report/report.selector';
import { getConnectionServiceName } from 'helpers/common.helper';

const format = 'MMM DD, YYYY';
const blacklist = [AspType.PLAID, AspType.SHOPIFY, AspType.SQUARE];

const SearchTimestamp = (): any => {
  const classes = style();
  const { t } = useTranslation();

  const [serviceAsp, setServiceAsp] = useState(null);
  const [serviceBanks, setServiceBanks] = useState(null);
  const selectedBusiness = useSelector(getSelectedBusiness);
  const filter = useSelector(getBusinessSummaryFilter);

  useEffect(() => {
    const connections = selectedBusiness.connections;
    const AspKeys = Object.values(AspType);

    const asps = connections?.filter(
      (c) => AspKeys.includes(c.serviceName) && !blacklist.includes(c.serviceName),
    );
    const banks = connections?.filter(
      (c) => c.serviceName === AspType.PLAID && (c.firstRecordDate || c.institution),
    );

    setServiceAsp(asps.length > 0 ? asps[0] : null);
    setServiceBanks(banks.length > 0 ? banks : null);
  }, [selectedBusiness]);

  const getReconcileDate = (): string => {
    const start = moment(filter.startDate).format(format);
    const end = moment(filter.endDate).format(format);
    return `${start} - ${end}`;
  };

  if (!serviceAsp || !serviceBanks) {
    return <></>;
  }

  return (
    <Grid container>
      <Grid item className={classes.flexContainer}>
        <div className={classes.imgContainer}>
          <RailzLogo name={serviceAsp.serviceName} variant="small" outlined />
        </div>
        <Typography className={classes.aspName}>{getConnectionServiceName(serviceAsp)}</Typography>
        <div className={classes.arrowIconContainer}>
          <ArrowIcon />
        </div>
      </Grid>
      {serviceBanks.map((bank, i) => {
        const bankQty = serviceBanks.length - 1;
        const renderComma = i < bankQty && i !== bankQty;
        const renderMarginAfterPadding = i > 0;
        return (
          <Grid
            item
            className={`${classes.flexContainer} ${
              renderMarginAfterPadding ? classes.commaContainer : null
            }`}
            key={i}
          >
            <div className={classes.imgContainer}>
              <img src={bank?.institution?.logo} alt={`${bank?.institution?.name} icon`} />
            </div>
            <Typography className={classes.aspName}>
              {bank?.institution?.name} {renderComma && ','}
            </Typography>
          </Grid>
        );
      })}
      <div className={`${classes.verticalLineContainer} ${classes.flexContainer}`}>
        <VerticalLine />
      </div>
      <Grid item className={classes.flexContainer}>
        <Typography className={classes.reconcileTitle}>
          {t('DASHBOARD_ENUM_REPORT_TABLE_HEADER_RECONILE_PERIOD')}
        </Typography>
        <Typography className={classes.reconcileDate}>{getReconcileDate()}</Typography>
      </Grid>
    </Grid>
  );
};

export default SearchTimestamp;
