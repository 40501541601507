import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minHeight: theme.spacing(4),
      fontWeight: 600,
      fontSize: theme.spacing(3),
      lineHeight: '32px',
      letterSpacing: '-0.02em',
      color: '#000000',
      height: '100%',
      marginBottom: theme.spacing(),
    },
    description: {
      fontWeight: 400,
      fontSize: theme.spacing(2),
      lineHeight: '26px',
      color: '#424242',
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    loginButton: {
      display: 'block',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    termsLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    termsLink: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
    termContainer: {
      marginTop: theme.spacing(3),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      fontWeight: 600,
    },
    forgotPasswordButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    signUpLabel: {
      width: '100%',
      textAlign: 'left',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    twoFactorButton: {
      display: 'inline-block',
      marginLeft: theme.spacing(1),
    },
    mfaLoginHeader: {
      paddingBottom: theme.spacing(2),
    },
    mfaRecoveryDescription: {
      paddingBottom: theme.spacing(2),
    },
    mfaRecoveryInput: {
      marginBottom: theme.spacing(3),
    },
    mfaContainer: {
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    mfaRecoveryBack: {
      textAlign: 'left',
      marginTop: theme.spacing(2),
      width: '100%',
    },
    ssoLogin: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      '& > button:first-child': {
        marginBottom: theme.spacing(2),
      },
    },
    captchaContainer: {
      width: theme.spacing(38),
      height: theme.spacing(9.75),
    },
    captchaError: {
      color: theme.palette.error.main,
      margin: `3px ${theme.spacing(1.75)}px 0px ${theme.spacing(1.75)}px`,
      fontWeight: Number(theme.typography.fontWeightRegular),
      lineHeight: '1.66',
      fontSize: '0.75rem',
    },
  }),
);
