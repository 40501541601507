import axios from 'axios';

import Config from '../../../config';
import { BusinessCrudResponse, Services } from '../../../types';

import { BusinessStatus } from '../../../pages/business/types/enums';

import {
  BusinessEventStat,
  BusinessFilter,
  BusinessResponseApi,
  CreateBusinessParam,
  DeleteBusinessParam,
  GenerateUrlPayload,
  GenerateUrlResponse,
  RenameBusinessParam,
} from './business.state';

import { Connection } from 'pages/business/types/interfaces';

class BusinessService {
  async deleteConnection(params: { connectionId: string }): Promise<Connection> {
    return await axios.post(`${Config.FEEDER_SERVER}/connections/disconnect`, params);
  }

  async deleteBusiness(params: DeleteBusinessParam): Promise<BusinessCrudResponse> {
    return await axios.delete(`${Config.FEEDER_SERVER}/businesses/${params}`);
  }

  async createBusinesses(params: CreateBusinessParam): Promise<BusinessCrudResponse> {
    return await axios.post(`${Config.FEEDER_SERVER}/v2/businesses`, params);
  }

  async renameBusiness(params: RenameBusinessParam): Promise<BusinessCrudResponse> {
    return await axios.put(`${Config.FEEDER_SERVER}/v2/businesses/${params.uuid}`, {
      businessName: params.businessName,
    });
  }

  async getBusinesses(params?: BusinessFilter | string): Promise<BusinessResponseApi> {
    const url = `${Config.FEEDER_SERVER}/businesses`;
    if (typeof params === 'string') return axios.get(`${url}${params}`);

    return axios.get(url, { params });
  }

  async getServices(): Promise<Array<Services>> {
    return axios.get(`${Config.SERVER_URL}/services`);
  }

  async getStats(params: { state: BusinessStatus }): Promise<BusinessEventStat> {
    return axios.get(`${Config.FEEDER_SERVER}/businesses/stats`, { params });
  }

  async generateUrl(payload: GenerateUrlPayload): Promise<GenerateUrlResponse> {
    return await axios.post(`${Config.FEEDER_SERVER}/v2/businesses/generateUrl`, payload);
  }
}

export default new BusinessService();
