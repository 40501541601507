import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';

import { Trans, useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useHistory } from 'react-router';

import useStyle from './style';

import EmptyFolder from 'assets/images/EmptyFolder.svg';

interface Props {
  hasWebhooks?: boolean;
  hasWebhookHistory?: boolean;
}

export default function WebhookLogsEmptyState({
  hasWebhooks,
  hasWebhookHistory,
}: Props): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const noWebhookHistoryDefaultState = {
    title: 'DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_TITLE_NO_ACTIVITY',
    subtitle: 'DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_DESCRIPTION_NO_ACTIVITY',
    buttonText: 'DASHBOARD_BUSINESS_HOMEPAGE_LINK',
    redirectLink: '/businesses',
  };
  const noWebhooksDefaultState = {
    title: 'DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_DEFAULT_TITLE',
    subtitle: 'DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_DEFAULT_DESCRIPTION',
    buttonText: 'DASHBOARD_DEVELOPERS_WEBHOOKS_LOG_DEFAULT_CTA',
    redirectLink: '/developers/webhooks',
  };

  const [defaultStateMsg, setDefaultStateMsg] = useState(noWebhookHistoryDefaultState);

  useEffect(() => {
    if (!hasWebhooks && !hasWebhookHistory) setDefaultStateMsg(noWebhooksDefaultState);
  }, []);

  const handleRedirect = (redirectLink): void => {
    history.push(redirectLink);
  };

  return (
    <>
      <div className={classes.emptyStateContainer}>
        <Box>
          <img src={EmptyFolder} alt="logo" />
        </Box>
        <Typography
          variant="subtitle1"
          paragraph={true}
          className={classes.noMarginBottom}
          style={{ fontWeight: 600 }}
        >
          {<Trans i18nKey={defaultStateMsg.title} />}
        </Typography>
        <Typography variant="subtitle2" paragraph={true} style={{ fontWeight: 400 }}>
          {<Trans i18nKey={defaultStateMsg.subtitle} />}
        </Typography>
        <Box>
          <RailzButton
            size="large"
            type="outlined"
            onClick={(): void => handleRedirect(defaultStateMsg.redirectLink)}
            target="_self"
            label={t(defaultStateMsg.buttonText)}
            data-testid="webhook-log-redirect-button"
          >
            <span slot="suffix">
              <ArrowForwardIcon fontSize="small" />
            </span>
          </RailzButton>
        </Box>
      </div>
    </>
  );
}
