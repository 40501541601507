import {
  AspType,
  ITableField,
  ReportAccountingMethod,
  ReportFrequency,
  ReportStatus,
  ReportType,
  ExportOption,
  ReportFinancialStatementType,
  ReportTypeDataTypeMapping,
  ASP_SERVICE_TYPE,
} from '../../types';
import { descendingComparator, DropDownMap, generateDropdown } from '../common.helper';

import { DataType, TableHeaderKey } from './types';
import { generateTranslation } from './translation-utils';

import i18n from 'translations';

export const REPORT_PAGINATION_LIMIT = 100;
export const REPORT_DOWNLOAD_LIMIT = 500;
export const PARALLEL_DOWNLOAD_REQUESTS = 2;

const translatedHeaders = generateTranslation(TableHeaderKey);

export const headerTransaction: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.CREATED_DATE],
    keys: [TableHeaderKey.CREATED_DATE],
    key: TableHeaderKey.CREATED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.IDENTIFIER],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.IDENTIFIER,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYMENT_METHOD_TYPE],
    keys: [TableHeaderKey.PAYMENT_METHOD_TYPE],
    key: TableHeaderKey.PAYMENT_METHOD_TYPE,
    type: DataType.COMMERCE_PAYMENT_METHOD,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_AMOUNT],
    keys: [TableHeaderKey.TOTAL_AMOUNT],
    key: TableHeaderKey.TOTAL_AMOUNT,
    type: DataType.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.SYMBOL],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.TRANSACTION_TYPE],
    keys: [TableHeaderKey.TRANSACTION_TYPE],
    key: TableHeaderKey.TRANSACTION_TYPE,
    type: DataType.COMMERCE_TRANSACTION_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.COMMERCE_TRANSACTION_STATUS,
  },
];

export const headerCommerceOrder: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.CREATED_DATE],
    keys: [TableHeaderKey.CREATED_DATE],
    key: TableHeaderKey.CREATED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.ORDER_IDENTIFIER],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ORDER_IDENTIFIER,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER],
    keys: [TableHeaderKey.CUSTOMER_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CUSTOMER,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_ORDER_AMOUNT],
    keys: [TableHeaderKey.TOTAL_ORDER_AMOUNT],
    key: TableHeaderKey.TOTAL_ORDER_AMOUNT,
    type: DataType.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.ORDER_STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.ORDER_STATUS,
    type: DataType.COMMERCE_ORDER_STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYMENT_STATUS],
    keys: [TableHeaderKey.PAYMENT_STATUS],
    key: TableHeaderKey.PAYMENT_STATUS,
    type: DataType.COMMERCE_ORDER_PAYMENT_STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.FULFILMENT_STATUS],
    keys: [TableHeaderKey.FULFILMENT_STATUS],
    key: TableHeaderKey.FULFILMENT_STATUS,
    type: DataType.COMMERCE_ORDER_FULFILMENT_STATUS,
  },
];

export const headerTrialBalance: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.END_DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.SECTION],
    keys: [TableHeaderKey.SECTION],
    key: TableHeaderKey.SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_SECTION],
    keys: [TableHeaderKey.SUB_SECTION],
    key: TableHeaderKey.SUB_SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.GROUP],
    keys: [TableHeaderKey.GROUP],
    key: TableHeaderKey.GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_GROUP],
    keys: [TableHeaderKey.SUB_GROUP],
    key: TableHeaderKey.SUB_GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT],
    keys: [TableHeaderKey.ACCOUNT],
    key: TableHeaderKey.ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_ACCOUNT],
    keys: [TableHeaderKey.SUB_ACCOUNT],
    key: TableHeaderKey.SUB_ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.DEBIT],
    keys: [TableHeaderKey.DEBIT],
    key: TableHeaderKey.DEBIT,
    type: DataType.NUMBER_WITH_0_FALLBACK,
  },
  {
    name: translatedHeaders[TableHeaderKey.CREDIT],
    keys: [TableHeaderKey.CREDIT],
    key: TableHeaderKey.CREDIT,
    type: DataType.NUMBER_WITH_0_FALLBACK,
  },
];

export const headerBusinessValuations: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.END_DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.LIQUIDATION_VALUE],
    keys: [TableHeaderKey.LIQUIDATION_VALUE],
    key: TableHeaderKey.LIQUIDATION_VALUE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DISCOUNTED_CASHFLOW_VALUE],
    keys: [TableHeaderKey.DISCOUNTED_CASHFLOW_VALUE],
    key: TableHeaderKey.DISCOUNTED_CASHFLOW_VALUE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DISCOUNT_RATE],
    keys: [TableHeaderKey.DISCOUNT_RATE],
    key: TableHeaderKey.DISCOUNT_RATE,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.MULTIPLE_TO_REVENUE_VALUE],
    keys: [TableHeaderKey.MULTIPLE_TO_REVENUE_VALUE],
    key: TableHeaderKey.MULTIPLE_TO_REVENUE_VALUE,
  },
  {
    name: translatedHeaders[TableHeaderKey.REVENUE_MULTIPLE],
    keys: [TableHeaderKey.REVENUE_MULTIPLE],
    key: TableHeaderKey.REVENUE_MULTIPLE,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.FIRST_CHICAGO_VALUE],
    keys: [TableHeaderKey.FIRST_CHICAGO_VALUE],
    key: TableHeaderKey.FIRST_CHICAGO_VALUE,
  },
];

export const headerTrackingCategories: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.NAME],
    keys: [TableHeaderKey.NAME],
    key: TableHeaderKey.NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.PARENT_REFERENCE_ID],
    keys: [TableHeaderKey.PARENT_REF, TableHeaderKey.ID],
    key: TableHeaderKey.PARENT_REFERENCE_ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.PARENT_REFERENCE_NAME],
    keys: [TableHeaderKey.PARENT_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.PARENT_REFERENCE_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
  },
];

export const headerCashFlow: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.END_DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.SECTION],
    keys: [TableHeaderKey.SECTION],
    key: TableHeaderKey.SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_SECTION],
    keys: [TableHeaderKey.SUB_SECTION],
    key: TableHeaderKey.SUB_SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.GROUP],
    keys: [TableHeaderKey.GROUP],
    key: TableHeaderKey.GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT],
    keys: [TableHeaderKey.ACCOUNT],
    key: TableHeaderKey.ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_ACCOUNT],
    keys: [TableHeaderKey.SUB_ACCOUNT],
    key: TableHeaderKey.SUB_ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.VALUE],
    keys: [TableHeaderKey.VALUE],
    key: TableHeaderKey.VALUE,
    type: DataType.NUMBER,
  },
];

export const headerFinancialForecasts: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.SECTION],
    keys: [TableHeaderKey.SECTION],
    key: TableHeaderKey.SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_SECTION],
    keys: [TableHeaderKey.SUB_SECTION],
    key: TableHeaderKey.SUB_SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.GROUP],
    keys: [TableHeaderKey.GROUP],
    key: TableHeaderKey.GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_GROUP],
    keys: [TableHeaderKey.SUB_GROUP],
    key: TableHeaderKey.SUB_GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT],
    keys: [TableHeaderKey.ACCOUNT],
    key: TableHeaderKey.ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_ACCOUNT],
    keys: [TableHeaderKey.SUB_ACCOUNT],
    key: TableHeaderKey.SUB_ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.VALUE],
    keys: [TableHeaderKey.VALUE],
    key: TableHeaderKey.VALUE,
    type: DataType.NUMBER,
  },
];

export const headerDefault: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.END_DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.SECTION],
    keys: [TableHeaderKey.SECTION],
    key: TableHeaderKey.SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_SECTION],
    keys: [TableHeaderKey.SUB_SECTION],
    key: TableHeaderKey.SUB_SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.GROUP],
    keys: [TableHeaderKey.GROUP],
    key: TableHeaderKey.GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_GROUP],
    keys: [TableHeaderKey.SUB_GROUP],
    key: TableHeaderKey.SUB_GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT],
    keys: [TableHeaderKey.ACCOUNT],
    key: TableHeaderKey.ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_ACCOUNT],
    keys: [TableHeaderKey.SUB_ACCOUNT],
    key: TableHeaderKey.SUB_ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.VALUE],
    keys: [TableHeaderKey.VALUE],
    key: TableHeaderKey.VALUE,
    type: DataType.NUMBER,
  },
];

export const headerInvoice: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.INVOICE_NUMBER],
    keys: [TableHeaderKey.INVOICE_NUMBER],
    key: TableHeaderKey.INVOICE_NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER_NAME],
    keys: [TableHeaderKey.CUSTOMER_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.CUSTOMER_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DUE_DATE],
    keys: [TableHeaderKey.DUE_DATE],
    key: TableHeaderKey.DUE_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.INVOICE_AMOUNT],
    keys: [TableHeaderKey.INVOICE_AMOUNT],
    key: TableHeaderKey.INVOICE_AMOUNT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT_DUE],
    keys: [TableHeaderKey.AMOUNT_DUE],
    key: TableHeaderKey.AMOUNT_DUE,
    type: DataType.NUMBER,
  },
];

export const headerEmployees: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.EMPLOYEE_NAME],
    keys: [TableHeaderKey.EMPLOYEE_NAME],
    key: TableHeaderKey.EMPLOYEE_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.HIRED_DATE],
    keys: [TableHeaderKey.HIRED_DATE],
    key: TableHeaderKey.HIRED_DATE,
    type: DataType.DATE,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.RELEASED_DATE],
    keys: [TableHeaderKey.RELEASED_DATE],
    key: TableHeaderKey.RELEASED_DATE,
    type: DataType.DATE,
    sort: false,
  },
];

export const headerDeposits: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_CUSTOMER_NAME],
    keys: [TableHeaderKey.ENTITY_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.ENTITY_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT],
    keys: [TableHeaderKey.AMOUNT],
    key: TableHeaderKey.AMOUNT,
  },
];

export const headerEstimates: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER_NAME],
    keys: [TableHeaderKey.CUSTOMER_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.CUSTOMER_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.EXPIRY_DATE],
    keys: [TableHeaderKey.EXPIRY_DATE],
    key: TableHeaderKey.EXPIRY_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT],
    keys: [TableHeaderKey.AMOUNT],
    key: TableHeaderKey.AMOUNT,
    type: DataType.NUMBER,
  },
];

export const headerJournalEntries: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DESCRIPTION],
    keys: [TableHeaderKey.DESCRIPTION],
    key: TableHeaderKey.DESCRIPTION,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT_NAME],
    keys: [TableHeaderKey.ACCOUNT_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.ACCOUNT_NAME,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.DEBIT],
    keys: [TableHeaderKey.DEBIT],
    key: TableHeaderKey.DEBIT,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.CREDIT],
    keys: [TableHeaderKey.CREDIT],
    key: TableHeaderKey.CREDIT,
    sort: false,
  },
];

export const headerInvoiceCreditNotes: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER_NAME],
    keys: [TableHeaderKey.CUSTOMER_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.CUSTOMER_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.ALLOCATED_DATE],
    keys: [TableHeaderKey.ALLOCATED_DATE],
    key: TableHeaderKey.ALLOCATED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.CREDIT_NOTE_AMOUNT],
    keys: [TableHeaderKey.INVOICE_AMOUNT],
    key: TableHeaderKey.INVOICE_AMOUNT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.REMAINING_CREDIT],
    keys: [TableHeaderKey.REMAINING_CREDIT],
    key: TableHeaderKey.REMAINING_CREDIT,
    type: DataType.NUMBER,
  },
];

export const headerPurchaseOrders: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_NAME],
    keys: [TableHeaderKey.VENDOR_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT],
    keys: [TableHeaderKey.AMOUNT],
    key: TableHeaderKey.AMOUNT,
    type: DataType.NUMBER,
  },
];
export const headerProbabilityOfDefault: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.DATE],
    key: TableHeaderKey.DATE,
    type: DataType.DATE,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.MODEL],
    keys: [TableHeaderKey.MODEL],
    key: TableHeaderKey.MODEL,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.VALUE],
    keys: [TableHeaderKey.VALUE],
    key: TableHeaderKey.VALUE,
    sort: false,
  },
];

export const headerRefunds: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER_NAME],
    keys: [TableHeaderKey.CUSTOMER_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.DATE],
    key: TableHeaderKey.DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT],
    keys: [TableHeaderKey.AMOUNT],
    key: TableHeaderKey.AMOUNT,
    type: DataType.NUMBER,
  },
];

export const headerBill: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_NAME],
    keys: [TableHeaderKey.VENDOR_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DUE_DATE],
    keys: [TableHeaderKey.DUE_DATE],
    key: TableHeaderKey.DUE_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.BILL_AMOUNT],
    keys: [TableHeaderKey.BILL_AMOUNT],
    key: TableHeaderKey.BILL_AMOUNT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT_DUE],
    keys: [TableHeaderKey.AMOUNT_DUE],
    key: TableHeaderKey.AMOUNT_DUE,
    type: DataType.NUMBER,
  },
];
export const headerInvoicePayments: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER_NAME],
    keys: [TableHeaderKey.CUSTOMER_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT_NAME],
    keys: [TableHeaderKey.ACCOUNT_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.ACCOUNT_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYMENT_DATE],
    keys: [TableHeaderKey.DATE],
    key: TableHeaderKey.DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT],
    keys: [TableHeaderKey.AMOUNT],
    key: TableHeaderKey.AMOUNT,
    type: DataType.NUMBER,
  },
];
export const headerBillPayments: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_NAME],
    keys: [TableHeaderKey.VENDOR_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT_NAME],
    keys: [TableHeaderKey.ACCOUNT_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.ACCOUNT_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYMENT_DATE],
    keys: [TableHeaderKey.DATE],
    key: TableHeaderKey.PAYMENT_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT],
    keys: [TableHeaderKey.AMOUNT],
    key: TableHeaderKey.AMOUNT,
    type: DataType.NUMBER,
  },
];

export const headerBillPaymentRequests: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_NAME],
    keys: [TableHeaderKey.VENDOR_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYEE_BANK_ACCOUNT],
    keys: [TableHeaderKey.BANK_ACCOUNT_REF, TableHeaderKey.ID],
    key: TableHeaderKey.PAYEE_BANK_ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATE],
    keys: [TableHeaderKey.STATE],
    key: TableHeaderKey.STATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYMENT_DATE],
    keys: [TableHeaderKey.PAYMENT_DATE],
    key: TableHeaderKey.PAYMENT_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYMENT_METHOD],
    keys: [TableHeaderKey.PAYMENT_METHOD_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.PAYMENT_METHOD,
    type: DataType.STRING_WITH_DASH_FALLBACK,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_AMOUNT],
    keys: [TableHeaderKey.TOTAL_AMOUNT],
    key: TableHeaderKey.TOTAL_AMOUNT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT_DUE],
    keys: [TableHeaderKey.AMOUNT_DUE],
    key: TableHeaderKey.AMOUNT_DUE,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_PAID],
    keys: [TableHeaderKey.TOTAL_PAID],
    key: TableHeaderKey.TOTAL_PAID,
    type: DataType.NUMBER,
  },
];

export const headerBillCreditNotes: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_NAME],
    keys: [TableHeaderKey.VENDOR_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.POSTED_DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.ALLOCATED_DATE],
    keys: [TableHeaderKey.ALLOCATED_DATE],
    key: TableHeaderKey.ALLOCATED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    type: DataType.STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.CREDIT_NOTE_AMOUNT],
    keys: [TableHeaderKey.BILL_AMOUNT],
    key: TableHeaderKey.BILL_AMOUNT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.REMAINING_CREDIT],
    keys: [TableHeaderKey.REMAINING_CREDIT],
    key: TableHeaderKey.REMAINING_CREDIT,
    type: DataType.NUMBER,
  },
];

export const headerAccounts: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.NAME],
    keys: [TableHeaderKey.NAME],
    key: TableHeaderKey.NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.CODE],
    keys: [TableHeaderKey.ACCOUNT_CODE],
    key: TableHeaderKey.ACCOUNT_CODE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DESCRIPTION],
    keys: [TableHeaderKey.DESCRIPTION],
    key: TableHeaderKey.DESCRIPTION,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
  },
  {
    name: translatedHeaders[TableHeaderKey.SECTION],
    keys: [TableHeaderKey.SECTION],
    key: TableHeaderKey.SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_SECTION],
    keys: [TableHeaderKey.SUB_SECTION],
    key: TableHeaderKey.SUB_SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.GROUP],
    keys: [TableHeaderKey.GROUP],
    key: TableHeaderKey.GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_GROUP],
    keys: [TableHeaderKey.SUB_GROUP],
    key: TableHeaderKey.SUB_GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACTIVE],
    keys: [TableHeaderKey.ACTIVE],
    key: TableHeaderKey.ACTIVE,
    type: DataType.BOOLEAN,
  },
  {
    name: translatedHeaders[TableHeaderKey.IS_BANK_ACCOUNT],
    keys: [TableHeaderKey.IS_BANK_ACCOUNT],
    key: TableHeaderKey.IS_BANK_ACCOUNT,
    type: DataType.BOOLEAN,
  },
];
export const headerCredits: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.SP_RATING],
    keys: [TableHeaderKey.SP_RATING],
    key: TableHeaderKey.SP_RATING,
  },
  {
    name: translatedHeaders[TableHeaderKey.MOODYS_RATING],
    keys: [TableHeaderKey.MOODYS_RATING],
    key: TableHeaderKey.MOODYS_RATING,
  },
  {
    name: translatedHeaders[TableHeaderKey.FITCH_RATING],
    keys: [TableHeaderKey.FITCH_RATING],
    key: TableHeaderKey.FITCH_RATING,
  },
  {
    name: translatedHeaders[TableHeaderKey.DBRS_RATING],
    keys: [TableHeaderKey.DBRS_RATING],
    key: TableHeaderKey.DBRS_RATING,
  },
  {
    name: translatedHeaders[TableHeaderKey.SCORE_PIT],
    keys: [TableHeaderKey.SCORE_PIT],
    key: TableHeaderKey.SCORE_PIT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.RAILZ_RATING],
    keys: [TableHeaderKey.RAILZ_RATING],
    key: TableHeaderKey.RAILZ_RATING,
  },
];
export const headerAccountingTransactions: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.TRANSACTION_ID],
    keys: [TableHeaderKey.TRANSACTION_ID],
    key: TableHeaderKey.TRANSACTION_ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.TYPE],
    keys: [TableHeaderKey.TYPE],
    key: TableHeaderKey.TYPE,
    type: DataType.ACC_TRANSACTION_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_CUSTOMER_NAME],
    keys: [TableHeaderKey.ENTITY_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_CUSTOMER_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT],
    keys: [TableHeaderKey.ACCOUNT_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.ACCOUNT,
  },
  {
    name: translatedHeaders[TableHeaderKey.PARENT_TRANSACTION_ID],
    keys: [TableHeaderKey.PARENT_TRANSACTION_ID],
    key: TableHeaderKey.PARENT_TRANSACTION_ID,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_TOTAL],
    keys: [TableHeaderKey.SUB_TOTAL],
    key: TableHeaderKey.SUB_TOTAL,
    type: DataType.NUMBER,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.TAX],
    keys: [TableHeaderKey.TAX_AMOUNT],
    key: TableHeaderKey.TAX,
    type: DataType.NUMBER,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL],
    keys: [TableHeaderKey.TOTAL_AMOUNT],
    key: TableHeaderKey.TOTAL,
    type: DataType.NUMBER,
    decimals: 2,
  },
];
export const headerArAging: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.END_DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER_NAME],
    keys: [TableHeaderKey.CUSTOMER_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.CUSTOMER_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENT],
    keys: [TableHeaderKey.CURRENT],
    key: TableHeaderKey.CURRENT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_30],
    keys: [TableHeaderKey.DPD_30],
    key: TableHeaderKey.DPD_30,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_60],
    keys: [TableHeaderKey.DPD_60],
    key: TableHeaderKey.DPD_60,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_90],
    keys: [TableHeaderKey.DPD_90],
    key: TableHeaderKey.DPD_90,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_90_PLUS],
    keys: [TableHeaderKey.DPD_90_PLUS],
    key: TableHeaderKey.DPD_90_PLUS,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_PAST_DUE],
    keys: [TableHeaderKey.TOTAL],
    key: TableHeaderKey.TOTAL,
    type: DataType.NUMBER,
  },
];

export const headerApAging: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.END_DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_NAME],
    keys: [TableHeaderKey.VENDOR_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.ID],
    key: TableHeaderKey.CURRENCY,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENT],
    keys: [TableHeaderKey.CURRENT],
    key: TableHeaderKey.CURRENT,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_30],
    keys: [TableHeaderKey.DPD_30],
    key: TableHeaderKey.DPD_30,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_60],
    keys: [TableHeaderKey.DPD_60],
    key: TableHeaderKey.DPD_60,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_90],
    keys: [TableHeaderKey.DPD_90],
    key: TableHeaderKey.DPD_90,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.DPD_90_PLUS],
    keys: [TableHeaderKey.DPD_90_PLUS],
    key: TableHeaderKey.DPD_90_PLUS,
    type: DataType.NUMBER,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_PAST_DUE],
    keys: [TableHeaderKey.TOTAL],
    key: TableHeaderKey.TOTAL,
    type: DataType.NUMBER,
  },
];
export const headerVendors: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.VENDOR_NAME],
    keys: [TableHeaderKey.VENDOR_NAME],
    key: TableHeaderKey.VENDOR_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.CONTACT_NAME],
    keys: [TableHeaderKey.CONTACT_NAME],
    key: TableHeaderKey.CONTACT_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.EMAIL_ADDRESS],
    keys: [TableHeaderKey.EMAIL_ADDRESS],
    key: TableHeaderKey.EMAIL_ADDRESS,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.PHONE],
    keys: [TableHeaderKey.PHONE],
    key: TableHeaderKey.PHONE,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.TAX_NUMBER],
    keys: [TableHeaderKey.TAX_NUMBER],
    key: TableHeaderKey.TAX_NUMBER,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.BALANCE],
    keys: [TableHeaderKey.BALANCE],
    key: TableHeaderKey.BALANCE,
    type: DataType.NUMBER,
  },
];
export const headerCustomers: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.CUSTOMER_NAME],
    keys: [TableHeaderKey.CUSTOMER_NAME],
    key: TableHeaderKey.CUSTOMER_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.CONTACT_NAME],
    keys: [TableHeaderKey.CONTACT_NAME],
    key: TableHeaderKey.CONTACT_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.EMAIL_ADDRESS],
    keys: [TableHeaderKey.EMAIL_ADDRESS],
    key: TableHeaderKey.EMAIL_ADDRESS,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.PHONE],
    keys: [TableHeaderKey.PHONE],
    key: TableHeaderKey.PHONE,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.TAX_NUMBER],
    keys: [TableHeaderKey.TAX_NUMBER],
    key: TableHeaderKey.TAX_NUMBER,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.BALANCE],
    keys: [TableHeaderKey.BALANCE],
    key: TableHeaderKey.BALANCE,
    type: DataType.NUMBER,
  },
];
export const headerTaxRates: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.NAME],
    keys: [TableHeaderKey.NAME],
    key: TableHeaderKey.NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.CODE],
    keys: [TableHeaderKey.CODE],
    key: TableHeaderKey.CODE,
  },
  {
    name: translatedHeaders[TableHeaderKey.EFFECTIVE_TAX_RATE],
    keys: [TableHeaderKey.EFFECTIVE_TAX_RATE],
    key: TableHeaderKey.EFFECTIVE_TAX_RATE,
    sort: false,
    type: DataType.NUMBER,
    decimals: 3,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_TAX_RATE],
    keys: [TableHeaderKey.TOTAL_TAX_RATE],
    key: TableHeaderKey.TOTAL_TAX_RATE,
    sort: false,
    type: DataType.NUMBER,
    decimals: 3,
  },
];
export const headerInventory: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.NAME],
    keys: [TableHeaderKey.NAME],
    key: TableHeaderKey.NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.CODE],
    keys: [TableHeaderKey.CODE],
    key: TableHeaderKey.CODE,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.INVENTORY_STATUS],
    key: TableHeaderKey.STATUS,
  },
];
export const headerBusinessInfo: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.BUSINESS_ATTRIBUTE],
    keys: [TableHeaderKey.BUSINESS_ATTRIBUTE],
    key: TableHeaderKey.BUSINESS_ATTRIBUTE,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.VALUE],
    keys: [TableHeaderKey.VALUE],
    key: TableHeaderKey.VALUE,
    style: { whiteSpace: 'pre' },
    sort: false,
  },
];
export const headerFinancialRatios: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.END_DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.RATIO_TYPE],
    keys: [TableHeaderKey.RATIO_TYPE],
    key: TableHeaderKey.RATIO_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.RATIO],
    keys: [TableHeaderKey.RATIO],
    key: TableHeaderKey.RATIO,
  },
  {
    name: translatedHeaders[TableHeaderKey.VALUE],
    keys: [TableHeaderKey.RATIO_VALUE],
    key: TableHeaderKey.RATIO_VALUE,
  },
  {
    name: '',
    keys: ['KEY'],
    key: 'BLANK',
  },
];

export const headerPaymentMethods: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.UPDATED_AT],
    keys: [TableHeaderKey.SOURCE_MODIFIED_DATE],
    key: TableHeaderKey.SOURCE_MODIFIED_DATE,
    type: DataType.SOURCE_MODIFIED_DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.NAME],
    keys: [TableHeaderKey.NAME],
    key: TableHeaderKey.NAME,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.TYPE],
    keys: [TableHeaderKey.TYPE],
    key: TableHeaderKey.TYPE,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.PAYMENT_METHODS_STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    sort: true,
    type: DataType.PAYMENT_METHODS_STATUS,
  },
];

export const headerAttachments: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.UPLOAD_DATE],
    keys: [TableHeaderKey.UPLOAD_DATE],
    key: TableHeaderKey.UPLOAD_DATE,
    type: DataType.UPLOAD_DATE,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.SOURCE_MODIFIED_DATE],
    keys: [TableHeaderKey.SOURCE_MODIFIED_DATE],
    key: TableHeaderKey.SOURCE_MODIFIED_DATE,
    type: DataType.DATE,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.ID],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.FILE_NAME],
    keys: [TableHeaderKey.FILE_NAME],
    key: TableHeaderKey.FILE_NAME,
    type: DataType.FILE_NAME,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.CONTENT_TYPE],
    keys: [TableHeaderKey.CONTENT_TYPE],
    key: TableHeaderKey.CONTENT_TYPE,
    type: DataType.CONTENT_TYPE,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.FILE_SIZE],
    keys: [TableHeaderKey.FILE_SIZE],
    key: TableHeaderKey.FILE_SIZE,
    type: DataType.FILE_NAME,
    sort: true,
  },
];

export const headerBankTransfers: Array<ITableField> = [
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSFER_TABLE_DATE'),
    keys: [TableHeaderKey.DATE],
    key: TableHeaderKey.DATE,
    type: DataType.DATE,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSFER_TABLE_HEADER_ID'),
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSFER_TABLE_TOTAL_AMOUNT'),
    keys: [TableHeaderKey.TOTAL_AMOUNT],
    key: TableHeaderKey.TOTAL,
    type: DataType.CURRENCY,
    decimals: 2,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSFER_TABLE_ACCOUNT_FROM'),
    keys: [TableHeaderKey.FROM_ACCOUNT, TableHeaderKey.NAME],
    key: TableHeaderKey.FROM_ACCOUNT,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSFER_TABLE_ACCOUNT_TO'),
    keys: [TableHeaderKey.TO_ACCOUNT, TableHeaderKey.NAME],
    key: TableHeaderKey.TO_ACCOUNT,
  },
];

export const headerAccountingBankTransactions: Array<ITableField> = [
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSACTIONS_TABLE_POSTED_DATE'),
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSACTIONS_TABLE_ID'),
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSACTIONS_TABLE_TRANSACTION_TYPE'),
    keys: [TableHeaderKey.TRANSACTION_TYPE],
    key: TableHeaderKey.TRANSACTION_TYPE,
    type: DataType.ACC_TRANSACTION_TYPE,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSACTIONS_TABLE_BANK_NAME'),
    keys: [TableHeaderKey.BANK_ACCOUNT_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.BANK_ACCOUNT,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSACTIONS_TABLE_TOTAL_AMOUNT'),
    keys: [TableHeaderKey.TOTAL_AMOUNT],
    key: TableHeaderKey.TOTAL_AMOUNT,
    type: DataType.CURRENCY,
    decimals: 2,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_TRANSACTIONS_TABLE_CURRENCY'),
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
];

export const headerBankAccounts: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT_ID],
    keys: [TableHeaderKey.ACCOUNT_ID],
    key: TableHeaderKey.ACCOUNT_ID,
  },
  {
    name: translatedHeaders[TableHeaderKey.INSTITUTION],
    keys: [TableHeaderKey.INSTITUTION],
    key: TableHeaderKey.INSTITUTION,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK_ACCOUNT],
    keys: [TableHeaderKey.ACCOUNT_NAME],
    key: TableHeaderKey.ACCOUNT_NAME,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT_TYPE],
    keys: [TableHeaderKey.ACCOUNT_TYPE],
    key: TableHeaderKey.ACCOUNT_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT_SUB_TYPE],
    keys: [TableHeaderKey.ACCOUNT_SUB_TYPE],
    key: TableHeaderKey.ACCOUNT_SUB_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.BALANCE],
    keys: [TableHeaderKey.CURRENT_BALANCE],
    key: TableHeaderKey.CURRENT_BALANCE,
    type: DataType.NUMBER,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.LIMIT],
    keys: [TableHeaderKey.LIMIT],
    key: TableHeaderKey.LIMIT,
    type: DataType.NUMBER,
    decimals: 2,
    sort: false,
  },
];

export const headerAccountingBankAccounts: Array<ITableField> = [
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_ACCOUNTS_TABLE_ID'),
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_ACCOUNTS_TABLE_INST_NAME'),
    keys: [TableHeaderKey.INSTITUTION_NAME_AND_BANK_CODE],
    key: TableHeaderKey.INSTITUTION,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_ACCOUNTS_TABLE_ACCOUNT_NAME'),
    keys: [TableHeaderKey.NAME],
    key: TableHeaderKey.NAME,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_ACCOUNTS_TABLE_ACCOUNT_TYPE'),
    keys: [TableHeaderKey.ACCOUNT_TYPE],
    key: TableHeaderKey.ACCOUNT_TYPE,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_ACCOUNTS_TABLE_ACCOUNT_NUMBER'),
    keys: [TableHeaderKey.ACCOUNT_NUMBER],
    key: TableHeaderKey.ACCOUNT_NUMBER,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_ACCOUNTS_TABLE_CURRENT_BALANCE'),
    keys: [TableHeaderKey.CURRENT_BALANCE],
    key: TableHeaderKey.CURRENT_BALANCE,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_BANK_ACCOUNTS_TABLE_CURRENCY'),
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
  },
];

export const headerExpenses: Array<ITableField> = [
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_EXPENSES_TABLE_ID'),
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
    sort: false,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_EXPENSES_TABLE_POSTED_DATE'),
    keys: [TableHeaderKey.POSTED_DATE],
    key: TableHeaderKey.POSTED_DATE,
    type: DataType.DATE,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_EXPENSES_TABLE_VENDOR_NAME'),
    keys: [TableHeaderKey.VENDOR_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.VENDOR_REF,
    sort: false,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_EXPENSES_TABLE_ACCOUNT_NAME'),
    keys: [TableHeaderKey.ACCOUNT_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.ACCOUNT_REF,
    sort: false,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_EXPENSES_TABLE_TOTAL_AMOUNT'),
    keys: [TableHeaderKey.TOTAL_AMOUNT],
    key: TableHeaderKey.TOTAL_AMOUNT,
    sort: true,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_EXPENSES_TABLE_PAYMENT_METHOD'),
    keys: [TableHeaderKey.PAYMENT_METHOD_REF, TableHeaderKey.NAME],
    key: TableHeaderKey.PAYMENT_METHOD,
    type: DataType.STRING_WITH_DASH_FALLBACK,
  },
  {
    name: i18n.t('DASHBOARD_ENUM_REPORT_ACCOUNTING_EXPENSES_TABLE_CURRENCY'),
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
  },
];

export const headerBankingReconciliation: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.END_DATE],
    key: TableHeaderKey.END_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK_TRANSACTIONS_COUNT],
    keys: [TableHeaderKey.BANK_TRANSACTIONS_COUNT],
    key: TableHeaderKey.BANK_TRANSACTIONS_COUNT,
    type: DataType.NUMBER,
    decimals: 0,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNTING_TRANSACTIONS_COUNT],
    keys: [TableHeaderKey.ACCOUNTING_TRANSACTIONS_COUNT],
    key: TableHeaderKey.ACCOUNTING_TRANSACTIONS_COUNT,
    type: DataType.NUMBER,
    decimals: 0,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK_TRANSACTIONS_AMOUNT],
    keys: [TableHeaderKey.BANK_TRANSACTIONS_AMOUNT],
    key: TableHeaderKey.BANK_TRANSACTIONS_AMOUNT,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNTING_TRANSACTIONS_AMOUNT],
    keys: [TableHeaderKey.ACCOUNTING_TRANSACTIONS_AMOUNT],
    key: TableHeaderKey.ACCOUNTING_TRANSACTIONS_AMOUNT,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK_TRANSACTIONS_TOTAL_VALUE],
    keys: [TableHeaderKey.BANK_TRANSACTIONS_TOTAL_VALUE],
    key: TableHeaderKey.BANK_TRANSACTIONS_TOTAL_VALUE,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNTING_TRANSACTIONS_TOTAL_VALUE],
    keys: [TableHeaderKey.ACCOUNTING_TRANSACTIONS_TOTAL_VALUE],
    key: TableHeaderKey.ACCOUNTING_TRANSACTIONS_TOTAL_VALUE,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.WEIGHTED_PERCENTAGE_ERROR],
    keys: [TableHeaderKey.WEIGHTED_PERCENTAGE_ERROR],
    key: TableHeaderKey.WEIGHTED_PERCENTAGE_ERROR,
    type: DataType.PERCENTAGE,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCURACY_SCORE],
    keys: [TableHeaderKey.ACCURACY_SCORE],
    key: TableHeaderKey.ACCURACY_SCORE,
    type: DataType.PERCENTAGE_OVER_100,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK_TRANSACTIONS_UNRECONCILED_COUNT],
    keys: [TableHeaderKey.BANK_TRANSACTIONS_UNRECONCILED_COUNT],
    key: TableHeaderKey.BANK_TRANSACTIONS_UNRECONCILED_COUNT,
    type: DataType.NUMBER,
    decimals: 0,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK_TRANSACTIONS_UNRECONCILED_AMOUNT],
    keys: [TableHeaderKey.BANK_TRANSACTIONS_UNRECONCILED_AMOUNT],
    key: TableHeaderKey.BANK_TRANSACTIONS_UNRECONCILED_AMOUNT,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.DAILY_ACCURACY_SCORE],
    keys: [TableHeaderKey.DAILY_ACCURACY_SCORE],
    key: TableHeaderKey.DAILY_ACCURACY_SCORE,
    type: DataType.PERCENTAGE_OVER_100,
    decimals: 2,
  },
];

export const headerBusinessBankReconciliation: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.DATE],
    key: TableHeaderKey.DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.SERVICE_NAME],
    keys: [TableHeaderKey.SERVICE_NAME],
    key: TableHeaderKey.SERVICE_NAME,
    style: { fontWeight: '700' },
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNTING_TRANSACTIONS_TYPE],
    keys: [TableHeaderKey.ACCOUNTING_TRANSACTIONS_TYPE],
    key: TableHeaderKey.ACCOUNTING_TRANSACTIONS_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNTING_TRANSACTIONS_AMOUNT],
    keys: [TableHeaderKey.ACCOUNTING_TRANSACTIONS_AMOUNT],
    key: TableHeaderKey.ACCOUNTING_TRANSACTIONS_AMOUNT,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: '',
    keys: ['bankIcon'],
    key: 'bankIcon',
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK],
    keys: [TableHeaderKey.INSTITUTION],
    key: TableHeaderKey.INSTITUTION,
    style: { fontWeight: '700' },
  },
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT],
    keys: [TableHeaderKey.ACCOUNT_TYPE],
    key: TableHeaderKey.ACCOUNT_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DESCRIPTION],
    keys: [TableHeaderKey.DESCRIPTION],
    key: TableHeaderKey.DESCRIPTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.BANK_TRANSACTIONS_AMOUNT],
    keys: [TableHeaderKey.AMOUNT_VALUE],
    key: TableHeaderKey.AMOUNT_VALUE,
    type: DataType.CURRENCY,
    decimals: 2,
  },
  {
    name: translatedHeaders[TableHeaderKey.MATCHED],
    keys: [TableHeaderKey.MATCHED],
    key: TableHeaderKey.MATCHED,
  },
];

export const headerBankTransactions: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.DATE],
    key: TableHeaderKey.DATE,
    type: DataType.DATE,
  },
  {
    name: i18n.t(`DASHBOARD_ENUM_REPORT_TABLE_HEADER_BANK_TRANSACTIONS_ACCOUNT_TYPE`),
    keys: [TableHeaderKey.ACCOUNT_TYPE],
    key: TableHeaderKey.ACCOUNT_TYPE,
    type: DataType.CAPITALIZED_STRING,
    sort: true,
  },
  {
    name: i18n.t(`DASHBOARD_ENUM_REPORT_TABLE_HEADER_BANK_TRANSACTIONS_ACCOUNT_SUB_TYPE`),
    keys: [TableHeaderKey.ACCOUNT_SUB_TYPE],
    key: TableHeaderKey.ACCOUNT_SUB_TYPE,
    type: DataType.CAPITALIZED_STRING,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.SECTION],
    keys: [TableHeaderKey.SECTION],
    key: TableHeaderKey.SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_SECTION],
    keys: [TableHeaderKey.SUB_SECTION],
    key: TableHeaderKey.SUB_SECTION,
  },
  {
    name: translatedHeaders[TableHeaderKey.GROUP],
    keys: [TableHeaderKey.GROUP],
    key: TableHeaderKey.GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.SUB_GROUP],
    keys: [TableHeaderKey.SUB_GROUP],
    key: TableHeaderKey.SUB_GROUP,
  },
  {
    name: translatedHeaders[TableHeaderKey.CATEGORIES],
    keys: [TableHeaderKey.CATEGORIES],
    key: TableHeaderKey.CATEGORIES,
    type: DataType.ARRAY,
  },
  {
    name: translatedHeaders[TableHeaderKey.DESCRIPTION],
    keys: [TableHeaderKey.DESCRIPTION],
    key: TableHeaderKey.DESCRIPTION,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.TRANSACTION_TYPE],
    keys: [TableHeaderKey.TRANSACTION_TYPE],
    key: TableHeaderKey.TRANSACTION_TYPE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.AMOUNT_VALUE],
    keys: [TableHeaderKey.AMOUNT_VALUE],
    key: TableHeaderKey.AMOUNT_VALUE,
    type: DataType.NUMBER,
    decimals: 2,
  },
];

export const headerBankAssets: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.ACCOUNT_ID],
    keys: [TableHeaderKey.ACCOUNT_ID],
    key: TableHeaderKey.ACCOUNT_ID,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.AVAILABLE_BALANCE],
    keys: [TableHeaderKey.AVAILABLE_BALANCE],
    key: TableHeaderKey.AVAILABLE_BALANCE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENT_BALANCE],
    keys: [TableHeaderKey.CURRENT_BALANCE],
    key: TableHeaderKey.CURRENT_BALANCE,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.LIMIT],
    keys: [TableHeaderKey.LIMIT],
    key: TableHeaderKey.LIMIT,
  },
];

export const headerCommerceProducts: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.CREATED_DATE],
    key: TableHeaderKey.CREATED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.PRODUCT_IDENTIFIER],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.CATEGORY],
    keys: [TableHeaderKey.TYPE],
    key: TableHeaderKey.TYPE,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.NAME],
    keys: [TableHeaderKey.NAME],
    key: TableHeaderKey.NAME,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    sort: true,
    type: DataType.PRODUCT_STATUS,
  },
];

export const headerCommerceDisputes: Array<ITableField> = [
  {
    name: translatedHeaders[TableHeaderKey.DATE],
    keys: [TableHeaderKey.CREATED_DATE],
    key: TableHeaderKey.CREATED_DATE,
    type: DataType.DATE,
  },
  {
    name: translatedHeaders[TableHeaderKey.DISPUTE_IDENTIFIER],
    keys: [TableHeaderKey.ID],
    key: TableHeaderKey.ID,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.ORDER_NO],
    keys: [TableHeaderKey.ORDER_REF, TableHeaderKey.ID],
    key: TableHeaderKey.ORDER_NO,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.TOTAL_AMOUNT],
    keys: [TableHeaderKey.TOTAL_AMOUNT],
    key: TableHeaderKey.TOTAL_AMOUNT,
    decimals: 2,
    type: DataType.CURRENCY,
    sort: true,
  },
  {
    name: translatedHeaders[TableHeaderKey.CURRENCY],
    keys: [TableHeaderKey.CURRENCY_REF, TableHeaderKey.SYMBOL],
    key: TableHeaderKey.CURRENCY,
    sort: false,
  },
  {
    name: translatedHeaders[TableHeaderKey.STATUS],
    keys: [TableHeaderKey.STATUS],
    key: TableHeaderKey.STATUS,
    sort: true,
    type: DataType.DISPUTE_STATUS,
  },
  {
    name: translatedHeaders[TableHeaderKey.DISPUTE_REASON],
    keys: [TableHeaderKey.REASON],
    key: TableHeaderKey.REASON,
    sort: true,
    type: DataType.DISPUTE_REASON,
  },
];

export const ReportTypeUrlMapping = {
  // analytics
  [ReportType.FINANCIAL_BENCHMARKINGS]: 'v2/analytics/financialBenchmarking',
  [ReportType.FINANCIAL_FORECASTS]: 'v2/analytics/financialForecasts',
  [ReportType.FRAUD_RISK_METRICS]: 'v2/analytics/fraudRiskMetrics',
  [ReportType.TAX_BENCHMARKINGS]: 'v2/analytics/taxBenchmarking',
  [ReportType.BUSINESS_VALUATIONS]: 'v2/analytics/businessValuations',
  [ReportType.PROBABILITY_OF_DEFAULT]: 'v2/analytics/probabilityOfDefault',
  [ReportType.BANK_RECONCILIATION]: 'v2/analytics/bankReconciliation',
  [ReportType.BANKING_RECONCILIATION]: 'v2/analytics/bankReconciliation',
  [ReportType.BUSINESS_BANK_RECONCILIATION]: 'v2/analytics/businessBankReconciliation',
  [ReportType.FINANCIAL_RATIOS]: 'v2/analytics/financialRatios',
  // banking
  [ReportType.BANK_ACCOUNTS]: 'v2/banking/bankAccounts',
  [ReportType.BANK_TRANSACTIONS]: 'v2/banking/bankTransactions',
  [ReportType.BANK_ASSET]: 'v2/banking/bankAssets',
  // accounting
  [ReportType.ACCOUNTING_BANK_TRANSACTIONS]: 'v2/accounting/bankTransactions',
  [ReportType.ACCOUNTING_BANK_ACCOUNTS]: 'v2/accounting/bankAccounts',
  [ReportType.PAYMENT_METHODS]: 'v2/accounting/paymentMethods',
  [ReportType.ACCOUNTS]: 'v2/accounting/accounts',
  [ReportType.ATTACHMENTS]: 'v2/accounting/attachments',
  [ReportType.JOURNALS]: 'v2/accounting/journals',
  [ReportType.ACCOUNTING_TRANSACTIONS]: 'v2/accounting/accountingTransactions',
  [ReportType.CREDITS]: 'v2/visualizations/credits',
  [ReportType.AGED_PAYABLE]: 'v2/accounting/apAging',
  [ReportType.AGED_RECEIVABLE]: 'v2/accounting/arAging',
  [ReportType.BANK_TRANSFER]: 'v2/accounting/bankTransfers',
  [ReportType.BALANCE_SHEETS]: 'v2/accounting/balanceSheets',
  [ReportType.BILLS]: 'v2/accounting/bills',
  [ReportType.BILL_CREDIT_NOTES]: 'v2/accounting/bills/creditNotes',
  [ReportType.BILL_PAYMENTS]: 'v2/accounting/bills/payments',
  [ReportType.BILL_PAYMENT_REQUEST]: 'v2/accounting/bills/paymentRequests',
  [ReportType.BUSINESS_INFO]: 'v2/businesses/info',
  [ReportType.CASHFLOW_STATEMENTS]: 'v2/accounting/cashFlowStatements',
  [ReportType.EXPENSES]: 'v2/accounting/expenses',
  [ReportType.CUSTOMERS]: 'v2/accounting/customers',
  [ReportType.INCOME_STATEMENTS]: 'v2/accounting/incomeStatements',
  [ReportType.INVENTORY]: 'v2/accounting/inventory',
  [ReportType.INVOICES]: 'v2/accounting/invoices',
  [ReportType.INVOICE_CREDIT_NOTES]: 'v2/accounting/invoices/creditNotes',
  [ReportType.INVOICE_PAYMENTS]: 'v2/accounting/invoices/payments',
  [ReportType.DEPOSITS]: 'v2/accounting/deposits',
  [ReportType.EMPLOYEES]: 'v2/accounting/employees',
  [ReportType.ESTIMATES]: 'v2/accounting/estimates',
  [ReportType.JOURNAL_ENTRIES]: 'v2/accounting/journalEntries',
  [ReportType.PURCHASE_ORDERS]: 'v2/accounting/purchaseOrders',
  [ReportType.REFUNDS]: 'v2/accounting/refunds',
  [ReportType.TAX_RATES]: 'v2/accounting/taxRates',
  [ReportType.TRIAL_BALANCES]: 'v2/accounting/trialBalances',
  [ReportType.TRACKING_CATEGORIES]: 'v2/accounting/trackingCategories',
  [ReportType.VENDORS]: 'v2/accounting/vendors',
  // commerce
  [ReportType.TRANSACTIONS]: 'v2/commerce/transactions',
  [ReportType.ORDERS]: 'v2/commerce/orders',
  [ReportType.PRODUCTS]: 'v2/commerce/products',
  [ReportType.DISPUTES]: 'v2/commerce/disputes',
};

export const reportFrequencyValues = generateDropdown(ReportFrequency);
export const reportAccountingMethods = generateDropdown(ReportAccountingMethod);
export const reportStatusValues = generateDropdown(ReportStatus);
export const reportFinancialStatementTypes = generateDropdown(ReportFinancialStatementType);

export enum ExportFileType {
  pdf = 'pdf',
  xlsx = 'xlsx',
  csv = 'csv',
  json = 'json',
}

export const saveOptions: ExportOption[] = [
  {
    type: ExportFileType.pdf,
    value: 'DASHBOARD_REPORT_CTA_SAVE_PDF',
  },
  {
    type: ExportFileType.json,
    value: 'DASHBOARD_REPORT_CTA_SAVE_JSON',
  },
  {
    type: ExportFileType.xlsx,
    value: 'DASHBOARD_REPORT_CTA_SAVE_XLSX',
  },
  {
    type: ExportFileType.csv,
    value: 'DASHBOARD_REPORT_CTA_SAVE_CSV',
  },
];

export const getHeaderTable = (type: ReportType | string): Array<ITableField> => {
  switch (type) {
    case ReportType.CREDITS:
      return headerCredits;
    case ReportType.ACCOUNTING_TRANSACTIONS:
      return headerAccountingTransactions;
    case ReportType.ACCOUNTS:
      return headerAccounts;
    case ReportType.BANK_ACCOUNTS:
      return headerBankAccounts;
    case ReportType.ACCOUNTING_BANK_ACCOUNTS:
      return headerAccountingBankAccounts;
    case ReportType.BANKING_RECONCILIATION:
      return headerBankingReconciliation;
    case ReportType.BUSINESS_BANK_RECONCILIATION:
      return headerBusinessBankReconciliation;
    case ReportType.BANK_TRANSACTIONS:
      return headerBankTransactions;
    case ReportType.ACCOUNTING_BANK_TRANSACTIONS:
      return headerAccountingBankTransactions;
    case ReportType.BANK_TRANSFER:
      return headerBankTransfers;
    case ReportType.AGED_PAYABLE:
      return headerApAging;
    case ReportType.AGED_RECEIVABLE:
      return headerArAging;
    case ReportType.BILLS:
      return headerBill;
    case ReportType.BILL_PAYMENTS:
      return headerBillPayments;
    case ReportType.BILL_PAYMENT_REQUEST:
      return headerBillPaymentRequests;
    case ReportType.BILL_CREDIT_NOTES:
      return headerBillCreditNotes;
    case ReportType.INVOICE_PAYMENTS:
      return headerInvoicePayments;
    case ReportType.BUSINESS_INFO:
      return headerBusinessInfo;
    case ReportType.BUSINESS_VALUATIONS:
      return headerBusinessValuations;
    case ReportType.CASHFLOW_STATEMENTS:
      return headerCashFlow;
    case ReportType.CUSTOMERS:
      return headerCustomers;
    case ReportType.DEPOSITS:
      return headerDeposits;
    case ReportType.EMPLOYEES:
      return headerEmployees;
    case ReportType.ESTIMATES:
      return headerEstimates;
    case ReportType.EXPENSES:
      return headerExpenses;
    case ReportType.FINANCIAL_RATIOS:
      return headerFinancialRatios;
    case ReportType.INVENTORY:
      return headerInventory;
    case ReportType.INVOICES:
      return headerInvoice;
    case ReportType.JOURNAL_ENTRIES:
      return headerJournalEntries;
    case ReportType.INVOICE_CREDIT_NOTES:
      return headerInvoiceCreditNotes;
    case ReportType.PAYMENT_METHODS:
      return headerPaymentMethods;
    case ReportType.ATTACHMENTS:
      return headerAttachments;
    case ReportType.PROBABILITY_OF_DEFAULT:
      return headerProbabilityOfDefault;
    case ReportType.PURCHASE_ORDERS:
      return headerPurchaseOrders;
    case ReportType.REFUNDS:
      return headerRefunds;
    case ReportType.TAX_RATES:
      return headerTaxRates;
    case ReportType.TRANSACTIONS:
      return headerTransaction;
    case ReportType.ORDERS:
      return headerCommerceOrder;
    case ReportType.TRIAL_BALANCES:
      return headerTrialBalance;
    case ReportType.TRACKING_CATEGORIES:
      return headerTrackingCategories;
    case ReportType.VENDORS:
      return headerVendors;
    case ReportType.PRODUCTS:
      return headerCommerceProducts;
    case ReportType.DISPUTES:
      return headerCommerceDisputes;
    case ReportType.FINANCIAL_FORECASTS:
      return headerFinancialForecasts;
    case ReportType.BANK_ASSET:
      return headerBankAssets;
    default:
      return headerDefault;
  }
};

export const getTableSortKey = (type: ReportType | string): TableHeaderKey => {
  switch (type) {
    case ReportType.VENDORS:
      return TableHeaderKey.VENDOR_NAME;
    case ReportType.CUSTOMERS:
      return TableHeaderKey.CUSTOMER_NAME;
    case ReportType.TAX_RATES:
    case ReportType.INVENTORY:
    case ReportType.ACCOUNTS:
    case ReportType.TRACKING_CATEGORIES:
      return TableHeaderKey.NAME;
    case ReportType.FINANCIAL_RATIOS:
      return TableHeaderKey.RATIO;
    case ReportType.REFUNDS:
    case ReportType.TRANSACTIONS:
    case ReportType.ORDERS:
      return TableHeaderKey.CREATED_DATE;
    case ReportType.BILL_PAYMENTS:
    case ReportType.BILL_PAYMENT_REQUEST:
      return TableHeaderKey.PAYMENT_DATE;
    case ReportType.BUSINESS_INFO:
    case ReportType.BANK_ASSET:
      return null;
    case ReportType.EMPLOYEES:
      return TableHeaderKey.EMPLOYEE_NAME;
    case ReportType.BILL_CREDIT_NOTES:
    case ReportType.INVOICE_CREDIT_NOTES:
    case ReportType.DEPOSITS:
    case ReportType.ESTIMATES:
    case ReportType.JOURNAL_ENTRIES:
    case ReportType.PURCHASE_ORDERS:
    case ReportType.BILLS:
    case ReportType.INVOICES:
    case ReportType.EXPENSES:
    case ReportType.ACCOUNTING_BANK_TRANSACTIONS:
      return TableHeaderKey.POSTED_DATE;
    case ReportType.ACCOUNTING_TRANSACTIONS:
    case ReportType.ACCOUNTING_BANK_ACCOUNTS:
      return TableHeaderKey.ID;
    case ReportType.BANK_ACCOUNTS:
      return TableHeaderKey.ACCOUNT_NAME;
    case ReportType.INVOICE_PAYMENTS:
    case ReportType.PROBABILITY_OF_DEFAULT:
    case ReportType.BANK_TRANSACTIONS:
    case ReportType.BUSINESS_BANK_RECONCILIATION:
    case ReportType.BANK_TRANSFER:
      return TableHeaderKey.DATE;
    case ReportType.PRODUCTS:
    case ReportType.DISPUTES:
      return TableHeaderKey.CREATED_DATE;
    case ReportType.ATTACHMENTS:
      return TableHeaderKey.UPLOAD_DATE;
    case ReportType.PAYMENT_METHODS:
      return TableHeaderKey.SOURCE_MODIFIED_DATE;
    default:
      return TableHeaderKey.END_DATE;
  }
};

/** Returns the sorting order for the given report type key. */
export const getDefaultTableSortOrder = (sortKey: TableHeaderKey): 'asc' | 'desc' => {
  const keysWithDescendingSortOrderByDefault = [
    TableHeaderKey.CREATED_DATE,
    TableHeaderKey.END_DATE,
    TableHeaderKey.DATE,
    TableHeaderKey.POSTED_DATE,
    TableHeaderKey.PAYMENT_DATE,
    TableHeaderKey.TRANSACTION_ID,
    TableHeaderKey.ACCOUNT_NAME,
    TableHeaderKey.UPLOAD_DATE,
    TableHeaderKey.SOURCE_MODIFIED_DATE,
  ];

  return keysWithDescendingSortOrderByDefault.includes(sortKey) ? 'desc' : 'asc';
};

export const reportTypesList: Array<DropDownMap> = (generateDropdown(ReportType) || []).sort(
  (a, b) => descendingComparator(b, a, 'name'),
);

export const acceptedAspList = Object.keys(AspType).reduce((parent, current) => {
  parent[AspType[current]] = reportTypesList.filter((report) => {
    const serviceType = ASP_SERVICE_TYPE[current.toLowerCase() as AspType];
    const reportType = report.value;

    return Object.values(ReportTypeDataTypeMapping).some(
      (dataMapping) => dataMapping.value === reportType && dataMapping.serviceType === serviceType,
    );
  });

  return parent;
}, {});
