import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { debounce } from 'lodash';

import useStyle from '../../style';
import { Business } from '../../../business/types/interfaces';
import {
  fetchBusinessSuggestions,
  resetBusinessSuggestions,
  setSelectedBusiness,
} from '../../../../store/features/business/business.action';
import {
  getBusinesses,
  getBusinessSuggestions,
  getSelectedBusiness,
  isBusinessLoading,
} from '../../../../store/features/business/business.selector';

import { BusinessStatus } from '../../../../pages/business/types/enums';

import storeService from 'store';

interface Props {
  errorBusiness: string;
  defaultSelectedBusiness?: Business;
}

const validStatusToShow = [BusinessStatus.ACTIVE, BusinessStatus.INACTIVE];
const isActiveOrInactive = ({ status }: Business): boolean => validStatusToShow.includes(status);
const hasIdEqualToSelected = (
  { businessUuid }: Business,
  { businessUuid: selectedBusinessUuid }: Business,
): boolean => businessUuid === selectedBusinessUuid;

export default function BusinessName({
  errorBusiness,
  defaultSelectedBusiness,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const store = storeService.getStore();
  const ref = useRef();
  const dispatch = useDispatch();

  const businesses = useSelector<ReturnType<typeof store.getState>, Business[]>(getBusinesses);
  const businessSuggestions = useSelector<ReturnType<typeof store.getState>, Business[]>(
    getBusinessSuggestions,
  );
  const selectedBusiness = useSelector<ReturnType<typeof store.getState>, Business>(
    getSelectedBusiness,
  );
  const isLoading = useSelector<ReturnType<typeof store.getState>, boolean>(isBusinessLoading);

  const [businessName, setBusinessName] = useState('');
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (selectedBusiness?.businessName) {
      handleBusinessNameChange(selectedBusiness.businessName);
    }
    return () => {
      dispatch(resetBusinessSuggestions());
    };
  }, [selectedBusiness?.businessName]);

  const updateFilteredBusiness = (
    businessList: Business[],
    selected = selectedBusiness,
  ): Business[] => {
    const activeBusinesses = businessList.filter(
      (business) => isActiveOrInactive(business) && !hasIdEqualToSelected(business, selected),
    );

    setFilteredBusinesses([selected, ...activeBusinesses]);
    return activeBusinesses;
  };

  const updateSelectedBusiness = (businessName: string): void => {
    const selected = filteredBusinesses?.find((item) => item.businessName === businessName);

    if (initialLoad) {
      setInitialLoad(false);
    } else {
      dispatch(setSelectedBusiness(selected || defaultSelectedBusiness));
    }
  };

  useEffect(() => {
    // get all active businesses for drop down
    if (businesses) {
      updateFilteredBusiness(businesses, selectedBusiness);
    }
  }, [businesses]);

  useEffect(() => {
    // update business name if changed
    if (businessName) {
      updateSelectedBusiness(businessName);
    }
  }, [businessName]);

  useEffect(() => {
    if (businessSuggestions.length) {
      updateFilteredBusiness(businessSuggestions, selectedBusiness);
    } else {
      updateFilteredBusiness(businesses, selectedBusiness);
    }
  }, [businessSuggestions]);

  const handleBusinessNameChange = (businessName: string): void => {
    setBusinessName(businessName);
  };

  const debounceHandleSearch = debounce((event) => {
    if (event.target.value !== selectedBusiness.businessName) {
      setFilteredBusinesses([]);
      dispatch(
        fetchBusinessSuggestions({
          businessName: `%${event.target.value}%`,
          orderBy: `businessName`,
          limit: 25,
          status: [BusinessStatus.ACTIVE, BusinessStatus.INACTIVE],
        }),
      );
    } else {
      dispatch(resetBusinessSuggestions());
    }
  }, 400);

  return (
    <Autocomplete
      options={filteredBusinesses}
      loading={isLoading}
      data-testid={'business-name-autocomplete'}
      value={selectedBusiness}
      classes={{ input: classes.nameText }}
      defaultValue={filteredBusinesses && filteredBusinesses.length > 0 && filteredBusinesses[0]}
      getOptionLabel={(option): string => option?.businessName || ''}
      getOptionSelected={(option, value): boolean => option?.businessName === value?.businessName}
      renderInput={(params): React.ReactElement => (
        <TextField
          {...params}
          fullWidth
          label={t('DASHBOARD_BUSINESS_FINANCIAL_SUMMARY_BUSINESS_LABEL')}
          placeholder={t('DASHBOARD_REPORT_BUSINESS_PLACEHOLDER')}
          margin="normal"
          size="small"
          error={!!errorBusiness}
          helperText={t(errorBusiness)}
          ref={ref}
          className={classes.nameRoot}
          inputProps={{
            ...params.inputProps,
            'data-testid': 'business-name-autocomplete-textfield',
          }}
          FormHelperTextProps={{
            className: 'error-text-custom',
          }}
        />
      )}
      onInputChange={(e: any): any => {
        if (e?.target?.value) {
          debounceHandleSearch(e);
        }
      }}
      onChange={(event: any, newValue: any | null): void =>
        handleBusinessNameChange(newValue?.businessName)
      }
    />
  );
}
