import packageJson from '../../package.json';
/**
 * Obtain the environment from the host location.
 * This should be replaced with an explicit environment variable.
 */
function generateEnvFromHost(locationHostname: string): string {
  if (locationHostname === 'dashboard.railz.ai') return 'production';
  if (locationHostname === 'localhost') return 'development';
  if (locationHostname === '127.0.0.1') return 'development';
  const hostArray = locationHostname.toLowerCase().split('.');

  const environment =
    hostArray[0] === 'dashboard' && hostArray.length === 4 ? hostArray[1] : 'development';
  return environment;
}

const environment = generateEnvFromHost(window?.location?.hostname);
const hostMod = environment === 'production' ? '' : `${environment}.`;

const config = {
  get SENTRY_ENVIRONMENT(): string {
    return environment;
  },
  get VERSION(): string {
    return packageJson?.version;
  },
  get PUBLIC_URL(): string {
    return process.env.PUBLIC_URL;
  },
  get SERVER_URL(): string {
    return process.env.REACT_APP_SERVER_URL || `https://servant.${hostMod}railz.ai`;
  },
  get FEEDER_SERVER(): string {
    return process.env.REACT_APP_FEEDER_URL || `https://api.${hostMod}railz.ai`;
  },
  get WIDGET_SERVER(): string {
    return process.env.REACT_APP_WIDGET_SERVER || `https://widget.${hostMod}railz.ai`;
  },
  get NODE_ENV(): string {
    return process.env.NODE_ENV;
  },
  get IS_PRODUCTION_ENV(): boolean {
    return window?.location?.hostname === 'dashboard.railz.ai';
  },
  get DEBUG(): boolean {
    return process.env.NODE_ENV !== 'production';
  },
  get RECAPTCHA_KEY(): string {
    return process.env.REACT_APP_RECAPTCHA_KEY || '';
  },
  get INTERCOM_APP_ID(): string {
    return process.env.REACT_APP_INTERCOM_APP_ID;
  },
  get GOOGLE_TAG_MANAGER_ID(): string {
    return process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  },
  get SENTRY_DSN(): string {
    return process.env.REACT_APP_SENTRY_DSN;
  },
  get MICROSOFT_CLIENT_ID(): string {
    return process.env.REACT_APP_MICROSOFT_CLIENT_ID;
  },
  get MICROSOFT_CLIENT_REDIRECT_URI(): string {
    return process.env.REACT_APP_MICROSOFT_REDIRECT_URI;
  },
  get MICROSOFT_SCOPES(): string[] {
    if (!process.env.REACT_APP_MICROSOFT_SCOPES) {
      return [];
    }
    return process.env.REACT_APP_MICROSOFT_SCOPES.trim().split(',');
  },
  get GOOGLE_CLIENT_ID(): string {
    return process.env.REACT_APP_GOOGLE_CLIENT_ID;
  },
  get GOOGLE_REDIRECT_URI(): string {
    return process.env.REACT_APP_GOOGLE_REDIRECT_URI;
  },
  get GOOGLE_BASE_AUTH_URL(): string {
    return process.env.REACT_APP_GOOGLE_BASE_AUTH_URL;
  },
  get GOOGLE_SCOPES(): string[] {
    if (!process.env.REACT_APP_GOOGLE_SCOPES) {
      return [];
    }
    return process.env.REACT_APP_GOOGLE_SCOPES.trim().split(',');
  },
  get MAX_API_KEYS(): number {
    return process.env.REACT_APP_MAX_API_KEYS ? Number(process.env.REACT_APP_MAX_API_KEYS) : 5;
  },
};
export default config;
