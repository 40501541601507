import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR, DEFAULT_SECONDARY_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    filterInfoWrapper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      '& .MuiGrid-item': {
        paddingTop: 0,
      },
    },
    tabTitle: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: theme.palette.grey[900],
      display: 'flex',
      gap: theme.spacing(1),
      '&.MuiGrid-item': {
        paddingBottom: 0,
      },
    },
    searchItems: {
      height: theme.spacing(5),
      width: theme.spacing(62),
      margin: 0,
      marginLeft: theme.spacing(2),
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        marginTop: theme.spacing(-1),
        marginLeft: 0,
      },
    },
    reportTypesGroup: {
      marginLeft: theme.spacing(6),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: 'none',
      position: 'unset',
      '&:first-child': {
        borderTop: '0px',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    date: {
      fontSize: '0.875rem',
      fontWeight: +theme.typography.fontWeightRegular,
      color: theme.palette.grey[800],
    },
    title: {
      margin: `${theme.spacing(2)}px 0px`,
      display: 'flex',
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: '32px',
      alignItems: 'center',
      color: ACTIVE_TEXT_COLOR,
    },
    icon: {
      marginRight: theme.spacing(2),
      fontSize: '1.167rem',
    },
    reportTypes: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, max(292px, 100%/4)), 1fr))',
      maxWidth: 'calc(308px * 3)', // (292px (column) + 16) * 3 (max columns)
      columnGap: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
    reportType: {
      background: 'none',
      textAlign: 'left',
      cursor: 'pointer',
      border: '1px solid transparent',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      fontWeight: +theme.typography.fontWeightRegular,
      fontSize: '1rem',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      color: `${theme.palette.grey[800]}`,
      outline: 'none',
      boxShadow: 'none',
      appearance: 'none',
      margin: 0,
      '&:hover': {
        background: `${theme.palette.grey[100]}`,
      },
      '&:focus': {
        background: `${theme.palette.grey[100]}`,
      },
      '&:focus-visible': {
        borderRadius: '3px',
        boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      },

      '& span': {
        lineHeight: 1.4,
      },
    },
    back: {
      margin: 0,
      marginTop: theme.spacing(3),
    },
    backLink: {
      cursor: 'pointer',
      color: ACTIVE_TEXT_COLOR,
      fontWeight: +theme.typography.fontWeightMedium,
      fontSize: '1rem',
      lineHeight: '1.75rem',
    },
    backIcon: {
      fontSize: '1rem',
      margin: `0px ${theme.spacing()}px -3px 0px`,
      height: '1rem',
      transform: 'rotate(-180deg)',
      color: ACTIVE_TEXT_COLOR,
    },
    matchedChipFont: {
      color: ACTIVE_TEXT_COLOR,
    },
    errorContainer: {
      paddingTop: theme.spacing(16),
    },
    errorImage: {
      paddingBottom: theme.spacing(),
    },
    contentContainer: {
      paddingBottom: theme.spacing(20),
    },
    timestampContainer: {
      paddingBottom: theme.spacing(4),
    },
    gridContainer: {
      gap: theme.spacing(3),
      flexDirection: 'row',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    grid: {
      padding: theme.spacing(2),
      minHeight: 102,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),' +
        ' 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    accuracyContainer: {
      maxWidth: theme.spacing(33),
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    bankText: {
      fontWeight: 700,
    },
    exportOptions: {
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: theme.spacing(4.5),
      '&.MuiGrid-item': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        justifyContent: 'space-between',
        width: '100%',
      },
      '& .button.text.sc-railz-button': {
        margin: 0,
        padding: `${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
      },
    },
    filter: {
      height: '100%',
      marginTop: theme.spacing(),
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      '& .Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
    tabBusinessTitle: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: `${theme.spacing(4)}px`,
      color: theme.palette.grey[900],
    },
    tabBusinessDescription: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: `${theme.spacing(2.375)}px`,
      color: theme.palette.grey[800],
      marginTop: theme.spacing(3.25),
      marginBottom: theme.spacing(3.125),
    },
    lastSyncText: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: `${theme.spacing(2.125)}px`,
      color: theme.palette.grey[800],
      marginBottom: theme.spacing(2.75),
    },
    lastSyncValue: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: `${theme.spacing(2.125)}px`,
      color: theme.palette.grey[800],
      paddingLeft: theme.spacing(1),
    },
    description: {
      maxWidth: '100%',
      fontSize: '0.75rem',
      lineHeight: `${theme.spacing(3.5)}px`,
      fontWeight: 500,
      color: '#757575',
    },
    errorTableContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flex: 1,
      marginTop: theme.spacing(8),
    },
    noDataText: {
      fontSize: '0.875rem',
      color: theme.palette.grey[800],
      lineHeight: `${theme.spacing(1)}px`,
    },
    connectionDescription: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: `${theme.spacing(4)}px`,
      letterSpacing: 0,
      textAlign: 'left',
      color: '#757575',
      marginTop: theme.spacing(0),
    },
    table: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall:first-child': {
          paddingLeft: 0,
        },
        '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall:last-child': {
          paddingRight: 0,
        },
      },
    },
    firstColumn: {
      [theme.breakpoints.down('xs')]: {
        '&.MuiTableCell-root': {
          paddingLeft: 0,
        },
      },
    },
    lastColumn: {
      [theme.breakpoints.down('xs')]: {
        '&.MuiTableCell-root': {
          paddingRight: 0,
        },
      },
    },
    reportInfoModal: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    modalHeaderTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'space-between',
      alignItems: 'flex-end',

      fontSize: '1.125rem',
      fontWeight: 500,
      color: theme.palette.grey[900],
      marginTop: theme.spacing(-1),

      '& .chip': {
        fontSize: '0.75rem',
        fontWeight: +theme.typography.fontWeightMedium,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        color: ACTIVE_TEXT_COLOR,
        backgroundColor: DEFAULT_SECONDARY_COLOR['main'],
      },
      '& railz-button.button': {
        color: ACTIVE_TEXT_COLOR,
        fontSize: '0.75rem',
        fontWeight: 500,
        '& .MuiSvgIcon-fontSizeSmall': {
          fontSize: '1rem',
          marginTop: '-1px',
        },
      },
    },
  });
});
