import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      ...theme.typography.h5,
      fontSize: '1rem',
      marginTop: theme.spacing(),
    },
  }),
);
