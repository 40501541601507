import { createStyles, makeStyles, Theme } from '@material-ui/core';

const buttonStyle = {
  padding: '8px 24px',
  '& .MuiButton-label, & .MuiButton-label span': {
    padding: 0,
    margin: 0,
  },
};
export default makeStyles((theme: Theme) =>
  createStyles({
    largeButton: {
      ...buttonStyle,
      minWidth: theme.spacing(23),
    },
    smallButton: {
      ...buttonStyle,
      minWidth: theme.spacing(14),
    },
    buttonText: {
      fontSize: '1rem',
      lineHeight: 'normal',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '12px !important',
      marginLeft: '-12px !important',
    },
  }),
);
