import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes appear': {
      from: {
        transform: 'scale(.8)',
        opacity: 0,
        filter: 'blur(5px)',
      },
    },

    visible: {
      opacity: 1,
      visibility: 'visible',
      animation: `$appear .2s ease-in-out both`,
    },

    // Content
    heading: {
      fontWeight: 600,
      marginBottom: '.5em',
      fontSize: '1.25em',
    },

    servicesList: {
      padding: 0,
      margin: '0 auto',
      listStyle: 'none',
      textAlign: 'left',
      width: '70%',

      '& li': {
        padding: '.75em 0',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },

    serviceName: {
      fontWeight: 400,
      fontSize: '.875em',
      display: 'flex',
      alignItems: 'center',
      gap: '1em',
    },

    linkContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    helpLink: {
      fontWeight: 600,
      fontSize: '0.85em',
      textDecoration: 'underline',
      textUnderlineOffset: '5px',
      margin: '2em auto 0',
      display: 'block',
      width: '80%',
    },

    checkmark: {
      color: 'var(--brand-secondary-background)',
    },
  }),
);
