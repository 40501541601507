/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RailzNavigationProgressBar } from '@railzai/railz-uikit-react';

import useStyle from './style';

import { getIntegrations } from 'store/features/integration/integration.selector';

import storeService from 'store';

interface Props {
  steps?: Step[];
  accentColor: string;
  overviewEnabled: boolean;
  summaryEnabled: boolean;
  isIntegration?: boolean;
}

interface Step {
  name: 'Overview' | 'Accounting' | 'Banking' | 'Commerce' | 'Summary';
  state: 'current' | 'pending' | 'success' | 'skipped';
  onClick: Function;
}

export default function ProgressBarPreview({
  accentColor,
  overviewEnabled,
  summaryEnabled,
}: Props): JSX.Element {
  const classes = useStyle();
  const [filteredSteps, setFilteredSteps] = useState([]);
  const store = storeService.getStore();

  const integrations = useSelector<ReturnType<typeof store.getState>, any>(getIntegrations);

  const steps: Step[] = [
    {
      name: 'Overview',
      state: 'current',
      onClick: (): void => {},
    },
    {
      name: 'Accounting',
      state: 'pending',
      onClick: (): void => {},
    },
    {
      name: 'Banking',
      state: 'pending',
      onClick: (): void => {},
    },
    {
      name: 'Commerce',
      state: 'pending',
      onClick: (): void => {},
    },
    {
      name: 'Summary',
      state: 'pending',
      onClick: (): void => {},
    },
  ];

  useEffect(() => {
    const activeItegrations = integrations?.filter((service) => {
      return service.state === 'active';
    });

    const activeKinds = activeItegrations?.map((integration) => {
      return integration.serviceKind;
    });

    const uniqueKinds = activeKinds?.reduce((acc, item) => {
      if (acc.indexOf(item) === -1) {
        acc.push(item);
      }
      return acc;
    }, []);

    const updateValue = (array: Step[], index: number, newValue: Step['state']): Step[] => {
      array[index].state = newValue;
      return array;
    };

    const activeSteps = steps?.filter((step) => {
      return uniqueKinds?.map((kind) => {
        const stepName = step?.name.toString().toLowerCase();
        const kindName = kind?.toString().toLowerCase();

        return stepName === kindName;
      });
    });

    if (!overviewEnabled) {
      activeSteps?.splice(0, 1);
      updateValue(activeSteps, 0, 'current');
    }

    if (!summaryEnabled) {
      const index = activeSteps?.indexOf({
        name: 'Summary',
        state: 'pending',
        onClick: (): void => {},
      });

      activeSteps.splice(index, 1);
    }

    if (summaryEnabled && !overviewEnabled) {
      updateValue(activeSteps, 0, 'success');
      updateValue(activeSteps, 1, 'success');
      updateValue(activeSteps, 2, 'success');
      updateValue(activeSteps, 3, 'current');
    }

    setFilteredSteps(activeSteps);
  }, [integrations, overviewEnabled, summaryEnabled]);

  return (
    <div
      className={classes.progressBarContainer}
      style={{ '--brand-secondary-background': `#${accentColor}` } as React.CSSProperties}
    >
      <RailzNavigationProgressBar pages={filteredSteps} />
    </div>
  );
}
