import { createSelector } from 'reselect';

import { AllState } from '../../../state';

import { ACCOUNT_STORE_NAME } from '../account.state';

import { TEAM_STORE_NAME, TeamState } from './team.state';

/** Select Team State */
export const getTeamState = (state: AllState): TeamState =>
  state[ACCOUNT_STORE_NAME][TEAM_STORE_NAME];

export const isTeamLoading = createSelector(
  getTeamState,
  (team: TeamState) => team && team.loading,
);

export const getTeamError = createSelector(getTeamState, (team: TeamState) => team && team.error);
export const getTeamErrorMessage = createSelector(
  getTeamState,
  (team: TeamState) => team && team.error && team.error.message,
);

export const isTeamApiSuccess = createSelector(
  getTeamState,
  (team: TeamState) => team && team.response && team.response.success,
);

export const getTeamApiMessage = createSelector(
  getTeamState,
  (team: TeamState) => team && team.response && team.response.message,
);

export const getTeamApiResponse = createSelector(
  getTeamState,
  (team: TeamState) => team && team.response,
);

export const getTeamsApiResponse = createSelector(
  getTeamState,
  (team: TeamState) => team && team.teams,
);

export const getTeamV2ApiResponse = createSelector(
  getTeamState,
  (team: TeamState) => team && team.team,
);

export const getTeamMembersApiResponse = createSelector(
  getTeamState,
  (team: TeamState) => team && team.teamMembers,
);
