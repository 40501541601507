export enum WebhookEvent {
  LOGIN = 'login',
  AUTH = 'auth',
  CONNECTION_STATUS = 'connectionStatus',
  DATA = 'data',
  PUSH = 'push',
  BATCH_PUSH = 'batchPush',
}

export interface Webhook {
  uuid?: string;
  event: WebhookEvent[];
  url: string;
  secret?: string;
  requestTypes?: string[];
}

export interface WebhookHistory {
  payload: any;
  response: any;
  status: string;
  statusCode: number;
  method: string;
  event: string;
  timestamp: string;
  sandbox: boolean;
  serviceName: string;
  businessName: string;
  connectionUuid: string;
  requestId: string;
  requestIdParam?: string;
  url: string;
}
export interface WebhookHistoryParams {
  webhookEvent?: string;
  connectionUuid?: string;
  status?: string;
  limit?: number;
  offset?: number;
  startDate?: string;
  endDate?: string;
}

export interface WebhookHistoryResponse {
  meta: any;
  webhookHistory: WebhookHistory[];
}
export interface WebhookDateRange {
  startDate: string;
  endDate: string;
}
