/* eslint-disable max-len */
import React from 'react';
import { Grid, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyle from '../style';

interface Props {
  disabled: boolean;
  accountingRequired?: boolean;
  bankingRequired?: boolean;
  commerceRequired?: boolean;
  setAccountingRequired?: (overviewEnabled: boolean) => void;
  setBankingRequired?: (overviewEnabled: boolean) => void;
  setCommerceRequired?: (overviewEnabled: boolean) => void;
}

export default function IntegrationsSection({
  disabled,
  accountingRequired,
  bankingRequired,
  commerceRequired,
  setAccountingRequired,
  setBankingRequired,
  setCommerceRequired,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Grid container className={disabled ? classes.uploadDisabled : null}>
      <Typography className={classes.description}>
        {t('DASHBOARD_SITES_INTEGRATION_DESCRIPTION')}
      </Typography>
      <Grid container>
        <div className={classes.inputContainer}>
          <div className={classes.toggleContainer}>
            <Typography className={classes.toggleText}>Accounting Services</Typography>
            <Switch
              checked={accountingRequired}
              data-testid="accounting-required-switch"
              inputProps={{
                'aria-label': `${t(
                  accountingRequired ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )}`,
              }}
              onChange={(e): void => setAccountingRequired(e.target.checked)}
              color="primary"
            />
          </div>
          <div className={classes.toggleContainer}>
            <Typography className={classes.toggleText}>Banking Services</Typography>
            <Switch
              checked={bankingRequired}
              data-testid="banking-required-switch"
              inputProps={{
                'aria-label': `${t(
                  bankingRequired ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )}`,
              }}
              onChange={(e): void => setBankingRequired(e.target.checked)}
              color="primary"
            />
          </div>
          <div className={classes.toggleContainer}>
            <Typography className={classes.toggleText}>Commerce Services</Typography>
            <Switch
              checked={commerceRequired}
              data-testid="commerce-required-switch"
              inputProps={{
                'aria-label': `${t(
                  commerceRequired ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )}`,
              }}
              onChange={(e): void => setCommerceRequired(e.target.checked)}
              color="primary"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
