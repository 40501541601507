import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';

import storeService from '../../../store';

import { resetAuthInformation } from '../../../store/features/auth';

import { EventService } from '../../../services';

import useStyle from './style';

import { ApiPurpose, BusinessRole, CustomerRequest, FormInputProps, Plan } from 'types';

import { getCountryList } from 'data/countries';
import SignUpBG from 'assets/images/SignUpBG.svg';
import { FormInput, FormSelect, FormSelectAutoComplete } from 'components/form';
import { generateDropdown } from 'helpers/common.helper';
import Logo from 'components/logo';
import { resetBusinessInformation } from 'store/features/business/business.action';
import { resetIntegrationsInformation } from 'store/features/integration/integration.action';
import { logoutErrorSnackbar } from 'layout/helpers/util';
import {
  resetCustomerResponse,
  updateCompanyApi,
} from 'store/features/account/customer/customer.action';
import {
  getCustomerError,
  getCustomerState,
  isQuestionnaireComplete,
} from 'store/features/account/customer/customer.selector';
import { ErrorResponse } from 'types/interface/error.interface';

const validationSchema = yup.object().shape({
  companyName: yup
    .string()
    .trim()
    .required('DASHBOARD_SIGNUP_COMPANY_NAME_REQUIRED')
    .min(2, 'DASHBOARD_SIGNUP_COMPANY_NAME_CRUD_MIN')
    .max(50, 'DASHBOARD_SIGNUP_COMPANY_NAME_CRUD_MAX'),
  apiPurpose: yup.string().required('DASHBOARD_SIGNUP_BUILDING_REQUIRED').nullable(),
  businessRole: yup.string().required('DASHBOARD_SIGNUP_ROLE_REQUIRED').nullable(),
  country: yup.object().required('DASHBOARD_SIGNUP_LOCATION_REQUIRED').nullable(),
  onBehalfAnother: yup
    .string()
    .required('DASHBOARD_SIGNUP_ON_BEHALF_OF_ANOTHER_REQUIRED')
    .nullable(),
});

const apiPurposeOptions = generateDropdown(ApiPurpose);
const businessRoleOptions = generateDropdown(BusinessRole);
const countries = getCountryList();

export default function Questionnaire(): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse>(getCustomerError);
  const history = useHistory();
  const customer = useSelector(getCustomerState);
  const isQuestionnaireCompleted = useSelector(isQuestionnaireComplete);

  const { handleSubmit, errors, getValues, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      apiPurpose: '',
      businessRole: '',
      country: '',
      onBehalfAnother: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: CustomerRequest): Promise<void> => {
    const country: any = getValues(['country']);
    data.country = country?.country?.countryShortCode;
    data.onBehalfAnother = Boolean(Number(data.onBehalfAnother));
    dispatch(updateCompanyApi(data));
  };

  const onKindChange = (value: string, formOnChange: (value: string) => void): void => {
    formOnChange(value);
  };

  useEffect(() => {
    if (error) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: t('DASHBOARD_SIGNUP_QUESTIONNAIRE_UNKNOWN_FAILURE'),
      });
    }
  }, [error]);

  useEffect(() => {
    if (!!customer && isQuestionnaireCompleted && customer?.billingPlan === Plan.FREE) {
      history.replace('/select-plan', { from: '/questionnaire' });
      dispatch(resetCustomerResponse());
    }
    if (customer?.billingPlan === Plan.CUSTOM) {
      history.replace('/');
    }
  }, [customer]);

  useEffect(() => {
    if (isQuestionnaireCompleted) {
      history.replace('/');
    }
    //needed to avoid the user go back during this flow
    window.onbeforeunload = function (): void {
      handleLogout();
      return history.push('/login');
    };
    //needed to remove the onbeforeunload function when the user leave the page
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      store.dispatch(resetAuthInformation());
      store.dispatch(resetBusinessInformation());
      store.dispatch(resetIntegrationsInformation());
    } catch (err) {
      logoutErrorSnackbar(err);
    }
  }, []);

  const RadioInput = (
    props: Partial<FormInputProps> & { value?: string | boolean },
  ): React.ReactElement => <Radio size="small" color="primary" {...props} required />;

  return (
    <Box className={classes.mainContainer}>
      <div className={classes.illustration}>
        <img src={SignUpBG} alt="logo" />
      </div>
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Logo className={classes.logoIcon} />
          <Typography className={classes.title}>
            {t('DASHBOARD_SIGNUP_WELCOM_QUESTIONNAIRE')}
          </Typography>
          <Typography className={classes.description}>
            {t('DASHBOARD_SIGNUP_WELCOME_DESCRIPTION')}
          </Typography>
          <form
            className={classes.formContainer}
            data-testid="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography>{t('DASHBOARD_SIGNUP_BEHALF_COMPANY_LABEL')}</Typography>
            <Grid item md={6} sm={9} xs={12} className={classes.formGrid}>
              <FormControl error={!!errors.onBehalfAnother?.message}>
                <Controller
                  control={control}
                  name="onBehalfAnother"
                  testid="onBehalfAnother-input"
                  render={({ onChange, value }): React.ReactElement => (
                    <RadioGroup
                      aria-label="onBehalfAnother"
                      name="onBehalfAnotherRadio"
                      onChange={(event): void => onKindChange(event.target.value, onChange)}
                      value={value}
                      row={true}
                      className={classes.radioInputs}
                    >
                      <FormControlLabel
                        control={<RadioInput data-testid="onBehalfAnother-yes-radio" />}
                        label={t('DASHBOARD_SIGNUP_YES_LABEL')}
                        value="1"
                      />
                      <FormControlLabel
                        control={<RadioInput data-testid="onBehalfAnother-no-radio" />}
                        label={t('DASHBOARD_SIGNUP_NO_LABEL')}
                        value="0"
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText className={classes.errorText}>
                  {t(errors.onBehalfAnother?.message)}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Typography>{t('DASHBOARD_SIGNUP_BODY_LABEL')}</Typography>
            <FormInput
              name="companyName"
              label="DASHBOARD_SIGNUP_COMPANY_LABEL"
              placeholder="DASHBOARD_SIGNUP_COMPANY_PLACEHOLDER"
              fullWidth
              errorobj={errors}
              control={control}
              testid="company-input"
            />
            <FormSelect
              name="apiPurpose"
              label="DASHBOARD_SIGNUP_BUILDING_LABEL"
              placeholder="DASHBOARD_SIGNUP_BUILDING_LABEL"
              fullWidth
              options={apiPurposeOptions}
              errorobj={errors}
              control={control}
              testid="api-purpose-input"
            />
            <FormSelect
              name="businessRole"
              label="DASHBOARD_SIGNUP_ROLE_LABEL"
              placeholder="DASHBOARD_SIGNUP_ROLE_PLACEHOLDER"
              fullWidth
              options={businessRoleOptions}
              errorobj={errors}
              control={control}
              testid="business-role-input"
            />
            <FormSelectAutoComplete
              placeholder="DASHBOARD_SIGNUP_COUNTRY_PLACEHOLDER"
              label="DASHBOARD_SIGNUP_COUNTRY_LABEL"
              fullWidth
              name="country"
              errorobj={errors}
              control={control}
              options={countries}
              getOptionLabel={(option: any): string => option.countryName || ''}
              renderOption={(option): React.ReactElement => (
                <React.Fragment>{option.countryName}</React.Fragment>
              )}
              testid="country-input"
              valuekey="countryShortCode"
            />
            <RailzButton
              size="large"
              buttonType="submit"
              data-testid="questionnaire-submit"
              label={t('DASHBOARD_SIGNUP_QUESTIONNAIRE_CTA')}
              className={classes.cta}
            />
            <Box onClick={handleLogout} data-testid="logout-btn">
              <Typography className={classes.logout}>
                <LogoutIcon />
                {t('DASHBOARD_SIGNUP_QUESTIONNAIRE_LOGOUT')}
              </Typography>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
