export enum AspType {
  QUICKBOOKS = 'quickbooks',
  QUICKBOOKS_DESKTOP = 'quickbooksDesktop',
  ORACLE_NETSUITE = 'oracleNetsuite',
  SAGE_BUSINESS_CLOUD = 'sageBusinessCloud',
  SAGE_INTACCT = 'sageIntacct',
  FRESHBOOKS = 'freshbooks',
  XERO = 'xero',
  DYNAMICS_BUSINESS_CENTRAL = 'dynamicsBusinessCentral',
  WAVE = 'wave',
  MYOB = 'myob',
  SANDBOX = 'sandbox',
  RAILZ_SANDBOX = 'railzSandbox',
  PLAID = 'plaid',
  SHOPIFY = 'shopify',
  SQUARE = 'square',
  ZOHO_BOOKS = 'zohoBooks',
}

export enum IntegrationStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  INVALID_KEYS = 'invalid_keys',
}

export enum ServiceType {
  ACCOUNTING = 'accounting',
  BANKING = 'banking',
  COMMERCE = 'commerce',
  ANALYTICS = 'analytics',
}

export enum ServiceTypeIndex {
  ACCOUNTING = 1,
  BANKING = 2,
  COMMERCE = 3,
  ANALYTICS = 4,
}

export enum SelectableReportType {
  ACCOUNTING = 'accounting',
  BANKING = 'banking',
  COMMERCE = 'commerce',
  ANALYTICS = 'analytics',
}

export enum ServiceEntityState {
  SANDBOX = 'sandbox',
  ACTIVE = 'active',
  DISABLED = 'disabled',
}
