import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Header, ScrollView, View } from '../../components';

import {
  fetchBusinesses,
  fetchSingleBusinesses,
} from '../../store/features/business/business.action';

import { getSelectedBusiness } from '../../store/features/business/business.selector';
import { getBusinessSummaryFilter } from '../../store/features/report/report.selector';
import {
  fetchDataTypesApi,
  setReportSummaryFilter,
} from '../../store/features/report/report.action';

import PageDescription from '../../components/page-description/page-description';

import { calculateFilterByQuery } from '../../helpers/business-helpers/financial-summary-helper';

import { BusinessStatus } from '../../pages/business/types/enums';

import Controls from './components/controls';
import useStyle from './style';

import useQuerySearchUpdate from 'hooks/use-query-search-update';

const FinancialSummaryPage = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyle();
  const selectedBusiness = useSelector(getSelectedBusiness);
  const reportFilter = useSelector(getBusinessSummaryFilter);
  const [querySearch, setQueryParams] = useQuerySearchUpdate();
  const businessName = querySearch?.get('businessName');
  const showStatus = querySearch?.get('show');
  const history = useHistory();

  useEffect(() => {
    const payload = {
      orderBy: `businessName`,
      limit: 25,
      status: [BusinessStatus.ACTIVE, BusinessStatus.INACTIVE],
    };
    dispatch(fetchBusinesses(payload));
    dispatch(fetchDataTypesApi());
    return () => {
      dispatch(setReportSummaryFilter(null));
    };
  }, []);

  useEffect(() => {
    if (businessName) {
      dispatch(fetchSingleBusinesses({ businessName, limit: 1, type: 'single' }));
    } else {
      window.location.replace('/businesses');
    }
  }, [businessName]);

  useEffect(() => {
    if (selectedBusiness) {
      const { filter } = calculateFilterByQuery(selectedBusiness, querySearch);
      const { businessName, serviceName, reportFrequency, reportType, startDate, endDate, status } =
        filter;
      dispatch(
        setReportSummaryFilter({
          reportFrequency,
          ...reportFilter,
          reportType,
          businessName,
          serviceName,
          endDate,
          startDate,
          status,
        }),
      );
      setQueryParams({ businessName, serviceName, reportFrequency, startDate, endDate, status });
    }

    return () => {
      dispatch(setReportSummaryFilter(null));
    };
  }, [selectedBusiness]);

  return (
    <>
      <Header
        drawerMenu={true}
        leftComponent={
          <Header.BackButton
            onClick={(): void =>
              history.replace(`/businesses${showStatus ? `?show=${showStatus}` : ''}`)
            }
          />
        }
        rightComponent={null}
        title={t('DASHBOARD_FINANCIAL_OVERVIEW_HEADER_TITLE')}
        testId="test-header-financial-summary-page"
      />

      <View className={classes.mainView}>
        <ScrollView style={{ padding: 16 }}>
          <PageDescription
            title="DASHBOARD_FINANCIAL_OVERVIEW_TITLE"
            subtitle="DASHBOARD_FINANCIAL_OVERVIEW_SUBTITLE"
            className={classes.descriptionContainer}
          />

          {reportFilter && <Controls />}
        </ScrollView>
      </View>
    </>
  );
};

export default FinancialSummaryPage;
