import { ServiceType } from './integration.enum';

import i18n from 'translations';

export enum ReportType {
  _name = 'REPORT_TYPE',
  ACCOUNTS = 'accounts',
  ACCOUNTING_TRANSACTIONS = 'accountingTransactions',
  ACCOUNTING_BANK_TRANSACTIONS = 'accountingBankTransactions',
  ACCOUNTING_BANK_ACCOUNTS = 'accountingBankAccounts',
  BANK_ACCOUNTS = 'bankAccounts', // this can be accounting or banking
  BANK_RECONCILIATION = 'bankReconciliation',
  BANKING_RECONCILIATION = 'bankingReconciliation',
  BUSINESS_BANK_RECONCILIATION = 'businessBankReconciliation',
  BANK_TRANSFER = 'bankTransfers',
  BANK_TRANSACTIONS = 'bankTransactions', //  accounting or banking
  BANK_ASSET = 'bankAssets',
  AGED_RECEIVABLE = 'agedReceivable',
  AGED_PAYABLE = 'agedPayable',
  BALANCE_SHEETS = 'balanceSheets',
  BILLS = 'bills',
  BILL_PAYMENTS = 'billPayments',
  BUSINESS_INFO = 'businessInfo',
  BUSINESS_VALUATIONS = 'businessValuations',
  BILL_CREDIT_NOTES = 'billCreditNotes',
  INVOICE_CREDIT_NOTES = 'invoiceCreditNotes',
  CREDITS = 'creditScore',
  CREDIT_RATING = 'creditRatings',
  CASHFLOW_STATEMENTS = 'cashflowStatements',
  CUSTOMERS = 'customers',
  DEPOSITS = 'deposits',
  EMPLOYEES = 'employees',
  ESTIMATES = 'estimates',
  EXPENSES = 'expenses',
  FINANCIAL_RATIOS = 'financialRatios',
  INCOME_STATEMENTS = 'incomeStatements',
  INVENTORY = 'inventory',
  INVOICES = 'invoices',
  INVOICE_PAYMENTS = 'invoicePayments',
  JOURNAL_ENTRIES = 'journalEntries',
  PROBABILITY_OF_DEFAULT = 'probabilityOfDefault',
  PURCHASE_ORDERS = 'purchaseOrders',
  REFUNDS = 'refunds',
  TAX_RATES = 'taxRates',
  TRIAL_BALANCES = 'trialBalances',
  TRACKING_CATEGORIES = 'trackingCategories',
  TRANSACTIONS = 'transactions',
  VENDORS = 'vendors',
  PRODUCTS = 'products',
  PORTFOLIO_METRICS = 'portfolioMetrics',
  DISPUTES = 'disputes',
  ORDERS = 'orders',
  FINANCIAL_SUMMARY = 'financialSummary',
  ATTACHMENTS = 'attachments',
  JOURNALS = 'journals',
  FINANCIAL_BENCHMARKINGS = 'financialBenchmarking',
  FINANCIAL_FORECASTS = 'financialForecasts',
  FRAUD_RISK_METRICS = 'fraudRiskMetrics',
  PAYMENT_METHODS = 'paymentMethods',
  TAX_BENCHMARKINGS = 'taxBenchmarking',
  VENDOR_BANK_ACCOUNT = 'vendorBankAccounts',
  BILL_PAYMENT_REQUEST = 'billPaymentRequests',
}

export const ReportApiReferenceUrl: Omit<Record<ReportType, string>, ReportType._name> = {
  accounts: 'https://docs.railz.ai/reference/getaccounts',
  accountingTransactions: 'https://docs.railz.ai/reference/get-accountingtransactions',
  accountingBankTransactions: 'https://docs.railz.ai/reference/accounting-banktransactions',
  accountingBankAccounts: 'https://docs.railz.ai/reference/accounting-bankaccounts',
  bankAccounts: 'https://docs.railz.ai/reference/get-bankaccounts', // accounting or banking
  bankReconciliation: 'https://docs.railz.ai/reference/bankreconciliation',
  bankingReconciliation: 'https://docs.railz.ai/reference/bankreconciliation',
  billPaymentRequests: 'https://docs.railz.ai/reference/bill-paymentrequests',
  businessBankReconciliation: 'https://docs.railz.ai/reference/bankreconciliation',
  bankTransfers: 'https://docs.railz.ai/reference/get-banktransfers',
  bankTransactions: 'https://docs.railz.ai/reference/get-banktransactions', // accounting or banking
  agedReceivable: 'https://docs.railz.ai/reference/get-araging',
  agedPayable: 'https://docs.railz.ai/reference/get-apaging',
  balanceSheets: 'https://docs.railz.ai/reference/getbalancesheets',
  bills: 'https://docs.railz.ai/reference/get-bills',
  billPayments: 'https://docs.railz.ai/reference/getbillspayments',
  businessInfo: 'https://docs.railz.ai/reference/businessinfo',
  businessValuations: 'https://docs.railz.ai/reference/get-businessvaluations',
  billCreditNotes: 'https://docs.railz.ai/reference/bill-creditnotes',
  invoiceCreditNotes: 'https://docs.railz.ai/reference/get-invoice-creditnotes',
  creditScore: 'https://docs.railz.ai/reference/get-creditscores',
  creditRatings: 'https://docs.railz.ai/reference/get-creditratings',
  cashflowStatements: 'https://docs.railz.ai/reference/getcashflowstatements',
  customers: 'https://docs.railz.ai/reference/getcustomers',
  deposits: 'https://docs.railz.ai/reference/get-deposits',
  employees: 'https://docs.railz.ai/reference/railz-api', // Deprecated and Removed
  estimates: 'https://docs.railz.ai/reference/get-estimates',
  expenses: 'https://docs.railz.ai/reference/expense',
  financialRatios: 'https://docs.railz.ai/reference/get-financialratios',
  incomeStatements: 'https://docs.railz.ai/reference/getincomestatements',
  inventory: 'https://docs.railz.ai/reference/getinventory',
  invoices: 'https://docs.railz.ai/reference/getinvoices',
  invoicePayments: 'https://docs.railz.ai/reference/getinvoicespayments',
  journalEntries: 'https://docs.railz.ai/reference/get-journalentries',
  probabilityOfDefault: 'https://docs.railz.ai/reference/probabilityofdefaults',
  purchaseOrders: 'https://docs.railz.ai/reference/get-purchaseorder',
  refunds: 'https://docs.railz.ai/reference/get-refund',
  taxRates: 'https://docs.railz.ai/reference/gettaxrates',
  trialBalances: 'https://docs.railz.ai/reference/gettrialbalances',
  trackingCategories: 'https://docs.railz.ai/reference/get-trackingcategories',
  transactions: 'https://docs.railz.ai/reference/transactions',
  vendors: 'https://docs.railz.ai/reference/getvendors',
  products: 'https://docs.railz.ai/reference/product',
  portfolioMetrics: 'https://docs.railz.ai/reference/getportfoliometrics',
  disputes: 'https://docs.railz.ai/reference/dispute',
  orders: 'https://docs.railz.ai/reference/order',
  financialSummary: 'https://docs.railz.ai/reference/financialbenchmarking',
  attachments: 'https://docs.railz.ai/reference/get-attachments',
  journals: 'https://docs.railz.ai/reference/journals-1',
  financialBenchmarking: 'https://docs.railz.ai/reference/financialbenchmarking',
  financialForecasts: 'https://docs.railz.ai/reference/get-financialforecasts',
  fraudRiskMetrics: 'https://docs.railz.ai/reference/financialfraudmetrics',
  paymentMethods: 'https://docs.railz.ai/reference/paymentmethod',
  taxBenchmarking: 'https://docs.railz.ai/reference/taxbenchmarking',
  vendorBankAccounts: 'https://docs.railz.ai/reference/vendors-bankaccounts',
  bankAssets: 'https://docs.railz.ai/reference/bankassets',
} as const;

interface ReportTypeDataInfo {
  value: string;
  serviceType: ServiceType;
  // if set to false, will hide on the reports page
  isImplementedOnDashboard: boolean;
  uniqueValue?: ReportType;
  url: string;
  name?: string;
}

export const ReportTypeDataTypeMapping: Record<string, ReportTypeDataInfo> = {
  ACCOUNTING_TRANSACTION: {
    value: 'accountingTransactions',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/accountingTransactions',
  },
  ACCOUNTS: {
    value: 'accounts',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/accounts',
  },
  AGED_PAYABLE: {
    value: 'agedPayable',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/apAging',
  },
  AGED_RECEIVABLE: {
    value: 'agedReceivable',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/arAging',
  },
  ATTACHMENT: {
    value: 'attachments',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/attachments',
  },
  BALANCE_SHEETS: {
    value: 'balanceSheets',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/balanceSheets',
  },
  BANKING_RECONCILIATION: {
    value: 'bankingReconciliation',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/bankReconciliation',
  },
  BANK_TRANSACTION_ACCOUNTING: {
    value: 'bankTransactions',
    uniqueValue: ReportType.ACCOUNTING_BANK_TRANSACTIONS,
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/bankTransactions',
  },
  BANK_ACCOUNT_ACCOUNTING: {
    value: 'bankAccounts',
    uniqueValue: ReportType.ACCOUNTING_BANK_ACCOUNTS,
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/bankAccounts',
  },
  BANK_ACCOUNT: {
    value: 'bankAccounts',
    serviceType: ServiceType.BANKING,
    isImplementedOnDashboard: true,
    url: '/v2/banking/bankAccounts',
  },
  BANK_ASSET: {
    value: 'bankAssets',
    serviceType: ServiceType.BANKING,
    isImplementedOnDashboard: true,
    url: '/v2/banking/bankAssets',
  },
  BANK_TRANSACTION: {
    value: 'bankTransactions',
    serviceType: ServiceType.BANKING,
    isImplementedOnDashboard: true,
    url: '/v2/banking/bankTransactions',
  },
  BANK_TRANSFERS: {
    value: 'bankTransfers',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/bankTransfers',
  },
  BILL_CREDIT_NOTES: {
    value: 'billCreditNotes',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/bills/creditNotes',
  },
  BILL_PAYMENTS: {
    value: 'billPayments',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/bills/payments',
  },
  BILLS: {
    value: 'bills',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/bills',
  },
  BUSINESS_VALUATION: {
    value: 'businessValuations',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/businessValuations',
  },
  BUSINESS_INFO: {
    value: 'businessInfo',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/businesses/info',
  },
  CASH_FLOW_STATEMENTS: {
    value: 'cashflowStatements',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/cashflowStatements',
  },
  CREDIT_SCORE: {
    value: 'creditScore',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/creditScores, /v2/analytics/creditRatings',
  },
  CONTACT: {
    value: 'contacts',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: false,
    url: '/v2/accounting/contacts',
  },
  CUSTOMERS: {
    value: 'customers',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/customers',
  },
  DEPOSITS: {
    value: 'deposits',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/deposits',
  },
  COMMERCE_DISPUTE: {
    value: 'disputes',
    serviceType: ServiceType.COMMERCE,
    isImplementedOnDashboard: true,
    url: '/v2/commerce/disputes',
  },
  ESTIMATES: {
    value: 'estimates',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/estimates',
  },
  EXPENSES: {
    value: 'expenses',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/expenses',
    name: i18n.t('DASHBOARD_ENUM_REPORT_TYPE_EXPENSES'),
  },
  FINANCIAL_RATIO: {
    value: 'financialRatios',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/financialRatios',
  },
  INCOME_STATEMENTS: {
    value: 'incomeStatements',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/incomeStatements',
  },
  INVENTORY: {
    value: 'inventory',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/inventory',
  },
  INVOICE_CREDIT_NOTES: {
    value: 'invoiceCreditNotes',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/invoices/creditNotes',
  },
  INVOICE_PAYMENTS: {
    value: 'invoicePayments',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/invoices/payments',
  },
  INVOICES: {
    value: 'invoices',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/invoices',
  },
  JOURNAL_ENTRIES: {
    value: 'journalEntries',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/journalEntries',
  },
  JOURNAL: {
    value: 'journals',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/journals',
  },
  COMMERCE_ORDER: {
    value: 'orders',
    serviceType: ServiceType.COMMERCE,
    isImplementedOnDashboard: true,
    url: '/v2/commerce/orders',
  },
  PROBABILITY_OF_DEFAULT: {
    value: 'probabilityOfDefault',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/probabilityOfDefault',
  },
  COMMERCE_PRODUCT: {
    value: 'products',
    serviceType: ServiceType.COMMERCE,
    isImplementedOnDashboard: true,
    url: '/v2/commerce/products',
  },
  PORTFOLIO_METRICS: {
    value: 'portfolioMetrics',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/portfolioMetrics',
    name: 'Portfolio Metrics',
  },
  PURCHASE_ORDERS: {
    value: 'purchaseOrders',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/purchaseOrders',
  },
  REFUNDS: {
    value: 'refunds',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/refunds',
  },
  TAX_BENCHMARKING: {
    value: 'taxBenchmarking',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/taxBenchmarking',
    name: 'Tax Benchmarking',
  },
  FINANCIAL_BENCHMARKING: {
    value: 'financialBenchmarking',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/financialBenchmarking',
    name: 'Financial Benchmarking',
  },
  FINANCIAL_FORECAST: {
    value: 'financialForecasts',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/financialForecasts',
  },
  FRAUD_RISK_METRICS: {
    value: 'fraudRiskMetrics',
    serviceType: ServiceType.ANALYTICS,
    isImplementedOnDashboard: true,
    url: '/v2/analytics/fraudRiskMetrics',
    name: 'Fraud Risk Metrics',
  },
  TAX_RATES: {
    value: 'taxRates',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/taxRates',
  },
  TRACKING_CATEGORIES: {
    value: 'trackingCategories',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/trackingCategories',
  },
  COMMERCE_TRANSACTION: {
    value: 'transactions',
    serviceType: ServiceType.COMMERCE,
    isImplementedOnDashboard: true,
    url: '/v2/commerce/transactions',
  },
  TRIAL_BALANCES: {
    value: 'trialBalances',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/trialBalances',
  },
  VENDORS: {
    value: 'vendors',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/vendors',
  },
  PAYMENT_METHODS: {
    value: 'paymentMethods',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/paymentMethods',
  },
  VENDOR_BANK_ACCOUNT: {
    value: 'vendorBankAccounts',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: false,
    url: '/v2/accounting/vendors/bankAccounts',
    name: i18n.t('DASHBOARD_ENUM_REPORT_TYPE_VENDOR_BANK_ACCOUNT'),
  },
  BILL_PAYMENT_REQUEST: {
    value: 'billPaymentRequests',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: true,
    url: '/v2/accounting/bills/paymentRequests',
    name: i18n.t('DASHBOARD_ENUM_REPORT_TYPE_BILL_PAYMENT_REQUESTS'),
  },
  CUSTOMER_BANK_ACCOUNT: {
    value: 'customerBankAccounts',
    serviceType: ServiceType.ACCOUNTING,
    isImplementedOnDashboard: false,
    url: '/v2/customer/bankAccounts',
    name: i18n.t('DASHBOARD_ENUM_REPORT_TYPE_CUSTOMER_BANK_ACCOUNTS'),
  },
};

export const DATA_TYPES_WITH_WARNINGS_ON_SYNC_ENABLE = {
  VENDOR_BANK_ACCOUNT: {
    title: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_VENDOR_BANK_ACCOUNT_TITLE',
    message: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_VENDOR_BANK_ACCOUNT',
  },
  BANK_ACCOUNT_ACCOUNTING: {
    title: `DASHBOARD_DATA_SYNC_WARNING_SYNC_BANK_ACCOUNT_ACCOUNTING_TITLE`,
    message: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_BANK_ACCOUNT_ACCOUNTING',
  },
  CUSTOMER_BANK_ACCOUNT: {
    title: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_CUSTOMER_BANK_ACCOUNTS_TITLE',
    message: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_CUSTOMER_BANK_ACCOUNTS',
  },
};

export const DATA_TYPES_TOOLTIPS = {
  BANK_ACCOUNT_ACCOUNTING: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_BANK_ACCOUNT_ACCOUNTING',
  CUSTOMER_BANK_ACCOUNT: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_CUSTOMER_BANK_ACCOUNTS',
  VENDOR_BANK_ACCOUNT: 'DASHBOARD_DATA_SYNC_WARNING_SYNC_VENDOR_BANK_ACCOUNT',
};

export const ReportTypeDataTypeMappingByServiceType: Record<
  ServiceType,
  Record<DataTypeUniqueName, ReportTypeDataInfo>
> = Object.values(ReportTypeDataTypeMapping).reduce((acc, reportTypeDataInfo) => {
  const { serviceType, value } = reportTypeDataInfo;
  if (!acc[serviceType]) {
    acc[serviceType] = {};
  }

  acc[serviceType][value] = reportTypeDataInfo;
  return acc;
}, {} as Record<ServiceType, Record<DataTypeUniqueName, ReportTypeDataInfo>>);

export type DataTypeUniqueName = keyof typeof ReportTypeDataTypeMapping;

export enum ReportSummaryType {
  BANK_ACCOUNTS = 'bankAccounts',
  BALANCE_SHEETS = 'balanceSheets',
  BILLS = 'bills',
  CASHFLOW_STATEMENTS = 'cashflowStatements',
  CREDIT_SCORES = 'creditScores',
  EXPENSES = 'expenses',
  FINANCIAL_RATIOS = 'financialRatios',
  INCOME_STATEMENTS = 'incomeStatements',
  INVOICES = 'invoices',
  REVENUE = 'revenue',
}

export enum AllReportFrequency {
  _name = 'REPORT_FREQUENCY',
  ALL = 'all',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum ReportFrequency {
  _name = 'REPORT_FREQUENCY',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum ReportFinancialStatementType {
  _name = 'REPORT_FORECAST_FINANCIAL_STATEMENT_TYPE',
  BALANCE_SHEETS = 'balanceSheets',
  CASHFLOW_STATEMENTS = 'cashflowStatements',
  INCOME_STATEMENTS = 'incomeStatements',
}

export enum ReportStatus {
  _name = 'STATUS',
  ALL = 'all',
  OPEN = 'open',
  CLOSED = 'closed',
  PAID = 'paid',
  VOID = 'void',
  PARTIALLY_PAID = 'partiallyPaid',
  DRAFT = 'draft',
  UNKNOWN = 'unknown',
}

export enum CommerceTransactionStatus {
  _name = 'TRANSACTION_STATUS',
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  APPROVED = 'approved',
  SCHEDULED = 'scheduled',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export enum CommercePaymentMethodType {
  _name = 'PAYMENT_METHOD_TYPE',
  CASH = 'cash',
  CARD = 'card',
  CRYPTOCURRENCY = 'cryptocurrency',
  BANK = 'bank',
  DIGITAL = 'digital',
  STORE_CREDIT = 'storeCredit',
  PAYPAL = 'paypal',
  PREPAID = 'prepaid',
  BUY_NOW_PAY_LATER = 'buyNowPayLater',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export enum CommerceTransactionType {
  _name = 'TRANSACTION_TYPE',
  FAILED_PAYOUT = 'failedPayout',
  PAYMENT = 'payment',
  PAYMENT_FEE = 'paymentFee',
  PAYMENT_FEE_REFUND = 'paymentFeeRefund',
  PAYOUT = 'payout',
  REFUND = 'refund',
  TRANSFER = 'transfer',
  DISPUTE = 'dispute',
  ORDER = 'order',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export enum CommerceOrderStatus {
  _name = 'COMMERCE_ORDER_STATUS',
  OPEN = 'open',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown',
}

export enum CommerceOrderPaymentStatus {
  _name = 'COMMERCE_ORDER_PAYMENT_STATUS',
  PENDING = 'pending',
  APPROVED = 'approved',
  PAID = 'paid',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
  UNKNOWN = 'unknown',
}

export enum CommerceOrderFulfilmentStatus {
  _name = 'COMMERCE_ORDER_FULFILMENT_STATUS',
  FULFILLED = 'fulfilled',
  PARTIAL = 'partial',
  UNFULFILLED = 'unfulfilled',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown',
}

export enum AccountingTransactionsType {
  _name = 'ACC_TRANSACTIONS_TYPE',
  BILL = 'bill',
  BILL_PAYMENT = 'billPayment',
  CREDIT_NOTE = 'creditNote',
  DEPOSIT = 'deposit',
  DEPOSIT_FROM_OTHER_ACCOUNTS = 'depositFromOtherAccounts',
  ESTIMATE = 'estimate',
  EXPENSE = 'expense',
  EXPENSE_REFUND = 'expenseRefund',
  INTEREST_INCOME = 'interestIncome',
  INVENTORY_ADJUSTMENT = 'inventoryAdjustment',
  INVOICE = 'invoice',
  JOURNAL_ENTRY = 'journalEntry',
  OTHER = 'other',
  OTHER_INCOME = 'otherIncome',
  OVER_PAYMENT = 'overPayment',
  OWNERS_CONTRIBUTION = 'ownersContribution',
  OWNER_DRAWINGS = 'ownerDrawings',
  PAYMENT = 'payment',
  PURCHASE_ORDER = 'purchaseOrder',
  REFUND = 'refund',
  SALES_RETURN = 'salesReturn',
  SALES_WITHOUT_INVOICES = 'salesWithoutInvoices',
  TAX_PAYMENT = 'taxPayment',
  TAX_ADJUSTMENT = 'taxAdjustment',
  TAX_REFUND = 'taxRefund',
  TAX_REVERSAL = 'taxReversal',
  TRANSFER = 'transfer',
  UNKNOWN = 'unknown',
  VENDOR_CREDIT_NOTE = 'vendorCreditNote',
}

export enum CreditScoreTypes {
  VERY_POOR = 'Very Poor',
  POOR = 'Poor',
  FAIR = 'Fair',
  GOOD = 'Good',
  VERY_GOOD = 'Very Good',
  EXCELLENT = 'Excellent',
}

export enum QuarterRoundType {
  START = 'start',
  END = 'end',
}
export enum ReportAccountingMethod {
  _name = 'ACCOUNTING_METHOD',
  ACCRUAL = 'accrual',
  CASH = 'cash',
}

export enum ProductStatusType {
  _name = 'PRODUCT_STATUS',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
}

export enum DisputeStatusType {
  _name = 'DISPUTE_STATUS',
  EVIDENCE_REQUIRED = 'evidenceRequired',
  INQUIRY_PROCESSING = 'inquiryProcessing',
  PROCESSING = 'processing',
  INQUIRY_CLOSED = 'inquiryClosed',
  CHARGE_REFUNDED = 'chargeRefunded',
  INQUIRY_ACCEPTED = 'inquiryAccepted',
  INQUIRY_WON = 'inquiryWon',
  INQUIRY_LOST = 'inquiryLost',
  UNKNOWN = 'unknown',
  OTHER = 'other',
}

export enum DisputeReasonType {
  _name = 'DISPUTE_REASON',
  BANK_NOT_PROCESSED = 'bankNotProcessed',
  CREDIT_NOT_PROCESSED = 'creditNotProcessed',
  DEBIT_NOT_AUTHORIZED = 'debitNotAuthorized',
  DUPLICATE = 'duplicate',
  FRAUDULENT = 'fraudulent',
  GENERAL = 'general',
  INCORRECT_ACCOUNT_DETAILS = 'incorrectAccountDetails',
  INSUFFICIENT_FUNDS = 'insufficientFunds',
  PRODUCT_NOT_RECEIVED = 'productNotReceived',
  PRODUCT_UNACCEPTABLE = 'productUnacceptable',
  SUBSCRIPTION_CANCELLED = 'subscriptionCanceled',
  UNRECOGNIZED = 'unrecognized',
  UNKNOWN = 'unknown',
  OTHER = 'other',
}
