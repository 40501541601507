import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Typography } from '@material-ui/core';

import i18n from '../../../translations';

import DownloadFile from 'assets/icons/download-file';
import { EventService } from 'services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: `0px ${theme.spacing(2)}px 0px ${theme.spacing(0.5)}px`,
  },
  progressMessageContainer: {
    color: theme.palette.common.black,

    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    lineHeight: `${theme.spacing(3)}px`,
    '& > *': {
      margin: 0,
      height: theme.spacing(3),
    },
  },
  messageTitle: {
    fontSize: '0.875rem',
    lineHeight: 'normal',

    fontWeight: 600,
    margin: 0,
  },
  messageBody: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '19px',
  },
  progressBar: {
    marginTop: theme.spacing(1),
    width: '352px',
    height: theme.spacing(1 / 4),
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#009BBD',
    },
  },
}));

export function ReportDownloadProgress(): JSX.Element {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    EventService.addListener(EventService.EVENT.DOWNLOAD_PROGRESS, setProgress);

    return (): void => {
      EventService.removeListener(EventService.EVENT.DOWNLOAD_PROGRESS, setProgress);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>{DownloadFile}</div>
      <div className={classes.progressMessageContainer}>
        <h3 className={classes.messageTitle}>{i18n.t('DASHBOARD_REPORT_DOWNLOAD_MODAL_TITLE')}</h3>

        <Typography variant="body1" className={classes.messageBody}>
          {i18n.t('DASHBOARD_REPORT_DOWNLOAD_MODAL_BODY')}
        </Typography>
        <LinearProgress className={classes.progressBar} variant="determinate" value={progress} />
      </div>
    </div>
  );
}
