import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    view: {
      display: 'flex',
      width: '100%',
      flexGrow: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      minHeight: 0,
      backgroundColor: '#F9F9F9',
      overflow: 'auto',

      '& > *': {
        boxSizing: 'border-box',
      },
    },
    sideBar: {
      background: 'white',
      width: '100%',
      minWidth: '300px',
      flexGrow: 1,
      position: 'relative',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',

      ['@media screen and (min-width: 800px)']: {
        width: '25%',
      },

      ['@media screen and (min-width: 1080px)']: {
        width: '40%',
        maxWidth: '490px',
        flexGrow: 1,
        height: '100%',
        paddingBottom: theme.spacing(12),
      },
    },

    previewCanvas: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px',
      overflow: 'hidden',
      position: 'sticky',
      top: 0,
      height: '100%',

      ['@media screen and (min-width: 800px)']: {
        overflow: 'auto',
        flexGrow: 1,
        width: '60%',
      },

      '& > *': {
        margin: 'auto',
        width: '100%',
        maxWidth: '860px',
      },
    },
    title: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
      fontSize: '20px',
    },
    description: {
      marginBottom: theme.spacing(2),
    },

    link: {
      fontWeight: 700,
      color: ACTIVE_TEXT_COLOR,
      textDecoration: 'underline',
    },

    modal: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.grey[700],
      opacity: 0.75,
    },

    dialog: {
      marginTop: theme.spacing(-4),
      maxWidth: '420px',
      backgroundColor: 'white',
      zIndex: 1,
      padding: theme.spacing(7),
      textAlign: 'center',
      borderRadius: '5px',
      boxShadow: '0 4px 4px rgba(0,0,0, .1)',
    },

    button: {
      marginTop: theme.spacing(3),
    },

    illustration: {
      marginBottom: theme.spacing(3),
    },
  }),
);
