import {
  CUSTOMER_INITIAL_STATE,
  CUSTOMER_STORE_NAME,
  CustomerState,
} from './customer/customer.state';
import { PROFILE_INITIAL_STATE, PROFILE_STORE_NAME, ProfileState } from './profile/profile.state';
import { TEAM_INITIAL_STATE, TEAM_STORE_NAME, TeamState } from './team/team.state';

export const ACCOUNT_STORE_NAME = 'account';

export const ACCOUNT_INITIAL_STATE: AccountState = {
  [CUSTOMER_STORE_NAME]: CUSTOMER_INITIAL_STATE,
  [PROFILE_STORE_NAME]: PROFILE_INITIAL_STATE,
  [TEAM_STORE_NAME]: TEAM_INITIAL_STATE,
};

export interface AccountState {
  [CUSTOMER_STORE_NAME]: CustomerState;
  [PROFILE_STORE_NAME]: ProfileState;
  [TEAM_STORE_NAME]: TeamState;
}
