import { makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

const drawerItemStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginRight: theme.spacing(),
  },
  activeNavLink: {
    backgroundColor: '#F3FFF8 !important',
    color: ACTIVE_TEXT_COLOR,
    '& .MuiListItemIcon-root': {
      color: 'inherit',
    },
  },
  activeNavLinkMenu: {
    color: ACTIVE_TEXT_COLOR,
    '& .MuiListItemIcon-root': {
      color: 'inherit',
    },
  },
  inactiveNavLinkMenu: {
    '& .MuiListItemIcon-root': {
      color: 'inherit',
    },
  },
  linkText: {
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  externalLinkText: {
    fontWeight: 500,
    overflow: 'inherit',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  switchContainer: {
    marginRight: `-8px`,
  },
  listContainer: {
    width: '100%',
  },
  listItem: {
    borderTopRightRadius: theme.spacing(4),
    borderBottomRightRadius: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  documentationContainer: {
    margin: theme.spacing(),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: '#F9F9F9',
    '& p, & railz-button': {
      visibility: 'visible',
      transition: 'opacity 0.5s ease-in-out, visibility 0.1s ease-in',
      opacity: 1,
    },
    '&>a': {
      visibility: 'hidden',
      transition: 'opacity 0.5s ease-in-out, visibility 0.1s ease-in',
      opacity: 0,
    },
    '&.collapsed': {
      backgroundColor: '#fff',
      margin: 0,
      '& p, & railz-button': {
        visibility: 'hidden',
        opacity: 0,
      },
      '&>a': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  documentationContainerTitle: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginBottom: theme.spacing(),
  },
  documentationSubTitle: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2),
    width: theme.spacing(18.5),
  },
  mainContainer: {
    justifyContent: 'space-between',
  },
  buttonDocumentation: {
    borderColor: theme.palette.grey[800],
    color: theme.palette.grey[800],
    fontSize: '1rem',
    fontWeight: 500,
  },
  sandboxSwitchWrapper: {
    height: theme.spacing(7),
  },
}));

export default drawerItemStyles;
