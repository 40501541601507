/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Grid, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@material-ui/icons/Refresh';

import { useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from '../style';

import { FormInput } from 'components/form';
import { getCustomerState } from 'store/features/account/customer/customer.selector';

interface Props {
  disabled: boolean;
  form: any;
  defaultContent: any;
  railzWatermarkEnabled: boolean;
  setRailzWatermarkEnabled: (bool: boolean) => void;
}

export default function LeftSideBarSection({
  disabled,
  railzWatermarkEnabled,
  setRailzWatermarkEnabled,
  defaultContent,
  form: { errors, control, setValue, clearErrors, getValues, watch },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const customer = useSelector(getCustomerState);
  const [isDefault, setIsDefault] = useState(false);

  const watchAllFields = watch();
  useEffect(() => {
    const { leftSideBarTitle, leftSideBarBody } = getValues();
    setIsDefault(
      leftSideBarTitle === defaultContent.leftSideBarTitle &&
        leftSideBarBody === defaultContent.leftSideBarBody &&
        railzWatermarkEnabled === defaultContent.railzWatermarkEnabled,
    );
  }, [watchAllFields]);

  return (
    <Grid container className={disabled ? classes.uploadDisabled : null}>
      <Typography className={classes.description}>
        {t('DASHBOARD_SETTINGS_SITES_LEFT_PAGE_DESCRIPTION')}
      </Typography>
      <Grid container>
        <div className={classes.inputContainer}>
          <FormInput
            defaultValue=""
            label={t('DASHBOARD_SETTINGS_SITES_LANDING_PAGE_TEXT_FIELD_TITLE')}
            placeholder={customer?.companyName}
            id="leftSideBarTitle"
            name="leftSideBarTitle"
            margin="dense"
            testid="left-side-bar-title-id-input"
            fullWidth
            errorobj={errors}
            control={control}
            className={classes.contentInputItem}
          />
          <FormInput
            defaultValue=""
            label={t('DASHBOARD_SETTINGS_SITES_LANDING_PAGE_TEXT_FIELD_BODY')}
            placeholder={t('DASHBOARD_SETTINGS_SITES_LEFT_PAGE_BODY')}
            id="leftSideBarBody"
            name="leftSideBarBody"
            margin="dense"
            testid="left-side-bar-body-id-input"
            fullWidth
            errorobj={errors}
            control={control}
            className={classes.contentInputItem}
            {...{ multiline: true, minRows: 4 }}
          />
        </div>
      </Grid>
      <Grid container>
        <div className={classes.inputContainer}>
          <Typography className={classes.subTitle}>
            {t('DASHBOARD_SETTINGS_SITES_WATERMARK_TOGGLE')}
          </Typography>
          <Typography className={classes.description}>
            {t('DASHBOARD_SETTINGS_SITES_WATERMARK_DESCRIPTION')}
          </Typography>
        </div>
      </Grid>
      <Grid container>
        <div className={classes.inputContainer}>
          <div className={classes.toggleContainer}>
            <Typography className={classes.toggleText}>
              {t('DASHBOARD_SETTINGS_SITES_WATERMARK_HEADER')}
            </Typography>
            <div className={classes.toggleSwitchContainer}>
              {!railzWatermarkEnabled && (
                <Typography className={classes.toggleSwitchText}>
                  {t('DASHBOARD_SHOW_LABEL')}
                </Typography>
              )}
              <Switch
                checked={railzWatermarkEnabled}
                data-testid="left-side-bar-enabled-switch"
                inputProps={{
                  'aria-label': `${t(
                    railzWatermarkEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                  )}`,
                }}
                onChange={(e): void => setRailzWatermarkEnabled(e.target.checked)}
                color="primary"
              />
            </div>
          </div>
        </div>
      </Grid>
      {!isDefault && (
        <RailzButton
          data-testid="reset-left-side-bar"
          type="text warning"
          buttonType="button"
          onClick={(): void => {
            setValue('leftSideBarTitle', defaultContent.leftSideBarTitle);
            setValue('leftSideBarBody', defaultContent.leftSideBarBody);
            setRailzWatermarkEnabled(defaultContent.railzWatermarkEnabled);
            clearErrors();
          }}
          label={t('DASHBOARD_SETTINGS_SITES_WATERMARK_TOGGLE_RESET')}
        >
          <span slot="prefix">
            <RefreshIcon fontSize="small" />
          </span>
        </RailzButton>
      )}
    </Grid>
  );
}
