import React from 'react';

const VectorStart = (): any => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 8L5.25333 5.24L8 4L5.25333 2.76L4 0L2.77333 2.76L0 4L2.77333 5.24L4 8Z"
        fill="#006037"
      />
    </svg>
  );
};

export default VectorStart;
