import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';

import { Chip } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { RailzButton } from '@railzai/railz-uikit-react';

import { Link } from 'react-router-dom';

import Accordion from '../../components/accordion/accordion';

import style from './style';

import { FormSelect } from 'components/form';

import {
  resetSyncConfigsApiResponse,
  updateSyncConfigsApi,
} from 'store/features/integration/integration.action';
import {
  getIntegrationState,
  getSyncConfigs,
} from 'store/features/integration/integration.selector';
import { showSnackbar } from 'helpers/common.helper';
import LoadingShimmer from 'components/loading-shimmer';
import { getProfilePlan } from 'store/features/account/profile/profile.selector';
import { Plan } from 'types';
import { SelectOptions } from 'components/form/select';
import i18n from 'translations';

const YEARS_ACCORDING_TO_PLAN = {
  [Plan.CUSTOM]: 10,
  default: 5,
};

function optionsAccordingToPlan(plan: Plan): SelectOptions {
  const amountOfYears = YEARS_ACCORDING_TO_PLAN[plan] || YEARS_ACCORDING_TO_PLAN.default;
  const options: SelectOptions = Array.from(Array(amountOfYears).keys()).map((value) => ({
    name: String(value + 1),
    value: value + 1,
  }));

  if (plan !== Plan.CUSTOM) {
    options.push({
      name: i18n.t('DASHBOARD_DATA_SYNC_ENTERPRISE_ONLY'),
      value: 'enterprise-only',
      disabled: true,
    });
  }
  return options;
}

export default function YearsToSync(): JSX.Element {
  const classes = style();
  const [options, setOptions] = useState<SelectOptions>(optionsAccordingToPlan(Plan.FREE));
  const { yearsToSync } = useSelector(getSyncConfigs);
  const billingPlan = useSelector(getProfilePlan);

  const isLoading = useSelector(getIntegrationState).loading;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      yearsToSync: yearsToSync || '5',
    },
  });

  const onSubmit = (values: { yearsToSync: string }): void => {
    if (yearsToSync === values.yearsToSync) {
      showSnackbar({
        message: t('DASHBOARD_DATA_SYNC_INITIAL_SAVE_TOAST_NONE'),
        type: 'success',
      });
    } else {
      dispatch(updateSyncConfigsApi({ yearsToSync: values.yearsToSync }));
    }
  };

  useEffect(() => {
    setOptions(optionsAccordingToPlan(billingPlan));
  }, [billingPlan]);

  useEffect(() => {
    setValue('yearsToSync', yearsToSync);
  }, [yearsToSync]);

  useEffect(() => {
    return () => {
      dispatch(resetSyncConfigsApiResponse());
    };
  }, []);

  return (
    <Accordion
      className=""
      header={
        <span className={classes.header}>
          <Typography variant="h3" className={classes.title}>
            {t('DASHBOARD_DATA_SYNC_YEARS_TITLE')}
          </Typography>
          <LoadingShimmer loading={isLoading} borderRadius="80px">
            <Chip
              className={classes.chip}
              data-testid={`years-to-sync-chip`}
              label={t(`DASHBOARD_DATA_SYNC_YEARS_LABEL`, { years: yearsToSync })}
              size="small"
            />
          </LoadingShimmer>
        </span>
      }
      showAccordion={true}
      children={
        <>
          <Typography className={classes.descriptionYears}>
            {t('DASHBOARD_DATA_SYNC_YEARS_DESCRIPTION')}
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormSelect
              name="yearsToSync"
              label="DASHBOARD_DATA_SYNC_YEARS_TITLE"
              placeholder="DASHBOARD_DATA_SYNC_YEARS_TITLE"
              options={options}
              errorobj={errors}
              control={control}
              className={classes.yearsInput}
              testid="year-to-sync-input"
            />
            {billingPlan !== Plan.CUSTOM && (
              <Typography className={classes.offerText}>
                <Trans
                  i18nKey="DASHBOARD_DATA_SYNC_ENTERPRISE_LABEL"
                  components={{ a: <Link to={'/account/billing'} className={classes.link} /> }}
                />
              </Typography>
            )}
            <div className={classes.ctaYears}>
              <RailzButton
                type="primary"
                data-testid="save-button-years"
                label={t('DASHBOARD_DATA_SYNC_YEARS_CTA')}
                buttonType="submit"
              />
            </div>
          </form>
        </>
      }
    />
  );
}
