import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, useTheme } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Alert from '@material-ui/lab/Alert';

import { useDispatch, useSelector } from 'react-redux';

import { isSandbox } from '../../../store/features/auth';

import useStyle from './style';

import AlertDialog from 'components/dialog';
import { FormCheckBox, FormInput, FormMultiSelect, FormSelect } from 'components/form';
import FormSwitch from 'components/form/switch';
import {
  DropDownMap,
  formChange,
  generateDropdown,
  getChangedFields,
  getLocalizedService,
  showSnackbar,
} from 'helpers/common.helper';
import {
  AspType,
  Integration,
  IntegrationCreationData,
  IntegrationStatus,
  IntegrationUpdateData,
  ServiceWithIntegrations,
} from 'types';

import { Kind } from 'components/table-actions/table-actions';
import {
  createIntegrationApi,
  updateIntegrationApi,
} from 'store/features/integration/integration.action';
import { CredentialsKind } from 'store/features/integration/integration.state';

import config from 'config';
import { plaidSupportedCountries } from 'data/countries';
import { openNewTab } from 'helpers/open-new-tab';

type ActionData =
  | {
      kind: Kind.EDIT;
      data: ServiceWithIntegrations;
      uuid: string;
    }
  | {
      kind: Kind.CREATE;
      data: ServiceWithIntegrations;
    };

const QUICKBOOKS_RAILZ_DOC_URL =
  'https://docs.railz.ai/docs/integrations-accounting-quickbooks-online-setup#enable-qbo-payments';
const PLAID_PERMISSION_ACCESS_URL = 'https://plaid.com/docs/assets/#testing-assets';

function getFormCtaText(kind: Kind, data: ServiceWithIntegrations): string {
  if (kind === Kind.EDIT) return 'DASHBOARD_SAVE_CHANGES_CTA';
  return !data.relatedIntegrations?.length
    ? 'DASHBOARD_INTEGRATION_KEYS_MODAL_CTA_ADD_ENABLE'
    : 'DASHBOARD_INTEGRATION_KEYS_MODAL_CTA_ADD';
}

function generateKinds(service: AspType): DropDownMap<string>[] {
  if (![AspType.PLAID, AspType.QUICKBOOKS].includes(service)) return [];

  const credentialsKinds = generateDropdown(CredentialsKind);
  if (service === AspType.PLAID)
    return credentialsKinds.filter((kind) => kind.value !== CredentialsKind.PRODUCTION);

  return credentialsKinds.filter((kind) => kind.value !== CredentialsKind.DEVELOPMENT);
}

function correctDefaultPermissions(
  payload: IntegrationCreationData | IntegrationUpdateData,
): IntegrationCreationData | IntegrationUpdateData {
  const permissions = payload?.metadata?.permissions;
  if (permissions?.readOlderAssets && !permissions.readAllAssets) {
    payload.metadata.permissions.readOlderAssets = false;
  }
  return payload;
}

/**
 * This function builds the initial form data based on the actionData.
 * For new integrations, it will return a default object with the required fields.
 * For existing integrations, it will return the integration object to be edited
 * (and complete the object with default values if needed)
 */
function buildInitialFormData(
  isSandboxEnabled: boolean,
  actionData: ActionData,
): IntegrationCreationData | IntegrationUpdateData {
  const { data, kind } = actionData;

  if (kind === Kind.EDIT) {
    const selectedIntegrationToEdit: IntegrationUpdateData = data.relatedIntegrations.find(
      (i) => i?.uuid === actionData.uuid,
    ) as Integration;
    if (!selectedIntegrationToEdit) throw new Error('Integration not found');

    // This fills the possibly missing fields with the default values
    if (data.name === AspType.PLAID) {
      selectedIntegrationToEdit.metadata = {
        permissions: {
          readAllAssets: !!selectedIntegrationToEdit.metadata?.permissions?.readAllAssets,
          readOlderAssets: !!selectedIntegrationToEdit.metadata?.permissions?.readOlderAssets,
        },
        countryCodes: selectedIntegrationToEdit.metadata?.countryCodes || [],
      };
    } else if (data.name === AspType.SHOPIFY) {
      selectedIntegrationToEdit.metadata = {
        permissions: {
          readAllOrders: !!selectedIntegrationToEdit.metadata?.permissions?.readAllOrders,
        },
      };
    } else if (data.name === AspType.QUICKBOOKS) {
      selectedIntegrationToEdit.metadata = {
        permissions: {
          enablePayments: !!selectedIntegrationToEdit.metadata?.permissions?.enablePayments,
        },
      };
    }

    return selectedIntegrationToEdit;
  }

  const baseLineIntegration: IntegrationCreationData = {
    kind: isSandboxEnabled ? CredentialsKind.SANDBOX : CredentialsKind.PRODUCTION,
    service: data.name,
    state: data.relatedIntegrations?.length
      ? data.relatedIntegrations[0].state
      : IntegrationStatus.ENABLED,

    isActive: data.relatedIntegrations?.length ? false : true,
    client: '',
    secret: '',
    name: '',
    metadata: {},
  };
  if (data.name === AspType.PLAID) {
    baseLineIntegration.metadata.countryCodes = [];
    baseLineIntegration.metadata.permissions = { readAllAssets: false, readOlderAssets: false };
  } else if (data.name === AspType.SHOPIFY) {
    baseLineIntegration.metadata.permissions = { readAllOrders: false };
  } else if (data.name === AspType.QUICKBOOKS) {
    baseLineIntegration.metadata.permissions = {
      enablePayments: false,
    };
  }
  return baseLineIntegration;
}

/**
 * This function builds the form configuration based on the actionData and the environment
 */
function buildFormConfig(
  isSandbox: boolean,
  actionData: ActionData,
): Record<
  | 'isActiveShown'
  | 'isActiveEnabled'
  | 'kind'
  | 'confirmationAndWarning'
  | 'readAllOrders'
  | 'readAllAssets'
  | 'readOlderAssets'
  | 'enablePayments'
  | 'countries',
  boolean
> {
  // Should the isActive checkbox be enabled or not
  const isActiveShown = actionData.kind === Kind.CREATE;
  const isActiveEnabled = isActiveShown && !!actionData.data?.relatedIntegrations?.length;

  // Should the form show confirmation and warning
  let confirmationAndWarning = false;
  if (actionData.kind === Kind.EDIT) {
    const integrationToEdit = actionData.data.relatedIntegrations.find(
      ({ uuid }) => uuid === actionData.uuid,
    );
    confirmationAndWarning = !!integrationToEdit.secret && integrationToEdit.connectionCount > 0;
  }

  // Should the form show the input for kind
  const plaidInSandboxMode = isSandbox && actionData.data.name === AspType.PLAID;
  const qboNotInProdEnvAndNotInProd =
    !config.IS_PRODUCTION_ENV && !isSandbox && actionData.data.name === AspType.QUICKBOOKS;
  const kind = plaidInSandboxMode || qboNotInProdEnvAndNotInProd;

  return {
    isActiveShown,
    isActiveEnabled,
    confirmationAndWarning,
    kind,
    readAllOrders: actionData.data.name === AspType.SHOPIFY,
    readAllAssets: actionData.data.name === AspType.PLAID,
    readOlderAssets: actionData.data.name === AspType.PLAID,
    enablePayments: actionData.data.name === AspType.QUICKBOOKS,
    countries: actionData.data.name === AspType.PLAID,
  };
}

export function IntegrationForm({
  onClose,
  actionData,
}: {
  onClose: () => void;
  actionData: ActionData;
}): JSX.Element {
  const { data, kind } = actionData;
  const { t } = useTranslation();
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSandboxEnabled = useSelector(isSandbox);
  const [formShowConfig] = useState(buildFormConfig(isSandboxEnabled, actionData));
  const [possibleKindValues] = useState(generateKinds(data.name));
  const [serviceName] = useState(getLocalizedService(data.name));
  const [showLongerAccessToggle, setShowLongerAccessToggle] = useState(!isSandboxEnabled);

  const [initialState] = useState(buildInitialFormData(isSandboxEnabled, actionData));

  async function onSubmit(
    formValues: (IntegrationUpdateData | IntegrationCreationData) & { confirmation?: string },
  ): Promise<void> {
    if (!formChange(getValues(), initialState)) {
      return showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }

    if (kind === Kind.EDIT) {
      delete formValues.confirmation;
      const payload = getChangedFields(formValues, initialState) as IntegrationUpdateData;
      correctDefaultPermissions(payload);
      dispatch(
        updateIntegrationApi({ ...payload, service: initialState.service, uuid: actionData.uuid }),
      );
    } else {
      const payload = { ...initialState, ...formValues } as IntegrationCreationData;
      correctDefaultPermissions(payload);
      dispatch(createIntegrationApi(payload));
    }

    onClose();
  }

  const handleKindChange = (event): void => {
    setValue('kind', event?.target?.value);
    if (!isSandboxEnabled || event?.target?.value !== CredentialsKind.SANDBOX)
      setShowLongerAccessToggle(true);
    else setShowLongerAccessToggle(false);
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: initialState,
    resolver: yupResolver(
      yup.object().shape({
        isActive: yup.boolean().nullable(),
        name: yup
          .string()
          .required('DASHBOARD_INTEGRATON_MANAGE_CLIENT_KEYREQUIRED')
          .min(2, 'DASHBOARD_INTEGRATION_KEYS_MODAL_LABEL_KEY_ERROR_MIN')
          .max(40, 'DASHBOARD_INTEGRATION_KEYS_MODAL_LABEL_KEY_ERROR_MAX')
          .test({
            name: 'one-of-',
            test: (value) => {
              const trimmedValue = value.trim().toLocaleLowerCase();
              const others = actionData.data.relatedIntegrations;
              if (trimmedValue === initialState?.name.toLocaleLowerCase()) return true;
              return others.every(({ name }) => trimmedValue !== name.toLocaleLowerCase());
            },
            message: 'DASHBOARD_INTEGRATION_KEYS_MODAL_LABEL_KEY_ERROR_DUPLICATE',
          })
          .nullable(),
        client: yup.string().required('DASHBOARD_INTEGRATON_MANAGE_CLIENT_ID_REQUIRED').nullable(),
        secret: yup
          .string()
          .required('DASHBOARD_INTEGRATON_MANAGE_CLIENT_SECRET_REQUIRED')
          .nullable(),
        kind:
          formShowConfig.kind &&
          yup.string().required('DASHBOARD_INTEGRATION_MANAGE_KIND_REQUIRED').nullable(),
        metadata:
          (formShowConfig.countries ||
            formShowConfig.readAllOrders ||
            formShowConfig.readAllAssets ||
            formShowConfig.readOlderAssets ||
            formShowConfig.enablePayments) &&
          yup
            .object()
            .shape({
              countryCodes:
                formShowConfig.countries &&
                yup
                  .array()
                  .of(yup.string())
                  .min(1, 'DASHBOARD_INTEGRATION_MANAGE_COUNTRY_REQUIRED')
                  .nullable(),
              permissions:
                (formShowConfig.readAllOrders ||
                  formShowConfig.readAllAssets ||
                  formShowConfig.readOlderAssets ||
                  formShowConfig.enablePayments) &&
                yup
                  .object()
                  .shape({
                    readAllOrders: formShowConfig.readAllOrders && yup.bool().nullable(),
                    readAllAssets: formShowConfig.readAllAssets && yup.bool().nullable(),
                    readOlderAssets: formShowConfig.readOlderAssets && yup.bool().nullable(),
                    enablePayments: formShowConfig.enablePayments && yup.bool().nullable(),
                  })
                  .nullable(),
              readAllOrders: formShowConfig.readAllOrders && yup.bool().nullable(),
              readAllAssets: formShowConfig.readAllAssets && yup.bool().nullable(),
              readOlderAssets: formShowConfig.readOlderAssets && yup.bool().nullable(),
              enablePayments: formShowConfig.enablePayments && yup.bool().nullable(),
            })
            .nullable(),
        confirmation:
          formShowConfig.confirmationAndWarning &&
          yup
            .string()
            .required('DASHBOARD_INTEGRATON_CONFIGURE_CHANGE_INLINE')
            .matches(/^CHANGE$/, 'DASHBOARD_INTEGRATON_CONFIGURE_CHANGE_INLINE')
            .nullable(),
      }),
    ),
  });

  const isActive = useWatch({ control, name: 'isActive' });
  const readAllOrders = useWatch({ control, name: 'metadata.permissions.readAllOrders' });
  const readAllAssets = useWatch({ control, name: 'metadata.permissions.readAllAssets' });
  const readOlderAssets = useWatch({ control, name: 'metadata.permissions.readOlderAssets' });
  const enablePayments = useWatch({ control, name: 'metadata.permissions.enablePayments' });

  return (
    <AlertDialog
      isOpen={true}
      title={t(
        actionData.kind === Kind.CREATE
          ? 'DASHBOARD_INTEGRATION_KEYS_MODAL_TITLE'
          : 'DASHBOARD_INTEGRATION_TITLE_MANAGE',
        { name: serviceName },
      )}
      cancel={
        isDirty && {
          label: t('DASHBOARD_CANCEL_CTA'),
          onClick: onClose,
          'data-testid': 'alert-cancel-button',
          type: 'gray',
        }
      }
      confirm={{
        label: t(getFormCtaText(kind, data)),
        onClick: handleSubmit(onSubmit),
      }}
      onClose={onClose}
      showCloseButton
      headerStyle={{ marginBottom: theme.spacing(2) }}
    >
      <form noValidate autoComplete="off">
        <Grid container>
          {formShowConfig.isActiveShown && (
            <Grid item xs={12} md={12}>
              <FormCheckBox
                label={t('DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT')}
                control={control}
                name="isActive"
                disabled={!formShowConfig.isActiveEnabled}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <FormInput
              defaultValue=""
              label={t('DASHBOARD_INTEGRATION_KEYS_MODAL_LABEL_KEY')}
              placeholder={t('DASHBOARD_INTEGRATION_KEYS_MODAL_LABEL_KEY')}
              id="name"
              name="name"
              margin="dense"
              testid="name-id-input"
              fullWidth
              errorobj={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormInput
              defaultValue=""
              label={t('DASHBOARD_INTEGRATON_MANAGE_CLIENT_ID_LABEL')}
              placeholder={t('DASHBOARD_INTEGRATON_MANAGE_CLIENT_ID_PLACEHOLDER')}
              id="client"
              name="client"
              margin="dense"
              testid="client-id-input"
              fullWidth
              errorobj={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormInput
              defaultValue=""
              label={t('DASHBOARD_INTEGRATON_MANAGE_CLIENT_SECRET_LABEL')}
              placeholder={t('DASHBOARD_INTEGRATON_MANAGE_CLIENT_SECRET_PLACEHOLDER')}
              id="secret"
              name="secret"
              margin="dense"
              testid="secret-input"
              fullWidth
              errorobj={errors}
              control={control}
              type="password"
            />
          </Grid>
          {formShowConfig.kind && (
            <Grid item xs={12} md={12} className={classes.kind}>
              <FormSelect
                defaultValue=""
                label="DASHBOARD_CREDENTIALS_MANAGE_LABEL"
                fullWidth
                id="kind"
                name="kind"
                testid="kind-input"
                errorobj={errors}
                control={control}
                options={possibleKindValues}
                onChange={handleKindChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          {formShowConfig.countries && (
            <Grid item xs={12} md={12} className={classes.countrySection}>
              <Typography className={classes.countryLabel} paragraph={true}>
                {t('DASHBOARD_INTEGRATION_MANAGE_COUNTRY_LABEL')}
              </Typography>
              <div className={classes.countrySelect}>
                <FormMultiSelect
                  placeholder="DASHBOARD_INTEGRATION_MANAGE_COUNTRY_SELECT_PLACEHOLDER"
                  label="DASHBOARD_INTEGRATION_MANAGE_COUNTRY_SELECT_LABEL"
                  fullWidth
                  id="metadata.countryCodes"
                  name="metadata.countryCodes"
                  errorobj={errors}
                  control={control}
                  options={plaidSupportedCountries}
                  clearAll={(): void => {
                    setValue('metadata.countryCodes', [], { shouldValidate: true });
                  }}
                  handleDelete={(code: string): void => {
                    const countryCodes = (getValues('metadata.countryCodes') as string[]) || [];
                    const newCountries = countryCodes.filter((key) => String(key) !== code);
                    setValue('metadata.countryCodes', newCountries, { shouldValidate: true });
                  }}
                  testid="country-input"
                />
              </div>
            </Grid>
          )}
          {formShowConfig.readAllOrders && (
            // TODO: make this into a reusable component
            <Grid container>
              <Typography
                className={`${classes.keysHeader} ${classes.permissionTitle}`}
                variant="h2"
              >
                {t('DASHBOARD_INTEGRATON_MANAGE_SCOPE_PERMISSIONS_LABEL')}
              </Typography>
              <Typography className={classes.permissionSubTitle} paragraph={true}>
                {t('DASHBOARD_INTEGRATON_MANAGE_SCOPE_PERMISSIONS_BODY')}
              </Typography>
              <FormSwitch
                controller={{
                  name: 'metadata.permissions.readAllOrders',
                  control,
                  defaultValue: false,
                }}
                input={{
                  color: 'primary',
                  'data-testid': 'permission-switch',
                  inputProps: { 'data-testid': 'permission-switch-input' },
                }}
                extras={{ errors }}
                label={{
                  label: readAllOrders
                    ? t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_ON')
                    : t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_OFF'),
                  labelPlacement: 'start',
                  className: readAllOrders
                    ? classes.permissionActiveLink
                    : classes.permissionInActiveLink,
                }}
              />
            </Grid>
          )}
          {formShowConfig.readAllAssets && (
            <Grid container>
              <Typography
                className={`${classes.keysHeader} ${classes.permissionTitle}`}
                variant="h2"
              >
                {t('DASHBOARD_INTEGRATON_MANAGE_SCOPE_PERMISSIONS_LABEL')}
              </Typography>
              <Typography className={classes.permissionSubTitle} paragraph={true}>
                <Trans
                  i18nKey="DASHBOARD_INTEGRATON_BANKING_MANAGE_SCOPE_PERMISSIONS_BODY"
                  components={{
                    a: (
                      <a
                        onClick={(e): void => openNewTab(e, PLAID_PERMISSION_ACCESS_URL)}
                        rel="noopener noreferrer"
                        className={classes.linkLearnMore}
                        href={PLAID_PERMISSION_ACCESS_URL}
                      >
                        {t('DASHBOARD_SEE_INSTRUCTIONS_CTA')}
                      </a>
                    ),
                  }}
                />
              </Typography>
              <FormSwitch
                controller={{
                  name: 'metadata.permissions.readAllAssets',
                  control,
                  defaultValue: false,
                }}
                input={{
                  color: 'primary',
                  'data-testid': 'readAllAssets-permission-switch',
                  inputProps: { 'data-testid': 'readAllAssets-permission-switch-input' },
                }}
                extras={{ errors }}
                label={{
                  label: readAllAssets
                    ? t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_ON')
                    : t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_OFF'),
                  labelPlacement: 'start',
                  className: readAllAssets
                    ? `${classes.permissionActiveLink} ${classes.multipleToggles}`
                    : `${classes.permissionInActiveLink} ${classes.multipleToggles}`,
                }}
              />
              {showLongerAccessToggle && (
                <>
                  <Typography className={classes.permissionSubTitle} paragraph={true}>
                    {t('DASHBOARD_INTEGRATON_BANKING_ENABLE_LONGER_ACCESS_BODY')}
                  </Typography>
                  <FormSwitch
                    controller={{
                      name: 'metadata.permissions.readOlderAssets',
                      control,
                      defaultValue: false,
                    }}
                    input={{
                      color: 'primary',
                      'data-testid': 'olderAssets-permission-switch',
                      inputProps: { 'data-testid': 'olderAssets-permission-switch-input' },
                      disabled: !readAllAssets,
                    }}
                    extras={{ errors }}
                    label={{
                      label: readOlderAssets
                        ? t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_ON')
                        : t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_OFF'),
                      labelPlacement: 'start',
                      className: readOlderAssets
                        ? classes.permissionActiveLink
                        : classes.permissionInActiveLink,
                    }}
                  />
                </>
              )}
            </Grid>
          )}
          {formShowConfig.enablePayments && (
            <Grid container>
              <Typography
                className={`${classes.keysHeader} ${classes.permissionTitle}`}
                variant="h2"
              >
                {t('DASHBOARD_INTEGRATON_ENABLE_QBO_PAYMENTS_LABEL')}
              </Typography>
              <Typography className={classes.permissionSubTitle} paragraph={true}>
                <Trans
                  i18nKey="DASHBOARD_INTEGRATON_ENABLE_QBO_PAYMENTS_BODY"
                  components={{
                    a: (
                      <a
                        onClick={(e): void => openNewTab(e, QUICKBOOKS_RAILZ_DOC_URL)}
                        rel="noopener noreferrer"
                        className={classes.linkLearnMore}
                        href={QUICKBOOKS_RAILZ_DOC_URL}
                      >
                        {t('DASHBOARD_SEE_INSTRUCTIONS_CTA')}
                      </a>
                    ),
                  }}
                />
              </Typography>
              <FormSwitch
                controller={{
                  name: 'metadata.permissions.enablePayments',
                  control,
                  defaultValue: false,
                }}
                input={{
                  color: 'primary',
                  'data-testid': 'payments-permission-switch',
                  inputProps: { 'data-testid': 'payments-permission-switch-input' },
                }}
                extras={{ errors }}
                label={{
                  label: enablePayments
                    ? t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_ON')
                    : t('DASHBOARD_INTEGRATION_PERMISSION_READ_ALL_TOGGLED_OFF'),
                  labelPlacement: 'start',
                  className: enablePayments
                    ? classes.permissionActiveLink
                    : classes.permissionInActiveLink,
                }}
              />
            </Grid>
          )}
          {formShowConfig.isActiveEnabled && isActive && (
            <Grid container>
              <Alert
                severity="warning"
                className={classes.confirmationAlert}
                iconMapping={{ warning: <WarningRoundedIcon fontSize="inherit" /> }}
              >
                <Trans
                  i18nKey="DASHBOARD_INTEGRATION_KEYS_MODAL_WARNING"
                  components={{ b: <strong /> }}
                />
              </Alert>
            </Grid>
          )}
          {formShowConfig.confirmationAndWarning && (
            <Grid container>
              <Alert
                severity="warning"
                className={classes.confirmationAlert}
                iconMapping={{ warning: <WarningRoundedIcon fontSize="inherit" /> }}
              >
                <Trans
                  i18nKey="DASHBOARD_INTEGRATON_CONFIGURE_WARNING"
                  components={{ b: <strong /> }}
                />
              </Alert>

              <Typography className={classes.confirmationLabel} paragraph={true}>
                <Trans
                  i18nKey="DASHBOARD_INTEGRATON_CONFIGURE_CHANGE"
                  components={{ b: <strong /> }}
                />
              </Typography>
              <Grid item xs={12} md={12}>
                <FormInput
                  className={classes.confirmationInput}
                  defaultValue=""
                  id="confirmation"
                  name="confirmation"
                  margin="dense"
                  testid="confirmation-input"
                  fullWidth
                  errorobj={errors}
                  control={control}
                  type="text"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </AlertDialog>
  );
}
