import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import style from '../style';
import storeService from '../../../../store';
// eslint-disable-next-line max-len
import { isCustomerApiSuccess } from '../../../../store/features/account/customer/customer.selector';
import { ErrorResponse } from '../../../../types/interface/error.interface';
import {
  deleteUserMfaApi,
  resetProfileError,
  resetProfileResponse,
} from '../../../../store/features/account/profile/profile.action';
import { getProfileError } from '../../../../store/features/account/profile/profile.selector';

import { showSnackbar } from 'helpers/common.helper';
import { AuthType } from 'types';
import { FormInput } from 'components/form';

interface IProps {
  isOpen: boolean;
  handleMfaToast: () => void;
}

interface IFormValue {
  authCode: string;
}

export const validationSchema = yup.object().shape({
  authCode: yup.string().required().nullable(),
});

const initialValues: IFormValue = {
  authCode: '',
};

const MfaVerifyForm: React.FC<IProps> = ({ isOpen = false, handleMfaToast }) => {
  const classes = style();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse>(getProfileError);

  const isSuccess = useSelector<ReturnType<typeof store.getState>, boolean>(isCustomerApiSuccess);

  const { handleSubmit, errors, control, reset, formState } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  useEffect(() => {
    if (error) {
      dispatch(resetProfileError());

      if (error.code === 401) {
        showSnackbar({
          type: 'error',
          message: t('DASHBOARD_SECURITY_MFA_AUTH_CODE_INVALID'),
        });
      } else {
        showSnackbar({
          type: 'error',
          message: t('DASHBOARD_SECURITY_2FACTOR_DISABLE_TOAST_ERROR'),
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetProfileResponse());
      showSnackbar({
        message: t('DASHBOARD_MFA_DISABLE_SUCCESS'),
        type: 'success',
      });
    }
  }, [isSuccess]);

  const onSubmit = async ({ authCode }): Promise<void> => {
    dispatch(deleteUserMfaApi({ token: authCode, authType: AuthType.GOOGLE }));
    handleMfaToast();
  };

  return (
    <>
      <Box>
        <Box flexGrow={1}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.formContainer}>
              <FormInput
                testid="input-verify"
                placeholder="DASHBOARD_SECURITY_MFA_AUTH_CODE_LABEL"
                label="DASHBOARD_SECURITY_MFA_AUTH_CODE_LABEL"
                variant="outlined"
                name="authCode"
                control={control}
                errorobj={errors}
                fullWidth
              />
              <div className={classes.actions}>
                <RailzButton
                  data-testid="cta-verify"
                  buttonType="submit"
                  size="large"
                  className={classes.submitButton}
                  isDisabled={Boolean(errors.authCode || isSubmitting)}
                  label={t('DASHBOARD_SECURITY_MFA_AUTH_CODE_CTA_SAVE')}
                />
              </div>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default MfaVerifyForm;
