import React from 'react';
import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

interface Props {
  submitDisabled: boolean;
  saveReport: (params: any) => Promise<void>;
}

export default function SaveButton({ submitDisabled, saveReport }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <RailzButton
      onButtonClick={saveReport}
      isDisabled={submitDisabled}
      data-testid="save-button"
      label={t('DASHBOARD_FINANCIAL_SUMMARY_APPLY')}
      grow
    />
  );
}
