/* eslint-disable max-len */
import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import { RailzButton } from '@railzai/railz-uikit-react';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { getMessage, resetAuthResponse } from '../../store/features/auth';

import storeService from '../../store';

import useStyle from './style';

import ChevronCircleIcon from 'assets/icons/chevron-circle';

import SignupForm from 'pages/signup/signup-form';

import Title from 'components/title';

import Logo from 'components/logo';
import SignUpBG from 'assets/images/SignUpBG.svg';
import { openNewTab } from 'helpers/open-new-tab';

const Signup: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const classes = useStyle();
  const message = useSelector<ReturnType<typeof store.getState>, string>(getMessage);

  useEffect(() => {
    if (message) {
      dispatch(resetAuthResponse());
      history.replace('/verification');
    }
    // eslint-disable-next-line
  }, [message]);

  const handleSignInNavigation = (): void => {
    history.push('/login');
  };

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.mainContent}>
          <Box className={classes.logoRailz}>
            <Logo />
          </Box>
          <RailzButton
            type="text primary"
            label={t('DASHBOARD_BACK_TO_LOGIN')}
            onButtonClick={handleSignInNavigation}
          >
            <span slot="prefix">
              <ArrowBack fontSize="small" />
            </span>
          </RailzButton>
          <Title className={classes.titleRailz} title="DASHBOARD_SIGNUP_HEADER" />
          <SignupForm />
        </div>
      </div>
      <div className={classes.masthead}>
        <div className={classes.illustration}>
          <img src={SignUpBG} alt="logo" />
        </div>
        <div className={classes.mastheadContent}>
          <div className={classes.content}>
            <h3>{t('DASHBOARD_SIGNUP_CONTENT_HEADER')}</h3>
            <ul className={classes.features}>
              <li className={classes.featureItem}>
                <span className={classes.bullet}>
                  <ChevronCircleIcon />
                </span>
                <Typography variant="body1">{t('DASHBOARD_SIGNUP_FEATURE_BULLET_1')}</Typography>
              </li>
              <li className={classes.featureItem}>
                <span className={classes.bullet}>
                  <ChevronCircleIcon />
                </span>
                <Typography variant="body1">{t('DASHBOARD_SIGNUP_FEATURE_BULLET_2')}</Typography>
              </li>
              <li className={classes.featureItem}>
                <span className={classes.bullet}>
                  <ChevronCircleIcon />
                </span>
                <Typography variant="body1">{t('DASHBOARD_SIGNUP_FEATURE_BULLET_3')}</Typography>
              </li>
            </ul>

            <Typography variant="body1">
              <Trans
                i18nKey="DASHBOARD_SIGNUP_CONTENT_CONTACT"
                components={{
                  a: (
                    <a
                      className={classes.inlineLink}
                      href="https://railz.ai/contact#contact-form"
                      onClick={(e): void => openNewTab(e, 'https://railz.ai/contact#contact-form')}
                      rel="noopener noreferrer"
                    >
                      {t('DASHBOARD_CTA_CONTACT_SALES')}
                    </a>
                  ),
                }}
              />
            </Typography>
          </div>
        </div>
        <div className={classes.footer}>
          <h4>{t('DASHBOARD_SIGNUP_FOOTER_HEADER')}</h4>
          <ul className={classes.links}>
            <li>
              <a
                className={classes.link}
                href="https://railz.ai/contact#contact-form"
                onClick={(e): void => openNewTab(e, 'https://railz.ai/contact#contact-form')}
                rel="noopener noreferrer"
              >
                {t('DASHBOARD_CTA_CONTACT_SALES')}
              </a>
            </li>
            <li>
              <a
                className={classes.link}
                href="https://docs.railz.ai/docs"
                onClick={(e): void => openNewTab(e, 'https://docs.railz.ai/docs')}
                rel="noopener noreferrer"
              >
                {t('DASHBOARD_CTA_DEVELOPER_DOCUMENTATION')}
              </a>
            </li>
            <li>
              <a
                className={classes.link}
                href="https://help.railz.ai/en/"
                onClick={(e): void => openNewTab(e, 'https://help.railz.ai/en/')}
                rel="noopener noreferrer"
              >
                {t('DASHBOARD_CTA_HELP_CENTER')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Signup;
