import { createReducer } from '@reduxjs/toolkit';

import * as Actions from './webhook.action';
import { WEBHOOK_INITIAL_STATE } from './webhook.state';

/**
 * webhook Reducers
 */
export const webhookReducer = createReducer(WEBHOOK_INITIAL_STATE, (builder) => {
  builder
    .addCase(Actions.RESET_STORAGE_WEBHOOK, () => WEBHOOK_INITIAL_STATE)
    .addCase(Actions.RESET_WEBHOOK_ERROR, (state) => ({
      ...state,
      error: null,
    }))
    .addCase(Actions.RESET_WEBHOOK_API_RESPONSE, (state) => ({
      ...state,
      response: null,
    }))
    .addCase(Actions.FETCH_WEBHOOK_API, (state) => ({
      ...state,
      webhooks: [],
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(Actions.FETCH_WEBHOOK_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: { message: 'fetch', success: true },
      webhooks: action.payload,
      loading: false,
    }))
    .addCase(Actions.FETCH_WEBHOOK_FAILURE_API, (state, action: any) => ({
      ...state,
      webhooks: [],
      response: { message: 'fetch', success: false },
      error: action.payload,
      loading: false,
    }))
    .addCase(Actions.CREATE_WEBHOOK_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(Actions.CREATE_WEBHOOK_SUCCESS_API, (state, action: any) => ({
      ...state,
      webhooks: [action.payload, ...state.webhooks],
      response: { message: 'create', success: true },
      loading: false,
    }))
    .addCase(Actions.CREATE_WEBHOOK_FAILURE_API, (state, action: any) => ({
      ...state,
      response: { message: 'create', success: false },
      error: action.payload,
      loading: false,
    }))
    .addCase(Actions.UPDATE_WEBHOOK_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(Actions.UPDATE_WEBHOOK_SUCCESS_API, (state, { payload }: any) => {
      const index = state.webhooks.findIndex(({ uuid }) => uuid === payload.uuid);
      const webhooks =
        index === -1
          ? state.webhooks
          : [...state.webhooks.slice(0, index), payload, ...state.webhooks.slice(index + 1)];

      return {
        ...state,
        webhooks,
        response: { message: 'update', success: true },
        loading: false,
      };
    })
    .addCase(Actions.UPDATE_WEBHOOK_FAILURE_API, (state, action: any) => ({
      ...state,
      response: { message: 'update', success: false },
      error: action.payload,
      loading: false,
    }))
    .addCase(Actions.DELETE_WEBHOOK_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(Actions.DELETE_WEBHOOK_SUCCESS_API, (state, { payload }: any) => ({
      ...state,
      webhooks: state.webhooks.filter(({ uuid }) => uuid !== payload),
      response: { message: 'delete', success: true },
      loading: false,
    }))
    .addCase(Actions.DELETE_WEBHOOK_FAILURE_API, (state, action: any) => ({
      ...state,
      response: { message: 'delete', success: false },
      error: action.payload,
      loading: false,
    }))
    .addCase(Actions.FETCH_WEBHOOK_HISTORY_API, (state) => ({
      ...state,
      webhookHistory: {
        meta: {},
        webhookHistory: [],
      },
      response: null,
      error: null,
      webhookHistoryLoading: true,
    }))
    .addCase(Actions.FETCH_WEBHOOK_HISTORY_SUCCESS, (state, action: any) => ({
      ...state,
      response: { message: 'fetch', success: true },
      webhookHistory: action.payload,
      webhookHistoryLoading: false,
    }))
    .addCase(Actions.FETCH_WEBHOOK_HISTORY_FAILURE, (state, action: any) => ({
      ...state,
      webhookHistory: {
        meta: {},
        webhookHistory: [],
      },
      response: { message: 'fetch', success: false },
      error: action.payload,
      webhookHistoryLoading: false,
    }));
});
