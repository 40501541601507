import { createSelector } from 'reselect';

import { AllState } from '../../state';

import { AuthState } from './auth.state';

/** Select Auth State */
export const getAuthState = (state: AllState): AuthState => state.auth;

export const getUserResponse = createSelector(getAuthState, (auth: AuthState) => auth && auth.user);

export const isAuthLoading = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.loading,
);

export const isMfaEnabled = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.user && auth.user.mfaEnabled,
);

export const getUserEmail = createSelector(getAuthState, (auth: AuthState) => auth && auth.email);

export const getErrorResponse = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.error,
);

export const getErrorMessageResponse = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.error && auth.error.message?.length > 0 && auth.error.message,
);

export const getMessage = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.response && auth.response.message,
);

export const isRefreshingToken = createSelector(getAuthState, (auth: AuthState) => {
  if (!auth) return false;
  const isCurrentlyLoggedIn = auth.access_token && auth.expiredOn && Date.now() < auth.expiredOn;
  const isLoading = auth.loading;

  const isRefreshedToken = auth.response && auth.response.message === 'refreshToken';
  return isRefreshedToken || (isCurrentlyLoggedIn && isLoading);
});

export const isRefreshedToken = createSelector(
  getAuthState,
  (auth: AuthState) =>
    auth && !!auth.response && !!auth.response.message && auth.response.message === 'refreshToken',
);

export const isAuthResponseSuccess = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.response && !!auth.response.success,
);

export const isPasswordChangeSuccess = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.response && auth.response.message === 'changePassword',
);

export const getExpiredOn = createSelector(
  getAuthState,
  (auth: AuthState) => auth && auth.expiredOn,
);

export const isUserLoggedIn = createSelector(getAuthState, (auth: AuthState) => {
  return auth && auth.expiredOn && Date.now() < auth.expiredOn;
});

export const isSandbox = createSelector(getAuthState, (auth: AuthState) => {
  return auth && auth.sandbox;
});

export const getAccessToken = createSelector(getAuthState, (auth: AuthState) => {
  return auth && auth.access_token;
});
