import { createReducer } from '@reduxjs/toolkit';

import {
  FETCH_WEBHOOK_CONFIG_SUCCESS_API,
  FETCH_WEBHOOK_CONFIG_FAILURE_API,
  UPDATE_WEBHOOK_CONFIG_API,
  UPDATE_WEBHOOK_CONFIG_FAILURE_API,
  UPDATE_WEBHOOK_CONFIG_SUCCESS_API,
  DELETE_WEBHOOK_CONFIG_API,
  DELETE_WEBHOOK_CONFIG_SUCCESS_API,
  DELETE_WEBHOOK_CONFIG_FAILURE_API,
  RESET_STORAGE_WEBHOOK_CONFIG,
  RESET_WEBHOOK_CONFIG_RESPONSE,
  RESET_WEBHOOK_CONFIG_ERROR,
} from './webhook-config.action';
import { WEBHOOK_CONFIG_INITIAL_STATE, WEBHOOK_CONFIG_STORE_NAME } from './webhook-config.state';

/**
 * webhook Config Reducers
 */
export const webhookConfigReducer = createReducer(WEBHOOK_CONFIG_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_WEBHOOK_CONFIG, () => WEBHOOK_CONFIG_INITIAL_STATE)
    .addCase(RESET_WEBHOOK_CONFIG_ERROR, (state) => ({ ...state, error: null }))
    .addCase(RESET_WEBHOOK_CONFIG_RESPONSE, (state) => ({ ...state, response: null }))
    .addCase(FETCH_WEBHOOK_CONFIG_SUCCESS_API, (state, action: any) => ({
      ...state,
      webhookConfig: action.payload,
      loading: false,
    }))
    .addCase(FETCH_WEBHOOK_CONFIG_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(UPDATE_WEBHOOK_CONFIG_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_WEBHOOK_CONFIG_SUCCESS_API, (state, action: any) => ({
      ...state,
      webhookConfig: action.payload,
      response: { success: true, message: 'Update Webhook Config Successful' },
      loading: false,
    }))
    .addCase(UPDATE_WEBHOOK_CONFIG_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(DELETE_WEBHOOK_CONFIG_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_WEBHOOK_CONFIG_SUCCESS_API, (state) => ({
      ...state,
      webhookConfig: null,
      response: { success: true, message: 'Webhook Config Deleted' },
      loading: false,
    }))
    .addCase(DELETE_WEBHOOK_CONFIG_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }));
});

export const webhookConfigReducerConfig = {
  [WEBHOOK_CONFIG_STORE_NAME]: webhookConfigReducer,
};
