import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import {
  resetCustomerResponse,
  updateCompanyApi,
} from '../../store/features/account/customer/customer.action';
import storeService from '../../store';
import {
  getCustomerError,
  isCustomerApiSuccess,
} from '../../store/features/account/customer/customer.selector';
import { ErrorResponse } from '../../types/interface/error.interface';

import CompanyForm from './company-form';
import useStyle from './style';

import { EventService } from 'services';
import { Header, ScrollView, View } from 'components';

interface Props {
  testid?: string;
}

const Company: React.FC<Props> = ({ testid = 'test-header-company-page' }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = storeService.getStore();

  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse>(getCustomerError);
  const apiSuccess = useSelector<ReturnType<typeof store.getState>, boolean>(isCustomerApiSuccess);

  useEffect(() => {
    if (error) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: t('DASHBOARD_COMPANY_UNKNOWN_FAILURE'),
      });
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (apiSuccess) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: t('DASHBOARD_COMPANY_UPDATE_SUCCESS'),
      });
      dispatch(resetCustomerResponse());
    }
    // eslint-disable-next-line
  }, [apiSuccess]);

  const companySubmit = async (values): Promise<void> => {
    dispatch(updateCompanyApi({ ...values, response: true }));
  };

  return (
    <>
      <Header
        drawerMenu={true}
        leftComponent={null}
        rightComponent={null}
        title={t('DASHBOARD_COMPANY_HEADER')}
        testId={testid}
      />
      <View>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.headDescription}
          role={'alert'}
          tabIndex={0}
        >
          <Trans i18nKey="DASHBOARD_COMPANY_DESCRIPTION" />
        </Typography>
        <ScrollView classNames={classes.scrollView}>
          <CompanyForm companySubmit={companySubmit} customerError={error} />
        </ScrollView>
      </View>
    </>
  );
};

export default Company;
