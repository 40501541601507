import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

export enum Kind {
  EDIT = 'edit',
  DELETE = 'delete',
  CREATE = 'create',
}

export interface TableAction {
  kind: Kind;
  ariaLabel: string;
  onClick: (value?: any) => void;
}

interface Props {
  actions: Array<TableAction>;
}

const TableActions = ({ actions }: Props): JSX.Element => (
  <>
    {actions.map(({ kind, ariaLabel, onClick }, index) => (
      <IconButton key={index} onClick={onClick} aria-label={ariaLabel}>
        {kind === Kind.EDIT ? <EditIcon /> : <DeleteIcon />}
      </IconButton>
    ))}
  </>
);

export default TableActions;
