import * as Sentry from '@sentry/react';
import React from 'react';
import 'translations';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IntercomProvider } from 'react-use-intercom';

import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux';

import store from './store';

import { Notification } from './components';
import Root from './pages';

import 'services/api.service';
import config from './config';

import ErrorView from 'components/error/error-boundaries';
import { EventProvider, ThemeProvider } from 'providers';

// Config Sentry
Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENVIRONMENT,
  release: config.VERSION,
  integrations: [Sentry.replayIntegration()],
  replaysSessionSampleRate: config.SENTRY_ENVIRONMENT === 'production' ? 0.1 : 1,
  replaysOnErrorSampleRate: 1.0,
});

const App: React.FC = () => {
  return (
    <IntercomProvider appId={config.INTERCOM_APP_ID}>
      <ThemeProvider>
        <Sentry.ErrorBoundary fallback={<ErrorView />}>
          <Provider store={store.getStore()}>
            <PersistGate loading={null} persistor={store.getPersistor()}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <EventProvider>
                  <Notification />
                  <Root />
                </EventProvider>
              </MuiPickersUtilsProvider>
            </PersistGate>
          </Provider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </IntercomProvider>
  );
};

export default App;
