import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';

import { Grid, IconButton } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from './style';

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  testId?: string;
  title?: string;
  message?: string | React.ReactNode;
  messageComponent?: {
    message: string | React.ReactNode;
    color: string;
  };
  cancel?: {
    label: string;
    variant?: 'text' | 'outlined' | 'contained';
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    asText?: boolean;
    style?: any;
    type?: 'primary' | 'gray';
    ['data-testid']?: string;
  };
  confirm?: {
    label: string;
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    type?: 'primary' | 'error';
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    style?: any;
    icon?: React.ReactNode;
    ['data-testid']?: string;
  };
  onClose?: () => void;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  headerStyle?: any;
  maxWidth?: any;
  classDialog?: any;
  classContent?: string;
}

const AlertDialog: React.FC<IProps> = ({
  isOpen = false,
  isLoading = false,
  testId = '',
  title = '',
  message = '',
  messageComponent,
  cancel,
  confirm,
  onClose,
  children,
  showCloseButton = false,
  headerStyle,
  maxWidth = 'sm',
  classDialog = '',
  classContent = '',
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [open, setOpen] = React.useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid={testId || 'alert-dialog'}
      aria-labelledby={title && 'alert-dialog-title'}
      aria-describedby={message && 'alert-dialog-description'}
      // Hack - this has been fixed on MUI 5.x
      // See https://github.com/mui-org/material-ui/pull/24500
      TransitionProps={{ role: 'presentation' } as any}
      disableEnforceFocus
      fullWidth
      maxWidth={maxWidth}
      className={classDialog}
    >
      <div className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.header}
          style={headerStyle}
        >
          <Grid item xs={10} sm={10} md={10}>
            {title && (
              <Typography id="alert-dialog-title" variant="h1" className={classes.title}>
                {title}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} sm={2} md={2} className={classes.closeButtonDiv}>
            {showCloseButton ? (
              <IconButton
                aria-label={t('DASHBOARD_CLOSE_MODAL_DESCRIPTION', { title })}
                data-testid={testId ? `${testId}-close-button` : 'alert-dialog-close-button'}
                className={classes.closeButton}
                onClick={onClose}
                autoFocus
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
        <DialogContent
          className={`${classes.noMarginNoPadding} ${classes.dialogContent} ${classContent}`}
        >
          {message && (
            <DialogContentText id="alert-dialog-description" className={classes.description}>
              {message}
            </DialogContentText>
          )}
          {messageComponent && (
            <DialogContentText
              id="alert-dialog-description"
              style={{ color: messageComponent.color }}
              className={classes.description}
            >
              {messageComponent.message}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
        {(cancel || confirm) && (
          <DialogActions className={classes.actions}>
            {cancel && (
              <RailzButton
                onClick={cancel.onClick}
                type="text primary"
                isDisabled={cancel.disabled}
                className={classes[cancel.type] + ' ' + cancel.className || ''}
                style={cancel.style}
                data-testid={cancel['data-testid'] || 'alert-cancel-button'}
                label={cancel.label}
              />
            )}
            {confirm && (
              <RailzButton
                onClick={confirm.onClick}
                color="primary"
                isDisabled={confirm.disabled}
                style={confirm.style}
                className={classes[confirm.type] + ' ' + confirm.className || ''}
                data-testid={confirm['data-testid'] || 'alert-confirm-button'}
                label={confirm.label}
                loading={isLoading}
              >
                {confirm.icon && <span slot="prefix">{confirm.icon}</span>}
              </RailzButton>
            )}
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
};

export default AlertDialog;
