import axios from 'axios';

import Config from 'config';
import { CustomerSendMail } from 'store/features/account/customer/customer.state';
import { CustomerUrl, PayloadGetUrl, ResponseGetUrl } from 'types';

class CustomerService {
  async verifyUser(token): Promise<any> {
    return await axios.get(`${Config.SERVER_URL}/customers/team/verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      external: true,
    });
  }

  async deleteCompanyLogo(): Promise<void> {
    await axios.delete(`${Config.SERVER_URL}/customers/companyLogo`);
  }

  async updateProfile(data: any): Promise<any> {
    return await axios.put(`${Config.SERVER_URL}/customers/user`, data);
  }

  async uploadProfile(data: any): Promise<CustomerUrl> {
    return await axios.post(`${Config.SERVER_URL}/customers/user/profile/upload`, data);
  }

  async updateCompany(data: any): Promise<void> {
    return await axios.put(`${Config.SERVER_URL}/customers/company`, data);
  }

  async getUrlTeam(payload?: PayloadGetUrl): Promise<ResponseGetUrl> {
    return await axios.post(`${Config.SERVER_URL}/sites/getTeamUrl`, payload);
  }

  async sendEmail(payload: CustomerSendMail): Promise<any> {
    return await axios.post(`${Config.SERVER_URL}/customers/user/sendEmail`, payload);
  }
}

export default new CustomerService();
