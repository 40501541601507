import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { RailzButton } from '@railzai/railz-uikit-react';

import { ServiceWithIntegrations } from '../../../types';

import { IntegrationForm } from '../integration-form';

import { ComingSoon } from './service-list-item-components/coming-soon';

import { ServiceName } from './service-list-item-components/service-name';

import { ServiceListItemTable } from './service-list-item-components/service-list-item-table';
// eslint-disable-next-line max-len
import { AllIntegrationsOfServiceToggle } from './service-list-item-components/all-integrations-of-service-toggle';

import Accordion from 'components/accordion/accordion';

import { View } from 'components';

import { CustomerState } from 'store/features/account/customer/customer.state';
import { Kind } from 'components/table-actions/table-actions';
import { showSnackbar } from 'helpers/common.helper';

export const ServiceCollapsableItem = ({
  expandedServiceName = '',
  toggleExpandedServiceName,
  service,
  user,
  isLoading,
  classes,
}: {
  expandedServiceName: string;
  toggleExpandedServiceName: () => void;
  service: ServiceWithIntegrations;
  user: CustomerState;
  isLoading: boolean;
  classes: Record<string, string>;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openFormData, setOpenFormData] =
    useState<Parameters<typeof IntegrationForm>['0']['actionData']>(null);

  function openForm(uuid?: string): void {
    if (uuid) {
      setOpenFormData({ kind: Kind.EDIT, data: service, uuid });
    } else {
      if (!service.canAddMoreIntegrations) {
        return showSnackbar({ message: t('DASHBOARD_INTEGRATON_KEYS_CTA_MAX'), type: 'error' });
      }
      setOpenFormData({ kind: Kind.CREATE, data: service });
    }
  }

  return (
    <React.Fragment key={service.name}>
      {openFormData && (
        <IntegrationForm onClose={(): void => setOpenFormData(null)} actionData={openFormData} />
      )}
      <Accordion
        dataTestId={`accordion-${service.name}`}
        key={`accordion-${service.name}`}
        classNameAccordion={classes.accordionRoot}
        className={classes.accordion}
        showAccordion={service.isConfigurable}
        muiProps={{
          defaultExpanded: false,
          onChange: toggleExpandedServiceName,
          expanded: expandedServiceName === service.name,
        }}
        header={
          <View
            key={service.name}
            flexDirection="row"
            alignItems="center"
            className={classes.serviceAccordionSummary}
            data-is-configurable={service.isConfigurable}
          >
            <ServiceName service={service} testid={`serviceName-${service.name}`} />
            <AllIntegrationsOfServiceToggle
              service={service}
              user={user}
              classes={classes}
              dispatch={dispatch}
            />

            <p
              className={classes.accordionSummaryConfigureText}
              data-is-configurable={service.isConfigurable}
            >
              {service.isConfigurable && t('DASHBOARD_INTEGRATION_CTA_MANAGE')}
            </p>

            <ComingSoon service={service} user={user} classes={classes} />
          </View>
        }
        children={
          service.isConfigurable && (
            <div className={classes.accordionContent}>
              <div className={classes.topRow}>
                <div className="titleAndDescription">
                  <Typography className={classes.tableTitle}>
                    {t('DASHBOARD_INTEGRATION_KEYS_TITLE')}
                  </Typography>
                  <Typography className={classes.tableSubtitle}>
                    {t('DASHBOARD_INTEGRATION_KEYS_DESCRIPTION')}
                  </Typography>
                </div>
                <RailzButton
                  onClick={(): void => openForm()}
                  label={t('DASHBOARD_INTEGRATION_KEYS_CTA_ADD')}
                ></RailzButton>
              </div>
              <ServiceListItemTable
                service={service}
                isLoading={isLoading}
                classes={classes}
                openForm={openForm}
              />
            </div>
          )
        }
      />
    </React.Fragment>
  );
};
