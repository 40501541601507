const CDN_SCRIPT = `https://cdn.jsdelivr.net/npm/@railzai/railz-connect/dist/railz-connect.js`;

export const CONNECT_SCRIPT_INFO = (
  widgetId: string,
): string => `<!-- Insert this div where you want Railz Connect to be initialized -->
<div id="railz-connect"></div>


<!-- Start of Railz Connect script -->
<script src="${CDN_SCRIPT}"></script>
<script>
  const widget = new RailzConnect();
  widget.mount({
    parentElement: document.getElementById('railz-connect'),
    widgetId: '${widgetId}',
    // Add additional Railz Connect configuration parameters here
  });
</script>
<!-- End of Railz Connect script -->`;

export const CONNECT_WIDGET_ID_INFO = (widgetId: string): string => `${widgetId}`;

export const CONNECT_NPM_INSTALL_INFO = `<!-- Install/Update via NPM -->
npm install @railzai/railz-connect --save

<!-- Install/Update via YARN -->
yarn add @railzai/railz-connect
`;

export const CONNECT_NPM_SCRIPT_INFO = (
  widgetId: string,
): string => `// Use this script as a guide to add Railz Connect to your code
import RailzConnect from '@railzai/railz-connect';
import { useEffect, useRef } from "react";

function App() {
  const ref = useRef();
  const widget = new RailzConnect();

  useEffect(() => {
    widget.mount({
      parentElement: ref.current,
      widgetId: '${widgetId}',
      // Add additional Railz Connect configuration parameters here
    });
  }, []);

  return (
    <div id='railz-connect' ref={ref}></div>
  );
}

export default App;
`;
