import { useHistory, useLocation } from 'react-router';

type QueryParamUpdater = (params: { [key: string]: any }) => void;

/**
 * Custom hook with the ability to read and update the query string of the URL.
 * Returns an array of the current query params and a function to update them.
 * The setter function is not destructive of other query params that are not passed as parameter.
 * To remove a query param, pass null, undefined, or empty string as value.
 */
const useQuerySearchUpdate = (): [URLSearchParams, QueryParamUpdater] => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location?.search);
  const updateParams: QueryParamUpdater = (params) => {
    Object.keys(params).forEach((param) => {
      if (params[param]) {
        searchParams.set(param, String(params[param]));
      } else {
        searchParams.delete(param);
      }
    });
    if (history) {
      history.replace({ search: searchParams.toString() });
    }
  };

  return [searchParams, updateParams];
};

export default useQuerySearchUpdate;
