/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Config from '../config';

class Store {
  store: any;

  constructor() {
    this.store =
      Config.NODE_ENV === 'development'
        ? require('./store.development')
        : require('./store.production');
  }

  getStore() {
    return this.store.default;
  }

  getPersistor() {
    return this.store.persistor;
  }
}

export default new Store();
