import { createSelector } from 'reselect';

import { AllState } from '../../state';

import { ConnectState } from './connect.state';

/** Select Connect State */
export const getConnectState = (state: AllState): ConnectState => state.connect;

export const getConnectUrl = createSelector(getConnectState, (connect: ConnectState) => {
  return connect && connect.connectUrl;
});

export const getIps = createSelector(getConnectState, (connect: ConnectState) => {
  return connect && !connect.loading && connect.ipWhitelists;
});

export const isLoading = createSelector(
  getConnectState,
  (connect: ConnectState) => connect && connect.loading,
);

export const getConnectError = createSelector(
  getConnectState,
  (connect: ConnectState) => connect && connect.error,
);

export const getConnectErrorMessage = createSelector(
  getConnectState,
  (connect: ConnectState) => connect && connect.error && connect.error.message,
);

export const isConnectApiSuccess = createSelector(
  getConnectState,
  (connect: ConnectState) => connect && connect.response && connect.response.success,
);

export const getConnectApiResponse = createSelector(
  getConnectState,
  (connect: ConnectState) => connect && connect.response && connect.response.message,
);
