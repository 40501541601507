import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import moment, { Moment as MomentType } from 'moment-timezone';

import { ReportFrequency, ReportType } from '../../../types';

import DatePicker from 'components/date-picker';

interface Props {
  dateView: any;
  selectedStartDate: MomentType;
  firstRecordDate: Date | null;
  disableDates: boolean;
  handleSelected: (string) => void;
  selectedReportType?: string;
}

export default function StartDate({
  dateView,
  selectedStartDate: parentSelectedStartDate,
  firstRecordDate,
  disableDates,
  handleSelected,
  selectedReportType,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().subtract(1, 'month').startOf(ReportFrequency.MONTH),
  );

  useEffect(() => {
    if (parentSelectedStartDate && parentSelectedStartDate.isValid()) {
      const momentFirstRecordDate = moment(firstRecordDate);
      if (
        momentFirstRecordDate.isAfter(parentSelectedStartDate) &&
        selectedReportType !== ReportType.FINANCIAL_FORECASTS
      ) {
        setSelectedStartDate(momentFirstRecordDate);
      } else {
        setSelectedStartDate(parentSelectedStartDate);
      }
    }
  }, [parentSelectedStartDate]);

  const onChange = (date): void => {
    setSelectedStartDate(date);
    handleSelected && handleSelected(date);
  };

  return (
    <DatePicker
      label={t('DASHBOARD_REPORT_START_DATE_LABEL')}
      placeholder={t('DASHBOARD_REPORT_START_DATE_PLACEHOLDER')}
      view={dateView}
      value={selectedStartDate}
      minDate={
        selectedReportType === ReportType.FINANCIAL_FORECASTS
          ? moment(firstRecordDate).startOf('month').toDate() || selectedStartDate.toDate()
          : firstRecordDate || selectedStartDate.toDate()
      }
      minDateErrorMessage={t('DASHBOARD_REPORT_DATE_START_ERROR_FIRST_RECORD', {
        date: moment(firstRecordDate).format('MM-DD-YYYY'),
      })}
      maxDateErrorMessage={t('DASHBOARD_REPORT_DATE_ERROR_START_CURRENT_YEAR')}
      invalidDateMessage={t('DASHBOARD_REPORT_DATE_INVALID_DATE')}
      ariaLabel={t('DASHBOARD_REPORT_START_DATE_ARIA')}
      disableFuture={true}
      disabled={disableDates}
      onChange={onChange}
      size="small"
    />
  );
}
