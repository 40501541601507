import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { Alert } from '@material-ui/lab';

import useStyles from './style';

import { FormInput, FormSelect } from 'components/form';
import { formChange, generateDropdown, showSnackbar } from 'helpers/common.helper';
import { Role, TeamUserResponse } from 'types';

import AlertDialog from 'components/dialog';

import {
  resetTeamError,
  resetTeamResponse,
  updateTeamMemberRoleApi,
} from 'store/features/account/team/team.action';
import { getTeamApiResponse, getTeamError } from 'store/features/account/team/team.selector';
import { getUserRole } from 'store/features/account/profile/profile.selector';

import UserDetails from 'components/user-details';

const roleOptions = generateDropdown(Role);

const validationSchema = yup.object().shape({
  role: yup.string().required('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE_REQUIRED'),
});

interface FormProp {
  onClose: () => void;
  teamUuid: string;
  member: TeamUserResponse;
}

const UpdateTeamMemberForm = ({ onClose, teamUuid, member }: FormProp): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { handleSubmit, errors, control, formState, setError, reset, getValues, watch } = useForm({
    mode: 'onTouched',
    defaultValues: { email: member.email, role: member.role },
    resolver: yupResolver(validationSchema),
  });

  const [roleSelectableOptions, setRoleSelectableOptions] = useState(roleOptions);
  const role = useSelector(getUserRole);

  const [showSuperAdminUpgradeAlert, setShowSuperAdminUpgradeAlert] = useState(false);
  const [showSuperAdminDowngradeAlert, setShowSuperAdminDowngradeAlert] = useState(false);
  const teamErrorMessage = useSelector(getTeamError);
  const teamResponse = useSelector(getTeamApiResponse);

  const onSubmitData = ({ role }): void => {
    if (!formChange({ ...member, role }, member)) {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    } else {
      if (formState.isSubmitting || formState.isSubmitted) return;
      dispatch(
        updateTeamMemberRoleApi({
          memberUuid: member.userUuid,
          memberTeams: [
            {
              role,
              teamUuid,
            },
          ],
        }),
      );
    }
  };

  useEffect(() => {
    setRoleSelectableOptions(
      role !== Role.SUPER_ADMINISTRATOR
        ? roleOptions.filter(({ value }) => value !== Role.SUPER_ADMINISTRATOR)
        : roleOptions,
    );
  }, [role]);

  useEffect(() => {
    if (formState.isSubmitSuccessful || (formState.isSubmitting && formState.isValid)) {
      reset(getValues(), { dirtyFields: true, isSubmitted: true });
      dispatch(resetTeamResponse());
      if (teamErrorMessage) {
        dispatch(resetTeamError());
        if (Array.isArray(teamErrorMessage.message)) {
          const message = teamErrorMessage.message[0];
          setError(`role`, { message });
        }
        showSnackbar({ message: t('DASHBOARD_BRAND_DATA_FAILED'), type: 'error' });
      }
      if (teamResponse) {
        showSnackbar({ message: t('DASHBOARD_TEAM_MEMBER_EDIT_SUCESS_TOAST'), type: 'success' });
        onClose();
      }
    }
  }, [teamErrorMessage, teamResponse]);

  const roleValue = watch('role');

  useEffect(() => {
    if (member.role === Role.SUPER_ADMINISTRATOR) {
      if (roleValue !== Role.SUPER_ADMINISTRATOR) {
        setShowSuperAdminDowngradeAlert(true);
      } else {
        setShowSuperAdminDowngradeAlert(false);
      }
    }
    if (member.role !== Role.SUPER_ADMINISTRATOR) {
      if (roleValue === Role.SUPER_ADMINISTRATOR) {
        setShowSuperAdminUpgradeAlert(true);
      } else {
        setShowSuperAdminUpgradeAlert(false);
      }
    }
  }, [roleValue]);

  return (
    <AlertDialog
      isOpen={true}
      title={t('DASHBOARD_TEAM_EDIT_SUBHEADER')}
      cancel={
        formState.isDirty && {
          label: t('DASHBOARD_TEAM_ADD_CTA_CANCEL'),
          onClick: onClose,
          'data-testid': 'cancel-button',
          type: 'gray',
        }
      }
      confirm={{
        label: t('DASHBOARD_TEAM_EDIT_SAVE_CTA'),
        onClick: handleSubmit(onSubmitData),
        'data-testid': 'cta-button',
      }}
      onClose={onClose}
      showCloseButton
    >
      <UserDetails user={member}></UserDetails>

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitData)}
        className={classes.form}
      >
        <FormInput
          placeholder="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_EMAIL"
          label="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_EMAIL"
          name={`email`}
          margin="dense"
          testid={`email`}
          fullWidth
          defaultValue=""
          inputProps={{ readOnly: true }}
          errorobj={errors}
          control={control}
          type="string"
          disabled
        />

        <FormSelect
          placeholder="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE"
          label="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE"
          fullWidth
          margin="dense"
          name={`role`}
          errorobj={errors}
          control={control}
          options={roleSelectableOptions}
          testid={`role`}
        />

        {showSuperAdminUpgradeAlert ? (
          <Alert
            severity="warning"
            icon={<WarningRoundedIcon />}
            classes={{ root: classes.alert }}
            data-testid="super-admin-upgrade-warning"
          >
            <Typography variant="body2">
              {t('DASHBOARD_MEMBER_EDIT_MODAL_SUPER_ADMIN_UPGRADE_WARNING')}
            </Typography>
          </Alert>
        ) : null}

        {showSuperAdminDowngradeAlert ? (
          <Alert
            severity="warning"
            icon={<WarningRoundedIcon />}
            classes={{ root: classes.alert }}
            data-testid="super-admin-downgrade-warning"
          >
            <Typography variant="body2">
              {t('DASHBOARD_MEMBER_EDIT_MODAL_SUPER_ADMIN_DOWNGRADE_WARNING')}
            </Typography>
          </Alert>
        ) : null}
      </form>
    </AlertDialog>
  );
};

export default UpdateTeamMemberForm;
