import { call, put, takeLatest } from 'redux-saga/effects';

import { BusinessCrudResponse, Services } from '../../../types';

import { default as BusinessService } from './business.service';
import {
  CREATE_BUSINESS_API,
  createBusinessesFailureApi,
  createBusinessesSuccessApi,
  DISCONNECT_CONNECTION,
  disconnectConnectionFailureApi,
  disconnectConnectionSuccessApi,
  FETCH_BUSINESSES,
  FETCH_BUSINESSES_GET_SERVICES,
  FETCH_BUSINESSES_STATS,
  FETCH_SINGLE_BUSINESSES,
  fetchBusinessesFailureApi,
  fetchBusinessesSuccessApi,
  fetchBusinessStatsFailureApi,
  fetchBusinessStatsSuccessApi,
  fetchServicesFailureApi,
  fetchServicesSuccessApi,
  fetchSingleBusinessesFailureApi,
  fetchSingleBusinessesSuccessApi,
  GENERATE_BUSINESS_URL_API,
  generateBusinessUrlFailureApi,
  generateBusinessUrlSuccessApi,
  RENAME_BUSINESS_API,
  renameBusinessFailureApi,
  renameBusinessSuccessApi,
  DELETE_BUSINESS_API,
  deleteBusinessFailureApi,
  fetchBusinessSuggestionsSuccessApi,
  FETCH_BUSINESS_SUGGESTIONS,
} from './business.action';

import {
  BusinessAction,
  BusinessEventStat,
  BusinessResponseApi,
  GenerateUrlResponse,
} from './business.state';

function* disconnectConnectionApiCall(action: BusinessAction): Generator<any> {
  try {
    yield call(BusinessService.deleteConnection, action.payload);
    yield put(disconnectConnectionSuccessApi({ success: true, message: 'disconnectBusiness' }));
  } catch (error) {
    yield put(
      disconnectConnectionFailureApi({
        ...error,
        message: ['disconnectBusiness'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* disconnectConnectionSaga(): Generator<any> {
  yield takeLatest(DISCONNECT_CONNECTION, disconnectConnectionApiCall);
}

function* deleteBusinessSaga(): Generator<any> {
  yield takeLatest(DELETE_BUSINESS_API, deleteBusinessApiCall);
}

function* deleteBusinessApiCall(action: BusinessAction): Generator<any> {
  try {
    yield call(BusinessService.deleteBusiness, action.payload);
    yield put(disconnectConnectionSuccessApi({ success: true, message: 'deleteBusiness' }));
  } catch (error) {
    yield put(
      deleteBusinessFailureApi({
        ...error,
        message: ['deleteBusiness'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* fetchServicesApiCall(): Generator<any> {
  try {
    const services = (yield call(BusinessService.getServices) as unknown) as Services[];
    yield put(fetchServicesSuccessApi(services));
  } catch (error) {
    yield put(fetchServicesFailureApi(error));
  }
}

function* fetchServicesSaga(): Generator<any> {
  yield takeLatest(FETCH_BUSINESSES_GET_SERVICES, fetchServicesApiCall);
}

function* fetchBusinessStatsApiCall(action: BusinessAction): Generator<any> {
  try {
    const stats = (yield call(
      BusinessService.getStats,
      action.payload,
    ) as unknown) as BusinessEventStat;
    yield put(fetchBusinessStatsSuccessApi(stats));
  } catch (error) {
    yield put(fetchBusinessStatsFailureApi(error));
  }
}

function* fetchBusinessStatsSaga(): Generator<any> {
  yield takeLatest(FETCH_BUSINESSES_STATS, fetchBusinessStatsApiCall);
}

function* fetchBusinessesApiCall(action: BusinessAction): Generator<any> {
  try {
    const { data, meta } = (yield call(
      BusinessService.getBusinesses,
      action.payload,
    ) as unknown) as BusinessResponseApi;
    yield put(fetchBusinessesSuccessApi({ businesses: data, meta }));
  } catch (error) {
    yield put(
      fetchBusinessesFailureApi({
        ...error,
        message: ['action.payload.type'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* fetchBusinessSuggestionsSaga(): Generator<any> {
  yield takeLatest(FETCH_BUSINESS_SUGGESTIONS, fetchBusinessSuggestionsApiCall);
}

function* fetchBusinessSuggestionsApiCall(action: BusinessAction): Generator<any> {
  try {
    const { data, meta } = (yield call(
      BusinessService.getBusinesses,
      action.payload,
    ) as unknown) as BusinessResponseApi;
    yield put(fetchBusinessSuggestionsSuccessApi({ businesses: data, meta }));
  } catch (error) {
    yield put(
      fetchBusinessesFailureApi({
        ...error,
        message: ['action.payload.type'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* fetchBusinessesSaga(): Generator<any> {
  yield takeLatest(FETCH_BUSINESSES, fetchBusinessesApiCall);
}

function* fetchSingleBusinessesApiCall(action: BusinessAction): Generator<any> {
  try {
    const { data: businesses, meta } = (yield call(
      BusinessService.getBusinesses,
      action.payload,
    ) as unknown) as BusinessResponseApi;
    if (businesses.length < 1) {
      window.location.replace('/businesses');
    }
    yield put(fetchSingleBusinessesSuccessApi({ businesses, meta }));
  } catch (error) {
    yield put(
      fetchSingleBusinessesFailureApi({
        ...error,
        message: ['action.payload.type'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* fetchSingleBusinessesSaga(): Generator<any> {
  yield takeLatest(FETCH_SINGLE_BUSINESSES, fetchSingleBusinessesApiCall);
}

function* createBusinessApiCall(action: BusinessAction): Generator<any> {
  try {
    const createdBusiness = (yield call(
      BusinessService.createBusinesses,
      action.payload,
    )) as BusinessCrudResponse;
    yield put(
      createBusinessesSuccessApi({ success: true, message: 'createBusiness', ...createdBusiness }),
    );
  } catch (error) {
    yield put(
      createBusinessesFailureApi({
        ...error,
        message: ['createBusiness'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* createBusinessSaga(): Generator<any> {
  yield takeLatest(CREATE_BUSINESS_API, createBusinessApiCall);
}

function* renameBusinessApiCall(action: BusinessAction): Generator<any> {
  try {
    yield call(BusinessService.renameBusiness, action.payload);
    yield put(renameBusinessSuccessApi({ success: true, message: 'renameBusiness' }));
  } catch (error) {
    yield put(
      renameBusinessFailureApi({
        ...error,
        message: ['renameBusiness'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* renameBusinessSaga(): Generator<any> {
  yield takeLatest(RENAME_BUSINESS_API, renameBusinessApiCall);
}

function* generateBusinessUrlApiCall(action: BusinessAction): Generator<any> {
  try {
    const response = (yield call(
      BusinessService.generateUrl,
      action.payload,
    ) as unknown) as GenerateUrlResponse;
    yield put(generateBusinessUrlSuccessApi(response.url));
  } catch (error) {
    yield put(
      generateBusinessUrlFailureApi({
        ...error,
        message: ['generateUrl'],
        status: error.status || error.statusCode,
      }),
    );
  }
}

function* generateBusinessUrlSaga(): Generator<any> {
  yield takeLatest(GENERATE_BUSINESS_URL_API, generateBusinessUrlApiCall);
}

export {
  disconnectConnectionApiCall,
  fetchServicesApiCall,
  fetchBusinessStatsApiCall,
  fetchBusinessesApiCall,
  fetchBusinessSuggestionsApiCall,
  fetchSingleBusinessesApiCall,
  createBusinessApiCall,
  renameBusinessApiCall,
  generateBusinessUrlApiCall,
  deleteBusinessApiCall,
};
export const businessSaga = [
  disconnectConnectionSaga(),
  fetchServicesSaga(),
  fetchBusinessStatsSaga(),
  fetchBusinessesSaga(),
  fetchBusinessSuggestionsSaga(),
  fetchSingleBusinessesSaga(),
  createBusinessSaga(),
  renameBusinessSaga(),
  generateBusinessUrlSaga(),
  deleteBusinessSaga(),
];
