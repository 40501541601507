/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import Moment from 'moment-timezone';

import { useTranslation } from 'react-i18next';

import CloseIcon from '@material-ui/icons/Close';

import useStyle from './style';

import { WEBHOOK_STATE, WebhookHistory } from 'types';
import ConnectCode, { ConnectCodeProps } from 'pages/connect/setup/connect.code';
import { stringifyWithErorHandling } from 'layout/helpers/util';

interface Props {
  webhookHistory?: WebhookHistory;
  handleClose?: () => void;
}

export default function WebhookHistoryDetail({ webhookHistory, handleClose }: Props): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();
  const [formattedWebhookData, setFormattedWebhookData] = useState(null);
  const [payloadCode, setPayloadCode] = useState<ConnectCodeProps>({
    code: '',
    header3: 'Webhook Payload',
    identifier: 'payload',
    language: 'json',
  });
  const [responseCode, setResponseCode] = useState<ConnectCodeProps>({
    code: '',
    header3: 'Webhook Response',
    identifier: 'response',
    language: 'json',
  });
  const [copied, setCopied] = useState('');

  useEffect(() => {
    setCopied('');
    webhookHistory && formatWebhookData(webhookHistory);
    setPayloadCode({
      code: `${stringifyWithErorHandling({
        data: webhookHistory.payload,
        requestId: webhookHistory.requestId,
        ...(webhookHistory?.requestIdParam != null && {
          requestIdParam: webhookHistory.requestIdParam,
        }),
      })}`,
      header3: 'Webhook Payload',
      identifier: 'payload',
      language: 'plaintext',
    });
    setResponseCode({
      code: `${stringifyWithErorHandling(webhookHistory?.response)}`,
      header3: 'Response',
      identifier: 'response',
      language: 'plaintext',
    });
  }, [webhookHistory]);

  const formatWebhookData = (webhookDetails): void => {
    const formattedData = {
      STATUS: webhookDetails.statusCode || '-',
      'REQUEST ID': webhookDetails.requestId,
      'BUSINESS NAME': webhookDetails.businessName,
      'BUSINESS UUID': webhookDetails.payload?.businessUuid || '-',
      'CONNECTION UUID': webhookDetails.connectionUuid,
      'SERVICE NAME': webhookDetails.serviceName,
      TIMESTAMP: Moment(webhookDetails.timestamp).format('DD-MMM-YYYY hh:mm:ss A'),
      URL: webhookDetails.url,
    };
    if (webhookDetails?.requestIdParam)
      formattedData['REQUEST ID PARAM'] = webhookDetails?.requestIdParam;

    setFormattedWebhookData(formattedData);
  };

  return (
    <>
      <div className={classes.webhookHistoryDetail} data-testid="webhook-detail-tab">
        <Box className={`${classes.detailHeader} ${classes.smallPaddingLeft} `}>
          <div>
            <IconButton aria-label="delete" size="small" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className={classes.smallPaddingLeft}>
            <Chip
              className={`${classes.chip} ${
                webhookHistory.status === 'success' ? classes.chipActive : classes.chipInactive
              }`}
              data-testid={`webhook-secret-status-chip`}
              label={WEBHOOK_STATE[webhookHistory.status]}
              size="small"
            />
          </div>
          <div className={`${classes.headerText} ${classes.smallPaddingLeft}`}>
            {webhookHistory?.method.toUpperCase()}
          </div>
          <Divider className={classes.vDivider} orientation="vertical" variant="middle" flexItem />
          <div className={`${classes.headerText} ${classes.longText}`}>{webhookHistory?.event}</div>
          <Divider className={classes.vDivider} orientation="vertical" variant="middle" flexItem />
          <div className={`${classes.headerText} ${classes.longText}`}>{webhookHistory?.url}</div>
        </Box>
        <Divider className={classes.hDivider} style={{ marginTop: '9px' }} />
        <Box data-testid="webhook-history-details">
          {formattedWebhookData &&
            Object.keys(formattedWebhookData).map((dataKey, index) => {
              return (
                <div className={classes.dataField} key={`${dataKey['REQUEST ID']}_${index}`}>
                  <div className={classes.dataKey}>{dataKey}:</div>
                  <div className={classes.dataValue}>{formattedWebhookData[dataKey]}</div>
                </div>
              );
            })}
        </Box>
        <Divider className={classes.hDivider} />
        <Box className={classes.codeWrapper} data-testid="webhook-history-payload">
          <ConnectCode {...payloadCode} setCopied={setCopied} />
          {copied && copied === payloadCode?.identifier && (
            <span className={classes.copiedText}>{t('DASHBOARD_CONNECT_COPIED')}</span>
          )}
        </Box>
        <Divider className={classes.hDivider} />
        <Box className={classes.codeWrapper} data-testid="webhook-history-response">
          <ConnectCode {...responseCode} setCopied={setCopied} />
          {copied && copied === responseCode?.identifier && (
            <span className={classes.copiedText}>{t('DASHBOARD_CONNECT_COPIED')}</span>
          )}
        </Box>
      </div>
    </>
  );
}
