import { ACTIVE_TEXT_COLOR, createThemeObject } from '../providers/theme-provider';

const muiTheme = createThemeObject();

const baseStyle = (): any => {
  return {
    container: {
      display: 'flex',
      padding: muiTheme.spacing(2),
      position: 'relative',
      border: `1px solid ${muiTheme.palette.grey[200]}`,
      borderRadius: '7px',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 'auto',
    },
    title: {
      color: ACTIVE_TEXT_COLOR,
      fontSize: '18px !important',
      fontWeight: 600,
    },
    chartContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      marginLeft: -10,
    },
  };
};

export default baseStyle;
