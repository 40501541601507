export const maskIdentifier = (identifier: string): string => {
  const startMask = identifier.lastIndexOf('_') + 2;
  const maskedIdentifier = `${identifier.slice(
    0,
    startMask,
  )} \u2022\u2022\u2022\u2022 ${identifier.slice(-4)}`;
  return maskedIdentifier;
};

export const maskSecret = (secret: string): string => {
  const startMask = secret.lastIndexOf('_') + 2;
  const maskedSecret =
    `${secret.slice(0, startMask)}` + new Array(15).fill('•').join('') + `${secret.slice(-4)}`;

  return maskedSecret;
};
