import axios from 'axios';

import storeService from '../store';
import { getAccessToken, resetAuthInformation } from '../store/features/auth';

import { HttpStatus, HttpStatusError } from 'types';

declare module 'axios' {
  export interface AxiosTransformer {
    (data: any, headers?: any): any;
  }

  export interface AxiosAdapter {
    (config: AxiosRequestConfig): AxiosPromise<any>;
  }

  export interface AxiosBasicCredentials {
    username: string;
    password: string;
  }

  export interface AxiosProxyConfig {
    host: string;
    port: number;
    auth?: {
      username: string;
      password: string;
    };
  }

  export interface AxiosRequestConfig {
    // custom config (this works through interface declaration merging)
    // When this field is true we don't send a token since it's not used by external endpoints
    external?: boolean;
  }
}

axios.interceptors.request.use(function (config): any {
  const store = storeService.getStore();
  const token = getAccessToken(store.getState());

  if (token && !config.external) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response) {
      if (response?.status === HttpStatus.NO_DATA || response?.status === HttpStatus.PENDING) {
        return { statusCode: response?.status };
      }
    }
    return response?.data || response;
  },
  (error) => {
    if (error) {
      const response = error?.response;
      if (response && response?.status) {
        const { status } = response;

        switch (status) {
          case HttpStatusError.UNAUTHORIZED:
            if (
              !window?.location?.pathname?.includes('login') &&
              !window?.location?.pathname?.includes('verifyEmail')
            ) {
              const store = storeService.getStore();
              store.dispatch(resetAuthInformation());
              window.location.replace('/login');
            }

            return Promise.reject(response.data?.error || response);
          case HttpStatusError.FORBIDDEN:
          case HttpStatusError.BAD_REQUEST:
          case HttpStatusError.NOT_FOUND:
          case HttpStatusError.SERVICE_UNAVAILABLE:
          case HttpStatusError.INTERNAL_SERVER_ERROR:
          default:
            // let the service decide how to handle other errors
            return Promise.reject(response.data?.error || response);
        }
      } else {
        return Promise.reject(error?.data || error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);
