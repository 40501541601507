/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createReducer } from '@reduxjs/toolkit';

import { PROFILE_INITIAL_STATE } from './profile.state';

import {
  DELETE_USER_MFA_API,
  DELETE_USER_MFA_FAILURE_API,
  DELETE_USER_MFA_SUCCESS_API,
  ENABLE_MFA_API,
  ENABLE_MFA_FAILURE_API,
  ENABLE_MFA_SUCCESS_API,
  FETCH_PROFILE_API,
  FETCH_PROFILE_FAILURE_API,
  FETCH_PROFILE_SUCCESS_API,
  GENERATE_MFA_API,
  GENERATE_MFA_FAILURE_API,
  GENERATE_MFA_SUCCESS_API,
  REMOVE_TEAM_FROM_PROFILE,
  RESET_PROFILE_ERROR,
  RESET_PROFILE_RESPONSE,
  RESET_STORAGE_PROFILE,
  SET_PROFILE_MFA,
  UPDATE_PROFILE_API,
  UPDATE_PROFILE_FAILURE_API,
  UPDATE_PROFILE_PICTURE_API,
  UPDATE_PROFILE_PICTURE_FAILURE_API,
  UPDATE_PROFILE_PICTURE_SUCCESS_API,
  UPDATE_PROFILE_SUCCESS_API,
  UPDATE_TEAM_SETTINGS_API,
  UPDATE_TEAM_SETTINGS_FAILURE_API,
  UPDATE_TEAM_SETTINGS_SUCCESS_API,
} from './profile.action';

export const profileReducer = createReducer(PROFILE_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_PROFILE, () => PROFILE_INITIAL_STATE)
    .addCase(SET_PROFILE_MFA, (state, action: any) => ({
      ...state,
      response: null,
      mfaEnabled: action.payload,
    }))
    .addCase(RESET_PROFILE_ERROR, (state) => ({ ...state, error: null }))
    .addCase(RESET_PROFILE_RESPONSE, (state) => ({ ...state, response: null }))
    .addCase(FETCH_PROFILE_API, (state, action: any) => ({
      ...state,
      ...(action.payload && { baseLoading: true }),
      error: null,
      loading: true,
    }))
    .addCase(FETCH_PROFILE_SUCCESS_API, (state, action: any) => ({
      ...state,
      ...action.payload,
      loading: false,
      baseLoading: false,
    }))
    .addCase(FETCH_PROFILE_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      baseLoading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_PROFILE_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(UPDATE_PROFILE_SUCCESS_API, (state, action: any) => ({
      ...state,
      ...action.payload,
      response: { success: true, message: 'updateProfile' },
      loading: false,
    }))
    .addCase(REMOVE_TEAM_FROM_PROFILE, (state, action: any) => ({
      ...state,
      teams: state.teams.filter((element) => element.uuid !== action.payload.teamUuid),
    }))
    .addCase(UPDATE_PROFILE_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_PROFILE_PICTURE_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_PROFILE_PICTURE_SUCCESS_API, (state, action: any) => ({
      ...state,
      ...action.payload,
      response: { success: true, message: 'Profile Pic successful' },
      loading: false,
    }))
    .addCase(UPDATE_PROFILE_PICTURE_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DELETE_USER_MFA_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(DELETE_USER_MFA_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      mfaEnabled: false,
      loading: false,
    }))
    .addCase(DELETE_USER_MFA_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(ENABLE_MFA_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(ENABLE_MFA_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(ENABLE_MFA_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(GENERATE_MFA_API, (state) => ({
      ...state,
      error: null,
      response: null,
      loading: true,
    }))
    .addCase(GENERATE_MFA_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(GENERATE_MFA_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_TEAM_SETTINGS_API, (state) => ({
      ...state,
      error: null,
      response: null,
      loading: true,
    }))
    .addCase(UPDATE_TEAM_SETTINGS_SUCCESS_API, (state, action: any) => ({
      ...state,
      teamSettings: { ...state.teamSettings, ...action.payload },
      response: action.payload,
      loading: false,
    }))
    .addCase(UPDATE_TEAM_SETTINGS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: { ...action.payload, type: 'teamSettingsUpdate' },
    }));
});
