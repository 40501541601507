import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import { FormInputProps } from 'types';

import './styles.css';

const FormInput = (props: FormInputProps): React.ReactElement => {
  const { t } = useTranslation('dashboard');
  const { name, label, required, errorobj, control, placeholder, inputProps, testid, autoFocus } =
    props;
  let errorMessage = '';

  if (errorobj && errorobj[name]) {
    errorMessage = errorobj[name].message;
  }

  const n = name.replace('[', '.').replace(']', '').split('.');

  if (n.length > 1 && errorobj && errorobj[n[0]]) {
    if (errorobj[n[0]][n[1]]) {
      const a = errorobj[n[0]][n[1]];
      if (a[n[2]]) errorMessage = errorobj[n[0]][n[1]][n[2]].message;
    }
  }

  return (
    <Controller
      as={TextField}
      variant="outlined"
      autoFocus={autoFocus}
      name={name}
      control={control}
      InputLabelProps={{
        required: required || false,
      }}
      FormHelperTextProps={{
        className: 'error-text',
      }}
      error={!!errorMessage}
      helperText={t(errorMessage)}
      {...props}
      placeholder={t(placeholder)}
      label={t(label)}
      inputProps={{ ...inputProps, 'data-testid': testid }}
    />
  );
};

export default FormInput;
