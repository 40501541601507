import { makeStyles, Theme } from '@material-ui/core';

export const DRAWER_WIDTH = 240;

const drawerStyles = makeStyles((theme: Theme) => ({
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(2.5),
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },

  toolbar: theme.mixins.toolbar,
  toolbarText: {
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  drawerPaper: {
    position: 'relative',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerHeader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    height: theme.spacing(9),
  },
  drawerHeaderTitle: {
    color: theme.palette.text.secondary,
    marginRight: '0.2rem',
  },
  buttonMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerToggleButtonWrapper: {
    display: 'flex',
  },
  drawerMini: {
    overflowX: 'hidden',
    width: theme.spacing(7),
  },
  miniToggleButton: {
    opacity: 1,
    transition: 'opacity 500ms',
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(2),
  },
  minLogo: {
    opacity: 0,
    position: 'absolute',
    left: theme.spacing(-2),
    transition: 'opacity 500ms',
    zIndex: -1,
  },
  logo: {
    height: 30,
  },
  wrapperSwitch: {
    paddingTop: 8,
  },
}));

export default drawerStyles;
