/**
 * Webhook payload
 */
import { ApiResponse, WebhookConfig } from '../../../../types';
import { ErrorResponse } from '../../../../types/interface/error.interface';

export interface WebhookConfigAction<T> {
  payload?: T;
  type: string;
}

export type WebhookConfigActionCreator<T = undefined> = (payload?: T) => WebhookConfigAction<T>;

/**
 * Webhook store state
 */
export interface WebhookConfigState {
  loading: boolean;
  error: ErrorResponse;
  response: ApiResponse;
  webhookConfig: WebhookConfig;
}

/**
 * Name of the webhook Store
 */
export const WEBHOOK_CONFIG_STORE_NAME = 'webhook-config';

/**
 * webhook initial state
 */
export const WEBHOOK_CONFIG_INITIAL_STATE: WebhookConfigState = {
  response: null,
  webhookConfig: undefined,
  loading: false,
  error: null,
};
