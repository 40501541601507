import React from 'react';
import { TextField } from '@material-ui/core';

import useStyle from './style';

import { getHexValue } from 'pages/sites/utils';

interface Props {
  color: string;
  setColor: (brandColor: string) => void;
  ariaLabel: string;
  testid: string;
  disabled?: boolean;
}

export default function ColorPicker({
  color,
  setColor,
  ariaLabel,
  testid,
  disabled,
}: Props): JSX.Element {
  const classes = useStyle();
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div className={classes.colorContainer}>
        <input
          type="color"
          value={'#' + getHexValue(color)?.toUpperCase()}
          aria-label={ariaLabel}
          onChange={(event): void => setColor(event.target.value)}
          style={{
            padding: '0',
            width: '150%',
            height: '150%',
            margin: '-25%',
          }}
          disabled={disabled}
        />
      </div>
      <TextField
        style={{
          marginLeft: '12px',
        }}
        type="text"
        inputProps={{
          'aria-label': `${ariaLabel} text input`,
          'data-testid': testid,
        }}
        fullWidth
        value={'#' + getHexValue(color)?.toUpperCase()}
        onChange={(event): void => setColor(event.target.value)}
        disabled={disabled}
      />
    </div>
  );
}
