import { MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { reportStatusValues } from '../../../helpers/report-helpers/constants';

import { ReportStatus, ReportType } from 'types';

interface Props {
  selectedReportType: string;
  selectedStatus: string;
  handleSelected: (string) => void;
}

export default function Status({
  selectedReportType,
  selectedStatus: parentSelectedStatus,
  handleSelected,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    setSelectedStatus(parentSelectedStatus);
  }, [parentSelectedStatus]);

  const onChange = (event): void => {
    setSelectedStatus(event.target.value);
    handleSelected && handleSelected(event.target.value);
  };

  return (
    <TextField
      inputProps={{ 'data-testid': 'report-filter-status' }}
      fullWidth
      label={t('DASHBOARD_REPORT_STATUS_LABEL')}
      placeholder={t('DASHBOARD_REPORT_STATUS_PLACEHOLDER')}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      select
      value={selectedStatus || ''}
      onChange={onChange}
      size="small"
    >
      {reportStatusValues
        .filter(({ value }) => {
          switch (selectedReportType) {
            case ReportType.DEPOSITS:
              return value !== ReportStatus.PARTIALLY_PAID && value !== ReportStatus.CLOSED;
            case ReportType.PURCHASE_ORDERS:
            case ReportType.ESTIMATES:
              return value !== ReportStatus.PARTIALLY_PAID && value !== ReportStatus.PAID;
            default:
              return value !== ReportStatus.CLOSED;
          }
        })
        .map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
    </TextField>
  );
}
