import { createSelector } from 'reselect';

import { AllState } from '../../../state';

import { DEVELOPER_STORE_NAME } from '../developer.state';

import { WEBHOOK_SECRET_STORE_NAME, WebhookSecretState } from './webhook-secret.state';

/** Select Webhook State */
export const getWebhookSecretState = (state: AllState): WebhookSecretState =>
  state[DEVELOPER_STORE_NAME][WEBHOOK_SECRET_STORE_NAME];

export const isWebhookSecretLoading = createSelector(
  getWebhookSecretState,
  (webhook) => webhook.loading,
);

export const getWebhookSecret = createSelector(
  getWebhookSecretState,
  (webhook) => webhook.webhookSecret,
);

export const getWebhookSecretError = createSelector(
  getWebhookSecretState,
  (webhook) => webhook.error,
);

export const getWebhookSecretErrorMessage = createSelector(
  getWebhookSecretState,
  (webhook) => webhook.error && webhook.error.message,
);
