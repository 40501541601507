import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  isCustomerPlanApiError,
  isCustomerPlanApiSuccess,
} from '../../store/features/account/customer/customer.selector';
import storeService from '../../store';

import { EventService } from '../../services';

import Plans from './components/plans';
import PlanForm from './components/plan-form';

import PageDescription from 'components/page-description/page-description';

import {
  resetCustomerError,
  resetCustomerResponse,
  updateCustomerContactApi,
} from 'store/features/account/customer/customer.action';
import { ContactType, Role } from 'types';
import { Header, ScrollView, View } from 'components';
// eslint-disable-next-line max-len
import AlertSuperAdminBillingFeatures from 'components/alert/alert-super-admin-billing-features/alert-super-admin-billing-features';
import { getUserRole } from 'store/features/account/profile/profile.selector';

interface Props {
  testid: string;
}

const Plan: React.FC<Props> = ({ testid = 'test-header-plan-page' }) => {
  const store = storeService.getStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const success = useSelector<ReturnType<typeof store.getState>, boolean>(isCustomerPlanApiSuccess);
  const error = useSelector<ReturnType<typeof store.getState>, boolean>(isCustomerPlanApiError);
  const role = useSelector(getUserRole);

  useEffect(() => {
    if (success) {
      dispatch(resetCustomerResponse());
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: t('DASHBOARD_BILLING_UPDATE_SUCCESS'),
      });
    }
    // eslint-disable-next-line
  }, [success]);
  useEffect(() => {
    if (error) {
      dispatch(resetCustomerError());
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: t('DASHBOARD_BILLING_CONTACT_UNKNOWN_FAILURE'),
      });
    }
    // eslint-disable-next-line
  }, [error]);

  const onSubmit = async (values): Promise<void> => {
    dispatch(
      updateCustomerContactApi({
        contactType: ContactType.BILLING,
        contactName: values?.billContactName || '',
        contactEmail: values?.billContactEmail || '',
      }),
    );
  };

  return (
    <>
      <Header
        drawerMenu={true}
        title={t('DASHBOARD_BILLING_PLAN_HEADER')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />
      <View>
        <ScrollView style={{ padding: '16px' }}>
          {role !== Role.SUPER_ADMINISTRATOR && role !== Role.BILLING ? (
            <AlertSuperAdminBillingFeatures />
          ) : null}

          <div style={{ padding: '16px' }}>
            <PageDescription
              title="DASHBOARD_BILLING_PLAN_TITLE"
              subtitle="DASHBOARD_BILLING_PLAN_SUBTITLE"
            />
            <Grid container spacing={3}>
              <Plans />
            </Grid>
            {role === Role.SUPER_ADMINISTRATOR || role === Role.BILLING ? (
              <PlanForm
                onSubmit={onSubmit}
                isDisabled={role !== Role.SUPER_ADMINISTRATOR && role !== Role.BILLING}
              />
            ) : null}
          </div>
        </ScrollView>
      </View>
    </>
  );
};

export default Plan;
