/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createReducer } from '@reduxjs/toolkit';

import {
  ENABLE_DISABLE_SSO_API,
  ENABLE_DISABLE_SSO_FAILURE_API,
  ENABLE_DISABLE_SSO_SUCCESS_API,
  CREATE_STRIPE_PAYMENT_FAILURE_API,
  CREATE_STRIPE_CHECKOUT_API,
  CREATE_STRIPE_CHECKOUT_SUCCESS_API,
  CREATE_STRIPE_CHECKOUT_FAILURE_API,
  CREATE_STRIPE_SIGNUP_CHECKOUT_API,
  CREATE_STRIPE_SIGNUP_CHECKOUT_SUCCESS_API,
  CREATE_STRIPE_SIGNUP_CHECKOUT_FAILURE_API,
  DELETE_COMPANY_LOGO_API,
  DELETE_COMPANY_LOGO_FAILURE_API,
  DELETE_COMPANY_LOGO_SUCCESS_API,
  FETCH_CUSTOMER_API,
  FETCH_CUSTOMER_FAILURE_API,
  FETCH_CUSTOMER_SUCCESS_API,
  FETCH_CUSTOMER_EMAIL_API,
  FETCH_CUSTOMER_EMAIL_SUCCESS_API,
  FETCH_CUSTOMER_EMAIL_FAILURE_API,
  RESET_CUSTOMER_EMAIL_SUGGESTIONS_API,
  FETCH_STRIPE_PORTAL_API,
  FETCH_STRIPE_PORTAL_FAILURE_API,
  FETCH_STRIPE_PORTAL_SUCCESS_API,
  RESET_CUSTOMER_ERROR,
  RESET_CUSTOMER_RESPONSE,
  UPDATE_COMPANY_API,
  UPDATE_COMPANY_FAILURE_API,
  UPDATE_COMPANY_SUCCESS_API,
  UPDATE_CUSTOMER_API,
  UPDATE_CUSTOMER_CONTACT_API,
  UPDATE_CUSTOMER_CONTACT_FAILURE_API,
  UPDATE_CUSTOMER_CONTACT_SUCCESS_API,
  UPDATE_CUSTOMER_FAILURE_API,
  UPDATE_CUSTOMER_SUCCESS_API,
  NO_BRANDING_CHANGE_SAVE,
  SEND_EMAIL_SUCCESS_API,
  SEND_EMAIL_FAILURE_API,
  SEND_EMAIL_API,
  RESET_SEND_EMAIL_API,
  TRANSFER_OWNERSHIP_FAILURE_API,
  TRANSFER_OWNERSHIP_API,
  TRANSFER_OWNERSHIP_SUCCESS_API,
  RESET_CUSTOMER_INFORMATION,
  RESET_STORAGE_CUSTOMER,
} from './customer.action';

import { CUSTOMER_INITIAL_STATE } from './customer.state';

export const customerReducer = createReducer(CUSTOMER_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_CUSTOMER, () => CUSTOMER_INITIAL_STATE)
    .addCase(RESET_CUSTOMER_INFORMATION, () => CUSTOMER_INITIAL_STATE)
    .addCase(RESET_CUSTOMER_ERROR, (state) => ({ ...state, error: null, sessionURL: '' }))
    .addCase(RESET_CUSTOMER_RESPONSE, (state) => ({ ...state, response: null, sessionURL: '' }))
    .addCase(FETCH_CUSTOMER_API, (state, action: any) => ({
      ...state,
      ...(action.payload && { baseLoading: true }),
      error: null,
      loading: true,
    }))
    .addCase(FETCH_CUSTOMER_SUCCESS_API, (state, action: any) => ({
      ...state,
      baseLoading: false,
      loading: false,
      ...action.payload,
    }))
    .addCase(FETCH_CUSTOMER_FAILURE_API, (state, action: any) => ({
      ...state,
      baseLoading: false,
      loading: false,
      error: action.payload,
    }))
    .addCase(FETCH_CUSTOMER_EMAIL_API, (state, action: any) => ({
      ...state,
      ...(action.payload && { baseLoading: true }),
      error: null,
      loading: true,
    }))
    .addCase(FETCH_CUSTOMER_EMAIL_SUCCESS_API, (state, action: any) => ({
      ...state,
      baseLoading: false,
      loading: false,
      emailSuggestions: [...action.payload.emails],
      emailSuggestionsInputIndex: action.payload.index,
    }))
    .addCase(FETCH_CUSTOMER_EMAIL_FAILURE_API, (state, action: any) => ({
      ...state,
      baseLoading: false,
      loading: false,
      error: action.payload,
    }))
    .addCase(RESET_CUSTOMER_EMAIL_SUGGESTIONS_API, (state) => ({
      ...state,
      response: null,
      emailSuggestions: [],
    }))

    .addCase(UPDATE_CUSTOMER_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(UPDATE_CUSTOMER_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload.response,
      ...action.payload.customer,
    }))
    .addCase(UPDATE_CUSTOMER_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_CUSTOMER_CONTACT_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(UPDATE_CUSTOMER_CONTACT_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: { success: true, message: 'plan_contact' },
      ...action.payload,
    }))
    .addCase(SEND_EMAIL_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(SEND_EMAIL_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: { success: true, message: 'send_email' },
      ...action.payload,
    }))
    .addCase(SEND_EMAIL_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(RESET_SEND_EMAIL_API, (state) => ({ ...state, error: null, response: null }))
    .addCase(UPDATE_CUSTOMER_CONTACT_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_COMPANY_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(UPDATE_COMPANY_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload.response,
      ...action.payload.customer,
    }))
    .addCase(UPDATE_COMPANY_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DELETE_COMPANY_LOGO_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(DELETE_COMPANY_LOGO_SUCCESS_API, (state) => ({
      ...state,
      loading: false,
      railzConnectMetadata: { ...state.railzConnectMetadata, companyLogo: '' },
      response: { message: 'delete', success: true },
    }))
    .addCase(DELETE_COMPANY_LOGO_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(FETCH_STRIPE_PORTAL_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(FETCH_STRIPE_PORTAL_SUCCESS_API, (state) => ({
      ...state,
      loading: false,
      response: { message: 'stripePortal', success: true },
    }))
    .addCase(FETCH_STRIPE_PORTAL_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(CREATE_STRIPE_PAYMENT_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(CREATE_STRIPE_CHECKOUT_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(CREATE_STRIPE_CHECKOUT_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: { message: 'createCheckout', success: true },
      sessionURL: action.payload,
    }))
    .addCase(CREATE_STRIPE_CHECKOUT_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(CREATE_STRIPE_SIGNUP_CHECKOUT_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(CREATE_STRIPE_SIGNUP_CHECKOUT_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: { message: 'createCheckout', success: true },
      sessionURL: action.payload,
    }))
    .addCase(CREATE_STRIPE_SIGNUP_CHECKOUT_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(NO_BRANDING_CHANGE_SAVE, (state) => ({
      ...state,
      response: { message: 'no changes', success: true },
    }))
    .addCase(ENABLE_DISABLE_SSO_API, (state) => ({
      ...state,
      error: null,
      response: null,
      loading: true,
    }))
    .addCase(ENABLE_DISABLE_SSO_SUCCESS_API, (state, action: any) => {
      return {
        ...state,
        ...action.payload,
        response: { success: true },
        loading: false,
      };
    })
    .addCase(ENABLE_DISABLE_SSO_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(TRANSFER_OWNERSHIP_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(TRANSFER_OWNERSHIP_API, (state) => ({ ...state, error: null, loading: true }))
    .addCase(TRANSFER_OWNERSHIP_SUCCESS_API, (state) => ({
      ...state,
      loading: false,
      response: { message: 'transferOwnership', success: true },
    }));
});
