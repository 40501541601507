import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    field: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(1),

      '& > div': {
        width: 'clamp(240px, 40%, 100%)',
        flexGrow: 1,
      },
    },
    alert: {
      marginTop: theme.spacing(2.5),
      boxSizing: 'border-box',
      borderRadius: '5px',
    },
  }),
);
