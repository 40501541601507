import React, { useCallback, useEffect, useState } from 'react';

import {
  Divider,
  Drawer,
  IconButton,
  isWidthUp,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import DrawerItems from './drawer-items';

import drawerStyles from './drawer.style';

import RailzLogo from 'assets/images/Logo.svg';

import { View } from 'components';
import { EventService } from 'services';

const INITIAL_MINI_STATE_STORE_KEY = 'mini-drawer';
const initialMiniState = (): boolean =>
  localStorage.getItem(INITIAL_MINI_STATE_STORE_KEY) === 'true';

const DrawerComponent: React.FC<any> = ({
  width,
  handleLogout,
}: {
  width: Breakpoint;
  handleLogout: () => Promise<void>;
}) => {
  const classes = drawerStyles();
  const [variant, setVariant] = useState<'temporary' | 'permanent'>('temporary');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mini, setMini] = useState(() => initialMiniState());
  const { t } = useTranslation();

  const handleToggleDrawer = useCallback(() => setDrawerOpen(!drawerOpen), [drawerOpen]);

  useEffect(() => {
    EventService.addListener(EventService.EVENT.TOGGLE_DRAWER, handleToggleDrawer);
    return () => EventService.removeListener(EventService.EVENT.TOGGLE_DRAWER, handleToggleDrawer);
  }, []);

  useEffect(() => {
    const widthUp = isWidthUp('md', width);
    setVariant(widthUp ? 'permanent' : 'temporary');
    setMini(widthUp ? initialMiniState() : false);
  }, [width]);

  const headerMenuHandler = useCallback(() => {
    if (isWidthUp('md', width)) {
      localStorage.setItem(INITIAL_MINI_STATE_STORE_KEY, String(!mini));
      setMini(!mini);
    } else {
      setDrawerOpen(!drawerOpen);
    }
  }, [drawerOpen, width, mini]);

  return (
    <Drawer
      variant={variant}
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerMini]: mini,
        }),
      }}
      anchor="left"
      open={drawerOpen}
      onClose={(): void => setDrawerOpen(false)}
      role="navigation"
      data-testid="drawer"
    >
      <div className={classes.drawerToggleButtonWrapper}>
        <div className={classNames(classes.toolbar, classes.drawerHeader)}>
          <IconButton
            onClick={headerMenuHandler}
            className={classes.miniToggleButton}
            data-testid="drawer-nav-menu-toggle-button"
            aria-label={t(`DASHBOARD_NAV_MENU_${mini ? 'OPEN' : 'CLOSE'}`)}
          >
            <MenuOutlinedIcon />
          </IconButton>
          <View>
            <img src={RailzLogo} alt="logo" className={classes.logo} />
          </View>
        </div>
      </div>
      <Divider />
      <DrawerItems drawerOpen={!mini} />
      <Divider />
      <List disablePadding component={'div'}>
        <ListItem
          className={classes.toolbar}
          button
          onClick={handleLogout}
          data-testid="drawer-nav-menu-logout-button"
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t('DASHBOARD_NAV_LOGOUT')} className={classes.toolbarText} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default DrawerComponent;
