import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ALL_FONTS } from '../../providers/theme-provider';

export default makeStyles((theme: Theme) => {
  return createStyles({
    subTitleStyle: {
      marginTop: theme.spacing(),
      fontSize: '0.875rem',
      fontFamily: ALL_FONTS,
      marginBottom: theme.spacing(3),
    },
    message: {
      fontSize: '0.875rem',
    },
    inputContainer: {
      marginBottom: theme.spacing(2),
    },
  });
});
