import { camelCase, isNil, startCase, sortBy, isEqual, intersection } from 'lodash';

import EventService from '../services/event.service';
import { INotification, SelectableReportType } from '../types';
import { ACTIVE_STATES, BusinessStatus } from '../pages/business/types/enums';

import i18n from 'translations';

export const handleError = (message: string | undefined): void => {
  const notification: INotification = {
    showAs: 'snackbar',
    type: 'error',
    title: 'Error',
    message: message || i18n.t('DASHBOARD_ENUM_ERROR_MESSAGE_UNKNOWN'),
  };
  EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, notification);
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const showSnackbar = (options: Optional<INotification, 'title' | 'showAs'>): void => {
  const notification: INotification = {
    showAs: 'snackbar',
    title: '',
    ...options,
  };

  EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, notification);
};

export const comparator = <T>(x: T, y: T): number => {
  if (isNil(y) || x > y) {
    return -1;
  }
  if (isNil(x) || x < y) {
    return 1;
  }
  return 0;
};

export const orderByArray = (key: string, orderList: string[]): any => {
  const updatedList = orderList.reduce(
    (order, orderKey, index) => Object.assign(order, { [orderKey]: index + 1 }),
    {},
  );

  const getValue = (value: any): any => updatedList[value[key] || value[key]] || Infinity;
  return (a: any, b: any): any => getValue(a) - getValue(b);
};

export const descendingComparator = <T>(a: T, b: T, sortBy: keyof T): number => {
  const x: any = typeof a[sortBy] === 'string' ? String(a[sortBy]).toUpperCase() : a[sortBy];
  const y: any = typeof b[sortBy] === 'string' ? String(b[sortBy]).toUpperCase() : b[sortBy];
  return comparator(x, y);
};

export const descendingComparatorByArray = <T>(a: T, b: T, sortBy: string[]): number => {
  let aValue: string | number = getNestedProperty(a as any, sortBy);
  let bValue: string | number = getNestedProperty(b as any, sortBy);
  // Resolve Currency sorting.
  // First detect if it is a currency and after that it removes all "$" and ","
  if (typeof aValue === 'string' && Boolean(aValue.match(/(^\$)/g))) {
    aValue = Number(aValue.replace(/(^\$|,)/g, ''));
    bValue = Number(bValue.replace(/(^\$|,)/g, ''));
  }
  const x: any = typeof aValue === 'string' ? String(aValue).toUpperCase() : aValue;
  const y: any = typeof bValue === 'string' ? String(bValue).toUpperCase() : bValue;
  return comparator(x, y);
};

export interface Enum {
  [key: string]: string;
}

export interface DropDownMap<T = string> {
  name: string;
  value: T;
  reportType?: SelectableReportType;
}

export const generateDropdown = (enumVar: Enum): Array<DropDownMap> => {
  if (!enumVar._name)
    throw new Error('Enum must have meta field _name for generating dropdown options');

  return Object.keys(enumVar)
    .filter((key) => key !== '_name' && i18n.exists(`DASHBOARD_ENUM_${enumVar._name}_${key}`))
    .map((key) => ({
      name: i18n.t([`DASHBOARD_ENUM_${enumVar._name}_${key}`]),
      value: enumVar[key],
    }));
};

export function getDropdownListItem(list: Array<DropDownMap>, key: string | number): DropDownMap {
  if (!list || !list.length) throw new Error('List is empty or undefined');
  const item = list.find(({ value }) => value === key);
  if (!item) throw new Error(`Item (${String(key)}) not found in list`);

  return item;
}

/**
 * To generate a dropdown for select fields from a given array
 *
 * @param {Array<any>} List - array from which the options has to be generated.
 * @param {string} valueKey - key which should be mapped to value in the options.
 * @param {string} nameKey - key which should be mapped to name in the options.
 * @returns {Array<DropDownMap>} Resulting options array
 */
export const generateDropdownFromArray = (
  List: Array<any>,
  valueKey: string,
  nameKey: string,
): Array<DropDownMap> => {
  if (!valueKey || !nameKey) {
    throw new Error('must have a value or name key for generating dropdown options');
  }
  return List.map((item) => ({ value: item[valueKey], name: item[nameKey] }));
};

export const getLocalizedService = (name: string): string => {
  const serviceKey = `DASHBOARD_SERVICE_${name?.toUpperCase()}`;
  return i18n.exists(serviceKey) ? i18n.t(serviceKey) : name;
};

export const getConnectionServiceName = (connection: {
  description?: string;
  serviceName: string;
  isMockData?: boolean;
}): string => {
  if (!connection) return '';
  const name = connection.description || getLocalizedService(connection.serviceName);
  if (!connection.isMockData) return name;
  return `Railz Sandbox - ` + name;
};

export const getLocalizedStatus = (status: string): string => {
  const statusKey = `DASHBOARD_BUSINESS_STATUS_${
    ACTIVE_STATES.includes(status as BusinessStatus)
      ? status.toUpperCase()
      : BusinessStatus.INACTIVE.toUpperCase()
  }`;
  return i18n.exists(statusKey) ? i18n.t(statusKey) : status[0].toUpperCase() + status.substring(1);
};

/**
 * Pass an object and a list of keys nested in the object
 * Ex. getNestedProperty({'customer': {'customerName': 'test'}}, [ 'customer', 'customerName' ])
 * will return test
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const getNestedProperty = (obj: object, keys: string[]): string =>
  keys.reduce(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (prop: object, key: string) =>
      !!prop && (!!prop[key] || prop[key] === 0) ? prop[key] : undefined,
    obj,
  );

/**
 * Sort an array by custom order list
 * arrayList = ['email', 'name', 'phone']
 * customOrder = ['name', 'email', 'phone']
 * Ex. arrayList.sort(sortByCustomOrder(customOrder)
 * will return ['name', 'email', 'phone']
 */
export const sortByCustomOrder = (sortOrder: string[]): any => {
  const orderArrangement = sortOrder.reduce(
    (order, key, index) => Object.assign(order, { [key]: index + 1 }),
    {},
  );
  return (a: any, b: any): any => orderArrangement[a] - orderArrangement[b];
};

/**
 * Get the key of an enum value
 */
export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(
  selectedEnum: T,
  enumValue: string,
): keyof T | null => {
  const keys = Object.keys(selectedEnum).filter((x) => selectedEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
};

/** Makes subarrays of a given size from an array.*/
export function makeSubArraysOfSize<T>(array: T[], size: number): T[][] {
  return array.reduce(
    (acc, curr) => {
      const lastGroup = acc[acc.length - 1];
      const lastGroupIsComplete = lastGroup && lastGroup.length === size;
      if (lastGroupIsComplete) acc.push([curr]);
      else lastGroup.push(curr);
      return acc;
    },
    [[]] as T[][],
  );
}

/**
 * Creates an element and set it on the clipboard
 */
export const copyContent = (content: string): void => {
  const el = document.createElement('textarea');
  el.value = content;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  navigator.clipboard.writeText(content);
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const getErrorMessage = (message: string | undefined): string => {
  switch (message) {
    case 'Email already in use':
      return 'DASHBOARD_PROFILE_EMAIL_DUPLICATE';
    case 'ACCOUNT NOT APPROVED':
      return 'DASHBOARD_SIGNUP_EMAIL_NOT_VALID';
    case 'DISPOSABLE_EMAIL':
      return 'DASHBOARD_SIGNUP_EMAIL_DISPOSABLE_EMAIL';
    default:
      return 'DASHBOARD_PROFILE_UNKNOWN_FAILURE';
  }
};

export const sentenceCase = (value: string): string => (value ? startCase(camelCase(value)) : '');

export const setClipboard = (text: string): Promise<void> => {
  if (navigator?.clipboard?.writeText) {
    return navigator.clipboard.writeText(text);
  }
  return;
};

export const isFieldsNotEqual = (
  field1: object | string | number,
  field2: object | string | number,
): boolean =>
  Array.isArray(field1) && Array.isArray(field2)
    ? !isEqual(sortBy(field1), sortBy(field2))
    : !isEqual(field1, field2);

export const formChange = (objectNew: object, objectBase: object = {}): boolean => {
  const keys = intersection(Object.keys(objectNew), Object.keys(objectBase));
  return keys.some((key) => {
    return isFieldsNotEqual(objectBase[key], objectNew[key]);
  });
};

export const getChangedFields = (objectNew: object, objectBase: object = {}): object => {
  const keys = Object.keys(objectNew);
  return keys
    .filter((key) => isFieldsNotEqual(objectBase[key], objectNew[key]))
    .reduce((parent, key) => ({ ...parent, [key]: objectNew[key] }), {});
};

export const getRedirectToSandbox = (): boolean => {
  const params = new URLSearchParams(location.search);
  const hasRedirect = params.get('redirect');

  return (
    hasRedirect &&
    new URLSearchParams(hasRedirect?.substring(hasRedirect.indexOf('?'))).get('sandbox') === 'true'
  );
};

export const capitalizeFirstLetter = (s: string): string =>
  s ? `${s[0].toUpperCase()}${s.slice(1)}` : '';
