import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      padding: theme.spacing(4),
      fontSize: '1rem',
      color: theme.palette.grey[600],
      boxSizing: 'border-box',
      textAlign: 'center',
    },
    link: {
      borderBottom: `0.0625rem solid ${theme.palette.primary.main}`,
      paddingBottom: '0.0625rem',
      color: theme.palette.primary.main,
      fontSize: '1rem',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    text: {
      marginTop: '0.75rem',
    },
  });
});
