import React, { useEffect, useState } from 'react';
import {
  Box,
  PropTypes,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

import { View } from 'components';
import { ReportType } from 'types';
import ErrorComponent from 'components/error/error-component';
import { descendingComparatorByArray, getNestedProperty } from 'helpers/common.helper';
import { getHeaderTable } from 'helpers/report-helpers/constants';
import { formatValue } from 'helpers/report-helpers/utils';

const getTableCellAlignment = (type): string => {
  return type === 'number' || type === 'numberYtd' ? 'right' : 'left';
};

interface Props {
  reportType: ReportType;
  data: Array<any>;
  loading: boolean;
  statusCode?: number;
  defaultSort?: string;
  defaultSortByValue?: string[];
  defaultOrder?: string | number | symbol;
  setUserSortBy?: ({
    key,
    order,
    keys,
  }: {
    key: string;
    order: 'asc' | 'desc';
    keys: string[];
  }) => void;
  setUserSortByValue?: (any) => void;
  validBankReconciliationBusiness?: boolean;
}

export default function ReportTable({
  reportType,
  data,
  loading,
  statusCode,
  defaultSort,
  defaultSortByValue,
  defaultOrder,
  setUserSortBy,
  setUserSortByValue,
  validBankReconciliationBusiness = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const [headerTable, setHeaderTable] = useState(getHeaderTable(reportType));
  const [order, setOrder] = useState<any>('');
  const [sortBy, setSortBy] = useState<string>('');
  const [sortByValue, setSortByValue] = useState<string[]>([]);
  const [reportData, setReportData] = useState(data || []);

  useEffect(() => {
    setSortBy(defaultSort);
    setOrder(defaultOrder);
    setSortByValue(defaultSortByValue);
  }, [defaultSort, defaultOrder, defaultSortByValue]);

  useEffect(() => {
    // we only really want to update the header when the data changes.
    setHeaderTable(getHeaderTable(reportType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect((): void => {
    let sorted = data;
    if (sortByValue?.length) {
      sorted = data.sort((a, b) =>
        order === 'desc'
          ? descendingComparatorByArray(a, b, sortByValue)
          : -descendingComparatorByArray(a, b, sortByValue),
      );
    }
    setReportData(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, sortByValue, data]);

  const handleRequestSort = (key: string, keys: string[]) => (): void => {
    const isAsc = sortBy === key && order === 'asc';
    const sortOrder = isAsc ? 'desc' : 'asc';
    setOrder(sortOrder);
    setSortBy(key);
    setSortByValue(keys);
    setUserSortBy({ key, order: sortOrder, keys });
    setUserSortByValue(keys);
  };

  if (statusCode && !reportData.length) {
    const showBankReconciliation404Error =
      !validBankReconciliationBusiness &&
      reportType === ReportType.BANKING_RECONCILIATION &&
      statusCode === 404;

    return (
      <View flexGrow alignItems="center" justifyContent="flex-start">
        <Box className={classes.container}>
          <ErrorComponent
            statusCode={statusCode}
            subTitle={
              showBankReconciliation404Error
                ? 'DASHBOARD_BUSINESS_BANK_RECONCILIATION_SERVICE_CRITERIA_MISSING'
                : ''
            }
          />
        </Box>
      </View>
    );
  }

  if ((!loading && !reportData.length) || (loading && !reportData.length)) {
    return null;
  }

  return (
    <View id="report-table" flexGrow className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headerTable.map(({ name, type, key, keys, sort }) => (
              <TableCell
                key={key}
                tabIndex={0}
                sortDirection={sort !== false && sortBy === key ? order : false}
                align={getTableCellAlignment(type) as PropTypes.Alignment}
              >
                {sort !== false ? (
                  <TableSortLabel
                    active={sortBy === key}
                    direction={sortBy === key ? order : 'asc'}
                    onClick={handleRequestSort(key, keys)}
                  >
                    {t(name)}
                    {sortBy === key ? (
                      <span className={classes.sortIcon}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  t(name)
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reportData?.map((item, id) => (
            <TableRow key={`${item?.createdAt || ''}${id}`} role="tableRow">
              {headerTable.map(({ key, keys, type, style, decimals = 2 }: any, index) => (
                <TableCell
                  tabIndex={0}
                  key={`${item?.createdAt}${key}${index}`}
                  align={getTableCellAlignment(type) as PropTypes.Alignment}
                  style={style}
                >
                  {formatValue(getNestedProperty(item, keys), type, decimals, key)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </View>
  );
}
