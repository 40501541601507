/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  IntegrationStatus,
  ServiceEntityState,
  Services,
  ServiceWithIntegrations,
} from '../../../../types';

import { CustomerState } from 'store/features/account/customer/customer.state';

export const isBeta = (account: Services | CustomerState): boolean => !!account?.beta;
export const isActive = (account: Services): boolean =>
  account.status === ServiceEntityState.ACTIVE;
export const isEnabled = (account: ServiceWithIntegrations): boolean =>
  !!account.relatedIntegrations?.length &&
  account.relatedIntegrations.every(({ state }) => state === IntegrationStatus.ENABLED);
export const isManageable = (account: Services): boolean => !!account.requiresCredential;
export const isNotToggleable = (account: ServiceWithIntegrations): boolean => {
  if (!account.requiresCredential) return false;
  return !account.relatedIntegrations?.length;
};
