import React from 'react';
import CopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';

import { copyContent } from 'helpers/common.helper';

interface Props {
  label: string;
  generatedContent: string;
  setCopied: (boolean) => void;
  className?: string;
  contentToCopy?: string; // use when the content to copy is different than generatedContent
  ariaLabel?: string;
  iconStyle?: React.CSSProperties;
}

const ClipboardInput: React.FC<Props> = ({
  label,
  ariaLabel,
  generatedContent,
  contentToCopy,
  setCopied,
  className,
  iconStyle,
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      variant="outlined"
      margin="normal"
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={generatedContent}
      disabled
      inputProps={{
        'aria-label': ariaLabel || label,
      }}
      className={className}
      InputProps={{
        endAdornment: generatedContent && (
          <InputAdornment data-testid="clipboard-input" position="end" style={{ marginRight: -8 }}>
            <IconButton
              data-testid="copy-clipboard-button"
              aria-label={t('DASHBOARD_GENERATE_LINK_LINK_ARIA_LABEL')}
              onClick={(): void => {
                copyContent(contentToCopy || generatedContent);
                setCopied(true);
              }}
            >
              <CopyIcon style={iconStyle} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ClipboardInput;
