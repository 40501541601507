import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import useStyles from './style';

import AlertDialog from 'components/dialog';

interface Props {
  open: boolean;
  onClose: () => void;
}

const SubscriptionSuccess = ({ open, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <AlertDialog
      isOpen={open}
      title={t('DASHBOARD_BILLING_PAYMENT_SUCCESS_HEADER')}
      confirm={{
        label: t('DASHBOARD_BUSINESS_DONE'),
        onClick: onClose,
      }}
      onClose={onClose}
      showCloseButton
    >
      <div className={classes.container}>
        <CheckCircleIcon className={classes.icon} />
        <div>{t('DASHBOARD_SIGNUP_PAYG_SUCCESS_MODAL_BODY')}</div>
      </div>
    </AlertDialog>
  );
};

export default SubscriptionSuccess;
