import { Alert, AlertTitle } from '@material-ui/lab';

import Typography from '@material-ui/core/Typography';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Button } from '@material-ui/core';

import storeService from '../../../store';

import { isIntegrationActive } from '../../../store/features/integration/integration.selector';

import style from './style';

interface Props {
  sandbox: boolean;
}

export default function AlertConfigureIntegrations({ sandbox }: Props): React.ReactElement {
  const classes = style();
  const { t } = useTranslation();
  const store = storeService.getStore();

  const isActiveIntegration = useSelector<ReturnType<typeof store.getState>, boolean>(
    isIntegrationActive,
  );
  return (
    isActiveIntegration === false && (
      <Alert
        severity="warning"
        icon={<WarningRoundedIcon />}
        classes={{ root: classes.alert }}
        action={
          !window?.location?.pathname?.includes('integration') ? (
            <Button color="inherit" component={Link} to="/integration/accounting">
              {t('DASHBOARD_INTEGRATION_CTA_MANAGE')}
            </Button>
          ) : null
        }
      >
        <AlertTitle>
          <Typography variant="h2" className={classes.heading}>
            {t('DASHBOARD_HOME_CONFIGURE_INTEGRATIONS_HEADER')}
          </Typography>
        </AlertTitle>
        <Typography variant="body2">
          {sandbox
            ? t('DASHBOARD_HOME_CONFIGURE_INTEGRATIONS_CONTENT_SANDBOX')
            : t('DASHBOARD_HOME_CONFIGURE_INTEGRATIONS_CONTENT')}
        </Typography>
      </Alert>
    )
  );
}
