import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Divider,
  Switch,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import SandboxIcon from '@material-ui/icons/FlipToFront';

import drawerItemStyles from './drawer-items.style';

import { Role } from 'types';

import { View } from 'components';

const DrawerSandboxSwitch: React.FC<any> = ({
  role,
  drawerOpen = false,
  isSandboxEnabled = false,
  handleToggleSandbox,
}: {
  role: Role;
  drawerOpen: boolean;
  isSandboxEnabled: boolean;
  handleToggleSandbox: any;
}) => {
  const classes = drawerItemStyles();
  const { t } = useTranslation();

  if ([Role.BILLING, Role.ANALYST].includes(role)) return <></>;

  return (
    <div>
      <Divider />
      <View
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        className={classes.sandboxSwitchWrapper}
        data-testid="drawer-sandbox-switch-wrapper"
      >
        {drawerOpen ? (
          <List component={'div'} className={classes.listContainer}>
            <ListItem
              button
              data-testid="drawer-nav-menu-sandbox-button"
              style={{ height: '48px' }}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <SandboxIcon />
              </ListItemIcon>
              <ListItemText
                primary={t(`DASHBOARD_NAV_SANDBOX_TOGGLE_${isSandboxEnabled ? 'ON' : 'OFF'}`)}
                classes={{ primary: classes.linkText }}
                className={
                  isSandboxEnabled ? classes.activeNavLinkMenu : classes.inactiveNavLinkMenu
                }
                data-testid="drawer-sandbox-switch-label"
              />
              <div className={classes.switchContainer}>
                <Switch
                  checked={isSandboxEnabled}
                  data-testid="drawer-sandbox-switch"
                  inputProps={{
                    'aria-label': `${t(
                      isSandboxEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                    )} ${t('DASHBOARD_NAV_SANDBOX')}`,
                  }}
                  onChange={(_, checked): void => handleToggleSandbox(checked)}
                  color="primary"
                />
              </div>
            </ListItem>
          </List>
        ) : (
          <Tooltip
            title={t(
              isSandboxEnabled
                ? 'DASHBOARD_NAV_SANDBOX_TOOLTIP_ENABLED'
                : 'DASHBOARD_NAV_SANDBOX_TOOLTIP_DISABLED',
            )}
          >
            <Switch
              checked={isSandboxEnabled}
              data-testid="drawer-sandbox-switch"
              inputProps={{
                'aria-label': `${t(
                  isSandboxEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )} ${t('DASHBOARD_NAV_SANDBOX')}`,
              }}
              onChange={(_, checked): void => handleToggleSandbox(checked)}
              color="primary"
            />
          </Tooltip>
        )}
      </View>
      <Divider />
    </div>
  );
};

export default DrawerSandboxSwitch;
