import React from 'react';
import UserIcon from '@material-ui/icons/VerifiedUser';
import TransactionIcon from '@material-ui/icons/ViewAgendaOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import KeyIcon from '@material-ui/icons/VpnKeyOutlined';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import WidgetIcon from '@material-ui/icons/WidgetsOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ShareIcon from '@material-ui/icons/Share';
import Settings from '@material-ui/icons/Settings';
import VpnLock from '@material-ui/icons/VpnLock';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

import { memoize } from 'lodash';

import { DrawerItem } from './interfaces';

import { AspType, ASP_SERVICE_BY_TYPE, ASP_SERVICE_TYPE, Role } from 'types';
import { ProfileState } from 'store/features/account/profile/profile.state';
import { EventService } from 'services';

export const getDrawerItems = memoize(
  (isSandboxEnabled: boolean, isSuperAdmin: boolean): Array<DrawerItem> => [
    {
      title: 'DASHBOARD_NAV_HOME',
      path: '/',
      icon: <HomeIcon />,
    },
    {
      title: 'DASHBOARD_NAV_ACCOUNT',
      path: '/account/profile',
      icon: <UserIcon />,
      childs: [
        {
          title: 'DASHBOARD_NAV_PROFILE',
          path: '/account/profile',
          icon: <PersonOutlineOutlinedIcon />,
        },
        {
          title: 'DASHBOARD_NAV_COMPANY',
          path: '/account/company',
          icon: <BusinessCenterOutlinedIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.BILLING],
        },
        {
          title: 'DASHBOARD_NAV_TEAMS',
          path: '/account/teams',
          icon: <PeopleOutlineIcon />,
          childPaths: ['/account/teams/team'],
        },
        isSuperAdmin && {
          title: 'DASHBOARD_NAV_MEMBERS',
          path: '/account/members',
          icon: <AccountBoxIcon />,
        },
        {
          title: 'DASHBOARD_NAV_SECURITY',
          path: '/account/security',
          icon: <LockOutlinedIcon />,
        },
        {
          title: 'DASHBOARD_NAV_BILLING',
          path: '/account/billing',
          icon: <TransactionIcon />,
        },
      ].filter(Boolean),
      childPaths: [
        '/account/profile',
        '/account/company',
        '/account/security',
        '/account/teams',
        '/account/teams/team',
        '/account/members',
        '/account/billing',
        '/account/billing/subscribe',
      ],
    },
    {
      title: 'DASHBOARD_NAV_INTEGRATIONS',
      path: '/integration/accounting',
      icon: <ExtensionIcon />,
      roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, isSandboxEnabled && Role.DEVELOPER],
      childs: [
        {
          title: 'DASHBOARD_NAV_INTEGRATIONS_ACCOUNTING',
          path: '/integration/accounting',
          icon: <AssessmentOutlinedIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, isSandboxEnabled && Role.DEVELOPER],
          childPaths: [
            ...ASP_SERVICE_BY_TYPE.accounting.map(
              (service) => `/integration/${ASP_SERVICE_TYPE[service]}/${service}`,
            ),
          ],
        },
        {
          title: 'DASHBOARD_NAV_INTEGRATIONS_BANKING',
          path: '/integration/banking',
          icon: <AccountBalanceIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, isSandboxEnabled && Role.DEVELOPER],
          childPaths: [
            ...ASP_SERVICE_BY_TYPE.banking.map(
              (service) => `/integration/${ASP_SERVICE_TYPE[service]}/${service}`,
            ),
          ],
        },
        {
          title: 'DASHBOARD_NAV_INTEGRATIONS_COMMERCE',
          path: '/integration/commerce',
          icon: <LocalMallOutlinedIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, isSandboxEnabled && Role.DEVELOPER],
          childPaths: [
            ...ASP_SERVICE_BY_TYPE.commerce.map(
              (service) => `/integration/${ASP_SERVICE_TYPE[service]}/${service}`,
            ),
          ],
        },
        {
          title: 'DASHBOARD_NAV_DATA_SYNC',
          path: '/integration/data-sync',
          icon: <DataUsageIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR],
        },
      ],
      childPaths: [
        '/integration/accounting',
        '/integration/banking',
        '/integration/commerce',
        '/integration/data-sync',
        ...Object.values(AspType).map(
          (service) => `/integration/${ASP_SERVICE_TYPE[service]}/${service}`,
        ),
      ],
    },
    {
      title: 'DASHBOARD_NAV_DEVELOPERS',
      path: '/developers/api-keys',
      icon: <DeveloperModeIcon />,
      roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER],
      childs: [
        {
          title: 'DASHBOARD_NAV_API',
          path: '/developers/api-keys',
          icon: <KeyIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER],
        },
        {
          title: 'DASHBOARD_NAV_DEVELOPERS_WEBHOOKS',
          path: '/developers/webhooks',
          icon: <ShareIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER],
        },
        {
          title: 'DASHBOARD_DEVELOPERS_WEBHOOKS_LOGS_TITLE',
          path: '/developers/webhook-logs',
          icon: <ListAltOutlinedIcon />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER],
        },
      ],
      childPaths: ['/developers/webhooks', '/developers/api-keys', '/developers/webhook-logs'],
    },
    {
      title: 'DASHBOARD_NAV_BUSINESSES',
      path: '/businesses',
      icon: <BusinessIcon />,
      roles: [
        Role.SUPER_ADMINISTRATOR,
        Role.ADMINISTRATOR,
        Role.ANALYST,
        isSandboxEnabled ? Role.DEVELOPER : Role.BILLING,
      ],
      childPaths: ['/businesses/overview'],
    },
    {
      title: 'DASHBOARD_NAV_RAILZ_CONNECT',
      path: '/connect/setup',
      icon: <WidgetIcon />,
      roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER],
      childs: [
        {
          title: 'DASHBOARD_NAV_RAILZ_CONNECT_SETUP',
          path: '/connect/setup',
          icon: <Settings />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR, Role.DEVELOPER],
        },
        {
          title: 'DASHBOARD_NAV_RAILZ_CONNECT_MANAGE_IP',
          path: '/connect/ip-whitelist',
          icon: <VpnLock />,
          roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR],
        },
      ],
      childPaths: ['/connect/setup', '/connect/ip-whitelist'],
    },
    {
      title: 'DASHBOARD_NAV_RAILZ_SITES',
      path: '/sites',
      icon: <DynamicFeedIcon />,
      roles: [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR],
    },
    {
      title: 'DASHBOARD_NAV_SETTINGS',
      path: '/settings',
      icon: <SettingsApplicationsIcon />,
      roles: [Role.SUPER_ADMINISTRATOR],
    },
  ],
  (...args) => args.map(Boolean).join('-'),
);

export const getValidRoles = (item: DrawerItem, user: ProfileState): boolean =>
  !item?.roles || (user?.role && item?.roles.includes(user.role));

export const isItemAllowedPerRole = (item: DrawerItem, role: Role): boolean => {
  if (!role) return false;
  if (!item.roles || (Array.isArray(item.roles) && !item.roles.length)) return true;
  if (item?.roles.includes(role)) return true;
  return false;
};

export const logoutErrorSnackbar = (err: { message: any }): void => {
  EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
    showAs: 'snackbar',
    type: 'error',
    title: 'Railz - Dashboard',
    message: err.message || 'Something went wrong while logout. Please try again.',
  });
};

export const stringifyWithErorHandling = (
  responseObj: Record<string, unknown> | unknown,
): Record<string, unknown> | unknown => {
  try {
    return JSON.stringify(responseObj, null, 2);
  } catch (error) {
    return responseObj;
  }
};
