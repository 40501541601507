import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { IconButton, Menu, MenuItem, Tooltip, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import StarOutlineIcon from '@material-ui/icons/StarBorderOutlined';
import StarIcon from '@material-ui/icons/Star';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonRounded';

import EditIcon from '@material-ui/icons/Edit';

import { Pagination } from '@material-ui/lab';

import { RailzButton } from '@railzai/railz-uikit-react';

import { withStyles } from '@material-ui/styles';

import useStyles from './style';
import TeamForm from './team-form';

import { ActionTypes } from './types/enums';

import DeleteTeamModal from './delete-team';

import PageDescription from 'components/page-description/page-description';
import BasicTable from 'components/table';
import { PaginationProps, PAGINATION_LIMIT, Plan, Role, TeamListElement } from 'types';

import { getProfileState, getUserRole } from 'store/features/account/profile/profile.selector';

import { Header, ScrollView, View } from 'components';

import {
  getTeamApiResponse,
  getTeamErrorMessage,
  getTeamsApiResponse,
  isTeamLoading,
} from 'store/features/account/team/team.selector';

import {
  addTeamV2Api,
  fetchTeamsApi,
  resetTeamError,
  resetTeamResponse,
  resetTeamState,
  updateTeamV2Api,
} from 'store/features/account/team/team.action';

import { showSnackbar } from 'helpers/common.helper';
import { fetchProfileApi, updateProfileApi } from 'store/features/account/profile/profile.action';
import VectorStart from 'assets/icons/vector-start';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import { openNewTab } from 'helpers/open-new-tab';

interface Props {
  testid?: string;
}

const TeamPage: React.FC<Props> = ({ testid = 'test-header-teams-page' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [isShowForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [team, setTeam] = useState(null);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState<TeamListElement>(null);
  const [cachedPaginationLoading, setCachedPaginationLoading] = useState(false);

  const [queryParams] = useQuerySearchUpdate();

  const { meta, teams } = useSelector(getTeamsApiResponse);
  const teamResponse: any = useSelector(getTeamApiResponse);
  const teamErrorMessage = useSelector(getTeamErrorMessage);
  const teamLoading = useSelector(isTeamLoading);
  const role = useSelector(getUserRole);
  const loading = useSelector(isTeamLoading);
  const [lastTeamName, setLastTeamName] = useState('');
  const profile = useSelector(getProfileState);

  const isEnterprise = profile?.billingPlan === Plan.CUSTOM;
  const isSuperAdmin = Role.SUPER_ADMINISTRATOR === role;
  const pageParam = parseInt(queryParams?.get('page'));

  useEffect(() => {
    if (!isEmpty(teamErrorMessage)) {
      dispatch(resetTeamError());
      if (teamErrorMessage[0] === 'fetch') {
        if (!pageParam) {
          showSnackbar({ message: t('DASHBOARD_TEAMS_UPDATE_FETCH_FAILURE'), type: 'error' });
          return;
        }

        setCachedPaginationLoading(true);
        dispatch(refreshTeams());
        return;
      } else if (teamErrorMessage[0] === 'Team name already in use') {
        showSnackbar({ message: t('DASHBOARD_TEAMS_CREATE_NAME_IN_USE_FAILURE'), type: 'error' });
        setErrors([{ key: 'teamName', message: teamErrorMessage }]);
      } else {
        showSnackbar({
          message: (
            <div>
              <Trans
                i18nKey={
                  team?.uuid
                    ? 'DASHBOARD_TEAMS_EDIT_ERROR_TOAST'
                    : 'DASHBOARD_TEAMS_UPDATE_FETCH_FAILURE'
                }
                values={{ 'team name': lastTeamName }}
                components={{ bold: <strong /> }}
              />
            </div>
          ),
          type: 'error',
        });

        setErrors([{ key: 'general', message: teamErrorMessage }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamErrorMessage, dispatch]);

  useEffect(() => {
    if (teamResponse) {
      dispatch(resetTeamResponse());
      if (teamResponse.message === 'no changes') {
        showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
      } else if (
        (teamResponse?.teamName || teamResponse?.uuid) &&
        teamResponse?.message !== 'delete'
      ) {
        showSnackbar({
          message: (
            <div>
              <Trans
                shouldUnescape={true}
                tOptions={{ interpolation: { escapeValue: true } }}
                i18nKey={
                  team?.uuid
                    ? 'DASHBOARD_TEAMS_EDIT_SUCCESS_TOAST'
                    : 'DASHBOARD_TEAMS_CREATE_TEAM_MODAL_TOAST_SUCCESS'
                }
                values={
                  team?.uuid
                    ? { 'Team name': teamResponse?.teamName }
                    : { name: teamResponse?.teamName }
                }
                components={{ bold: <strong /> }}
              />
            </div>
          ),
          type: 'success',
        });
        dispatch(fetchProfileApi());
        dispatch(refreshTeams());
        setTeam(null);
      } else if (
        (teamResponse?.teamName || teamResponse?.uuid) &&
        teamResponse?.message === 'delete'
      ) {
        if (currentPage > 1 && meta.count <= meta.offset) {
          const newPage = currentPage - 1;
          history.push(`?page=${newPage}`);
          setPaginationData(newPage, (newPage - 1) * PAGINATION_LIMIT, false);
        } else {
          dispatch(refreshTeams());
        }
      }
      setShowForm(false);
    }
    // eslint-disable-next-line
  }, [teamResponse]);

  const refreshTeams = (params?: PaginationProps, resetPaginationData = false): any => {
    const limit = params?.limit || PAGINATION_LIMIT;
    let localOffset = Number((currentPage - 1) * PAGINATION_LIMIT);

    if (params?.offset !== undefined) localOffset = params?.offset;

    const payload = {
      ...params,
      limit,
      offset: resetPaginationData ? 0 : localOffset,
    };

    return fetchTeamsApi(payload);
  };

  const setPaginationData = (page: number, offset: number, resetPagination: boolean): void => {
    setCurrentPage(page);
    dispatch(refreshTeams({ offset }, resetPagination));
  };

  const getPaginationData = (): void => {
    const page = pageParam ? pageParam : 1;
    const offset = pageParam ? (pageParam - 1) * PAGINATION_LIMIT : 0;
    const resetPagination = !pageParam;

    setPaginationData(page, offset, resetPagination);
  };

  const resetPaginationData = (): void => {
    setPaginationData(1, 0, true);
  };

  useEffect(() => {
    if (cachedPaginationLoading && !loading) {
      if (pageParam > meta.totalPages) {
        resetPaginationData();
        history.replace(location.pathname);
      } else {
        // cached page url query is valid
        getPaginationData();
      }
      setCachedPaginationLoading(false);
    }
  }, [cachedPaginationLoading, meta.totalPages, loading]);

  useEffect(() => {
    if (!pageParam) {
      resetPaginationData();
      return;
    }

    getPaginationData();

    return () => {
      dispatch(resetTeamState());
    };
  }, [pageParam, location.search]);

  const renderAvatar = ({ initials, profileImage }, index): React.ReactElement => {
    return (
      <div
        className={classes.avatar}
        style={{
          backgroundImage: `url(${profileImage}`,
        }}
        key={`${index}-${initials}`}
        data-testid={`avatar-${index}-${initials}`}
      >
        {profileImage === null && initials && <div className={classes.initials}>{initials}</div>}
        {profileImage === null && !initials && (
          <div className={classes.profileIcon}>
            <PersonOutlineOutlinedIcon style={{ color: 'white' }} />
          </div>
        )}
      </div>
    );
  };

  const overflowOptions = [
    {
      id: ActionTypes.EDIT,
      icon: <EditIcon />,
      label: t('DASHBOARD_TEAM_EDIT_CTA'),
      ariaLabel: t('DASHBOARD_TEAM_EDIT_CTA_ARIA'),
      show: (): boolean => isSuperAdmin,
      action: (team: unknown): void => {
        setShowForm(true);
        setTeam(team);
      },
      testid: 'edit-team-menu-item',
    },
    {
      id: ActionTypes.DELETE,
      icon: <DeleteOutlineIcon />,
      label: t('DASHBOARD_BUSINESS_MENU_DELETE'),
      ariaLabel: t('DASHBOARD_BUSINESS_MENU_DELETE_ARIA'),
      show: (team): boolean => {
        const isActiveTeam = team?.uuid === profile?.teamUuid;
        const isDefaultTeam = team?.uuid === profile?.defaultTeamUuid;
        return !isActiveTeam && !isDefaultTeam && isSuperAdmin;
      },
      action: (team: TeamListElement): void => {
        setShowDeleteTeamModal(team);
      },
      color: theme.palette.error.main,
      testid: 'delete-team-menu-item',
    },
    {
      id: ActionTypes.DEFAULT,
      icon: <StarOutlineIcon />,
      label: t('DASHBOARD_TEAM_SET_DEFAULT'),
      ariaLabel: t('DASHBOARD_TEAM_SET_DEFAULT_ARIA'),
      show: (team): boolean => {
        const isDefaultTeam = team?.uuid === profile?.defaultTeamUuid;
        return !isDefaultTeam && isEnterprise;
      },
      action: (team: TeamListElement): void => {
        dispatch(
          updateProfileApi({
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            profilePic: profile.profilePic,
            defaultTeamUuid: team?.uuid,
          }),
        );
      },
      testid: 'set-default-team-menu-item',
    },
  ];

  const CustomTooltip = withStyles({
    tooltip: {
      backgroundColor: '#003032',
      padding: '1rem',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
  })(Tooltip);

  const redirectToTeam = (uuid: string): void => {
    history.push({
      pathname: `/account/teams/team`,
      search: `?uuid=${uuid}`,
    });
  };

  const columns = [
    {
      key: 'teamName',
      name: t('DASHBOARD_TEAMS_TABLE_TEAM_NAME'),
      render: ({ active, teamName, uuid }): React.ReactElement => {
        return (
          <div className={classes.teamNameContainer}>
            {meta?.count >= 2 && uuid === profile.defaultTeamUuid && (
              <span className={classes.defaultIconContainer} data-testid="default-team-icon">
                <CustomTooltip
                  title={
                    <Trans
                      shouldUnescape={true}
                      tOptions={{ interpolation: { escapeValue: true } }}
                      i18nKey={'DASHBOARD_MEMBERS_DEFAULT_TEAM_TOOLTIP'}
                      components={{ bold: <strong /> }}
                    />
                  }
                >
                  <StarIcon style={{ fontSize: '0.875rem' }} />
                </CustomTooltip>
              </span>
            )}

            <span
              onKeyDown={(event): void => {
                if (event.key === 'Enter') {
                  redirectToTeam(uuid);
                }
              }}
              onClick={(): void => redirectToTeam(uuid)}
              className={classes.teamNameLinkTxt}
            >
              {teamName}
            </span>

            {active && (
              <span className={classes.currentTeamText}>
                {t('DASHBOARD_TEAMS_LABEL_CURRENT_TEAM')}
              </span>
            )}
          </div>
        );
      },
    },
    {
      key: 'users',
      name: t('DASHBOARD_TEAMS_TABLE_MEMBERS'),
      render: ({ totalUsers, users }): React.ReactElement => (
        <div className={classes.membersContainer}>
          <Typography className={classes.totalMembers}>
            {`${totalUsers} member${totalUsers !== 1 ? t('DASHBOARD_PLURAL_NOUN') : ''}`}
          </Typography>
          <div className={classes.avatarContainer}>
            {users?.map((user, idx) => renderAvatar(user, idx))}
            {totalUsers > 5 &&
              renderAvatar({ initials: `+${totalUsers - 5}`, profileImage: null }, 5)}
          </div>
        </div>
      ),
    },
    {
      key: 'cta',
      name: t('DASHBOARD_TEAMS_TABLE_ACTION'),
      render: (item, tabIndex = 0): React.ReactElement => {
        const options = overflowOptions.filter(({ show }) => show(item));

        return (
          <>
            <IconButton
              tabIndex={tabIndex}
              aria-haspopup="true"
              onClick={(event): void =>
                setMenuAnchorEl({ ...menuAnchorEl, [item.uuid]: event.currentTarget })
              }
              disabled={!options.length}
              data-testid={`${item.uuid}-overflow-menu-button`}
              className={classes.actionIcon}
              aria-label={'full-menu'}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`${item.uuid}-overflow-menu`}
              anchorEl={menuAnchorEl?.[item.uuid]}
              open={Boolean(menuAnchorEl?.[item.uuid])}
              onClose={(): void => setMenuAnchorEl({ ...menuAnchorEl, [item.uuid]: null })}
              data-testid={`${item.uuid}-overflow-menu`}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.id}
                  aria-label={option?.ariaLabel}
                  onClick={(): void => {
                    setMenuAnchorEl(null);
                    option.action && option.action(item);
                  }}
                  data-testid={option.testid}
                  style={{ color: option?.color }}
                >
                  {option.icon}
                  <span style={{ marginLeft: '12px' }}>{option.label}</span>
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
    },
  ];

  const onAddClick = (): void => {
    setShowForm(true);
  };

  const onAddTeam = async (values): Promise<void> => {
    if (values?.uuid) {
      dispatch(updateTeamV2Api(values));
      setLastTeamName(values?.teamName);
    } else {
      dispatch(addTeamV2Api(values));
    }
  };

  const onClose = (): void => {
    setShowForm(false);
    setErrors([]);
    setTeam(null);
  };

  const formatLinkParams = (row): { searchParam: string; linkLabel: string } => {
    if (!row) return null;

    return {
      searchParam: `?uuid=${row?.uuid}&teamsPage=${currentPage}`,
      linkLabel: t('DASHBOARD_TEAMS_TABLE_ROW_VIEW_DETAILS', { teamName: row?.teamName }),
    };
  };

  return (
    <>
      <Header
        drawerMenu
        title={t('DASHBOARD_TEAMS')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />
      <View className={classes.view} data-testid="teams-content">
        <div className={classes.viewHeader}>
          <PageDescription title="DASHBOARD_TEAMS_TITLE">
            <Trans
              i18nKey="DASHBOARD_TEAMS_BODY"
              components={{
                a: (
                  <a
                    onClick={(e): void =>
                      openNewTab(e, 'https://docs.railz.ai/docs/dashboard-manage-teams')
                    }
                    rel="noopener noreferrer"
                    href="https://docs.railz.ai/docs/dashboard-manage-teams"
                    className={classes.link}
                  >
                    {t('DASHBOARD_LEARN_MORE_CTA')}
                  </a>
                ),
              }}
            />
          </PageDescription>
          {isEnterprise && isSuperAdmin && (
            <RailzButton
              label={t('DASHBOARD_TEAMS_CTA_CREATE_TEAM')}
              onClick={onAddClick}
              size="large"
              data-testid="create-team-button"
              className={classes.primaryCta}
            >
              <span slot="prefix">
                <AddIcon />
              </span>
            </RailzButton>
          )}
          {!isEnterprise && (
            <Link
              to="/account/billing"
              className={classes.linkPlanUpdate}
              aria-label={t('DASHBOARD_TEAMS_UPGRATE_PLAN')}
            >
              <Typography className={classes.textPlanUpdate}>
                <span className={classes.iconPlanUpdate}>
                  <VectorStart></VectorStart>
                </span>
                {t('DASHBOARD_TEAMS_UPGRATE_PLAN')}
              </Typography>
            </Link>
          )}
        </div>

        <ScrollView classNames={classes.viewContent}>
          <BasicTable
            data-testid="teams-table"
            columns={columns}
            rows={!cachedPaginationLoading ? teams : []}
            stickyHeader
            isPaperComponent={false}
            isHoverable={true}
            onRowClick={{ pathName: '/account/teams/team', formatLinkParams }}
            isLoading={(!isEmpty(teamErrorMessage) && loading) || cachedPaginationLoading}
            noData={t('DASHBOARD_TEAMS_NO_DATA_FOUND')}
          />
        </ScrollView>

        {meta?.totalPages > 1 && (
          <div className={classes.pagination}>
            <Pagination
              count={meta?.totalPages}
              page={currentPage}
              defaultPage={1}
              onChange={(_, pagerNumber): void => {
                history.push(`?page=${pagerNumber}`);
                setPaginationData(pagerNumber, (pagerNumber - 1) * PAGINATION_LIMIT, false);
              }}
            />
          </div>
        )}
        {isShowForm && (
          <TeamForm
            team={team}
            errorsMessage={errors}
            onSubmit={onAddTeam}
            onClose={onClose}
            isLoading={teamLoading}
          />
        )}

        {showDeleteTeamModal && (
          <DeleteTeamModal
            onClose={(): void => setShowDeleteTeamModal(null)}
            team={showDeleteTeamModal}
          />
        )}
      </View>
    </>
  );
};

export default TeamPage;
