import { CustomerUrl, MfaRequest, SuccessResponse } from '../../../../types';
import { ErrorResponse } from '../../../../types/interface/error.interface';

import {
  EnabledMfaResponse,
  EnableMfaRequest,
  GeneratedMfaResponse,
  ProfileState,
  TeamSettings,
  UpdateUserDetails,
} from './profile.state';

export const SET_PROFILE_MFA = 'SET_PROFILE_MFA';
export const RESET_PROFILE_ERROR = 'RESET_PROFILE_ERROR';
export const RESET_PROFILE_RESPONSE = 'RESET_PROFILE_RESPONSE';

export const FETCH_PROFILE_API = 'FETCH_PROFILE_API';
export const FETCH_PROFILE_SUCCESS_API = 'FETCH_PROFILE_SUCCESS_API';
export const FETCH_PROFILE_FAILURE_API = 'FETCH_PROFILE_FAILURE_API';

export const UPDATE_PROFILE_PICTURE_API = 'UPDATE_PROFILE_PICTURE_API';
export const UPDATE_PROFILE_PICTURE_SUCCESS_API = 'UPDATE_PROFILE_PICTURE_SUCCESS';
export const UPDATE_PROFILE_PICTURE_FAILURE_API = 'UPDATE_PROFILE_PICTURE_FAILURE';

export const UPDATE_PROFILE_API = 'UPDATE_PROFILE_API';
export const UPDATE_PROFILE_SUCCESS_API = 'UPDATE_PROFILE_SUCCESS_API';
export const UPDATE_PROFILE_FAILURE_API = 'UPDATE_PROFILE_FAILURE_API';

export const DELETE_USER_MFA_API = 'DELETE_USER_MFA_API';
export const DELETE_USER_MFA_SUCCESS_API = 'DELETE_USER_MFA_SUCCESS_API';
export const DELETE_USER_MFA_FAILURE_API = 'DELETE_USER_MFA_FAILURE_API';

export const GENERATE_MFA_API = 'GENERATE_MFA_API';
export const GENERATE_MFA_SUCCESS_API = 'GENERATE_MFA_SUCCESS_API';
export const GENERATE_MFA_FAILURE_API = 'GENERATE_MFA_FAILURE_API';

export const ENABLE_MFA_API = 'ENABLE_MFA_API';
export const ENABLE_MFA_SUCCESS_API = 'ENABLE_MFA_SUCCESS_API';
export const ENABLE_MFA_FAILURE_API = 'ENABLE_MFA_FAILURE_API';

export const UPDATE_TEAM_SETTINGS_API = 'UPDATE_TEAM_SETTINGS_API';
export const UPDATE_TEAM_SETTINGS_SUCCESS_API = 'UPDATE_TEAM_SETTINGS_SUCCESS_API';
export const UPDATE_TEAM_SETTINGS_FAILURE_API = 'UPDATE_TEAM_SETTINGS_FAILURE_API';

export const REMOVE_TEAM_FROM_PROFILE = 'REMOVE_TEAM_FROM_PROFILE';

export const RESET_STORAGE_PROFILE = 'RESET_STORAGE_PROFILE';

export const resetProfileStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_PROFILE,
  };
};

export const setProfileMfa = (payload: boolean): { type: string; payload: boolean } => {
  return {
    type: SET_PROFILE_MFA,
    payload,
  };
};

export const resetProfileError = (): { type: string } => {
  return {
    type: RESET_PROFILE_ERROR,
  };
};
export const resetProfileResponse = (): { type: string } => {
  return {
    type: RESET_PROFILE_RESPONSE,
  };
};

export const fetchProfileApi = (payload?: boolean): { type: string; payload: boolean } => {
  return {
    type: FETCH_PROFILE_API,
    payload,
  };
};

export const fetchProfileSuccessApi = (
  payload: ProfileState,
): { type: string; payload: ProfileState } => {
  return {
    type: FETCH_PROFILE_SUCCESS_API,
    payload,
  };
};

export const fetchProfileFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_PROFILE_FAILURE_API,
    payload,
  };
};

export const removeTeamFromProfile = (
  teamUuid: string,
): { type: string; payload: { teamUuid: string } } => {
  return {
    type: REMOVE_TEAM_FROM_PROFILE,
    payload: { teamUuid },
  };
};

export const updateProfileApi = (
  payload: UpdateUserDetails,
): { type: string; payload: UpdateUserDetails } => {
  return {
    type: UPDATE_PROFILE_API,
    payload,
  };
};

export const updateProfileSuccessApi = (
  payload: ProfileState,
): { type: string; payload: ProfileState } => {
  return {
    type: UPDATE_PROFILE_SUCCESS_API,
    payload,
  };
};

export const updateProfileFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_PROFILE_FAILURE_API,
    payload,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateProfilePicApi = (payload: any): { type: string; payload: any } => {
  return {
    type: UPDATE_PROFILE_PICTURE_API,
    payload,
  };
};

export const updateProfilePicSuccessApi = (
  payload: CustomerUrl,
): { type: string; payload: CustomerUrl } => {
  return {
    type: UPDATE_PROFILE_PICTURE_SUCCESS_API,
    payload,
  };
};

export const updateProfilePicFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_PROFILE_PICTURE_FAILURE_API,
    payload,
  };
};

export const deleteUserMfaApi = (payload: MfaRequest): { payload: MfaRequest; type: string } => {
  return {
    type: DELETE_USER_MFA_API,
    payload,
  };
};

export const deleteUserMfaSuccessApi = (
  payload: SuccessResponse,
): { type: string; payload: SuccessResponse } => {
  return {
    type: DELETE_USER_MFA_SUCCESS_API,
    payload,
  };
};

export const deleteUserMfaFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DELETE_USER_MFA_FAILURE_API,
    payload,
  };
};

export const enableMfaApi = (
  payload: EnableMfaRequest,
): { payload: EnableMfaRequest; type: string } => {
  return {
    type: ENABLE_MFA_API,
    payload,
  };
};

export const enableMfaSuccessApi = (
  payload: EnabledMfaResponse,
): { type: string; payload: EnabledMfaResponse } => {
  return {
    type: ENABLE_MFA_SUCCESS_API,
    payload,
  };
};

export const enableMfaFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: ENABLE_MFA_FAILURE_API,
    payload,
  };
};

export const generateMfaApi = (): { type: string } => {
  return {
    type: GENERATE_MFA_API,
  };
};

export const generateMfaSuccessApi = (
  payload: GeneratedMfaResponse,
): { type: string; payload: GeneratedMfaResponse } => {
  return {
    type: GENERATE_MFA_SUCCESS_API,
    payload,
  };
};

export const generateMfaFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: GENERATE_MFA_FAILURE_API,
    payload,
  };
};

export const updateTeamSettingsApi = (
  payload: TeamSettings,
): { type: string; payload: TeamSettings } => {
  return {
    type: UPDATE_TEAM_SETTINGS_API,
    payload,
  };
};

export const updateTeamSettingsSuccessApi = (
  payload: TeamSettings,
): { type: string; payload: TeamSettings } => {
  return {
    type: UPDATE_TEAM_SETTINGS_SUCCESS_API,
    payload,
  };
};

export const updateTeamSettingsFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_TEAM_SETTINGS_FAILURE_API,
    payload,
  };
};
