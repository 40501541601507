import React, { createContext, useContext, useState } from 'react';

import { ICommonProviderProps } from 'types';

export interface EventInterface {
  date: string;
  apiRequestCount: number;
}

export interface ConnectionInterface {
  count: number;
  limit: number;
}

export interface BusinessInterface {
  count: number;
}

export interface InitializedInterface {
  intialized: boolean;
}

export type EventContextType = {
  events: Array<EventInterface>;
  setEvents: (events: Array<EventInterface>) => void;
  connections: ConnectionInterface;
  setConnections: (connections: ConnectionInterface) => void;
  businesses: BusinessInterface;
  setBusinesses: (businesses: BusinessInterface) => void;
  initialized: InitializedInterface;
  setInitialized: (initialized: boolean) => void;
};

const EventContext = createContext<EventContextType | any>({});
export const useEventContext = (): EventContextType => useContext(EventContext);

export default function EventProvider({ children }: ICommonProviderProps): React.ReactElement {
  const [events, setEvents] = useState([]);
  const [connections, setConnections] = useState({});
  const [businesses, setBusinesses] = useState(0);
  const [initialized, setInitialized] = useState(false);

  return (
    <EventContext.Provider
      value={{
        events,
        setEvents,
        connections,
        setConnections,
        businesses,
        setBusinesses,
        initialized,
        setInitialized,
      }}
    >
      {children}
    </EventContext.Provider>
  );
}
