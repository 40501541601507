import { createReducer } from '@reduxjs/toolkit';

import {
  CREATE_BUSINESS_API,
  CREATE_BUSINESS_FAILURE_API,
  CREATE_BUSINESS_SUCCESS_API,
  DISCONNECT_CONNECTION,
  DISCONNECT_CONNECTION_FAILURE,
  DISCONNECT_CONNECTION_SUCCESS,
  DELETE_BUSINESS_API,
  DELETE_BUSINESS_FAILURE_API,
  DELETE_BUSINESS_SUCCESS_API,
  FETCH_BUSINESSES,
  FETCH_BUSINESSES_FAILURE,
  FETCH_BUSINESSES_GET_SERVICES,
  FETCH_BUSINESSES_GET_SERVICES_FAILURE,
  FETCH_BUSINESSES_GET_SERVICES_SUCCESS,
  FETCH_BUSINESSES_STATS,
  FETCH_BUSINESSES_STATS_FAILURE,
  FETCH_BUSINESSES_STATS_SUCCESS,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_SINGLE_BUSINESSES,
  FETCH_SINGLE_BUSINESSES_FAILURE,
  FETCH_SINGLE_BUSINESSES_SUCCESS,
  GENERATE_BUSINESS_URL_API,
  GENERATE_BUSINESS_URL_FAILURE_API,
  GENERATE_BUSINESS_URL_SUCCESS_API,
  RENAME_BUSINESS_API,
  RENAME_BUSINESS_FAILURE_API,
  RENAME_BUSINESS_SUCCESS_API,
  RESET_BUSINESS_INFORMATION,
  RESET_ERROR,
  RESET_GENERATED_URL,
  RESET_RESPONSE,
  SET_SELECTED_BUSINESS,
  RESET_STORAGE_BUSINESS,
  RESET_BUSINESS_SUGGESTIONS,
  FETCH_BUSINESS_SUGGESTIONS_SUCCESS,
  FETCH_BUSINESS_SUGGESTIONS,
  FETCH_BUSINESS_SUGGESTIONS_FAILURE,
} from './business.action';
import { BUSINESS_INITIAL_STATE, BUSINESS_STORE_NAME } from './business.state';

export const businessReducer = createReducer(BUSINESS_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_BUSINESS, (state) => ({ ...state, ...BUSINESS_INITIAL_STATE }))
    .addCase(RESET_BUSINESS_INFORMATION, () => BUSINESS_INITIAL_STATE)
    .addCase(RESET_ERROR, (state) => ({
      ...state,
      error: null,
    }))
    .addCase(RESET_RESPONSE, (state) => ({
      ...state,
      response: null,
    }))
    .addCase(RESET_GENERATED_URL, (state) => ({
      ...state,
      generatedUrl: null,
    }))
    .addCase(RESET_BUSINESS_SUGGESTIONS, (state) => {
      return {
        ...state,
        businessSuggestions: [],
        businessLoading: false,
      };
    })
    .addCase(FETCH_BUSINESS_SUGGESTIONS, (state) => {
      return {
        ...state,
        businessLoading: true,
        businessSuggestions: [],
      };
    })
    .addCase(FETCH_BUSINESS_SUGGESTIONS_SUCCESS, (state, action: any) => {
      return {
        ...state,
        type: 'all',
        businessSuggestions: action.payload.businesses,
        businessLoading: false,
      };
    })
    .addCase(FETCH_BUSINESS_SUGGESTIONS_FAILURE, (state, action: any) => {
      return {
        ...state,
        businessLoading: false,
        businessSuggestions: [],
        error: action.payload,
      };
    })
    .addCase(FETCH_BUSINESSES, (state) => {
      return {
        ...state,
        businessLoading: true,
      };
    })
    .addCase(FETCH_BUSINESSES_SUCCESS, (state, action: any) => {
      return {
        ...state,
        type: 'all',
        businesses: action.payload.businesses,
        meta: action.payload.meta,
        businessLoading: false,
      };
    })
    .addCase(FETCH_BUSINESSES_FAILURE, (state, action: any) => {
      return {
        ...state,
        businessLoading: false,
        businesses: [],
        error: action.payload,
      };
    })
    .addCase(FETCH_SINGLE_BUSINESSES, (state) => {
      return {
        ...state,
        singleBusinessLoading: true,
      };
    })
    .addCase(FETCH_SINGLE_BUSINESSES_SUCCESS, (state, action: any) => {
      return {
        ...state,
        type: 'single',
        selectedBusiness: action.payload.businesses?.[0],
        singleBusinessLoading: false,
      };
    })
    .addCase(FETCH_SINGLE_BUSINESSES_FAILURE, (state, action: any) => {
      return {
        ...state,
        singleBusinessLoading: false,
        selectedBusiness: null,
        error: action.payload,
      };
    })
    .addCase(FETCH_BUSINESSES_STATS, (state) => ({ ...state, loading: true }))
    .addCase(FETCH_BUSINESSES_STATS_SUCCESS, (state, action: any) => ({
      ...state,
      loading: false,
      businessStats: action.payload,
    }))
    .addCase(FETCH_BUSINESSES_STATS_FAILURE, (state) => ({
      ...state,
      loading: false,
    }))
    .addCase(FETCH_BUSINESSES_GET_SERVICES, (state) => ({
      ...state,
      loading: true,
    }))
    .addCase(FETCH_BUSINESSES_GET_SERVICES_SUCCESS, (state, action: any) => ({
      ...state,
      services: action.payload,
      loading: false,
    }))
    .addCase(FETCH_BUSINESSES_GET_SERVICES_FAILURE, (state, action: any) => ({
      ...state,
      loading: false,
      error: { ...action.payload, message: ['services'] },
    }))
    .addCase(SET_SELECTED_BUSINESS, (state, action: any) => ({
      ...state,
      selectedBusiness: action.payload,
    }))
    .addCase(DISCONNECT_CONNECTION, (state) => ({
      ...state,
      loading: true,
    }))
    .addCase(DISCONNECT_CONNECTION_SUCCESS, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(DISCONNECT_CONNECTION_FAILURE, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(DELETE_BUSINESS_API, (state) => ({
      ...state,
      loading: true,
    }))
    .addCase(DELETE_BUSINESS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(DELETE_BUSINESS_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(CREATE_BUSINESS_API, (state) => ({
      ...state,
      loading: true,
      generatedUrl: null,
    }))
    .addCase(CREATE_BUSINESS_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(CREATE_BUSINESS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(RENAME_BUSINESS_API, (state) => ({
      ...state,
      loading: true,
      generatedUrl: null,
    }))
    .addCase(RENAME_BUSINESS_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      response: action.payload,
    }))
    .addCase(RENAME_BUSINESS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(GENERATE_BUSINESS_URL_API, (state) => ({
      ...state,
      loading: true,
      generatedUrl: null,
    }))
    .addCase(GENERATE_BUSINESS_URL_SUCCESS_API, (state, action: any) => ({
      ...state,
      loading: false,
      generatedUrl: action.payload,
    }))
    .addCase(GENERATE_BUSINESS_URL_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }));
});

export const businessReducerConfig = {
  [BUSINESS_STORE_NAME]: businessReducer,
};
