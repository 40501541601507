import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

import {
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';

import { Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { FormInputProps } from '../../../types';

import useStyles from './style';

interface Option {
  name: string;
  value: string;
}

interface Props extends FormInputProps {
  options: Array<Option>;
  clearAll: () => void;
  handleDelete: (value: string) => void;
}

function get<T = unknown>(obj: Record<string, unknown>, path: string, defaultValue?: T): T {
  if (!obj || typeof path === 'undefined') return;
  return path
    .split('.')
    .reduce(
      (pointer, key) =>
        pointer && typeof pointer[key] !== 'undefined' ? pointer[key] : defaultValue,
      obj,
    ) as T;
}

const FormMultiSelect = ({
  label,
  name,
  errorobj,
  control,
  testid = '',
  options,
  handleDelete,
  clearAll,
  ...rest
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const errorMessage = get<{ message: string }>(errorobj, name)?.message || '';

  const handleChange = ({ value }): void => {
    control.setValue(name, value, { shouldValidate: true });
  };

  const getChipLabel = (selectedValue: string): string =>
    options.find((opt) => opt.value === selectedValue)?.name;

  const onDelete = (optionVal): void => {
    handleDelete(optionVal);
    if (!control.getValues(name)?.length) {
      try {
        (document.activeElement as HTMLElement).blur();
      } catch (err) {}
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }): JSX.Element => (
        <FormControl fullWidth variant="outlined" size="small" error={!!errorMessage} {...rest}>
          <InputLabel id="multiselect-label">{t(label)}</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            labelId="multiselect-label"
            id="multiselect"
            multiple
            role="multiselect"
            fullWidth
            value={value}
            margin="dense"
            onChange={(event): void => {
              /* react-hook-form wasn't updating with the default onchange
               * so just do it manually and trigger the internal onchange of Mui
               *  so that the internal values are updated */
              handleChange(event.target);
              onChange(event);
            }}
            input={<OutlinedInput style={{ paddingRight: 0 }} label={t(label)} />}
            inputProps={{
              'data-testid': testid,
            }}
            endAdornment={
              control.getValues(name)?.length ? (
                <InputAdornment className={classes.clearIcon} position="end">
                  <ClearIcon onClick={clearAll} data-testid="clear-cta" />
                </InputAdornment>
              ) : null
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected): any => (
              <div className={classes.chips}>
                {(selected as string[]).map((optionVal) => (
                  <Chip
                    onDelete={(): void => onDelete(optionVal)}
                    onMouseDown={(event): void => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    key={optionVal}
                    label={getChipLabel(optionVal)}
                    className={classes.chip}
                    size="small"
                  />
                ))}
              </div>
            )}
          >
            {options.map(({ name, value }) => (
              <MenuItem classes={{ selected: classes.selected }} key={name} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{t(errorMessage)}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormMultiSelect;
