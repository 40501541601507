import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { Menu, IconButton, Typography, MenuItem, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SendSharpIcon from '@material-ui/icons/SendSharp';

import { useSelector, useDispatch } from 'react-redux';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import { RailzButton } from '@railzai/railz-uikit-react';

import { ArrowBack } from '@material-ui/icons';

import { Pagination } from '@material-ui/lab';

import useStyles from './style';

import UpdateTeamMemberForm from './update-team-member-form';

import DeleteTeamMember from './delete-team-member';

import { Header, ScrollView, View } from 'components';
import {
  getTeamApiResponse,
  getTeamErrorMessage,
  getTeamV2ApiResponse,
} from 'store/features/account/team/team.selector';
import {
  fetchTeamApi,
  fetchTeamV2Api,
  resendTeamMemberInvitationApi,
  resetTeamError,
  resetTeamResponse,
  resetTeamState,
} from 'store/features/account/team/team.action';
import BasicTable from 'components/table';
import { PaginationProps, PAGINATION_LIMIT, Role, TeamRole, TeamUserResponse } from 'types';
import {
  getProfileState,
  getRoleOnTeam,
  getUserRole,
} from 'store/features/account/profile/profile.selector';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import { capitalizeFirstLetter, showSnackbar } from 'helpers/common.helper';
import Chip from 'components/chip';
import { AllState } from 'store/state';
import InviteTeamMemberForm from 'components/invite-team-member-form';

interface Props {
  testid?: string;
}

const isAdminRole = (role: Role): boolean => {
  return [Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR].includes(role);
};

const TeamPage: React.FC<Props> = ({ testid = 'test-header-team-page' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [queryParams] = useQuerySearchUpdate();

  const profile = useSelector(getProfileState);
  const team = useSelector(getTeamV2ApiResponse);
  const teamResponse = useSelector(getTeamApiResponse);
  const teamErrorMessage = useSelector(getTeamErrorMessage);

  const [menuAnchorEl, setMenuAnchorEl] = useState({});

  const [querySearch] = useQuerySearchUpdate();
  const [showInviteForm, setShowInviteForm] = useState<boolean>(false);
  const [member, setMember] = useState(null);
  const [showEditFormData, setShowEditForm] = useState<TeamUserResponse>(null);
  const [showDeleteForm, setShowDeleteForm] = useState(false);

  const [teamUuid] = useState(querySearch?.get('uuid'));
  const [teamsPaginationPage] = useState(parseInt(queryParams?.get('teamsPage')));
  const roleOnTeam = useSelector((state: AllState) => getRoleOnTeam(state, teamUuid));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const isSuperAdmin = roleOnTeam === Role.SUPER_ADMINISTRATOR;
  const userRole = useSelector(getUserRole);

  const menuOptions = [
    {
      key: 'edit',
      icon: <EditIcon />,
      label: t('DASHBOARD_TEAM_EDIT_CTA'),
      show: (member): boolean => {
        if (profile.uuid === member.userUuid) return true;
        if (isAdminRole(roleOnTeam)) {
          if (roleOnTeam === Role.ADMINISTRATOR && member.role === Role.SUPER_ADMINISTRATOR)
            return false;
          return true;
        }
        return false;
      },
      action: (member: TeamUserResponse): void => {
        if (profile.uuid === member.userUuid) {
          history.push('/account/profile');
        } else {
          setShowEditForm(member);
        }
      },
      testid: 'edit-member-action',
    },
    {
      key: 'resendInvite',
      icon: <SendSharpIcon />,
      label: t('DASHBOARD_TEAM_RESEND_INVITE_CTA'),
      show: (member): boolean =>
        member.state !== 'active' && isAdminRole(userRole) && isAdminRole(roleOnTeam),
      action: (member: TeamUserResponse): void => {
        setMember(member);
        onResendTeamMemberInvitation(member.email);
      },
      testid: 'resend-invite-member',
    },
    {
      key: 'delete',
      icon: <DeleteOutlineIcon />,
      label: t('DASHBOARD_TEAM_REMOVE_CTA'),
      color: theme.palette.error.main,
      show: (member): boolean =>
        !(member.role === Role.SUPER_ADMINISTRATOR) &&
        (isSuperAdmin || (isAdminRole(roleOnTeam) && !member.isCurrentUser)),
      action: (member: TeamUserResponse): void => {
        setMember(member);
        setShowDeleteForm(true);
      },
      testid: 'delete-member-option',
    },
  ];

  const getFullName = (member): string => `${member?.firstName || ''} ${member?.lastName || ''}`;

  const refreshTeam = (params?: PaginationProps): any => {
    const payload = { ...params, teamUuid, currentPage, paginationLimit: PAGINATION_LIMIT };
    dispatch(fetchTeamV2Api(payload));
  };

  useEffect(() => {
    // TODO: the following action doesn't do anything AFAICS.
    //       Should we remove all calls to this action in the codebase?
    dispatch(fetchTeamApi());

    // On first load, if the user is either part of the team or not yet loaded, we fetch the team
    if (teamUuid && (roleOnTeam || !profile?.uuid)) {
      refreshTeam();
    }
    return () => {
      dispatch(resetTeamState());
    };
  }, [profile.uuid, teamUuid]);

  useEffect(() => {
    if (profile?.uuid && !roleOnTeam) {
      history.push('/account/teams');
    }
  }, [roleOnTeam, profile]);

  useEffect(() => {
    if (teamResponse) {
      dispatch(resetTeamResponse());
      if (teamResponse.message === 'resendInvitation') {
        showSnackbar({
          message: (
            <Trans
              i18nKey="DASHBOARD_TEAM_RESEND_INVITATION_SUCCESS"
              values={{
                fullName: member?.firstName ? getFullName(member) : member?.email,
              }}
            />
          ),
          type: 'success',
        });
      }

      // note: when we add delete member functionality we need to refresh team
      if (teamResponse.success) {
        refreshTeam();
      }

      setMember(null);
    }
    // eslint-disable-next-line
  }, [teamResponse]);

  useEffect(() => {
    if (!isEmpty(teamErrorMessage)) {
      dispatch(resetTeamError());
      if (teamErrorMessage[0] === 'resendInvitation') {
        showSnackbar({
          message: (
            <Trans
              i18nKey="DASHBOARD_TEAM_RESEND_INVITATION_FAILURE"
              values={{
                fullName: member?.firstName ? getFullName(member) : member?.email,
              }}
            />
          ),
          type: 'error',
        });
      }
    }

    setMember(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamErrorMessage, dispatch]);

  const columns = [
    {
      key: 'name',
      name: t('DASHBOARD_TEAMS_TEAM_FULLNAME'),
      render: ({ firstName, lastName, isCurrentUser }): React.ReactElement => {
        const name =
          firstName || lastName
            ? `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`
            : '-';

        return (
          <div>
            <span>{name}</span>
            {isCurrentUser && (
              <span className={classes.youText}>{t('DASHBOARD_TEAM_MEMBERS_ROLE_YOU')}</span>
            )}
          </div>
        );
      },
    },
    {
      key: 'email',
      name: t('DASHBOARD_TEAMS_TEAM_EMAIL'),
      render: ({ email, state }): React.ReactElement => (
        <span className={classes.email}>
          <span className={classes.emailLabel}>{email}</span>
          {state !== 'active' && (
            <Chip chipText={t('DASHBOARD_BUSINESS_STATUS_PENDING')} size="small" />
          )}
        </span>
      ),
    },
    {
      key: 'parsedRole',
      name: t('DASHBOARD_TEAMS_TEAM_ROLE'),
      render: ({ parsedRole, owner }): React.ReactElement => (
        <div className={classes.roleDescription}>
          <span className={classes.role}>{parsedRole}</span>
          {owner && (
            <span className={classes.ownerText}>{t('DASHBOARD_TEAM_MEMBERS_ROLE_OWNER')}</span>
          )}
        </div>
      ),
    },
    {
      key: 'cta',
      name: t('DASHBOARD_TEAM_MEMBERS_EDIT_DELETE_COL_HEADER'),
      render: (item): React.ReactElement => {
        const options = menuOptions.filter(({ show }) => show(item));

        return (
          <>
            <IconButton
              aria-owns={
                menuAnchorEl?.[item.userUuid] ? `${item.userUuid}-overflow-menu` : undefined
              }
              aria-haspopup="true"
              onClick={(event): void =>
                setMenuAnchorEl({ ...menuAnchorEl, [item.userUuid]: event.currentTarget })
              }
              disabled={!options.length}
              data-testid={`${item.userUuid}-overflow-menu-button`}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`${item.userUuid}-overflow-menu`}
              anchorEl={menuAnchorEl?.[item.userUuid]}
              open={Boolean(menuAnchorEl?.[item.userUuid])}
              onClose={(): void => setMenuAnchorEl({ ...menuAnchorEl, [item.userUuid]: null })}
              data-testid={`${item.userUuid}-overflow-menu`}
            >
              {options.map(({ key, action, color, icon, label, testid }) => (
                <MenuItem
                  key={key}
                  aria-label={label}
                  onClick={(): void => {
                    setMenuAnchorEl({ ...menuAnchorEl, [item.userUuid]: null });
                    action(item);
                  }}
                  style={{ color: color }}
                  data-testid={testid}
                >
                  {icon}
                  <span style={{ marginLeft: '16px' }}>{label}</span>
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
    },
  ];

  const onResendTeamMemberInvitation = (email: string): void => {
    dispatch(resendTeamMemberInvitationApi({ email }));
  };

  const routeToTeams = (): void => {
    history.push({
      pathname: `/account/teams`,
      search: `?page=${teamsPaginationPage}`,
    });
  };

  return (
    <>
      <Header
        drawerMenu
        title={t('DASHBOARD_NAV_TEAM')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />

      <View className={classes.view} data-testid="content">
        <div className={classes.viewHeader}>
          <RailzButton
            type="text primary"
            label={t('DASHBOARD_TEAMS_TEAM_BACK')}
            onButtonClick={(): void => routeToTeams()}
            data-testid={'go-back-cta'}
          >
            <span slot="prefix">
              <ArrowBack fontSize="small" />
            </span>
          </RailzButton>

          <div className={classes.headerContent}>
            <Typography
              id="page-title"
              data-testid="page-title"
              className={classes.title}
              variant="h2"
            >
              {team?.teamName || ''}
            </Typography>
            {team?.description && (
              <Typography
                id="page-subtitle"
                data-testid="page-subtitle"
                className={classes.subtitle}
                variant="body1"
              >
                {team.description}
              </Typography>
            )}
          </div>

          {isAdminRole(roleOnTeam) && isAdminRole(userRole) && (
            <RailzButton
              label={t('DASHBOARD_TEAMS_CTA_CREATE_TEAM_MEMBER')}
              onClick={(): void => setShowInviteForm(true)}
              size="large"
              data-testid="invite-member-cta"
              className={classes.primaryCta}
            >
              <span slot="prefix">
                <AddIcon />
              </span>
            </RailzButton>
          )}
        </div>

        <ScrollView classNames={classes.viewContent}>
          <BasicTable
            isLoading={!team}
            stickyHeader
            data-testid="member-table"
            columns={columns}
            rows={team?.users?.map((member: any) => ({
              ...member,
              parsedRole: TeamRole[member.role],
              isCurrentUser: member.email === profile?.email,
            }))}
            isPaperComponent={false}
            isHoverable={false}
            noData={team && team?.users?.length && t('DASHBOARD_TEAM_USERS_NO_DATA_FOUND')}
          />
        </ScrollView>

        {team?.users && team?.meta?.totalPages > 1 && (
          <div className={classes.pagination}>
            <Pagination
              count={team?.meta?.totalPages}
              page={currentPage}
              defaultPage={1}
              onChange={(_, pagerNumber): void => {
                setCurrentPage(pagerNumber);
                refreshTeam({
                  offset: (pagerNumber - 1) * PAGINATION_LIMIT,
                });
              }}
            />
          </div>
        )}
      </View>

      {showInviteForm && (
        <InviteTeamMemberForm onClose={(): void => setShowInviteForm(false)} teamUuid={teamUuid} />
      )}

      {showEditFormData && (
        <UpdateTeamMemberForm
          onClose={(): void => setShowEditForm(null)}
          member={showEditFormData}
          teamUuid={teamUuid}
        />
      )}

      {showDeleteForm && (
        <DeleteTeamMember
          onClose={(): void => setShowDeleteForm(false)}
          member={member}
          teamName={team?.teamName}
          fetchTeamPayload={{ teamUuid, currentPage, paginationLimit: PAGINATION_LIMIT }}
          teamUuid={teamUuid}
        />
      )}
    </>
  );
};

export default TeamPage;
