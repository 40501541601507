import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      gap: theme.spacing(1),
      marginTop: -3,
      maxWidth: '90%',
    },
    chip: {
      fontSize: '0.75rem',
      backgroundColor: `${theme.palette.grey[200]} !important`,

      '& .MuiChip-label': {
        padding: `0 ${theme.spacing(1.5)}px !important`,
      },
    },
    selected: {
      color: theme.palette.primary.main,
      // material ui sets this even if you override here, use important as a last resort!
      backgroundColor: `${theme.palette.background.paper} !important`,
      fontWeight: 600,
    },
    clearIcon: {
      position: 'absolute',
      right: '33px',
      cursor: 'pointer',
      '& svg': {
        color: theme.palette.grey[200],
        height: '16px',
        width: '16px',
      },
    },
    select: {
      padding: '0 !important',
      lineHeight: 1,
      maxHeight: theme.spacing(5),
      '&:focus': {
        backgroundColor: `${theme.palette.background.paper}`,
      },
    },
  }),
);
