import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { ALL_SPECIAL_CHARS, NotificationType, WebhookConfig } from '../../../../types';

import { WEBHOOK_PAYLOAD_TYPE } from '../webhook.utils';

import style from './style';

import {
  updateWebhookConfigApi,
  resetWebhookConfigResponse,
  resetWebhookConfigError,
} from 'store/features/developer/webhook-config/webhook-config.action';

import { FormInput, FormSelect } from 'components/form';

import { formChange, showSnackbar } from 'helpers/common.helper';
import {
  getWebhookConfigError,
  getWebhookConfigSuccess,
  isWebhookConfigLoading,
} from 'store/features/developer/webhook-config/webhook-config.selector';
import { openNewTab } from 'helpers/open-new-tab';
import i18n from 'translations';

const DASHBOARD_PAYLOAD_TYPE_DOC =
  'https://docs.railz.ai/reference/webhook-data-synchronized#payload-types';

const PAYLOAD_TYPE_OPTIONS = Object.values(WEBHOOK_PAYLOAD_TYPE).map((option) => {
  return {
    name: i18n.t(`DASHBOARD_WEBHOOK_PAYLOAD_TYPE_${option.toUpperCase()}`),
    value: option,
  };
});

const defaultValues = {
  parameterName: '',
  payloadType: WEBHOOK_PAYLOAD_TYPE.FULL,
};

const snackOptions: Record<'update' | 'updateError', [string, NotificationType]> = {
  update: ['DASHBOARD_SAVE_TOAST_SUCCESS', 'success'],
  updateError: ['DASHBOARD_SAVE_TOAST_ERROR', 'error'],
};

export const WebhookConfigForm = ({
  webhookConfig,
  isWebhookSecretLoading,
}: {
  webhookConfig: WebhookConfig;
  isWebhookSecretLoading?: boolean;
}): React.ReactElement => {
  const { t } = useTranslation();
  const classes = style();
  const dispatch = useDispatch();

  const isLoading = useSelector(isWebhookConfigLoading);
  const isSuccessResp = useSelector(getWebhookConfigSuccess);
  const errorResp = useSelector(getWebhookConfigError);
  const [submitAction, setSubmitAction] = useState('update');

  const validationSchema = yup.object().shape({
    parameterName: yup
      .string()
      .trim()
      .nullable()
      .max(40, t('DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_CRUD_MAX'))
      .test({
        message: t('DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_CRUD_MIN'),
        test: (value) => {
          if (value === undefined) {
            return true;
          }
          return value.length === 0 || value.length >= 2;
        },
      })
      .test({
        message: t('DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_ERROR_CHARACTERS'),
        test: (value) => !ALL_SPECIAL_CHARS.some((char) => value?.includes(char)),
      })
      .test({
        test: (value) => !['requestid', 'data'].includes(value?.toLowerCase()),
        message: t('DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_EXISTS_ERROR'),
      }),
  });

  const { handleSubmit, errors, control, reset, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue('parameterName', webhookConfig?.requestIdParam || defaultValues.parameterName);
    setValue('payloadType', webhookConfig?.payloadType || defaultValues.payloadType);
  }, [webhookConfig]);

  useEffect(() => {
    if (isSuccessResp) {
      const [message, type] = snackOptions[submitAction];
      showSnackbar({ message: t(message), type });
      dispatch(resetWebhookConfigResponse());
    }
  }, [isSuccessResp]);

  useEffect(() => {
    if (errorResp) {
      const [message, type] = snackOptions[`${submitAction}Error`];
      showSnackbar({ message: t(message), type });
      dispatch(resetWebhookConfigError());
      reset({
        parameterName: webhookConfig?.requestIdParam,
        payloadType: webhookConfig?.payloadType as WEBHOOK_PAYLOAD_TYPE,
      });
    }
  }, [errorResp]);

  const handleDeleteRequestParam = async (): Promise<void> => {
    setValue('parameterName', defaultValues.parameterName);
  };

  const onSubmitData = (values): void => {
    const isFormChanged = formChange(values, {
      parameterName: webhookConfig?.requestIdParam || '',
      payloadType: webhookConfig?.payloadType,
    });
    if (isFormChanged) {
      dispatch(
        updateWebhookConfigApi({
          requestIdParam: values.parameterName || null,
          payloadType: values.payloadType,
        }),
      );
      setSubmitAction('update');
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }
  };

  const showClearCta = watch('parameterName');

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitData)}>
        <Typography className={classes.subTitle}>
          {t('DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_HEADER')}
        </Typography>
        <Typography className={classes.description}>
          {t('DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_DESCRIPTION')}
        </Typography>
        <Grid container spacing={1} className={classes.formGrid}>
          <FormInput
            margin="dense"
            className={`${classes.parameterNameInput} ${classes.inputField}`}
            placeholder="DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_PLACEHOLDER"
            label="DASHBOARD_DEVELOPERS_WEBHOOKS_PARAMETER_PLACEHOLDER"
            variant="outlined"
            autoFocus
            fullWidth
            name="parameterName"
            errorobj={errors}
            control={control}
            testid={`webhook-config-form-parameter-name-input`}
          />
          {showClearCta && (
            <RailzButton
              type="text warning"
              buttonType="button"
              onClick={handleDeleteRequestParam}
              aria-label="DASHBOARD_CLEAR_CTA"
              label={t('DASHBOARD_CLEAR_CTA')}
              className={classes.deleteButton}
              data-testid="delete-webhook-config-button"
            >
              <span slot="prefix">
                <DeleteOutlinedIcon fontSize="small" />
              </span>
            </RailzButton>
          )}
        </Grid>
        <Typography className={classes.subTitle}>
          {t('DASHBOARD_DEVELOPERS_WEBHOOKS_PAYLOAD_TYPE_TITLE')}
        </Typography>
        <Typography className={classes.description} paragraph={true}>
          <Trans
            i18nKey="DASHBOARD_DEVELOPERS_WEBHOOKS_PAYLOAD_TYPE_DESC"
            components={{
              a: (
                <a
                  onClick={(e): void => openNewTab(e, DASHBOARD_PAYLOAD_TYPE_DOC)}
                  rel="noopener noreferrer"
                  className={classes.linkLearnMore}
                  href={DASHBOARD_PAYLOAD_TYPE_DOC}
                >
                  {t('DASHBOARD_SEE_INSTRUCTIONS_CTA')}
                </a>
              ),
            }}
          />
        </Typography>
        <FormSelect
          name="payloadType"
          label="DASHBOARD_WEBHOOK_PAYLOAD_TYPE_LABEL"
          placeholder="DASHBOARD_WEBHOOK_PAYLOAD_TYPE_LABEL"
          options={PAYLOAD_TYPE_OPTIONS}
          errorobj={errors}
          control={control}
          className={classes.inputField}
          testid="payload-type-input"
        />
        <RailzButton
          onButtonClick={(): any => handleSubmit(onSubmitData)}
          loading={isLoading || isWebhookSecretLoading}
          className={classes.button}
          data-testid="webhook-config-submit"
          label={t('DASHBOARD_SAVE_CHANGES_CTA')}
        />
      </form>
    </>
  );
};
