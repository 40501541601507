import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

import { isProfileBaseLoading } from '../../store/features/account/profile/profile.selector';
import { isRefreshingToken } from '../../store/features/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const Loading = (): React.ReactElement => {
  const classes = useStyles();

  const refreshingToken = useSelector(isRefreshingToken);
  const loadingProfile = useSelector(isProfileBaseLoading);

  const showLoading = !!(loadingProfile || refreshingToken);
  return (
    <Backdrop
      className={classes.backdrop}
      open={showLoading}
      transitionDuration={{ enter: 300, exit: 300 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
