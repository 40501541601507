import React, { useEffect } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SchemaOf } from 'yup';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from './style';

import { FormIpWhitelistProps } from 'types';
import { FormInput } from 'components/form';
import { IP_REGEX } from 'helpers/regex.helper';
import { formChange, showSnackbar } from 'helpers/common.helper';

interface IFormValue {
  ip: string;
  name: string;
}

const Form = ({
  ip = {},
  ipWhitelist = [],
  onCancel = (): void => null,
  onSubmit,
  errorsMessage = [],
}: FormIpWhitelistProps): React.ReactElement => {
  const classes = useStyle();
  const { t } = useTranslation();

  const validationSchema: SchemaOf<IFormValue> = yup.object().shape({
    ip: yup
      .string()
      .required('DASHBOARD_RAILZ_CONNECT_IP_REQUIRED')
      .nullable()
      .matches(IP_REGEX, 'DASHBOARD_RAILZ_CONNECT_IP_INVALID')
      .test({
        message: t('DASHBOARD_RAILZ_CONNECT_IP_ADDRESS_DUPLICATE'),
        test: (value) => {
          const isDuplicate = ipWhitelist
            ?.filter(({ uuid }) => uuid != ip.uuid)
            .some(({ ip }) => value === ip);
          return !isDuplicate;
        },
      }),
    name: yup
      .string()
      .required('DASHBOARD_RAILZ_CONNECT_IP_LABEL_REQUIRED')
      .nullable()
      .trim()
      .max(15, 'DASHBOARD_RAILZ_CONNECT_IP_LABEL_MAX')
      .min(4, 'DASHBOARD_RAILZ_CONNECT_IP_LABEL_MIN')
      .test({
        message: t('DASHBOARD_RAILZ_CONNECT_IP_LABEL_DUPLICATE'),
        test: (value) => {
          const isDuplicate = ipWhitelist
            ?.filter(({ uuid }) => uuid != ip.uuid)
            .some(({ name }) => value.toLowerCase() === name.toLowerCase());
          return !isDuplicate;
        },
      }),
  });

  const { handleSubmit, errors, control, formState, setError } = useForm({
    mode: 'onChange',
    defaultValues: ip,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (errorsMessage.length) {
      errorsMessage.forEach(({ key, message }) => {
        setError(key, { message });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsMessage]);

  const { isSubmitting } = formState;

  const onSubmitData = (values): void => {
    values.ip = `${values.ip.trim()}`;
    const defaultValues = { ip: '', name: '' };
    if (formChange(values, { ...defaultValues, ...ip })) {
      onSubmit(values);
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitData)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormInput
            defaultValue=""
            testid="add-ip-address-input"
            placeholder="DASHBOARD_RAILZ_CONNECT_IP_PLACEHOLDER"
            label="DASHBOARD_RAILZ_CONNECT_IP"
            variant="outlined"
            autoFocus={true}
            fullWidth
            name="ip"
            errorobj={errors}
            control={control}
            margin="dense"
            inputProps={{
              autoComplete: 'new-password',
              'data-lpignore': 'true',
              'data-1p-ignore': 'true',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            defaultValue=""
            placeholder="DASHBOARD_RAILZ_CONNECT_IP_LABEL_PLACEHOLDER"
            label="DASHBOARD_RAILZ_CONNECT_IP_LABEL"
            testid="add-ip-label-input"
            variant="outlined"
            fullWidth
            name="name"
            errorobj={errors}
            control={control}
            margin="dense"
            inputProps={{
              autoComplete: 'new-password',
              'data-lpignore': 'true',
              'data-1p-ignore': 'true',
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.actions}>
        <RailzButton
          type="text primary"
          onButtonClick={onCancel}
          className={classes.cancel}
          buttonType="button"
          label={t('DASHBOARD_RAILZ_CONNECT_IP_ADD_CTA_CANCEL')}
        />

        <RailzButton
          size="large"
          buttonType="submit"
          isDisabled={isSubmitting}
          data-testid="add-ip-address-button"
          label={t('DASHBOARD_RAILZ_CONNECT_IP_ADD_CTA')}
        >
          {isSubmitting ? (
            <span slot="prefix">
              <CircularProgress size={20} color="inherit" />
            </span>
          ) : null}
        </RailzButton>
      </div>
    </form>
  );
};

export default Form;
