import { Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useStyles from './styles';

import i18n from 'translations';

interface Props {
  title?: string;
  subtitle?: string;
  subtitleComponents?: { [key: string]: any };
  children?: any;
  className?: any;
  titleAriaLabel?: string;
}

const PageDescription = ({
  title,
  subtitle,
  subtitleComponents,
  children,
  className,
  titleAriaLabel,
}: Props): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={`${classes.container} ${className || ''}`}>
      {title && (
        <Typography
          id="page-title"
          data-testid="page-title"
          className={classes.title}
          variant="h2"
          aria-label={titleAriaLabel}
        >
          {i18n.exists(title) ? t(title) : ''}
        </Typography>
      )}
      {subtitle && (
        <Typography
          id="page-subtitle"
          data-testid="page-subtitle"
          className={classes.subtitle}
          variant="body1"
        >
          <Trans
            i18nKey={i18n.exists(subtitle) ? t(subtitle) : ''}
            components={subtitleComponents}
          />
        </Typography>
      )}
      {children && (
        <Typography className={classes.subtitle} variant="body1">
          {children}
        </Typography>
      )}
    </div>
  );
};
export default PageDescription;
