import React, { useEffect, useRef, useState } from 'react';

import EventService from '../../services/event.service';
import { INotification } from '../../types';

import NotificationMessageBox from './notification-message-box.component';
import NotificationSnackbar from './notification-snackbar.component';

const initialMessageBoxNotification: INotification = {
  showAs: 'snackbar',
  type: 'error',
  title: '',
  message: '',
};

const NotificationComponent: React.FC = () => {
  const messageBoxNotification = useRef(initialMessageBoxNotification);
  const [visibleMessageBox, setVisibleMessageBox] = useState(false);
  const snackbarNotification = useRef(initialMessageBoxNotification);
  const [visibleSnackbar, setVisibleSnackbar] = useState(false);

  const [, setLastHandledEvent] = useState(null);
  // this is a hack to force a re-render on the component when a new even is handled

  const currentCanBeReplacedBySameTopic = (next: INotification): boolean => {
    const current =
      next.showAs === 'messagebox' ? messageBoxNotification.current : snackbarNotification.current;

    if (!current.topic || !current.isPinned) return true;
    return current.topic === next?.topic;
  };

  useEffect(() => {
    EventService.addListener(EventService.EVENT.SHOW_NOTIFICATION, handleEvent);

    return (): void => {
      EventService.removeListener(EventService.EVENT.SHOW_NOTIFICATION, handleEvent);
    };
  }, []);

  const handleEvent = (newNotification: INotification): void => {
    if (newNotification.showAs === 'messagebox') {
      if (!currentCanBeReplacedBySameTopic(newNotification)) return;
      messageBoxNotification.current = newNotification;
      setVisibleMessageBox(true);
      setLastHandledEvent(newNotification);
    } else if (newNotification.showAs === 'snackbar') {
      if (!currentCanBeReplacedBySameTopic(newNotification)) return;
      snackbarNotification.current = newNotification;
      setVisibleSnackbar(true);
      setLastHandledEvent(newNotification);
    }
  };

  return (
    <React.Fragment>
      <NotificationMessageBox
        open={visibleMessageBox}
        notification={messageBoxNotification.current}
        onClose={(): void => setVisibleMessageBox(false)}
      />
      <NotificationSnackbar
        open={visibleSnackbar}
        notification={snackbarNotification.current}
        onClose={(): void => setVisibleSnackbar(false)}
      />
    </React.Fragment>
  );
};

export default NotificationComponent;
