import React from 'react';

import { Switch, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { IntegrationStatus, ServiceWithIntegrations } from '../../../../types';

// eslint-disable-next-line max-len
import { updateIntegrationsStateApi } from './../../../../store/features/integration/integration.action';

import { isActive, isBeta, isNotToggleable } from './helper';

import { CustomerState } from './../../../../store/features/account/customer/customer.state';

import { getLocalizedService } from 'helpers/common.helper';
import { View } from 'components';

interface Props {
  service: ServiceWithIntegrations;
  user: CustomerState;
  classes: Record<string, string>;
  dispatch: any;
}
function stopPropagationOfEvent(e: React.MouseEvent | React.ChangeEvent): void {
  e.preventDefault();
  e.stopPropagation();
  e.bubbles = false;
}

export const AllIntegrationsOfServiceToggle: React.FC<Props> = ({
  service,
  user,
  classes,
  dispatch,
}): React.ReactElement => {
  const { t } = useTranslation();

  const toggleEnable = async (checked: boolean): Promise<void> => {
    if (isNotToggleable(service)) return;

    try {
      const state = checked ? IntegrationStatus.ENABLED : IntegrationStatus.DISABLED;
      await dispatch(updateIntegrationsStateApi({ state, service: service.name }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View flexDirection="row" alignItems="center">
      {isBeta(service) && !isBeta(user)
        ? null
        : isActive(service) && (
            <Typography color={service.enabled ? 'primary' : 'textSecondary'}>
              {service.enabled
                ? t('DASHBOARD_INTEGRATION_TOGGLED_ON')
                : t('DASHBOARD_INTEGRATION_TOGGLED_OFF')}
            </Typography>
          )}
      {isBeta(service) && !isBeta(user)
        ? null
        : isActive(service) && (
            <Switch
              checked={service.enabled}
              disabled={isNotToggleable(service)}
              inputProps={{
                'aria-label': `${t(
                  service.enabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )} ${getLocalizedService(service.name)}`,
              }}
              onChange={(e, checked): Promise<void> => {
                stopPropagationOfEvent(e);
                return toggleEnable(checked);
              }}
              onClick={(e): void => {
                stopPropagationOfEvent(e);
              }}
              style={{ zIndex: 100 }}
              color="primary"
              className={classes.checkboxContainer}
            />
          )}
    </View>
  );
};
