import React, { useEffect, useRef, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AllBusinessStatus } from '../../types/enums';
import { generateDropdown } from '../../../../helpers/common.helper';

import useStyles from './styles';

interface Props {
  selectedStatus: string;
  handleSelected: (string) => void;
}

export default function Status({
  selectedStatus: parentSelectedStatus,
  handleSelected,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const statuses = generateDropdown(AllBusinessStatus);

  useEffect(() => {
    setSelectedStatus(parentSelectedStatus);
  }, [parentSelectedStatus]);

  const onChange = (event): void => {
    setSelectedStatus(event.target.value);
    handleSelected && handleSelected(event.target.value);
  };

  return (
    <TextField
      ref={ref}
      fullWidth
      label={t('DASHBOARD_SHOW_LABEL')}
      classes={{
        root: classes.root,
      }}
      margin="dense"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      SelectProps={{
        MenuProps: {
          anchorEl: ref.current,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          PopoverClasses: {
            paper: classes.menu,
          },
        },
      }}
      select
      value={selectedStatus || AllBusinessStatus.ACTIVE}
      onChange={onChange}
      data-testid="status-input"
    >
      {statuses.map((status, index) => (
        <MenuItem key={index} value={status.value}>
          {status?.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
