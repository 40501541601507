import React from 'react';
import { Chip, PropTypes } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyle from './style';

interface ChipProps {
  chipText: string;
  size?: 'small' | 'medium';
  color?: Exclude<PropTypes.Color, 'inherit'>;
  className?: string;
  loading?: boolean;
  testId?: string;
}

export default function RailzChip(props: ChipProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyle();
  const {
    chipText,
    className,
    size = 'small',
    color = 'secondary',
    loading = false,
    testId = '',
  } = props;

  return (
    !loading && (
      <Chip
        label={t(chipText)}
        color={color}
        size={size}
        className={`${classes.chip} ${className}`}
        data-testid={`chip-${size}-${testId}`}
      />
    )
  );
}
