import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import useStyles from './style';

import { FormInput } from 'components/form';
import { showSnackbar } from 'helpers/common.helper';
import AlertDialog from 'components/dialog';
import {
  resetCustomerError,
  resetCustomerResponse,
  transferOwnershipApi,
} from 'store/features/account/customer/customer.action';
import {
  getCustomerApiResponse,
  getCustomerError,
  isCustomerLoading,
} from 'store/features/account/customer/customer.selector';

const createTranslationString =
  (values: Record<string, string>) =>
  ({ i18nKey }: { i18nKey: string }): React.ReactElement =>
    (
      <Trans
        shouldUnescape={true}
        tOptions={{ interpolation: { escapeValue: true } }}
        i18nKey={i18nKey}
        components={{ bold: <strong /> }}
        values={values}
      />
    );

const TransferOwnershipModal = ({
  onClose,
  member,
}: {
  onClose: () => void;
  member: any;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { t } = useTranslation();
  const nameToDisplay =
    member?.firstName && member?.lastName ? `${member.firstName} ${member.lastName}` : member.email;
  const TranslatedString = createTranslationString({
    Name: nameToDisplay,
  });

  const { handleSubmit, errors, control, formState, getValues, setError } = useForm({
    mode: 'onTouched',
    defaultValues: { currentPassword: '' },
    resolver: yupResolver(
      yup.object().shape({
        currentPassword: yup.string().required('DASHBOARD_MEMBERS_TRANSFER_HEADER2'),
      }),
    ),
  });

  const customerError = useSelector(getCustomerError);
  const customerApiResponse = useSelector(getCustomerApiResponse);
  const customerLoading = useSelector(isCustomerLoading);
  const [showCancelButton, setShowCloseButton] = useState(false);

  const onSubmitData = async (values): Promise<void> => {
    const { currentPassword } = values;
    await dispatch(transferOwnershipApi({ userUuid: member.uuid, currentPassword }));
  };

  useEffect(() => {
    const { currentPassword } = getValues();
    setShowCloseButton(currentPassword?.length > 0);
  }, [getValues()]);

  useEffect(() => {
    if (formState.isSubmitting && !customerLoading && customerApiResponse) {
      if (customerApiResponse === 'transferOwnership') {
        showSnackbar({
          message: t('DASHBOARD_MEMBERS_ACCOUNT_TRANSFER_SUCCESS_TOAST'),
          type: 'success',
        });
        dispatch(resetCustomerResponse());
        onClose();
      }
    }
  }, [customerApiResponse]);

  useEffect(() => {
    if (!customerLoading && customerError?.error) {
      dispatch(resetCustomerError());
      if (customerError?.error === 'Bad Request' || customerError?.error === 'Forbidden') {
        setError('currentPassword', {
          type: 'auth',
          message: t('DASHBOARD_MEMBERS_TRANSFER_PASSWORD_INCORRECT'),
        });
      } else {
        showSnackbar({
          message: t('DASHBOARD_MEMBERS_ACCOUNT_TRANSFER_FAIL_TOAST'),
          type: 'error',
        });
      }
    }
  }, [customerError]);

  if (customerLoading)
    return (
      <AlertDialog isOpen={true}>
        <Grid container className={classes.loading} direction="column">
          <Grid item>
            <Typography className={classes.confirmationLabel} paragraph={true}>
              <TranslatedString i18nKey="DASHBOARD_MEMBERS_TRANSFER_MODAL_WAIT_MESSAGE" />
            </Typography>
          </Grid>

          <Grid item>
            <CircularProgress size="3rem" />
          </Grid>
        </Grid>
      </AlertDialog>
    );

  return (
    <AlertDialog
      isOpen={true}
      title={t('DASHBOARD_MEMBERS_TRANSFER_HEADER')}
      cancel={
        showCancelButton && {
          label: t('DASHBOARD_TEAM_ADD_CTA_CANCEL'),
          onClick: onClose,
          'data-testid': 'cancel-button',
          type: 'gray',
        }
      }
      confirm={{
        type: 'error',
        label: t('DASHBOARD_MEMBERS_TRANSFER_COMPLETE_CTA'),
        onClick: handleSubmit(onSubmitData),
        'data-testid': 'cta-button',
      }}
      onClose={onClose}
      showCloseButton
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e): any => {
          e.preventDefault();
        }}
      >
        <Grid container>
          <Typography className={classes.confirmationLabel} paragraph={true}>
            <TranslatedString i18nKey="DASHBOARD_MEMBERS_TRANSFER_DESCRIPTION" />
          </Typography>

          <div className={classes.labelContainer}>
            <Typography className={classes.confirmationLabel} paragraph={true}>
              <TranslatedString i18nKey="DASHBOARD_MEMBERS_TRANSFER_HEADER2" />
            </Typography>
          </div>

          <Grid item xs={12} md={12}>
            <FormInput
              className={classes.confirmationInput}
              defaultValue=""
              id="currentPassword"
              name="currentPassword"
              margin="dense"
              testid="currentPassword-input"
              fullWidth
              errorobj={errors}
              control={control}
              type="password"
            />
          </Grid>
        </Grid>
      </form>
    </AlertDialog>
  );
};

export default TransferOwnershipModal;
