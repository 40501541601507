import React, { createContext, useContext, useState } from 'react';

import { ICommonProviderProps } from '../../../types';

import { ActionTypes } from '../types/enums';
import { Business } from '../types/interfaces';

export type BusinessEventType = {
  event: ActionTypes.RENAME | ActionTypes.ADD | ActionTypes.MANAGE_SERVICES | ActionTypes.DELETE;
  contextBusiness: Business;
  openUpdateOrCreateModal: boolean;
  setOpenUpdateOrCreateModal: (openUpdateModal: boolean) => void;
  setEvent: (
    event: ActionTypes.RENAME | ActionTypes.ADD | ActionTypes.MANAGE_SERVICES | ActionTypes.DELETE,
  ) => void;
  setContextBusiness: (business: Business) => void;
  openDisconnectBusinessModal: boolean;
  setOpenDisconnectBusinessModal: (openDisconnectBusinessModal: boolean) => void;
  openDeleteBusinessModal: boolean;
  setOpenDeleteBusinessModal: (openDeleteBusinessModal: boolean) => void;
};

export const BusinessEventContext = createContext<BusinessEventType>(null);
export const useBusinessContext = (): BusinessEventType => useContext(BusinessEventContext);

export default function BusinessEventProvider({
  children,
  initialValues = {},
}: ICommonProviderProps & { initialValues?: Partial<BusinessEventType> }): React.ReactElement {
  const [event, setEvent] = useState(initialValues?.event || null);
  const [contextBusiness, setContextBusiness] = useState(initialValues?.contextBusiness || null);
  const [openUpdateOrCreateModal, setOpenUpdateOrCreateModal] = useState<boolean>(
    !!initialValues?.openUpdateOrCreateModal,
  );
  const [openDisconnectBusinessModal, setOpenDisconnectBusinessModal] = useState<boolean>(
    !!initialValues?.openDisconnectBusinessModal,
  );
  const [openDeleteBusinessModal, setOpenDeleteBusinessModal] = useState<boolean>(
    !!initialValues?.openDeleteBusinessModal,
  );

  const handleSetOpenUpdateModal = (val: boolean): void => {
    if (!val) {
      setContextBusiness(null);
    }
    setOpenUpdateOrCreateModal(val);
  };

  return (
    <BusinessEventContext.Provider
      value={{
        event,
        setEvent,
        contextBusiness,
        setContextBusiness,
        openUpdateOrCreateModal,
        setOpenUpdateOrCreateModal: handleSetOpenUpdateModal,
        openDisconnectBusinessModal,
        setOpenDisconnectBusinessModal,
        openDeleteBusinessModal,
        setOpenDeleteBusinessModal,
      }}
    >
      {children}
    </BusinessEventContext.Provider>
  );
}
