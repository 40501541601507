// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiKeySaga } from './api-key/api-key.saga';
import { webhookSaga } from './webhook/webhook.saga';
import { webhookSecretSaga } from './webhook-secret/webhook-secret.saga';
import { webhookConfigSaga } from './webhook-config/webhook-config.saga';

export const developerSaga = [
  ...webhookSaga,
  ...apiKeySaga,
  ...webhookSecretSaga,
  ...webhookConfigSaga,
];
