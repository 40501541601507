import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import { RailzButton } from '@railzai/railz-uikit-react';

import { ArrowBack } from '@material-ui/icons';

import useStyle from './style';

import { EMAIL_TEAM_INVALID_DOMAINS_REGEX } from 'helpers/regex.helper';
import { FormInput } from 'components/form';

export interface FormValue {
  email: string;
}

interface Props {
  onSubmit: SubmitHandler<FormValue>;
}

const initialValues: FormValue = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('DASHBOARD_LOGIN_EMAIL_REQUIRED')
    .required('DASHBOARD_LOGIN_EMAIL_REQUIRED')
    .matches(EMAIL_TEAM_INVALID_DOMAINS_REGEX, 'DASHBOARD_EMAIL_NOT_WORK_EMAIL')
    .nullable(),
});
const ForgotPasswordForm: React.FC<any> = ({ onSubmit }: Props) => {
  const classes = useStyle();
  const history = useHistory();
  const { t } = useTranslation();
  const { handleSubmit, errors, formState, control } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;

  const handleSigninNavigation = (): void => {
    history.push('/login');
  };

  return (
    <div className={classes.view}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <FormInput
              label="DASHBOARD_RESET_PASSWORD_EMAIL_LABEL"
              placeholder="DASHBOARD_RESET_PASSWORD_EMAIL_PLACEHOLDER"
              id="email"
              name="email"
              fullWidth
              control={control}
              errorobj={errors}
              testid="email-input"
              autoFocus
            />
          </Grid>
        </Grid>
        <RailzButton
          size="large"
          buttonType="submit"
          isDisabled={isSubmitting}
          data-testid="submit"
          label={t('DASHBOARD_RESET_PASSWORD_EMAIL_CTA')}
          className={classes.forgotPasswordButton}
        />
      </form>
      <RailzButton
        type="text primary"
        label={t('DASHBOARD_BACK_TO_LOGIN')}
        onButtonClick={handleSigninNavigation}
      >
        <span slot="prefix">
          <ArrowBack fontSize="small" />
        </span>
      </RailzButton>
    </div>
  );
};

export default ForgotPasswordForm;
