import { createStyles, makeStyles } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from '../../providers/theme-provider';

export default makeStyles(() =>
  createStyles({
    chip: {
      fontSize: 12,
      color: ACTIVE_TEXT_COLOR,
      padding: '4px 14px',
      '& span': {
        padding: 0,
        fontWeight: 600,
      },
    },
  }),
);
