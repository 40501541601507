import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { useDispatch, useSelector } from 'react-redux';

import useStyles from './style';

import { FormInput, FormSelect } from 'components/form';
import { formChange, generateDropdown } from 'helpers/common.helper';
import { Role } from 'types';
import AlertDialog from 'components/dialog';
import {
  fetchTeamApi,
  resetTeamError,
  resetTeamResponse,
  updateTeamMemberRoleApi,
} from 'store/features/account/team/team.action';
import {
  getTeamApiResponse,
  getTeamError,
  isTeamLoading,
} from 'store/features/account/team/team.selector';
import { getUserRole } from 'store/features/account/profile/profile.selector';
import { EventService } from 'services';
import UserDetails from 'components/user-details';

const roleOptions = generateDropdown(Role);

const validationSchema = yup.object().shape({
  members: yup.array().of(
    yup.object().shape(
      {
        team: yup.string().nullable().when('role', {
          is: '',
          then: yup.string().nullable(),
        }),
        role: yup
          .string()
          .nullable()
          .when('team', {
            is: '',
            then: yup.string().nullable(),
            otherwise: yup.string().required('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE_REQUIRED'),
          }),
      },
      [
        ['team', 'role'],
        ['role', 'team'],
      ],
    ),
  ),
});

interface FormProp {
  onClose: () => void;
  member: any;
}

const EditTeamMemberForm = ({ onClose, member }: FormProp): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { handleSubmit, errors, control, formState, watch, setValue } = useForm({
    mode: 'onTouched',
    defaultValues: {
      members: member.teams.map(({ name, role, uuid }) => ({
        team: name,
        role,
        teamUuid: uuid,
        userUuid: member.uuid,
      })),
    },
    resolver: yupResolver(validationSchema),
  });

  const [roleSelectableOptions, setRoleSelectableOptions] = useState(roleOptions);
  const { fields } = useFieldArray({ name: 'members', control });
  const role = useSelector(getUserRole);

  const teamErrorMessage = useSelector(getTeamError);
  const teamResponse = useSelector(getTeamApiResponse);
  const teamLoading = useSelector(isTeamLoading);

  const [showSuperAdminUpgradeAlert, setShowSuperAdminUpgradeAlert] = useState(false);
  const [showSuperAdminDowngradeAlert, setShowSuperAdminDowngradeAlert] = useState(false);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [currentRole, setCurrentRole] = useState('');
  const [makeSuperAdmin, setMakeSuperAdmin] = useState(false);

  const { isDirty } = formState;
  const onSubmitData = (values): void => {
    const baseTeamsMember = member.teams.map(({ name, role }) => ({ team: name, role }));
    const memberTeams = values.members.map(({ team: name, role }) => {
      const team = member.teams.find((item) => item.name === name);
      return { role, teamUuid: team.uuid };
    });

    if (formChange(values.members, baseTeamsMember)) {
      if (!teamLoading) {
        dispatch(updateTeamMemberRoleApi({ memberUuid: member.uuid, memberTeams }));
      }
    } else {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: <Trans i18nKey="DASHBOARD_BRANDING_NO_CHANGES_MADE"></Trans>,
      });
    }
  };

  useEffect(() => {
    setRoleSelectableOptions(
      role !== Role.SUPER_ADMINISTRATOR
        ? roleOptions.filter(({ value }) => value !== Role.SUPER_ADMINISTRATOR)
        : roleOptions,
    );
  }, [role]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      if (teamErrorMessage) {
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'error',
          message: <Trans i18nKey="DASHBOARD_BRAND_DATA_FAILED"></Trans>,
        });
        dispatch(resetTeamError());
      }
      if (teamResponse) {
        dispatch(fetchTeamApi());
        dispatch(resetTeamResponse());
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'success',
          message: <Trans i18nKey="DASHBOARD_TEAM_MEMBER_EDIT_SUCESS_TOAST"></Trans>,
        });
        onClose();
      }
    }
  }, [teamErrorMessage, teamResponse]);

  const roleValues = fields.map((item, i) => watch(`members[${i}].role`));

  useEffect(() => {
    const orignalRoles = fields.map((item) => item.role);

    if (orignalRoles.every((role) => role === Role.SUPER_ADMINISTRATOR)) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
      if (roleValues.includes(Role.SUPER_ADMINISTRATOR)) {
        setMakeSuperAdmin(true);
      }
    }
  }, [roleValues]);

  useEffect(() => {
    if (isSuperAdmin) {
      setCurrentRole(roleValues[0] as string);

      if (!roleValues.every((role) => role === Role.SUPER_ADMINISTRATOR)) {
        setShowSuperAdminDowngradeAlert(true);
      }

      if (!roleValues.every((role) => role === roleValues[0])) {
        const changedRole = roleValues?.find((newRole) => newRole !== currentRole);
        setAllRoles(changedRole as Role);
      }
    }
    if (makeSuperAdmin) {
      if (!roleValues.every((role) => role === Role.SUPER_ADMINISTRATOR)) {
        const changedRole = roleValues?.find((newRole) => newRole !== Role.SUPER_ADMINISTRATOR);
        setAllRoles(changedRole as Role);
        setShowSuperAdminUpgradeAlert(false);
        setMakeSuperAdmin(false);
      }
    }
  }, [roleValues]);

  useEffect(() => {
    if (makeSuperAdmin) {
      setAllRoles(Role.SUPER_ADMINISTRATOR);
      setShowSuperAdminUpgradeAlert(true);
      setShowSuperAdminDowngradeAlert(false);
    }
  }, [makeSuperAdmin]);

  const setAllRoles = (role: Role): void => {
    setCurrentRole(role as string);
    fields.forEach((item, i) => {
      setValue(`members[${i}].role`, role, { shouldDirty: true });
    });
  };

  return (
    <AlertDialog
      isOpen={true}
      title={t('DASHBOARD_MEMBER_EDIT_SUBHEADER')}
      cancel={
        isDirty && {
          label: t('DASHBOARD_TEAM_ADD_CTA_CANCEL'),
          variant: 'text',
          onClick: onClose,
          asText: true,
          type: 'gray',
          'data-testid': 'edit-cancel-button',
        }
      }
      confirm={{
        label: t('DASHBOARD_TEAM_ADD_CTA_UPDATE'),
        variant: 'contained',
        onClick: handleSubmit(onSubmitData),
        size: 'large',
        'data-testid': 'edit-cta-button',
      }}
      onClose={onClose}
      showCloseButton
      isLoading={teamLoading}
    >
      <UserDetails user={member}></UserDetails>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitData)}>
        {fields.map((item, i) => (
          <div className={classes.field} key={item.id}>
            <FormInput
              placeholder="DASHBOARD_MEMBER_EDIT_MODAL_TEAM"
              label="DASHBOARD_MEMBER_EDIT_MODAL_TEAM"
              name={`members[${i}].team`}
              margin="dense"
              testid={`members[${i}].team`}
              fullWidth
              defaultValue={item.team}
              errorobj={errors}
              control={control}
              type="string"
              inputProps={{ readOnly: true }}
              disabled
            />
            <FormSelect
              placeholder="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE"
              label="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE"
              fullWidth
              margin="dense"
              name={`members[${i}].role`}
              errorobj={errors}
              control={control}
              defaultValue={item.role}
              options={roleSelectableOptions}
              testid={`members[${i}].role`}
            />
          </div>
        ))}
      </form>

      {showSuperAdminUpgradeAlert ? (
        <Alert
          severity="warning"
          icon={<WarningRoundedIcon />}
          classes={{ root: classes.alert }}
          data-testid="super-admin-upgrade-warning"
        >
          <Typography variant="body2">
            {t('DASHBOARD_MEMBER_EDIT_MODAL_SUPER_ADMIN_UPGRADE_WARNING')}
          </Typography>
        </Alert>
      ) : null}

      {showSuperAdminDowngradeAlert ? (
        <Alert
          severity="warning"
          icon={<WarningRoundedIcon />}
          classes={{ root: classes.alert }}
          data-testid="super-admin-downgrade-warning"
        >
          <Typography variant="body2">
            {t('DASHBOARD_MEMBER_EDIT_MODAL_SUPER_ADMIN_DOWNGRADE_WARNING')}
          </Typography>
        </Alert>
      ) : null}
    </AlertDialog>
  );
};

export default EditTeamMemberForm;
