import axios, { AxiosResponse } from 'axios';

import { CustomerState, StripePortalParam, StripePortalResponse } from './customer.state';

import { CustomerResponse } from 'types';

import Config from 'config';

class CustomerService {
  async getCustomer(): Promise<AxiosResponse<CustomerState>> {
    return await axios.get<CustomerState>(`${Config.SERVER_URL}/customers/customer`);
  }

  async getCustomerEmail(payload: string): Promise<AxiosResponse<CustomerState>> {
    return await axios.get<CustomerState>(
      `${Config.SERVER_URL}/customers/user/search?email=${payload}`,
    );
  }

  async updateCustomer(data: any): Promise<AxiosResponse<CustomerState>> {
    return await axios.patch<CustomerState>(`${Config.SERVER_URL}/teams/team`, data);
  }

  async updateContact(payload): Promise<AxiosResponse<CustomerState>> {
    return await axios.put<CustomerState>(`${Config.SERVER_URL}/customers/contact`, payload);
  }

  async updateCompany(data: any): Promise<AxiosResponse<CustomerResponse>> {
    return await axios.put<CustomerResponse>(`${Config.SERVER_URL}/customers/company`, data);
  }

  async deleteCompanyLogo(): Promise<void> {
    await axios.delete(`${Config.SERVER_URL}/customers/companyLogo`);
  }

  async getStripePortal(payload: StripePortalParam): Promise<AxiosResponse<StripePortalResponse>> {
    return await axios.post<StripePortalResponse>(
      `${Config.SERVER_URL}/customers/stripe/portal`,
      payload,
    );
  }

  async createCheckoutSession(): Promise<AxiosResponse<string>> {
    return await axios.post<string>(`${Config.SERVER_URL}/customers/stripe/session`);
  }

  async createSignupCheckoutSession(): Promise<AxiosResponse<string>> {
    return await axios.post<string>(`${Config.SERVER_URL}/customers/stripe/session/signup`);
  }

  async transferOwnership(payload): Promise<AxiosResponse<CustomerState>> {
    return await axios.post<CustomerState>(`${Config.SERVER_URL}/customers/transfer`, payload);
  }
}

export default new CustomerService();
