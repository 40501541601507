import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    confirmationAlert: {
      margin: `0px 0px ${theme.spacing(2)}px`,
      padding: theme.spacing(2),
      backgroundColor: '#FDF7E7',

      '& .MuiAlert-icon': {
        color: '#F2A74C',
        marginRight: '18.94px',
        padding: '3px 0px 0px',
      },
      '& .MuiAlert-message': {
        color: theme.palette.grey[800],
        fontSize: '1rem',
        lineHeight: '1.5rem',
        padding: 0,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
    },
    text: {
      color: theme.palette.grey[800],
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  }),
);
