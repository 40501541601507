import React, { useMemo, useRef } from 'react';
import { Checkbox, ListItemText, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import useStyles from './styles';

// eslint-disable-next-line max-len
import { getGroupedIntegrationsWithConnections } from 'store/features/integration/integration.selector';

interface Props {
  selections: string[];
  handleSelection: React.Dispatch<React.SetStateAction<string[]>>;
}

function groupSelection(
  selections: string[],
  groupedProviders: Array<{ name: string; uuids: string[] }>,
): string[] {
  return groupedProviders
    .filter(({ uuids }) => {
      return uuids.some((uuid) => selections.includes(uuid));
    })
    .map(({ name }) => name);
}

function unGroupSelection(
  selections: string[],
  groupedProviders: Array<{ name: string; uuids: string[] }>,
): string[] {
  return groupedProviders.reduce((acum, { uuids, name }) => {
    if (selections.includes(name)) return acum.concat(uuids);
    return acum;
  }, []);
}

export default function RelatedIntegrations({ selections, handleSelection }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef(null);
  const groupedProviders = useSelector(getGroupedIntegrationsWithConnections);
  const groupedSelections = useMemo(
    () => groupSelection(selections, groupedProviders),
    [selections, groupedProviders],
  );
  const allSelected = groupedSelections.length >= groupedProviders.length;

  const onChange = (event): void => {
    const newSelection = event.target.value as string[];
    if (newSelection.includes('all')) {
      handleSelection(allSelected ? [] : groupedProviders.map((provider) => provider.uuids).flat());
    } else {
      handleSelection(unGroupSelection(newSelection, groupedProviders));
    }
  };

  return (
    <TextField
      fullWidth
      label={t('DASHBOARD_BUSINESS_LIST_SERVICE_PROVIDER_LABEL')}
      classes={{ root: classes.root }}
      ref={ref}
      margin="dense"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      select
      SelectProps={{
        displayEmpty: true,
        autoWidth: true,
        multiple: true,
        MenuProps: {
          disableAutoFocusItem: true,
          TransitionProps: {
            onEntering: (node): void => {
              if (node) {
                node.scrollTop = 0;
                node.querySelector('li')?.focus();
              }
            },
          },
          anchorEl: ref.current,
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          PopoverClasses: { paper: classes.menu },
        },
        renderValue: (selected: string[]): JSX.Element => {
          if (allSelected)
            return (
              <span className={classes.result}>
                {t('DASHBOARD_BUSINESS_LIST_SERVICE_PROVIDER_ENUM_ALL')}
              </span>
            );

          if (!selected.length)
            return (
              <span className={classes.result}>
                {t('DASHBOARD_BUSINESS_LIST_SERVICE_PROVIDER_ENUM_SELECT_NONE')}
              </span>
            );

          const allSelectedNames = selected.sort((a, b) => a.length - b.length);

          return (
            <span className={classes.result}>
              <span>{allSelectedNames.slice(0, 2).join(', ')}</span>
              {allSelectedNames.length > 2 && <span>+{allSelectedNames.length - 2}</span>}
            </span>
          );
        },
      }}
      value={groupedSelections}
      onChange={onChange}
      data-testid="provider-input"
    >
      <MenuItem key={'all-serviceProviders'} value={'all'} className={classes.menuItem}>
        <Checkbox
          checked={allSelected}
          indeterminate={!!groupedSelections.length && !allSelected}
          color={'primary'}
        />
        <ListItemText primary={t('DASHBOARD_BUSINESS_LIST_SERVICE_PROVIDER_ENUM_SELECT_ALL')} />
      </MenuItem>
      {groupedProviders.map(({ name }) => (
        <MenuItem key={name} value={name} className={classes.menuItem}>
          <Checkbox checked={groupedSelections.includes(name)} color={'primary'} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </TextField>
  );
}
