import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    forgotPasswordButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      padding: theme.spacing(1.5),
    },
  }),
);
