import React, { useEffect, useRef } from 'react';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { flatten, omitBy, reduce, set, values } from 'lodash';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SchemaOf } from 'yup';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../../../store';

import WebookOptionalParam from '../webhook-optional-params';

import { View } from '../../../../components';

import {
  FormGeneralProps,
  FormInputProps,
  NotificationType,
  WebhookSecret as WebhookSecretType,
  WebhookSecretMethod,
  WebhookSecretRequest,
} from '../../../../types';

import style from './style';

import { FormInput, FormSelect } from 'components/form';
import { WEBSITE_WITH_PROTOCOL_REGEX } from 'helpers/regex.helper';
import {
  crateWebhookSecretApi as createWebhookSecretApi,
  deleteWebhookSecretApi,
  updateWebhookSecretApi,
} from 'store/features/developer/webhook-secret/webhook-secret.action';
// eslint-disable-next-line max-len
import { formChange, getChangedFields, showSnackbar } from 'helpers/common.helper';
// eslint-disable-next-line max-len
import { getWebhookSecretErrorMessage } from 'store/features/developer/webhook-secret/webhook-secret.selector';

interface IFormValue
  extends Omit<WebhookSecretRequest, 'state' | 'kind' | 'method' | 'header' | 'body'> {
  kind: string;
  method: string;
  header: any;
  body: any;
}

export interface IFormWebhookSecretProps extends FormGeneralProps {
  webhookSecret?: any;
}

const headerNameValidationSchema = yup
  .string()
  .trim()
  .min(2, 'DASHBOARD_HEADER_NAME_CRUD_MIN')
  .max(40, 'DASHBOARD_HEADER_NAME_CRUD_MAX')
  .required('DASHBOARD_HEADER_NAME_CRUD_MIN')
  .test(
    'ReservedKeywords',
    'DASHBOARD_HEADER_NAME_CRUD_RESERVED',
    (value): boolean =>
      !['authorization', 'railz-signature', 'railz-api-version'].includes(value.toLowerCase()),
  )
  .nullable();

const bodyNameValidationSchema = yup
  .string()
  .trim()
  .min(2, 'DASHBOARD_BODY_NAME_CRUD_MIN')
  .max(40, 'DASHBOARD_BODY_NAME_CRUD_MAX')
  .required('DASHBOARD_HEADER_NAME_CRUD_MIN')
  .test(
    'ReservedKeywords',
    'DASHBOARD_BODY_NAME_CRUD_RESERVED',
    (value): boolean => !['requestid'].includes(value.toLowerCase()),
  )
  .nullable();

const headerValueValidationSchema = yup
  .string()
  .trim()
  .min(2, 'DASHBOARD_HEADER_VALUE_CRUD_MIN')
  .max(255, 'DASHBOARD_HEADER_VALUE_CRUD_MAX')
  .required('DASHBOARD_HEADER_VALUE_CRUD_MIN')
  .nullable();

const validationSchema = (clearErrors): SchemaOf<IFormValue> =>
  yup.object().shape({
    authUrl: yup
      .string()
      .required('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_ACCESS_TOKEN_URL_INVALID')
      .min(2, 'DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_ACCESS_TOKEN_URL_INVALID')
      .max(2048, 'DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_ACCESS_TOKEN_URL_INVALID')
      .matches(
        WEBSITE_WITH_PROTOCOL_REGEX,
        'DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_ACCESS_TOKEN_URL_INVALID',
      )
      .nullable(),
    username: yup
      .string()
      .min(2, 'DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_USERNAME_INVALID')
      .max(255, 'DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_USERNAME_INVALID')
      .required('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_USERNAME_INVALID')
      .nullable(),
    password: yup
      .string()
      .min(2, 'DASHBOARD_SIGNUP_PASSWORD_REQUIRED')
      .max(255, 'DASHBOARD_SIGNUP_PASSWORD_REQUIRED')
      .required('DASHBOARD_SIGNUP_PASSWORD_REQUIRED')
      .nullable(),
    tokenName: yup
      .string()
      .min(2, 'DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_TOKEN_RESPONSE_FIELD_INVALID')
      .max(255, 'DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_TOKEN_RESPONSE_FIELD_INVALID')
      .required('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_TOKEN_RESPONSE_FIELD_INVALID')
      .nullable(),
    method: yup.string().required('DASHBOARD_INTEGRATON_MANAGE_CLIENT_SECRET_REQUIRED').nullable(),
    kind: yup.string().required('DASHBOARD_DEVELOPERS_WEBHOOK_EVENT_REQUIRED').nullable(),
    header: yup
      .array()
      .of(
        yup
          .object()
          .shape({ name: headerNameValidationSchema, value: headerValueValidationSchema }),
      )
      .test('duplicate keys', 'DASHBOARD_HEADER_NAME_CRUD_DUPLICATE', function (val): any {
        if (!val?.length) return true;
        const keywords = val.map((headerName) => {
          return headerName.name;
        });
        const allIndexes = flatten(
          values(
            omitBy(
              reduce(keywords, (a, v, i) => set(a, v, (a[v] || []).concat([i])), {}),
              (v: any) => v.length <= 1,
            ),
          ),
        );

        const element = allIndexes.length - 1;

        if (element < 1) clearErrors();

        return element >= 0
          ? this.createError({
              message: 'DASHBOARD_HEADER_NAME_CRUD_DUPLICATE',
              path: `header[${allIndexes[element]}].name`,
            })
          : true;
      }),
    body: yup
      .array()
      .of(
        yup.object().shape({ name: bodyNameValidationSchema, value: headerValueValidationSchema }),
      )
      .test('duplicate keys', 'DASHBOARD_BODY_NAME_CRUD_DUPLICATE', function (val): any {
        if (!val?.length) return true;
        const keywords = val.map((bodyName) => {
          return bodyName.name;
        });
        const allIndexes = flatten(
          values(
            omitBy(
              reduce(keywords, (a, v, i) => set(a, v, (a[v] || []).concat([i])), {}),
              (v: any) => v.length <= 1,
            ),
          ),
        );

        const element = allIndexes.length - 1;

        if (element < 1) clearErrors();

        return element >= 0
          ? this.createError({
              message: 'DASHBOARD_BODY_NAME_CRUD_DUPLICATE',
              path: `body[${allIndexes[element]}].name`,
            })
          : true;
      }),
  });

const defaultValues = {
  tokenName: '',
  password: '',
  username: '',
  authUrl: '',
  kind: 'none',
  method: WebhookSecretMethod.GET,
  header: [],
  body: [],
};

const RadioInput = (
  props: Partial<FormInputProps> & { value?: string | boolean },
): React.ReactElement => <Radio size="small" color="primary" {...props} />;

const snackOptions: Record<'create' | 'update' | 'error', [string, NotificationType]> = {
  create: ['DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_SUCCESS', 'success'],
  update: ['DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_UPDATE_SUCCESS', 'success'],
  error: ['DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_UNSUCCESSFUL', 'error'],
};

const WebhookSecretForm = ({
  webhookSecret,
  isLoading,
}: {
  webhookSecret?: WebhookSecretType;
  isLoading?: boolean;
}): React.ReactElement => {
  const [hideFormInputs, setHideFormInputs] = React.useState(true);
  const [lastAction, setLastAction] = React.useState('update');
  const classes = style();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const store = storeService.getStore();

  const errorsOnWebhook = useSelector<ReturnType<typeof store.getState>, string[]>(
    getWebhookSecretErrorMessage,
  );

  const [methodOptions] = React.useState<{ name: string; value: string }[]>(
    Object.entries(WebhookSecretMethod).map(
      ([key, value]) =>
        ({
          name: t(`DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_METHOD_${key}`),
          value,
        } as { name: string; value: string }),
    ),
  );

  const copiedClearErrors = useRef<any>();

  const { handleSubmit, errors, control, formState, reset, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues: { ...defaultValues, ...webhookSecret },
    resolver: yupResolver(validationSchema(copiedClearErrors.current)),
  });

  useEffect(() => {
    copiedClearErrors.current = clearErrors;
  }, [clearErrors]);

  const {
    fields: headerFields,
    append: appendHeader,
    remove: removeHeader,
  } = useFieldArray({ name: 'header', control });

  const {
    fields: bodyFields,
    append: appendBody,
    remove: removeBody,
  } = useFieldArray({ name: 'body', control });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      const option = !errorsOnWebhook?.length ? lastAction : 'error';
      if (lastAction === 'no-changes') {
        showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
      } else {
        const [message, type] = snackOptions[option];
        showSnackbar({ message: t(message), type });
      }
    }
  }, [errorsOnWebhook, formState.isSubmitSuccessful, lastAction]);

  useEffect(() => {
    const resetValues: WebhookSecretRequest = {
      ...defaultValues,
      ...webhookSecret,
    };
    setHideFormInputs(resetValues.kind !== 'none');
    reset(resetValues as any);
  }, [webhookSecret]);

  const onSubmitData = (values): void => {
    if (!values.header) values.header = [];
    if (!values.body) values.body = [];
    const oldValues = { ...defaultValues, ...webhookSecret };

    if (formChange(values, oldValues)) {
      setLastAction(webhookSecret ? 'update' : 'create');
      dispatch(
        webhookSecret
          ? updateWebhookSecretApi(getChangedFields(values, oldValues))
          : createWebhookSecretApi(values),
      );
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
      setLastAction('no-changes');
    }
  };

  const onKindChange = (value: string, formOnChange: (value: string) => void): void => {
    setHideFormInputs(value !== 'none');
    formOnChange(value);
    if (webhookSecret && value === 'none') {
      dispatch(deleteWebhookSecretApi());
      const [message, type] = snackOptions['update'];
      showSnackbar({ message: t(message), type });
    }
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitData)}
      data-testid="webhook-secret-form"
    >
      <Typography className={classes.description}>
        {t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_SUBHEADER')}
      </Typography>
      <Grid item md={6} sm={9} xs={12} className={classes.formGrid}>
        <Controller
          control={control}
          name="kind"
          testid="webhook-secret-kind-input"
          render={({ onChange, value }): React.ReactElement => (
            <RadioGroup
              aria-label="kind"
              name="kind"
              onChange={(event): void => onKindChange(event.target.value, onChange)}
              value={value}
              row={true}
              className={classes.radioInputs}
            >
              <FormControlLabel
                control={<RadioInput data-testid="webhook-secret-kind-none-radio" value="none" />}
                label="No Authorization"
              />
              <FormControlLabel
                control={
                  <RadioInput data-testid="webhook-secret-kind-bearer-radio" value="bearer" />
                }
                label="Bearer"
              />
            </RadioGroup>
          )}
        />
        {hideFormInputs && (
          <>
            <FormGroup className={classes.methodAndAuthUrlInput}>
              <FormInput
                label="DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_ACCESS_TOKEN_URL"
                variant="outlined"
                autoFocus={true}
                fullWidth
                name="authUrl"
                errorobj={errors}
                control={control}
                testid={`webhook-secret-authUrl-input`}
                InputProps={{
                  startAdornment: (
                    <FormSelect
                      name="method"
                      options={methodOptions}
                      variant="standard"
                      id="method-input"
                      errorobj={errors}
                      control={control}
                      testid="webhook-secret-method-input"
                    />
                  ),
                }}
              />
            </FormGroup>

            <FormInput
              label={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_USERNAME')}
              placeholder={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_USERNAME')}
              name="username"
              margin="dense"
              testid="webhook-secret-username-input"
              fullWidth
              errorobj={errors}
              control={control}
              inputProps={{
                autocomplete: 'off',
                'data-lpignore': 'true',
              }}
            />

            <FormInput
              label={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_PASSWORD')}
              placeholder={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_PASSWORD')}
              id="password"
              name="password"
              margin="dense"
              testid="webhook-secret-password-input"
              fullWidth
              errorobj={errors}
              control={control}
              type="password"
              inputProps={{
                autocomplete: 'off',
                'data-lpignore': 'true',
              }}
            />

            <FormInput
              label={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_TOKEN_RESPONSE_FIELD')}
              placeholder={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_TOKEN_RESPONSE_FIELD')}
              name="tokenName"
              margin="dense"
              testid="webhook-secret-tokenName-input"
              fullWidth
              errorobj={errors}
              control={control}
              type="string"
            />
          </>
        )}
      </Grid>
      {hideFormInputs && (
        <>
          <WebookOptionalParam
            paramType="body"
            errorobj={errors}
            control={control}
            append={appendBody}
            remove={removeBody}
            fields={bodyFields}
          />
          <WebookOptionalParam
            paramType="header"
            errorobj={errors}
            control={control}
            append={appendHeader}
            remove={removeHeader}
            fields={headerFields}
          />
          <View flexDirection="row">
            <RailzButton
              label={t('DASHBOARD_DEVELOPERS_WEBHOOKS_AUTHORIZATION_CTA')}
              className={classes.button}
              onClick={handleSubmit(onSubmitData)}
              loading={isLoading}
              data-testid="webhooks-secret-submit-button"
            />
          </View>
        </>
      )}
    </form>
  );
};

export default WebhookSecretForm;
