import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, SvgIcon } from '@material-ui/core';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import { useDispatch, useSelector } from 'react-redux';

import { cloneDeep } from 'lodash';

import Fuse from 'fuse.js';

import { Moment } from 'moment-timezone';

import SearchItems from '../../../../../components/search-items';

import useStyle from './style';
import BusinessSelectedReport from './selected-report';

import {
  AspType,
  ASP_SERVICE_TYPE,
  ReportStatus,
  ReportType,
  SelectableReportType,
  ServiceType,
  ReportFinancialStatementType,
  ReportTypeDataTypeMapping,
  isAnalyticsReport,
} from 'types';
import { setReportSummaryFilter } from 'store/features/report/report.action';
import { getBusinessSummaryFilter, getDataTypes } from 'store/features/report/report.selector';
import { getSelectedBusiness } from 'store/features/business/business.selector';
import { Business } from 'pages/business/types/interfaces';
import { FormattedDataTypes } from 'store/features/report/report.state';
import { DropDownMap } from 'helpers/common.helper';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import { clearedOutFilterForCharts } from 'helpers/business-helpers/financial-summary-helper';
import { ConnectionStatus } from 'pages/business/types/enums';
import { getReportUniqueIdentifier } from 'helpers/report-helpers';

const ReportTypesCategories = {
  [SelectableReportType.ACCOUNTING]: {
    icon: PollOutlinedIcon,
    title: 'DASHBOARD_NAV_INTEGRATIONS_ACCOUNTING',
  },
  [SelectableReportType.BANKING]: {
    icon: AccountBalanceIcon,
    title: 'DASHBOARD_NAV_INTEGRATIONS_BANKING',
  },
  [SelectableReportType.COMMERCE]: {
    icon: LocalMallOutlinedIcon,
    title: 'DASHBOARD_NAV_INTEGRATIONS_COMMERCE',
  },
  [SelectableReportType.ANALYTICS]: {
    icon: TimelineIcon,
    title: 'DASHBOARD_NAV_INTEGRATIONS_ANALYTICS',
  },
};
type ReportTypeDataGroup = {
  icon: typeof SvgIcon;
  title: string;
  serviceType: SelectableReportType;
  serviceName: AspType;
  connectionUuid: string;
  reportTypes: DropDownMap[];
};

const reportOverviewListMapper = (
  aspList: FormattedDataTypes,
  business: Business,
): ReportTypeDataGroup[] => {
  if (!aspList || !business) return [];
  const connections = business.connections || [];

  const serviceNamesAndTypes = connections
    .filter(({ status }) => status !== ConnectionStatus.PENDING)
    .reduce((acum, { serviceName, connectionId }) => {
      const serviceType = ASP_SERVICE_TYPE[serviceName];
      let reportTypes = [];

      if (Array.isArray(aspList[serviceName])) {
        reportTypes = aspList[serviceName].filter(
          // Show only implemented reports
          ({ value }) => {
            const isAnalytics = isAnalyticsReport(value as ReportType);
            const matchingDataMappingValue = Object.values(ReportTypeDataTypeMapping).find(
              (dataMapping) => {
                // Since analytics isn't a service type, this case has to be handled separately
                if (isAnalytics) {
                  return (
                    dataMapping.serviceType === ServiceType.ANALYTICS && dataMapping.value === value
                  );
                }

                return dataMapping.serviceType === serviceType && dataMapping.value === value;
              },
            );

            return !!matchingDataMappingValue?.isImplementedOnDashboard;
          },
        );
      }

      if (!acum[serviceType]) {
        acum[serviceType] = {
          ...ReportTypesCategories[serviceType],
          serviceType,
          serviceName,
          connectionUuid: connectionId,
          reportTypes: reportTypes
            .filter((t) => t.reportType !== SelectableReportType.ANALYTICS)
            .map((t) => ({
              ...t,
              value: getReportUniqueIdentifier(t, serviceType),
            })),
        };
      }

      if (
        !acum[SelectableReportType.ANALYTICS] &&
        reportTypes.some((t) => t.reportType === SelectableReportType.ANALYTICS)
      ) {
        acum[SelectableReportType.ANALYTICS] = {
          ...ReportTypesCategories[SelectableReportType.ANALYTICS],
          serviceName,
          serviceType: SelectableReportType.ANALYTICS,
          connectionUuid: connectionId,
          reportTypes: reportTypes.filter((t) => t.reportType === SelectableReportType.ANALYTICS),
        };
      }

      return acum;
    }, {}) as Record<ServiceType, ReportTypeDataGroup>;

  return Object.values(serviceNamesAndTypes).sort((a, b) => {
    // Analytics always comes first
    if (a.serviceType === SelectableReportType.ANALYTICS) {
      return -1;
    }
    if (b.serviceType === SelectableReportType.ANALYTICS) {
      return 1;
    }

    return String(a.serviceType).localeCompare(b.serviceType);
  });
};

/**
 * Checks if the current business has a service with a reportType matching the one passed in.
 * @param reports the list of reports for the currently selected business
 * @param reportTypeValue the reportType to check for (usually from the filter object)
 * @returns a serviceName and reportType object, if found, otherwise an empty object
 */
const getPossibleServiceAndReportTypeForActiveReport = (
  reports: ReportTypeDataGroup[],
  reportTypeValue: ReportType,
): { serviceName?: AspType; reportType?: DropDownMap } => {
  let selectedReportType;
  const validServiceForActiveReport = reports.find(({ reportTypes }) => {
    const reportType = reportTypes.find(({ value }) => value === reportTypeValue);
    if (!reportType) return false;
    selectedReportType = reportType;
    return true;
  });
  return { serviceName: validServiceForActiveReport?.serviceName, reportType: selectedReportType };
};

const Reports = (): JSX.Element => {
  const [reports, setReports] = React.useState<ReportTypeDataGroup[]>([]);
  const [searchedValue, setSearchedValue] = React.useState('');
  const [filteredReports, setFilteredReports] = React.useState<ReportTypeDataGroup[]>([]);
  // Starts with true so that we are able to refresh the page and be on the correct state
  const [selectedReport, setSelectedReport] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aspList = useSelector(getDataTypes);
  const selectedBusiness = useSelector(getSelectedBusiness);
  const classes = useStyle();
  const filter = useSelector(getBusinessSummaryFilter);
  const [searchQuery, setQuerySearch] = useQuerySearchUpdate();

  useEffect(() => {
    setReports(reportOverviewListMapper(aspList, selectedBusiness));
  }, [selectedBusiness, aspList]);

  const selectReport = (
    reportType?: DropDownMap,
    serviceName?: AspType,
    connectionUuid?: string,
    startDate?: string | Moment,
    endDate?: string | Moment,
    status?: ReportStatus,
  ): void => {
    const newFilterData = {
      ...clearedOutFilterForCharts,
      reportType: reportType?.value as ReportType,
      reportName: reportType?.name,
      serviceName: serviceName,
      connectionUuid: connectionUuid,
      businessName: selectedBusiness?.businessName,
      financialStatementType: ReportFinancialStatementType.BALANCE_SHEETS,
      startDate,
      endDate,
      status,
    };

    setQuerySearch(newFilterData);
    setSelectedReport(!!reportType);
    dispatch(setReportSummaryFilter(newFilterData));
  };

  useEffect(() => {
    if (selectedReport && filter.reportType && reports?.length) {
      const { serviceName, reportType } = getPossibleServiceAndReportTypeForActiveReport(
        reports,
        filter.reportType,
      );
      const connectionUuid = filter.connectionUuid || '';

      // reset the report if the business changes and the report type is not supported
      selectReport(
        reportType,
        serviceName,
        connectionUuid,
        searchQuery.get('startDate') || filter.startDate,
        searchQuery.get('endDate') || filter.endDate,
        searchQuery.get('status') as ReportStatus,
      );
    }

    const filtered = filteredForSearch();
    setFilteredReports(filtered);
  }, [reports, searchedValue]);

  const filteredForSearch = (): ReportTypeDataGroup[] => {
    const toReturn: ReportTypeDataGroup[] = cloneDeep(reports);
    if (searchedValue === '') {
      return toReturn;
    }
    const filteredToReturn = toReturn.filter((report) => {
      report.reportTypes = report.reportTypes.filter((reportType) => {
        const toSearch = searchedValue.toUpperCase();
        const name = reportType?.name?.toUpperCase();
        const fuse = new Fuse([name], { threshold: 0.3 });
        return fuse.search(toSearch).length > 0;
      });

      return report.reportTypes.length > 0;
    });
    return filteredToReturn;
  };

  if (!reports.length) {
    return <div>{t('DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_LOADING_TEXT')}</div>;
  }

  if (selectedReport) {
    return <BusinessSelectedReport goBack={selectReport} />;
  }

  return (
    <>
      <div className={classes.header}>
        <p className={classes.tabTitle}>{t('DASHBOARD_BUSINESS_REPORTS')}</p>
        <SearchItems
          className={classes.searchItems}
          handleSearch={(search): void => setSearchedValue(search)}
          i18nKey="DASHBOARD_REPORT_TYPE_SEARCH_ITEM"
        />
      </div>
      <div>
        {filteredReports.length > 0 ? (
          filteredReports.map(
            ({
              title,
              icon: ReportIcon,
              serviceType,
              serviceName,
              connectionUuid,
              reportTypes,
            }) => (
              <div
                key={serviceType}
                className={classes.reportTypesGroup}
                data-testid={`report-group-${serviceType}`}
              >
                <Typography
                  data-testid={`title-${serviceType}`}
                  className={classes.title}
                  variant="h2"
                >
                  <ReportIcon className={classes.icon} /> {t(title)}
                </Typography>

                <div className={classes.reportTypes}>
                  {reportTypes.map((reportType) => (
                    <button
                      key={reportType.value}
                      className={classes.reportType}
                      onClick={(): void => {
                        setSearchedValue('');
                        selectReport(reportType, serviceName, connectionUuid);
                      }}
                    >
                      <Typography component="span">{reportType.name}</Typography>
                    </button>
                  ))}
                </div>
              </div>
            ),
          )
        ) : (
          <div>{t('DASHBOARD_FINANCIAL_SUMMARY_CHART_NOT_SEARCH_FOUND')}</div>
        )}
      </div>
    </>
  );
};

export default Reports;
