import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: theme.typography.fontWeightBold as number,
      marginBottom: theme.spacing(3),
    },
    nameText: {
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: theme.spacing(3),
    },
    businessName: {
      minWidth: `min(${theme.spacing(44.5)}px, 100%)`,
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(0),
      },
    },
    headline: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(),
    },
    mainView: {
      height: '100%',
    },
    summary: {
      height: '100%',
      padding: theme.spacing(3),
    },

    summaryHeader: {
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: '24px !important',
      color: theme.palette.grey[900],
    },
    reportHeader: {
      fontSize: '18px !important',
      fontWeight: 600,
      color: theme.palette.primary.dark,
    },

    summarySubContainer: {
      marginTop: theme.spacing(3),
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    nameRoot: {
      '& .MuiFormLabel-root': {
        fontWeight: 600,
        color: theme.palette.grey['A700'],
      },
    },
    descriptionContainer: {
      marginBottom: theme.spacing(3),
      padding: 8,
    },
    uuidRowLabel: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      color: theme.palette.grey[600],
    },
    copyIcon: {
      marginLeft: theme.spacing(1),
      '& .MuiIconButton-root, .MuiSvgIcon-root.copy-button__icon': {
        fontSize: '1rem',
        padding: 0,
      },
      '& span.copy-button-copied-text': {
        marginLeft: theme.spacing(1),
        display: 'inline',
        fontSize: '0.75rem',
        fontWeight: 400,
        color: theme.palette.grey[600],
      },
    },
  }),
);
