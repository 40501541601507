import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(-1),
    },
    input: {
      marginBottom: theme.spacing(1),
    },
  }),
);
