import i18n from '../../translations';

import { ITableField, ReportQuery, ReportType } from '../../types';

import { ExportFileType, REPORT_PAGINATION_LIMIT, getHeaderTable } from './constants';
import { exportFileFunction } from './exporters';

import { PaginationData } from './types';

import ReportDownload from './large-report-exports';

import { showSnackbar } from 'helpers/common.helper';

function generateReportFilename(businessName: string, report: ReportType): string {
  return `${businessName.substring(0, 6)}_${report}`.toLowerCase();
}

export const isLargeReportToExport = (pagination: PaginationData): boolean => {
  const { pagination: reportPagination } = pagination;
  return reportPagination && reportPagination.count > REPORT_PAGINATION_LIMIT;
};

export const generateFullReportAndExport = async (
  initialReportData: any[],
  initialPaginationData: PaginationData,
  businessName: string,
  filter: ReportQuery,
  type = ExportFileType.xlsx,
  controller: AbortController,
): Promise<void> => {
  let allData = initialReportData;

  if (isLargeReportToExport(initialPaginationData)) {
    allData = await ReportDownload(initialPaginationData, filter, controller);
    if (!allData) return;
  }

  const fileName = generateReportFilename(businessName, filter.reportType);
  const headerTable: ITableField[] = getHeaderTable(filter.reportType);
  exportFileFunction(allData, fileName, headerTable, type);
  showSnackbar({
    topic: 'report-export',
    message: i18n.t('DASHBOARD_REPORT_DOWNLOAD_TOAST_SUCCESS'),
    type: 'success',
  });
};
