import React from 'react';
import { Button } from '@material-ui/core';

import useStyles from './style';

interface Props {
  id?: string;
  logo: string;
  label: string;
  iconStyle?: any;
  onClick: () => void;
}

export const SSOButton: React.FC<Props> = ({ id, logo, label, iconStyle, onClick }) => {
  const classes = useStyles();

  return (
    <Button id={id} className={classes.button} onClick={onClick}>
      <div className={classes.label}>
        <img src={logo} className={classes.logo} />
        <span style={iconStyle}>{label}</span>
      </div>
    </Button>
  );
};
