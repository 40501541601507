import { AnyAction, createReducer } from '@reduxjs/toolkit';

import { REPORT_INITIAL_STATE, REPORT_STORE_NAME } from './report.state';
import {
  FETCH_DATA_TYPES_API,
  FETCH_DATA_TYPES_FAILURE_API,
  FETCH_DATA_TYPES_SUCCESS_API,
  FETCH_REPORTS_API,
  FETCH_REPORTS_FAILURE_API,
  FETCH_REPORTS_SUCCESS_API,
  SET_REPORT_SUMMARY_FILTER,
  RESET_REPORTS_API,
  FETCH_SYNC_STATUS_BUSINESS_SUCCESS,
  FETCH_SYNC_STATUS_BUSINESS_FAILURE,
  FETCH_SYNC_STATUS_BUSINESS_API,
  SET_REPORT_EXPORT_STATE,
  FETCH_REPORTS_PENDING_API,
  RESET_STORAGE_REPORT,
} from './report.action';

import { getChangedFields } from 'helpers/common.helper';
export const reportReducer = createReducer(REPORT_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_REPORT, () => REPORT_INITIAL_STATE)
    .addCase(FETCH_REPORTS_API, (state: any) => state)
    .addCase(FETCH_REPORTS_PENDING_API, (state: any, action: any) => ({
      ...state,
      report: {
        ...state.report,
        requestParams: action.payload.requestParams,
        loading: true,
      },
    }))
    .addCase(FETCH_REPORTS_SUCCESS_API, (state, action: AnyAction) => ({
      ...state,
      report: { ...action.payload, requestParams: state.report.requestParams, loading: false },
    }))
    .addCase(FETCH_REPORTS_FAILURE_API, (state, action: AnyAction) => ({
      ...state,
      report: { ...action.payload, requestParams: state.report.requestParams, loading: false },
    }))
    .addCase(RESET_REPORTS_API, (state) => ({
      ...state,
      report: REPORT_INITIAL_STATE.report,
    }))
    .addCase(FETCH_DATA_TYPES_API, (state) => ({
      ...state,
      aspDataTypes: {
        ...state.aspDataTypes,
        loading: true,
      },
    }))
    .addCase(FETCH_DATA_TYPES_SUCCESS_API, (state, action: any) => ({
      ...state,
      aspDataTypes: {
        loading: false,
        reportTypes: action.payload,
      },
    }))
    .addCase(FETCH_DATA_TYPES_FAILURE_API, (state, action: any) => ({
      ...state,
      aspDataTypes: {
        loading: false,
        reportTypes: action.payload,
      },
    }))
    .addCase(SET_REPORT_SUMMARY_FILTER, (state, action: any) => {
      if (action.payload && state.filter) {
        // TODO: this is a hack to avoid some calls to the backend when nothing
        // was changed in the filters. The problem is that multiple places update the filters
        // and sometimes the observed filter value is not changed in time and the
        // backend is called multiple times. This REDUCES the number of calls to the backend.
        const changedProps = getChangedFields(action.payload, state.filter);
        const shouldSwallowChangeAttempt = !Object.keys(changedProps).length;
        if (shouldSwallowChangeAttempt) return state;
      }
      return {
        ...state,
        filter: action.payload,
      };
    })
    .addCase(SET_REPORT_EXPORT_STATE, (state, action: any) => ({
      ...state,
      reportExport: action.payload,
    }))
    .addCase(FETCH_SYNC_STATUS_BUSINESS_API, (state) => ({
      ...state,
      businessHistory: {
        loading: true,
        success: false,
        error: '',
      },
    }))
    .addCase(FETCH_SYNC_STATUS_BUSINESS_SUCCESS, (state, action: any) => ({
      ...state,
      businessHistory: {
        loading: false,
        history: action.payload,
        success: true,
      },
    }))
    .addCase(FETCH_SYNC_STATUS_BUSINESS_FAILURE, (state, action: any) => ({
      ...state,
      businessHistory: {
        loading: false,
        error: action.payload,
        success: false,
      },
    }));
});

export const reportReducerConfig = {
  [REPORT_STORE_NAME]: reportReducer,
};
