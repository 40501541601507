import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: '0px',
    },
    subTitle: {
      fontWeight: 600,
      fontSize: '1rem',
      marginBottom: theme.spacing(1),
      lineHeight: `${theme.spacing(4)}px`,
    },
    parameterNameInput: {
      marginBottom: theme.spacing(0),
      width: theme.spacing(30),
    },

    description: {
      maxWidth: 732,
      margin: `0px 0px ${theme.spacing(1)}px 0px`,
    },

    button: {
      marginTop: theme.spacing(3),
      display: 'block',
    },

    deleteButton: {
      display: 'block',
      margin: `${theme.spacing(1.5)}px 0px ${theme.spacing(1)}px 0px`,
    },
    linkLearnMore: {
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    inputField: {
      marginTop: theme.spacing(1),
      minWidth: theme.spacing(30),
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(17),
      },
    },
  }),
);
