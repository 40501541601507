import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Link,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../store';

import { isProfileMfaEnabled } from '../../store/features/account/profile/profile.selector';

import { generateMfaApi } from '../../store/features/account/profile/profile.action';

import useStyle from './style';
import PasswordForm from './password';
import MfaForm from './mfa/index';
import MfaVerifyForm from './mfa/verify/index';

import { Header, View } from 'components';
import { openNewTab } from 'helpers/open-new-tab';
import { showSnackbar } from 'helpers/common.helper';

interface Props {
  testid: string;
}

export const Security: React.FC<Props> = ({
  testid = 'test-header-security-page',
}): React.ReactElement => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>();
  const [submitedMfaDeleteRequest, setSubmitedMfaDeleteRequest] = useState(false);

  const classes = useStyle();
  const store = storeService.getStore();
  const dispatch = useDispatch();
  const mfaEnabled = useSelector<ReturnType<typeof store.getState>, boolean>(isProfileMfaEnabled);

  useEffect(() => {
    if (!mfaEnabled && submitedMfaDeleteRequest) {
      showSnackbar({
        message: t('DASHBOARD_SECURITY_2FACTOR_DISABLE_TOAST_SUCCESS'),
        type: 'success',
      });
      setSubmitedMfaDeleteRequest(false);
    }
  }, [mfaEnabled]);

  const handleChange = (panel: string): void => {
    if (panel !== expanded) {
      if (panel === 'mfa' && !mfaEnabled) {
        dispatch(generateMfaApi());
      }
      setExpanded(panel);
    } else {
      setExpanded(false);
    }
  };

  const showMfaMessage = (): void => {
    setSubmitedMfaDeleteRequest(true);
  };

  const getMfaButton = (): React.ReactElement => {
    if (mfaEnabled) {
      return (
        <ButtonToggle
          description={t('DASHBOARD_SECURITY_MFA_DISABLE_LABEL')}
          onClick={handleChange}
          isFor="mfaDisable"
          ariaLabel={t('DASHBOARD_SECURITY_MFA_DISABLE_ARIA_LABEL')}
        />
      );
    }
    return (
      <ButtonToggle
        description={t('DASHBOARD_SECURITY_MFA_LABEL')}
        onClick={handleChange}
        isFor="mfa"
        ariaLabel={t('DASHBOARD_SECURITY_MFA_ENABLE_ARIA_LABEL')}
      />
    );
  };

  const titles = {
    password: <div>{t('DASHBOARD_SECURITY_PASSWORD_HEADER')}</div>,
    mfa: <div>{t('DASHBOARD_SECURITY_MFA_HEADER')}</div>,
  };

  return (
    <>
      <Header
        drawerMenu={true}
        title={t('DASHBOARD_SECURITY_HEADER')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />
      <View>
        <Card className={classes.container}>
          <Box>
            <CardHeader
              className={classes.cardHeader}
              title={titles.password}
              action={
                <ButtonToggle
                  description={t('DASHBOARD_SECURITY_PASSWORD_CTA')}
                  onClick={handleChange}
                  isFor="password"
                  ariaLabel={t('DASHBOARD_SECURITY_PASSWORD_PURPOSE')}
                />
              }
              subheader={
                <Box data-testid="change-password-subheader">
                  <Typography
                    variant="subtitle1"
                    paragraph={true}
                    className={classes.noMarginBottom}
                  >
                    {<Trans i18nKey="DASHBOARD_SECURITY_PASSWORD_PURPOSE" />}
                  </Typography>
                </Box>
              }
            />
            {expanded === 'password' && <PasswordForm onClose={(): void => setExpanded(null)} />}
          </Box>
          <Divider />
          <Box>
            <CardHeader
              className={classes.cardHeader}
              title={titles.mfa}
              subheader={
                <Box data-testid="security-mfa-subheader">
                  <Typography variant="subtitle1" paragraph={true}>
                    {t('DASHBOARD_SECURITY_MFA_PURPOSE')}
                    <span aria-hidden="true"> </span>
                    <Link
                      onClick={(e): void =>
                        openNewTab(
                          e,
                          'https://docs.railz.ai/docs/security#two-factor-authentication',
                        )
                      }
                      rel="noopener noreferrer"
                      href="https://docs.railz.ai/docs/security#two-factor-authentication"
                      className={classes.link}
                      aria-label={
                        t('DASHBOARD_SECURITY_MFA_PURPOSE') + ' ' + t('DASHBOARD_LEARN_MORE_CTA')
                      }
                    >
                      {t('DASHBOARD_LEARN_MORE_CTA')}
                    </Link>
                  </Typography>
                </Box>
              }
              action={getMfaButton()}
            />
            {mfaEnabled ? (
              <Collapse in={expanded === 'mfaDisable'}>
                <CardContent>
                  <MfaVerifyForm
                    isOpen={expanded === 'mfaDisable'}
                    handleMfaToast={(): void => showMfaMessage()}
                  />
                </CardContent>
              </Collapse>
            ) : (
              <Collapse in={expanded === 'mfa'}>
                <CardContent>
                  <MfaForm isOpen={expanded === 'mfa'} />
                </CardContent>
              </Collapse>
            )}
          </Box>
        </Card>
      </View>
    </>
  );
};

const ButtonToggle = ({
  description,
  onClick,
  isFor,
  ariaLabel,
  disabled = false,
}): React.ReactElement => {
  const store = storeService.getStore();
  const mfaEnabled = useSelector<ReturnType<typeof store.getState>, boolean>(isProfileMfaEnabled);

  if (mfaEnabled && isFor !== 'password') {
    return (
      <RailzButton
        data-testid={`btn-${isFor}`}
        type="outlined"
        label={description}
        onButtonClick={(): void => onClick(isFor)}
        aria-label={ariaLabel}
      />
    );
  }
  return (
    <RailzButton
      type="outlined"
      data-testid={`btn-${isFor}`}
      label={description}
      isDisabled={disabled}
      onButtonClick={(): void => onClick(isFor)}
      aria-label={ariaLabel}
    />
  );
};
