import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[400]}`,
      fontWeight: 500,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      minWidth: '140px',
    },
    logo: {
      marginRight: theme.spacing(1.5),
    },
  }),
);
