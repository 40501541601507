import { call, put, takeLatest } from 'redux-saga/effects';
import { isNil } from 'lodash';

import { CustomerResponse } from '../../../../types';

import { default as CustomerUserService } from '../../../../services/customer.service';
import { default as ProfileService } from '../profile/profile.service';

import { EnableDisableSSOAction, ProfileState } from '../profile/profile.state';

import { updateMembersOwnerSuccessApiV2 } from '../team/team.action';

import {
  createStripePaymentFailureApi,
  createStripeCheckoutSuccessApi,
  DELETE_COMPANY_LOGO_API,
  deleteCompanyLogoFailureApi,
  deleteCompanyLogoSuccessApi,
  FETCH_CUSTOMER_API,
  FETCH_CUSTOMER_EMAIL_API,
  FETCH_STRIPE_PORTAL_API,
  fetchCustomerFailureApi,
  fetchCustomerSuccessApi,
  fetchStripePortalFailureApi,
  fetchStripePortalSuccessApi,
  UPDATE_COMPANY_API,
  UPDATE_CUSTOMER_API,
  UPDATE_CUSTOMER_CONTACT_API,
  updateCompanyFailureApi,
  updateCompanySuccessApi,
  updateCustomerContactFailureApi,
  updateCustomerContactSuccessApi,
  updateCustomerFailureApi,
  updateCustomerSuccessApi,
  CREATE_STRIPE_CHECKOUT_API,
  SEND_EMAIL_API,
  sendEmailSuccessApi,
  sendEmailFailureApi,
  enableDisableSSOFailureApi,
  enableDisableSSOSuccessApi,
  ENABLE_DISABLE_SSO_API,
  fetchCustomerEmailSuccessApi,
  fetchCustomerEmailFailureApi,
  createStripeSignupCheckoutSuccessApi,
  createStripeSignupCheckoutFailureApi,
  CREATE_STRIPE_SIGNUP_CHECKOUT_API,
  transferOwnershipSuccessApi,
  transferOwnershipFailureApi,
  TRANSFER_OWNERSHIP_API,
} from './customer.action';

import { default as CustomerService } from './customer.service';
import {
  CustomerAction,
  CustomerState,
  StripePortalResponse,
  TransferOwnershipAction,
} from './customer.state';

import { SENTRY_CONTEXTS, updateSentryContext } from 'services';

function* fetchCustomerApiCall(): Generator<any> {
  try {
    const customerProfile = (yield call(CustomerService.getCustomer)) as CustomerState;
    updateSentryContext(SENTRY_CONTEXTS.CUSTOMER, {
      uuid: customerProfile.uuid,
      companyName: customerProfile.companyName,
    });

    yield put(fetchCustomerSuccessApi(customerProfile as CustomerState));
  } catch (error) {
    yield put(fetchCustomerFailureApi(error));
  }
}

function* fetchCustomerSaga(): Generator<any> {
  yield takeLatest(FETCH_CUSTOMER_API, fetchCustomerApiCall);
}

function* fetchCustomerEmailApiCall({ payload }: CustomerAction): Generator<any> {
  try {
    const emails = yield call(CustomerService.getCustomerEmail, payload.search);
    yield put(fetchCustomerEmailSuccessApi({ emails: emails as any, index: payload.index }));
  } catch (error) {
    yield put(fetchCustomerEmailFailureApi(error));
  }
}

function* fetchCustomerEmailSaga(): Generator<any> {
  yield takeLatest(FETCH_CUSTOMER_EMAIL_API, fetchCustomerEmailApiCall);
}

function* updateCustomerApiCall({ payload }: CustomerAction): Generator<any> {
  const companyLogo = payload?.get('companyLogo');
  const branding = typeof companyLogo === 'object' && companyLogo ? 'image' : 'color';
  try {
    const customerProfile = (yield call(
      CustomerService.updateCustomer,
      payload,
    ) as unknown) as CustomerState;
    const response = {
      customer: customerProfile,
      ...(!isNil(branding) && {
        response: { message: branding, success: true },
      }),
    };
    yield put(updateCustomerSuccessApi(response));
  } catch (error) {
    yield put(
      updateCustomerFailureApi({
        ...error,
        ...(!isNil(branding) && { message: [branding] }),
      }),
    );
  }
}

function* updateCustomerSaga(): Generator<any> {
  yield takeLatest(UPDATE_CUSTOMER_API, updateCustomerApiCall);
}

function* updateCompanyApiCall({ payload }: CustomerAction): Generator<any> {
  try {
    const { response, ...data } = payload;

    const customerProfile = (yield call(
      CustomerService.updateCompany,
      data,
    ) as unknown) as CustomerResponse;
    yield put(
      updateCompanySuccessApi({
        customer: customerProfile,
        ...(response && { response: { message: 'company success', success: true } }),
      }),
    );
  } catch (error) {
    yield put(updateCompanyFailureApi(error));
  }
}

function* updateCompanySaga(): Generator<any> {
  yield takeLatest(UPDATE_COMPANY_API, updateCompanyApiCall);
}

function* updateCustomerContactApiCall({ payload }: CustomerAction): Generator<any> {
  try {
    const customerProfile = (yield call(
      CustomerService.updateContact,
      payload,
    ) as unknown) as CustomerState;
    yield put(updateCustomerContactSuccessApi(customerProfile));
  } catch (error) {
    yield put(updateCustomerContactFailureApi({ ...error, message: ['plan_contact'] }));
  }
}

function* updateCustomerContactSaga(): Generator<any> {
  yield takeLatest(UPDATE_CUSTOMER_CONTACT_API, updateCustomerContactApiCall);
}

function* deleteCompanyLogoApiCall(): Generator<any> {
  try {
    yield call(CustomerService.deleteCompanyLogo);
    yield put(deleteCompanyLogoSuccessApi());
  } catch (error) {
    yield put(
      deleteCompanyLogoFailureApi({
        ...error,
        message: ['delete'],
      }),
    );
  }
}

function* deleteCompanyLogoSaga(): Generator<any> {
  yield takeLatest(DELETE_COMPANY_LOGO_API, deleteCompanyLogoApiCall);
}

function* sendEmailApiCall({ payload }: CustomerAction): Generator<any> {
  try {
    yield call(CustomerUserService.sendEmail, payload);
    yield put(sendEmailSuccessApi());
  } catch (error) {
    yield put(sendEmailFailureApi({ ...error, message: ['send_email'] }));
  }
}

function* sendEmailSaga(): Generator<any> {
  yield takeLatest(SEND_EMAIL_API, sendEmailApiCall);
}

function* fetchStripePortalApiCall(action: CustomerAction): Generator<any> {
  try {
    const response = (yield call(
      CustomerService.getStripePortal,
      action.payload,
    ) as unknown) as StripePortalResponse;
    if (response?.customerPortalUrl) {
      window.location.href = response.customerPortalUrl;
    }
    yield put(fetchStripePortalSuccessApi(response));
  } catch (error) {
    yield put(fetchStripePortalFailureApi(error));
  }
}

function* fetchStripePortalSaga(): Generator<any> {
  yield takeLatest(FETCH_STRIPE_PORTAL_API, fetchStripePortalApiCall);
}

function* createStripeCheckoutApiCall(): Generator<any> {
  try {
    const response = (yield call(CustomerService.createCheckoutSession) as unknown) as string;
    yield put(createStripeCheckoutSuccessApi(response));
  } catch (error) {
    const message = ['createCheckout'];
    yield put(createStripePaymentFailureApi({ ...error, message }));
  }
}

function* createStripeCheckoutSaga(): Generator<any> {
  yield takeLatest(CREATE_STRIPE_CHECKOUT_API, createStripeCheckoutApiCall);
}

function* createStripeSignupCheckoutApiCall(): Generator<any> {
  try {
    const response = (yield call(CustomerService.createSignupCheckoutSession) as unknown) as string;
    yield put(createStripeSignupCheckoutSuccessApi(response));
  } catch (error) {
    const message = ['createCheckout'];
    yield put(createStripeSignupCheckoutFailureApi({ ...error, message }));
  }
}

function* createStripeSignupCheckoutSaga(): Generator<any> {
  yield takeLatest(CREATE_STRIPE_SIGNUP_CHECKOUT_API, createStripeSignupCheckoutApiCall);
}

function* enabledDisableSSOApiCall({ payload }: EnableDisableSSOAction): Generator<any> {
  try {
    const response = (yield call(
      ProfileService.enableDisableSSO,
      payload,
    ) as unknown) as ProfileState;
    yield put(enableDisableSSOSuccessApi(response));
  } catch (error) {
    yield put(enableDisableSSOFailureApi(error));
  }
}

function* enabledDisableSSO(): Generator<any> {
  yield takeLatest(ENABLE_DISABLE_SSO_API, enabledDisableSSOApiCall);
}

function* transferOwnershipApiCall({ payload }: TransferOwnershipAction): Generator<any> {
  try {
    const response = (yield call(CustomerService.transferOwnership, payload) as unknown) as any;
    yield put(transferOwnershipSuccessApi(response));
    yield put(updateMembersOwnerSuccessApiV2(payload.userUuid));
  } catch (error) {
    yield put(transferOwnershipFailureApi(error));
  }
}

function* transferOwnership(): Generator<any> {
  yield takeLatest(TRANSFER_OWNERSHIP_API, transferOwnershipApiCall);
}

export {
  fetchCustomerApiCall,
  deleteCompanyLogoApiCall,
  updateCustomerApiCall,
  updateCompanyApiCall,
  updateCustomerContactApiCall,
  fetchStripePortalApiCall,
  createStripeCheckoutApiCall,
  createStripeSignupCheckoutApiCall,
  enabledDisableSSOApiCall,
  fetchCustomerEmailApiCall,
  transferOwnershipApiCall,
};
export const customerSaga = [
  fetchCustomerSaga(),
  updateCustomerSaga(),
  updateCompanySaga(),
  deleteCompanyLogoSaga(),
  sendEmailSaga(),
  updateCustomerContactSaga(),
  fetchStripePortalSaga(),
  createStripeCheckoutSaga(),
  createStripeSignupCheckoutSaga(),
  enabledDisableSSO(),
  fetchCustomerEmailSaga(),
  transferOwnership(),
];
