import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ChevronRight from '@material-ui/icons/ChevronRightOutlined';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import { useIntercom } from 'react-use-intercom';

import { RailzButton } from '@railzai/railz-uikit-react';

import Data from '../../../../data/data.json';
import {
  getCustomerSessionURL,
  getCustomerState,
  isCustomerSendEmailApiError,
  isCustomerSendEmailApiSuccess,
} from '../../../../store/features/account/customer/customer.selector';
import storeService from '../../../../store';
import { CustomerState } from '../../../../store/features/account/customer/customer.state';
import {
  fetchStripePortalApi,
  createStripeCheckoutApi,
} from '../../../../store/features/account/customer/customer.action';
import SubscriptionSuccess from '../subscription-success/subscription-success';
import TermsConditionsModal from '../../../../components/terms-conditions-modal';
import useQuerySearch from '../../../../hooks/use-query-search';
import RailzChip from '../../../../components/chip';

import useStyle from './style';

import { EventService } from 'services';
import { View } from 'components';
import { Plan, Role } from 'types';
import ContactSalesModal from 'components/contact-sales-modal';
import { getProfileState, getUserRole } from 'store/features/account/profile/profile.selector';

export default function Plans(): JSX.Element {
  const classes = useStyle();
  const [contactSalesOpenModal, setContactSalesOpenModal] = useState(false);
  const [termsOpenModal, setTermsOpenModal] = useState(false);
  const [subscriptionSuccessOpen, setSubscriptionSuccessOpen] = useState(false);
  const { t } = useTranslation();
  const store = storeService.getStore();
  const dispatch = useDispatch();

  const customerState = useSelector<ReturnType<typeof store.getState>, CustomerState>(
    getCustomerState,
  );
  const role = useSelector(getUserRole);
  const user = useSelector(getProfileState);

  const sessionURL = useSelector<ReturnType<typeof store.getState>, string>(getCustomerSessionURL);
  const sendEmailSuccess = useSelector<ReturnType<typeof store.getState>, boolean>(
    isCustomerSendEmailApiSuccess,
  );
  const sendEmailError = useSelector<ReturnType<typeof store.getState>, boolean>(
    isCustomerSendEmailApiError,
  );
  const history = useHistory();
  const querySearch = useQuerySearch();
  const { show } = useIntercom();

  const getCtaLabel = (itm, isCurrentPlan): string => {
    if (customerState?.billingPlan === Plan.CUSTOM && customerState?.billingPlan === itm.code) {
      return '';
    } else if (customerState?.billingPlan === Plan.FREE) {
      return t(itm.buttonNoPlan);
    }
    return t(isCurrentPlan ? itm.buttonCurrentPlan : itm.buttonOtherPlan);
  };

  const onClickCTA = async (item): Promise<void> => {
    if (item.code === Plan.CUSTOM && customerState?.billingPlan !== Plan.CUSTOM) {
      setContactSalesOpenModal(true);
      return;
    }
    if (customerState?.billingPlan === item.code) {
      dispatch(
        fetchStripePortalApi({
          railzRedirectUrl: window.location.href,
        }),
      );
    } else {
      if (item.code === Plan.PAY_AS_YOU_GO && customerState?.billingPlan !== Plan.CUSTOM) {
        setTermsOpenModal(true);
      } else {
        show();
      }
    }
  };

  const closeTermsModal = async (accepted: boolean): Promise<void> => {
    if (accepted) {
      dispatch(createStripeCheckoutApi());
    } else {
      setTermsOpenModal(false);
    }
  };

  useEffect(() => {
    if (sessionURL) {
      document.location.href = sessionURL;
      setTermsOpenModal(false);
    }
  }, [sessionURL]);

  useEffect(() => {
    if (
      querySearch &&
      querySearch.get('success') === 'true' &&
      customerState?.billingPlan === Plan.PAY_AS_YOU_GO
    ) {
      setSubscriptionSuccessOpen(true);
      querySearch.delete('success');
      history.replace({ search: querySearch.toString() });
    }
  }, [querySearch, customerState]);

  useEffect(() => {
    if (sendEmailSuccess) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: t('DASHBOARD_BILLING_CONTACT_SALES_MODAL_SUCCESS'),
      });
    }
    if (sendEmailError) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: t('DASHBOARD_BILLING_CONTACT_SALES_MODAL_ERROR'),
      });
    }
  }, [sendEmailSuccess, sendEmailError]);

  useEffect(() => {
    if (customerState?.error?.message?.length) {
      if (customerState?.error?.message?.includes('createCheckout')) {
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'error',
          message: t('DASHBOARD_SIGNUP_GENERIC_ERROR'),
        });
        setTermsOpenModal(false);
      }
    }
    // eslint-disable-next-line
  }, [customerState?.error]);

  return (
    <>
      <TermsConditionsModal
        open={termsOpenModal}
        close={closeTermsModal}
        loading={customerState?.loading}
      />
      <ContactSalesModal
        open={contactSalesOpenModal}
        close={(): void => setContactSalesOpenModal(false)}
        loading={customerState?.loading}
      />
      <SubscriptionSuccess
        open={subscriptionSuccessOpen}
        onClose={(): void => setSubscriptionSuccessOpen(false)}
      />
      {Data.map((item, index) => {
        const isCurrentPlan = user?.billingPlan === item.code;
        const label = getCtaLabel(item, isCurrentPlan);
        return (
          <Grid
            item
            xs={12}
            sm={4}
            key={index}
            tabIndex={0}
            role="group"
            aria-labelledby={`plan-title-${item.tag}`}
            aria-describedby={`plan-description-${item.tag}`}
          >
            <Card
              className={classNames(
                classes.card,
                classes[user?.billingPlan === item.code ? 'active' : ''],
              )}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.cardPillSection}
              >
                <Typography variant="h3" className={classes.cardTitle}>
                  {t(item.tag)?.toUpperCase()}
                </Typography>
                {user?.billingPlan === item.code && (
                  <RailzChip chipText="DASHBOARD_BILLING_CURRENT_PLAN" testId="active-billing" />
                )}
              </Grid>
              <Typography
                id={`plan-title-${item.tag}`}
                variant="h4"
                className={classes.cardSubTitle}
              >
                {t(item.type)}
              </Typography>
              <Typography id={`plan-description-${item.tag}`} variant="body2">
                <Trans i18nKey={item.description} />
              </Typography>
              {(role === Role.SUPER_ADMINISTRATOR || role === Role.BILLING) && label ? (
                <RailzButton
                  className={classes.cardButton}
                  onButtonClick={(): Promise<void> => onClickCTA(item)}
                  type={isCurrentPlan ? 'primary' : 'secondary'}
                  label={t(label)}
                  data-testid={`${item.code}-btn`}
                >
                  <span slot="suffix">
                    <ChevronRight />
                  </span>
                </RailzButton>
              ) : null}
            </Card>
            <View className={classes.featureContainer}>
              <Typography variant="h5" className={classes.featureHeader}>
                {t('DASHBOARD_BILLING_PLAN_FEATURES_HEADER')}
              </Typography>
              <ul className={classes.featureListContainer}>
                {item.features.map((feature, index) => (
                  <li key={index} className={classes.featureText}>
                    <DoneIcon color="primary" className={classes.checkmark} />
                    {t(feature)}
                  </li>
                ))}
                {item.excludedFeatures.map((service, index) => (
                  <li key={index} className={classes.featureText}>
                    <CloseIcon color="error" className={classes.checkmark} />
                    {t(service)}
                  </li>
                ))}
              </ul>
            </View>
          </Grid>
        );
      })}
    </>
  );
}
