import React, { useState, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ButtonPreview from '../button-preview';

import useStyle from './style';
interface Props {
  url?: string;
  brandColor: string;
}

const SaveUrlPreview: React.FC<Props> = ({ url, brandColor }: Props) => {
  const classes = useStyle();
  const [urlValue, setUrlValue] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    setUrlValue(url);
  }, [url]);

  return (
    <div className={classes.visible}>
      <div>
        <Typography className={classes.title} variant="h4">
          {t('DASHBOARD_SITES_ONBOARDING_SAVE_URL_HEADER')}
        </Typography>
        <Typography className={classes.description}>
          {t('DASHBOARD_SITES_ONBOARDING_SAVE_URL_BODY')}
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.cardPillSection}
        >
          <Typography className={classes.inputTitle}>
            {t('DASHBOARD_GENERATE_LINK_LINK')}
          </Typography>
        </Grid>
        <div className={classes.inputBox}>
          <div className={classes.inputText}>
            <span>{urlValue}</span>
          </div>
          <FileCopyIcon className={classes.inputIcon} />
        </div>
      </div>
      <ButtonPreview brandColor={brandColor} className={classes.cta} />
    </div>
  );
};

export default SaveUrlPreview;
