import React from 'react';
import { useTranslation } from 'react-i18next';

import Search, { SearchProps } from '../../../../components/form/search';

export default function SearchBusiness({
  handleSearch,
  value,
  className,
}: SearchProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Search
      value={value}
      className={className}
      handleSearch={(e): void => handleSearch(e.target.value)}
      inputProps={{
        'aria-label': t('DASHBOARD_BUSINESS_SEARCH_NAME'),
      }}
      data-testid="search-input"
      placeholder={t('DASHBOARD_BUSINESS_SEARCH_NAME')}
    />
  );
}
