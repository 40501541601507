import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';

import { FormInputProps } from 'types';

const useStyles = makeStyles({
  inputRoot: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    paddingLeft: '0 !important',
  },
  input: {
    padding: '12px 16px !important',
    minHeight: '40px',
    boxSizing: 'border-box',
  },
  option: {
    backgroundColor: 'white',
    '&[aria-selected="true"]:not(.MuiAutocomplete-option[data-focus="true"])': {
      backgroundColor: 'transparent',
    },
  },
  errorText: {
    marginRight: 14,
    marginLeft: 14,
  },
});

const CountrySelect = React.forwardRef(
  (props: any, ref: React.RefObject<HTMLElement>): React.ReactElement => {
    const {
      errorobj,
      name,
      getOptionLabel,
      placeholder,
      label,
      testid,
      options,
      getOptionSelected,
    } = props;
    const { ...otherProps } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    let errorMessage = '';

    if (errorobj && errorobj[name]) {
      errorMessage = errorobj[name].message;
    }

    return (
      <Autocomplete
        {...otherProps}
        options={props.options ? props.options : []}
        classes={{ inputRoot: classes.inputRoot, input: classes.input, option: classes.option }}
        ref={ref}
        defaultValue={options && options.length > 0 && options[0]}
        data-testid={`${testid}-autocomplete`}
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option: any): string => option.label || ''
        }
        getOptionSelected={
          getOptionSelected
            ? getOptionSelected
            : (option, value): boolean => (option as any).label === (value as any).label
        }
        renderInput={(params): React.ReactElement => (
          <>
            <TextField
              {...params}
              placeholder={t(placeholder)}
              label={t(label)}
              variant="outlined"
              id={`${testid}-textfield`}
              inputProps={{
                ...params.inputProps,
                'data-testid': testid,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              error={!!errorMessage}
            />
            {errorMessage && (
              <FormHelperText className={classes.errorText} error={!!errorMessage}>
                {t(errorMessage)}
              </FormHelperText>
            )}
          </>
        )}
        onChange={(_, data): void => props.onChange(data)}
      />
    );
  },
);

function FormSelectAutoComplete(parentProps: FormInputProps): React.ReactElement {
  return (
    <Controller
      render={({ onChange, ...props }): JSX.Element => (
        <CountrySelect onChange={onChange} {...props} {...parentProps} />
      )}
      defaultValue=""
      {...parentProps}
    />
  );
}

export default FormSelectAutoComplete;
