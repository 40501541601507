import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        padding: 0,
      },
    },
    qrContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      background: '#fff',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        marginBottom: theme.spacing(2),
      },
    },
    qr: {
      border: '1px solid',
      borderColor: theme.palette.grey[200],
      padding: theme.spacing(1),
      borderRadius: '4px',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
    title: {
      padding: 0,
      '& h2': {
        textAlign: 'left',
      },
    },
    description: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '85%',
      },
    },
    formContainer: {
      [theme.breakpoints.between('xs', 'sm')]: {
        flexDirection: 'column',
        marginTop: theme.spacing(2),
      },
    },
    input: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('lg')]: {
        maxWidth: '85%',
      },
    },
    actions: {
      padding: 0,
      marginTop: theme.spacing(1),
    },
    submitButton: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        '& > *': {
          flexGrow: 1,
        },
      },
    },
    authCode: {
      backgroundColor: theme.palette.grey[200],
    },
  });
});
