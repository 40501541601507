import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    tooltipContainer: {
      position: 'relative',
      zIndex: 999999,
      height: theme.spacing(2),
    },

    popper: {
      zIndex: 999999,
      '& .MuiTooltip-tooltip': {
        fontFeatureSettings: `'clig' off, 'liga' off`,
        padding: theme.spacing(2),
      },
      '& .MuiTooltip-tooltip, & .MuiTooltip-arrow::before': {
        background: `var(--primary-dark-green, #003032)`,
        fontSize: '0.875rem',
      },
      // left placement arrow
      '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementLeft .MuiTooltip-arrow': {
        right: '0px',
        marginRight: '-0.71em',
        height: '1em',
        width: '0.71em',
      },
      '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementLeft .MuiTooltip-arrow::before': {
        marginLeft: '-0.41em',
      },

      // bottom placement arrow
      '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom .MuiTooltip-arrow': {
        top: '0px',
        marginTop: '-0.71em',
        width: '1em',
        height: '0.71em',
      },
      '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom .MuiTooltip-arrow::before': {
        marginTop: '0.49em',
      },

      // top placement arrow
      '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop .MuiTooltip-arrow': {
        bottom: '0px',
        marginBottom: '-0.71em',
        width: '1em',
        height: '0.71em',
      },
      '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop .MuiTooltip-arrow::before': {
        marginTop: '-0.49em',
      },
    },

    tooltipIcon: {
      margin: 0,
      fontSize: '1rem',
      color: 'var(--greyscale-grey-800, #424242)',
      transition: 'all 0.2s ease-in-out',
      pointerEvents: 'auto',
      transformOrigin: 'top right',
      willChange: 'transform, opacity',
      opacity: 0.5,
      transform: 'scale(.7)',
      '&:hover, &:focus, &[data-opened="true"], &.bigIcon': {
        opacity: 1,
        transform: 'scale(1)',
      },
    },
  }),
);
