import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';

import { Webhook } from '../../../types/interface';

import { Kind } from 'components/table-actions/table-actions';

interface Props {
  webhook: Webhook;
  onUpdateWebhook: (Webhook) => void;
  setWebhook: (Webhook) => void;
  setOpenAlert: (boolean) => void;
  formattedWebhookKind: (event: string[], requestTypes: Webhook['requestTypes']) => string;
}

const WebhookRowMenu: React.FC<Props> = ({
  webhook,
  onUpdateWebhook,
  setWebhook,
  setOpenAlert,
  formattedWebhookKind,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const overflowOptions = [
    {
      id: Kind.EDIT,
      icon: <EditIcon />,
      label: t('DASHBOARD_TEAM_EDIT_CTA'),
      ariaLabel: t('DASHBOARD_DEVELOPERS_WEBHOOK_EDIT_ACTION_ARIA', {
        kind: formattedWebhookKind(webhook.event, webhook.requestTypes),
      }),
      action: (webhook: Webhook): void => {
        onUpdateWebhook(webhook);
      },
      testid: `edit-cta-option-${webhook?.uuid}`,
    },
    {
      id: Kind.DELETE,
      icon: <DeleteOutlinedIcon />,
      label: t('DASHBOARD_TEAM_DELETE_CTA'),
      color: theme.palette.error.main,

      ariaLabel: t('DASHBOARD_DEVELOPERS_WEBHOOK_DELETE_ACTION_ARIA', {
        kind: formattedWebhookKind(webhook.event, webhook.requestTypes),
      }),
      action: (webhook: Webhook): void => {
        setWebhook(webhook);
        setOpenAlert(true);
      },
      testid: `delete-cta-option-${webhook?.uuid}`,
    },
  ];

  return (
    <>
      <IconButton
        aria-owns={anchorEl ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={(event): void => setAnchorEl(event.currentTarget)}
        data-testid={`overflow-menu-button-${webhook?.uuid}`}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(): void => setAnchorEl(null)}
      >
        {overflowOptions.map((option) => {
          return (
            <MenuItem
              key={option.id}
              aria-label={option?.ariaLabel}
              onClick={(): void => {
                setAnchorEl(null);
                option.action && option.action(webhook);
              }}
              data-testid={option.testid}
              style={{ color: option?.color }}
            >
              {option.icon}
              <span style={{ marginLeft: '12px' }}>{option.label}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default WebhookRowMenu;
