import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';

import { HttpStatusCode } from 'axios';

import { BankAccount } from '../../types/interface/report-summary.interface';

import Loading from '../loading';
import ErrorChartComponent from '../error/error-chart';
import { HttpStatus, ReportType } from '../../types';

import { formatNumber } from '../../helpers/format.helper';

import useStyle from './style';

import reportService from 'store/features/report/report.service';
import { generateDropdownFromArray } from 'helpers/common.helper';

interface Props {
  title: string;
  testId?: string;
  allBankConnections?: { connectionId: string; institutionName: string }[];
}

const fetchBankAccountApiCall = async (
  connectionId: string,
): Promise<{ data: any; status: HttpStatus | HttpStatusCode }> => {
  const params = { connectionUuid: connectionId, orderBy: 'asc', offset: '0' };
  try {
    const response = await reportService.getReport({ type: ReportType.BANK_ACCOUNTS, params });
    if (!response || !Array.isArray(response.data)) return { data: {}, status: HttpStatus.NO_DATA };

    /*
    mostly re used the logic from visualizations.
    It takes the response and filters it for each unique institution name per connectionId
    and makes the data object
    which contains the institution name (used for the dropdown label) as the key
    the value is everything in the response data like the accountName and current balance,
    which is used in the display
    */
    const institutionNames: string = response.data?.map(({ institutionName }) => institutionName);
    const uniqueBankAccounts = new Set(institutionNames);
    const diffBanks = {};
    uniqueBankAccounts.forEach((institutionName) => {
      diffBanks[institutionName] = response.data?.filter(
        ({ institutionName: internalInstitutionName }) =>
          internalInstitutionName === institutionName,
      );
    });
    return { data: diffBanks, status: HttpStatus.OK };
  } catch (e: unknown) {
    console.error('getBankAccount', e);
    return { data: {}, status: HttpStatusCode.InternalServerError };
  }
};

const AccountsTable = ({ title, testId, allBankConnections }: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyle();
  const ref = React.useRef();
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState<BankAccount>({});
  const [selectedConnectionId, setSelectedConnectionId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(200);

  const fetchBankAccount = async (connectionId): Promise<void> => {
    setIsLoading(true);
    if (!connectionId) {
      setIsLoading(false);
      setStatusCode(HttpStatus.NO_DATA);
      return;
    }
    setSelectedConnectionId(connectionId);
    const { data, status } = await fetchBankAccountApiCall(connectionId);
    data && setSelectedAccount(data);
    setStatusCode(status);
    setIsLoading(false);
  };

  useEffect(() => {
    if (allBankConnections?.length > 0) {
      const options = generateDropdownFromArray(
        allBankConnections,
        'connectionId',
        'institutionName',
      );
      setAccountOptions(options);
      fetchBankAccount(options[0]?.value);
    } else {
      setStatusCode(HttpStatusCode.NoContent);
    }
  }, []);

  const onChange = (event): void => {
    fetchBankAccount(event.target.value);
  };

  return (
    <Box className={classes.container} data-testid={`${testId}-container`}>
      <Grid container justifyContent={'flex-start'}>
        <Grid>
          <Typography variant={'h4'} className={classes.title} data-testid={`${testId}-title`}>
            {t(title)}
          </Typography>
        </Grid>
      </Grid>
      {isLoading && (
        <Loading
          loading={isLoading}
          testId={testId}
          isBackdrop={false}
          loadingStyle={{ width: '32px', height: '32px' }}
          loadingText={'DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_LOADING_TEXT'}
        />
      )}
      {!isLoading && <ErrorChartComponent statusCode={statusCode} testId={testId} />}
      {!isLoading && statusCode === HttpStatus.OK && accountOptions.length && (
        <List className={classes.list} subheader={<li />} data-testid={`${testId}-list`}>
          <TextField
            id={`${testId}-select`}
            select
            disabled={accountOptions.length < 2}
            margin="none"
            variant="outlined"
            ref={ref}
            defaultValue={selectedConnectionId || ''}
            onChange={onChange}
            size="small"
            inputProps={{ id: `${testId}-select`, 'data-testid': `${testId}-select` }}
            SelectProps={{
              IconComponent: accountOptions.length > 1 ? undefined : (): null => null,
              MenuProps: {
                anchorEl: () => ref.current,
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                PopoverClasses: {
                  paper: 'menu',
                },
              },
            }}
          >
            {accountOptions.map((item) => (
              <MenuItem key={item.value} value={item?.value} className="menuItem">
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          {Object.keys(selectedAccount).map((institutionName) => (
            <li key={institutionName}>
              <ul className={classes.sectionUl}>
                {selectedAccount[institutionName].map((account, index) => (
                  <ListItem
                    key={`${account.accountId}-${index}`}
                    ContainerComponent="div"
                    className={classes.item}
                  >
                    <ListItemText
                      secondary={
                        <Grid component="span" container className={classes.itemGrid}>
                          <Grid component="span">
                            <Typography component="span" className={classes.itemPrimary}>
                              {startCase(account.accountName)}
                            </Typography>
                          </Grid>
                          <Grid className={classes.itemDashed} component="span"></Grid>
                          <Grid component="span" className={classes.itemSecondaryGrid}>
                            <Typography component="span" className={classes.itemSecondary}>
                              ${formatNumber(account.currentBalance)}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                ))}
              </ul>
            </li>
          ))}
        </List>
      )}
    </Box>
  );
};

export default AccountsTable;
