import { ErrorResponse } from '../../../types/interface/error.interface';

import { SyncDataState } from './integration.state';

import type {
  AspType,
  Integration,
  IntegrationCreationData,
  IntegrationStatus,
  IntegrationUpdateData,
} from 'types';

export const FETCH_INTEGRATIONS_API = 'FETCH_INTEGRATIONS_API';
export const FETCH_INTEGRATIONS_SUCCESS_API = 'FETCH_INTEGRATIONS_SUCCESS_API';
export const FETCH_INTEGRATIONS_FAILURE_API = 'FETCH_INTEGRATIONS_FAILURE_API';

export const CREATE_INTEGRATION_API = 'CREATE_INTEGRATION_API';
export const CREATE_INTEGRATION_SUCCESS_API = 'CREATE_INTEGRATION_SUCCESS_API';
export const CREATE_INTEGRATION_FAILURE_API = 'CREATE_INTEGRATION_FAILURE_API';

export const UPDATE_INTEGRATION_API = 'UPDATE_INTEGRATION_API';
export const UPDATE_INTEGRATION_SUCCESS_API = 'UPDATE_INTEGRATION_SUCCESS_API';
export const UPDATE_INTEGRATION_FAILURE_API = 'UPDATE_INTEGRATION_FAILURE_API';

export const UPDATE_INTEGRATIONS_DEFAULT_API = 'UPDATE_INTEGRATIONS_DEFAULT_API';
export const UPDATE_INTEGRATIONS_DEFAULT_SUCCESS_API = 'UPDATE_INTEGRATIONS_DEFAULT_SUCCESS_API';
export const UPDATE_INTEGRATIONS_DEFAULT_FAILURE_API = 'UPDATE_INTEGRATIONS_DEFAULT_FAILURE_API';

export const UPDATE_INTEGRATIONS_STATE_API = 'UPDATE_INTEGRATIONS_STATE_API';
export const UPDATE_INTEGRATIONS_STATE_SUCCESS_API = 'UPDATE_INTEGRATIONS_STATE_SUCCESS_API';
export const UPDATE_INTEGRATIONS_STATE_FAILURE_API = 'UPDATE_INTEGRATIONS_STATE_FAILURE_API';

export const DELETE_INTEGRATION_API = 'DELETE_INTEGRATION_API';
export const DELETE_INTEGRATION_SUCCESS_API = 'DELETE_INTEGRATION_SUCCESS_API';
export const DELETE_INTEGRATION_FAILURE_API = 'DELETE_INTEGRATION_FAILURE_API';

export const RESET_INTEGRATIONS_INFORMATION = 'RESET_INTEGRATIONS_INFORMATION';

export const FETCH_SYNC_CONFIGS_API = 'FETCH_SYNC_CONFIGS_API';
export const FETCH_SYNC_CONFIGS_SUCCESS_API = 'FETCH_SYNC_CONFIGS_SUCCESS_API';
export const FETCH_SYNC_CONFIGS_FAILURE_API = 'FETCH_SYNC_CONFIGS_FAILURE_API';

export const UPDATE_SYNC_CONFIGS_API = 'UPDATE_SYNC_CONFIGS_API';
export const UPDATE_SYNC_CONFIGS_SUCCESS_API = 'UPDATE_SYNC_CONFIGS_SUCCESS_API';
export const UPDATE_SYNC_CONFIGS_FAILURE_API = 'UPDATE_SYNC_CONFIGS_FAILURE_API';
export const RESET_SYNC_CONFIGS_API_RESPONSE = 'RESET_SYNC_CONFIGS_API_RESPONSE';

export const RESET_STORAGE_INTEGRATION = 'RESET_STORAGE_INTEGRATION';

export const resetIntegrationStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_INTEGRATION,
  };
};

export const resetIntegrationsInformation = (): { type: string } => {
  return {
    type: RESET_INTEGRATIONS_INFORMATION,
  };
};

export const fetchIntegrationsApi = (): { type: string } => {
  return {
    type: FETCH_INTEGRATIONS_API,
  };
};

export const fetchIntegrationsSuccessApi = (
  payload: Integration[],
): { type: string; payload: Integration[] } => {
  return {
    type: FETCH_INTEGRATIONS_SUCCESS_API,
    payload,
  };
};

export const fetchIntegrationsFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_INTEGRATIONS_FAILURE_API,
    payload,
  };
};

export const fetchSyncConfigsApi = (): { type: string } => {
  return {
    type: FETCH_SYNC_CONFIGS_API,
  };
};

export const fetchSyncConfigsSuccessApi = (
  payload: SyncDataState,
): { type: string; payload: SyncDataState } => {
  return {
    type: FETCH_SYNC_CONFIGS_SUCCESS_API,
    payload,
  };
};

export const fetchSyncConfigsFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_SYNC_CONFIGS_FAILURE_API,
    payload,
  };
};

export const updateSyncConfigsApi = (
  payload: SyncDataState,
): { type: string; payload: SyncDataState } => {
  return {
    type: UPDATE_SYNC_CONFIGS_API,
    payload,
  };
};

export const updateSyncConfigsSuccessApi = (
  payload: SyncDataState,
): { type: string; payload: SyncDataState } => {
  return {
    type: UPDATE_SYNC_CONFIGS_SUCCESS_API,
    payload,
  };
};

export const updateSyncConfigsFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_SYNC_CONFIGS_FAILURE_API,
    payload,
  };
};

export const resetSyncConfigsApiResponse = (): { type: string } => ({
  type: RESET_SYNC_CONFIGS_API_RESPONSE,
});

export const createIntegrationApi = (
  payload: IntegrationCreationData,
): { type: string; payload: IntegrationCreationData } => {
  return {
    type: CREATE_INTEGRATION_API,
    payload,
  };
};

export const createIntegrationSuccessApi = (
  payload: Integration,
): { type: string; payload: Integration } => {
  return {
    type: CREATE_INTEGRATION_SUCCESS_API,
    payload,
  };
};

export const createIntegrationFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: CREATE_INTEGRATION_FAILURE_API,
    payload,
  };
};

export const updateIntegrationApi = (
  payload: IntegrationUpdateData,
): { type: string; payload: IntegrationUpdateData } => {
  return {
    type: UPDATE_INTEGRATION_API,
    payload,
  };
};

export const updateIntegrationSuccessApi = (
  payload: Integration,
): { type: string; payload: Integration } => {
  return {
    type: UPDATE_INTEGRATION_SUCCESS_API,
    payload,
  };
};

export const updateIntegrationFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_INTEGRATION_FAILURE_API,
    payload,
  };
};

export const updateIntegrationsDefaultApi = (
  payload: Integration,
): { type: string; payload: Integration } => {
  return {
    type: UPDATE_INTEGRATIONS_DEFAULT_API,
    payload,
  };
};

export const updateIntegrationsDefaultSuccessApi = (
  payload: Integration,
): { type: string; payload: Integration } => {
  return {
    type: UPDATE_INTEGRATIONS_DEFAULT_SUCCESS_API,
    payload,
  };
};

export const updateIntegrationsDefaultFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_INTEGRATIONS_DEFAULT_FAILURE_API,
    payload,
  };
};

export const updateIntegrationsStateApi = (payload: {
  state: IntegrationStatus;
  service: AspType;
}): {
  type: string;
  payload: {
    state: IntegrationStatus;
    service: AspType;
  };
} => {
  return {
    type: UPDATE_INTEGRATIONS_STATE_API,
    payload,
  };
};

export const updateIntegrationsStateSuccessApi = (payload: {
  state: IntegrationStatus;
  service: AspType;
  uuid?: string;
  name?: string;
}): {
  type: string;
  payload: {
    state: IntegrationStatus;
    service: AspType;
    uuid?: string;
    name?: string;
  };
} => {
  return {
    type: UPDATE_INTEGRATIONS_STATE_SUCCESS_API,
    payload,
  };
};

export const updateIntegrationsStateFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: UPDATE_INTEGRATIONS_STATE_FAILURE_API,
    payload,
  };
};

export const deleteIntegrationApi = (
  payload: Integration,
): { type: string; payload: Integration } => {
  return {
    type: DELETE_INTEGRATION_API,
    payload,
  };
};

export const deleteIntegrationSuccessApi = (
  payload: Pick<Integration, 'uuid' | 'service' | 'deletedAt'>,
): { type: string; payload: Pick<Integration, 'uuid' | 'service' | 'deletedAt'> } => {
  return {
    type: DELETE_INTEGRATION_SUCCESS_API,
    payload,
  };
};

export const deleteIntegrationFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: DELETE_INTEGRATION_FAILURE_API,
    payload,
  };
};
