import React, { useState, useEffect } from 'react';

import { contrastColor } from 'contrast-color';

import useStyle from './style';

interface Props {
  brandColor?: string;
  className?: any;
}

const ButtonPreview: React.FC<Props> = ({ brandColor, className }: Props) => {
  const classes = useStyle();
  const [color, setColor] = useState<string>('#D8D8D8');

  useEffect(() => {
    setColor(brandColor ? contrastColor({ bgColor: `#${brandColor}` }) : '#D8D8D8');
  }, [brandColor]);

  return (
    <div
      className={[classes.button, className].join(' ')}
      style={{ backgroundColor: `#${brandColor}`, color }}
    >
      <span>Next</span>
    </div>
  );
};

export default ButtonPreview;
