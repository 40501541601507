import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import { RailzButton } from '@railzai/railz-uikit-react';

import { Connection } from '../../types/interfaces';
import { ActionTypes, ConnectionStatus } from '../../types/enums';

import BusinessDisconnect from './business-disconnect/business-disconnect';
import useStyle from './style';

import { getConnectionServiceName } from 'helpers/common.helper';
import { useBusinessContext } from 'pages/business/provider/business-event';
import AlertDialog from 'components/dialog';

const BusinessManageServices: React.FC<{
  updateBusiness: () => void;
}> = ({ updateBusiness }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [selectedConnection, setSelectedConnection] = useState<Connection>();

  const {
    event,
    setEvent,
    contextBusiness,
    openDisconnectBusinessModal,
    setOpenDisconnectBusinessModal,
  } = useBusinessContext();

  const headerTable = [
    {
      name: t('DASHBOARD_MANAGE_SERVICES_SERVICE'),
    },
    {
      name: t('DASHBOARD_MANAGE_SERVICES_STATUS'),
    },
    {
      name: t('DASHBOARD_MANAGE_SERVICES_ACTION'),
    },
  ];

  const connectionsMapped = contextBusiness?.connections
    ?.filter((connection) => connection.status !== ConnectionStatus.PENDING)
    .sort((serviceA, serviceB) => {
      const textA = serviceA.status.toUpperCase();
      const textB = serviceB.status.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .map((connection) => ({
      id: connection.connectionId,
      name: getConnectionServiceName(connection),
      status: (
        <Chip
          className={
            connection.status === ConnectionStatus.ACTIVE
              ? classes.pillConnected
              : classes.pillDisconnected
          }
          icon={connection.status === ConnectionStatus.ACTIVE && <CheckIcon />}
          label={
            connection.status === ConnectionStatus.ACTIVE
              ? t('DASHBOARD_MANAGE_SERVICES_CONNECTED')
              : t('DASHBOARD_MANAGE_SERVICES_DISCONNECTED')
          }
          size="small"
        />
      ),
      action: connection.status === ConnectionStatus.ACTIVE && (
        <RailzButton
          data-testid={`disconnect-button-${connection.connectionId}`}
          type="text warning"
          onButtonClick={(): void => {
            setSelectedConnection(connection);
            setOpenDisconnectBusinessModal(true);
          }}
          label={t('DASHBOARD_MANAGE_SERVICES_DISCONNECT')}
        />
      ),
    }));

  const handleUpdateBusiness = (): void => {
    updateBusiness();
    setEvent(null);
  };

  return (
    <>
      <AlertDialog
        testId="business-manage-services"
        showCloseButton
        isOpen={
          !!contextBusiness && !openDisconnectBusinessModal && event === ActionTypes.MANAGE_SERVICES
        }
        title={t('DASHBOARD_MANAGE_SERVICES_HEADER')}
        onClose={(): void => {
          setEvent(null);
        }}
        maxWidth="sm"
        classDialog={classes.dialog}
      >
        <>
          <Table data-testid="business-manage-services-table">
            <TableHead>
              <TableRow>
                {headerTable.map(({ name }) => (
                  <TableCell key={name}>{name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {connectionsMapped?.map(({ id, name, status, action }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Typography className={classes.nameRowLabel}>{name}</Typography>
                    <Typography className={classes.uuidRowLabel}>{id}</Typography>
                  </TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{action}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </AlertDialog>
      <BusinessDisconnect
        connection={{ ...selectedConnection, businessName: contextBusiness?.businessName }}
        updateBusiness={handleUpdateBusiness}
      />
    </>
  );
};

export default BusinessManageServices;
