import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';

import { useBusinessContext } from '../../provider/business-event';
import { HttpStatusError, SPECIAL_CHARS } from '../../../../types';
import { ActionTypes } from '../../types/enums';

import {
  getBusinesses,
  getErrorMessage,
  getErrorStatus,
  getResponse,
} from '../../../../store/features/business/business.selector';
import { Business } from '../../types/interfaces';

import {
  createBusinessesApi,
  renameBusinessApi,
  resetBusinessError,
  resetBusinessResponse,
} from '../../../../store/features/business/business.action';

import useStyles from './styles';

import { showSnackbar } from 'helpers/common.helper';
import AlertDialog from 'components/dialog';
import storeService from 'store';
import ClipboardInput from 'pages/business/input-clipboard';

interface Props {
  open: boolean;
  close: (reload?: boolean) => void;
}

export default function RenameBusiness({ open, close }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const businesses = useSelector<ReturnType<typeof store.getState>, Business[]>(getBusinesses);
  const createResponse = useSelector(getResponse);
  const errorResponseMessage = useSelector<ReturnType<typeof store.getState>, string[]>(
    getErrorMessage,
  );
  const errorStatus = useSelector<ReturnType<typeof store.getState>, number>(getErrorStatus);

  const { event, contextBusiness } = useBusinessContext();

  const [newBusinessName, setNewBusinessName] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [copied, setCopied] = useState<boolean>();
  const [createdBusiness, setCreatedBusiness] = useState<boolean>();
  const [generatedLink, setGeneratedLink] = useState<string>();

  const createdBusinessRef = useRef(null);
  const newBusinessNameRef = useRef(null);

  const handleSubmit = (): void => {
    const isValid = validateBusinessName();
    if (isValid) {
      if (event === ActionTypes.RENAME) {
        handleRenameSubmit();
        return;
      }
      handleCreateSubmit();
    }
  };

  useEffect(() => {
    if (contextBusiness?.businessName) {
      if (SPECIAL_CHARS.some((char) => contextBusiness?.businessName.includes(char))) {
        setErrorMessage(t('DASHBOARD_BUSINESS_CRUD_FAIL_SPECIAL_CHARS'));
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextBusiness?.businessName]);

  useEffect(() => {
    if (createResponse && createResponse.message) {
      dispatch(resetBusinessResponse());
      if (createResponse.message === 'createBusiness') {
        setCreatedBusiness(true);
        createdBusinessRef?.current?.focus();
        setGeneratedLink(createResponse?.url);
      } else if (createResponse.message === 'renameBusiness') {
        showSnackbar({
          message: (
            <div>
              <Trans
                i18nKey="DASHBOARD_BUSINESS_RENAME_SUCCESS"
                shouldUnescape={true}
                tOptions={{ interpolation: { escapeValue: true } }}
                values={{ newBusinessName }}
                components={{ bold: <strong /> }}
              />
            </div>
          ),
          type: 'success',
        });
        close(true);
      }
    }
  }, [createResponse]);

  useEffect(() => {
    if (errorResponseMessage && errorStatus) {
      dispatch(resetBusinessError());
      newBusinessNameRef?.current?.focus();
      if (errorResponseMessage.includes('createBusiness')) {
        if (errorStatus && errorStatus === HttpStatusError.CONFLICT) {
          setErrorMessage(t('DASHBOARD_BUSINESS_CRUD_FAIL_ALREADY_EXISTS'));
          return;
        } else {
          showSnackbar({ message: t('DASHBOARD_BUSINESS_CREATED_FAIL'), type: 'error' });
        }
      } else if (errorResponseMessage.includes('renameBusiness')) {
        if (errorStatus && errorStatus === HttpStatusError.CONFLICT) {
          setErrorMessage(t('DASHBOARD_BUSINESS_CRUD_FAIL_ALREADY_EXISTS'));
          return;
        } else {
          showSnackbar({
            message: (
              <div>
                <Trans
                  i18nKey="DASHBOARD_BUSINESS_RENAME_FAIL"
                  shouldUnescape={true}
                  tOptions={{ interpolation: { escapeValue: true } }}
                  values={{ businessName: contextBusiness?.businessName }}
                  components={{ bold: <strong /> }}
                />
              </div>
            ),
            type: 'error',
          });
          close();
        }
      } else if (
        errorResponseMessage.includes('generateUrl') &&
        event === ActionTypes.ADD &&
        createdBusiness
      ) {
        showSnackbar({
          message: t('DASHBOARD_GENERATE_LINK_NOT_ABLE_TO_GENERATE'),
          type: 'error',
        });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponseMessage, errorStatus]);

  const handleCreateSubmit = (): void => {
    dispatch(
      createBusinessesApi({
        businessName: newBusinessName,
      }),
    );
  };

  const handleRenameSubmit = (): void => {
    dispatch(
      renameBusinessApi({
        uuid: contextBusiness?.businessUuid,
        businessName: newBusinessName,
      }),
    );
  };

  const businessCrudSchema = yup.object().shape({
    newBusinessName: yup
      .string()
      .nullable()
      .trim()
      .required('DASHBOARD_BUSINESS_CRUD_REQUIRED')
      .min(2, 'DASHBOARD_BUSINESS_CRUD_MIN')
      .max(60, 'DASHBOARD_BUSINESS_CRUD_MAX')
      .test({
        message: t('DASHBOARD_BUSINESS_CRUD_FAIL_ALREADY_EXISTS'),
        test: (value) => {
          const isDuplicateName = businesses?.some(
            ({ businessName }) => value.toLowerCase() === businessName.toLowerCase(),
          );
          return !isDuplicateName;
        },
      })
      // TODO: to replace noSpecialChars
      .test({
        message: t('DASHBOARD_CRUD_FAIL_SPECIAL_CHARS'),
        test: (value) => !SPECIAL_CHARS.some((char) => value.includes(char)),
      }),
  });

  const validateBusinessName = (): boolean => {
    setErrorMessage(null);
    try {
      businessCrudSchema.validateSync({ newBusinessName: newBusinessName || '' });
      return true;
    } catch (error) {
      newBusinessNameRef?.current?.focus();
      setErrorMessage(error.message);
    }
    return false;
  };

  useEffect(() => {
    if (newBusinessName) validateBusinessName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBusinessName]);

  const handleClose = (): void => {
    setCreatedBusiness(false);
    setErrorMessage(null);
    setGeneratedLink(null);
    setCopied(false);
    setNewBusinessName(null);
    if (createdBusiness) {
      showSnackbar({
        message: (
          <div>
            <Trans
              i18nKey="DASHBOARD_BUSINESS_CREATED_SUCCESS"
              shouldUnescape={true}
              tOptions={{ interpolation: { escapeValue: true } }}
              values={{ businessName: newBusinessName }}
              components={{ bold: <strong /> }}
            />
          </div>
        ),
        type: 'success',
      });
    }
    close(!!createdBusiness);
  };

  return (
    <AlertDialog
      testId="business-create-rename"
      isOpen={open}
      cancel={
        ((newBusinessName && !createdBusiness) || contextBusiness?.businessName) && {
          label: t('DASHBOARD_BUSINESS_CANCEL'),
          onClick: handleClose,
          type: 'gray',
          'data-testid': 'cancel-business-create-rename',
        }
      }
      title={
        event === ActionTypes.RENAME
          ? t('DASHBOARD_BUSINESS_RENAME_TITLE')
          : event === ActionTypes.ADD && createdBusiness
          ? t('DASHBOARD_BUSINESS_NEW_DONE_TITLE')
          : t('DASHBOARD_BUSINESS_NEW_TITLE')
      }
      confirm={{
        label:
          event === ActionTypes.RENAME
            ? t('DASHBOARD_BUSINESS_RENAME_SAVE')
            : createdBusiness
            ? t('DASHBOARD_BUSINESS_DONE')
            : t('DASHBOARD_BUSINESS_ADD'),
        onClick: event === ActionTypes.ADD && createdBusiness ? handleClose : handleSubmit,
        'data-testid': 'submit-business-create-rename',
      }}
      onClose={handleClose}
      showCloseButton
    >
      {event === ActionTypes.ADD && createdBusiness ? (
        <>
          <div
            tabIndex={0}
            className={classes.container}
            aria-label={t('DASHBOARD_BUSINESS_ADDED_A11Y', { businessName: newBusinessName })}
            id="createdBusiness"
            ref={createdBusinessRef}
          >
            <CheckCircleIcon className={classes.icon} />
            <div>
              <Trans
                i18nKey="DASHBOARD_BUSINESS_ADDED"
                shouldUnescape={true}
                tOptions={{ interpolation: { escapeValue: true } }}
                values={{ businessName: newBusinessName }}
              />
            </div>
          </div>
          {generatedLink && (
            <ClipboardInput
              label={t('DASHBOARD_GENERATE_LINK_LINK')}
              ariaLabel={t('DASHBOARD_GENERATE_LINK_INPUT_ARIA_LABEL')}
              generatedContent={generatedLink}
              setCopied={setCopied}
            />
          )}
          {copied && (
            <span className={classes.copiedText}>{t('DASHBOARD_GENERATE_LINK_COPIED')}</span>
          )}
        </>
      ) : (
        <TextField
          fullWidth
          label={t('DASHBOARD_BUSINESS_NAME')}
          margin="normal"
          variant="outlined"
          inputProps={{
            'aria-label':
              event === ActionTypes.ADD
                ? t('DASHBOARD_BUSINESS_ADD_INPUT_ARIA_LABEL')
                : t('DASHBOARD_BUSINESS_RENAME_INPUT_ARIA_LABEL'),
          }}
          defaultValue={contextBusiness?.businessName || ''}
          onChange={(event): void => setNewBusinessName(event.target.value.trim())}
          error={!!errorMessage}
          helperText={t(errorMessage)}
          id="newBusinessName"
          name="newBusinessName"
          inputRef={newBusinessNameRef}
          data-testid="business-name"
        />
      )}
    </AlertDialog>
  );
}
