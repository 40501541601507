import { addPageStatsToPagination, formatReportData } from '../utils';

import { PaginationData } from '../types';

import { PARALLEL_DOWNLOAD_REQUESTS, REPORT_DOWNLOAD_LIMIT } from '../constants';

import { ReportParam } from 'store/features/report/report.state';

import { makeSubArraysOfSize, showSnackbar } from 'helpers/common.helper';
import reportService from 'store/features/report/report.service';
import { PaginationProps, ReportQuery } from 'types';
import { EventService } from 'services';
import i18n from 'translations';

const JOB_STATUS = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  FAILED: 'failed',
} as const;

type JobStatus = typeof JOB_STATUS[keyof typeof JOB_STATUS];

type GenericReportData = { data?: any[]; pagination: PaginationProps; meta: any; reports?: any[] };
type DataFetchJob = {
  requestParams?: ReportParam;
  data?: GenericReportData;
  status: JobStatus;
  reason?: string;
  index: number;
  onCompleteCb?: () => void;
};
const errorMessage = i18n.t('DASHBOARD_REPORT_DOWNLOAD_TOAST_ERROR');
const cancelMessage = i18n.t('DASHBOARD_REPORT_DOWNLOAD_TOAST_CANCELLED');

/**
 * Actual fetching of the data from the API.
 * @param jobs the full list of jobs, to be updated with the data or the error
 * @param index the index of the job to be done
 * @param signal the AbortSignal to cancel the requests
 */
async function fetchJobAndUpdateStatus(
  jobs: DataFetchJob[],
  index: number,
  signal: AbortSignal,
): Promise<void> {
  const { requestParams, onCompleteCb } = jobs[index];
  await reportService
    .getReport(requestParams, signal)
    .then((data: GenericReportData) => {
      jobs[index] = { index, data, status: JOB_STATUS.FULFILLED };
      onCompleteCb();
      return jobs[index];
    })
    .catch((reason) => {
      if (reason.message !== 'canceled')
        console.error(`Error downloading part ${index}`, requestParams, reason);
      jobs[index] = { index, requestParams, reason, status: JOB_STATUS.FAILED };

      return jobs[index];
    });
}

/**
 * Fetches all the reports data from the API in parallel.
 * It will retry the requests that failed up to 3 times.
 * It also has a cancelation mechanism (AbortSignal)
 */
async function fetchData(
  dataFetchJobs: DataFetchJob[],
  signal: AbortSignal,
  retryAttempt = 0,
): Promise<DataFetchJob[]> {
  function signalAborterCallback(): void {
    signal.removeEventListener('abort', signalAborterCallback);
    throw new Error('canceled');
  }

  retryAttempt && console.warn(`Retry attempt #${retryAttempt + 1}):`, dataFetchJobs);
  try {
    if (retryAttempt >= 3) throw new Error('Failed to fetch report data');
    signal.addEventListener('abort', signalAborterCallback);

    // Filters out the requests that are already fulfilled (avoid refetching data)
    const requestsToDo = dataFetchJobs.filter(({ status }) => status !== JOB_STATUS.FULFILLED);

    // Groups the requests to be done in groups of size PARALLEL_DOWNLOAD_REQUESTS
    const groupedRequests = makeSubArraysOfSize(requestsToDo, PARALLEL_DOWNLOAD_REQUESTS);

    for (const jobGroup of groupedRequests) {
      await Promise.all(
        jobGroup.map((job) => fetchJobAndUpdateStatus(dataFetchJobs, job.index, signal)),
      );
    }

    if (signal.aborted) signalAborterCallback();

    // Retry mechanism, if any of the requests failed, it will retry the function
    if (dataFetchJobs.some(({ status }) => status !== JOB_STATUS.FULFILLED))
      return await fetchData(dataFetchJobs, signal, retryAttempt + 1);

    signal.removeEventListener('abort', signalAborterCallback);
    return dataFetchJobs;
  } catch (error) {
    if (error?.message === 'canceled') {
      showSnackbar({ message: cancelMessage, type: 'info', topic: 'report-export' });
    } else {
      console.error(`Error downloading Reports`, error, dataFetchJobs);
      showSnackbar({ message: errorMessage, type: 'error', topic: 'report-export' });
    }
    signal.removeEventListener('abort', signalAborterCallback);
    return;
  }
}

/**
 * Unifies all the data fetched from the API into one object.
 * It uses the first fetched data as a template to build the final object.
 */
function gatherAllFetchedData(fetchedJobs: DataFetchJob[]): GenericReportData {
  const { data, reports, meta, pagination } = fetchedJobs[0].data;
  const limit = REPORT_DOWNLOAD_LIMIT * fetchedJobs.length;
  const sumOfData = {
    ...(data ? { data: [] } : {}),
    ...(reports ? { reports: [] } : {}),
    meta: meta,
    pagination: { ...pagination, limit } as const,
  };

  fetchedJobs.forEach(({ data }) => {
    const { data: dataToPush, reports: reportsToPush } = data;
    if (dataToPush) sumOfData.data = sumOfData.data.concat(dataToPush);
    if (reportsToPush) sumOfData.reports = sumOfData.reports.concat(reportsToPush);
  });

  return sumOfData;
}

/**
 * Prepares the requests to be done,
 * including a callback to be called when the request is completed.
 */
function prepareJobsForFetching(initialPaginationData: PaginationData): DataFetchJob[] {
  const { pagination, requestParams } = initialPaginationData;
  const { totalPages: totalJobs } = addPageStatsToPagination(pagination, REPORT_DOWNLOAD_LIMIT);

  let completedJobs = 0;
  const allPages = Array.from({ length: totalJobs }, (_, index) => ({
    status: JOB_STATUS.PENDING,
    index,
    requestParams: {
      type: requestParams.type,
      params: {
        ...requestParams.params,
        offset: String(index * REPORT_DOWNLOAD_LIMIT),
        limit: REPORT_DOWNLOAD_LIMIT,
      },
    },
    onCompleteCb: (): void => {
      completedJobs++;
      const completionPercentage = Math.floor((completedJobs / totalJobs) * 100);
      EventService.emit(EventService.EVENT.DOWNLOAD_PROGRESS, completionPercentage);
    },
  }));

  return allPages;
}

/**
 * Entry point for the large report download fetching logic.
 * It will fetch all the data from the API and format it to be exported.
 * @param initialPaginationData The initial pagination data to be used for the already fetched data.
 *  Used to calculate the total number of pages to be fetched.
 * @param filter used to format the data
 * @param signal an abort signal, to be used to cancel the download if needed
 * @returns the formatted data to be exported, with the same format as the already fetched,
 * initial data
 */
export async function getAllReportsPagesOfData(
  initialPaginationData: PaginationData,
  filter: ReportQuery,
  signal: AbortSignal,
): Promise<any[]> {
  // Prepares the requests to be done
  const allJobs = prepareJobsForFetching(initialPaginationData);

  // Executes the prepared requests
  const fetchedData = await fetchData(allJobs, signal);
  if (!fetchedData) return null;

  // Unifies all the data fetched from the API into one object
  const unifiedData = gatherAllFetchedData(fetchedData);

  // Formats the data to be exported
  return formatReportData({
    data: unifiedData,
    selectedReportType: filter.reportType,
    serviceName: filter.serviceName,
  });
}
