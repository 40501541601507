import { ErrorResponse } from '../../../types/interface/error.interface';
import { ApiResponse, AuthType, LoginMfa } from '../../../types';

export const AUTH_STORE_NAME = 'auth';
export const AUTH_INITIAL_STATE: AuthState = {
  error: null,
  loading: false,
  expiredOn: null,
  email: null,
  access_token: null,
  response: null,
  user: null,
  sandbox: null,
};

export interface LoginParam {
  email: string;
  password: string;
  sandbox?: boolean;
}

export interface GoogleLoginParam {
  code: string;
}

export interface MicrosoftLoginParam {
  accessToken: string;
}

export interface LoginMfaParam {
  userUuid: string;
  authType: AuthType;
  token: string;
}

export interface LoginMfaRecoveryParam {
  userUuid: string;
  recoveryCode: string;
}

export interface RefreshTokenParam {
  sandbox: boolean;
  teamUuid?: string;
}

export interface AuthAction {
  payload: LoginParam | LoginMfaParam | any;
  type: string;
}

export interface AuthMfaAction {
  payload: LoginMfaParam;
  type: string;
}

export interface VerifyEmailParam {
  email: string;
}

export interface ChangePasswordParam {
  currentPassword: string;
  newPassword: string;
}

export interface VerifyEmailResponse {
  message: string;
}

export interface LoginApiResponse {
  email?: string;
  access_token?: string;
  user?: LoginMfa;
  sandbox?: boolean;
}

export interface LoginResponse extends LoginApiResponse {
  expiredOn: number;
}

export interface AuthState extends LoginResponse {
  error?: ErrorResponse | any;
  loading: boolean;
  response: ApiResponse;
}
