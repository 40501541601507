import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ReportType } from '../../../types';

import { acceptedAspList } from '../../../helpers/report-helpers/constants';

import { formatDataType } from '../../../helpers/report-helpers/utils';

import { default as ReportService } from './report.service';
import {
  FETCH_DATA_TYPES_API,
  FETCH_REPORTS_API,
  fetchDataTypesFailureApi,
  fetchDataTypesSuccessApi,
  fetchReportsFailureApi,
  fetchReportsSuccessApi,
  FETCH_SYNC_STATUS_BUSINESS_API,
  fetchBusinessHistoryApiSuccess,
  fetchBusinessHistoryApiFailure,
  fetchReportsPendingApi,
} from './report.action';
import {
  DataTypesResponse,
  FormattedDataTypes,
  ReportAction,
  ReportData,
  SyncStatusAction,
} from './report.state';
import { getReport } from './report.selector';

import { isFieldsNotEqual } from 'helpers/common.helper';

function* fetchReportApiCall({ payload: requestParams }: ReportAction): Generator<any> {
  const previousReportState = (yield select(getReport)) as ReportData;

  const defaultResult = { data: null, statusCode: null };
  if (!requestParams.params?.connectionUuid) {
    // if there's no connectionUuid, we are in a "still loading" state
    yield put(fetchReportsPendingApi({ ...defaultResult, requestParams }));
    return;
  }

  const previousParams = previousReportState.requestParams;
  const sameAsBefore = previousParams && !isFieldsNotEqual(previousParams, requestParams);

  if (sameAsBefore) {
    // if there's no change of query params,
    // we either are waiting for an api response, or we already have data,
    // either way, we don't need to make a new request

    if (previousReportState.data) {
      yield put(fetchReportsPendingApi({ requestParams }));
      yield put(fetchReportsSuccessApi(previousReportState));
    }

    return;
  }

  try {
    // if we get here, it means that we should make a new request, and clean up the data meanwhile.
    yield put(fetchReportsPendingApi({ ...defaultResult, requestParams }));

    const result = (yield call(ReportService.getReport, requestParams) as unknown) as any;
    if (result) {
      yield put(
        fetchReportsSuccessApi({
          data: result,
          statusCode: result.statusCode || 200,
        }),
      );
    } else {
      yield put(fetchReportsFailureApi({ ...defaultResult, statusCode: 500 }));
    }
  } catch (error) {
    yield put(fetchReportsFailureApi({ ...defaultResult, statusCode: error?.statusCode || 500 }));
  }
}

function* fetchReportSaga(): Generator<any> {
  yield takeEvery(FETCH_REPORTS_API, fetchReportApiCall);
}

function* fetchDataTypesApiCall(): Generator<any> {
  try {
    const response = (yield call(ReportService.getDataTypes) as unknown) as DataTypesResponse[];
    const dataTypes = response?.reduce((parentValue, currentValue) => {
      parentValue[currentValue.serviceName] = formatDataType(
        ReportType._name,
        currentValue.dataTypes,
        currentValue.serviceType,
      );
      return parentValue;
    }, {});
    yield put(fetchDataTypesSuccessApi(dataTypes as FormattedDataTypes));
  } catch (error) {
    yield put(fetchDataTypesFailureApi(acceptedAspList));
  }
}

function* fetchDataTypesSaga(): Generator<any> {
  yield takeLatest(FETCH_DATA_TYPES_API, fetchDataTypesApiCall);
}

function* fetchBusinessHistoryApiCall(action: SyncStatusAction): Generator<any> {
  try {
    const result = (yield call(ReportService.getBusinessHistoryBusiness, {
      params: action.payload.params,
    }) as unknown) as any;
    yield put(fetchBusinessHistoryApiSuccess(result));
  } catch (error) {
    yield put(fetchBusinessHistoryApiFailure(error.message));
  }
}

function* fetchBusinessHistorySaga(): Generator<any> {
  yield takeLatest(FETCH_SYNC_STATUS_BUSINESS_API, fetchBusinessHistoryApiCall);
}

export { fetchReportApiCall, fetchDataTypesApiCall, fetchBusinessHistoryApiCall };
export const reportSaga = [fetchReportSaga(), fetchDataTypesSaga(), fetchBusinessHistorySaga()];
