import { createSelector } from 'reselect';

import { AllState } from '../../../state';

import { ACCOUNT_STORE_NAME } from '../account.state';

import { CUSTOMER_STORE_NAME, CustomerState } from './customer.state';

/** Select Customer State */
export const getCustomerState = (state: AllState): CustomerState =>
  state[ACCOUNT_STORE_NAME][CUSTOMER_STORE_NAME];

export const getRailzConnectMetadata = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.railzConnectMetadata,
);

export const getCompanyLogo = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer && customer.railzConnectMetadata && customer.railzConnectMetadata.companyLogo,
);

export const getCustomerBillingPlan = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.billingPlan,
);

export const isCustomerLoading = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.loading,
);

export const isSignUpFlowComplete = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer &&
    !!customer?.apiPurpose &&
    customer.country &&
    !!customer?.businessRole &&
    customer.billingPlan,
);

export const isQuestionnaireComplete = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer && !!customer.country && !!customer?.businessRole && !!customer?.apiPurpose,
);

export const isPlanMissing = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer &&
    !!customer?.apiPurpose &&
    customer.country &&
    !!customer?.businessRole &&
    !customer.billingPlan,
);

export const isCustomerBaseLoading = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.baseLoading,
);

export const getCustomerError = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.error,
);

export const getCustomerErrorMessage = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.error && customer.error.message,
);

export const isCustomerApiSuccess = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.response && customer.response.success,
);

export const getCustomerApiResponse = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer && customer.response && customer.response.message,
);

export const getCustomerSessionURL = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer?.sessionURL,
);

export const getCustomerTeams = createSelector(
  getCustomerState,
  (customer: CustomerState) => customer?.teams,
);

export const isCustomerPlanApiSuccess = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer &&
    customer.response &&
    customer.response.success &&
    customer.response.message === 'plan_contact',
);

export const isCustomerPlanApiError = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer &&
    customer.error &&
    customer.error.message &&
    customer.error.message.includes('plan_contact'),
);

export const isCustomerSendEmailApiSuccess = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer &&
    customer.response &&
    customer.response.success &&
    customer.response.message === 'send_email',
);

export const isCustomerSendEmailApiError = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer &&
    customer.error &&
    customer.error.message &&
    customer.error.message.includes('send_email'),
);

export const getEmailSuggestions = createSelector(
  getCustomerState,
  (customer: CustomerState) =>
    customer &&
    customer.emailSuggestions && {
      emailSuggestions: customer.emailSuggestions,
      index: customer.emailSuggestionsInputIndex,
    },
);
