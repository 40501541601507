import React, { useEffect, useState } from 'react';
import { Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyles from './style';
import ApiKeysGenerate from './api-keys-generate/api-keys-generate';
import ApiKeysDelete from './api-keys-delete/api-keys-delete';
import ApiKeysTable, { ApiKeyActionTypes } from './api-keys-table/api-keys-table';

import { ApiKey, ApiKeyGenerateResponse } from 'types';
import { EventService } from 'services';
import { Header, ScrollView, View } from 'components';
import PageDescription from 'components/page-description/page-description';
import useDefaultStyles from 'assets/styles/style';
import {
  getApiKeyResponse,
  getApiKeyError,
  getApiKeys,
  getApiKeyState,
} from 'store/features/developer/api-key/api-key.selector';
import {
  deleteApiKey,
  generateApiKey,
  fetchApiKeys,
  resetApiKeyResponse,
  resetApiKeyError,
} from 'store/features/developer/api-key/api-key.action';
import { showSnackbar } from 'helpers/common.helper';
import Config from 'config';
import { openNewTab } from 'helpers/open-new-tab';

interface Props {
  testid: string;
}

const ApiKeys: React.FC<Props> = ({ testid = 'test-header-api-page' }) => {
  const dispatch = useDispatch();
  const apiKeys = useSelector(getApiKeys);
  const apiResponse = useSelector(getApiKeyResponse);
  const apiErrorResponse = useSelector(getApiKeyError);
  const apiKeysLoading = useSelector(getApiKeyState).loading;

  const [generatedApiKey, setGeneratedApiKey] = useState<ApiKeyGenerateResponse | undefined>();
  const [isGenerateDialogOpen, setIsGenerateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedApiKeyToDelete, setSelectedApiKeyToDelete] = useState<ApiKey>(null);

  const [copied, setCopied] = useState('');

  const { t } = useTranslation();
  const classes = useStyles();
  const defaultClasses = useDefaultStyles();

  const copyToClipboard = (identifier: string): void => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(identifier);
      setCopied(identifier);
    }
  };

  useEffect(() => {
    dispatch(fetchApiKeys());
  }, [dispatch]);

  useEffect(() => {
    if (isGenerateDialogOpen) {
      setGeneratedApiKey(undefined);
    }
  }, [isGenerateDialogOpen]);

  useEffect(() => {
    if (apiResponse?.message) {
      dispatch(resetApiKeyResponse());

      if (apiResponse.message === 'create') {
        setGeneratedApiKey(apiResponse.apiKey);
      }

      if (apiResponse.message === 'no changes') {
        showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
      }

      if (apiResponse.message === 'delete') {
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'success',
          message: (
            <Trans
              i18nKey="DASHBOARD_API_DELETE_KEY_SUCCESS"
              values={{ name: apiResponse.name }}
              components={{ bold: <strong /> }}
            ></Trans>
          ),
        });
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    if (apiErrorResponse?.message?.[0]) {
      dispatch(resetApiKeyError());

      if (apiErrorResponse.message[0] === 'fetch') {
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'error',
          message: <Trans i18nKey="DASHBOARD_API_KEYS_FETCH_FAILURE"></Trans>,
        });
      }

      if (apiErrorResponse.message[0] === 'create') {
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'error',
          message: <Trans i18nKey="DASHBOARD_API_GENERATE_FAILURE"></Trans>,
        });
      }

      if (apiErrorResponse.message[0] === 'delete') {
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'error',
          message: <Trans i18nKey="DASHBOARD_API_DELETE_KEY_FAIL"></Trans>,
        });
      }
    }
  }, [apiErrorResponse]);

  const handleClickGenerateApiKey = (): void => {
    setCopied(null);
    if (apiKeys.length >= Config.MAX_API_KEYS) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: t('DASHBOARD_API_GENERATE_FAILURE_MAX', { maxApiKeys: Config.MAX_API_KEYS }),
      });
    } else {
      setIsGenerateDialogOpen(true);
    }
  };

  const handleGenerateApiKey = (value: ApiKey): void => {
    setCopied(null);
    dispatch(generateApiKey(value));
  };

  const handleDeleteApiKey = ({ identifier, name }: { identifier: string; name: string }): void => {
    setCopied(null);
    dispatch(deleteApiKey({ identifier, name }));
  };

  const handleApiKeyAction = (type: ApiKeyActionTypes, apiKey: ApiKey): void => {
    if (type === ApiKeyActionTypes.DELETE) {
      setSelectedApiKeyToDelete(apiKey);
      setIsDeleteDialogOpen(true);
    }
  };

  return (
    <>
      <Header
        drawerMenu={true}
        title={t('DASHBOARD_API_HEADER')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />
      <View className={classes.view}>
        <div className={classes.viewHeader}>
          <PageDescription
            title="DASHBOARD_DEVELOPERS_API_TITLE"
            subtitle="DASHBOARD_DEVELOPERS_API_DESCRIPTION"
            subtitleComponents={{
              a: (
                <Link
                  href="https://docs.railz.ai/reference/authentication"
                  onClick={(e): void =>
                    openNewTab(e, 'https://docs.railz.ai/reference/authentication')
                  }
                  rel="noopener noreferrer"
                  className={defaultClasses.link}
                >
                  {t('DASHBOARD_LEARN_MORE_CTA')}
                </Link>
              ),
            }}
          />
          <RailzButton
            size="large"
            label={t('DASHBOARD_API_CTA')}
            onButtonClick={handleClickGenerateApiKey}
            data-testid="add-api-key-button"
            className={classes.primaryCta}
          >
            <span slot="prefix">
              <AddIcon />
            </span>
          </RailzButton>
        </div>

        <ScrollView classNames={classes.viewContent}>
          <ApiKeysTable
            apiKeys={apiKeys}
            onAction={handleApiKeyAction}
            copyToClipboard={copyToClipboard}
            copied={copied}
            isLoading={apiKeysLoading}
          />
        </ScrollView>

        {selectedApiKeyToDelete && (
          <ApiKeysDelete
            isOpen={isDeleteDialogOpen}
            apiKey={selectedApiKeyToDelete}
            onDelete={handleDeleteApiKey}
            onClose={(): void => setIsDeleteDialogOpen(false)}
          />
        )}
        {isGenerateDialogOpen && (
          <ApiKeysGenerate
            generatedApiKey={generatedApiKey}
            apiKeys={apiKeys}
            onGenerate={handleGenerateApiKey}
            onClose={(): void => setIsGenerateDialogOpen(false)}
          />
        )}
      </View>
    </>
  );
};

export default ApiKeys;
