/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import { useHistory } from 'react-router-dom';

import { getHexValue, isHexColor } from '../utils';

import {
  getCustomerApiResponse,
  getCustomerError,
  getCustomerState,
} from '../../../store/features/account/customer/customer.selector';

import Accordion from '../../../components/accordion/accordion';

import useStyle from './style';

import BrandSection from './sections/brand-section';
import LogoSection from './sections/logo-section';
import UrlSection from './sections/url-section';

import OnboardLandingPageSection from './sections/onboard-landing-page-section';

import OverviewAndSummaryPageSection from './sections/overview-and-summary-page-section';

import LeftSideBarSection from './sections/left-side-bar-section';

import HelpLinkSection from './sections/help-link-section';

import TermsAndConditionsSection from './sections/terms-and-conditions';
import IntegrationsSection from './sections/integrations-section';

import SeoMetadataSection from './sections/seo-metadata-section';

import { DefaultBrandColors, Plan, RailzSitesMetadata } from 'types';
import {
  resetCustomerError,
  resetCustomerResponse,
  updateCustomerApi,
  noChangeSave,
  fetchCustomerApi,
} from 'store/features/account/customer/customer.action';
import { formChange, showSnackbar } from 'helpers/common.helper';
import BlockerNavigation from 'components/blocker/blocker-navigation';
import { openNewTab } from 'helpers/open-new-tab';

interface Props {
  setBrandColor: (brandColor: string) => void;
  setAccentColor: (accentColor: string) => void;
  pageCompanyLogo: string | File | null;
  setPageUploadCompanyLogo: (imgOrNull: string | File | null) => void;
  pageMetaFavicon: string | File | null;
  setPageMetaFavicon: (imgOrNull: string | File | null) => void;
  pageMetaImage: string | File | null;
  setPageMetaImage: (imgOrNull: string | File | null) => void;
  expanded?: any;
  setExpanded?: (expaneded: any) => void;
  userIsOnFreePlan: boolean;
  saveBusinessUrlEnabled?: boolean;
  saveUrl?: boolean;
  overviewEnabled?: boolean;
  summaryEnabled?: boolean;
  accountingRequired?: boolean;
  bankingRequired?: boolean;
  commerceRequired?: boolean;
  railzWatermarkEnabled?: boolean;
  helpLinkEnabled?: boolean;
  termsAndConditionsEnabled?: boolean;
  setSaveBusinessUrlEnabled?: (saveBusinessUrlEnabled: boolean) => void;
  setSummaryEnabled?: (summaryEnabled: boolean) => void;
  setOverviewEnabled?: (overviewEnabled: boolean) => void;
  setAccountingRequired?: (overviewEnabled: boolean) => void;
  setBankingRequired?: (overviewEnabled: boolean) => void;
  setCommerceRequired?: (overviewEnabled: boolean) => void;
  setRailzWatermarkEnabled?: (railzWatermarkEnabled: boolean) => void;
  setHelpLinkEnabled?: (helpLinkEnabled: boolean) => void;
  setTermsAndConditionsEnabled?: (termsAndConditionsEnabled: boolean) => void;
  setSaveUrl?: (saveUrl: string) => void;
  defaultContent?: RailzSitesMetadata;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  sitesForm?: {
    handleSubmit: Function;
    control: any;
    getValues: Function;
    clearErrors: Function;
    errors: any;
    setValue: Function;
    watch: Function;
  };
}

export default function BrandForm({
  setBrandColor: parentSetBrandColor,
  setAccentColor: parentSetAccentColor,
  setPageUploadCompanyLogo,
  userIsOnFreePlan,
  expanded,
  setExpanded,
  saveBusinessUrlEnabled,
  setSaveBusinessUrlEnabled,
  overviewEnabled,
  setSummaryEnabled,
  summaryEnabled,
  accountingRequired,
  bankingRequired,
  commerceRequired,
  setAccountingRequired,
  setBankingRequired,
  setCommerceRequired,
  railzWatermarkEnabled,
  setRailzWatermarkEnabled,
  setOverviewEnabled,
  helpLinkEnabled,
  setHelpLinkEnabled,
  termsAndConditionsEnabled,
  setTermsAndConditionsEnabled,
  setSaveUrl,
  pageCompanyLogo,
  pageMetaFavicon,
  setPageMetaFavicon,
  pageMetaImage,
  setPageMetaImage,
  defaultContent,
  isLoading,
  setIsLoading,
  sitesForm: { handleSubmit, control, getValues, errors, setValue, clearErrors, watch },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();

  const dispatch = useDispatch();

  const customer = useSelector(getCustomerState);
  const error = useSelector(getCustomerError);
  const message = useSelector(getCustomerApiResponse);

  const isFreePlan = customer?.billingPlan === Plan.FREE;
  const notEnterprise =
    customer?.billingPlan === Plan.FREE || customer?.billingPlan === Plan.PAY_AS_YOU_GO;

  const [brandColor, setBrandColor] = useState<string>('#');
  const [accentColor, setAccentColor] = useState<string>('#');
  const [brandColorInvalid, setBrandColorInvalid] = useState(false);
  const [accentColorInvalid, setAccentColorInvalid] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);
  const [discardImage, setDiscardImage] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);

  const watchAllFields = watch();

  //Uploaded Logo

  const formRef = useRef(null);
  const refs = [
    'saveBusinessUrlToggle',
    'onboard',
    'overview',
    'integrations',
    'brand',
    'logo',
    'left-side-bar',
    'help-link',
    'terms-and-conditions',
    'seo',
  ].map((id) => ({
    name: id,
    ref: useRef(null),
  }));

  /**
   * Handles accordian animation & auto scroll.
   * Set timeout is needed for now to prevent the animation from being cut off.
   */
  const handleChange = (i) => (_, isExpanded) => {
    setExpanded(isExpanded ? refs[i].name : null);

    if (isExpanded) {
      const parentEl = refs[i].ref.current.parentNode;
      const elementOffsetTop = parentEl.offsetTop;

      setTimeout(() => {
        if (formRef && formRef?.current) {
          formRef.current.scrollTop = elementOffsetTop;
        }
      }, 400);
    }
  };

  useEffect(() => {
    if (brandColor) {
      parentSetBrandColor(getHexValue(brandColor).toUpperCase());
    }
  }, [brandColor, parentSetBrandColor]);

  useEffect(() => {
    if (accentColor) {
      parentSetAccentColor(getHexValue(accentColor).toUpperCase());
    }
  }, [accentColor, parentSetAccentColor]);

  useEffect(() => {
    setShowDiscard(validateIfValuesChange());
  }, [watchAllFields, overviewEnabled, summaryEnabled, setRailzWatermarkEnabled]);

  useEffect(() => {
    if (message || error) setIsLoading(false);

    if (message) {
      ``;
      dispatch(resetCustomerResponse());
      if (message === 'image') {
        showSnackbar({ message: t('DASHBOARD_BRAND_SUCCESS_UPLOAD'), type: 'success' });
      } else if (message === 'color' || message === 'delete') {
        showSnackbar({ message: t('DASHBOARD_BRAND_SUCCESS_DATA'), type: 'success' });
      } else if (message === 'no changes') {
        showSnackbar({ message: t('DASHBOARD_BRANDING_NO_CHANGES_MADE'), type: 'success' });
      }
    }

    if (error) {
      dispatch(resetCustomerError());
      if (error.message?.includes('color')) {
        showSnackbar({ message: t('DASHBOARD_BRAND_DATA_FAILED'), type: 'error' });
      } else if (error.message?.includes('delete') || error.message?.includes('image')) {
        showSnackbar({ message: t('DASHBOARD_BRAND_UPLOAD_FAILED'), type: 'error' });
      }
    }
  }, [message, error]);

  useEffect(() => {
    setBrandColor(`#${customer?.railzConnectMetadata?.brandColor || DefaultBrandColors.BRAND}`);
    setAccentColor(`#${customer?.railzConnectMetadata?.accentColor || DefaultBrandColors.ACCENT}`);
    setSaveBusinessUrlEnabled(
      customer?.railzSitesMetadata?.saveBusinessUrlEnabled === undefined ||
        customer?.railzSitesMetadata?.saveBusinessUrlEnabled === null
        ? defaultContent.saveBusinessUrlEnabled
        : customer?.railzSitesMetadata?.saveBusinessUrlEnabled,
    );
    setSummaryEnabled(
      customer?.railzSitesMetadata?.summaryEnabled === undefined ||
        customer?.railzSitesMetadata?.summaryEnabled === null
        ? defaultContent.summaryEnabled
        : customer?.railzSitesMetadata?.summaryEnabled,
    );
    setOverviewEnabled(
      customer?.railzSitesMetadata?.overviewEnabled === undefined ||
        customer?.railzSitesMetadata?.overviewEnabled === null
        ? defaultContent.overviewEnabled
        : customer?.railzSitesMetadata?.overviewEnabled,
    );
    setAccountingRequired(
      customer?.railzSitesMetadata?.accountingRequired === undefined ||
        customer?.railzSitesMetadata?.accountingRequired === null
        ? defaultContent.accountingRequired
        : customer?.railzSitesMetadata?.accountingRequired,
    );
    setBankingRequired(
      customer?.railzSitesMetadata?.bankingRequired === undefined ||
        customer?.railzSitesMetadata?.bankingRequired === null
        ? defaultContent.bankingRequired
        : customer?.railzSitesMetadata?.bankingRequired,
    );
    setCommerceRequired(
      customer?.railzSitesMetadata?.commerceRequired === undefined ||
        customer?.railzSitesMetadata?.commerceRequired === null
        ? defaultContent.commerceRequired
        : customer?.railzSitesMetadata?.commerceRequired,
    );
    setRailzWatermarkEnabled(
      customer?.railzSitesMetadata?.railzWatermarkEnabled === undefined ||
        customer?.railzSitesMetadata?.railzWatermarkEnabled === null
        ? defaultContent.railzWatermarkEnabled
        : customer?.railzSitesMetadata?.railzWatermarkEnabled,
    );
    setHelpLinkEnabled(
      customer?.railzSitesMetadata?.helpLinkEnabled === undefined ||
        customer?.railzSitesMetadata?.helpLinkEnabled === null
        ? defaultContent.helpLinkEnabled
        : customer?.railzSitesMetadata?.helpLinkEnabled,
    );
    setTermsAndConditionsEnabled(
      customer?.railzSitesMetadata?.termsAndConditionsEnabled === undefined ||
        customer?.railzSitesMetadata?.termsAndConditionsEnabled === null
        ? defaultContent.termsAndConditionsEnabled
        : customer?.railzSitesMetadata?.termsAndConditionsEnabled,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.railzConnectMetadata, customer?.railzSitesMetadata]);

  useEffect(() => {
    if (brandColor) {
      setBrandColorInvalid(!isHexColor(brandColor));
    } else {
      setBrandColor('#');
    }
    if (accentColor) {
      setAccentColorInvalid(!isHexColor(accentColor));
    } else {
      setAccentColor('#');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandColor, accentColor]);

  const saveChanges = async (): Promise<void> => {
    const {
      onboardLandingPageTitle,
      onboardLandingPageBody,
      leftSideBarTitle,
      leftSideBarBody,
      helpLinkTitle,
      helpLinkBody,
      termsAndConditionsTitle,
      termsAndConditionsBody,
      metaTitle,
      metaDescription,
    } = getValues();

    const formChange = validateIfValuesChange();

    if (formChange) {
      setIsLoading(true);
      const data = new FormData();
      data.append('favicon', pageMetaFavicon);
      data.append('metaImage', pageMetaImage);
      data.append('companyLogo', pageCompanyLogo);
      data.append('accentColor', getHexValue(accentColor));
      data.append('brandColor', getHexValue(brandColor));
      data.append('onboardLandingPageTitle', onboardLandingPageTitle);
      data.append('onboardLandingPageBody', onboardLandingPageBody);
      data.append('leftSideBarTitle', leftSideBarTitle);
      data.append('leftSideBarBody', leftSideBarBody);
      data.append('metaTitle', metaTitle);
      data.append('metaDescription', metaDescription);
      data.append('helpLinkTitle', helpLinkTitle);
      data.append('helpLinkBody', helpLinkBody);
      data.append('termsAndConditionsTitle', termsAndConditionsTitle);
      data.append('termsAndConditionsBody', termsAndConditionsBody);
      data.append(
        'saveBusinessUrlEnabled',
        saveBusinessUrlEnabled === undefined || saveBusinessUrlEnabled === null
          ? defaultContent.saveBusinessUrlEnabled.toString()
          : saveBusinessUrlEnabled.toString(),
      );
      data.append(
        'overviewEnabled',
        overviewEnabled === undefined || overviewEnabled === null
          ? defaultContent.overviewEnabled.toString()
          : overviewEnabled.toString(),
      );
      data.append(
        'summaryEnabled',
        summaryEnabled === undefined || summaryEnabled === null
          ? defaultContent.summaryEnabled.toString()
          : summaryEnabled.toString(),
      );
      data.append(
        'accountingRequired',
        accountingRequired === undefined || accountingRequired === null
          ? defaultContent.accountingRequired.toString()
          : accountingRequired.toString(),
      );
      data.append(
        'bankingRequired',
        bankingRequired === undefined || bankingRequired === null
          ? defaultContent.bankingRequired.toString()
          : bankingRequired.toString(),
      );
      data.append(
        'commerceRequired',
        commerceRequired === undefined || commerceRequired === null
          ? defaultContent.commerceRequired.toString()
          : commerceRequired.toString(),
      );
      data.append(
        'railzWatermarkEnabled',
        railzWatermarkEnabled === undefined || railzWatermarkEnabled === null
          ? defaultContent.railzWatermarkEnabled.toString()
          : railzWatermarkEnabled.toString(),
      );
      data.append(
        'helpLinkEnabled',
        helpLinkEnabled === undefined || helpLinkEnabled === null
          ? defaultContent.helpLinkEnabled.toString()
          : helpLinkEnabled.toString(),
      );
      data.append(
        'termsAndConditionsEnabled',
        termsAndConditionsEnabled === undefined || termsAndConditionsEnabled === null
          ? defaultContent.termsAndConditionsEnabled.toString()
          : termsAndConditionsEnabled.toString(),
      );

      dispatch(updateCustomerApi(data));
    } else {
      dispatch(noChangeSave());
    }
  };

  const validateIfValuesChange = (): boolean => {
    const { railzConnectMetadata, railzSitesMetadata } = customer || {};
    if (railzConnectMetadata?.companyLogo !== pageCompanyLogo) return true;
    if (railzSitesMetadata?.faviconUrl !== pageMetaFavicon) return true;
    if (railzSitesMetadata?.metaImageUrl !== pageMetaImage) return true;

    if (!userIsOnFreePlan) {
      const brandColorUnchanged =
        (railzConnectMetadata?.brandColor || DefaultBrandColors.BRAND).toUpperCase() ===
        getHexValue(brandColor)?.toUpperCase();
      if (!brandColorUnchanged) return true;

      const accentColorUnchanged =
        (railzConnectMetadata?.accentColor || DefaultBrandColors.ACCENT).toUpperCase() ===
        getHexValue(accentColor)?.toUpperCase();
      if (!accentColorUnchanged) return true;
    }

    const {
      onboardLandingPageTitle,
      onboardLandingPageBody,
      metaTitle,
      metaDescription,
      leftSideBarTitle,
      leftSideBarBody,
      helpLinkTitle,
      helpLinkBody,
      termsAndConditionsTitle,
      termsAndConditionsBody,
    } = getValues();

    const sitesContentChanged = formChange(
      {
        ...(customer.billingPlan === Plan.CUSTOM && {
          onboardLandingPageTitle,
          onboardLandingPageBody,
          leftSideBarTitle,
          metaTitle,
          metaDescription,
          leftSideBarBody,
          helpLinkTitle,
          helpLinkBody,
          termsAndConditionsTitle,
          termsAndConditionsBody,
          railzWatermarkEnabled,
          termsAndConditionsEnabled,
          helpLinkEnabled,
        }),
        saveBusinessUrlEnabled,
        overviewEnabled,
        summaryEnabled,
        accountingRequired,
        bankingRequired,
        commerceRequired,
      },
      { ...defaultContent, ...customer?.railzConnectMetadata, ...customer.railzSitesMetadata },
    );

    return sitesContentChanged;
  };

  const getHeader = (headerText: string): JSX.Element => {
    return (
      <span className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          {headerText}
        </Typography>
      </span>
    );
  };

  const discardChanges = (): void => {
    setDiscardImage(!discardImage);
    setPageUploadCompanyLogo(customer?.railzConnectMetadata?.companyLogo);
    dispatch(fetchCustomerApi());
  };

  const handleDiscard = (e): void => {
    e.preventDefault();
    discardChanges();
  };

  const handleDiscardModal = (): void => {
    discardChanges();
    setShowDiscard(false);
    history.push(lastLocation, { discard: 'true' });
  };

  return (
    <div className={classes.form} ref={formRef}>
      <BlockerNavigation
        showMessage={showDiscard}
        handleDiscardModal={handleDiscardModal}
        setLastLocation={setLastLocation}
        title="DASHBOARD_SITES_WARNING_MODAL_TITLE"
        description="DASHBOARD_SITES_WARNING_MODAL_DESCRIPTION"
        confirmLabel="DASHBOARD_SITES_WARNING_MODAL_DISCARD_CTA"
        cancelLabel="DASHBOARD_SITES_WARNING_MODAL_CONTINUE_CTA"
      ></BlockerNavigation>
      <div className={classes.introduction}>
        <Typography variant="h2" className={classes.title}>
          {t('DASHBOARD_SITES_TITLE')}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          <Trans
            i18nKey="DASHBOARD_SITES_BODY"
            components={{
              a: (
                <a
                  className={classes.linkLearnMore}
                  href="https://docs.railz.ai/docs/railz-sites-overview"
                  onClick={(e): void =>
                    openNewTab(e, 'https://docs.railz.ai/docs/railz-sites-overview')
                  }
                  rel="noopener noreferrer"
                >
                  {t('DASHBOARD_LEARN_MORE_CTA')}
                </a>
              ),
            }}
          />
        </Typography>
      </div>
      <Accordion
        dataTestId="onboarding-link-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SETTINGS_SITES_LINK_HEADER'))}
        showAccordion={true}
        hideSides={true}
        children={
          <UrlSection
            saveBusinessUrlEnabled={saveBusinessUrlEnabled}
            setSaveBusinessUrlEnabled={setSaveBusinessUrlEnabled}
            setSaveUrl={setSaveUrl}
          ></UrlSection>
        }
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'saveBusinessUrlToggle',
          onChange: handleChange(0),
          expanded: expanded === 'saveBusinessUrlToggle',
        }}
        accordianRef={refs[0].ref}
      />
      <Accordion
        dataTestId="seo-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SETTINGS_SITES_SEO_SECTION_TITLE'))}
        showAccordion={true}
        hideSides={true}
        planMessage={t('DASHBOARD_SITES_UPGRADE_ENTERPRISE_TAGLINE')}
        showPlanMessage={notEnterprise}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'seo',
          onChange: handleChange(9),
          expanded: expanded === 'seo',
        }}
        accordianRef={refs[9].ref}
        children={
          <SeoMetadataSection
            disabled={notEnterprise}
            pageMetaFavicon={pageMetaFavicon}
            setPageMetaFavicon={setPageMetaFavicon}
            pageMetaImage={pageMetaImage}
            setPageMetaImage={setPageMetaImage}
            defaultContent={defaultContent}
            form={{ errors, control, setValue, clearErrors, getValues, watch }}
          />
        }
      />
      <Accordion
        dataTestId="onboard-landing-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SETTINGS_SITES_LANDING_PAGE_HEADER'))}
        showAccordion={true}
        hideSides={true}
        planMessage={t('DASHBOARD_SITES_UPGRADE_ENTERPRISE_TAGLINE')}
        showPlanMessage={notEnterprise}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'onboard',
          onChange: handleChange(1),
          expanded: expanded === 'onboard',
        }}
        accordianRef={refs[1].ref}
        children={
          <OnboardLandingPageSection
            disabled={notEnterprise}
            defaultContent={defaultContent}
            form={{ errors, control, setValue, clearErrors, getValues, watch }}
          ></OnboardLandingPageSection>
        }
      />
      <Accordion
        dataTestId="overview-summary-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SETTINGS_SITES_OVERVIEW_SUMMARY_PAGE_HEADER'))}
        showAccordion={true}
        hideSides={true}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'overview',
          onChange: handleChange(2),
          expanded: expanded === 'overview',
        }}
        accordianRef={refs[2].ref}
        children={
          <OverviewAndSummaryPageSection
            overviewEnabled={overviewEnabled}
            setOverviewEnabled={setOverviewEnabled}
            summaryEnabled={summaryEnabled}
            setSummaryEnabled={setSummaryEnabled}
            disabled={false}
          />
        }
      />
      <Accordion
        dataTestId="integrations-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SITES_INTEGRATION_TITLE'))}
        showAccordion={true}
        hideSides={true}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'integrations',
          onChange: handleChange(3),
          expanded: expanded === 'integrations',
        }}
        accordianRef={refs[3].ref}
        children={
          <IntegrationsSection
            accountingRequired={accountingRequired}
            setAccountingRequired={setAccountingRequired}
            bankingRequired={bankingRequired}
            setBankingRequired={setBankingRequired}
            commerceRequired={commerceRequired}
            setCommerceRequired={setCommerceRequired}
            disabled={false}
          />
        }
      />
      <Accordion
        dataTestId="brand-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SITES_BRAND_TITLE'))}
        showAccordion={true}
        hideSides={true}
        planMessage={t('DASHBOARD_SITES_UPGRADE_PAYG_TAGLINE')}
        showPlanMessage={isFreePlan}
        muiProps={{
          defaultExpanded: false,
          key: 'brand',
          onChange: handleChange(4),
          expanded: expanded === 'brand',
          square: true,
        }}
        accordianRef={refs[4].ref}
        children={
          <BrandSection
            brandColor={brandColor}
            setBrandColor={setBrandColor}
            accentColor={accentColor}
            setAccentColor={setAccentColor}
            brandColorInvalid={brandColorInvalid}
            accentColorInvalid={accentColorInvalid}
            userIsOnFreePlan={userIsOnFreePlan}
          />
        }
      />
      <Accordion
        dataTestId="logo-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SITES_LOGO_TITLE'))}
        showAccordion={true}
        hideSides={true}
        planMessage={t('DASHBOARD_SITES_UPGRADE_ENTERPRISE_TAGLINE')}
        showPlanMessage={notEnterprise}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'logo',
          onChange: handleChange(5),
          expanded: expanded === 'logo',
        }}
        accordianRef={refs[5].ref}
        children={
          <LogoSection
            disabled={notEnterprise}
            setUploadImage={setPageUploadCompanyLogo}
            logoValue={pageCompanyLogo}
          />
        }
      />
      <Accordion
        dataTestId="left-side-bar-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SETTINGS_SITES_LEFT_PAGE_HEADER'))}
        showAccordion={true}
        hideSides={true}
        planMessage={t('DASHBOARD_SITES_UPGRADE_ENTERPRISE_TAGLINE')}
        showPlanMessage={notEnterprise}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'left-side-bar',
          onChange: handleChange(6),
          expanded: expanded === 'left-side-bar',
        }}
        accordianRef={refs[6].ref}
        children={
          <LeftSideBarSection
            disabled={notEnterprise}
            railzWatermarkEnabled={railzWatermarkEnabled}
            defaultContent={defaultContent}
            setRailzWatermarkEnabled={setRailzWatermarkEnabled}
            form={{ errors, control, setValue, clearErrors, getValues, watch }}
          />
        }
      />
      <Accordion
        dataTestId="help-link-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SETTINGS_SITES_HELP_PAGE_HEADER'))}
        showAccordion={true}
        hideSides={true}
        planMessage={t('DASHBOARD_SITES_UPGRADE_ENTERPRISE_TAGLINE')}
        showPlanMessage={notEnterprise}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'help-link',
          onChange: handleChange(7),
          expanded: expanded === 'help-link',
        }}
        accordianRef={refs[7].ref}
        children={
          <HelpLinkSection
            disabled={notEnterprise}
            helpLinkEnabled={helpLinkEnabled}
            setHelpLinkEnabled={setHelpLinkEnabled}
            defaultContent={defaultContent}
            form={{ errors, control, setValue, clearErrors, getValues, watch }}
          ></HelpLinkSection>
        }
      />
      <Accordion
        dataTestId="terms-and-conditions-section"
        classNameAccordion={classes.classAccordion}
        className={classes.root}
        header={getHeader(t('DASHBOARD_SETTINGS_SITES_TERMS_PAGE_HEADER'))}
        showAccordion={true}
        hideSides={true}
        planMessage={t('DASHBOARD_SITES_UPGRADE_ENTERPRISE_TAGLINE')}
        showPlanMessage={notEnterprise}
        muiProps={{
          defaultExpanded: false,
          square: true,
          key: 'terms-and-conditions',
          onChange: handleChange(8),
          expanded: expanded === 'terms-and-conditions',
        }}
        accordianRef={refs[8].ref}
        children={
          <TermsAndConditionsSection
            disabled={notEnterprise}
            termsAndConditionsEnabled={termsAndConditionsEnabled}
            setTermsAndConditionsEnabled={setTermsAndConditionsEnabled}
            defaultContent={defaultContent}
            form={{ errors, control, setValue, clearErrors, getValues, watch }}
          ></TermsAndConditionsSection>
        }
      />
      <div className={classes.buttonContainer}>
        {showDiscard && (
          <RailzButton
            size="medium"
            onClick={handleDiscard}
            type="reset"
            label={t('DASHBOARD_SITES_DISCARD_CTA')}
            className={classes.discardCta}
          />
        )}
        {isLoading && (
          <RailzButton size="large" buttonType="button" data-testid="submit">
            <span slot="prefix">
              <CircularProgress size={20} color="inherit" />
            </span>
          </RailzButton>
        )}
        {!isLoading && (
          <RailzButton
            size="large"
            onClick={handleSubmit(saveChanges)}
            data-testid="submit"
            label={t('DASHBOARD_BRAND_SAVE_CTA')}
          />
        )}
      </div>
    </div>
  );
}
