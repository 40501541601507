import { IntercomProps } from 'react-use-intercom';

import { CustomerState } from 'store/features/account/customer/customer.state';

import { ProfileState } from 'store/features/account/profile/profile.state';
import { BusinessEventStat } from 'store/features/business/business.state';
import { FREE_PLAN_CONNECTION_LIMIT, Plan } from 'types';

export const buildIntercomClientUpdatePayload = (
  profile: ProfileState,
  customer: CustomerState,
  storeStats?: BusinessEventStat,
): IntercomProps => {
  if (!profile?.uuid) return null;

  const payload: IntercomProps = {
    name: `${profile?.firstName} ${profile?.lastName}`,
    userId: profile?.uuid,
    email: profile?.email,
    userHash: profile?.userUuidHmac,
    createdAt: Math.floor(+new Date(profile?.userCreatedAt) / 1000).toString(),
    customAttributes: {
      user_role: profile?.role,
    },
  };

  if (customer?.uuid && customer?.companyName) {
    payload.customAttributes.business_role = customer?.businessRole;
    payload.company = {
      companyId: customer.uuid,
      name: customer?.companyName,
      plan: customer?.billingPlan,
      customAttributes: {
        'Customer Vertical': customer?.apiPurpose,
        'Beta Enabled': customer?.beta,
        website: customer?.website,
      },
    };

    if (storeStats?.businesses && storeStats?.connections) {
      // If on free plan show remaining connections, otherwise active connections
      payload.customAttributes['Active Businesses'] =
        customer.billingPlan === Plan.FREE
          ? FREE_PLAN_CONNECTION_LIMIT - storeStats?.businesses.active
          : storeStats.businesses.active;

      payload.customAttributes['Active Connections'] = storeStats?.connections.summary.active;
      payload.customAttributes['Inactive Connections'] = storeStats?.connections.summary.inactive;
    }
  }

  return payload;
};
