import { ApiResponse, AuthType, AutoDeletePeriods, Role } from '../../../../types';
import { EnableDisableSSORequest } from '../customer/customer.state';

export const PROFILE_INITIAL_STATE: ProfileState = {
  userCreatedAt: '',
  userUuid: '',
  userUuidHmac: '',
  uuid: '',
  email: '',
  changeEmail: '',
  firstName: '',
  lastName: '',
  state: '',
  role: null,
  emailVerified: '',
  profilePic: '',
  lastLogin: '',
  failedLoginCount: 0,
  createdById: 0,
  customerId: 0,
  createdAt: '',
  mfaEnabled: false,
  baseLoading: false,
  ssoEnabled: false,
  loading: false,
  error: null,
  response: null,
  teamId: 0,
  teamUuid: '',
  defaultTeamUuid: '',
  teams: [],
  teamSettings: {},
};
export const PROFILE_STORE_NAME = 'profile';

export class UpdateUserDetails {
  firstName: string;
  lastName: string;
  email: string;
  profilePic: string;
  defaultTeamUuid?: string;
}

export interface EnableMfaRequest {
  token: string;
  secret: string;
}

export interface GeneratedMfaResponse {
  authType?: AuthType;
  otpAuth?: string;
  secret?: string;
}

export interface EnabledMfaResponse {
  recoveryCode?: string;
}

export interface ProfileCompanyAction {
  payload: any;
  type: string;
}

export interface EnableDisableSSOAction {
  payload: EnableDisableSSORequest;
  type: string;
}

export interface UpdateTeamSettingsAction {
  payload: TeamSettings;
  type: string;
}

export interface ProfileResponse extends EnabledMfaResponse, GeneratedMfaResponse, ApiResponse {}

export interface ProfileUserTeams {
  name: string;
  uuid: string;
  role: Role;
}
export interface TeamSettings {
  autoDeleteBusinessPeriod?: AutoDeletePeriods;
}

export interface ProfileState {
  billingPlan?: string;
  uuid: string;
  userCreatedAt: string;
  userUuidHmac: string;
  userUuid: string;
  email: string;
  changeEmail: string;
  firstName: string;
  lastName: string;
  state: string;
  role: Role;
  emailVerified: string;
  profilePic: string;
  lastLogin: string;
  failedLoginCount: number;
  createdById: number;
  customerId: number;
  createdAt: string;
  mfaEnabled: boolean;
  url?: string;
  ssoEnabled?: boolean;
  baseLoading: boolean;
  loading: boolean;
  error: any;
  response: ProfileResponse;
  teamId: number;
  teamUuid: string;
  defaultTeamUuid: string;
  teams: ProfileUserTeams[];
  teamSettings: TeamSettings;
}
