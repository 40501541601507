import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return createStyles({
    copiedText: {
      fontSize: '0.75rem',
    },
    container: {
      wordBreak: 'break-word',
    },
  });
});
