import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    alert: {
      backgroundColor: '#EAF4FC',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      paddingRight: theme.spacing(4),
      boxSizing: 'border-box',
      borderRadius: '5px',
    },

    heading: {
      filter: 'brightness(50%)',
      fontSize: '1rem',
      fontWeight: 500,
      padding: `${theme.spacing(0.5)}px 0`,
    },
  });
});
