import React from 'react';
import { Trans } from 'react-i18next';

/**
 *  This is a helper function to format translations with pre-defined components.
 *  This also allows us to get a translation from any non-react context (like the store)
 */
export function FormattedTranslation({
  i18nKey,
  values,
  components,
}: {
  i18nKey: string;
  values?: Record<string, string | number>;
  components?: Record<string, JSX.Element>;
}): JSX.Element {
  return (
    <Trans
      i18nKey={i18nKey}
      values={values}
      components={{
        bold: <strong />,
        b: <strong />,
        strong: <strong />,
        italic: <em />,
        br: <br />,
        ...components,
      }}
      shouldUnescape={true}
      tOptions={{ interpolation: { escapeValue: true } }}
    />
  );
}
