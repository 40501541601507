import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import useStyle from './style';

import { FormInputPropsWithCopy } from 'types';

const FormInputWithCopy = (props: FormInputPropsWithCopy): React.ReactElement => {
  const classes = useStyle();

  const { t } = useTranslation('dashboard');
  const {
    name,
    label,
    required,
    errorobj,
    control,
    placeholder,
    inputProps,
    testid,
    autoFocus,
    onClick,
  } = props;
  let errorMessage = '';

  if (errorobj && errorobj[name]) {
    errorMessage = errorobj[name].message;
  }

  const n = name.replace('[', '.').replace(']', '').split('.');

  if (n.length > 1 && errorobj && errorobj[n[0]]) {
    if (errorobj[n[0]][n[1]]) {
      const a = errorobj[n[0]][n[1]];
      if (a[n[2]]) errorMessage = errorobj[n[0]][n[1]][n[2]].message;
    }
  }

  return (
    <Controller
      render={({ onChange, onBlur, value, ref }): JSX.Element => (
        <TextField
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          label={label}
          helperText={t(errorMessage)}
          error={!!errorMessage}
          placeholder={t(placeholder)}
          inputRef={ref}
          autoFocus={autoFocus}
          variant="outlined"
          InputLabelProps={{
            required: required || false,
          }}
          FormHelperTextProps={{
            className: classes.errorText,
          }}
          inputProps={{ ...inputProps, 'data-testid': testid }}
          {...props}
          onClick={undefined}
          InputProps={{
            endAdornment: (
              <IconButton className={classes.iconInputUrl} onClick={onClick}>
                <FileCopyIcon />
              </IconButton>
            ),
          }}
        />
      )}
      variant="outlined"
      name={name}
      control={control}
      {...props}
    />
  );
};

export default FormInputWithCopy;
