import type { Integration } from '../../../types';

export const INTEGRATION_STORE_NAME = 'integration';
export const INTEGRATION_INITIAL_STATE: IntegrationState = {
  integrations: [],
  loading: false,
  isActive: null,
  syncConfigs: {
    yearsToSync: '5',
    dataSyncFrequency: 'daily',
    pulledDataTypes: {},
  },
  error: null,
  success: false,
};

export enum CredentialsKind {
  _name = 'CREDENTIALS_KIND',
  SANDBOX = 'sandbox',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export interface IntegrationAction<T = any> {
  type: string;
  payload: T;
}

export interface IntegrationState {
  loading: boolean;
  isActive: boolean;
  integrations: Integration[];
  syncConfigs: SyncDataState;
  error: any;
  success: boolean;
}
export type ItemDataTypeConfigStatus = { syncOnFirstRun: boolean; sync: boolean };

export type PulledDataTypes = {
  [x: string]: ItemDataTypeConfigStatus;
};

export interface SyncDataState {
  yearsToSync?: string;
  dataSyncFrequency?: string;
  pulledDataTypes?: PulledDataTypes;
}
