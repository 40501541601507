import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import { ConnectionStatus } from '../../../../../pages/business/types/enums';

import useStyle from './style';

import { View } from 'components';
import ReportFilter from 'components/report-filter';
import ReportFilterExportButton from 'components/report-filter/export-as-button';
import ReportFilterServices from 'components/report-filter/service';

import {
  Service,
  buildReportDescription,
  generateFullReportAndExport,
  reportAccountingMethods,
} from 'helpers/report-helpers';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import { getSelectedBusiness } from 'store/features/business/business.selector';
import {
  setReportDownloadState,
  setReportSummaryFilter,
} from 'store/features/report/report.action';
import {
  getBusinessSummaryFilter,
  getDataTypes,
  getIsReportExportLoading,
  getReportPaginationAndQuery,
} from 'store/features/report/report.selector';
import {
  AllReportFrequency,
  AspType,
  ExportOption,
  ReportAccountingMethod,
  ReportFinancialStatementType,
  ReportFrequency,
  ReportQuery,
  ReportStatus,
  ReportType,
} from 'types';
import {
  buildDataFromFilterUpdate,
  BusinessReportDefaultFilterFlags,
  DataFromFilter,
} from 'helpers/business-helpers/report-filter-helpers';
import { getDropdownListItem } from 'helpers/common.helper';

const BusinessReportFilter = ({
  applyFilter,
  reportData,
  reportName,
  forcedHideFrequency,
  forcedReportType,
  reportParamsButtonChild,
}: {
  applyFilter?: (query: ReportQuery) => void;
  reportData?: any[];
  reportName?: string;
  forcedHideFrequency?: boolean;
  forcedReportType?: ReportType;
  reportParamsButtonChild?: JSX.Element;
}): JSX.Element => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [selectedConnection, setSelectedConnection] = useState<Service>(null);

  const [selectedAccountingMethod, setSelectedAccountingMethod] = useState<ReportAccountingMethod>(
    ReportAccountingMethod.ACCRUAL,
  );
  const [possibleServices, setPossibleServices] = useState<Service[]>([]);
  const [dateView, setDateView] = useState<AllReportFrequency>(AllReportFrequency.MONTH);
  const [querySearch, setQuerySearch] = useQuerySearchUpdate();
  const [reportFrequency, setReportFrequency] = useState<ReportFrequency>(ReportFrequency.MONTH);

  const [startDate, setStartDate] = useState<Moment>(moment());

  const [selectedStatus, setSelectedStatus] = useState<ReportStatus>(
    (querySearch.get('status') as ReportStatus) || ReportStatus.ALL,
  );
  const [selectedFinancialStatementType, setSelectedFinancialStatementType] =
    useState<ReportFinancialStatementType>(
      (querySearch.get('financialStatementType') as ReportFinancialStatementType) ||
        ReportFinancialStatementType.BALANCE_SHEETS,
    );

  const [endDate, setEndDate] = useState<Moment>(moment().add(1, ReportFrequency.MONTH));
  const [filterSubmitted, setFilterSubmitted] = useState<boolean>(false);
  const [reportTypeMetaFlags, setReportTypeMetaFlags] = useState<DataFromFilter['meta']>(
    BusinessReportDefaultFilterFlags,
  );
  const [bankConnectionId, setBankConnectionId] = useState<string>(null);
  const [allBankConnections, setAllBankConnections] = useState([]);

  const aspList = useSelector(getDataTypes);
  const selectedBusiness = useSelector(getSelectedBusiness);
  const filter = useSelector(getBusinessSummaryFilter);
  const paginationAndQueryData = useSelector(getReportPaginationAndQuery);
  const isExportingAnotherReport = useSelector(getIsReportExportLoading);
  const [reportDescriptionLabel, setReportDescriptionLabel] = useState<string>(null);

  useEffect(() => {
    if (!filter || !selectedBusiness) return;

    if (filterSubmitted) {
      setFilterSubmitted(false);
      return;
    }
    const forcedFilter = { ...filter, reportType: forcedReportType || filter.reportType };
    const data = buildDataFromFilterUpdate(forcedFilter, selectedBusiness, aspList);
    const { connections } = selectedBusiness;
    const plaidConnection = connections.find(({ serviceName }) => serviceName === AspType.PLAID);

    const allPlaidConnections = connections
      .map(({ serviceName, connectionId, institution, status }) => {
        if (serviceName === AspType.PLAID && status !== ConnectionStatus.PENDING)
          return { connectionId, institutionName: institution?.name };
      })
      .filter(Boolean);
    setAllBankConnections(allPlaidConnections);

    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setReportFrequency(data.reportFrequency);
    setDateView(data.dateView as unknown as AllReportFrequency);
    setReportTypeMetaFlags(data.meta);
    setSelectedConnection(data.selectedConnection);
    setPossibleServices(
      data.possibleServices.filter((service) => service.serviceName !== AspType.PLAID),
    );
    setBankConnectionId(plaidConnection?.connectionId);
  }, [filter]);

  const submitFilter = async (
    override: Partial<ReportQuery>,
    context: 'onReady' | 'onSubmit',
  ): Promise<void> => {
    const start = override?.startDate ? moment(override.startDate) : startDate;
    const end = override?.endDate ? moment(override.endDate) : endDate;
    const reportFilter = {
      reportFrequency,
      reportType: forcedReportType || filter.reportType,
      status: selectedStatus,
      financialStatementType: selectedFinancialStatementType,
      startDate: start?.isValid() && moment(start)?.format('YYYY-MM-DD'),
      endDate: end?.isValid() && moment(end)?.format('YYYY-MM-DD'),
      serviceName: (selectedConnection?.serviceName as AspType) || filter.serviceName,
      connectionUuid: selectedConnection?.connectionId || filter.connectionUuid,
      bankConnectionUuid: bankConnectionId,
      allBankConnections,
      accountingMethod: selectedAccountingMethod,
    };

    const queryFilter = { ...filter, ...reportFilter };
    setFilterSubmitted(true);
    dispatch(setReportSummaryFilter(queryFilter));
    setQuerySearch(reportFilter);
    applyFilter && applyFilter(queryFilter);

    const filterIsSubmittedOrFirstLoad = context === 'onSubmit' || !filterSubmitted;
    if (filterIsSubmittedOrFirstLoad) {
      const label = buildReportDescription(
        filter?.reportType,
        selectedFinancialStatementType,
        [ReportType.BANK_TRANSACTIONS, ReportType.BANK_ACCOUNTS, ReportType.BANK_ASSET].includes(
          filter.reportType,
        )
          ? bankConnectionId
          : queryFilter?.connectionUuid,
        selectedBusiness,
      );
      setReportDescriptionLabel(label);
    }
  };

  const handleExportOptions = async ({ type }: ExportOption): Promise<void> => {
    const controller = new AbortController();
    dispatch(setReportDownloadState({ loading: true, controller }));
    await generateFullReportAndExport(
      reportData,
      paginationAndQueryData,
      selectedBusiness.businessName,
      filter,
      type,
      controller,
    );
    dispatch(setReportDownloadState({ loading: false }));
  };

  const handleSelectedConnection = (connection: Service): void => {
    if (!connection) return;
    if (
      [ReportType.BANK_TRANSACTIONS, ReportType.BANK_ACCOUNTS, ReportType.BANK_ASSET].includes(
        filter.reportType,
      )
    ) {
      setBankConnectionId(connection.connectionId);
    } else {
      setSelectedConnection(connection);
    }
  };

  const withServices = Array.isArray(possibleServices) && possibleServices.length > 1;
  const withExportableData = Array.isArray(reportData) && reportData.filter(Boolean).length > 0;
  return (
    <View flexDirection="column" className={classes.filter}>
      <Grid alignItems="flex-start" container spacing={2} direction="row">
        <Grid item md={12} xs={12} sm={12}>
          <ReportFilter
            onReady={(params): Promise<void> => submitFilter(params, 'onReady')}
            onSubmit={(params): Promise<void> => submitFilter(params, 'onSubmit')}
            setSelectedReportFrequency={setReportFrequency}
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            selectedFinancialStatementType={selectedFinancialStatementType}
            setSelectedFinancialStatementType={setSelectedFinancialStatementType}
            setSelectedStartDate={setStartDate}
            setSelectedEndDate={setEndDate}
            selectedConnection={selectedConnection}
            showFrequency={reportTypeMetaFlags.frequencyShown}
            forcedHideFrequency={forcedHideFrequency}
            showStatus={reportTypeMetaFlags.statusShown}
            disableDates={reportTypeMetaFlags.disabledDates}
            showFinancialStatementType={reportTypeMetaFlags.financialStatementTypeShown}
            selectedReportType={forcedReportType || filter.reportType}
            dateView={dateView}
            setDateView={setDateView}
            selectedService={selectedConnection}
            startDate={startDate}
            endDate={endDate}
            maxEndDate={moment()}
            selectedBusiness={selectedBusiness}
            setSelectedConnection={handleSelectedConnection}
            showAccountingMethod={reportTypeMetaFlags.accountingMethodShown}
            selectedAccountingMethod={selectedAccountingMethod}
            setSelectedAccountingMethod={setSelectedAccountingMethod}
          >
            {withServices && (
              <Grid item md xs={12} sm>
                <ReportFilterServices
                  services={possibleServices}
                  selectedService={selectedConnection}
                  handleSelected={setSelectedConnection}
                />
              </Grid>
            )}
          </ReportFilter>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        justifyContent="space-between"
        container
        spacing={1}
        direction="row"
        className={classes.filterInfoWrapper}
      >
        <Grid item className={classes.tabTitle}>
          <span>{reportName || filter.reportName}</span>
          {reportTypeMetaFlags.accountingMethodShown && filter.accountingMethod && (
            <span>
              - {getDropdownListItem(reportAccountingMethods, filter.accountingMethod).name}
            </span>
          )}
        </Grid>

        <Grid item className={classes.exportOptions}>
          {reportTypeMetaFlags.dateRangeIndicatorShown && (
            <span className={classes.date}>
              {moment(startDate).format('MMM DD, YYYY')} -
              {moment(endDate).format('[ ]MMM DD, YYYY')}
            </span>
          )}
          {withExportableData && (
            <ReportFilterExportButton
              onSelect={handleExportOptions}
              disabled={isExportingAnotherReport}
            />
          )}
          {reportParamsButtonChild && reportParamsButtonChild}
        </Grid>
      </Grid>
      {reportDescriptionLabel && (
        <p className={classes.connectionDescription}>{reportDescriptionLabel}</p>
      )}
    </View>
  );
};

export default BusinessReportFilter;
