import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';

import { FormInputProps } from 'types';

const useStyles = makeStyles(() => ({
  inputRoot: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    paddingLeft: '0 !important',
  },
  input: {
    padding: '12px 0 12px 16px !important',
    minHeight: '40px',
    boxSizing: 'border-box',
  },
  option: {
    fontSize: '0.875rem',
    backgroundColor: 'white',
    '&[aria-selected="true"]:not(.MuiAutocomplete-option[data-focus="true"])': {
      backgroundColor: 'transparent',
    },
  },
}));

const EmailSelect = React.forwardRef(
  (props: any, ref: React.RefObject<HTMLElement>): React.ReactElement => {
    const {
      errorobj,
      name,
      getOptionLabel,
      placeholder,
      label,
      testid,
      options,
      getOptionSelected,
    } = props;
    const { ...otherProps } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    let errorMessage = '';

    if (errorobj && errorobj[name]) {
      errorMessage = errorobj[name].message;
    }

    const n = name.replace('[', '.').replace(']', '').split('.');

    if (n.length > 1 && errorobj && errorobj[n[0]]) {
      if (errorobj[n[0]][n[1]]) {
        const a = errorobj[n[0]][n[1]];
        if (a[n[2]]) errorMessage = errorobj[n[0]][n[1]][n[2]].message;
      }
    }

    return (
      <Autocomplete
        {...otherProps}
        options={props.options ? props.options : []}
        classes={{ inputRoot: classes.inputRoot, input: classes.input, option: classes.option }}
        ref={ref}
        defaultValue={options && options.length > 0 && options[0]}
        data-testid={`${testid}-autocomplete`}
        disableClearable
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option: any): string => option.label || ''
        }
        getOptionSelected={
          getOptionSelected
            ? getOptionSelected
            : (option, value): boolean => (option as any).label === (value as any).label
        }
        noOptionsText={null}
        renderInput={(params): React.ReactElement => {
          return (
            <>
              <TextField
                {...params}
                placeholder={t(placeholder)}
                label={t(label)}
                variant="outlined"
                margin="dense"
                id={`${testid}-textfield`}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': testid,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                  type: 'text',
                  'data-lpignore': 'true',
                }}
                error={!!errorMessage}
              />
              {errorMessage && (
                <FormHelperText error={!!errorMessage}>{t(errorMessage)}</FormHelperText>
              )}
            </>
          );
        }}
        onChange={(_, data): void => props.onChange(data)}
      />
    );
  },
);

function FormEmailSelectAutoComplete(parentProps: FormInputProps): React.ReactElement {
  return (
    <Controller
      render={({ onChange, ...props }): JSX.Element => (
        <EmailSelect onChange={onChange} {...props} {...parentProps} />
      )}
      defaultValue=""
      {...parentProps}
    />
  );
}

export default FormEmailSelectAutoComplete;
