import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    deleteButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    deleteButton: {
      display: 'block',
      margin: `${theme.spacing(1.5)}px 0px ${theme.spacing(1)}px`,
    },
    disabledInput: {
      '& label, input, fieldset, label.Mui-focused, .Mui-focused input': {
        color: theme.palette.grey[600],
        borderColor: theme.palette.grey[300],
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
      },
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),

      '& > div': {
        width: 'clamp(240px, 40%, 100%)',
        flexGrow: 1,
      },
    },
    alert: {
      marginTop: theme.spacing(1),
      boxSizing: 'border-box',
      borderRadius: '5px',
    },
  }),
);
