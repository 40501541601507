import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

const MINIMUM_SPACE_NEEDED_FOR_TABS = '520px';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes appear': {
      from: {
        transform: 'translate(-200%)',
        opacity: 0,
      },
      to: {
        transform: 'translate(0)',
        opacity: 1,
      },
    },
    box: {
      borderBottom: 'solid 1px ',
      borderColor: '#D8D8D8 !important',
      '& .MuiTabs-root': {
        height: '2.5rem !important',
        minHeight: '2.5rem !important',
        marginBottom: '-0.125rem',
      },
      '& .MuiTab-root': {
        height: '2.5rem !important',
        minHeight: '2.5rem !important',
      },
      // on xs screen
      [`@media screen and (max-width: ${MINIMUM_SPACE_NEEDED_FOR_TABS})`]: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
      },
    },
    tab: {
      textTransform: 'none',
      minWidth: '2rem !important',
      margin: '0 0.5rem 0 0.5rem',
      padding: '0',
      color: theme.palette.grey[800],
      opacity: 1,
      fontWeight: +theme.typography.fontWeightMedium,
      fontSize: '1rem',
      lineHeight: '1.75rem',
      [`@media screen and (max-width: ${MINIMUM_SPACE_NEEDED_FOR_TABS})`]: {
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    selected: {
      borderColor: 'green',
    },
    tabs: {
      '& .MuiTabs-indicator': {
        backgroundColor: `${ACTIVE_TEXT_COLOR} !important`,
        minWidth: '2rem !important',
        height: '0.25rem',
      },
      '& .Mui-selected': {
        color: `${ACTIVE_TEXT_COLOR} !important`,
        fontWeight: 600,
      },
      '& .MuiTabScrollButton-root': {
        width: theme.spacing(4),
        transition: '0.2s',
        display: 'inline-flex',
        '&:first-child': {
          marginLeft: theme.spacing(-1.25),
        },
        '&:last-child': {
          marginRight: theme.spacing(-1.25),
        },
        '&.Mui-disabled': {
          visibility: 'hidden',
          transition: '0.2s',
        },
      },
    },
  }),
);
