import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    cta: {
      padding: 0,
      width: 'auto',
      fontWeight: 600,
      color: ACTIVE_TEXT_COLOR,
    },
    exportText: {
      marginRight: theme.spacing(),
    },
    menu: {
      marginTop: theme.spacing(6),
    },
    icon: { width: '1rem' },
  }),
);
