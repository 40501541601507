import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    upload: {
      padding: theme.spacing(3),
      height: theme.spacing(30),
      border: '1px dashed #D8D8D8',
      boxSizing: 'border-box',
      borderRadius: '0.188rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.875rem',
      '& span': {
        padding: theme.spacing(1),
      },
    },
    uploadDisabled: {
      opacity: 0.5,
    },
    imagePreview: {
      height: theme.spacing(20),
      maxWidth: '80%',
      objectFit: 'contain',
      margin: 'auto',
      '&.favicon': {
        objectFit: 'contain',
        maxWidth: '56px',
      },
    },
    preview: {
      display: 'flex',
      flexDirection: 'row',
    },
    trashCta: {
      cursor: 'pointer',
      color: 'red',
      fontSize: '1rem',
    },
    container: {
      position: 'relative',
      width: '100%',
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);
