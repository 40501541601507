/* eslint-disable max-len */
export const WEBSITE_REGEX =
  /^(?!\/\/:)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/;
export const WEBSITE_WITH_PROTOCOL_REGEX =
  /^(?:(?:https?|http):\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/;
export const WEBSITE_WITH_OR_WITHOUT_PROTOCOL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

export const EMAIL_INVALID_DOMAINS_REGEX =
  /^.*@(?!\b(gmail|googlemail|yahoo|hotmail|live|me|aol|outlook|protonmail|yopmail|mailinator)\b).*$/;
export const EMAIL_TEAM_INVALID_DOMAINS_REGEX = /^.*@(?!\b(yopmail|mailinator)\b).*$/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,64}$/;

export const PHONE_REGEX = /^$|\W?\d{3}\W{0,2}\d{3}\W*\d{4}$/;
export const ZIP_REGEXES = {
  US: '^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$',
  // eslint-disable-next-line max-len
  CA: '^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$',
};

export const TAX_ID_REGEX = /^\d{9}$/;

export const IP_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
