/* eslint-disable max-len */
import React from 'react';
import { Grid, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyle from '../style';

interface Props {
  disabled: boolean;
  overviewEnabled: boolean;
  setOverviewEnabled: (bool: boolean) => void;
  summaryEnabled: boolean;
  setSummaryEnabled: (bool: boolean) => void;
}

export default function OverviewAndSummaryPageSection({
  disabled,
  overviewEnabled,
  setOverviewEnabled,
  summaryEnabled,
  setSummaryEnabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Grid container className={disabled ? classes.uploadDisabled : null}>
      <Typography className={classes.description}>
        {t('DASHBOARD_SETTINGS_SITES_OVERVIEW_SUMMARY_PAGE_DESCRIPTION')}
      </Typography>
      <Grid container>
        <div className={classes.inputContainer}>
          <div className={classes.toggleContainer}>
            <Typography className={classes.toggleText}>
              {t('DASHBOARD_SETTINGS_SITES_OVERVIEW_TOGGLE')}
            </Typography>
            <Switch
              checked={overviewEnabled}
              data-testid="overview-enabled-switch"
              inputProps={{
                'aria-label': `${t(
                  overviewEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )}`,
              }}
              onChange={(e): void => setOverviewEnabled(e.target.checked)}
              color="primary"
            />
          </div>
          <div className={classes.toggleContainer}>
            <Typography className={classes.toggleText}>
              {t('DASHBOARD_SETTINGS_SITES_SUMMARY_TOGGLE')}
            </Typography>
            <Switch
              checked={summaryEnabled}
              data-testid="summary-enabled-switch"
              inputProps={{
                'aria-label': `${t(
                  summaryEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
                )}`,
              }}
              onChange={(e): void => setSummaryEnabled(e.target.checked)}
              color="primary"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
