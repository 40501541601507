import { createReducer } from '@reduxjs/toolkit';

import { Integration, IntegrationStatus } from '../../../types';

import { INTEGRATION_INITIAL_STATE, INTEGRATION_STORE_NAME } from './integration.state';

import {
  CREATE_INTEGRATION_API,
  CREATE_INTEGRATION_FAILURE_API,
  CREATE_INTEGRATION_SUCCESS_API,
  DELETE_INTEGRATION_API,
  DELETE_INTEGRATION_FAILURE_API,
  DELETE_INTEGRATION_SUCCESS_API,
  FETCH_INTEGRATIONS_API,
  FETCH_INTEGRATIONS_FAILURE_API,
  FETCH_INTEGRATIONS_SUCCESS_API,
  FETCH_SYNC_CONFIGS_API,
  FETCH_SYNC_CONFIGS_FAILURE_API,
  FETCH_SYNC_CONFIGS_SUCCESS_API,
  RESET_INTEGRATIONS_INFORMATION,
  RESET_STORAGE_INTEGRATION,
  RESET_SYNC_CONFIGS_API_RESPONSE,
  UPDATE_INTEGRATIONS_DEFAULT_API,
  UPDATE_INTEGRATIONS_DEFAULT_FAILURE_API,
  UPDATE_INTEGRATIONS_DEFAULT_SUCCESS_API,
  UPDATE_INTEGRATIONS_STATE_API,
  UPDATE_INTEGRATIONS_STATE_FAILURE_API,
  UPDATE_INTEGRATIONS_STATE_SUCCESS_API,
  UPDATE_INTEGRATION_API,
  UPDATE_INTEGRATION_FAILURE_API,
  UPDATE_INTEGRATION_SUCCESS_API,
  UPDATE_SYNC_CONFIGS_API,
  UPDATE_SYNC_CONFIGS_FAILURE_API,
  UPDATE_SYNC_CONFIGS_SUCCESS_API,
} from './integration.action';

export const integrationReducer = createReducer(INTEGRATION_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_INTEGRATION, () => INTEGRATION_INITIAL_STATE)
    .addCase(RESET_INTEGRATIONS_INFORMATION, () => INTEGRATION_INITIAL_STATE)
    .addCase(FETCH_INTEGRATIONS_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(FETCH_INTEGRATIONS_SUCCESS_API, (state, action: any) => ({
      ...state,
      integrations: action.payload,
      isActive: action?.payload?.some(
        (integration) => integration.state === IntegrationStatus.ENABLED,
      ),
      loading: false,
    }))
    .addCase(FETCH_INTEGRATIONS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(FETCH_SYNC_CONFIGS_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(FETCH_SYNC_CONFIGS_SUCCESS_API, (state, action: any) => ({
      ...state,
      syncConfigs: action.payload,
      loading: false,
    }))
    .addCase(FETCH_SYNC_CONFIGS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))
    .addCase(UPDATE_SYNC_CONFIGS_API, (state) => ({
      ...state,
      error: null,
      loading: true,
      success: false,
    }))
    .addCase(RESET_SYNC_CONFIGS_API_RESPONSE, (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
    }))
    .addCase(UPDATE_SYNC_CONFIGS_SUCCESS_API, (state, action: any) => ({
      ...state,
      syncConfigs: action.payload,
      loading: false,
      success: true,
    }))
    .addCase(UPDATE_SYNC_CONFIGS_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
      success: false,
    }))

    .addCase(CREATE_INTEGRATION_API, (state) => ({
      ...state,
      error: null,
      loading: true,
      success: false,
    }))
    .addCase(CREATE_INTEGRATION_SUCCESS_API, (state, action: any) => {
      const { uuid, service, isActive } = action.payload as Integration;
      if (isActive) {
        return {
          ...state,
          integrations: [...state.integrations, action.payload].map((integration) => {
            const isActive =
              integration.service === service ? integration.uuid === uuid : integration.isActive;
            return { ...integration, isActive };
          }),
          loading: false,
          success: true,
        };
      }

      return {
        ...state,
        integrations: [...state.integrations, action.payload],
        loading: false,
        success: true,
      };
    })
    .addCase(CREATE_INTEGRATION_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
      success: false,
    }))

    .addCase(UPDATE_INTEGRATION_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_INTEGRATION_SUCCESS_API, (state, action: any) => ({
      ...state,
      integrations: [
        ...state.integrations.filter(
          (integration: Integration) => integration.uuid !== action.payload.uuid,
        ),
        {
          ...state.integrations.find(
            (integration: Integration) => integration.uuid === action.payload.uuid,
          ),
          ...action.payload,
        },
      ],
      loading: false,
    }))
    .addCase(UPDATE_INTEGRATION_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))

    .addCase(DELETE_INTEGRATION_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_INTEGRATION_SUCCESS_API, (state, action: any) => ({
      ...state,
      integrations: [
        ...state.integrations.filter(
          (integration: Integration) => integration.uuid !== action.payload.uuid,
        ),
        {
          ...state.integrations.find(({ uuid }) => uuid === action.payload.uuid),
          deletedAt: action.payload.deletedAt,
        },
      ],
      loading: false,
    }))
    .addCase(DELETE_INTEGRATION_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))

    .addCase(UPDATE_INTEGRATIONS_STATE_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_INTEGRATIONS_STATE_SUCCESS_API, (state, action: any) => {
      if (action.payload.uuid) {
        return {
          ...state,
          integrations: state.integrations.concat(action.payload),
          loading: false,
        };
      }
      return {
        ...state,
        integrations: state.integrations.map((integration) => {
          if (integration.service !== action.payload.service) return integration;
          return {
            ...integration,
            state:
              integration.service === action.payload.service
                ? action.payload.state
                : integration.state,
          };
        }),
        loading: false,
      };
    })
    .addCase(UPDATE_INTEGRATIONS_STATE_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }))

    .addCase(UPDATE_INTEGRATIONS_DEFAULT_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_INTEGRATIONS_DEFAULT_SUCCESS_API, (state, action: any) => ({
      ...state,
      integrations: state.integrations.map((integration) => {
        const { service, uuid } = action.payload;
        const isActive =
          integration.service === service ? integration.uuid === uuid : integration.isActive;
        return { ...integration, isActive };
      }),
      loading: false,
    }))
    .addCase(UPDATE_INTEGRATIONS_DEFAULT_FAILURE_API, (state, action: any) => ({
      ...state,
      loading: false,
      error: action.payload,
    }));
});

export const integrationReducerConfig = {
  [INTEGRATION_STORE_NAME]: integrationReducer,
};
