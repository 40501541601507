import { ApiResponse, ApiKey, ApiKeyGenerateRequest } from '../../../../types';

import { ErrorResponse } from 'types/interface/error.interface';

export const RESET_API_KEY_ERROR = 'RESET_API_KEY_ERROR';
export const RESET_API_KEY_RESPONSE = 'RESET_API_KEY_RESPONSE';

export const GENERATE_API_KEY = 'GENERATE_API_KEY';
export const GENERATE_API_KEY_SUCCESS = 'GENERATE_API_KEY_SUCCESS';
export const GENERATE_API_KEY_FAILURE = 'GENERATE_API_KEY_FAILURE';

export const FETCH_API_KEYS = 'FETCH_API_KEYS';
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS';
export const FETCH_API_KEYS_FAILURE = 'FETCH_API_KEY_FAILURE';

export const DELETE_API_KEY = 'DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAILURE = 'DELETE_API_KEY_FAILURE';

export const RESET_STORAGE_API_KEY = 'RESET_STORAGE_API_KEY';

export const resetApiKeyStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_API_KEY,
  };
};

export const resetApiKeyError = (): { type: string } => {
  return {
    type: RESET_API_KEY_ERROR,
  };
};

export const resetApiKeyResponse = (): { type: string } => {
  return {
    type: RESET_API_KEY_RESPONSE,
  };
};

export const fetchApiKeys = (): { type: string } => {
  return {
    type: FETCH_API_KEYS,
  };
};

export const fetchApiKeysSuccess = (payload: ApiKey[]): { type: string; payload: ApiKey[] } => {
  return {
    type: FETCH_API_KEYS_SUCCESS,
    payload,
  };
};

export const fetchApiKeysFailure = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: FETCH_API_KEYS_FAILURE,
    payload,
  };
};

export const generateApiKey = (
  payload: ApiKeyGenerateRequest,
): { payload: ApiKeyGenerateRequest; type: string } => {
  return {
    type: GENERATE_API_KEY,
    payload,
  };
};

export const generateApiKeySuccess = (
  payload: ApiResponse & { apiKey },
): { payload: ApiResponse & { apiKey }; type: string } => {
  return {
    type: GENERATE_API_KEY_SUCCESS,
    payload,
  };
};

export const generateApiKeyFailure = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: GENERATE_API_KEY_FAILURE,
    payload,
  };
};

export const deleteApiKey = (payload: {
  identifier: string;
  name: string;
}): { type: string; payload: { identifier: string; name: string } } => {
  return {
    type: DELETE_API_KEY,
    payload,
  };
};

export const deleteApiKeySuccess = (
  payload: ApiResponse & { name: string },
): { type: string; payload: ApiResponse & { name: string } } => {
  return {
    type: DELETE_API_KEY_SUCCESS,
    payload,
  };
};

export const deleteApiKeyFailure = (
  payload: ErrorResponse,
): { payload: ErrorResponse; type: string } => {
  return {
    type: DELETE_API_KEY_FAILURE,
    payload,
  };
};
