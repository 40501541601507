import axios, { AxiosResponse } from 'axios';

import { SyncDataState } from './integration.state';

import type {
  AspType,
  Integration,
  IntegrationCreationData,
  IntegrationPostResponse,
  IntegrationUpdateData,
} from 'types';

import Config from 'config';

class IntegrationService {
  async getIntegration(): Promise<AxiosResponse<Integration[]>> {
    return await axios.get<Integration[]>(`${Config.SERVER_URL}/integrations/v2?showDeleted=true`);
  }
  async getSyncConfigs(): Promise<AxiosResponse<SyncDataState>> {
    return await axios.get<SyncDataState>(`${Config.FEEDER_SERVER}/data/syncConfigs`);
  }
  async putSyncConfigs(payload: SyncDataState): Promise<AxiosResponse<SyncDataState>> {
    return await axios.put<SyncDataState>(`${Config.FEEDER_SERVER}/data/syncConfigs`, payload);
  }

  async createIntegration(
    payload: IntegrationCreationData,
  ): Promise<AxiosResponse<IntegrationPostResponse>> {
    return await axios.post(`${Config.SERVER_URL}/integrations`, payload);
  }

  async updateIntegration(
    payload: IntegrationUpdateData,
  ): Promise<AxiosResponse<{ success: boolean }>> {
    const { uuid, ...rest } = payload;
    return await axios.patch(`${Config.SERVER_URL}/integrations/${uuid}`, rest);
  }

  async updateIntegrationStatus(
    payload: Pick<IntegrationUpdateData, 'service' | 'state'>,
  ): Promise<AxiosResponse<{ success: boolean; uuid?: string }>> {
    return await axios.put(`${Config.SERVER_URL}/integrations/${payload.service}`, {
      state: payload.state,
    });
  }

  async updateIntegrationActiveIntegration(
    uuid: IntegrationUpdateData['uuid'],
  ): Promise<AxiosResponse<{ success: boolean }>> {
    return await axios.put(`${Config.SERVER_URL}/integrations/active/${uuid}`);
  }

  async deleteIntegration(
    uuid: IntegrationUpdateData['uuid'],
  ): Promise<AxiosResponse<{ deletedAt: string; service: AspType; uuid: string }>> {
    return await axios.delete(`${Config.SERVER_URL}/integrations/${uuid}`);
  }
}

export default new IntegrationService();
