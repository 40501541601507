export enum BusinessStatus {
  NEW = 'new',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ActionTypes {
  MANAGE_SERVICES = 'manageServices',
  GENERATE_LINK = 'generateLink',
  ADD = 'add',
  RENAME = 'rename',
  DELETE = 'delete',
}

export enum AllBusinessStatus {
  _name = 'BUSINESS_TABLE_STATUS',
  ALL = 'all',
  ACTIVE = 'active',
  NEW = 'new',
  INACTIVE = 'inactive',
}

export enum ConnectionStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
  INVALID = 'invalid',
}

export const ACTIVE_STATES = [BusinessStatus.ACTIVE, BusinessStatus.NEW];
