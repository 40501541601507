import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import useStyle from '../style';

import { FormInput, FormMultiSelect } from 'components/form';
import { formChange, showSnackbar } from 'helpers/common.helper';
import AlertDialog from 'components/dialog';
import { SPECIAL_CHARS, TEAMS_PURPOSE } from 'types';
import { getTeamsApiResponse } from 'store/features/account/team/team.selector';

interface FormProp {
  team;
  onSubmit;
  onClose?;
  isLoading;
  errorsMessage;
}

const TeamForm = ({
  onSubmit,
  errorsMessage = [],
  onClose,
  isLoading,
  team,
}: FormProp): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { teams } = useSelector(getTeamsApiResponse);
  const editMode = team?.uuid;
  const validationSchema = yup.object().shape({
    teamName: yup
      .string()
      .nullable()
      .required('DASHBOARD_TEAM_NAME_REQUIRED')
      .trim()
      .min(2, 'DASHBOARD_TEAMS_CREATE_TEAM_CRUD_MIN')
      .max(50, 'DASHBOARD_TEAMS_CREATE_TEAM_CRUD_MAX')
      .test({
        message: t('DASHBOARD_TEAMS_CREATE_TEAM_CRUD_DUPLICATE'),
        test: (value) => {
          const isDuplicateName = !team
            ? teams?.some(
                ({ teamName, uuid }) =>
                  value?.toLocaleLowerCase() === teamName.toLowerCase() && uuid != team?.uuid,
              )
            : false;
          return !isDuplicateName;
        },
      })
      .test({
        message: t('DASHBOARD_TEAMS_CREATE_TEAM_CRUD_RESTRICTIONS'),
        test: (value) => !SPECIAL_CHARS.some((char) => value.includes(char)),
      }),
    description: yup
      .string()
      .max(140, 'DASHBOARD_TEAMS_CREATE_TEAM_DESCRIPTION_CRUD_MAX')
      .nullable(),
    purpose: yup.array().of(yup.string()).nullable(),
  });

  const { handleSubmit, errors, control, formState, setError, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { teamName: '', description: '', purpose: [], ...team },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (errorsMessage.length) {
      errorsMessage.forEach(({ key, message }) => {
        setError(key, { message });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsMessage]);

  const { isDirty } = formState;

  const onSubmitData = (values): void => {
    const currentTeam = { teamName: '', description: '', purpose: [], ...team };
    if (formChange(values, currentTeam)) {
      const payload = editMode ? { ...values, uuid: team?.uuid } : values;
      if (!isLoading) {
        onSubmit(payload);
      }
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }
  };

  const handleDelete = (reason: string): void => {
    const updatedPurpose = getValues('purpose')?.filter((key) => String(key) !== reason);
    setValue('purpose', updatedPurpose, { shouldValidate: true });
  };

  const handleClear = (): void => {
    setValue('purpose', [], { shouldValidate: true });
  };

  return (
    <AlertDialog
      isOpen={true}
      title={t(editMode ? 'DASHBOARD_TEAMS_EDIT_MODAL' : 'DASHBOARD_TEAMS_CREATE_TEAM_MODAL')}
      cancel={
        isDirty && {
          label: t('DASHBOARD_TEAM_ADD_CTA_CANCEL'),
          onClick: onClose,
          'data-testid': 'alert-cancel-button',
          type: 'gray',
        }
      }
      confirm={{
        label: editMode
          ? t('DASHBOARD_TEAMS_EDIT_MODAL_SAVE_CTA')
          : t('DASHBOARD_TEAMS_CREATE_TEAM_MODAL_CREATE_CTA'),
        onClick: handleSubmit(onSubmitData),
        'data-testid': 'alert-confirm-button',
      }}
      onClose={onClose}
      showCloseButton
      isLoading={isLoading}
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitData)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <FormInput
              defaultValue=""
              label={
                editMode
                  ? 'DASHBOARD_TEAMS_EDIT_MODAL_NAME'
                  : 'DASHBOARD_TEAMS_CREATE_TEAM_MODAL_NAME'
              }
              variant="outlined"
              fullWidth
              name="teamName"
              errorobj={errors}
              control={control}
              testid="team-name-input"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <FormInput
              defaultValue=""
              label={
                editMode
                  ? 'DASHBOARD_TEAMS_EDIT_MODAL_DESCRIPTION'
                  : 'DASHBOARD_TEAMS_CREATE_TEAM_MODAL_DESCRIPTION'
              }
              fullWidth
              name="description"
              errorobj={errors}
              control={control}
              testid="description-input"
              {...{ multiline: true, rows: 4 }}
            />
          </Grid>
          {!editMode && (
            <Grid item xs={12} md={12} className={classes.reasonsSection}>
              <Typography className={classes.reasonsLabel} variant="subtitle2" paragraph={true}>
                {t('DASHBOARD_TEAMS_CREATE_TEAM_MODAL_REASON')}
              </Typography>
              <div className={classes.reasonsSelect}>
                <FormMultiSelect
                  placeholder="DASHBOARD_TEAMS_CREATE_TEAM_MODAL_SELECT_REASONS_PLACEHOLDER"
                  label="DASHBOARD_TEAMS_CREATE_TEAM_MODAL_SELECT_REASONS"
                  fullWidth
                  id="purpose"
                  name="purpose"
                  errorobj={errors}
                  control={control}
                  options={TEAMS_PURPOSE(t)}
                  clearAll={handleClear}
                  handleDelete={handleDelete}
                  testid="purpose-input"
                />
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </AlertDialog>
  );
};

export default TeamForm;
