import React from 'react';

const VerticalLine = (): any => {
  return (
    <svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0V23.6973" stroke="#757575" />
    </svg>
  );
};

export default VerticalLine;
