import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getBusinessHistoryData } from '../../../../../store/features/report/report.selector';

import useStyle from './style';

import { getSelectedBusiness } from 'store/features/business/business.selector';
import BasicTable from 'components/table';
import { fetchBusinessHistoryApi } from 'store/features/report/report.action';
import {
  BusinessHistoryData,
  BusinessHistoryDataFormatted,
} from 'store/features/report/report.state';
import ErrorIcon from 'components/error/error-icon';
import { formatDate } from 'helpers/format.helper';
import { getConnectionServiceName } from 'helpers/common.helper';

const BusinessHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const selectedBusiness = useSelector(getSelectedBusiness);
  const { history: historyData, error } = useSelector(getBusinessHistoryData);

  const historyIsLoading = useSelector(getBusinessHistoryData).loading;

  const getBusinessHistory = (): void => {
    if (selectedBusiness?.businessUuid) {
      dispatch(
        fetchBusinessHistoryApi({
          params: { uuid: selectedBusiness?.businessUuid },
        }),
      );
    }
  };

  useEffect(() => {
    if (selectedBusiness?.businessName) {
      getBusinessHistory();
    }
  }, [selectedBusiness?.businessName]);

  useEffect(() => {
    const rowsFormatted = formatRows(historyData?.businessConnectionHistory);
    setRows(rowsFormatted);
  }, [historyData]);

  const columns = [
    {
      key: 'event',
      name: t('DASHBOARD_BUSINESS_CONNECTION_HISTORY_TABLE_EVENT'),
      className: classes.firstColumn,
    },
    {
      key: 'service',
      name: t('DASHBOARD_BUSINESS_CONNECTION_HISTORY_TABLE_SERVICE'),
    },
    {
      key: 'timeStamp',
      name: t('DASHBOARD_BUSINESS_CONNECTION_HISTORY_TABLE_TIME_STAMP'),
      className: classes.lastColumn,
    },
  ];

  const format = 'D MMM YYYY h:mm:ss A';

  const formatRows = (rowsNotFormatted: BusinessHistoryData[]): BusinessHistoryDataFormatted[] => {
    if (!rowsNotFormatted || !Array.isArray(rowsNotFormatted)) return [];
    return rowsNotFormatted.map((row) => {
      return {
        event: row?.event
          ? t(`DASHBOARD_BUSINESS_CONNECTION_HISTORY_TABLE_EVENT_${row?.event.toUpperCase()}`)
          : '-',
        service: row?.metadata?.serviceName
          ? getConnectionServiceName(row?.metadata as { serviceName: string; isMockData?: boolean })
          : '-',
        timeStamp: formatDate(row?.timestamp, format),
      };
    });
  };

  const errorLoading = (
    <Box className={classes.errorTableContainer} data-testid="error-table">
      <ErrorIcon statusCode={500} />
      <Typography color="textSecondary" className={classes.description}>
        {t('DASHBOARD_FINANCIAL_SUMMARY_CHART_ERROR_500')}
      </Typography>
    </Box>
  );

  const noDataFound = (
    <span className={classes.noDataText}>
      {t('DASHBOARD_BUSINESS_CONNECTION_HISTORY_NOT_DATA_FOUND')}
    </span>
  );

  return (
    <Box>
      <p className={classes.tabBusinessTitle}>{t('DASHBOARD_BUSINESS_CONNECTION_HISTORY_TITLE')}</p>
      <p className={classes.lastSyncText}>
        {t('DASHBOARD_BUSINESS_CONNECTION_HISTORY_LAST_SYNC')}{' '}
        <span className={classes.lastSyncValue}>
          {historyData?.lastSyncDate ? formatDate(historyData?.lastSyncDate, format) : '-'}
        </span>
      </p>
      <>
        <BasicTable
          className={classes.table}
          stickyHeader
          isLoading={historyIsLoading}
          columns={columns}
          rows={rows}
          size="small"
          isPaperComponent={false}
          noData={noDataFound}
          errorLoading={error && error[0] !== 'Connection not found' ? errorLoading : null}
        />
      </>
    </Box>
  );
};

export default BusinessHistory;
