import { Purpose } from 'store/features/account/team/team.state';

export * from './integration.constants';

export const FREE_PLAN_CONNECTION_LIMIT = 5;
export const PAGINATION_LIMIT = 25;
export const TEAMS_DROPDOWN_PAGINATION_LIMIT = 100;

export const EXPIRES_IN = 1000 * 60 * 59;
export const SPECIAL_CHARS = ['%', '[', ']', '\\', '*', '|', '(', ')', '{', '}', '"'];
export const ALL_SPECIAL_CHARS = [
  '%',
  '[',
  ']',
  '\\',
  '*',
  '|',
  '(',
  ')',
  '{',
  '}',
  '"',
  '!',
  '@',
  '#',
  '$',
  '^',
  '&',
  '+',
  '=',
  ';',
  ':',
  "'",
  '<',
  '>',
  '?',
  '/',
  '.',
  ',',
  '`',
  '~',
  '^',
];

export const TEAMS_PURPOSE = (t: (key: string) => string): any[] => [
  { value: Purpose.USE_CASES, name: t('DASHBOARD_TEAMS_PURPOSE_USE_CASES') },
  { value: Purpose.DIFF_PRODUCTS, name: t('DASHBOARD_TEAMS_PURPOSE_PRODUCTS') },
  { value: Purpose.PROD_ENVIRONMENT, name: t('DASHBOARD_TEAMS_PURPOSE_ENVIRONMENT') },
  { value: Purpose.SEPARATE_APPLICATIONS, name: t('DASHBOARD_TEAMS_PURPOSE_SEPARATE_APP') },
  { value: Purpose.SEPARATE_TEAMS, name: t('DASHBOARD_TEAMS_PURPOSE_SEPARATE_TEAMS') },
  { value: Purpose.OTHER, name: t('DASHBOARD_TEAMS_PURPOSE_OTHER') },
];

/**
 * All the possible scopes names. Copied from the backend
 */
const ENDPOINT_SCOPES = {
  SDK: 'SDK',
  API: 'API',
} as const;

const buildReadOnlyAccessLevels = <T extends typeof ENDPOINT_SCOPES[keyof typeof ENDPOINT_SCOPES]>(
  scope: T,
): { READ: `${T}:READ` } => ({ READ: `${scope}:READ` } as const);

const buildReadAndWriteAccessLevels = <
  T extends typeof ENDPOINT_SCOPES[keyof typeof ENDPOINT_SCOPES],
>(
  scope: T,
): { READ: `${T}:READ`; WRITE: `${T}:WRITE` } =>
  ({ READ: `${scope}:READ`, WRITE: `${scope}:WRITE` } as const);

/**
 * All the possible scopes. Copied from the backend.
 */
export const SCOPES = {
  [ENDPOINT_SCOPES.SDK]: buildReadOnlyAccessLevels(ENDPOINT_SCOPES.SDK),
  [ENDPOINT_SCOPES.API]: buildReadAndWriteAccessLevels(ENDPOINT_SCOPES.API),
};

export const SelectableScopes = {
  [ENDPOINT_SCOPES.SDK]: [SCOPES.SDK.READ],
  [ENDPOINT_SCOPES.API]: [SCOPES.API.READ, SCOPES.API.WRITE],
};

export const TranslationLabels = {
  SDK: 'DASHBOARD_API_KEY_MODAL_VISUALIZATIONS_SDK',
  API: 'DASHBOARD_API_KEY_MODAL_RAILZ_API',
};

export const WEBHOOK_STATE = {
  success: 'Success',
  error: 'Fail',
};

export enum ENVIRONMENT_TYPES {
  PROD = 'production',
  QA2 = 'qa2',
  QA1 = 'qa1',
  DEVELOPMENT = 'development',
}
