import React, { ChangeEventHandler, useEffect, useState } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { reportAccountingMethods } from '../../../helpers/report-helpers/constants';
import { ReportAccountingMethod } from '../../../types';

export default function AccountingMethod({
  selectedAccountingMethod,
  handleSelected,
}: {
  selectedAccountingMethod: ReportAccountingMethod;
  handleSelected: (selected: ReportAccountingMethod) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [selectedAccMethod, setselectedAccMethod] = useState(selectedAccountingMethod);

  useEffect(() => {
    setselectedAccMethod(selectedAccountingMethod);
  }, [selectedAccountingMethod]);

  const onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event): void => {
    const value = event?.target?.value as ReportAccountingMethod;
    if (!value) return;
    setselectedAccMethod(value);
    handleSelected && handleSelected(value);
  };

  return (
    <TextField
      fullWidth
      label={t('DASHBOARD_REPORT_ACCOUNTING_METHOD_LABEL')}
      placeholder={t('DASHBOARD_REPORT_REPORT_TYPE_PLACEHOLDER')}
      margin="normal"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      select
      value={selectedAccMethod}
      onChange={onChange}
      size="small"
      inputProps={{ 'data-testid': 'accmethod-input' }}
      data-testid="accmethod-input-root"
    >
      {reportAccountingMethods.map((item, index) => (
        <MenuItem key={index} value={item.value} data-testid={`item-${item.value}`}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
