import Moment from 'moment-timezone';
import { isEmpty } from 'lodash';

import { ReportStatus, ReportType, ReportAccountingMethod, ReportQuery } from '../../types';

import { ReportParam } from '../../store/features/report/report.state';

import { REPORT_PAGINATION_LIMIT } from './constants';
import { ReportApiParam } from './types';
import { disableAccountingMethodAsp, noFrequency } from './utils';

export function getReportApiParams({
  serviceName,
  connectionUuid,
  reportType,
  reportFrequency,
  startDate,
  endDate,
  status,
  financialStatementType,
  accountingMethod = ReportAccountingMethod.ACCRUAL,
}: ReportQuery): ReportApiParam {
  const defaultParam = { connectionUuid };
  const dateParam = {
    startDate: Moment(startDate).format('YYYY-MM-DD'),
    endDate: Moment(endDate).format('YYYY-MM-DD'),
  };
  const frequency = noFrequency(reportType) ? undefined : { reportFrequency };
  switch (reportType) {
    case ReportType.INVOICES:
    case ReportType.INVOICE_CREDIT_NOTES:
    case ReportType.BILL_CREDIT_NOTES:
    case ReportType.BILLS:
    case ReportType.DEPOSITS:
    case ReportType.ESTIMATES:
    case ReportType.PURCHASE_ORDERS:
      return {
        ...defaultParam,
        ...dateParam,
        ...(status !== ReportStatus.ALL && { status }),
      };
    case ReportType.BILL_PAYMENTS:
    case ReportType.BILL_PAYMENT_REQUEST:
    case ReportType.BUSINESS_VALUATIONS:
    case ReportType.INVOICE_PAYMENTS:
    case ReportType.JOURNAL_ENTRIES:
    case ReportType.REFUNDS:
    case ReportType.PROBABILITY_OF_DEFAULT:
    case ReportType.BANKING_RECONCILIATION:
    case ReportType.PRODUCTS:
    case ReportType.DISPUTES:
      return {
        ...defaultParam,
        ...dateParam,
      };
    case ReportType.ACCOUNTS:
    case ReportType.BUSINESS_INFO:
    case ReportType.CUSTOMERS:
    case ReportType.INVENTORY:
    case ReportType.TAX_RATES:
    case ReportType.VENDORS:
    case ReportType.EMPLOYEES:
    case ReportType.TRACKING_CATEGORIES:
    case ReportType.BANK_ACCOUNTS:
    case ReportType.ACCOUNTING_BANK_ACCOUNTS:
      return defaultParam;
    case ReportType.FINANCIAL_FORECASTS:
      return {
        ...defaultParam,
        ...dateParam,
        ...frequency,
        financialStatementType,
      };
    case ReportType.BALANCE_SHEETS:
    case ReportType.TRIAL_BALANCES:
    case ReportType.INCOME_STATEMENTS:
      return {
        ...defaultParam,
        ...dateParam,
        ...frequency,
        ...(!disableAccountingMethodAsp(serviceName) && {
          accountingMethod,
        }),
      };
    case ReportType.PAYMENT_METHODS:
    case ReportType.ATTACHMENTS:
    case ReportType.BANK_ASSET:
      return defaultParam;
    default:
      return {
        ...defaultParam,
        ...dateParam,
        ...frequency,
      };
  }
}

export function buildReportServiceParams({
  localOrder,
  localSortBy,
  localOffset,
  query,
}: {
  localOrder: string;
  localSortBy: string;
  query: ReportQuery;
  localOffset: number;
}): ReportParam {
  if (isEmpty(query.reportType)) return;
  const paramsByReportType = getReportApiParams(query);

  const reportParams = {
    type: query.reportType,
    params: {
      ...paramsByReportType,
      offset: localOffset.toString(),
      limit: REPORT_PAGINATION_LIMIT,
    },
  };

  if (!isEmpty(localOrder) && !isEmpty(localSortBy)) {
    reportParams.params.orderBy = (localOrder === 'asc' ? '' : '-') + localSortBy;
  }
  return reportParams;
}
