import numbro from 'numbro';
import Moment from 'moment-timezone';
import { isNil } from 'lodash';

import { WebhookDateRange } from 'types';

export const formatNumber = (number: number, decimals = 2): string => {
  if (!isNil(number)) {
    return numbro(Number(number)).format(`0,000.${'0'.repeat(decimals)}`);
  }
  return '';
};

export const formatPercentage = (number: number, decimals = 2): string => {
  if (!isNil(number)) {
    const percentile = number * 100;
    const percentage = formatNumber(percentile, decimals);
    return percentage + '%';
  }
};

export const formatDate = (date: string | number, format: string): string => {
  return Moment(date).format(format);
};

/**
 * To get date range between today and past date
 * @param howManyMonthsBack how many months back the start date should be
 * @param howManyDaysBack how many days back the start date should be
 * @param howManyHoursBack how many hours back the start date should be
 * @param adjustStartAndEndTime adjust time for start and end of day
 * @returns { Object<{startDate: string; endDate: string}> } Resulting start and end date
 */
export const getDateRange = (
  howManyMonthsBack = 0,
  howManyDaysBack = 0,
  howManyHoursBack = 0,
  adjustStartAndEndTime = false,
): WebhookDateRange => {
  const today = Moment(new Date());

  if (adjustStartAndEndTime) today.startOf('day'); // Adjusting time to 00:00:00

  const startDate = Moment(today);

  startDate.subtract({
    months: howManyMonthsBack,
    days: howManyDaysBack,
    hours: howManyHoursBack,
  });

  const endDate = Moment(today);

  if (adjustStartAndEndTime) endDate.endOf('day'); // Adjust time to 23:59:59.999

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};
