import axios from 'axios';

import {
  AddTeamV2,
  AddOrUpdateTeamMemberV2,
  InviteTeamErrorResponse,
  AddOrUpdateTeamMemberResponse,
  UpdateMemberRole,
  TeamsFilter,
} from './team.state';

import {
  AddTeamV2Response,
  ResendTeamMemberInvitationResponse,
  SuccessResponse,
  TeamMembersResponse,
  TeamsResponse,
  CustomerEmailId,
  TeamUserResponse,
  UpdateTeamV2Response,
} from 'types';

import Config from 'config';

class TeamService {
  async getTeamMembers(params?: TeamsFilter | string): Promise<TeamMembersResponse> {
    if (typeof params === 'string')
      return await axios.get(`${Config.SERVER_URL}/customers/team?${params}`);

    return await axios.get(`${Config.SERVER_URL}/customers/team`, { params });
  }

  async getTeams(params?: TeamsFilter | string): Promise<TeamsResponse> {
    if (typeof params === 'string') return await axios.get(`${Config.SERVER_URL}/teams${params}`);

    return await axios.get(`${Config.SERVER_URL}/teams`, { params });
  }

  async getTeamV2(payload): Promise<Array<TeamUserResponse>> {
    const params = {
      limit: payload?.limit,
      offset: payload?.offset,
    };

    return await axios.get(`${Config.SERVER_URL}/teams/team/${payload.teamUuid}`, {
      params,
    });
  }

  async addTeamV2(payload: AddTeamV2): Promise<AddTeamV2Response> {
    return await axios.post(`${Config.SERVER_URL}/teams/team`, payload);
  }

  async updateTeamV2(payload: AddTeamV2): Promise<UpdateTeamV2Response> {
    return await axios.put(`${Config.SERVER_URL}/teams/team`, payload);
  }

  async addOrUpdateTeamMemberV2(
    payload: AddOrUpdateTeamMemberV2,
  ): Promise<AddOrUpdateTeamMemberResponse | InviteTeamErrorResponse> {
    return await axios.post(`${Config.SERVER_URL}/teams/invite`, payload);
  }

  async updateTeamMembersRoleV2(payload: UpdateMemberRole): Promise<SuccessResponse> {
    return await axios.put(`${Config.SERVER_URL}/teams/member`, payload);
  }

  async deleteTeamMemberFromTeam(teamId: string, userId: string): Promise<CustomerEmailId> {
    return await axios.delete(`${Config.SERVER_URL}/teams/${teamId}/team/${userId}`);
  }

  async deleteTeamMemberFromAllTeams(userId: string): Promise<CustomerEmailId> {
    return await axios.delete(`${Config.SERVER_URL}/teams/memberships/${userId}`);
  }

  async deleteTeam(id: string): Promise<any> {
    return await axios.delete(`${Config.SERVER_URL}/teams/${id}`);
  }

  async resendTeamMemberInvitation(email: string): Promise<ResendTeamMemberInvitationResponse> {
    return await axios.post(`${Config.SERVER_URL}/teams/invite/resend`, { email });
  }
}

export default new TeamService();
