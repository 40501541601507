import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    backupCodeContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    code: {
      fontSize: theme.spacing(3),
      height: 'fit-content',
      alignSelf: 'center',
    },
    copiedText: {
      fontSize: '0.75rem',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'block',
    },
  });
});
