import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { MouseEventHandler } from 'styled-jsx/node_modules/@types/react';

import useStyle from '../style';

import { BusinessWithQuery } from '../types/interfaces';

import BusinessRow from './business-row';
import BusinessHeaders from './business-header';

import { OnRowClick } from 'types/interface/table.interface';

interface Props {
  handleSort: (key: string) => MouseEventHandler<HTMLSpanElement>;
  columnsHeader: any;
  sort: {
    order: string;
    orderBy: any;
  };
  isLoading: boolean;
  business: BusinessWithQuery[];
  updateBusiness?: () => void;
  onRowClick?: OnRowClick;
}

const BusinessTable: React.FC<Props> = ({
  handleSort,
  columnsHeader,
  sort,
  isLoading,
  business,
  updateBusiness,
  onRowClick = null,
}: Props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { order, orderBy } = sort;

  return (
    <TableContainer classes={{ root: classes.tableRoot }}>
      <Table size="small" stickyHeader>
        <TableHead className={classes.tableHead}>
          <BusinessHeaders
            handleSort={handleSort}
            columnsHeader={columnsHeader}
            orderBy={orderBy}
            order={order}
            classes={classes}
          />
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          <BusinessRow
            business={business}
            isLoading={isLoading}
            updateBusiness={updateBusiness}
            onRowClick={onRowClick}
          />
          {!business?.length && !isLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center" className={classes.noData}>
                <Typography>{t('DASHBOARD_BUSINESS_NO_RESULTS')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(BusinessTable);
