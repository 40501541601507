import React, { useState } from 'react';
import { FormGroup, FormHelperText, FormLabel, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import {
  MAX_WEBHOOKS_PER_EVENT,
  getEventUsageStats,
  getWebhookEvents,
  getWebhookRequestTypesCountLabel,
} from '../webhook.utils';

import style from './style';

import { View } from 'components';
import { FormCheckBox, FormInput } from 'components/form';
import { WEBSITE_WITH_PROTOCOL_REGEX } from 'helpers/regex.helper';
import AlertDialog from 'components/dialog';

import { FormGeneralProps, ServiceTypeIndex, Webhook, WebhookEvent } from 'types';
import { formChange, showSnackbar } from 'helpers/common.helper';
import {
  createWebhookApi,
  updateWebhookApi,
} from 'store/features/developer/webhook/webhook.action';
import { AllDataTypes, ItemDataType } from 'pages/data-sync/types/data-sync';
import Accordion from 'components/accordion/accordion';
import { groupAndSortDataTypesByServiceType } from 'helpers/data-sync/utils';

const defaultValue = { url: '', event: [] };

interface IFormWebhooksProps extends FormGeneralProps {
  otherWebhooks: Webhook[];
  dataTypes: AllDataTypes;
  webhook?: Webhook;
  onClose: () => void;
  testid?: string;
  sandbox?: boolean;
}

type WebhookFormValues = Omit<Webhook, 'event' | 'requestTypes'> & {
  event?: Record<string, boolean>;
  requestTypes?: Record<string, boolean>;
};

const dataPerTypes = [
  {
    label: 'DASHBOARD_ENUM_WEBHOOK_KIND_DATAPERTYPE_SUBCATEGORY_ACCOUNTING',
    value: ServiceTypeIndex[ServiceTypeIndex.ACCOUNTING].toString().toLocaleLowerCase(),
    values: [],
  },
  {
    label: 'DASHBOARD_ENUM_WEBHOOK_KIND_DATAPERTYPE_SUBCATEGORY_BANKING',
    value: ServiceTypeIndex[ServiceTypeIndex.BANKING].toString().toLocaleLowerCase(),
    values: [],
  },
  {
    label: 'DASHBOARD_ENUM_WEBHOOK_KIND_DATAPERTYPE_SUBCATEGORY_COMMERCE',
    value: ServiceTypeIndex[ServiceTypeIndex.COMMERCE].toString().toLocaleLowerCase(),
    values: [],
  },
  {
    label: 'DASHBOARD_ENUM_WEBHOOK_KIND_DATAPERTYPE_SUBCATEGORY_ANALYTICS',
    value: ServiceTypeIndex[ServiceTypeIndex.ANALYTICS].toString().toLocaleLowerCase(),
    values: [],
  },
] as { label: string; value: string; values: ItemDataType[] }[];

/**
 * Merges the dataPerTypes with the dataTypes coming
 * from the API and the general dataTypes mapping object
 */
const dataTypesPerServiceType = (dataTypes: AllDataTypes): typeof dataPerTypes => {
  const grouping = groupAndSortDataTypesByServiceType(dataTypes);
  return dataPerTypes.map((item) => ({
    ...item,
    values: Array.isArray(grouping[item.value]) ? grouping[item.value] : [],
  }));
};

/**
 *  Converts the dataPerTypes object into the form values structure (for the requestTypes property)
 */
const dataTypesPerServiceTypeToFormValues = (
  groupedDataTypes: typeof dataPerTypes,
  webhook: Webhook,
): Record<string, boolean> => {
  return groupedDataTypes.reduce((acc, { values }) => {
    Array.isArray(values) &&
      values.forEach(({ key }) => {
        acc[key] = webhook?.requestTypes.includes(key);
      });
    return acc;
  }, {});
};

/**
 * Takes the webhook object coming from the API to be edited in the form,
 * and prepares it to be used in the form
 */
const prepareWebhookForForm = (
  webhook: Webhook,
  webhookEvents: Array<{ value; name }>,
  dataTypes: AllDataTypes,
): WebhookFormValues => {
  const event = webhook?.event
    ? webhookEvents.reduce((acc, { value }) => {
        acc[value] = webhook.event.includes(value);
        return acc;
      }, {})
    : {};
  const requestTypes = dataTypesPerServiceTypeToFormValues(
    dataTypesPerServiceType(dataTypes),
    webhook,
  );

  return {
    ...webhook,
    event,
    url: webhook?.url || '',
    requestTypes,
  };
};

/**
 * Prepares the webhook object coming from the form to be submitted to the API
 */
const prepareWebhookForSubmit = (formValues: WebhookFormValues): Webhook => {
  return {
    ...formValues,
    url: formValues.url.trim(),
    event: Object.entries(formValues.event)
      .filter(([, value]) => value)
      .map(([name]) => name) as WebhookEvent[],
    requestTypes: Object.entries(formValues.requestTypes).reduce((acc, [key, value]) => {
      value && acc.push(key);
      return acc;
    }, [] as string[]),
  };
};

export const WebhookForm = ({
  webhook = null,
  onClose = (): void => null,
  otherWebhooks = [],
  dataTypes,
  sandbox = false,
}: IFormWebhooksProps): React.ReactElement => {
  const { t } = useTranslation();
  const classes = style();
  const dispatch = useDispatch();
  const [eventUsage] = useState(getEventUsageStats(otherWebhooks, sandbox));
  const [expandedAccordionCategory, setExpandedAccordionCategory] = useState<string | false>(false);
  const [dataTypesPerService] = useState(dataTypesPerServiceType(dataTypes));
  const [webhookEvents] = useState(getWebhookEvents(sandbox));

  const validationSchema = yup.object().shape({
    url: yup
      .string()
      .required('DASHBOARD_DEVELOPERS_WEBHOOKS_URL_REQUIRED')
      .matches(WEBSITE_WITH_PROTOCOL_REGEX, 'DASHBOARD_DEVELOPERS_WEBHOOK_URL_INVALID')
      .nullable()
      .test({
        name: 'one-of-url',
        test: (value) => {
          const trimmedValue = value.trim();
          if (trimmedValue === webhook?.url) return true;
          return otherWebhooks.every(({ url }) => trimmedValue !== url);
        },
        message: 'DASHBOARD_DEVELOPERS_WEBHOOKS_MODAL_ERROR_URL_DUPLICATE',
      }),
    event: yup
      .object(
        webhookEvents.reduce((acc, { value }) => {
          acc[value] = yup.boolean();
          return acc;
        }, {}),
      )
      .test({
        name: 'one-of-events',
        test: (values) => Object.values(values).some((value) => !!value),
        message: 'DASHBOARD_DEVELOPERS_WEBHOOK_EVENT_REQUIRED',
        exclusive: false,
      }),
  });

  const { handleSubmit, errors, control, trigger, formState, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: prepareWebhookForForm(webhook, webhookEvents, dataTypes),
    resolver: yupResolver(validationSchema),
  });

  const requestTypesValues = watch(
    'requestTypes',
    dataTypesPerServiceTypeToFormValues(dataTypesPerService, webhook),
  );

  const handleAccordionChange =
    (panel: string) => (_event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
      setExpandedAccordionCategory(newExpanded ? panel : false);
    };

  const onSubmitData = (formValues: WebhookFormValues): void => {
    const values = prepareWebhookForSubmit(formValues);

    if (formChange(values, { ...defaultValue, ...webhook })) {
      dispatch(
        webhook ? updateWebhookApi({ ...values, uuid: webhook.uuid }) : createWebhookApi(values),
      );
    } else {
      showSnackbar({ message: t('DASHBOARD_NO_CHANGES_MADE'), type: 'success' });
    }
    onClose();
  };

  return (
    <AlertDialog
      isOpen={true}
      cancel={
        (formState.isDirty || !!webhook) && {
          label: t('DASHBOARD_DEVELOPERS_WEBHOOK_ADD_CTA_CANCEL'),
          onClick: onClose,
          type: 'gray',
          'data-testid': 'modal-cancel-cta',
        }
      }
      title={
        webhook
          ? t('DASHBOARD_DEVELOPERS_WEBHOOK_EDIT_HEADER')
          : t('DASHBOARD_DEVELOPERS_WEBHOOK_ADD_ARIA')
      }
      confirm={{
        label: webhook
          ? t('DASHBOARD_DEVELOPERS_WEBHOOK_ADD_CTA')
          : t('DASHBOARD_DEVELOPERS_WEBHOOK_ADD_ARIA'),
        onClick: handleSubmit(onSubmitData),
      }}
      onClose={onClose}
      showCloseButton
    >
      <View className={classes.subheader}>
        {webhook ? (
          <Alert severity="info" iconMapping={{ info: <InfoIcon fontSize="inherit" /> }}>
            {t('DASHBOARD_DEVELOPERS_WEBHOOKS_MODAL_EDIT_SUBHEADER')}
          </Alert>
        ) : (
          t('DASHBOARD_DEVELOPERS_WEBHOOKS_MODAL_SUBHEADER')
        )}
      </View>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitData)}>
        <Grid container spacing={2} className={classes.formGrid}>
          <Grid item xs={12}>
            <FormInput
              margin="dense"
              className={classes.url}
              placeholder="DASHBOARD_DEVELOPERS_WEBHOOK_URL_PLACEHOLDER"
              label="DASHBOARD_DEVELOPERS_WEBHOOK_URL_LABEL"
              variant="outlined"
              autoFocus
              fullWidth
              name="url"
              errorobj={errors}
              control={control}
              testid={`webhook-form-url-input`}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel className={classes.kindHeader}>
              {t('DASHBOARD_DEVELOPERS_WEBHOOKS_MODAL_SUBHEADER_SELECT')}
            </FormLabel>
            <FormGroup data-testid={`webhook-form-kinds-input`} className={classes.kinds}>
              {webhookEvents.map(({ name, value }) => {
                const eventInputName = `event.${value}`;
                const disabled = eventUsage[value] >= MAX_WEBHOOKS_PER_EVENT;

                if (value !== 'dataPerType')
                  return (
                    <FormCheckBox
                      key={eventInputName}
                      disabled={disabled}
                      onChange={(): Promise<boolean> => trigger('event')}
                      testid={`webhook-form-${eventInputName}`}
                      control={control}
                      name={eventInputName}
                      className={classes.kind}
                      label={name}
                    />
                  );

                const requestInputValues = Object.entries(requestTypesValues);
                const selected = requestInputValues.filter(([, value]) => value).length;
                const unselected = requestInputValues.filter(([, value]) => !value).length;

                const selectedLabel = getWebhookRequestTypesCountLabel(selected, dataTypes);

                return (
                  <Accordion
                    key={eventInputName}
                    className={classes.dataPerTypeEvent}
                    header={
                      <FormCheckBox
                        disabled={disabled}
                        onChange={(event, value): void => {
                          event.stopPropagation();
                          requestInputValues.map(([key]) => setValue(`requestTypes.${key}`, value));
                          trigger('event');
                        }}
                        testid={`webhook-form-${eventInputName}`}
                        control={control}
                        name={eventInputName}
                        className={classes.kind}
                        label={name + selectedLabel}
                        indeterminate={selected >= 1 && unselected >= 1}
                      />
                    }
                    showAccordion={true}
                    children={dataTypesPerService.map(({ label, values }) => {
                      return (
                        <Accordion
                          className={classes.requestInputAccordion}
                          key={label}
                          header={t(label)}
                          showAccordion={true}
                          muiProps={{
                            expanded: expandedAccordionCategory === label,
                            onChange: handleAccordionChange(label),
                          }}
                          children={values.map(({ key, name }) => (
                            <FormCheckBox
                              key={key}
                              disabled={disabled}
                              onChange={(_event, checked): void => {
                                setValue(eventInputName, Boolean(selected + (checked ? 1 : -1)));
                                trigger('requestTypes');
                                trigger('event');
                              }}
                              testid={`webhook-form-requestTypes.${key}`}
                              control={control}
                              name={`requestTypes.${key}`}
                              className={classes.kind}
                              label={t(name)}
                            />
                          ))}
                        />
                      );
                    })}
                  />
                );
              })}
            </FormGroup>
          </Grid>
          {Object.values(requestTypesValues).every(Boolean) && (
            <Grid item xs={12} className={classes.confirmationAlert}>
              <Alert
                data-testid="webhook-form-confirmation-alert"
                severity="warning"
                iconMapping={{ warning: <WarningRoundedIcon fontSize="inherit" /> }}
              >
                <Trans i18nKey="DASHBOARD_DEVELOPERS_WEBHOOKS_MODAL_WARNING" />
              </Alert>
            </Grid>
          )}
          {errors?.event && (
            <Grid item className={classes.eventError}>
              <FormHelperText className="error-text Mui-error">
                {t((errors?.event as { message?: string }).message)}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </form>
    </AlertDialog>
  );
};
