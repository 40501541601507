import { createReducer } from '@reduxjs/toolkit';

import {
  CREATE_IP_WHITELIST_API,
  CREATE_IP_WHITELIST_FAILURE_API,
  CREATE_IP_WHITELIST_SUCCESS_API,
  DELETE_IP_WHITELIST_API,
  DELETE_IP_WHITELIST_FAILURE_API,
  DELETE_IP_WHITELIST_SUCCESS_API,
  GET_CONNECT_URL_API,
  GET_CONNECT_URL_FAILURE_API,
  GET_CONNECT_URL_SUCCESS_API,
  GET_IP_WHITELIST_API,
  GET_IP_WHITELIST_FAILURE_API,
  GET_IP_WHITELIST_SUCCESS_API,
  RESET_CONNECT_API_RESPONSE,
  RESET_CONNECT_ERROR,
  RESET_STORAGE_CONNECT,
  UPDATE_IP_WHITELIST_API,
  UPDATE_IP_WHITELIST_FAILURE_API,
  UPDATE_IP_WHITELIST_SUCCESS_API,
} from './connect.action';
import { CONNECT_INITIAL_STATE, CONNECT_STORE_NAME } from './connect.state';

/**
 * connect Reducers
 */
export const connectReducer = createReducer(CONNECT_INITIAL_STATE, (builder) => {
  builder
    .addCase(RESET_STORAGE_CONNECT, () => CONNECT_INITIAL_STATE)
    .addCase(RESET_CONNECT_ERROR, (state) => ({ ...state, error: null }))
    .addCase(RESET_CONNECT_API_RESPONSE, (state) => ({ ...state, response: null }))
    .addCase(UPDATE_IP_WHITELIST_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(UPDATE_IP_WHITELIST_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(UPDATE_IP_WHITELIST_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(DELETE_IP_WHITELIST_API, (state) => ({
      ...state,
      response: null,
      error: null,
      loading: true,
    }))
    .addCase(DELETE_IP_WHITELIST_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(DELETE_IP_WHITELIST_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(CREATE_IP_WHITELIST_API, (state) => ({
      ...state,
      error: null,
      response: null,
      loading: true,
    }))
    .addCase(CREATE_IP_WHITELIST_SUCCESS_API, (state, action: any) => ({
      ...state,
      response: action.payload,
      loading: false,
    }))
    .addCase(CREATE_IP_WHITELIST_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(GET_IP_WHITELIST_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(GET_IP_WHITELIST_SUCCESS_API, (state, action: any) => ({
      ...state,
      ipWhitelists: action.payload,
      loading: false,
    }))
    .addCase(GET_IP_WHITELIST_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }))
    .addCase(GET_CONNECT_URL_API, (state) => ({
      ...state,
      error: null,
      loading: true,
    }))
    .addCase(GET_CONNECT_URL_SUCCESS_API, (state, action: any) => ({
      ...state,
      connectUrl: action.payload,
      loading: false,
    }))
    .addCase(GET_CONNECT_URL_FAILURE_API, (state, action: any) => ({
      ...state,
      error: action.payload,
      loading: false,
    }));
});

export const connectReducerConfig = {
  [CONNECT_STORE_NAME]: connectReducer,
};
