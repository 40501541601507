import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import useStyle from './style';

import { getPossibleFileObjectURL } from 'pages/sites/utils';
interface Props {
  pageMetaFavicon?: File | string | null;
  metaTitle: string;
  visible: boolean;
}

const MetaSeoPreview: React.FC<Props> = ({ pageMetaFavicon, metaTitle, visible }: Props) => {
  const classes = useStyle();

  return (
    <div className={classes.seoPreview + ` ${visible ? classes.visible : classes.invisible}`}>
      <div className="favicon">
        <img src={getPossibleFileObjectURL(pageMetaFavicon) || '/favicon.svg'} alt="favicon" />
      </div>
      <div className="title">{metaTitle}</div>
      <CloseIcon className="close" />
    </div>
  );
};

export default MetaSeoPreview;
