// Libraries
import React from 'react';
import { Trans } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { Link, Typography } from '@material-ui/core';

import Icon from './icon';
// Styling
import useStyle from './style';

import { View } from 'components/index';

const ErrorView: React.FC = () => {
  const classes = useStyle();
  const { show } = useIntercom();

  return (
    <View justifyContent="center" alignItems="center" className={classes.container}>
      <Icon />
      <Typography className={classes.text}>
        <Trans
          i18nKey="DASHBOARD_ENUM_ERROR_MESSAGE_500"
          components={{
            a: <Link className={classes.link} onClick={show} />,
            bold: <strong />,
            br: <br />,
          }}
        ></Trans>
      </Typography>
    </View>
  );
};

export default ErrorView;
