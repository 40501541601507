import React, { useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import WarningIcon from '@material-ui/icons/Warning';

import { useDispatch, useSelector } from 'react-redux';

import {
  disconnectConnectionApi,
  resetBusinessError,
  resetBusinessResponse,
} from '../../../../../store/features/business/business.action';

import storeService from '../../../../../store';

import useStyle from './style';

import AlertDialog from 'components/dialog';

import { Connection } from 'pages/business/types/interfaces';
import { getLocalizedService, showSnackbar } from 'helpers/common.helper';
import { useBusinessContext } from 'pages/business/provider/business-event';

import { getErrorMessage, getResponseMessage } from 'store/features/business/business.selector';
import { NotificationType } from 'types';

interface Props {
  connection: Connection;
  updateBusiness: () => void;
}

const BusinessDisconnect: React.FC<Props> = ({ connection, updateBusiness }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const { openDisconnectBusinessModal, setOpenDisconnectBusinessModal } = useBusinessContext();
  const response = useSelector<ReturnType<typeof store.getState>, string>(getResponseMessage);
  const error = useSelector<ReturnType<typeof store.getState>, string[]>(getErrorMessage);

  useEffect(() => {
    if (response && response === 'disconnectBusiness') {
      dispatch(resetBusinessResponse());
      closeModalWithSnackbar('DASHBOARD_DISCONNECT_SUCCESS', 'success');
      updateBusiness();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (error && error.includes('disconnectBusiness')) {
      dispatch(resetBusinessError());
      closeModalWithSnackbar('DASHBOARD_DISCONNECT_FAILURE', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const closeModalWithSnackbar = (messageKey: string, type: NotificationType): void => {
    const transValues = {
      asp: connection.description || getLocalizedService(connection?.serviceName),
      businessName: connection.businessName,
    };
    showSnackbar({
      message: (
        <div>
          <Trans i18nKey={messageKey} values={transValues} components={{ bold: <strong /> }} />
        </div>
      ),
      type,
    });
    setOpenDisconnectBusinessModal(false);
  };

  const handleConfirm = (): void => {
    dispatch(disconnectConnectionApi(connection));
  };

  return (
    <AlertDialog
      showCloseButton
      isOpen={openDisconnectBusinessModal}
      title={t('DASHBOARD_DISCONNECT_HEADER')}
      onClose={(): void => setOpenDisconnectBusinessModal(false)}
      cancel={{
        label: t('DASHBOARD_DISCONNECT_CANCEL'),
        onClick: (): void => setOpenDisconnectBusinessModal(false),
        type: 'gray',
      }}
      confirm={{
        label: t('DASHBOARD_DISCONNECT_CONFIRM'),
        onClick: handleConfirm,
      }}
    >
      <>
        <div className={classes.container}>
          <WarningIcon className={classes.icon} />
          <div>
            <Trans
              i18nKey="DASHBOARD_DISCONNECT_TEXT"
              values={{
                asp: connection.description || getLocalizedService(connection?.serviceName),
                businessName: connection.businessName,
              }}
              components={{ bold: <strong /> }}
            />
          </div>
        </div>
      </>
    </AlertDialog>
  );
};

export default BusinessDisconnect;
