import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyle from '../style';

import Dropzone from '../dropzone';

interface Props {
  disabled: boolean;
  setUploadImage: (file: string) => void;
  logoValue: string | File | null;
}

export default function LogoSection({ disabled, logoValue, setUploadImage }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Grid container className={disabled ? classes.uploadDisabled : ''}>
      <Typography className={classes.description}>{t('DASHBOARD_SITES_LOGO_BODY')}</Typography>
      <Dropzone onChange={(file): void => setUploadImage(file)} value={logoValue} id=""></Dropzone>
      <Typography className={classes.uploadSubtitle}>
        {t('DASHBOARD_BRAND_UPLOAD_MAX_FILE')}
      </Typography>
    </Grid>
  );
}
