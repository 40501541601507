import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    back: {
      cursor: 'pointer',
      width: 'max-content',
      margin: 0,
      marginTop: theme.spacing(3),
    },
    backButton: {
      cursor: 'pointer',
      width: 'max-content',
      margin: 0,
      padding: 0,
      marginTop: theme.spacing(3),
    },
    backLink: {
      color: ACTIVE_TEXT_COLOR,
      fontWeight: +theme.typography.fontWeightMedium,
      fontSize: '1rem',
      lineHeight: '1.75rem',
    },
    backIcon: {
      fontSize: '1rem',
      margin: `0px ${theme.spacing()}px -3px 0px`,
      height: '1rem',
      transform: 'rotate(-180deg)',
      color: ACTIVE_TEXT_COLOR,
    },
    tabTitle: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: theme.palette.grey[900],
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      '& .Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
    errorTextCustom: {
      position: 'absolute',
      bottom: '-20px',
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
);
