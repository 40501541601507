import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import useStyles from './styles';
import { sendEmailApi } from './../../store/features/account/customer/customer.action';

import AlertDialog from 'components/dialog';
import { FormInput, FormPhoneInput } from 'components/form';
import { PHONE_REGEX } from 'helpers/regex.helper';
interface Props {
  open: boolean;
  close: (accepted?: boolean) => void;
  loading?: boolean;
  body?: string;
}

const initialValues = (initialMsg: string): { message: string; phoneNumber: string } => ({
  message: initialMsg,
  phoneNumber: '',
});

const validationSchema = yup.object().shape({
  message: yup.string().max(600, 'DASHBOARD_CONTACT_SALES_CRUD_MAX'),
  phoneNumber: yup.string().nullable().matches(PHONE_REGEX, 'DASHBOARD_COMPANY_PHONE_INVALID'),
});
export default function ContactSalesModal({
  open,
  close,
  loading,
  body = 'DASHBOARD_BILLING_CONTACT_SALES_MODAL_SUBTITLE',
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showCancelButton, setShowCloseButton] = useState(false);

  const handleClose = (): void => close();

  const { handleSubmit, errors, control, getValues } = useForm({
    mode: 'all',
    defaultValues: initialValues(t('DASHBOARD_SIGNUP_MODAL_ENTERPRISE_MESSAGE_TEXT')),
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
  });

  useEffect(() => {
    const { message } = getValues();
    setShowCloseButton(message?.length > 0);
  }, [getValues()]);

  const onSubmit = async (values): Promise<void> => {
    dispatch(sendEmailApi(values));
    close(true);
  };

  return (
    <AlertDialog
      isOpen={open}
      title={t('DASHBOARD_BILLING_CONTACT_SALES_MODAL_TITLE')}
      showCloseButton
      onClose={handleClose}
      headerStyle={{ marginBottom: '16px' }}
      cancel={
        showCancelButton && {
          label: t('DASHBOARD_CANCEL_CTA'),
          onClick: () => close(true),
          'data-testid': 'alert-cancel-button',
          type: 'gray',
        }
      }
      confirm={{
        label: !loading ? t('DASHBOARD_SEND_MESSAGE_CTA') : '',
        onClick: handleSubmit(onSubmit),
        'data-testid': 'contact-sales-button',
      }}
    >
      <form noValidate autoComplete="off">
        <p className={classes.subTitleStyle}>{t(body)}</p>
        <div className={classes.inputContainer}>
          <FormPhoneInput
            placeholder="DASHBOARD_SIGNUP_MODAL_ENTERPRISE_PHONE"
            label="DASHBOARD_SIGNUP_MODAL_ENTERPRISE_PHONE"
            variant="outlined"
            fullWidth
            name="phoneNumber"
            id="phoneNumber"
            errorobj={errors}
            control={control}
            // TODO - sync this with the country drop-down and enable all supported countries
            onlyCountries={['us']}
            defaultCountry="us"
            disableDropdown={true}
            disableCountryCode={true}
            testid="phone-number-input"
            margin="dense"
          />
        </div>
        <FormInput
          placeholder="DASHBOARD_BILLING_CONTACT_SALES_MODAL_MESSAGE"
          label="DASHBOARD_BILLING_CONTACT_SALES_MODAL_MESSAGE"
          fullWidth
          name="message"
          id="message"
          errorobj={errors}
          control={control}
          testid="message-input"
          {...{ multiline: true, rows: 10 }}
          inputProps={{
            className: classes.message,
          }}
        />
      </form>
    </AlertDialog>
  );
}
