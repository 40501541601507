import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    codeBlock: {
      backgroundColor: `${theme.palette.grey[100]} !important`,
      fontSize: '0.8rem',
      lineHeight: '1.8',
    },
    codeBlockHeader: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px !important`,
      margin: 0,
      marginBottom: 0,

      backgroundColor: `${theme.palette.grey[100]} !important`,

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '&>.MuiTypography-root': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1),
        fontSize: '0.75rem',
        color: theme.palette.grey[600],
        fontWeight: 600,
        letterSpacing: '0.72px',
      },
      '& railz-button .button': {
        fontSize: '0.75rem',
      },
    },
    code: {
      margin: '0px !important',
      padding: `0px ${theme.spacing(3)}px ${theme.spacing(2)}px !important`,
      borderRadius: '8px',
      fontSize: '0.75rem',
      lineHeight: '0.9425rem',
      backgroundColor: `${theme.palette.grey[100]} !important`,
      fontWeight: 400,
      display: 'block',
      maxHeight: '200px',
      overflow: 'auto',

      '& .react-syntax-highlighter-line-number.linenumber': {
        paddingRight: '1rem!important',
        fontSize: '0.75rem',
        color: theme.palette.grey[400],
      },
    },
  });
});
