import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ALL_FONTS } from '../../../../providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      paddingLeft: '0.5rem',
    },
    title: {
      fontSize: '1.25rem',
      color: theme.palette.grey[900],
      fontWeight: 600,
      marginTop: '1.5rem',
      marginBottom: '1rem',
    },
    description: {
      color: theme.palette.grey[800],
      whiteSpace: 'pre-line',
    },
    active: {
      boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
    },
    activeButton: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
    },
    nonActiveButton: {
      borderColor: theme.palette.common.black,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
    },
    card: {
      minHeight: theme.spacing(30),
      padding: theme.spacing(2),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    cardPillSection: {
      height: theme.spacing(3),
    },
    cardTitle: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    cardSubTitle: {
      fontSize: '1.125rem',
      fontWeight: theme.typography.fontWeightBold as number,
      color: theme.palette.grey[900],
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardButton: {
      display: 'flex',
      marginTop: 'auto',
      width: '100%',

      '& > *': {
        width: '100%',
        flexGrow: 1,
      },

      '& .label': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    featureContainer: {
      marginTop: theme.spacing(4),
    },
    featureListContainer: {
      margin: 0,
      listStyleType: 'none',
      padding: 0,
      marginTop: theme.spacing(2),
      '& li': {
        marginBottom: theme.spacing(2),
      },
    },
    featureHeader: {
      fontWeight: 600,
    },
    featureText: {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: '24px',
      fontFamily: ALL_FONTS,
      display: 'flex',
    },
    checkmark: {
      marginRight: theme.spacing(),
    },
  }),
);
