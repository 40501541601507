/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Grid, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import RefreshIcon from '@material-ui/icons/Refresh';

import useStyle from '../style';

import { FormInput } from 'components/form';
import FormInputWithCopy from 'components/form/input-with-copy';
import { setClipboard } from 'helpers/common.helper';

interface Props {
  disabled: boolean;
  form: any;
  termsAndConditionsEnabled: boolean;
  defaultContent: any;
  setTermsAndConditionsEnabled: (bool: boolean) => void;
}

export default function TermsAndConditionsSection({
  disabled,
  termsAndConditionsEnabled,
  setTermsAndConditionsEnabled,
  defaultContent,
  form: { errors, control, setValue, clearErrors, getValues, watch },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isDefault, setIsDefault] = useState(false);
  const [showCopyDone, setShowCopyDone] = useState(false);

  const watchAllFields = watch();

  const copyUrl = (): void => {
    const { termsAndConditionsTitle } = getValues();
    setClipboard(termsAndConditionsTitle).catch(console.error);
    setShowCopyDone(true);
  };

  useEffect(() => {
    const { termsAndConditionsTitle, termsAndConditionsBody } = getValues();
    setIsDefault(
      termsAndConditionsTitle === defaultContent.termsAndConditionsTitle &&
        termsAndConditionsBody === defaultContent.termsAndConditionsBody &&
        termsAndConditionsEnabled === defaultContent.termsAndConditionsEnabled,
    );
  }, [watchAllFields]);

  return (
    <Grid container className={disabled ? classes.uploadDisabled : null}>
      <Typography className={classes.description}>
        {t('DASHBOARD_SETTINGS_SITES_TERMS_PAGE_BODY')}
      </Typography>
      <div className={classes.inputContainer}>
        <div className={classes.toggleContainer}>
          <Typography className={classes.toggleText}>
            {t('DASHBOARD_SETTINGS_SITES_TERMS_PAGE_TOGGLE')}
          </Typography>
          <Switch
            checked={termsAndConditionsEnabled}
            inputProps={{
              'aria-label': `${t(
                termsAndConditionsEnabled ? 'DASHBOARD_TOGGLE_DISABLE' : 'DASHBOARD_TOGGLE_ENABLE',
              )}`,
            }}
            onChange={(e): void => setTermsAndConditionsEnabled(e.target.checked)}
            color="primary"
          />
        </div>
      </div>
      <Grid container>
        <div className={classes.inputContainer}>
          <FormInputWithCopy
            label={t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_TITLE')}
            placeholder={t('DASHBOARD_SETTINGS_SITES_TERMS_LINK_PLACEHOLDER')}
            id="termsAndConditionsTitle"
            name="termsAndConditionsTitle"
            margin="dense"
            testid="terms-conditions-title-id-input"
            fullWidth
            errorobj={errors}
            control={control}
            onClick={copyUrl}
            disabled={!termsAndConditionsEnabled}
            className={showCopyDone ? undefined : classes.contentInputItem}
          />
          {showCopyDone && (
            <Typography className={classes.clipBoardTextWithInputs}>
              {t('DASHBOARD_GENERATE_LINK_COPIED')}
            </Typography>
          )}
          <FormInput
            defaultValue=""
            label={t('DASHBOARD_SETTINGS_SITES_LINK_PAGE_TEXT_FIELD_COPY_TITLE')}
            placeholder={t('DASHBOARD_SETTINGS_SITES_TERMS_PAGE_TEXT_FIELD_DIPLAY')}
            id="termsAndConditionsBody"
            name="termsAndConditionsBody"
            margin="dense"
            testid="terms-conditions-body-id-input"
            fullWidth
            errorobj={errors}
            control={control}
            disabled={!termsAndConditionsEnabled}
            className={classes.contentInputItem}
          />
        </div>
      </Grid>
      {!isDefault && (
        <RailzButton
          data-testid="reset-terms-and-conditions"
          type="text warning"
          buttonType="button"
          onClick={(): void => {
            setValue('termsAndConditionsTitle', defaultContent.termsAndConditionsTitle);
            setValue('termsAndConditionsBody', defaultContent.termsAndConditionsBody);
            setTermsAndConditionsEnabled(defaultContent.termsAndConditionsEnabled);
            clearErrors();
          }}
          label={t('DASHBOARD_SETTINGS_SITES_TERMS_PAGE_RESET')}
        >
          <span slot="prefix">
            <RefreshIcon fontSize="small" />
          </span>
        </RailzButton>
      )}
    </Grid>
  );
}
