import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(0.5),
      minHeight: theme.spacing(5),
      minWidth: 126,
      maxWidth: 288,
      ['@media screen and (max-width: 768px)']: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    result: {
      display: 'flex',
      width: `calc(100% - ${theme.spacing(6)}px)`,
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.875rem',

      '& span:nth-child(1)': {
        fontSize: '0.875rem',
        width: '90%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      '& span:nth-child(2)': {
        fontSize: '0.75rem',
        color: '#757575',
      },
    },
    menu: {
      marginTop: theme.spacing(),
      ['@media screen and (max-width: 768px)']: {
        left: `${theme.spacing(3)}px !important`,
        width: `calc(100% - ${theme.spacing(6)}px)`,
      },
      '& ul': {
        maxHeight: 240,
        marginBottom: theme.spacing(),
      },
    },
    menuItem: {
      padding: ` 0px ${theme.spacing(2)}px 0px 0px`,
      '& .MuiIconButton-root, .MuiListItemText-root .MuiListItemText-primary': {
        fontSize: '0.875rem!important',
      },
      '&.Mui-selected:not(:focus)': {
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiCheckbox-indeterminate.MuiIconButton-colorPrimary': {
        color: theme.palette.primary.main,
      },
    },
  }),
);
