import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { debounce } from 'lodash';

import storeService from '../../store';

import useStyles from './style';

import { FormSelect, FormSelectAutoComplete } from 'components/form';
import { EMAIL_TEAM_INVALID_DOMAINS_REGEX } from 'helpers/regex.helper';
import { generateDropdown, generateDropdownFromArray } from 'helpers/common.helper';
import { Role } from 'types';
import AlertDialog from 'components/dialog';
import {
  addTeamMemberApiV2,
  resetTeamError,
  resetTeamResponse,
} from 'store/features/account/team/team.action';
import {
  getTeamApiResponse,
  getTeamError,
  getTeamsApiResponse,
  isTeamLoading,
} from 'store/features/account/team/team.selector';
import { getProfileState, getUserRole } from 'store/features/account/profile/profile.selector';
import { EventService } from 'services';
import FormEmailSelectAutoComplete from 'components/form/select-autocomplete/email-autocomplete';
import {
  fetchCustomerEmailApi,
  resetCustomerEmailSuggestionsApi,
} from 'store/features/account/customer/customer.action';
import { getEmailSuggestions } from 'store/features/account/customer/customer.selector';
import { ProfileState } from 'store/features/account/profile/profile.state';

const roleOptions = generateDropdown(Role);

interface FormProp {
  onClose: () => void;
  teamUuid?: string;
}

const InviteTeamMemberForm = ({ onClose, teamUuid }: FormProp): React.ReactElement => {
  const store = storeService.getStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [options, setOptions] = useState(new Array(5));
  const profile = useSelector<ReturnType<typeof store.getState>, ProfileState>(getProfileState);

  const validateTeam = !teamUuid && {
    teamUuid: yup.object().required('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_NOT_TEAM').nullable(),
  };

  const validationSchema = yup.object().shape({
    ...validateTeam,
    members: yup
      .array()
      .of(
        yup.object().shape(
          {
            email: yup
              .string()
              .email('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_INVALID_DOMAIN')
              .notOneOf([profile.email], 'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_DUPLICATE_USER')
              .nullable()
              .max(50, 'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_INVALID_EMAIL_LENGTH')
              .when('role', {
                is: '',
                then: yup.string().nullable(),
                otherwise: yup
                  .string()
                  .email('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_INVALID_DOMAIN')
                  .required('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_EMAIL_REQUIRED')
                  .matches(
                    EMAIL_TEAM_INVALID_DOMAINS_REGEX,
                    teamUuid
                      ? 'DASHBOARD_EMAIL_NOT_WORK_EMAIL'
                      : 'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_INVALID_DOMAIN',
                  ),
              }),
            role: yup
              .string()
              .nullable()
              .when('email', {
                is: '',
                then: yup.string().nullable(),
                otherwise: yup.string().required('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE_REQUIRED'),
              }),
          },
          [
            ['email', 'role'],
            ['role', 'email'],
          ],
        ),
      )
      .test('hasValue', 'Need at least 1 value', function (val): any {
        let errors = [];

        const resp = val.some((fieldVal) => {
          return fieldVal.email;
        });
        const shouldTestEmpty = this.parent?.teamUuid === undefined ? true : this.parent?.teamUuid;
        if (!resp && shouldTestEmpty) {
          errors = [
            new yup.ValidationError(
              'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_EMAIL_REQUIRED',
              val[0].email,
              'members[0].email',
            ),
            new yup.ValidationError(
              'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE_REQUIRED',
              val[0].role,
              'members[0].role',
            ),
          ].filter(Boolean);
        }
        if (errors.length === 0) {
          return true;
        }
        return this.createError({
          message: () => errors,
        });
      })
      .test('duplicate', 'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_DUPLICATE_EMAIL', function (val): any {
        let error;
        val.reduce((acum, { email }, index) => {
          const trimmedEmail = email.trim();
          if (!trimmedEmail) return acum;

          if (!acum[trimmedEmail]) {
            acum[trimmedEmail] = true;
          } else {
            error = this.createError({
              message: 'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_DUPLICATE_EMAIL',
              path: `members[${index}].email`,
            });
          }

          return acum;
        }, {});
        if (error?.value) return error;
        return true;
      }),
  });

  const { handleSubmit, errors, control, formState, getValues, setError, setValue, trigger } =
    useForm({
      mode: 'onChange',
      defaultValues: { teamUuid: teamUuid, members: new Array(5).fill({ email: '', role: '' }) },
      resolver: yupResolver(validationSchema),
    });

  const [roleSelectableOptions, setRoleSelectableOptions] = useState(roleOptions);
  const { fields } = useFieldArray({ name: 'members', control });
  const role = useSelector(getUserRole);
  const { emailSuggestions, index } = useSelector(getEmailSuggestions);
  const teamErrorMessage = useSelector(getTeamError);
  const teamResponse = useSelector(getTeamApiResponse);

  const teams = useSelector(getTeamsApiResponse);

  const teamLoading = useSelector(isTeamLoading);

  const teamOptions = !teamUuid && generateDropdownFromArray(teams?.teams, 'uuid', 'teamName');

  const { isDirty } = formState;

  const onSubmit = async (values): Promise<void> => {
    const selectedTeamUuid: any = getValues(['teamUuid']).teamUuid;

    if (teamLoading) {
      return;
    }
    const validRows = values.members.filter(({ email, role }) => !!email && !!role);

    dispatch(
      addTeamMemberApiV2({
        members: validRows,
        teamUuid: teamUuid ? teamUuid : selectedTeamUuid.value,
      }),
    );
  };

  useEffect(() => {
    dispatch(resetCustomerEmailSuggestionsApi());
  }, []);

  useEffect(() => {
    setRoleSelectableOptions(
      role !== Role.SUPER_ADMINISTRATOR
        ? roleOptions.filter(({ value }) => value !== Role.SUPER_ADMINISTRATOR)
        : roleOptions,
    );
  }, [role]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      if (teamErrorMessage) {
        if (Array.isArray(teamErrorMessage.badEmails)) {
          const badEmailsInfo = teamErrorMessage.badEmails.map((email, errorIndex) => {
            const index = getValues().members.findIndex((dataValue) => dataValue.email === email);
            const message = teamErrorMessage.message[errorIndex]
              ? teamErrorMessage.message[errorIndex]
              : teamErrorMessage.message[0];
            return { email, index, message };
          });
          badEmailsInfo.forEach(({ index, message }) => {
            setError(`members[${index}].email`, { message });
          });
        }
        if (Array.isArray(teamErrorMessage.message)) {
          teamErrorMessage.message.forEach((message) => {
            const [, ...messageFormatted] = message.split(' ');
            const input = message.split(' ')[0];
            if (message.includes('must be an email')) {
              setError(input, { message: t('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_INVALID_DOMAIN') });
            } else if (message.includes('must be shorter than or equal to')) {
              setError(input, {
                message: t('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_INVALID_EMAIL_LENGTH'),
              });
            } else {
              setError(input, { message: messageFormatted.join(' ') });
            }
          });
        }

        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'error',
          message: <Trans i18nKey="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_FAIL"></Trans>,
        });

        dispatch(resetTeamError());
      }
      if (teamResponse) {
        dispatch(resetTeamResponse());
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'success',
          message: <Trans i18nKey="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_SUCCESS"></Trans>,
        });
        onClose();
      }
    }
  }, [teamErrorMessage, teamResponse]);

  useEffect(() => {
    const suggestionMatrix = [...options];
    suggestionMatrix[index] = emailSuggestions;
    setOptions(suggestionMatrix);
  }, [emailSuggestions]);

  const debounceHandleSearch = debounce((event, name: string, inputIndex: number) => {
    const searchLength = event?.target?.value?.length;
    if (searchLength > 2) {
      dispatch(fetchCustomerEmailApi({ search: event.target.value, index: inputIndex }));
    } else {
      dispatch(resetCustomerEmailSuggestionsApi());
    }
  }, 400);

  return (
    <AlertDialog
      isOpen={true}
      title={t(
        teamUuid ? 'DASHBOARD_TEAMS_TEAM_ADD_MEMBERS' : 'DASHBOARD_MEMBERS_MODAL_ADD_MEMBERS',
      )}
      cancel={
        isDirty && {
          label: t('DASHBOARD_TEAM_ADD_CTA_CANCEL'),
          onClick: onClose,
          'data-testid': 'invite-cancel-button',
          type: 'gray',
        }
      }
      confirm={{
        label: t('DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_SEND'),
        onClick: handleSubmit(onSubmit),
        'data-testid': 'invite-cta-button',
      }}
      onClose={onClose}
      showCloseButton
      isLoading={teamLoading}
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} direction="column">
          {!teamUuid && (
            <Grid item sm={11} xs={12} className={classes.selectTeam}>
              <FormSelectAutoComplete
                placeholder="DASHBOARD_MEMBERS_MODAL_SELECT_TEAM"
                label="DASHBOARD_MEMBERS_MODAL_SELECT_TEAM"
                fullWidth
                margin="dense"
                name="teamUuid"
                errorobj={errors}
                control={control}
                options={teamOptions}
                getOptionLabel={(option: any): string => option.name || ''}
                testid="teamUuid"
                valuekey="value"
                renderOption={(option): React.ReactElement => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
              />
            </Grid>
          )}
          {fields.map((item, i) => {
            return (
              <Grid container spacing={1} className={classes.row} key={item.id}>
                <Grid item sm={7} xs={12}>
                  <FormEmailSelectAutoComplete
                    placeholder="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_EMAIL"
                    label="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_EMAIL"
                    fullWidth
                    name={`members[${i}].email`}
                    errorobj={errors}
                    control={control}
                    options={options[i]}
                    getOptionLabel={(option: any): string => option || ''}
                    renderOption={(option): React.ReactElement => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    onInputChange={(e: any): any => {
                      if (e?.target?.value) {
                        setValue(`members[${i}].email`, e.target.value, { shouldDirty: true });
                        debounceHandleSearch(e, `members[${i}].email`, i);
                        trigger(`members[${i}].email`);
                      }
                    }}
                    onBlur={(event: { target: { value: any } }): any => {
                      setValue(`members[${i}].email`, event.target.value, { shouldDirty: true });
                      trigger(`members[${i}].email`);
                    }}
                    testid={`members[${i}].email`}
                  />
                </Grid>
                <Grid item sm={4} xs={11}>
                  <FormSelect
                    placeholder="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE"
                    label="DASHBOARD_TEAMS_TEAM_ADD_MEMBERS_ROLE"
                    fullWidth
                    margin="dense"
                    name={`members[${i}].role`}
                    errorobj={errors}
                    control={control}
                    options={roleSelectableOptions}
                    testid={`members[${i}].role`}
                  />
                </Grid>
                <Grid item sm={1} xs={1} className={classes.deleteButtonWrapper}>
                  <RailzButton
                    type="text warning"
                    aria-label="DASHBOARD_BUSINESS_MENU_DELETE"
                    onClick={(e): void => {
                      e.preventDefault();
                      setValue(`members[${i}]`, { email: '', role: '' }, { shouldValidate: true });
                    }}
                    className={classes.deleteButton}
                  >
                    <span slot="prefix">
                      <DeleteOutlinedIcon fontSize="small" />
                    </span>
                  </RailzButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </form>
    </AlertDialog>
  );
};

export default InviteTeamMemberForm;
