import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxSizing: 'border-box',
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
    },

    main: {
      boxSizing: 'border-box',
      width: '50%',
      maxWidth: '100%',
      minWidth: '400px',
      flexGrow: 1,
      display: 'flex',
      padding: `${theme.spacing(2)}px clamp(${theme.spacing(2)}px, 4vw, ${theme.spacing(10)}px)`,

      '& h1': {
        fontSize: '24px',
        fontWeight: '600',
        margin: `${theme.spacing(4)}px 0`,
      },
    },

    mainContent: {
      flexGrow: 1,
      maxWidth: '640px',
      margin: 'auto',
      marginRight: 0,
      padding: `0 clamp(${theme.spacing(2)}px, 4vw, ${theme.spacing(10)}px)`,

      '& img': {
        height: theme.spacing(4),
      },
    },

    masthead: {
      boxSizing: 'border-box',
      width: '50%',
      maxWidth: '100%',
      minWidth: '400px',
      flexGrow: 1,
      backgroundColor: 'hsla(145, 100%, 98%, 1)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      position: 'sticky',
      top: 0,
      padding: `clamp(${theme.spacing(2)}px, 4vw, ${theme.spacing(10)}px)`,

      ['@media screen and (min-height: 820px)']: {
        overflowX: 'hidden',
        overflowY: 'auto',
        maxHeight: '100vh',
      },
    },

    illustration: {
      position: 'absolute',
      width: 'clamp(380px, 70%, 720px)',
      top: 'clamp(-120px, -25%, -40px)',
      right: '-20%',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        objectFit: 'contain',
        maxWidth: '100%',
        width: '100%',
      },
    },

    mastheadContent: {
      boxSizing: 'border-box',
      zIndex: 1,
      position: 'relative',
      maxWidth: '640px',
      margin: 'auto',
      marginLeft: 0,
    },

    content: {
      margin: 'auto',
      boxSizing: 'border-box',
      position: 'relative',
      padding: `clamp(${theme.spacing(2)}px, 4vw, ${theme.spacing(10)}px)`,

      '&::after': {
        content: `''`,
        position: 'absolute',
        top: '-80px',
        right: '-80px',
        height: 'calc(100% + 80px)',
        width: 'calc(100% + 80px)',
        background: `linear-gradient(to top right,
            hsla(145, 100%, 98%, 1) 50%,
            hsla(145, 100%, 98%, 0) 90%)`,
        zIndex: '-1',
      },

      '& h3': {
        fontSize: '24px',
        fontWeight: '600',
        margin: `0 0 ${theme.spacing(4)}px 0`,
      },
    },

    features: {
      margin: `0 0 ${theme.spacing(4)}px 0`,
      padding: 0,
      listStyle: 'none',
      maxWidth: '520px',
    },

    featureItem: {
      margin: `${theme.spacing(3)}px 0`,
      display: 'flex',
      alignItems: 'flex-start',
    },

    bullet: {
      boxSizing: 'border-box',
      width: theme.spacing(3),
      marginRight: theme.spacing(2),
      flexShrink: 0,
    },

    inlineLink: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.primary.dark,
      },
    },

    footer: {
      marginTop: 'auto',
      '& h4': {
        fontSize: '16px',
        fontWeight: '600',
        margin: `${theme.spacing(4)}px 0`,
      },
    },

    links: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: 0,
      padding: 0,
      listStyle: 'none',
      gap: `${theme.spacing(2)}px calc(2vw + ${theme.spacing(2)}px)`,
    },

    link: {
      color: theme.palette.primary.main,
      fontSize: '14px',
      fontWeight: 500,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.primary.dark,
      },
    },
    logoRailz: {
      paddingBottom: theme.spacing(3.375),
    },
    titleRailz: {
      marginTop: `clamp(${theme.spacing(2)}px, 4vw, ${theme.spacing(10)}px) !important`,
      color: '#000000',
    },
  }),
);
