import { CustomerRequest, SuccessResponse } from '../../../types';

import {
  AuthAction,
  ChangePasswordParam,
  GoogleLoginParam,
  LoginMfaParam,
  LoginMfaRecoveryParam,
  LoginParam,
  LoginResponse,
  MicrosoftLoginParam,
  RefreshTokenParam,
  VerifyEmailParam,
  VerifyEmailResponse,
} from './auth.state';

import { ErrorResponse } from 'types/interface/error.interface';

/** StateActions */
export const SET_AUTH_INFORMATION = 'SET_AUTH_INFORMATION';
export const RESET_AUTH_INFORMATION = 'RESET_AUTH_INFORMATION';
export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';
export const RESET_AUTH_RESPONSE = 'RESET_AUTH_RESPONSE';
export const RESET_EXPIRED_ON = 'RESET_EXPIRED_ON';

export const DO_LOGIN_API = 'DO_LOGIN_API';
export const DO_LOGIN_SUCCESS_API = 'DO_LOGIN_SUCCESS_API';
export const DO_LOGIN_FAILURE_API = 'DO_LOGIN_FAILURE_API';
export const DO_LOGIN_MFA_API = 'DO_LOGIN_MFA_API';
export const DO_LOGIN_MFA_SUCCESS_API = 'DO_LOGIN_MFA_SUCCESS_API';
export const DO_LOGIN_MFA_FAILURE_API = 'DO_LOGIN_MFA_FAILURE_API';
export const DO_LOGIN_MICROSOFT_API = 'DO_LOGIN_MICROSOFT_API';
export const DO_LOGIN_GOOGLE_API = 'DO_LOGIN_GOOGLE_API';
export const DO_RESEND_EMAIL_API = 'DO_RESEND_EMAIL_API';
export const DO_RESEND_EMAIL_SUCCESS_API = 'DO_RESEND_EMAIL_SUCCESS_API';
export const DO_RESEND_EMAIL_FAILURE_API = 'DO_RESEND_EMAIL_FAILURE_API';
export const DO_SIGNUP_API = 'DO_SIGNUP_API';
export const DO_SIGNUP_SUCCESS_API = 'DO_SIGNUP_SUCCESS_API';
export const DO_SIGNUP_FAILURE_API = 'DO_SIGNUP_FAILURE_API';
export const DO_MFA_RECOVERY_API = 'DO_MFA_RECOVERY_API';
export const DO_MFA_RECOVERY_SUCCESS_API = 'DO_MFA_RECOVERY_SUCCESS_API';
export const DO_MFA_RECOVERY_FAILURE_API = 'DO_MFA_RECOVERY_FAILURE_API';
export const DO_REFRESH_TOKEN_API = 'DO_REFRESH_TOKEN_API';
export const DO_REFRESH_TOKEN_SUCCESS_API = 'DO_REFRESH_TOKEN_SUCCESS_API';
export const DO_REFRESH_TOKEN_FAILURE_API = 'DO_REFRESH_TOKEN_FAILURE_API';
export const DO_CHANGE_PASSWORD_API = 'DO_CHANGE_PASSWORD_API';
export const DO_CHANGE_PASSWORD_SUCCESS_API = 'DO_CHANGE_PASSWORD_SUCCESS_API';
export const DO_CHANGE_PASSWORD_FAILURE_API = 'DO_CHANGE_PASSWORD_FAILURE_API';
export const RESET_STORAGE_AUTH = 'RESET_STORAGE_AUTH';

export const resetStoreAuth = (): { type: string } => {
  return {
    type: RESET_STORAGE_AUTH,
  };
};

export const setAuthInformation = (payload: LoginParam): AuthAction => {
  return {
    type: SET_AUTH_INFORMATION,
    payload,
  };
};

export const resetAuthError = (): { type: string } => {
  return {
    type: RESET_AUTH_ERROR,
  };
};

export const resetAuthResponse = (): { type: string } => {
  return {
    type: RESET_AUTH_RESPONSE,
  };
};

export const resetAuthInformation = (): { type: string } => {
  return {
    type: RESET_AUTH_INFORMATION,
  };
};

export const resetAuthExpiredOn = (): { type: string } => {
  return {
    type: RESET_EXPIRED_ON,
  };
};

export const doLoginApi = (payload: LoginParam): AuthAction => {
  return {
    type: DO_LOGIN_API,
    payload,
  };
};

export const doLoginSuccessApi = (
  payload: LoginResponse,
): { payload: LoginResponse; type: string } => {
  return {
    type: DO_LOGIN_SUCCESS_API,
    payload,
  };
};

export const doLoginFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DO_LOGIN_FAILURE_API,
    payload,
  };
};

export const doLoginMicrosoftApi = (payload: MicrosoftLoginParam): AuthAction => {
  return {
    type: DO_LOGIN_MICROSOFT_API,
    payload,
  };
};

export const doLoginGoogleApi = (payload: GoogleLoginParam): AuthAction => {
  return {
    type: DO_LOGIN_GOOGLE_API,
    payload,
  };
};

export const doLoginMfaApi = (payload: LoginMfaParam): AuthAction => {
  return {
    type: DO_LOGIN_MFA_API,
    payload,
  };
};

export const doLoginMfaSuccessApi = (
  payload: LoginResponse,
): { payload: LoginResponse; type: string } => {
  return {
    type: DO_LOGIN_MFA_SUCCESS_API,
    payload,
  };
};

export const doLoginMfaFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DO_LOGIN_MFA_FAILURE_API,
    payload,
  };
};

export const doResendEmailApi = (payload: VerifyEmailParam): AuthAction => {
  return {
    type: DO_RESEND_EMAIL_API,
    payload,
  };
};

export const doResendEmailSuccessApi = (
  payload: VerifyEmailResponse,
): { payload: VerifyEmailResponse; type: string } => {
  return {
    type: DO_RESEND_EMAIL_SUCCESS_API,
    payload,
  };
};

export const doResendEmailFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DO_RESEND_EMAIL_FAILURE_API,
    payload,
  };
};

export const doSignupApi = (payload: CustomerRequest): AuthAction => {
  return {
    type: DO_SIGNUP_API,
    payload,
  };
};

export const doSignupSuccessApi = (
  payload: SuccessResponse,
): { payload: SuccessResponse; type: string } => {
  return {
    type: DO_SIGNUP_SUCCESS_API,
    payload,
  };
};

export const doSignupFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DO_SIGNUP_FAILURE_API,
    payload,
  };
};

export const doMfaRecoveryApi = (payload: LoginMfaRecoveryParam): AuthAction => {
  return {
    type: DO_MFA_RECOVERY_API,
    payload,
  };
};

export const doMfaRecoverySuccessApi = (
  payload: SuccessResponse,
): { payload: SuccessResponse; type: string } => {
  return {
    type: DO_MFA_RECOVERY_SUCCESS_API,
    payload,
  };
};

export const doMfaRecoveryFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DO_MFA_RECOVERY_FAILURE_API,
    payload,
  };
};

export const doRefreshTokenApi = (payload?: RefreshTokenParam): AuthAction => {
  return {
    type: DO_REFRESH_TOKEN_API,
    payload,
  };
};

export const doRefreshTokenSuccessApi = (
  payload: LoginResponse,
): { type: string; payload: LoginResponse } => {
  return {
    type: DO_REFRESH_TOKEN_SUCCESS_API,
    payload,
  };
};

export const doRefreshTokenFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DO_REFRESH_TOKEN_FAILURE_API,
    payload,
  };
};

export const doChangePasswordApi = (payload?: ChangePasswordParam): AuthAction => {
  return {
    type: DO_CHANGE_PASSWORD_API,
    payload,
  };
};

export const doChangePasswordSuccessApi = (): { type: string } => {
  return {
    type: DO_CHANGE_PASSWORD_SUCCESS_API,
  };
};

export const doChangePasswordFailureApi = (
  payload: ErrorResponse | any,
): { payload: ErrorResponse | any; type: string } => {
  return {
    type: DO_CHANGE_PASSWORD_FAILURE_API,
    payload,
  };
};
