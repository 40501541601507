import React from 'react';

import { useSelector } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import useStyle from '../style';
import { Role } from '../../../types';
import { getUserRole } from '../../../store/features/account/profile/profile.selector';
import { useBusinessContext } from '../provider/business-event';

import { isSandbox } from '../../../store/features/auth';

import Status from './status';
import SearchBusiness from './search-business';
import CreateOrRenameBusiness from './create-or-rename-business';
import BusinessManageServices from './business-manage-services/business-manage-services';
import RelatedIntegrations from './related-integrations';

import { ActionTypes, AllBusinessStatus } from 'pages/business/types/enums';

export interface BusinessControlsProps {
  businessName: string;
  searchBusiness: React.Dispatch<React.SetStateAction<string>>;
  setSelectedStatus: React.Dispatch<React.SetStateAction<AllBusinessStatus>>;
  selectedStatus: AllBusinessStatus;
  updateBusiness: (addedBusiness?: boolean) => void;
  applyFilter: () => void;
  integrationUuids: string[];
  setIntegrationUuids: React.Dispatch<React.SetStateAction<string[]>>;
  resetFilter: () => void;
}

export default function BusinessControls({
  businessName,
  searchBusiness,
  setSelectedStatus,
  selectedStatus,
  updateBusiness,
  integrationUuids,
  setIntegrationUuids,
  applyFilter,
  resetFilter,
}: BusinessControlsProps): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();

  const { setEvent, setContextBusiness, openUpdateOrCreateModal, setOpenUpdateOrCreateModal } =
    useBusinessContext();
  const role = useSelector(getUserRole);
  const isSandboxEnabled = useSelector(isSandbox);

  const closeUpdateOrCreateModal = (reload = false): void => {
    setOpenUpdateOrCreateModal(false);
    if (reload) {
      updateBusiness();
    }
  };

  const handleOpenModal = (): void => {
    setContextBusiness(null);
    setEvent(ActionTypes.ADD);
    setOpenUpdateOrCreateModal(true);
  };

  return (
    <>
      <CreateOrRenameBusiness open={openUpdateOrCreateModal} close={closeUpdateOrCreateModal} />
      <BusinessManageServices updateBusiness={updateBusiness} />
      <div className={`${classes.wrapperFilter} `} data-testid="business-controls">
        <SearchBusiness
          handleSearch={searchBusiness}
          value={businessName}
          className={classes.searchFilter}
        />
        <Status selectedStatus={selectedStatus} handleSelected={setSelectedStatus} />
        <RelatedIntegrations selections={integrationUuids} handleSelection={setIntegrationUuids} />
        <RailzButton
          size="medium"
          label={t('DASHBOARD_BUSINESS_LIST_CTA_APPLY')}
          className={classes.ctaButton}
          // TODO: fix isDisabled not blocking the calling of the onClick function.
          onClick={applyFilter}
          data-testid="business-controls-filter-apply"
        />

        <RailzButton
          size="medium"
          type="reset"
          label={t('DASHBOARD_BUSINESS_LIST_CTA_RESET')}
          className={classes.ctaButton}
          onClick={resetFilter}
          data-testid="business-controls-filter-clear"
        />

        {[
          Role.SUPER_ADMINISTRATOR,
          Role.ADMINISTRATOR,
          isSandboxEnabled && Role.DEVELOPER,
        ].includes(role) && (
          <RailzButton
            className={classes.addBusinessButton}
            size="large"
            label={t('DASHBOARD_BUSINESS_NEW_BUSINESS')}
            onClick={handleOpenModal}
            data-testid="business-controls-new-business-cta"
          >
            <span slot="prefix">
              <AddIcon />
            </span>
          </RailzButton>
        )}
      </div>
    </>
  );
}
