import React from 'react';

import { useTranslation } from 'react-i18next';

import { Card, Grid, Tooltip, Typography } from '@material-ui/core';

import { RailzButton, RailzLogo } from '@railzai/railz-uikit-react';

import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import HelpIcon from '@material-ui/icons/Help';

import useStyle from './style';

import { getLocalizedService } from 'helpers/common.helper';
import { ServiceType } from 'types';
import Loading from 'components/loading';

interface Props {
  sandbox?: boolean;
  kind: string;
  connectionsByServiceName: Record<string, number>;
  connectionGroupSummary: {
    active: number;
    limit?: number;
  };
  logos: Record<string, string>;
}
const ServiceIcon = {
  [ServiceType.ACCOUNTING]: <AssessmentOutlinedIcon />,
  [ServiceType.BANKING]: <AccountBalanceOutlinedIcon />,
  [ServiceType.COMMERCE]: <LocalMallOutlinedIcon />,
};

const EmptyServiceCard = ({ kind }: Pick<Props, 'kind'>): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Card className={`${classes.cardContainer} ${classes.emptyCard} ${kind}`}>
      <div className={`${classes.cardTitleContainer} empty ${kind}`}>
        <div className={classes.cardTitle}>
          {ServiceIcon[kind]}
          <Typography variant="h6" className={classes.serviceKind}>
            {t(`DASHBOARD_INTEGRATION_${kind.toUpperCase()}_HEADER`)}
          </Typography>
        </div>
      </div>
      <Grid container className={`${classes.cardBody} empty`}>
        <Grid item xs={12} md={11}>
          {t(`DASHBOARD_HOMEPAGE_${kind.toUpperCase()}_BODY`)}
        </Grid>
        <Grid item xs={12} md={12}>
          <RailzButton
            type="rounded outlined secondary"
            label={t('DASHBOARD_LEARN_MORE_CTA')}
            href={`https://docs.railz.ai/docs/${kind}-integrations-overview`}
            data-testid={'button-learn-more'}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const ServiceCard = ({
  kind,
  providers,
  logos,
  connectionGroupSummary,
  sandbox,
}: Omit<Props, 'connectionsByServiceName'> & {
  providers: Array<[string, number]>;
}): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();

  const isLoading = typeof connectionGroupSummary?.active !== 'number';

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.cardTitleContainer}>
        <div className={classes.cardTitle}>
          {ServiceIcon[kind]}
          <Typography variant="h6" className={classes.serviceKind}>
            {t(`DASHBOARD_NAV_INTEGRATIONS_${kind.toUpperCase()}`)}
          </Typography>
        </div>
        <div className={classes.cardTitleCount}>
          <div className={classes.tooltipContainer}>
            <Tooltip
              title={
                sandbox
                  ? t('DASHBOARD_SANDBOX_SERVICES_CONNECTION_COUNT_TOOLTIP')
                  : t('DASHBOARD_SERVICES_CONNECTION_COUNT_TOOLTIP')
              }
              placement="right"
              arrow
              PopperProps={{ disablePortal: true }}
            >
              <HelpIcon className={classes.tooltipIcon} />
            </Tooltip>
          </div>
          <Typography variant="h6" className="count">
            {isLoading ? (
              <Loading
                loading={true}
                loadingStyle={{ color: 'inherit', width: '25px', height: '25px' }}
                isBackdrop={false}
                testId={`home-page-service-card-${kind}`}
              />
            ) : (
              connectionGroupSummary.active
            )}
          </Typography>
          <Typography variant="caption" className="description">
            {t(`DASHBOARD_SERVICES_COUNT_DESCRIPTION`)}
          </Typography>
        </div>
      </div>

      <Grid container className={`${classes.cardSubtitle}`}>
        <Typography variant="caption" align="left" className={classes.statsColumnLabel}>
          Provider
        </Typography>
        <Typography variant="caption" align="right" className={classes.statsColumnLabel}>
          {t('DASHBOARD_HOME_USAGE_HEADER')}
        </Typography>
      </Grid>
      <div className={`${classes.cardBody} list`}>
        {providers.map(([providerName, connections], index) => (
          <Grid key={index} container className={classes.providerRow}>
            <Grid item xs={8} md={8}>
              {kind === ServiceType.BANKING ? (
                <span className={classes.providerNameAndLogo}>
                  {logos && logos[providerName] !== '' ? (
                    <span className={classes.imgContainer}>
                      <img src={logos[providerName]} alt={`${providerName} icon`} />
                    </span>
                  ) : null}
                  {providerName}
                </span>
              ) : (
                <span className={classes.providerNameAndLogo}>
                  <span className={classes.imgContainer}>
                    <RailzLogo
                      className={classes.rowItem}
                      name={providerName}
                      variant="small"
                      outlined
                    />
                  </span>
                  {getLocalizedService(providerName)}
                </span>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              className={`${classes.connectionCountCol} connectionCount ${classes.rowItem}`}
            >
              {connections}
            </Grid>
          </Grid>
        ))}
      </div>
    </Card>
  );
};

const ConnectionInsightsCard = ({
  kind,
  connectionsByServiceName,
  logos,
  connectionGroupSummary,
  sandbox,
}: Props): JSX.Element => {
  const providers = (
    connectionsByServiceName ? Object.entries(connectionsByServiceName) : []
  ) as Array<[string, number]>;

  if (!providers?.length) return <EmptyServiceCard kind={kind} />;

  return (
    <ServiceCard
      kind={kind}
      providers={providers}
      logos={logos}
      connectionGroupSummary={connectionGroupSummary}
      sandbox={sandbox}
    />
  );
};

export default ConnectionInsightsCard;
