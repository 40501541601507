import { all } from 'redux-saga/effects';

import { authSaga } from './features/auth';
import { accountSaga } from './features/account/account.saga';
import { integrationSaga } from './features/integration/integration.saga';
import { businessSaga } from './features/business/business.saga';
import { developerSaga } from './features/developer/developer.saga';
import { reportSaga } from './features/report/report.saga';
import { connectSaga } from './features/connect';

export function* saga(): Generator<any> {
  yield all([
    ...authSaga,
    ...accountSaga,
    ...integrationSaga,
    ...businessSaga,
    ...developerSaga,
    ...reportSaga,
    ...connectSaga,
  ]);
}
