import React, { ReactElement, useState } from 'react';
import { IconButton, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import moment from 'moment';

import { useDispatch } from 'react-redux';

import type { Integration, ServiceWithIntegrations } from '../../../../types/interface';

import { IntegrationDefaultConfirmationModal } from './integration-default-modal';

import { IntegrationDeleteConfirmationModal } from './integration-delete-modal';

import BasicTable from 'components/table';
import RailzChip from 'components/chip';
import {
  deleteIntegrationApi,
  updateIntegrationsDefaultApi,
} from 'store/features/integration/integration.action';

export const ServiceListItemTable: React.FC<{
  openForm: (uuid?: string) => void;
  classes: Record<string, string>;
  service: ServiceWithIntegrations;
  isLoading: boolean;
}> = ({ service, classes, isLoading, openForm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(null);
  const [deleteConfirmationDialog, setDeleteConfirmationDialogData] = useState(null);
  const [defaultConfirmationDialog, setDefaultConfirmationDialogData] = useState(null);

  function handleDeleteConfirmationDialogClose(item: Integration, confirmed = false): void {
    if (confirmed) dispatch(deleteIntegrationApi(item));
    setDeleteConfirmationDialogData(null);
  }

  function handleDefaultConfirmationDialogClose(item: Integration, confirmed = false): void {
    if (confirmed) dispatch(updateIntegrationsDefaultApi(item));
    setDefaultConfirmationDialogData(null);
  }

  const columns = [
    {
      key: 'status',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_STATUS'),
      width: '10%',
      render: (item: Integration): ReactElement => {
        if (item.isActive)
          return <RailzChip chipText="DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_DEFAULT" />;
      },
    },
    {
      key: 'label',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL'),
      width: '20%',
      render: (item: Integration): ReactElement => {
        return <Typography className={classes.tableCell}>{item.name}</Typography>;
      },
    },
    {
      key: 'client',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_ID'),
      width: '10%',
      render: (item: Integration): ReactElement => {
        return <Typography className={classes.tableCell}>{item.client}</Typography>;
      },
    },
    {
      key: 'secret',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_SECRET'),
      width: '10%',
      render: (item: Integration): ReactElement => {
        return <Typography className={classes.tableCell}>{item.secret}</Typography>;
      },
    },
    {
      key: 'createdAt',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_CREATED'),
      width: '10%',
      render: (item: Integration): ReactElement => {
        return (
          <Typography className={classes.tableCell}>
            {moment(item.createdAt).format('DD MMM YYYY')}
          </Typography>
        );
      },
    },
    {
      key: 'updatedAt',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_UPDATED'),
      width: '10%',
      render: (item: Integration): ReactElement => {
        return (
          <Typography className={classes.tableCell}>
            {moment(item.updatedAt).format('DD MMM YYYY')}
          </Typography>
        );
      },
    },
    {
      key: 'connectionNumber',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_CONNECTIONS'),
      width: '10%',
      render: (item: Integration): ReactElement => {
        return <Typography className={classes.tableCell}>{item.connectionCount}</Typography>;
      },
    },
    {
      key: 'actions',
      name: t('DASHBOARD_INTEGRATION_KEYS_TABLE_LABEL_ACTION'),
      width: '5%',
      render: (item: Integration): ReactElement => {
        return (
          <span key={item.uuid}>
            <IconButton
              key={item.uuid}
              aria-owns={openMenu ? `integrations-item-${item.uuid}-overflow` : undefined}
              aria-haspopup="true"
              onClick={(event): void => {
                setOpenMenu({ ref: event.currentTarget, item });
              }}
              data-testid={`overflow-menu-button-${item?.uuid}`}
            >
              <MoreVertIcon />
            </IconButton>
            {openMenu && (
              <Menu
                key={`integrations-item-${item.uuid}-overflow`}
                id={`integrations-item-${item.uuid}-overflow`}
                anchorEl={openMenu?.ref}
                open={Boolean(openMenu?.ref && openMenu?.item.uuid === item.uuid)}
                onClose={(): void => setOpenMenu(null)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                children={[
                  {
                    key: `edit`,
                    icon: <EditIcon />,
                    label: 'DASHBOARD_TEAM_EDIT_CTA',
                    action: (): void => openForm(openMenu.item.uuid),
                    show: () => true,
                  },
                  {
                    key: 'setAsDefault',
                    icon: <CheckIcon />,
                    label: 'DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT',
                    show: () => !openMenu.item.isActive,
                    action: (): void => setDefaultConfirmationDialogData(openMenu.item),
                  },
                  {
                    key: 'delete',
                    icon: <DeleteOutlineIcon />,
                    label: 'DASHBOARD_INTEGRATIONS_MENU_DELETE',
                    show: () => !openMenu.item.isActive,
                    action: (): void => setDeleteConfirmationDialogData(openMenu.item),
                  },
                ].map(({ key, action, icon, label, show }) => {
                  return (
                    show() && (
                      <MenuItem
                        key={key}
                        onClick={(): void => {
                          action();
                          setOpenMenu(null);
                        }}
                        style={{ color: key === 'delete' ? theme.palette.error.main : '' }}
                        data-testid={`integrations-item-${item.uuid}-menu-option-${key}`}
                        className={classes.menuItem}
                      >
                        {icon}
                        {t(label)}
                      </MenuItem>
                    )
                  );
                })}
              />
            )}
          </span>
        );
      },
    },
  ];

  return (
    <>
      {defaultConfirmationDialog && (
        <IntegrationDefaultConfirmationModal
          integration={defaultConfirmationDialog}
          handleClose={handleDefaultConfirmationDialogClose}
        />
      )}
      {deleteConfirmationDialog && (
        <IntegrationDeleteConfirmationModal
          integration={deleteConfirmationDialog}
          handleClose={handleDeleteConfirmationDialogClose}
        />
      )}
      <BasicTable
        className={classes.table}
        isPaperComponent={false}
        columns={columns}
        rows={service.relatedIntegrations}
        stickyHeader={true}
        isLoading={isLoading}
        noData={t('DASHBOARD_INTEGRATION_KEYS_TABLE_EMPTY')}
      />
    </>
  );
};
