import axios from 'axios';

import Config from 'config';
import { Webhook, WebhookHistoryParams, WebhookHistoryResponse } from 'types';

export interface WebhookDeleteResponse {
  deleted: string;
}

class WebhookService {
  async getWebhooks(): Promise<Webhook[]> {
    return await axios.get(`${Config.SERVER_URL}/webhooks`);
  }

  async createWebhook(payload: Webhook): Promise<Webhook> {
    return await axios.post(`${Config.SERVER_URL}/webhooks`, payload);
  }

  async updateWebhook(payload: Webhook): Promise<Webhook> {
    const { uuid, ...rest } = payload;
    return await axios.put(`${Config.SERVER_URL}/webhooks/${uuid}`, rest);
  }

  async deleteWebhook(uuid: string): Promise<WebhookDeleteResponse> {
    return await axios.delete(`${Config.SERVER_URL}/webhooks/${uuid}`);
  }

  async getWebhookHistory(params: WebhookHistoryParams): Promise<WebhookHistoryResponse> {
    return await axios.get(`${Config.SERVER_URL}/webhooks/webhookHistory`, { params });
  }
}

export default new WebhookService();
