import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import config from 'config';
// Adds custom validation methods for Yup
import 'helpers/yup';

TagManager.initialize({
  gtmId: config.GOOGLE_TAG_MANAGER_ID,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
