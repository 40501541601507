import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.error.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 500,
    },
    serviceName: {
      fontSize: theme.spacing(2),
    },
    pillConnected: {
      padding: '0.25rem 0.594rem',
      backgroundColor: '#BCEDD2',
      color: '#1D7043',
      fontWeight: 500,
    },
    pillDisconnected: {
      padding: '0.25rem 0.594rem',
      backgroundColor: '#D8D8D8',
      color: '#4F4F4F',
      fontWeight: 500,
    },
    bankContainer: { display: 'flex', alignItems: 'center', paddingTop: theme.spacing(2) },
    bankIcon: {
      paddingRight: theme.spacing(1),
      height: theme.spacing(2.401),
      color: '#389BFF',
    },
    bankText: {
      fontSize: theme.spacing(1.75),
      color: '#000000',
    },
    nameRowLabel: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.1875rem',
    },
    uuidRowLabel: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      color: theme.palette.grey[600],
      paddingTop: theme.spacing(1),
    },
    dialog: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: theme.spacing(78.5),
      },
    },
  }),
);
