import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    button: {
      marginTop: '2em',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '.7em 1.5em',
      textAlign: 'center',
      borderRadius: '5em',
      fontSize: '1em',
      fontWeight: 600,
      minWidth: '30%',
      width: 'fit-content',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);
