import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing() * 2,
    },
    headDescription: {
      padding: theme.spacing() * 3,
    },
    container: {
      margin: theme.spacing(2),
      overflowY: 'auto',
      boxShadow: 'none',
      [theme.breakpoints.up('md')]: {
        maxWidth: '70%',
      },
    },
    cardHeader: {
      paddingLeft: 0,
      paddingRight: '1px',
      '& .MuiCardHeader-action': {
        alignSelf: 'auto',
        marginRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        '& .MuiCardHeader-action': {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        },
      },
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
    },
    action: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
    },
    subHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    subHeaderLabel: {
      marginRight: theme.spacing(2),
    },
    noMarginBottom: {
      marginBottom: 0,
    },
  }),
);
