import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    addHeaderButton: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      display: 'block',
      margin: `${theme.spacing(1.5)}px 0px ${theme.spacing(1)}px`,
    },
    errorText: {
      color: theme.palette.error.main,
    },
    mainGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });
});
