import React, { ReactElement } from 'react';

import { useSelector } from 'react-redux';

import HelpIcon from '@material-ui/icons/Help';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';

import useStyle from './style';

import { isLoading } from 'store/features/business/business.selector';
import Loading from 'components/loading';

interface Props {
  title: string;
  value: string;
  testId?: string;
  description?: string;
  valueDescription?: string;
  dateRange?: any | string;
  tooltipText?: string;
  icon?: React.ReactNode;
  link?: { to: string; label: string };
  type?: 'active' | 'pending' | 'inactive' | 'lifetime';
}

const StatCard = ({
  title,
  testId = '',
  description,
  dateRange,
  icon,
  value,
  valueDescription,
  type,
  tooltipText,
  link,
}: Props): JSX.Element => {
  const classes = useStyle();
  const statsLoading = useSelector(isLoading);

  const tooltip = (tooltipText: string): ReactElement => {
    return (
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipIcon}>
          <HelpIcon fontSize="small" />
        </div>
        <div className={classes.tooltip}>
          <span className={classes.tooltipText}>{tooltipText}</span>
        </div>
      </div>
    );
  };

  const linkButton = (linkLabel: string, linkTo: string): ReactElement => {
    return (
      <Link to={linkTo} className={classes.link}>
        <span className={classes.linkLabel}>{linkLabel}</span>
        <div className={classes.linkIcon}>
          <ArrowForwardIcon fontSize="small" />
        </div>
      </Link>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.container} ${type ? type : ''}`}>
        <div className={classes.header}>
          {icon && <div className={`${classes.iconContainer} ${type ? type : ''}`}>{icon}</div>}
          <div className={classes.headerContent}>
            <span className={classes.title}>{title}</span>
            {link && linkButton(link.label, link.to)}
            {description && (
              <div className={classes.descriptionContainer}>
                <span className={classes.description}>{description}</span>
                {dateRange && <span className={classes.dateRange}>{dateRange}</span>}
              </div>
            )}
          </div>
        </div>
        <div className={classes.statContainer}>
          {statsLoading ? (
            <Loading
              loading={statsLoading}
              loadingStyle={{ color: 'inherit', width: '32px', height: '32px' }}
              isBackdrop={false}
              testId={`${type}-businesses-count`}
            />
          ) : (
            <span className={classes.value} data-testid={testId}>
              {value}
              {valueDescription && (
                <span className={classes.valueDescription}>
                  <Trans i18nKey={valueDescription} components={{ b: <strong /> }} />
                </span>
              )}
            </span>
          )}
        </div>
        {tooltipText && tooltip(tooltipText)}
      </div>
    </div>
  );
};

export default StatCard;
