import React, { useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import useStyle from './style';

import AlertDialog from 'components/dialog';

import { getTeamApiResponse, getTeamError } from 'store/features/account/team/team.selector';
import {
  deleteTeamMemberFromAllTeamsApi,
  resetTeamError,
  resetTeamResponse,
} from 'store/features/account/team/team.action';
import { EventService } from 'services';

interface Props {
  member: any;
  onClose: () => void;
}

const DeleteMember: React.FC<Props> = ({ member, onClose }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const teamResponse = useSelector(getTeamApiResponse);
  const teamErrorMessage = useSelector(getTeamError);

  const getFullName = (member): string => `${member?.firstName || ''} ${member?.lastName || ''}`;
  const fullName = member?.firstName || member?.lastName ? getFullName(member) : member.email;

  const handleConfirm = (): void => {
    dispatch(deleteTeamMemberFromAllTeamsApi(member.uuid));
  };

  useEffect(() => {
    if (teamErrorMessage) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: (
          <Trans
            values={{
              fullName: fullName,
            }}
            i18nKey="DASHBOARD_TEAM_DELETE_ERROR"
            components={{ bold: <strong /> }}
          ></Trans>
        ),
      });
      dispatch(resetTeamError());
    }
    if (teamResponse) {
      dispatch(resetTeamResponse());
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'success',
        message: (
          <Trans
            values={{
              fullName: fullName,
            }}
            i18nKey="DASHBOARD_TEAM_MEMBER_ACCOUNT_DELETE_SUCCESS"
          ></Trans>
        ),
      });
      onClose();
    }
  }, [teamErrorMessage, teamResponse]);

  return (
    <AlertDialog
      showCloseButton
      isOpen={true}
      title={t('DASHBOARD_MEMBER_DELETE_MODAL')}
      onClose={onClose}
      cancel={{
        label: t('DASHBOARD_TEAM_DELETE_MODAL_CANCEL_CTA'),
        type: 'gray',
        onClick: onClose,
      }}
      confirm={{
        label: t('DASHBOARD_TEAM_MEMBER_ACCOUNT_CTA'),
        onClick: handleConfirm,
        type: 'error',
      }}
    >
      <>
        <div className={classes.container}>
          <div className={classes.text}>
            <Trans
              i18nKey="DASHBOARD_TEAM_MEMBER_DELETE_ACCOUNT_MODAL_BODY"
              values={{
                fullName: fullName,
              }}
              shouldUnescape={true}
              components={{ bold: <strong /> }}
              tOptions={{ interpolation: { escapeValue: true } }}
            />
          </div>
        </div>
      </>
    </AlertDialog>
  );
};

export default DeleteMember;
