import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';

import storeService from '../../store';

import { getProfileState } from '../../store/features/account/profile/profile.selector';
import { ProfileState } from '../../store/features/account/profile/profile.state';

import style from './style';

import ProfileForm from './profile-form';

import { fetchProfileApi, updateProfileApi } from 'store/features/account/profile/profile.action';
import { Header, ScrollView, View } from 'components';

interface Props {
  testid: string;
}

const Profile: React.FC<Props> = ({ testid = 'test-header-profile-page' }) => {
  const { t } = useTranslation();
  const store = storeService.getStore();
  const dispatch = useDispatch();
  const classes = style();

  const profile = useSelector<ReturnType<typeof store.getState>, ProfileState>(getProfileState);

  useEffect(() => {
    if (isEmpty(profile)) {
      dispatch(fetchProfileApi());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitAction = async (values): Promise<void> => {
    dispatch(updateProfileApi(values));
  };

  return (
    <>
      <Header
        drawerMenu={true}
        title={t('DASHBOARD_PROFILE_HEADER')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />
      <View>
        <ScrollView classNames={classes.scrollView}>
          <ProfileForm submitAction={submitAction} />
        </ScrollView>
      </View>
    </>
  );
};

export default Profile;
