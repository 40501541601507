import axios, { AxiosResponse } from 'axios';

import { CustomerRequest, SuccessResponse } from '../../../types';

import {
  ChangePasswordParam,
  LoginApiResponse,
  LoginMfaParam,
  LoginMfaRecoveryParam,
  LoginParam,
  RefreshTokenParam,
  VerifyEmailParam,
  VerifyEmailResponse,
} from './auth.state';

import Config from 'config';

export class AuthService {
  async signup(payload: CustomerRequest): Promise<AxiosResponse<SuccessResponse>> {
    return axios.post<SuccessResponse>(`${Config.SERVER_URL}/customers/create`, payload);
  }

  async login(payload: LoginParam): Promise<AxiosResponse<LoginApiResponse>> {
    return axios.post<LoginApiResponse>(`${Config.SERVER_URL}/auth/login`, payload);
  }

  async loginGoogle(code: string): Promise<AxiosResponse<LoginApiResponse>> {
    return axios.post(`${Config.SERVER_URL}/auth/sso/google`, { code });
  }

  async loginMicrosoft(accessToken: string): Promise<AxiosResponse<LoginApiResponse>> {
    return axios.post(`${Config.SERVER_URL}/auth/sso/microsoft`, { accessToken });
  }

  async mfaLogin(payload: LoginMfaParam): Promise<AxiosResponse<LoginApiResponse>> {
    return axios.post<LoginApiResponse>(`${Config.SERVER_URL}/auth/login/mfa`, payload);
  }

  async mfaRecovery({
    userUuid,
    recoveryCode,
  }: LoginMfaRecoveryParam): Promise<AxiosResponse<SuccessResponse>> {
    return axios.post<SuccessResponse>(
      `${Config.SERVER_URL}/customers/user/mfaRecovery/${userUuid}`,
      { recoveryCode },
    );
  }

  async refreshToken(params: RefreshTokenParam): Promise<AxiosResponse<LoginApiResponse>> {
    return axios.post<LoginApiResponse>(`${Config.SERVER_URL}/auth/refreshToken`, null, { params });
  }

  async resendVerifyEmail(payload: VerifyEmailParam): Promise<AxiosResponse<VerifyEmailResponse>> {
    return axios.post<VerifyEmailResponse>(`${Config.SERVER_URL}/auth/requestVerifyEmail`, payload);
  }

  async changePassword(data: ChangePasswordParam): Promise<void> {
    await axios.post(`${Config.SERVER_URL}/auth/changePassword`, data);
  }
}

export default new AuthService();
