/* eslint-disable max-len  */
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RailzButton } from '@railzai/railz-uikit-react';
import RefreshIcon from '@material-ui/icons/Refresh';

import useStyle from '../style';

import Dropzone from '../dropzone';

import { FormInput } from 'components/form';

interface Props {
  disabled: boolean;
  pageMetaFavicon: string | File | null;
  setPageMetaFavicon: (imgOrNull: string | File | null) => void;
  pageMetaImage: string | File | null;
  setPageMetaImage: (imgOrNull: string | File | null) => void;
  defaultContent: any;
  form: any;
}

export default function SeoMetadataSection({
  disabled,
  defaultContent,
  pageMetaFavicon,
  setPageMetaFavicon,
  pageMetaImage,
  setPageMetaImage,
  form: { errors, control, setValue, clearErrors, getValues, watch },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyle();
  const [isDefault, setIsDefault] = useState(false);

  const watchAllFields = watch();
  useEffect(() => {
    const { metaTitle, metaDescription } = getValues();
    setIsDefault(
      metaTitle === defaultContent.metaTitle && metaDescription === defaultContent.metaDescription,
    );
  }, [watchAllFields]);

  return (
    <>
      <Grid container direction="column" className={disabled ? classes.uploadDisabled : ''}>
        <Typography className={classes.description}>
          {t('DASHBOARD_SETTINGS_SITES_SEO_DESCRIPTION')}
        </Typography>

        <Typography className={classes.subHeader + ' extraMargin'}>
          {t('DASHBOARD_SETTINGS_SITES_SEO_FAVICON_SUBTITLE')}
        </Typography>
        <Dropzone
          id="metaFavicon"
          onChange={(file): void => setPageMetaFavicon(file)}
          value={pageMetaFavicon}
          fileType="favicon"
          maxBlockSize={20000}
        />

        <Typography className={`${classes.uploadSubtitle} ${classes.contentInputItem}`}>
          {t('DASHBOARD_SETTINGS_SITES_SEO_FAVICON_RULES')}
        </Typography>

        <Typography className={classes.subHeader + ' extraMargin'}>
          {t('DASHBOARD_SETTINGS_SITES_SEO_IMAGE_SUBTITLE')}
        </Typography>
        <Dropzone
          id="metaImage"
          onChange={(file): void => setPageMetaImage(file)}
          value={pageMetaImage}
          fileType="imageWithSvg"
          maxBlockSize={100000}
        />

        <Typography className={`${classes.uploadSubtitle} ${classes.contentInputItem}`}>
          {t('DASHBOARD_SETTINGS_SITES_SEO_IMAGE_RULES')}
        </Typography>

        <Grid container>
          <div className={classes.inputContainer}>
            <FormInput
              defaultValue={defaultContent.metaTitle}
              label={t('DASHBOARD_SETTINGS_SITES_SEO_TITLE_LABEL')}
              placeholder={t('DASHBOARD_SETTINGS_SITES_SEO_TITLE_PLACEHOLDER')}
              id="metaTitle"
              name="metaTitle"
              margin="dense"
              testid="onboarding-title-input"
              fullWidth
              errorobj={errors}
              control={control}
              className={classes.contentInputItem}
              {...{ multiline: true, minRows: 2 }}
            />
            <FormInput
              defaultValue={defaultContent.metaDescription}
              label={t('DASHBOARD_SETTINGS_SITES_SEO_DESCRIPTION_LABEL')}
              placeholder={t('DASHBOARD_SETTINGS_SITES_SEO_DESCRIPTION_PLACEHOLDER')}
              id="metaDescription"
              name="metaDescription"
              margin="dense"
              testid="onboarding-body-input"
              fullWidth
              errorobj={errors}
              control={control}
              className={classes.contentInputItem}
              {...{ multiline: true, minRows: 4 }}
            />

            {!isDefault && (
              <RailzButton
                data-testid="reset-onboard-landing"
                type="text warning"
                buttonType="button"
                onClick={(): void => {
                  setValue('metaTitle', defaultContent.metaTitle);
                  setValue('metaDescription', defaultContent.metaDescription);
                  clearErrors();
                }}
                label={t('DASHBOARD_SETTINGS_SITES_RESET_CONTENT')}
              >
                <span slot="prefix">
                  <RefreshIcon fontSize="small" />
                </span>
              </RailzButton>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
