import React from 'react';

import { useTranslation } from 'react-i18next';

import useStyles from './styles';

import RailzLogo from 'assets/images/Logo.svg';

const Logo = ({ style, className }: { style?: any; className?: any }): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <img
      src={RailzLogo}
      alt={t('DASHBOARD_LOGO_ALT')}
      className={className ? className : classes.logo}
      style={style}
    />
  );
};

export default Logo;
