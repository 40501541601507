import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      lineHeight: 1.6,
      display: 'flex',
      gap: theme.spacing(2),
    },

    title: {
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: '1rem',
      display: 'contents',
    },

    descriptionYears: {
      marginBottom: theme.spacing(2),
    },

    ctaYears: {
      marginTop: theme.spacing(3),
    },

    chip: {
      fontSize: '0.75rem',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      color: theme.palette.grey['800'],
      backgroundColor: theme.palette.grey['200'],
    },

    ctaSaveContainer: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: 'white',
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey['300']}`,
    },
    labelNotShowAgain: {
      margin: 'auto',
      marginLeft: theme.spacing(1.25),
    },

    initialSyncTitle: {
      fontSize: '1.125rem',
      fontWeight: 600,
      color: theme.palette.grey[900],
      marginBottom: theme.spacing(1),
    },

    description: {
      marginBottom: theme.spacing(3),
    },

    yearsInput: {
      minWidth: theme.spacing(30),
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(17),
      },
    },

    view: {
      height: '100%',
    },

    viewHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
      gap: theme.spacing(2),
    },

    viewContent: {
      display: 'flex',
      overflow: 'visible',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      minHeight: '0',
      padding: `0 ${theme.spacing(4)}px`,
      position: 'relative',

      '& table tr:last-of-type td': {
        paddingBottom: `${theme.spacing(10)}px`,
      },

      '& td:has($url)': {
        width: '50px',
      },
    },

    offerText: {
      marginTop: theme.spacing(1),
      fontSize: '0.875rem',
    },

    link: {
      textDecoration: 'underline',
      color: ACTIVE_TEXT_COLOR,
      fontWeight: 600,
    },

    checkbox: {
      paddingLeft: 0,
    },

    checkboxContainer: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
  }),
);
