import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import { yupResolver } from '@hookform/resolvers/yup';

import { object, boolean } from 'yup';

import { useForm } from 'react-hook-form';

import useStyle from './styles';

import AlertDialog from 'components/dialog';

import { Integration } from 'types';
import { FormCheckBox } from 'components/form';

interface Props {
  integration: Integration;
  handleClose: (integration: Integration, confirmed: boolean) => void;
}

export const IntegrationDefaultConfirmationModal: React.FC<Props> = ({
  integration,
  handleClose,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      object().shape({
        confirmation: boolean().isTrue(t('DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_SELECT_ERROR')),
      }),
    ),
  });

  return (
    <AlertDialog
      showCloseButton
      isOpen={true}
      title={t('DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_TITLE')}
      onClose={(): void => handleClose(integration, false)}
      cancel={{
        label: t('DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_CTA_CANCEL'),
        type: 'gray',
        onClick: (): void => handleClose(integration, false),
      }}
      confirm={{
        label: t('DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_CTA_SAVE'),
        onClick: handleSubmit(() => handleClose(integration, true)),
      }}
    >
      <Typography className={classes.confirmationDescription} paragraph={true}>
        <Trans
          i18nKey="DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_DESCRIPTION"
          components={{ b: <strong /> }}
          values={{ name: integration.name }}
        />
      </Typography>
      <Box className={classes.containerCheck}>
        <FormCheckBox
          label={t('DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT')}
          control={control}
          name="confirmation"
          errorobj={errors}
        />
      </Box>
    </AlertDialog>
  );
};
