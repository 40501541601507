import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      fontSize: '12px',
      fontWeight: 600,
      marginTop: theme.spacing(2),
      textTransform: 'uppercase',
      color: theme.palette.grey['A700'],
    },
    date: {
      marginTop: theme.spacing(),
      fontSize: '14px',
    },
  });
});
