import React, { useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import useStyle from '../../style';
import Tabs from '../../../../components/tab';

import SummaryCharts from './tabulated-controls/summary-charts';
import BusinessInfo from './business-info';
import Reports from './tabulated-controls/reports';
import BankReconciliation from './tabulated-controls/banks.reconciliation';

import BusinessHistory from './tabulated-controls/business-history';

import { OverviewTabs } from 'types';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import {
  getBusinessSummaryFilter,
  getIsReportExportLoading,
  getReportExportController,
} from 'store/features/report/report.selector';
import { clearedOutFilterForCharts } from 'helpers/business-helpers/financial-summary-helper';
import { resetReportsApi, setReportSummaryFilter } from 'store/features/report/report.action';
import BlockerNavigation from 'components/blocker/blocker-navigation';

const Controls = (): JSX.Element => {
  const { t } = useTranslation();

  const classes = useStyle();

  const [querySearch, setUserSearch] = useQuerySearchUpdate();
  const [tabValue, setTabValue] = useState<OverviewTabs>(
    (+querySearch?.get('tab') as OverviewTabs) || OverviewTabs.SUMMARY,
  );
  const dispatch = useDispatch();
  const filter = useSelector(getBusinessSummaryFilter);
  const reportExportIsLoading = useSelector(getIsReportExportLoading);
  const reportExportController = useSelector(getReportExportController);

  const handleTabChanged = (tab: number): void => {
    if (tab === tabValue) return;
    setTabValue(tab as OverviewTabs);

    const newFilter = {
      ...clearedOutFilterForCharts,
      businessName: filter.businessName,
    };

    dispatch(setReportSummaryFilter(newFilter));
    dispatch(resetReportsApi());
    setUserSearch({ tab, ...newFilter });
  };

  const tabValues = [
    { label: t('DASHBOARD_BUSINESS_SUMMARY'), value: OverviewTabs.SUMMARY },
    { label: t('DASHBOARD_BUSINESS_REPORTS'), value: OverviewTabs.REPORTS },
    { label: t('DASHBOARD_BUSINESS_BANK_RECONCILIATION'), value: OverviewTabs.BANK_RECONCILIATION },
    { label: t('DASHBOARD_BUSINESS_CONNECTION_HISTORY'), value: OverviewTabs.BUSINESS_HISTORY },
  ];

  return (
    <Grid
      alignItems="flex-start"
      container
      spacing={2}
      id="summary-control-grid"
      data-testid="summary-control-grid"
    >
      {reportExportIsLoading && (
        <BlockerNavigation
          showMessage={true}
          handleDiscardModal={(): void =>
            reportExportController.abort('Canceled by user from navigation')
          }
          title="DASHBOARD_REPORT_DOWNLOAD_MODAL_UNSAVED_TITLE"
          description="DASHBOARD_REPORT_DOWNLOAD_MODAL_UNSAVED_BODY"
          confirmLabel="DASHBOARD_REPORT_DOWNLOAD_MODAL_UNSAVED_CTA_LEAVE"
          cancelLabel="DASHBOARD_REPORT_DOWNLOAD_MODAL_UNSAVED_CTA_STAY"
          setLastLocation={undefined}
          continueAfterDiscard={true}
        />
      )}
      <BusinessInfo />
      <Grid item xs={12} md={12}>
        <Paper className={classes.summary}>
          <Tabs value={tabValue} handleChange={handleTabChanged} tabs={tabValues} />
          <TabComponent value={tabValue} changeOnDefault={handleTabChanged} />
        </Paper>
      </Grid>
    </Grid>
  );
};

const TabComponent = ({ value, changeOnDefault }): JSX.Element => {
  let component: JSX.Element;
  switch (value) {
    case OverviewTabs.SUMMARY:
      component = <SummaryCharts />;
      break;
    case OverviewTabs.REPORTS:
      component = <Reports />;
      break;
    case OverviewTabs.BANK_RECONCILIATION:
      component = <BankReconciliation />;
      break;
    case OverviewTabs.BUSINESS_HISTORY:
      component = <BusinessHistory />;
      break;
    default:
      component = <SummaryCharts />;
      changeOnDefault(OverviewTabs.SUMMARY);
      break;
  }

  return component;
};

export default Controls;
