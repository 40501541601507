import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import RecoveryDialog from '../mfa-recovery';

import useStyle from '../style';

import { ErrorResponse } from '../../../types/interface/error.interface';

import {
  doLoginMfaApi,
  getErrorResponse,
  getUserResponse,
  isUserLoggedIn,
  resetAuthError,
} from '../../../store/features/auth';

import storeService from '../../../store';

import { FormInput } from 'components/form';
import { validationSchema } from 'pages/security/mfa';
import { View } from 'components';
import { AuthType, LoginMfa } from 'types';
import { showSnackbar } from 'helpers/common.helper';

import useQuerySearch from 'hooks/use-query-search';

import Title from 'components/title';

import Logo from 'components/logo';

interface IFormValue {
  authCode: string;
}

const initialValues: IFormValue = {
  authCode: '',
};

const MfaLogin: React.FC = () => {
  const [isRecoveryDialogVisible, setIsRecoveryDialogVisible] = useState(false);
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const store = storeService.getStore();
  const query = useQuerySearch();

  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse | any>(
    getErrorResponse,
  );
  const userMfa = useSelector<ReturnType<typeof store.getState>, LoginMfa>(getUserResponse);
  const isLoggedIn = useSelector<ReturnType<typeof store.getState>, boolean>(isUserLoggedIn);

  const confirmRecoveryDialog = {
    label: t('DASHBOARD_API_BACKUP_CTA_ACTION'),
    onClick: (): void => setIsRecoveryDialogVisible(false),
  };

  const openRecoveryDialog = (): void => {
    setIsRecoveryDialogVisible(true);
  };

  const closeRecoveryDialog = (): void => {
    setIsRecoveryDialogVisible(false);
  };

  useEffect(() => {
    if (!userMfa || !userMfa?.uuid) {
      history.replace('/login');
    }
    // eslint-disable-next-line
  }, [userMfa, history]);

  useEffect(() => {
    if (error) {
      dispatch(resetAuthError());
      showSnackbar({
        message: t('DASHBOARD_LOGIN_MFA_INVALID'),
        type: 'error',
      });
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(query.get('redirect') || '/');
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const { handleSubmit, errors, formState, control } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;

  const onSubmit = async ({ authCode }): Promise<void> => {
    dispatch(doLoginMfaApi({ token: authCode, userUuid: userMfa.uuid, authType: AuthType.GOOGLE }));
  };

  return (
    <>
      <Container role="main" style={{ maxWidth: 480, margin: 'auto', paddingBottom: 24 }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <View>
            <View alignItems="center" className={classes.mfaLoginHeader}>
              <Logo />
              <Title title="DASHBOARD_SIGNIN_MFA_HEADER" className={classes.title} />
              <Typography color="textSecondary" variant="subtitle1" align="center">
                {t('DASHBOARD_SIGNIN_MFA_DESCRIPTION')}
              </Typography>
            </View>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <FormInput
                  name="authCode"
                  type="text"
                  pattern="^\d{6}$"
                  placeholder="DASHBOARD_SIGNIN_MFA_AUTH_CODE_PLACEHOLDER"
                  label="DASHBOARD_SIGNIN_MFA_AUTH_CODE_LABEL"
                  fullWidth
                  errorobj={errors}
                  control={control}
                  inputProps={{ maxLength: 6 }}
                  testid="authcode-input"
                />
              </Grid>
              <Grid xs={12} item>
                <RailzButton
                  className={classes.loginButton}
                  buttonType="submiy"
                  label={t('DASHBOARD_SIGNIN_MFA_CTA')}
                  size="large"
                  grow
                  isDisabled={isSubmitting}
                  data-testid="submit"
                />

                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography color="textSecondary" variant="subtitle1">
                    {t('DASHBOARD_SIGNIN_MFA_FORGOT')}
                  </Typography>
                  <RailzButton
                    className={classes.twoFactorButton}
                    type="text primary"
                    onButtonClick={openRecoveryDialog}
                    label={t('DASHBOARD_SIGNIN_MFA_FORGOT_CTA')}
                  />
                </Box>
              </Grid>
            </Grid>
          </View>
        </form>
      </Container>
      <RecoveryDialog
        isOpen={isRecoveryDialogVisible}
        confirm={confirmRecoveryDialog}
        close={closeRecoveryDialog}
      />
    </>
  );
};
export default MfaLogin;
