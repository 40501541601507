/* eslint-disable max-len */
import React from 'react';

export const ExpandArrowsIcon: React.FC<{
  color?: string;
}> = ({ color = '#424242' }): React.ReactElement => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10V5.55556H1.11111V8.11111L8.11111 1.11111H5.55556V0H10V4.44444H8.88889V1.88889L1.88889 8.88889H4.44444V10H0Z"
        fill={color}
      />
    </svg>
  );
};
