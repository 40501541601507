import React, { useCallback, useEffect, useState } from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import storeService from '../../../store';

import { resetAuthInformation } from '../../../store/features/auth';

import { EventService } from '../../../services';

import Data from '../../../data/data.json';

import useQuerySearch from '../../../hooks/use-query-search';

import useStyle from './style';

import SignUpBG from 'assets/images/SignUpBG.svg';
import Logo from 'components/logo';
import { resetBusinessInformation } from 'store/features/business/business.action';
import { resetIntegrationsInformation } from 'store/features/integration/integration.action';
import { logoutErrorSnackbar } from 'layout/helpers/util';

import {
  getCustomerError,
  getCustomerState,
  isCustomerSendEmailApiError,
  isCustomerSendEmailApiSuccess,
  getCustomerSessionURL,
  isQuestionnaireComplete,
} from 'store/features/account/customer/customer.selector';
import { View } from 'components';
import AlertDialog from 'components/dialog';
import { Plan } from 'types';
import { ErrorResponse } from 'types/interface/error.interface';
import { fetchProfileApi } from 'store/features/account/profile/profile.action';
import ContactSalesModal from 'components/contact-sales-modal';
import {
  updateCompanyApi,
  createStripeSignupCheckoutApi,
  resetSendEmailResponse,
} from 'store/features/account/customer/customer.action';
import TermsConditionsModal from 'components/terms-conditions-modal';

export default function SelectPlan(): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = storeService.getStore();
  const history = useHistory();
  const location = useLocation();
  const querySearch = useQuerySearch();

  const [contactSalesOpenModal, setContactSalesOpenModal] = useState(false);
  const [contactSalesSuccessModal, setContactSalesSuccessModal] = useState(false);

  const error = useSelector<ReturnType<typeof store.getState>, ErrorResponse>(getCustomerError);
  const customerState = useSelector(getCustomerState);
  const sendEmailSuccess = useSelector(isCustomerSendEmailApiSuccess);
  const sendEmailError = useSelector(isCustomerSendEmailApiError);
  const [termsOpenModal, setTermsOpenModal] = useState(false);
  const sessionURL = useSelector(getCustomerSessionURL);
  const customer = useSelector(getCustomerState);

  // eslint-disable-line no-unused-vars
  const onPlanSelect = async (code: string): Promise<void> => {
    if (code === Plan.FREE.toString()) {
      history.replace('/');
      dispatch(fetchProfileApi());
    } else if (code === Plan.CUSTOM.toString() && customerState?.billingPlan !== Plan.CUSTOM) {
      setContactSalesOpenModal(true);
      return;
    } else if (code === Plan.PAY_AS_YOU_GO.toString()) {
      setTermsOpenModal(true);
    }
  };

  useEffect(() => {
    if (error) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: t('DASHBOARD_SIGNUP_SELECT_PLAN_UNKNOWN_FAILURE'),
      });
    }
  }, [error]);

  useEffect(() => {
    if (
      querySearch &&
      querySearch.get('success') === 'false' &&
      customer?.billingPlan === null &&
      customer?.error?.message?.length
    ) {
      if (customer?.error?.message?.includes('createCheckout')) {
        EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
          showAs: 'snackbar',
          type: 'error',
          message: t('DASHBOARD_SIGNUP_GENERIC_ERROR'),
        });
      }
    }
  }, [querySearch, customer]);

  useEffect(() => {
    if (sendEmailSuccess) {
      setContactSalesSuccessModal(true);
    }
    if (sendEmailError) {
      EventService.emit(EventService.EVENT.SHOW_NOTIFICATION, {
        showAs: 'snackbar',
        type: 'error',
        message: t('DASHBOARD_BILLING_CONTACT_SALES_MODAL_ERROR'),
      });
    }
    dispatch(resetSendEmailResponse());
  }, [sendEmailSuccess]);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      store.dispatch(resetAuthInformation());
      store.dispatch(resetBusinessInformation());
      store.dispatch(resetIntegrationsInformation());
    } catch (err) {
      logoutErrorSnackbar(err);
    }
  }, []);

  const colorByPlan = (code: string): string => {
    if (code.includes(Plan.FREE.toString())) {
      return classes.freePlan;
    } else if (code.includes(Plan.PAY_AS_YOU_GO.toString())) {
      return classes.payAsGoPlan;
    } else {
      return classes.enterPricePlan;
    }
  };

  const handleConfirmation = (): void => {
    dispatch(updateCompanyApi({ billingPlan: Plan.FREE }));
    history.push('/');
  };

  const closeTermsModal = async (accepted: boolean): Promise<void> => {
    if (accepted) {
      window.onbeforeunload = null;
      dispatch(createStripeSignupCheckoutApi());
    } else {
      setTermsOpenModal(false);
    }
  };

  useEffect(() => {
    if (sessionURL) {
      document.location.href = sessionURL;
      setTermsOpenModal(false);
    }
  }, [sessionURL]);

  useEffect(() => {
    if (
      isQuestionnaireComplete &&
      (!location.state || (location.state && location.state['from'] != '/questionnaire'))
    ) {
      history.push('/');
    }
    //needed to avoid the user go back during this flow
    window.onbeforeunload = function (): void {
      handleLogout();
      return history.push('/login');
    };
    //needed to remove the onbeforeunload function when the user leave the page
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <Box className={classes.mainContainer}>
      <ContactSalesModal
        open={contactSalesOpenModal}
        close={(): void => setContactSalesOpenModal(false)}
        loading={customerState?.loading}
        body="DASHBOARD_SIGNUP_SELECT_PLAN_MODAL_BODY"
      />
      <AlertDialog
        isOpen={contactSalesSuccessModal}
        title={t('DASHBOARD_SIGNUP_SELECT_PLAN_MODAL_ENTERPRISE_SUCCESS')}
        onClose={handleConfirmation}
        confirm={{
          label: t('DASHBOARD_SIGNUP_SELECT_PLAN_MODAL_ENTERPRISE_CTA'),
          onClick: handleConfirmation,
        }}
      >
        <div className={classes.confirmationBody}>
          <div>{t('DASHBOARD_SIGNUP_SELECT_PLAN_MODAL_ENTERPRISE_SUCCESS_BODY')}</div>
        </div>
      </AlertDialog>
      <TermsConditionsModal
        open={termsOpenModal}
        close={closeTermsModal}
        loading={customer?.loading}
      />
      <div className={classes.illustration}>
        <img src={SignUpBG} alt="logo" />
      </div>
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Logo className={classes.logoIcon} />
          <Typography className={classes.title}>{t('DASHBOARD_SIGNUP_SELECT_PLAN')}</Typography>
        </Box>
      </Box>
      <Box className={classes.plansContainer}>
        {Data.map((item, index) => {
          const isFreePlan = item.code.includes('free');
          return (
            <Grid
              item
              key={index}
              tabIndex={0}
              role="group"
              aria-labelledby={`plan-title-${item.tag}`}
              aria-describedby={`plan-description-${item.tag}`}
              className={classes.gridItem}
            >
              <Box
                className={[
                  classes.planDescription,
                  !isFreePlan ? classes.plusPlans : classes.freePlanContainer,
                ].join(' ')}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={[classes.cardPillSection, colorByPlan(t(item.code))].join(' ')}
                >
                  <Typography variant="h3" className={classes.cardTitle}>
                    {t(item.tag)}
                  </Typography>
                </Grid>
                <Typography
                  id={`plan-title-${item.tag}`}
                  variant="h4"
                  className={classes.cardSubTitle}
                >
                  {t(item.type)}
                </Typography>
                <Typography id={`plan-description-${item.tag}`} className={classes.description}>
                  <Trans i18nKey={item.description} />
                </Typography>
                <Divider className={classes.divider}></Divider>
                <View className={classes.featureContainer}>
                  <ul className={classes.featureListContainer}>
                    {item.features.map((feature, index) => (
                      <li key={index} className={classes.featureText}>
                        <DoneIcon color="primary" className={classes.checkmark} />
                        {t(feature)}
                      </li>
                    ))}
                    {item.excludedFeatures.map((service, index) => (
                      <li key={index} className={classes.featureText}>
                        <CloseIcon color="error" className={classes.checkmark} />
                        {t(service)}
                      </li>
                    ))}
                  </ul>
                </View>
                <RailzButton
                  className={classes.cardButton}
                  onButtonClick={(): Promise<void> => onPlanSelect(t(item.code))}
                  type={isFreePlan ? 'primary' : 'secondary'}
                  label={t(item.buttonSignUp)}
                  data-testid={`${item.code}-btn`}
                />
              </Box>
            </Grid>
          );
        })}
      </Box>
      <Box onClick={handleLogout} data-testid={'logout-btn'}>
        <Typography className={classes.logout}>
          <LogoutIcon />
          {t('DASHBOARD_SIGNUP_QUESTIONNAIRE_LOGOUT')}
        </Typography>
      </Box>
    </Box>
  );
}
