import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR } from 'providers/theme-provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    bodyTag: {
      backgroundColor: theme.palette.grey[200],
      fontWeight: theme.typography.fontWeightBold as number,
      borderRadius: '3px',
    },
    titleContainer: {
      marginBottom: theme.spacing(1),
    },
    section: {
      marginBottom: theme.spacing(3),
    },
    code: {
      padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px !important`,
      borderRadius: '8px',
      fontSize: '0.8rem',
      lineHeight: '1.8',
      backgroundColor: `${theme.palette.grey[100]} !important`,
      display: 'block',
    },
    plainTextCode: {
      overflowY: 'auto',
    },
    link: {
      textDecoration: 'underline',
      color: ACTIVE_TEXT_COLOR,
      fontWeight: Number(theme.typography.fontWeightBold),
    },
    copiedText: {
      fontSize: '0.75rem',
      marginTop: theme.spacing(2),
    },
    subHeader: {
      fontSize: '1.125rem',
      marginTop: theme.spacing(3),
      fontWeight: theme.typography.fontWeightBold as number,
    },
    subHeaderH4: {
      fontSize: '1rem',
      marginTop: theme.spacing(3),
      fontWeight: theme.typography.fontWeightBold as number,
    },
    paragraph: {
      width: '80%',
      maxWidth: '80ch',
      marginTop: theme.spacing(0.5),
      marginBottom: 0,
      color: theme.palette.grey[600],
    },
    toggleContainer: {
      '& .MuiTypography-paragraph': {
        marginTop: theme.spacing(1),
      },
      '& .MuiFormControlLabel-labelPlacementStart': {
        margin: 0,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormControlLabel-label': {
        fontWeight: theme.typography.fontWeightMedium as number,
        color: theme.palette.primary.main,
      },
    },
  }),
);
