import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import moment, { Moment as MomentType } from 'moment-timezone';

import DatePicker from 'components/date-picker';
import { ReportFrequency } from 'types';

interface Props {
  dateView: any;
  selectedStartDate: MomentType;
  selectedEndDate: MomentType;
  selectedReportFrequency: ReportFrequency;
  maxEndDate: MomentType;
  firstRecordDate: Date | null;
  disableDates: boolean;
  handleSelected: (string) => void;
}

export default function EndDate({
  dateView,
  selectedStartDate,
  selectedEndDate: parentSelectedEndDate,
  selectedReportFrequency,
  maxEndDate,
  firstRecordDate,
  disableDates,
  handleSelected,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().subtract(1, 'month').endOf(ReportFrequency.MONTH),
  );

  useEffect(() => {
    if (parentSelectedEndDate && parentSelectedEndDate.isValid()) {
      setSelectedEndDate(parentSelectedEndDate);
    }
  }, [parentSelectedEndDate]);

  const onChange = (date): void => {
    setSelectedEndDate(date);
    handleSelected && handleSelected(date);
  };

  return (
    <DatePicker
      label={t('DASHBOARD_REPORT_END_DATE_LABEL')}
      placeholder={t('DASHBOARD_REPORT_END_DATE_PLACEHOLDER')}
      view={dateView}
      value={selectedEndDate}
      minDate={selectedStartDate?.toDate() || firstRecordDate}
      invalidDateMessage={t('DASHBOARD_REPORT_DATE_INVALID_DATE')}
      minDateErrorMessage={t('DASHBOARD_REPORT_DATE_ERROR_END_BEFORE_START')}
      maxDateErrorMessage={
        selectedReportFrequency
          ? t('DASHBOARD_REPORT_DATE_ERROR_END_CURRENT_YEAR', {
              period: maxEndDate.format('YYYY-MM-DD'),
            })
          : t('DASHBOARD_REPORT_DATE_ERROR_END_BEFORE_START_NO_FREQUENCY')
      }
      ariaLabel={t('DASHBOARD_REPORT_END_DATE_ARIA')}
      disabled={disableDates}
      maxDate={maxEndDate.toDate()}
      onChange={onChange}
      size="small"
    />
  );
}
