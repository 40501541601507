import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      whiteSpace: 'pre-line',
    },
    iconInputUrl: {
      color: theme.palette.grey[800],
      paddingRight: 0,
      flexShrink: 0,
      height: theme.spacing(5),
      width: theme.spacing(5),
      padding: 0,
    },
  }),
);
