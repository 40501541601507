import { ApiKeyState, API_KEY_INITIAL_STATE, API_KEY_STORE_NAME } from './api-key/api-key.state';
import {
  WebhookConfigState,
  WEBHOOK_CONFIG_INITIAL_STATE,
  WEBHOOK_CONFIG_STORE_NAME,
} from './webhook-config/webhook-config.state';
import {
  WebhookSecretState,
  WEBHOOK_SECRET_INITIAL_STATE,
  WEBHOOK_SECRET_STORE_NAME,
} from './webhook-secret/webhook-secret.state';
import { WEBHOOK_INITIAL_STATE, WEBHOOK_STORE_NAME, WebhookState } from './webhook/webhook.state';

/**
 * Name of the developer Store
 */
export const DEVELOPER_STORE_NAME = 'developer';

export const DEVELOPER_INITIAL_STATE: DeveloperState = {
  [WEBHOOK_STORE_NAME]: WEBHOOK_INITIAL_STATE,
  [WEBHOOK_SECRET_STORE_NAME]: WEBHOOK_SECRET_INITIAL_STATE,
  [WEBHOOK_CONFIG_STORE_NAME]: WEBHOOK_CONFIG_INITIAL_STATE,
  [API_KEY_STORE_NAME]: API_KEY_INITIAL_STATE,
};

export interface DeveloperState {
  [WEBHOOK_STORE_NAME]: WebhookState;
  [WEBHOOK_SECRET_STORE_NAME]: WebhookSecretState;
  [WEBHOOK_CONFIG_STORE_NAME]: WebhookConfigState;
  [API_KEY_STORE_NAME]: ApiKeyState;
}
