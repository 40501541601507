import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    view: {
      height: '100%',
    },

    viewHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
      gap: theme.spacing(2),
    },

    headerContent: {
      width: '100%',
    },

    title: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
      fontSize: '1.25rem',
      color: theme.palette.grey[900],
    },

    primaryCta: {
      alignSelf: 'flex-end',
    },

    viewContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      minHeight: '0',
      padding: `0 ${theme.spacing(4)}px`,
      position: 'relative',

      '& table': {
        paddingBottom: `${theme.spacing(10)}px`,
      },
    },

    roleDescription: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      gap: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    },

    role: {
      textTransform: 'capitalize',
    },

    ownerText: {
      color: theme.palette.primary.main,
      fontWeight: 800,
      textTransform: 'uppercase',
      fontSize: '0.625rem',
      letterSpacing: '.1em',
    },

    youText: {
      color: theme.palette.primary.main,
      fontWeight: 800,
      fontSize: '0.625rem',
      letterSpacing: '.1em',
      textTransform: 'uppercase',
      marginLeft: theme.spacing(2),
    },

    subtitle: {
      fontWeight: 400,
      fontSize: '1rem',
      color: theme.palette.grey[800],
    },

    email: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },

    emailLabel: {
      wordBreak: 'break-word',
    },

    pagination: {
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      backgroundColor: 'white',
      zIndex: 1,
      boxShadow: '0 0 32px -8px rgba(0, 0, 0, 0.2)',
      paddingBottom: theme.spacing(5),

      '& .Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);
