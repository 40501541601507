import axios, { AxiosResponse } from 'axios';

import Config from '../../../config';
import { ReportTypeUrlMapping } from '../../../helpers/report-helpers/constants';

import { DataTypesResponse, ReportParam, BusinessHistoryResponseDto } from './report.state';

class ReportService {
  public async getReport({ type, params }: ReportParam, signal?: AbortSignal): Promise<any> {
    return axios.get(`${Config.FEEDER_SERVER}/${ReportTypeUrlMapping[type]}`, { params, signal });
  }

  public async getDataTypes(): Promise<AxiosResponse<DataTypesResponse[]>> {
    return axios.get<DataTypesResponse[]>(
      `${Config.FEEDER_SERVER}/v2/data/dataTypes?isExtended=true`,
    );
  }

  public async getBusinessHistoryBusiness({
    params,
  }): Promise<AxiosResponse<BusinessHistoryResponseDto>> {
    return axios.get<BusinessHistoryResponseDto>(
      `${Config.FEEDER_SERVER}/v2/businesses/connectionHistory/${params.uuid}`,
    );
  }
}

export default new ReportService();
