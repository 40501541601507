import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { accountReducerConfig } from './features/account/account.reducer';
import { businessReducerConfig } from './features/business/business.reducer';
import { integrationReducerConfig } from './features/integration/integration.reducer';
import { reportReducerConfig } from './features/report/report.reducer';
import { authReducerConfig } from './features/auth';
import { developerReducerConfig } from './features/developer/developer.reducer';
import { connectReducerConfig } from './features/connect';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

export const persistedReducer = persistCombineReducers(persistConfig, {
  ...accountReducerConfig,
  ...businessReducerConfig,
  ...integrationReducerConfig,
  ...reportReducerConfig,
  ...authReducerConfig,
  ...developerReducerConfig,
  ...connectReducerConfig,
});
