import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    headDescription: {
      padding: theme.spacing(3),
    },
    scrollView: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(10)}px`,
    },
  }),
);
