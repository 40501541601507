import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

import style from './style';

export default function AlertSuperAdminBillingFeatures(): JSX.Element {
  const classes = style();
  const { t } = useTranslation();

  return (
    <Alert severity="info" icon={<InfoIcon />} classes={{ root: classes.alert }}>
      <AlertTitle>
        <Typography variant="h2" className={classes.heading}>
          {t('DASHBOARD_BILLING_INFORMATION_MODAL_HEADER')}
        </Typography>
      </AlertTitle>
      <Typography variant="body2">
        {t('DASHBOARD_BILLING_INFORMATION_MODAL_DESCRIPTION')}
      </Typography>
    </Alert>
  );
}
