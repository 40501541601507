import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyle from './style';

import Loading from 'components/loading';

interface ButtonProps {
  buttonText: string;
  type: 'small' | 'large';
  buttonTextClassName?: string;
  onClick?: () => void;
  variant?: 'contained' | 'outlined';
  className?: string;
  icon?: React.ReactElement;
  disableElevation?: boolean;
  loading?: boolean;
  disabled?: boolean;
  testid?: string;
  color?: 'primary' | 'secondary' | 'inherit' | 'default';
}

export default function RailzButton(props: ButtonProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyle();
  const {
    buttonText,
    className,
    buttonTextClassName,
    variant = 'contained',
    onClick,
    type,
    disabled = false,
    icon = false,
    disableElevation = false,
    loading = false,
    testid = '',
    color = 'primary',
  } = props;

  const renderIcon = (): React.ReactElement => {
    if (icon) {
      return React.cloneElement(icon, {
        id: `railz-${type}-button-icon`,
        'data-testid': `railz-${type}-button-icon`,
      });
    }
  };

  return (
    <Button
      {...(onClick ? { onClick } : {})}
      color={color}
      variant={variant}
      className={classes[`${type}Button`] + ' ' + className}
      id={`railz-${type}-button`}
      data-testid={`railz-${type}-button`}
      disableElevation={disableElevation}
      disabled={disabled || loading}
    >
      {loading && (
        <Loading
          loading={false}
          testId={`railz-${testid}`}
          isBackdrop={false}
          loadingStyle={{ width: '19px', height: '19px' }}
        />
      )}
      {icon && <span className={classes.icon}> {renderIcon()} </span>}
      <span className={classes.buttonText + ' ' + buttonTextClassName}>{t(buttonText)}</span>
    </Button>
  );
}
