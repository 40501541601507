import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    tooltipContainer: {
      '& .MuiTooltip-tooltip, & .MuiTooltip-arrow::before': {
        background: `var(--primary-dark-green, #003032)`,
        fontSize: '0.875rem',
      },
      '& .MuiTooltip-tooltip': {
        padding: theme.spacing(2),
      },
    },
    tooltipIcon: {
      marginLeft: theme.spacing(1),
      fontSize: '0.875rem',
      color: 'var(--greyscale-grey-800, #424242)',
    },

    confirmationDescription: {
      color: theme.palette.grey[800],
      margin: 0,
      marginTop: theme.spacing(-1),
    },

    confirmationLabel: {
      color: theme.palette.grey[800],
      margin: 0,
      marginTop: theme.spacing(3),
    },

    checkTableContainer: {
      textAlign: 'right',

      '& label': {
        marginRight: '0 !important',
      },
    },

    containerCheck: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      whiteSpace: 'normal',
      textAlign: 'right',
      lineHeight: 1.4,
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: theme.spacing(-1),
      },
      '& .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root': {
        color: theme.palette.grey[400],
      },
    },

    checkbox: {
      marginLeft: theme.spacing(-1.5),
    },
    confirmationInput: {
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: theme.spacing(0),
      },
    },
  }),
);
