import { AppBar, Avatar, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { RailzButton } from '@railzai/railz-uikit-react';

import storeService from '../../store';

import { isSandbox } from '../../store/features/auth';

import {
  getProfileState,
  getUserRole,
} from '../../store/features/account/profile/profile.selector';

import { ProfileState } from '../../store/features/account/profile/profile.state';

import { sentenceCase } from '../../helpers/common.helper';

import RailzChip from '../chip';

import BackButton from './back-button.component';
import DrawerMenuButton from './drawer-button.component';
import IconButton from './icon-button.component';
import useStyle from './style';

import SandboxBanner from './sandbox-banner.compoment';

import FreeLimitBanner from './free-limit-banner.component';

import TeamSwitchSelector from './team-switch.component';

import { Plan, Role } from 'types';

import AlertNoIntegrations from 'components/alert/alert-no-integrations/alert-no-integrations';

import { View } from 'components';
import { getBusinessStats } from 'store/features/business/business.selector';
import { BusinessEventStat } from 'store/features/business/business.state';
interface IProps {
  title: any;
  leftComponent: any;
  rightComponent: any;
  drawerMenu: boolean;
  testId: string;
  titleAriaLabel?: string;
}

const Header = (props: IProps): React.ReactElement => {
  const { title, leftComponent, rightComponent, drawerMenu, testId } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyle();
  const store = storeService.getStore();
  const { t } = useTranslation();
  const user = useSelector<ReturnType<typeof store.getState>, ProfileState>(getProfileState);
  const role = useSelector<ReturnType<typeof store.getState>, Role>(getUserRole);
  const sandbox = useSelector<ReturnType<typeof store.getState>, boolean>(isSandbox);

  const userIsOnFreePlan = user?.billingPlan === Plan.FREE;

  const storeStats = useSelector<ReturnType<typeof store.getState>, BusinessEventStat>(
    getBusinessStats,
  );

  return (
    <>
      <AppBar className={classes.headerBar} position="relative" elevation={0} data-testid={testId}>
        <Toolbar>
          {drawerMenu && <DrawerMenuButton testId={testId + '-drawer'} />}
          {Boolean(leftComponent) && (
            <View style={{ marginLeft: -12, marginRight: 16 }} data-testid={testId + '-left'}>
              {leftComponent}
            </View>
          )}

          {Boolean(title) && (
            <Typography
              id="title"
              variant="h1"
              style={{ flexGrow: 1 }}
              className={classes.title}
              tabIndex={0}
              data-testid={testId + '-title'}
              aria-label={props.titleAriaLabel}
            >
              {title}
            </Typography>
          )}

          {Boolean(rightComponent) && (
            <View style={{ marginLeft: 16, marginRight: -12 }} data-testid={testId + '-right'}>
              {rightComponent}
            </View>
          )}

          {Boolean(userIsOnFreePlan) && (
            <>
              <RailzChip
                chipText="DASHBOARD_HEADER_FREE"
                className={classes.chipStyle}
                testId={testId}
              />

              <Link
                to="/account/billing"
                className={classes.chipContainer}
                data-testid={testId + '-linkToBilling'}
                aria-label={t('DASHBOARD_PROFILE_NAME_LABEL')}
              >
                <RailzButton
                  label={t('DASHBOARD_HEADER_UPGRADE_NOW')}
                  type="secondary"
                  size="small"
                  className={classes.upgradeNowButton}
                  data-testid={testId + '-upgrade-now'}
                />
              </Link>
            </>
          )}
          <TeamSwitchSelector />

          {user?.firstName || user?.lastName ? (
            <Link
              to="/account/profile"
              style={{ textDecoration: 'none' }}
              data-testid={testId + '-link'}
              aria-label={t('DASHBOARD_PROFILE_NAME_LABEL')}
            >
              <View
                role="button"
                aria-label={t('DASHBOARD_PROFILE_NAME_LABEL')}
                alignItems="center"
                flexDirection="row"
              >
                <Avatar
                  className={classes.avatar}
                  src={user?.profilePic}
                  alt=""
                  data-testid={testId + '-avatar'}
                />
                <Typography className={classes.userName} data-testid={testId + '-name'}>
                  {sentenceCase(user?.firstName)} {sentenceCase(user?.lastName).charAt(0)}
                  {user?.lastName && '.'}
                </Typography>
              </View>
            </Link>
          ) : null}
        </Toolbar>
      </AppBar>
      {sandbox && <SandboxBanner />}
      {!sandbox && userIsOnFreePlan && (
        <FreeLimitBanner amountConnections={storeStats?.businesses.allTimeActive} />
      )}
      {[Role.SUPER_ADMINISTRATOR, Role.ADMINISTRATOR].includes(role) && (
        <AlertNoIntegrations sandbox={sandbox} />
      )}
    </>
  );
};

Header.BackButton = BackButton;
Header.IconButton = IconButton;
export default Header;
