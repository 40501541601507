import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Pagination } from '@material-ui/lab';

import { Link } from '@material-ui/core';

import { getBusinessesMeta } from '../../store/features/business/business.selector';

import {
  expandBusinessInfo,
  getColumnHeaders,
} from '../../helpers/business-helpers/business-helpers';

import BusinessControls from './business-controls';
import BusinessTable from './business-table/table';
import BusinessEventProvider from './provider/business-event';
import useStyles from './style';
import { AllBusinessStatus, BusinessStatus } from './types/enums';

import { BusinessWithQuery } from './types/interfaces';

import useDefaultStyles from 'assets/styles/style';

import { Header, ScrollView, View } from 'components';
import PageDescription from 'components/page-description/page-description';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import { getUserRole } from 'store/features/account/profile/profile.selector';
import { isSandbox } from 'store/features/auth/auth.selector';
import { fetchBusinesses, setSelectedBusiness } from 'store/features/business/business.action';
import { PaginationProps, PAGINATION_LIMIT } from 'types';
import { openNewTab } from 'helpers/open-new-tab';
import { getIntegrationsWithConnections } from 'store/features/integration/integration.selector';
import { BusinessFilter } from 'store/features/business/business.state';
import { fetchIntegrationsApi } from 'store/features/integration/integration.action';

const Business: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [querySearch] = useQuerySearchUpdate();
  const defaultClasses = useDefaultStyles();

  const { businesses, meta, loading: isLoading } = useSelector(getBusinessesMeta);
  const role = useSelector(getUserRole);
  const isSandboxEnabled = useSelector(isSandbox);
  const integrations = useSelector(getIntegrationsWithConnections);

  const showStatus = querySearch?.get('show');
  const [orderBy, setOrderBy] = useState<keyof any>('updatedAt');
  const [order, setOrder] = useState<any>('desc');
  const [status, setStatus] = useState<AllBusinessStatus>(
    AllBusinessStatus[showStatus] || AllBusinessStatus.ACTIVE,
  );
  const [integrationUuids, setIntegrationUuids] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [businessName, setBusinessName] = useState<string>('');
  const [businessesWithInitialQuery, setBusinessesWithInitialQuery] = useState<BusinessWithQuery[]>(
    [],
  );

  const columnsHeader = getColumnHeaders(isSandboxEnabled, role);

  const refreshBusinesses = (params?: PaginationProps): any => {
    const localOrderBy = params?.orderBy || orderBy;
    const localOrder = params?.order || order;
    let localOffset = Number((currentPage - 1) * PAGINATION_LIMIT);
    if (params?.offset !== undefined) localOffset = params?.offset;
    let localStatus = (params?.status || status) as BusinessStatus;
    if (!Object.keys(BusinessStatus).includes(localStatus.toUpperCase())) localStatus = null;

    let integrationsToFilterBy = params?.integrationUuids || integrationUuids;
    if (integrationUuids.length === integrations.length) {
      integrationsToFilterBy = undefined;
    }

    const payload: BusinessFilter = {
      orderBy: localOrder === 'asc' ? localOrderBy : `-${String(localOrderBy)}`,
      limit: params?.limit || PAGINATION_LIMIT,
      offset: localOffset,
      status: localStatus,
      businessNameOrUuid: params?.businessName,
      integrationUuids: integrationsToFilterBy,
    };

    return fetchBusinesses(payload);
  };

  const applySearchBusiness = (): void => {
    setCurrentPage(1);
    dispatch(fetchIntegrationsApi());
    dispatch(refreshBusinesses({ status: status, businessName: businessName, offset: 0 }));
  };

  const handleRequestSort = (key) => (): void => {
    if (key !== 'serviceList') {
      const isAsc = orderBy === key && order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setOrder(newOrder);
      setOrderBy(key);

      setCurrentPage(1);
      dispatch(refreshBusinesses({ order: newOrder, orderBy: key, offset: 0 }));
    }
  };

  const updateBusinesses = (): void => {
    dispatch(refreshBusinesses({ businessName, offset: 0 }));
  };

  function resetIntegrations(): string[] {
    const allIntegrations = integrations.map(({ uuid }) => uuid);
    setIntegrationUuids(allIntegrations);
    return allIntegrations;
  }

  const resetFilter = (): void => {
    const allIntegrations = resetIntegrations();
    setStatus(AllBusinessStatus.ACTIVE);
    setBusinessName('');
    setCurrentPage(1);
    dispatch(
      refreshBusinesses({
        status: AllBusinessStatus.ACTIVE,
        businessName: '',
        integrationUuids: allIntegrations,
        offset: 0,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchIntegrationsApi());
    updateBusinesses();
    dispatch(setSelectedBusiness(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (integrations && !integrationUuids.length) resetIntegrations();
  }, [integrations]);

  useEffect(() => {
    if (businesses) {
      setBusinessesWithInitialQuery(expandBusinessInfo(businesses));
    }
  }, [businesses]);

  const formatLinkParams = (row): { searchParam: string; linkLabel: string } => {
    if (!row) return null;
    return {
      searchParam: `?${row.query}${showStatus ? `&show=${showStatus}` : ''}`,
      linkLabel: t('DASHBOARD_BUSINESS_TABLE_ROW_VIEW_DETAILS', {
        businessName: row?.businessName,
      }),
    };
  };

  return (
    <>
      <Header
        drawerMenu={true}
        leftComponent={null}
        rightComponent={null}
        title={t('DASHBOARD_BUSINESS_HEADER')}
        testId="business-page-header"
      />
      <BusinessEventProvider>
        <View className={classes.view} data-testid="business-page-view">
          <PageDescription
            title="DASHBOARD_BUSINESS_TITLE"
            subtitle="DASHBOARD_BUSINESS_DESCRIPTION"
            subtitleComponents={{
              a: (
                <Link
                  href="https://docs.railz.ai/docs/dashboard-manage-businesses"
                  onClick={(e): void =>
                    openNewTab(e, 'https://docs.railz.ai/docs/dashboard-manage-businesses')
                  }
                  rel="noopener noreferrer"
                  className={defaultClasses.link}
                >
                  {t('DASHBOARD_LEARN_MORE_CTA')}
                </Link>
              ),
            }}
          />
          <ScrollView
            classNames={classes.scrollContainer}
            data-testid="business-page-controls-and-table"
          >
            <BusinessControls
              businessName={businessName}
              searchBusiness={setBusinessName}
              setSelectedStatus={setStatus}
              selectedStatus={status}
              integrationUuids={integrationUuids}
              setIntegrationUuids={setIntegrationUuids}
              updateBusiness={updateBusinesses}
              applyFilter={applySearchBusiness}
              resetFilter={resetFilter}
            />
            <BusinessTable
              handleSort={handleRequestSort}
              columnsHeader={columnsHeader}
              isLoading={isLoading}
              sort={{ order, orderBy }}
              business={businessesWithInitialQuery}
              updateBusiness={updateBusinesses}
              onRowClick={{ pathName: '/businesses/overview', formatLinkParams }}
            />
          </ScrollView>

          {!isEmpty(businessesWithInitialQuery) && (
            <div className={classes.pagination}>
              <Pagination
                count={meta?.totalPages}
                page={currentPage}
                defaultPage={1}
                onChange={(_, pagerNumber): void => {
                  setCurrentPage(pagerNumber);
                  dispatch(
                    refreshBusinesses({
                      offset: (pagerNumber - 1) * PAGINATION_LIMIT,
                    }),
                  );
                }}
              />
            </div>
          )}
        </View>
      </BusinessEventProvider>
    </>
  );
};

export default Business;
