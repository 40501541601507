import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';

import useStyle from './style';

interface IProps {
  label: string;
  secret: string;
}

export default function SecretRevealBtn({ label, secret }: IProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyle();
  const [revealed, setRevealed] = useState(false);

  const handleClick = (): void => {
    setRevealed(true);
  };

  if (!secret) return null;

  return revealed ? (
    <span className={classes.secret} onClick={(): void => setRevealed(false)}>
      {secret}
    </span>
  ) : (
    <>
      <Button
        variant="text"
        aria-label="reveal secret button"
        className={classes.button}
        fullWidth={true}
        disableElevation={true}
        disableRipple={true}
        disableFocusRipple={true}
        onClick={handleClick}
      >
        {t(label)}
      </Button>
    </>
  );
}
