/**
 * Webhook payload
 */
import { WebhookSecret } from '../../../../types';
import { ErrorResponse } from '../../../../types/interface/error.interface';

export interface WebhookSecretAction<T> {
  payload?: T;
  type: string;
}

export type WebhookSecretActionCreator<T = undefined> = (payload?: T) => WebhookSecretAction<T>;

/**
 * Webhook store state
 */
export interface WebhookSecretState {
  loading: boolean;
  error: ErrorResponse;
  webhookSecret: WebhookSecret;
}

/**
 * Name of the webhook Store
 */
export const WEBHOOK_SECRET_STORE_NAME = 'webhook-secret';

/**
 * webhook initial state
 */
export const WEBHOOK_SECRET_INITIAL_STATE: WebhookSecretState = {
  webhookSecret: undefined,
  loading: false,
  error: null,
};
