import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IconButton, Menu, MenuItem, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import TransferIcon from '@material-ui/icons/SyncAlt';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { useDispatch, useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import { Pagination } from '@material-ui/lab';

import {
  getProfileState,
  getUserRole,
} from '../../store/features/account/profile/profile.selector';
import {
  fetchTeamMembersApi,
  fetchTeamApi,
  fetchTeamsApi,
  resendTeamMemberInvitationApi,
  resetTeamError,
  resetTeamResponse,
} from '../../store/features/account/team/team.action';
import {
  getTeamErrorMessage,
  getTeamApiResponse,
  getTeamMembersApiResponse,
} from '../../store/features/account/team/team.selector';
import {
  PaginationProps,
  TeamRole,
  PAGINATION_LIMIT,
  Role,
  TeamUserResponse,
  TEAMS_DROPDOWN_PAGINATION_LIMIT,
} from '../../types';

import EditTeamMemberForm from './edit-member';

import useStyles from './style';

import { ActionTypes } from './types/enums';

import DeleteMember from './delete-member/index';

import TransferOwnershipModal from './transfer-ownership';

import { Header, ScrollView, View } from 'components';

import PageDescription from 'components/page-description/page-description';
import BasicTable from 'components/table';
import { showSnackbar, capitalizeFirstLetter } from 'helpers/common.helper';
import Chip from 'components/chip';
import InviteTeamMemberForm from 'components/invite-team-member-form';
import { openNewTab } from 'helpers/open-new-tab';

interface Props {
  testid?: string;
}

const MembersPage: React.FC<Props> = ({ testid = 'test-header-team-page' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [member, setMember] = useState(null);
  const profile = useSelector(getProfileState);
  const { meta, users } = useSelector(getTeamMembersApiResponse);
  const teamErrorMessage = useSelector(getTeamErrorMessage);
  const teamResponse = useSelector(getTeamApiResponse);
  const role = useSelector(getUserRole);

  const isSuperAdmin = role === Role.SUPER_ADMINISTRATOR;
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showAddMemberForm, setShowAddMemberForm] = useState(false);
  const [showTransferOwnershipModal, setShowTransferOwnershipModal] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const [currentMember, setCurrentMember] = useState({});

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (users?.length > 0 && profile && currentPage === 1) {
      // current user returned first
      setCurrentUser(users[0]);
    }
  }, [users, profile]);

  const menuOptions = [
    {
      id: ActionTypes.EDIT,
      icon: <EditIcon />,
      label: t('DASHBOARD_TEAM_EDIT_CTA'),
      ariaLabel: (item): string =>
        t('DASHBOARD_TEAM_EDIT_CTA_ARIA_LABEL', {
          fullName: getFullName(item),
        }),
      hide: (): boolean => false,
      action: (member): void => {
        member.isCurrentUser ? history.push('/account/profile') : setCurrentMember(member);
        setShowEditForm(true);
      },
    },
    {
      key: 'resendInvite',
      icon: <SendSharpIcon />,
      label: t('DASHBOARD_TEAM_RESEND_INVITE_CTA'),
      hide: (member): boolean => member.state === 'active',
      action: (member: TeamUserResponse): void => {
        setMember(member);
        onResendTeamMemberInvitation(member.email);
      },
    },
    {
      id: ActionTypes.DELETE,
      icon: <DeleteOutlineIcon />,
      label: t('DASHBOARD_TEAM_DELETE_CTA'),
      ariaLabel: (item): string =>
        t('DASHBOARD_TEAM_DELETE_CTA_ARIA_LABEL', {
          fullName: getFullName(item),
        }),
      color: theme.palette.error.main,
      hide: (member): boolean => !isSuperAdmin || member.isCurrentUser,
      action: (member): void => {
        setCurrentMember(member);
        setShowDeleteForm(true);
      },
    },
    {
      id: ActionTypes.TRANSFER,
      icon: <TransferIcon />,
      label: t('DASHBOARD_MEMBERS_TRANSFER_CTA'),
      ariaLabel: (item): string =>
        t('DASHBOARD_MEMBERS_TRANSFER_CTA', {
          fullName: getFullName(item),
        }),
      hide: (member): boolean => {
        if (currentUser) {
          return (
            !currentUser.owner ||
            member.role !== 'superAdmin' ||
            profile.uuid === member.uuid ||
            member?.state !== 'active' ||
            member?.owner
          );
        }
        return true;
      },
      action: (member): void => {
        setShowTransferOwnershipModal(member);
      },
    },
  ];

  const getFullName = (member): string => `${member?.firstName || ''} ${member?.lastName || ''}`;

  const refreshTeamMembers = (params?: PaginationProps): any => {
    const payload = { ...params, currentPage, paginationLimit: PAGINATION_LIMIT };
    dispatch(fetchTeamMembersApi(payload));
  };

  useEffect(() => {
    refreshTeamMembers();
    dispatch(fetchTeamApi());
    dispatch(fetchTeamsApi({ limit: TEAMS_DROPDOWN_PAGINATION_LIMIT }));
  }, []);

  useEffect(() => {
    if (teamResponse) {
      dispatch(resetTeamResponse());
      if (teamResponse.message === 'resendInvitation') {
        showSnackbar({
          message: (
            <Trans
              i18nKey="DASHBOARD_TEAM_RESEND_INVITATION_SUCCESS"
              values={{
                fullName: member?.firstName ? getFullName(member) : member?.email,
              }}
            />
          ),
          type: 'success',
        });
      }
      refreshTeamMembers();
      setMember(null);
    }
    // eslint-disable-next-line
  }, [teamResponse]);

  useEffect(() => {
    if (!isEmpty(teamErrorMessage)) {
      dispatch(resetTeamError());
      if (teamErrorMessage[0] === 'fetch') {
        showSnackbar({ message: t('DASHBOARD_MEMBERS_FETCH_FAILURE'), type: 'error' });
        return;
      } else if (teamErrorMessage[0] === 'resendInvitation') {
        showSnackbar({
          message: (
            <Trans
              i18nKey="DASHBOARD_TEAM_RESEND_INVITATION_FAILURE"
              values={{
                fullName: member?.firstName ? getFullName(member) : member?.email,
              }}
            />
          ),
          type: 'error',
        });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamErrorMessage, dispatch]);

  const renderTeam = (owner, teams): React.ReactElement => {
    const isSuperAdmin = teams?.filter((team) => team.role === Role.SUPER_ADMINISTRATOR);

    if (isSuperAdmin?.length > 0) {
      return (
        <div className={classes.roleDescription} key={`id-${0}-${teams[0].name}`}>
          <span className={classes.label}>All Teams:</span>
          <span className={classes.role}>{TeamRole.superAdmin}</span>

          {owner && (
            <span className={classes.ownerText}>{t('DASHBOARD_TEAM_MEMBERS_ROLE_OWNER')}</span>
          )}
        </div>
      );
    }

    return teams?.map((team, i) => {
      return (
        <div className={classes.roleDescription} key={`id-${i}-${team.name}`}>
          <span className={classes.label}>{team.name}:</span>
          <span className={classes.role}>{team.role}</span>
        </div>
      );
    });
  };

  const columns = [
    {
      key: 'name',
      name: t('DASHBOARD_MEMBERS_TABLE_NAME'),
      render: ({ firstName, lastName, isCurrentUser }): React.ReactElement => {
        const name =
          firstName || lastName
            ? `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`
            : '-';

        return (
          <>
            <span>{name}</span>
            {isCurrentUser && (
              <span className={classes.youText}>{t('DASHBOARD_TEAM_MEMBERS_ROLE_YOU')}</span>
            )}
          </>
        );
      },
    },
    {
      key: 'email',
      name: t('DASHBOARD_TEAM_MEMBERS_EMAIL_COL_HEADER'),
      render: ({ email, state }): React.ReactElement => (
        <span className={classes.email}>
          {email}
          {state !== 'active' && (
            <Chip chipText={t('DASHBOARD_BUSINESS_STATUS_PENDING')} size="small" />
          )}
        </span>
      ),
    },
    {
      key: 'role',
      name: t('DASHBOARD_MEMBERS_TABLE_TEAM_ROLE'),
      render: ({ owner, teams }): React.ReactElement => {
        return (
          <div className={teams?.length > 1 ? classes.roleContainer : ''}>
            {renderTeam(owner, teams)}
          </div>
        );
      },
    },
    {
      key: 'cta',
      name: t('DASHBOARD_TEAM_MEMBERS_EDIT_DELETE_COL_HEADER'),
      render: (item): React.ReactElement => {
        return (
          <>
            <IconButton
              tabIndex={0}
              aria-haspopup="true"
              onClick={(event): void =>
                setMenuAnchorEl({ ...menuAnchorEl, [item.uuid]: event.currentTarget })
              }
              data-testid={`${item.uuid}-overflow-menu-button`}
              aria-label={'full-menu'}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`${item.uuid}-overflow-menu`}
              anchorEl={menuAnchorEl?.[item.uuid]}
              open={Boolean(menuAnchorEl?.[item.uuid])}
              onClose={(): void => setMenuAnchorEl({ ...menuAnchorEl, [item.uuid]: null })}
              data-testid={`${item.uuid}-overflow-menu`}
            >
              {menuOptions.map(
                (option, i) =>
                  !option.hide(item) && (
                    <MenuItem
                      key={`${i}-${option.id}`}
                      className={classes.menuItem}
                      aria-label={option?.label}
                      onClick={(): void => {
                        setMenuAnchorEl(null);
                        option?.action(item);
                      }}
                      style={{ color: option?.color }}
                    >
                      {option.icon}
                      {option.label}
                    </MenuItem>
                  ),
              )}
            </Menu>
          </>
        );
      },
    },
  ];

  const onAddClick = (): void => {
    setShowAddMemberForm(true);
  };

  const onResendTeamMemberInvitation = (email: string): void => {
    dispatch(resendTeamMemberInvitationApi({ email }));
  };

  return (
    <>
      <Header
        drawerMenu
        title={t('DASHBOARD_MEMBERS')}
        leftComponent={null}
        rightComponent={null}
        testId={testid}
      />

      <View className={classes.view} data-testid="content">
        <PageDescription title="DASHBOARD_MEMBERS_TITLE">
          <Trans
            i18nKey="DASHBOARD_MEMBERS_BODY"
            components={{
              a: (
                <a
                  href="https://docs.railz.ai/docs/dashboard-manage-teams"
                  onClick={(e): void =>
                    openNewTab(e, 'https://docs.railz.ai/docs/dashboard-manage-teams')
                  }
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  {t('DASHBOARD_LEARN_MORE_CTA')}
                </a>
              ),
            }}
          />
        </PageDescription>
        <div className={classes.createTeamContainer}>
          <RailzButton
            label={t('DASHBOARD_MEMBERS_ADD_CTA')}
            onClick={onAddClick}
            size="large"
            data-testid="add-members-button"
          >
            <span slot="prefix">
              <AddIcon />
            </span>
          </RailzButton>
        </div>
        <ScrollView classNames={classes.viewContent}>
          <BasicTable
            data-testid="member-table"
            columns={columns}
            rows={users?.map((member: any) => ({
              ...member,
              role: member.role,
              isCurrentUser: member.email === profile?.email,
            }))}
            stickyHeader={true}
            isPaperComponent={false}
            noData={t('DASHBOARD_MEMBERS_NO_DATA_FOUND')}
          />
        </ScrollView>

        {users && (
          <div className={classes.pagination}>
            <Pagination
              count={meta?.totalPages}
              page={currentPage}
              defaultPage={1}
              onChange={(_, pagerNumber): void => {
                setCurrentPage(pagerNumber);
                refreshTeamMembers({
                  offset: (pagerNumber - 1) * PAGINATION_LIMIT,
                });
              }}
            />
          </div>
        )}
      </View>

      {showEditForm && (
        <EditTeamMemberForm member={currentMember} onClose={(): void => setShowEditForm(false)} />
      )}
      {showDeleteForm && (
        <DeleteMember member={currentMember} onClose={(): void => setShowDeleteForm(false)} />
      )}
      {showAddMemberForm && (
        <InviteTeamMemberForm onClose={(): void => setShowAddMemberForm(false)} />
      )}
      {showTransferOwnershipModal && (
        <TransferOwnershipModal
          onClose={(): void => setShowTransferOwnershipModal(null)}
          member={showTransferOwnershipModal}
        />
      )}
    </>
  );
};

export default MembersPage;
