export enum WebhookSecretKind {
  BEARER = 'bearer',
}

export enum WebhookSecretMethod {
  GET = 'get',
  POST = 'post',
}

export class HeaderParams {
  name: string;
  value: string;
}

export interface WebhookSecret {
  authUrl: string;
  username: string;
  tokenName: string;
  method: WebhookSecretMethod;
  password: string;
  kind: WebhookSecretKind;
  header?: HeaderParams[] | [];
  body?: HeaderParams[] | [];
  createdAt: string;
  updatedAt: string;
}

export interface WebhookSecretRequest {
  authUrl?: string;
  username?: string;
  tokenName?: string;
  method?: WebhookSecretMethod;
  password?: string;
  kind?: WebhookSecretKind | 'none';
  header?: HeaderParams[];
  body?: HeaderParams[];
}

export interface WebhookConfig {
  requestIdParam: string;
  payloadType: string;
}
