import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';

import { RailzButton } from '@railzai/railz-uikit-react';

import { saveOptions } from '../../../helpers/report-helpers/constants';

import useStyle from './styles';

import { ExportOption } from 'types';

export default function ReportFilterExportButton({
  onSelect,
  disabled = false,
}: {
  onSelect: (option: ExportOption) => void;
  disabled?: boolean;
}): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <RailzButton
        type="text primary"
        isDisabled={disabled}
        aria-label={t('DASHBOARD_REPORT_CTA_ARIA')}
        onClick={(event): void => {
          event.stopPropagation();
          if (disabled) return;
          setAnchorEl(event.currentTarget);
        }}
        data-testid="export-report-button"
        label={t('DASHBOARD_EXPORT_AS')}
      >
        <span slot="suffix">
          <GetAppIcon />
        </span>
      </RailzButton>

      <Menu
        id="simple-menu"
        data-testid="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(): void => setAnchorEl(null)}
        className={classes.menu}
      >
        {saveOptions.map((el) => (
          <MenuItem
            onClick={(): void => {
              onSelect(el);
              setAnchorEl(null);
            }}
            key={el.value}
          >
            {t(el.value)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
