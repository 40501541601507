import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import { useTranslation } from 'react-i18next';

import { FormInputProps } from 'types';

const FormCheckBox = ({
  label,
  name,
  errorobj,
  color = 'primary',
  control,
  testid = '',
  onChange: onChangeProp,
  ...rest
}: Omit<FormInputProps, 'onChange'> &
  Pick<CheckboxProps, 'onChange' | 'indeterminate'>): ReactElement => {
  const { t } = useTranslation('dashboard');
  let errorMessage = '';

  if (errorobj && errorobj[name]) {
    errorMessage = errorobj[name].message;
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, onBlur, value, ref }): JSX.Element => (
        <FormControl error={!!errorMessage}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={(event: React.MouseEvent): void => {
                  event.stopPropagation();
                }}
                onBlur={onBlur}
                checked={value}
                color={color}
                onChange={(event, checked): void => {
                  onChange(checked);
                  typeof onChangeProp === 'function' && onChangeProp(event, checked);
                }}
                inputRef={ref}
                data-testid={`${testid}-checkbox`}
                inputProps={{
                  'data-testid': `${testid}-checkbox-inner`,
                }}
                {...rest}
              />
            }
            label={label}
          />
          {errorMessage && <FormHelperText>{t(errorMessage)}</FormHelperText>}
        </FormControl>
      )}
      defaultValue={false}
      {...rest}
    />
  );
};

export default FormCheckBox;
