import { ErrorResponse } from '../../../types/interface/error.interface';

import { ReportQuery } from '../../../types';

import {
  FormattedDataTypes,
  ReportParam,
  BusinessHistoryParam,
  BusinessHistoryResponseDto,
  ReportData,
  ReportFetchActionData,
  ReportState,
} from './report.state';

export const SET_REPORT_SUMMARY_FILTER = 'SET_REPORT_SUMMARY_FILTER';

export const SET_REPORT_EXPORT_STATE = 'SET_REPORT_EXPORT_STATE';

export const FETCH_REPORTS_API = 'FETCH_REPORTS_API';
export const FETCH_REPORTS_PENDING_API = 'FETCH_REPORTS_PENDING_API';
export const FETCH_REPORTS_SUCCESS_API = 'FETCH_REPORTS_SUCCESS_API';
export const FETCH_REPORTS_FAILURE_API = 'FETCH_REPORTS_FAILURE_API';

export const FETCH_DATA_TYPES_API = 'FETCH_DATA_TYPES_API';
export const FETCH_DATA_TYPES_SUCCESS_API = 'FETCH_DATA_TYPES_SUCCESS_API';
export const FETCH_DATA_TYPES_FAILURE_API = 'FETCH_DATA_TYPES_FAILURE_API';

export const RESET_REPORTS_API = 'RESET_REPORTS_API';

export const FETCH_SYNC_STATUS_BUSINESS_API = 'FETCH_SYNC_STATUS_BUSINESS_API';
export const FETCH_SYNC_STATUS_BUSINESS_SUCCESS = 'FETCH_SYNC_STATUS_BUSINESS_SUCCESS';
export const FETCH_SYNC_STATUS_BUSINESS_FAILURE = 'FETCH_SYNC_STATUS_BUSINESS_FAILURE';

export const RESET_STORAGE_REPORT = 'RESET_STORAGE_REPORT';

export const resetReportStorage = (): { type: string } => {
  return {
    type: RESET_STORAGE_REPORT,
  };
};

export const fetchReportsApi = (payload: ReportParam): { type: string; payload: ReportParam } => {
  return {
    type: FETCH_REPORTS_API,
    payload,
  };
};

type FetchReportsResultAction = (payload: ReportFetchActionData<ReportData>) => {
  type: string;
  payload: ReportFetchActionData<ReportData>;
};

export const fetchReportsPendingApi: FetchReportsResultAction = (payload) => {
  return {
    type: FETCH_REPORTS_PENDING_API,
    payload,
  };
};

export const fetchReportsSuccessApi: FetchReportsResultAction = (payload) => {
  return {
    type: FETCH_REPORTS_SUCCESS_API,
    payload,
  };
};

export const fetchReportsFailureApi: FetchReportsResultAction = (payload) => {
  return {
    type: FETCH_REPORTS_FAILURE_API,
    payload,
  };
};

export const resetReportsApi = (): { type: string } => {
  return {
    type: RESET_REPORTS_API,
  };
};

export const fetchDataTypesApi = (): { type: string } => {
  return {
    type: FETCH_DATA_TYPES_API,
  };
};

export const fetchDataTypesSuccessApi = (
  payload: FormattedDataTypes,
): { type: string; payload: FormattedDataTypes } => {
  return {
    type: FETCH_DATA_TYPES_SUCCESS_API,
    payload,
  };
};

export const setReportSummaryFilter = (
  payload: ReportQuery,
): { type: string; payload: ReportQuery } => {
  return { type: SET_REPORT_SUMMARY_FILTER, payload };
};

export const setReportDownloadState = (
  payload: Partial<ReportState['reportExport']>,
): { type: string; payload: Partial<ReportState['reportExport']> } => {
  return { type: SET_REPORT_EXPORT_STATE, payload };
};

export const fetchDataTypesFailureApi = (
  payload: ErrorResponse | any,
): { type: string; payload: ErrorResponse | any } => {
  return {
    type: FETCH_DATA_TYPES_FAILURE_API,
    payload,
  };
};

export const fetchBusinessHistoryApi = (
  payload: BusinessHistoryParam,
): { type: string; payload: BusinessHistoryParam } => {
  return {
    type: FETCH_SYNC_STATUS_BUSINESS_API,
    payload,
  };
};

export const fetchBusinessHistoryApiSuccess = (
  payload: BusinessHistoryResponseDto,
): { type: string; payload: BusinessHistoryResponseDto } => {
  return {
    type: FETCH_SYNC_STATUS_BUSINESS_SUCCESS,
    payload,
  };
};

export const fetchBusinessHistoryApiFailure = (
  payload: BusinessHistoryResponseDto,
): { type: string; payload: BusinessHistoryResponseDto } => {
  return {
    type: FETCH_SYNC_STATUS_BUSINESS_FAILURE,
    payload,
  };
};
