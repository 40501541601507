import axios from 'axios';

import Config from 'config';
import { WebhookConfig } from 'types';

export const webhookConfigServiceUrl = `${Config.SERVER_URL}/webhooks/config`;

class WebhookService {
  async update(payload: WebhookConfig): Promise<WebhookConfig> {
    return await axios.put(webhookConfigServiceUrl, payload);
  }

  async delete(): Promise<undefined> {
    await axios.delete(webhookConfigServiceUrl);
    return;
  }
}

export default new WebhookService();
