import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import { yupResolver } from '@hookform/resolvers/yup';

import { object, string } from 'yup';

import { useForm } from 'react-hook-form';

import useStyle from './styles';

import AlertDialog from 'components/dialog';

import { Integration } from 'types';
import { FormInput } from 'components/form';

interface Props {
  integration: Integration;
  handleClose: (integration: Integration, confirmed: boolean) => void;
}

export const IntegrationDeleteConfirmationModal: React.FC<Props> = ({
  integration,
  handleClose,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      object().shape({
        confirmation: string().oneOf(
          ['DELETE'],
          t('DASHBOARD_INTEGRATION_KEYS_MODAL_DELETE_EMPTY'),
        ),
      }),
    ),
  });

  return (
    <AlertDialog
      showCloseButton
      isOpen={true}
      title={t('DASHBOARD_INTEGRATION_KEYS_MODAL_DELETE_TITLE')}
      onClose={(): void => handleClose(integration, false)}
      cancel={{
        label: t('DASHBOARD_INTEGRATION_KEYS_MODAL_DEFAULT_CTA_CANCEL'),
        type: 'gray',
        onClick: (): void => handleClose(integration, false),
      }}
      confirm={{
        label: t('DASHBOARD_INTEGRATION_KEYS_MODAL_DELETE_CTA'),
        onClick: handleSubmit(() => handleClose(integration, true)),
        type: 'error',
      }}
    >
      <Typography className={classes.confirmationDescription} paragraph={true}>
        <Trans
          i18nKey="DASHBOARD_INTEGRATION_KEYS_MODAL_DELETE_DESCRIPTION"
          components={{ b: <strong /> }}
          values={{ name: integration.name }}
        />
      </Typography>
      <Typography className={classes.confirmationLabel} paragraph={true}>
        <Trans
          i18nKey="DASHBOARD_INTEGRATION_KEYS_MODAL_DELETE_CONFIRM"
          components={{ b: <strong /> }}
        />
      </Typography>
      <Box>
        <FormInput
          className={classes.confirmationInput}
          defaultValue=""
          id="confirmation"
          name="confirmation"
          margin="dense"
          testid="confirmation-input"
          fullWidth
          errorobj={errors}
          control={control}
          type="text"
        />
      </Box>
    </AlertDialog>
  );
};
