import { createStyles, makeStyles } from '@material-ui/core';

import { ACTIVE_TEXT_COLOR, DEFAULT_SECONDARY_COLOR } from 'providers/theme-provider';

export default makeStyles((theme) =>
  createStyles({
    copiedText: {
      fontSize: '0.75rem',
    },

    link: {
      outline: 'none',
      boxShadow: 'none',
    },

    tableRowHover: {
      '&:hover': {
        background: theme.palette.grey[100],
      },
    },

    tableRow: {
      position: 'relative',
      transform: 'translateZ(0)',

      '&:focus-within': {
        background: theme.palette.grey[100],
      },

      '& td': {
        height: theme.spacing(7),
        position: 'relative',
        boxSizing: 'border-box',
      },
    },

    linkLabel: {
      position: 'absolute',
      clip: 'rect(0 0 0 0)',
      width: '1px',
      height: '1px',
      margin: '-1px',
      padding: 0,
      border: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    nameRowLabel: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
    uuidRowLabel: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      color: theme.palette.grey[600],
      paddingTop: theme.spacing(1),
    },
    tableCell: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    chipDefault: {
      fontWeight: theme.typography.fontWeightMedium as number,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      fontSize: '0.75rem',
    },
    activeChip: {
      color: ACTIVE_TEXT_COLOR,
      backgroundColor: DEFAULT_SECONDARY_COLOR['main'],
    },
    inactiveChip: {
      color: '#424242',
      backgroundColor: '#EEEEEE',
    },
  }),
);
