import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes appear': {
      from: {
        transform: 'scale(.8)',
        opacity: 0,
        filter: 'blur(5px)',
      },
    },

    visible: {
      width: '80% !important',
      opacity: 1,
      visibility: 'visible',
      animation: `$appear .2s ease-in-out both`,
    },

    // Content
    heading: {
      fontWeight: 600,
      marginBottom: '.5em',
      fontSize: '1.25em',
    },

    description: {
      fontWeight: 400,
      fontSize: '0.85em',
      hyphens: 'auto',
    },

    accentLinkText: {
      fontWeight: 600,
      fontSize: '0.85em',
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      marginTop: '.5em',
    },

    inputBox: {
      background: 'white',
      border: '2px solid',
      borderColor: theme.palette.grey[300],
      borderRadius: '.5em',
      padding: '.5em 1em',
      fontSize: '.875em',
      textAlign: 'left',
      margin: '2em auto',
      width: '75%',
      justifySelf: 'center',
    },

    button: {
      marginTop: '3em',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '.75em 1.5em',
      textAlign: 'center',
      borderRadius: '5em',
      fontSize: '1.125em',
      fontWeight: 600,
      width: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);
