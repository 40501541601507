import React, { createRef, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RailzButton } from '@railzai/railz-uikit-react';

import { isSandbox, resetAuthInformation } from '../store/features/auth';

import useStyle from './style';
import MainLayout from './dashboard-layout';

import { logoutErrorSnackbar } from './helpers/util';

import storeService from 'store';
import useQuerySearchUpdate from 'hooks/use-query-search-update';
import { resetBusinessInformation } from 'store/features/business/business.action';
import { resetIntegrationsInformation } from 'store/features/integration/integration.action';
import { resetCustomerInformation } from 'store/features/account/customer/customer.action';

export const withLayout =
  (Component: React.FC) =>
  <T,>(props: T): React.ReactElement => {
    const classes = useStyle();
    const ref: React.RefObject<HTMLInputElement> = createRef();
    const { t } = useTranslation();

    const focusHelper = (): void => {
      const firstFocusable: NodeListOf<HTMLElement> =
        ref.current.querySelectorAll('#title,#backButton');
      firstFocusable?.[0]?.focus();
    };

    const [queryParams, updateParams] = useQuerySearchUpdate();
    const store = storeService.getStore();
    const isSandboxEnabled = useSelector<ReturnType<typeof store.getState>, boolean>(isSandbox);

    const handleLogout = useCallback(async (): Promise<void> => {
      try {
        store.dispatch(resetAuthInformation());
        store.dispatch(resetBusinessInformation());
        store.dispatch(resetIntegrationsInformation());
        store.dispatch(resetCustomerInformation());
      } catch (err) {
        logoutErrorSnackbar(err);
      }
    }, []);

    const checkQuerySandbox = (isSandboxQuery, isSandboxServer): void => {
      if (isSandboxQuery && !isSandboxServer) {
        handleLogout();
      } else if (!isSandboxQuery && isSandboxServer) {
        updateParams({ sandbox: true });
      }
    };

    useEffect(() => {
      const isSandboxQuery = !!queryParams?.get('sandbox');
      const isSandboxServer = !!isSandboxEnabled;
      checkQuerySandbox(isSandboxQuery, isSandboxServer);
    }, [queryParams?.get('sandbox')]);

    return (
      <>
        <RailzButton
          className={classes.skipToContentButton}
          onButtonClick={focusHelper}
          label={t('DASHBOARD_SKIP_TO_CONTENT')}
        />

        <MainLayout mainRef={ref} handleLogout={handleLogout}>
          <Helmet>
            <style type="text/css">
              {`
            iframe[name='JSD widget'] {
              display: block;
            }
            `}
            </style>
          </Helmet>
          <Component {...props} />
        </MainLayout>
      </>
    );
  };
