import { Box, Tab, Tabs } from '@material-ui/core';

import React from 'react';

import useStyle from './style';
interface TabProps {
  value: TabInterface['value'];
  handleChange: (value: TabInterface['value']) => void;
  ariaLabel?: string;
  tabs: TabInterface[];
}
interface TabInterface {
  label: string;
  value: number | string;
}
const TabComponent = ({
  value,
  handleChange,
  ariaLabel = 'tabs-section',
  tabs = [],
}: TabProps): React.ReactElement => {
  const classes = useStyle();
  return (
    <Box className={classes.box}>
      <Tabs
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string): void =>
          handleChange(Number.isInteger(+newValue) ? +newValue : newValue)
        }
        variant="scrollable"
        scrollButtons="auto"
        aria-label={ariaLabel}
        className={classes.tabs}
      >
        {tabs.map((tab) => (
          <Tab label={tab.label} value={tab.value} className={classes.tab} key={tab.value} />
        ))}
      </Tabs>
    </Box>
  );
};
export default TabComponent;
