import React from 'react';

import { Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import style from './style';

export default function SandboxBanner(): React.ReactElement {
  const classes = style();
  const { t } = useTranslation();

  return (
    <div data-testid="sandbox-banner" className={classes.sandboxBanner}>
      <Typography id="sandboxTitle" className={classes.sandboxTitle} tabIndex={0}>
        {t('DASHBOARD_SANDBOX_BANNER')}
      </Typography>
    </div>
  );
}
