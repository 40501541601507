/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import { persistedReducer } from './persist';
import { saga } from './saga';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

// create store
const middlewares = [sagaMiddleware];

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));
sagaMiddleware.run(saga);

export const persistor = persistStore(store);

// export store singleton instance
export default store;
