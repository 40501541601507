import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useTranslation } from 'react-i18next';

import { Switch } from '@material-ui/core';

import { FormControlledProps } from 'types';

const FormSwitch = ({ controller, input, label, extras }: FormControlledProps): ReactElement => {
  const { t } = useTranslation('dashboard');
  let errorMessage = '';

  if (extras && extras.errors && extras.errors[controller.name]) {
    errorMessage = extras.errors[controller.name].message;
  }

  return (
    <Controller
      {...controller}
      render={({ onChange, onBlur, value, ref }): JSX.Element => (
        <FormControl error={!!errorMessage}>
          <FormControlLabel
            control={
              <Switch
                {...input}
                onBlur={onBlur}
                checked={value}
                onChange={(e, checked): void => {
                  onChange(checked);
                  typeof input.onChange === 'function' && input.onChange(checked);
                }}
                inputRef={ref}
              />
            }
            {...label}
          />
          <FormHelperText>{t(errorMessage)}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormSwitch;
