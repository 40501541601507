import React from 'react';

import { Chip, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { isActive, isBeta } from './helper';

import { getLocalizedService } from 'helpers/common.helper';
import { openNewTab } from 'helpers/open-new-tab';
import { ServiceWithIntegrations } from 'types';
import { CustomerState } from 'store/features/account/customer/customer.state';

interface Props {
  service: ServiceWithIntegrations;
  user: CustomerState;
  classes: Record<string, string>;
}
const href =
  // eslint-disable-next-line max-len
  'https://help.railz.ai/en/articles/5524859-what-is-the-railz-beta-program-and-how-do-i-get-access';

const JoinBetaLink: React.FC<Omit<Props, 'user'>> = ({ service, classes }): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Link
      className={classes.link}
      component="a"
      href={href}
      rel="noopener noreferrer"
      onClick={(e): void => openNewTab(e, href)}
      aria-label={getLocalizedService(service.name) + ',' + t('DASHBOARD_LEARN_MORE_CTA')}
    >
      {t('DASHBOARD_LEARN_MORE_CTA')}
    </Link>
  );
};

export const ComingSoon: React.FC<Props> = ({ service, user, classes }): React.ReactElement => {
  const { t } = useTranslation();
  const serviceIsBetaAndUserIsNot = isBeta(service) && !isBeta(user);
  if (serviceIsBetaAndUserIsNot) return <JoinBetaLink service={service} classes={classes} />;
  if (isActive(service)) return null;

  return (
    <Chip
      className={classes.chip}
      label={t('DASHBOARD_INTEGRATION_CTA_COMING_SOON')}
      aria-label={t('DASHBOARD_INTEGRATION_CTA_COMING_SOON_ARIA_LABEL', {
        accountName: getLocalizedService(service.name),
      })}
      tabIndex={0}
    />
  );
};
