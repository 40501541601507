import { combineReducers } from '@reduxjs/toolkit';

import { apiKeyReducer } from './api-key/api-key.reducer';
import { API_KEY_STORE_NAME } from './api-key/api-key.state';
import { DEVELOPER_STORE_NAME } from './developer.state';
import { webhookConfigReducer } from './webhook-config/webhook-config.reducer';
import { WEBHOOK_CONFIG_STORE_NAME } from './webhook-config/webhook-config.state';
import { webhookSecretReducer } from './webhook-secret/webhook-secret.reducer';
import { WEBHOOK_SECRET_STORE_NAME } from './webhook-secret/webhook-secret.state';
import { webhookReducer } from './webhook/webhook.reducer';
import { WEBHOOK_STORE_NAME } from './webhook/webhook.state';

export const developerReducer = combineReducers({
  [WEBHOOK_STORE_NAME]: webhookReducer,
  [API_KEY_STORE_NAME]: apiKeyReducer,
  [WEBHOOK_SECRET_STORE_NAME]: webhookSecretReducer,
  [WEBHOOK_CONFIG_STORE_NAME]: webhookConfigReducer,
});

export const developerReducerConfig = {
  [DEVELOPER_STORE_NAME]: developerReducer,
};
